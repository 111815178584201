<template>
  <div class="ma-1">
    <v-tooltip right>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" width="82px" height="40px"><v-icon left small>mdi-information</v-icon>{{formatLoadType(loadFields.type)}}</v-btn>
      </template>
      <template v-if="loadFields.type.split(',').findIndex(el => el === 'TL') >= 0">
        {{$t('Loads.TruckLoadDescription')}}<br/>
      </template>
      <template v-if="loadFields.type.split(',').findIndex(el => el === 'LTL') >= 0">
        {{$t('Loads.LessThanTruckLoadDescription')}}<br/>
      </template>
      <template v-if="loadFields.type.split(',').findIndex(el => el === 'PTL') >= 0">
        {{$t('Loads.PartialTruckLoadDescription')}}<br/>
      </template>
      <template v-if="loadFields.type.split(',').findIndex(el => el === 'RTL') >= 0">
        {{$t('Loads.ReturnTruckLoadDescription')}}<br/>
      </template>
      <template v-if="loadFields.type.split(',').findIndex(el => el === 'TL_RTL') >= 0">
        {{$t('Loads.RoundTripLoadDescription')}}<br/>
      </template>
      <br/>
      <template v-for="(locationEntry, index) in loadFields.locations">
        <div :key="locationEntry.id">
          <v-icon small :color="$root.getLocationColor(locationEntry)">{{$root.getLocationIcon(locationEntry)}}</v-icon>{{locationEntry.name}}
          <template v-if="locationEntry.truck > 0 && index < loadFields.locations.length - 1">
            <b><u>{{locationEntry.truckName}}</u>&nbsp;-&nbsp;{{locationEntry.driverInitials}} {{locationEntry.coDriverInitials}}</b>
            <br/>
          </template>
        </div>
      </template>
    </v-tooltip>
  </div>
</template>

<script>

export default {
  name: 'LoadLocationsTooltip',
  props: ['loadFields'],
  components: {
  },
  data: function () {
    return {
    };
  },
  methods: {
    formatLoadType (type) {
      if (type === 'TL') return 'TL ';
      if (type === 'TL_RTL') return 'RT ';
      return type;
    },
  }
};
</script>
