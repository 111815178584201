<template>
  <div>
    <!--{{locations}}-->
    <!--backgroundColor: '#4caf50'-->
    <div :style="{display:'block', width: currentProgress + '%', marginLeft: '0px'}" class="text-right">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon left v-on="on" v-html="getTruckIcon"></v-icon>
        </template>
        <span>
          <v-simple-table dense class="ma-1">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>{{$t('Loads.TruckSpeed')}}</td>
                    <td :style="{color: truckTelemetry.speed ? '#4caf50' : '#cccccc'}">{{truckTelemetry.speed}} {{$t('mphShort')}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Loads.TruckLocation')}}</td>
                    <td>{{truckTelemetry.description}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Loads.TruckOdometer')}}</td>
                    <td>{{truckTelemetry.odometer ? Number(truckTelemetry.odometer.toFixed(0)).toLocaleString() : '-' }} {{$t('milesShort')}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Loads.TruckFuel')}}&nbsp;{{$t('gallonsShort')}}</td>
                    <td>{{truckTelemetry.fuel ? Number(truckTelemetry.fuel.toFixed(0)).toLocaleString() : '-' }} {{$t('gallonsShort')}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Loads.TruckEngineHours')}}&nbsp;{{$t('hoursShort')}}</td>
                    <td>{{truckTelemetry.engine_hours ? Number(truckTelemetry.engine_hours.toFixed(0)).toLocaleString() : '-' }} {{$t('hoursShort')}}</td>
                  </tr>
                </tbody>
              </template>
          </v-simple-table>
        </span>
      </v-tooltip>
    </div>
    <v-progress-linear
      height="15"
      color="green"
      background-opacity="1"
      :buffer-value="currentProgress"
      stream
    ></v-progress-linear>
    <div style="display:block;width: 100%;">
      <template v-for="(item, index) in locations">
        <v-tooltip bottom v-if="stepsSizesPercent[index] && index < locations.length - 1" :key="'progressBarLocations'+index">
          <template v-slot:activator="{ on }">
            <div v-on="on" class="text-right" :style="{display:'inline-block', borderRight: '0px #f00 solid', backgroundColor: getColor(index), width: stepsSizesPercent[index] + '%', height:'30px'}">
              <v-icon right v-if="locations[index+1].pickup">fa-people-carry</v-icon>
              <v-icon right v-else-if="locations[index+1].delivery">fa-truck-loading</v-icon>
              <v-icon right v-else-if="locations[index+1].parking">mdi-parking</v-icon>
              <v-icon right v-else-if="locations[index+1].exchange">mdi-swap-horizontal-bold</v-icon>
              <v-icon right v-else-if="locations[index+1].returnToHQ">mdi-redo-variant</v-icon>
            </div>
          </template>
          <span>
            <v-simple-table dense class="ma-1">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>{{$t('Loads.FromLocation')}}</td>
                    <td colspan="2">
                      <v-icon left v-html="index === 0 ? 'mdi-ray-start-arrow' : $root.getLocationIcon(item)"></v-icon>&nbsp;
                      {{item.name}}
                    </td>
                  </tr>
                  <tr>
                    <td>{{$t('Loads.ToLocation')}}</td>
                    <td colspan="2">
                      <v-icon left v-html="$root.getLocationIcon(locations[index+1])"></v-icon>&nbsp;
                      {{locations[index+1].name}}
                    </td>
                  </tr>
                  <tr>
                    <td>{{$t('Loads.ToLocationTarget')}}</td>
                    <td><v-icon small>fa-route</v-icon> {{$root.metersToMiles(item.distance).toLocaleString()}}&nbsp;{{$t('milesShort')}}</td>
                    <td><v-icon>mdi-clock-fast</v-icon> {{$root.showDuration(item.duration)}}</td>
                  </tr>
                  <tr v-if="locations[index+1].driverOnTheWayCheck > 0 && !locations[index+1].driverOnSiteCheck">
                    <td>{{$t('Loads.ToLocationLeft')}}</td>
                    <td><v-icon small>fa-route</v-icon> {{$root.metersToMiles(locations[index+1].currentDistance).toLocaleString()}}&nbsp;{{$t('milesShort')}}</td>
                    <td><v-icon>mdi-clock-fast</v-icon> {{$root.showDuration(locations[index+1].currentDuration)}}</td>
                  </tr>
                  <tr v-if="isLocationDone(index)">
                    <td>{{$t('Loads.OnLocationTime')}}</td>
                    <td colspan="2">{{$root.showDuration(locations[index+1].driverDoneCheck - locations[index+1].driverOnSiteCheck)}}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </span>
        </v-tooltip>
      </template>
      <!--<v-progress-linear
        :buffer-value="doneProgress"
        :value="doneProgress"
        height="10px"
        stream
        color="green"
      ></v-progress-linear>-->
    </div>
  </div>
</template>

<script>
import userFormsCommonMixin from '../mixins/userFormsCommonMixin';

export default {
  name: 'LoadProgressBar',
  props: ['locations', 'truckTelemetry'],
  components: { },
  mixins: [userFormsCommonMixin],
  data: function () {
    return {
      loading: false,
      error: null,
      polling: null,
      updating: null,
      currentProgress: 0,
      currentProgressStep: 0,
      stepsSizesPercent: []
    };
  },
  watch: {},
  computed: {
    getTruckIcon () {
      for (let i = this.locations.length - 2; i >= 0; i--) {
        const location = this.locations[i + 1];
        const prevLocation = this.locations[i];
        if (location.dispatcherOnTheWayCheck > 0) {
          return 'mdi-truck-check';
        } else if (location.driverOnSiteCheck > 0 || location.driverDoneCheck) {
          return 'mdi-truck-delivery';
        } else if (prevLocation.driverOnTheWayCheck > 0) {
          return 'mdi-truck-fast';
        }
      }
      return 'mdi-truck';
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.calculateProgress();
      /* this.updating = setInterval(() => {
                    this.calculateProgress();
                    console.log({currentProgress: this.currentProgress,
                        currentProgressStep:  this.currentProgressStep,
                        stepsSizesPercent:  this.stepsSizesPercent});
                }, 60000); */
    });
  },
  beforeDestroy () {
    clearInterval(this.updating);
  },
  methods: {
    isLocationDone (index) {
      const location = this.locations[index + 1];
      if (location) {
        if ((location.driverOnSiteCheck > 0 && location.driverDoneCheck > 0) || location.dispatcherOnTheWayCheck > 0) {
          return true;
        }
      }
      return false;
    },
    getColor (index) {
      const location = this.locations[index + 1];
      /* if (location) {
        if ((location.driverOnSiteCheck > 0 && location.driverDoneCheck > 0) || location.dispatcherOnTheWayCheck > 0) {
          return '#4caf50';
        } else if (location.driverOnTheWayCheck > 0) {
          return '#ff8f00';
        }
      } */
      return this.$root.getLocationColor(location, index + 1);
    },
    getColorFromStringInHex (str) {
      let hash = 0;
      let i = 0;
      let color = '#';
      if (str.length === 0) return hash;

      for (i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
      }

      for (i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 255;
        color += ('00' + value.toString(16)).substr(-2);
      }
      return color;
    },

    calculateProgress () {
      if (this.locations.length < 2) return;

      this.stepsSizesPercent = [];
      let totalDuration = 0;
      // calc total time
      for (let i = 0; i < this.locations.length - 1; i++) {
        totalDuration += this.locations[i].duration;
      }
      // generate zones
      for (let i = 0; i < this.locations.length - 1; i++) {
        this.stepsSizesPercent.push(this.locations[i].duration / totalDuration * 100.0);
      }
      // calc progress
      this.currentProgress = 0;
      for (let i = 1; i < this.locations.length; i++) {
        const prevLocation = this.locations[i - 1];
        const location = this.locations[i];
        if (location.driverOnSiteCheck > 0 || location.driverDoneCheck > 0) {
          this.currentProgress += this.stepsSizesPercent[i - 1];
        } else if (location.driverOnTheWayCheck > 0) {
          let completedDuration = (location.currentDuration / prevLocation.duration);
          if (completedDuration > 1.0) completedDuration = 1.0;
          this.currentProgress += this.stepsSizesPercent[i - 1] - (this.stepsSizesPercent[i - 1] * completedDuration);

          /*console.log({
            totalDuration: totalDuration,
            stepsSizesPercent: this.stepsSizesPercent,
            currentProgress: this.currentProgress,
            currentDuration: location.currentDuration,
            duration: prevLocation.duration,
            completedDuration: (location.currentDuration / prevLocation.duration)
          });*/
        }
      }
    }
  }
};
</script>
