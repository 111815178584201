export default {
  data: function () {
  },
  methods: {
    isAccessRightAllowView (value) {
      let _r = this.getAccessRightsArrayFromNumber(value);
      if (_r[0] === '0' && _r[1] === '1') {
        return true;
      }
      return false;
    },
    isAccessRightAllowEdit (value) {
      let _r = this.getAccessRightsArrayFromNumber(value);
      if (_r[0] === '0' && _r[2] === '1') {
        return true;
      }
      return false;
    },
    isUsersListAllowed (role) {
      if (this.$store.getters.menuRights) {
        if (role) {
          return this.$root.isAccessRightAllowView(this.$store.getters.menuRights[role]) ||
            this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights[role]);
        } else {
          return this.$root.isAccessRightAllowView(this.$store.getters.menuRights['AllUsersList']) ||
            this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['AllUsersList']);
        }
      }
      return false;
    },
    isUsersListEditAllowed (role) {
      if (!this.$root.isOnline) return false;
      if (this.$store.getters.menuRights) {
        if (role) {
          return this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights[role]);
        } else {
          return this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['AllUsersList']);
        }
      }
      return false;
    },
    isDashboardAllowed () {
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.menuRights['Dashboard']) ||
          this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['Dashboard']);
      }
      return false;
    },
    isSettingsAllowed () {
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.menuRights['Settings']) ||
          this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['Settings']);
      }
      return false;
    },
    isAccessRightsEditAllowed () {
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['AccessRights']);
      }
    },
    isAccessRightsAllowed () {
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.menuRights['AccessRights']) ||
          this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['AccessRights']);
      }
      return false;
    },
    getAccessRightsArrayFromNumber (value) {
      if (value === 0) return ['0', '0', '0', '0', '0', '0', '0'];
      let bitsStr = parseInt(value).toString(2);
      while (bitsStr.length < 7) {
        bitsStr = '0' + bitsStr;
      }
      return bitsStr.split('');
    },
    rightNumberToUIRightsNumber (val) {
      if (val === 0) return 0;
      let ret = 0;
      let bits = this.getAccessRightsArrayFromNumber(val);
      if (bits[0] === '1' && bits[1] === '0' && bits[2] === '0' && bits[3] === '0') {
        ret = 0;
      } else if (bits[0] === '0' && bits[1] === '1' && bits[2] === '0' && bits[3] === '0') {
        ret = 1;
      } else if (bits[0] === '0' && bits[1] === '1' && bits[2] === '0' && bits[3] === '1') {
        ret = 2;
      } else if (bits[0] === '0' && bits[1] === '1' && bits[2] === '1' && bits[3] === '1') {
        ret = 3;
      }
      return ret;
    },
    genCategoryByTable (tableName, fieldName) {
      if (tableName === 'users') {
        if (fieldName.indexOf('administrator') >= 0) {
          return 'administrator';
        } else if (fieldName.indexOf('engineer') >= 0) {
          return 'engineer';
        } else if (fieldName.indexOf('driverOwnerOperator') >= 0) {
          return 'driverOwnerOperator';
        } else if (fieldName.indexOf('driver') >= 0) {
          return 'driver';
        } else if (fieldName.indexOf('dispatcher') >= 0) {
          return 'dispatcher';
        } else if (fieldName.indexOf('broker') >= 0) {
          return 'broker';
        } else if (fieldName.indexOf('accountingManager') >= 0) {
          return 'accountingManager';
        } else if (fieldName.indexOf('assistant') >= 0) {
          return 'assistant';
        }
      }
      return tableName;
    },
    getAccessRightsDisplayName (table, field) {
      if (table === 'leftMenu') {
        return this.$root.t('LeftMenu.' + field);
      } else if (table === 'trucks') {
        return this.$root.t('Trucks.Fields.' + field);
      } else if (table === 'settings') {
        return this.$root.t('User.Fields.' + field);
      } else if (table === 'reports') {
        return this.$root.t('Reports.' + field);
      } else if (table === 'loadsFeed') {
        return this.$root.t('LoadsFeed.' + field);
      } else if (table === 'users') {
        if (!this.$i18n.te('User.Fields.' + field)) {
          const _role = this.genCategoryByTable(table, field);
          return this.$root.t('User.Fields.' + field.substring(_role.length));
        }
        return this.$root.t('User.Fields.' + field);
      }
      return field;
      // if (this.$i18n.te('User.Fields.'+field) ? $t('User.Fields.'+item.displayName) : $t('User.Fields.'+item.displayName)
    },
    getAccessRightsByField (table, field) {
      return this.get('/api/accessRightsByField/' + table + '/' + field);
    },
    getAccessRightsByFields (table, fields) {
      return this.post('/api/accessRightsByFields', { table: table, fields: fields });
    },
    getAccessRights (type, name) {
      return this.get('/api/accessRights/' + type + '/' + name);
    },
    getUsersForAccessRights () {
      return this.get('/api/accessRights');
    },
    updateAccessRights (tableName, fieldName, type, name, value) {
      return this.post('/api/updateAccessRights', {
        tableName: tableName,
        fieldName: fieldName,
        type: type,
        name: name,
        value: value
      });
    },
    updateAccessRightsBatch (tableName, type, name, items) {
      return this.post('/api/updateAccessRightsBatch', {
        tableName: tableName,
        type: type,
        name: name,
        items: items
      });
    }
  }
};
