<template>
  <div>
    <v-expansion-panels v-if="$root.isMobile">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Loads ({{ Object.keys(loads).length }})
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <template v-for="load in loads">
            <v-simple-table style="width: 100%; border-bottom: 1px solid #000;" dense :key="'load' + load.id">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>{{$t('Shifts.Load')}}</td>
                    <td><a href="#" @click.stop="$root.onShowLoadPreview(load.id)">#{{load.id}}</a></td>
                  </tr>
                  <tr>
                    <td>{{$t('Loads.Dispatcher')}}</td>
                    <td>
                      <v-tooltip bottom :key="'shiftLoadDispatcher' + load.id">
                        <template v-slot:activator="{ on }">
                          <v-avatar v-on="on"
                                    style="cursor: pointer;"
                                    class="ml-1"
                                    :size="24"
                                    @click.stop="$root.onShowUserPreview(item.dispatcher, item.dispatcherName)"
                                    :color="(item.dispatcherInitials && !item.dispatcherAvatar)?'#015aaa':null">
                            <v-img :src="$root.getImageUrl('avatar', item.dispatcherAvatar)" v-if="item.dispatcherAvatar"></v-img>
                            <span class="white--text" v-else-if="item.dispatcherInitials">{{item.dispatcherInitials}}</span>
                            <v-icon large v-else>account_circle</v-icon>
                          </v-avatar>
                        </template>
                        <span>{{item.dispatcherName}}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                  <tr>
                    <td>{{$t('Loads.Started')}}</td>
                    <td>{{load.startedPST}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Loads.Ended')}}</td>
                    <td>{{load.endedPST}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Loads.Origin')}}</td>
                    <td>{{load.pickupLocation}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Loads.Destination')}}</td>
                    <td>{{load.deliveryLocation}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Loads.Broker')}}</td>
                    <td><a href="#" v-if="load.broker" @click.stop="$root.onShowUserPreview(load.broker)">{{load.brokerName}}</a></td>
                  </tr>
                  <tr>
                    <td>{{$t('Loads.Miles')}}</td>
                    <td>{{load.distanceInMiles}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Loads.Rate')}}</td>
                    <td>${{load.finished === 2 ? load.RateTONU : load.Rate}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Loads.RatePerMile')}}</td>
                    <td>${{load.RatePerMile}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Loads.Finished')}}</td>
                    <td>
                        <v-icon small :color="load.finished === 1 || load.finished === 2 ? 'success' : null">
                          {{load.finished === 0 ? 'mdi-truck-fast' : ''}}
                          {{load.finished === 1 ? 'mdi-check' : ''}}
                          {{load.finished === 2 ? 'mdi-flag-checkered' : ''}}
                          {{load.finished === 3 ? 'mdi-cancel' : ''}}
                        </v-icon>
                        {{load.finished === 0 ? $t('Loads.notFinishedStatus') : ''}}
                        {{load.finished === 1 ? $t('Loads.finishedStatus') : ''}}
                        {{load.finished === 2 ? $t('Loads.finishedWithTONUStatus') : ''}}
                        {{load.finished === 3 ? $t('Loads.Canceled') : ''}}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <v-layout>
                        <v-flex v-for="invoiceItem in load.invoicesArray"
                                shrink
                                class="pa-0"
                                :key="'inv'+invoiceItem.id">
                          <template v-if="invoiceItem.invoiceReceivedDate > 0 && $root.isInvoicesViewAllowed">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" :color="$root.getInvoiceTypeColorByStatus(invoiceItem.invoiceStatus)" @click.stop="$root.route('/invoices/page/'+invoiceItem.invoiceId)"><v-icon small>fa-file-invoice-dollar</v-icon></v-btn>
                              </template>
                              <span>{{$t('Loads.Invoice')}} #{{invoiceItem.invoiceNumber}} ${{invoiceItem.invoiceTotal}}&nbsp;<strong>{{invoiceItem.invoiceStatus}}</strong></span>
                            </v-tooltip>
                          </template>
                          <template v-else-if="!invoiceItem.invoiceReceivedDate && $root.isInvoicesViewAllowed">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" :color="invoiceItem.invoiceDueLeftSeconds < 0 ? 'red' : 'warning'" @click.stop="$root.route('/invoices/page/'+invoiceItem.invoiceId)"><v-icon small>fa-file-invoice-dollar</v-icon></v-btn>
                              </template>
                              <span v-if="invoiceItem.invoiceDueLeftSeconds < 0">{{$t('Loads.Invoice')}} #{{invoiceItem.invoiceNumber}} ${{invoiceItem.invoiceTotal}}&nbsp;{{$t('Invoices.Overdue')}}:&nbsp;-{{Math.ceil($root.secondsToHms(invoiceItem.invoiceDueLeftSeconds).hours / 24)}} d.</span>
                              <span v-if="invoiceItem.invoiceDueLeftSeconds > 0">{{$t('Loads.Invoice')}} #{{invoiceItem.invoiceNumber}} ${{invoiceItem.invoiceTotal}}&nbsp;{{$t('Invoices.DueDate')}}:&nbsp;+{{Math.ceil($root.secondsToHms(invoiceItem.invoiceDueLeftSeconds).hours / 24)}} d.</span>
                            </v-tooltip>
                          </template>
                        </v-flex>
                        <v-flex shrink v-if="!load.invoicesArray || load.invoicesArray.length === 0">
                          <v-btn icon disabled><v-icon small class="mr-1">fa-file-invoice-dollar</v-icon></v-btn>
                        </v-flex>
                        <v-flex shrink>
                          <template>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" @click.stop="onShowDocuments(load)">
                                  <v-icon small class="mr-1" :color="item.isAllRequiredDocsUploaded ? 'success' : 'warning'">mdi-file-multiple</v-icon>
                                </v-btn>
                              </template>
                              <span v-if="item.isAllRequiredDocsUploaded">{{$t('Loads.uploadDocuments')}}</span>
                              <span v-else>
                                    <template v-for="(md, index) in item.missingDocs">
                                      <span :key="'missingDocs_'+item.id+index" v-if="index === 'loadPhotos'">{{$t('Loads.LoadPhotos')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                                      <span :key="'missingDocs_'+item.id+index" v-if="index === 'rateCon'">{{$t('Loads.RateCon')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                                      <span :key="'missingDocs_'+item.id+index" v-if="index === 'do'">{{$t('Loads.DeliveryOrder')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                                      <span :key="'missingDocs_'+item.id+index" v-if="index === 'pod'">{{$t('Loads.POD')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                                      <span :key="'missingDocs_'+item.id+index" v-if="index === 'bol'">{{$t('Loads.BOL')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}</span>
                                    </template>
                                  </span>
                            </v-tooltip>
                          </template>
                        </v-flex>
                        <v-flex shrink>
                          <template>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" @click.stop="onShowExpenses(load.expensesJSON, load.finished, load.id)">
                                  <v-icon small class="mr-1" :color="load.isPendingExpenses ? 'warning' : null">fa-coins</v-icon>
                                </v-btn>
                              </template>
                              <span>{{!load.isPendingExpenses ? $t('Loads.ExpensesTitle') : $t('Loads.ExpensesHasPending')}}</span>
                            </v-tooltip>
                          </template>
                        </v-flex>
                        <v-flex shrink>
                          <template>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn small icon v-on="on" @click.stop="onShowFinancialData(load)">
                                  <v-icon small class="mr-1">mdi-finance</v-icon>
                                </v-btn>
                              </template>
                              <span>{{$t('Loads.FinancialData')}}</span>
                            </v-tooltip>
                          </template>
                        </v-flex>
                      </v-layout>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <template v-if="!$root.isMobile">
      <v-subheader><h4>Loads</h4></v-subheader>
      <v-simple-table style="width: 100%;" dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="pl-0">Load#</th>
            <th class="pl-0">{{$t('Loads.DispatcherShort')}}</th>
            <th class="pl-0">{{$t('Shifts.Trucks')}}</th>
            <th class="pl-0">{{$root.t('Shifts.Started')}} / {{$root.t('Shifts.Ended')}}</th>
            <th class="pl-0">{{$root.t('Shifts.Origin')}} / {{$root.t('Shifts.Finish')}}</th>
            <th class="pl-0">{{$t('Loads.Broker')}}</th>
            <th class="pl-0">{{$t('Loads.Miles')}}</th>
            <th class="pl-0">{{$t('Shifts.RatePerMile')}}</th>
            <th class="pl-0">{{$t('Shifts.DriversDetention')}}</th>
            <th class="pl-0">{{$t('Shifts.CompensationsShort')}}</th>
            <th class="pl-0">{{$t('Shifts.DeductionsShort')}}</th>
            <th class="pl-0">{{$t('Shifts.Bonuses')}}</th>
            <th class="pl-0">{{$t('Shifts.Salary')}}</th>
            <th class="pl-0">{{$t('Loads.Finished')}}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="load in loads" :key="'load' + load.id"
              @click="$root.onShowLoadPreview(load.id)">
            <td>#{{load.id}}</td>
            <td class="text-center">
              <v-tooltip bottom :key="'shiftLoadDispatcher' + load.id">
                <template v-slot:activator="{ on }">
                  <v-avatar v-on="on"
                            style="cursor: pointer;"
                            class="ml-1"
                            :size="24"
                            @click.stop="$root.onShowUserPreview(load.dispatcher, load.dispatcherName)"
                            :color="(load.dispatcherInitials && !load.dispatcherAvatar) ? '#015aaa' : null">
                    <v-img :src="$root.getImageUrl('avatar', load.dispatcherAvatar)" v-if="load.dispatcherAvatar"></v-img>
                    <span class="white--text" v-else-if="load.dispatcherInitials">{{load.dispatcherInitials}}</span>
                    <v-icon large v-else>account_circle</v-icon>
                  </v-avatar>
                </template>
                <span>{{load.dispatcherName}}</span>
              </v-tooltip>
            </td>
            <td>
              <template v-for="(truck, index) in load.trucksDistinctList">
                <a href="#" class="block" @click.stop="$root.onShowTruckPreview(truck.truckId, truck.truckName)" :key = "truck.truckName + '_' + index">{{truck.truckName}}</a>
                <br :key="truck.truckName + '_' + index + '_br'"/>
              </template>
            </td>
            <td class="text-no-wrap">
              {{load.startedPST}}<br/>
              {{load.endedPST}}
            </td>
            <td>
              <v-layout>
                <v-flex shrink>
                  <LoadLocationsTooltip :load-fields="load"/>
                </v-flex>
                <v-flex shrink>
                  <v-layout>
                    <v-flex grow>
                      <div class="text-no-wrap" style="max-width: 200px; text-overflow: ellipsis; overflow: hidden;">{{item.pickupLocation}}</div>
                    </v-flex>
                    <v-flex shrink>
                      <v-btn v-if="item.pickupLocation" small icon @click.stop="$root.copyToClipboard(item.pickupLocation)"><v-icon small>mdi-content-copy</v-icon></v-btn>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex grow>
                      <div class="text-no-wrap" style="max-width: 200px; text-overflow: ellipsis; overflow: hidden;">{{item.deliveryLocation}}</div>
                    </v-flex>
                    <v-flex shrink>
                      <v-btn v-if="item.deliveryLocation" small icon @click.stop="$root.copyToClipboard(item.deliveryLocation)"><v-icon small>mdi-content-copy</v-icon></v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex shrink class="">
                  <v-btn icon small @click.stop="$root.openGmapsDirections(load)"><v-icon small>mdi-directions</v-icon></v-btn><br/>
                  <v-btn icon small @click.stop="$emit('show-map-modal', load)"
                                    @mouseenter="(event) => { $emit('show-map-modal',load, true, event) }"
                                    @mouseout="() => { $root.floatingMap = false; }">
                    <v-icon small @mouseenter="(event) => { $emit('show-map-modal', load, true, event) }">mdi-map</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </td>
            <td><a href="#" v-if="load.broker" @click.stop="$root.onShowUserPreview(load.broker)">{{load.brokerName}}</a></td>
            <td>
              <v-icon small>mdi-map</v-icon>{{load.distanceInMiles}}{{ $t("milesShort") }}
              <v-layout>
                <v-flex shrink class="pt-1">
                  <v-icon small>mdi-counter</v-icon>{{load.loadsOdometerDistance}}{{ $t("milesShort") }}
                </v-flex>
                <v-flex shrink>
                  <OverriddenFieldsButton
                    :key="$root.overrideFieldKey"
                    :userId="driverId"
                    :itemType="$store.getters.overriddenFieldsTypes.LOADS"
                    :itemId="load.id"
                    prefix="mi."
                    fieldName="driversShiftDistance"
                    :currentNote="load.driversShiftDistanceNote"
                    :currentValue="load.loadsOdometerDistance"
                    :showCancel="load.isOverriddenLoadsOdometerDistance"
                    @change="$emit('fetch')"
                  />
                </v-flex>
              </v-layout>
            </td>
            <td>
              <!-- ${{load.finished === 2 ? load.RateTONU : load.Rate}} -->
              ${{load.RatePerMile}}
            </td>
            <td>
              <v-layout>
                <v-flex shrink class="pt-1">
                  {{item.driverDetentionFormatted}}
                </v-flex>
                <v-flex shrink>
                  <OverriddenFieldsButton
                    :key="$root.overrideFieldKey"
                    :userId="driverId"
                    :itemType="item.isShift ? $store.getters.overriddenFieldsTypes.SHIFTS : $store.getters.overriddenFieldsTypes.LOADS"
                    :itemId="item.id"
                    prefix="hh:mm"
                    :isTime="true"
                    fieldName="driverDetentionMinutes"
                    :currentNote="item.driverDetentionMinutesNote"
                    :currentValue="item.driverDetentionMinutes"
                    :showCancel="item.isOverriddenDriverDetentionMinutes"
                    @change="$emit('fetch')"
                  />
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex shrink class="pt-1">
                  ${{parseFloat(item.driverDetention.toFixed(2)).toLocaleString()}}
                </v-flex>
                <v-flex shrink>
                  <OverriddenFieldsButton
                    :key="$root.overrideFieldKey"
                    :userId="driverId"
                    :itemType="item.isShift ? $store.getters.overriddenFieldsTypes.SHIFTS : $store.getters.overriddenFieldsTypes.LOADS"
                    :itemId="item.id"
                    prefix="$"
                    fieldName="driversDetentionRate"
                    :currentNote="item.driversDetentionRateNote"
                    :currentValue="item.driverDetention"
                    :showCancel="item.isOverriddenDriverDetention"
                    @change="$emit('fetch')"
                  />
                </v-flex>
              </v-layout>
            </td>

            <td>
              ${{parseFloat(load.shiftToCompensate.toFixed(2)).toLocaleString()}}
            </td>
            <td>
              ${{parseFloat(load.shiftDeductions.toFixed(2)).toLocaleString()}}
            </td>
            <td>
              ${{parseFloat(load.shiftBonuses.toFixed(2)).toLocaleString()}}
            </td>
            <td>
              <div v-if="item.isPaid"
                 class="text-center">
              <v-layout>
                <v-flex>
                  <v-chip class="ma-1" small color="success">
                    <v-icon small left @click.stop="onPaid(item.id, driverId, 'DriversSalary', item.isShift ? $store.getters.overriddenFieldsTypes.SHIFTS : $store.getters.overriddenFieldsTypes.LOADS, item.paidAmount, item.paidDateTime, item.paidNote)">mdi-pencil</v-icon>
                    {{$t('Reports.Paid')}}&nbsp;${{parseInt(item.paidAmount).toLocaleString()}}
                    <v-icon small right color="error" @click.stop="resetPayment(driverId, item.isShift ? $store.getters.overriddenFieldsTypes.SHIFTS : $store.getters.overriddenFieldsTypes.LOADS, item.id, 'DriversSalary')">mdi-close</v-icon>
                  </v-chip>
                </v-flex>
                <v-flex v-if="item.isPaid && item.paidNote">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" small style="margin-top: 2px;"><v-icon>mdi-information</v-icon></v-btn>
                    </template>
                    <span>{{item.paidNote}}</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
              <div class="text-no-wrap">
                <label class="caption text-no-wrap">{{item.paidDateTime}}</label>
              </div>
            </div>
            <v-layout v-if="!item.isPaid" class="text-center">
              <v-flex>
                ${{item.toPayAmount.toLocaleString()}}
              </v-flex>
              <v-flex class="text-right pt-0" v-if="item.isVerified">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on"
                           class="ml-1"
                           x-small
                           @click.stop="onPaid(item.id, driverId, 'DriversSalary', item.isShift ? $store.getters.overriddenFieldsTypes.SHIFTS : $store.getters.overriddenFieldsTypes.LOADS, (item.isShift ? item.shiftRate : item.loadsOdometerRate) +  parseFloat(item.driverDetention || 0) + parseFloat(item.shiftToCompensate || 0) - parseFloat(item.shiftDeductions || 0) + parseFloat(item.shiftBonuses || 0), '')">
                      <v-icon small left color="success">mdi-currency-usd</v-icon>{{$t('Reports.Paid')}}
                    </v-btn>
                  </template>
                  <span>Mark as Paid</span>
                </v-tooltip>
              </v-flex>
            </v-layout>
            </td>
            <td>
              <v-icon small :color="load.finished === 1 || load.finished === 2 ? 'success' : null">
                  {{load.finished === 0 ? 'mdi-truck-fast' : ''}}
                  {{load.finished === 1 ? 'mdi-check' : ''}}
                  {{load.finished === 2 ? 'mdi-flag-checkered' : ''}}
                  {{load.finished === 3 ? 'mdi-cancel' : ''}}
                </v-icon>
                {{load.finished === 0 ? $t('Loads.notFinishedStatus') : ''}}
                {{load.finished === 1 ? $t('Loads.finishedStatus') : ''}}
                {{load.finished === 2 ? $t('Loads.finishedWithTONUStatus') : ''}}
                {{load.finished === 3 ? $t('Loads.Canceled') : ''}}
            </td>
            <td>
              <v-layout>
                <v-flex shrink class="pt-1">
                  <template>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn  v-on="on"
                                :loading="item.loading"
                                :disabled="!item.isChecked"
                                @click.stop="onReportToggleChecked(item, item.id, driverId, item.isShift ? $store.getters.overriddenFieldsTypes.SHIFTS : $store.getters.overriddenFieldsTypes.LOADS, 100)"
                                icon
                                small>
                          <v-icon :color="item.isVerified ? 'success': null">mdi-check-all</v-icon>
                        </v-btn>
                      </template>
                      <span>
                      {{item.isVerified ? ($t('Reports.MarkedAsVerified') + (item.verifiedDateTime ? ': ' + item.verifiedDateTime : '')) : $t('Reports.MarkAsVerified')}}
                    </span>
                    </v-tooltip>
                  </template>
                </v-flex>
                <v-flex shrink class="pt-1">
                  <template>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn  v-on="on"
                                :loading="item.loading"
                                @click.stop="onReportToggleChecked(item, item.id, driverId, item.isShift ? $store.getters.overriddenFieldsTypes.SHIFTS : $store.getters.overriddenFieldsTypes.LOADS)"
                                icon
                                small>
                          <v-icon small :color="item.isChecked ? 'success': null">mdi-check-decagram</v-icon>
                        </v-btn>
                      </template>
                      <span>
                      {{item.isChecked ? ($t('Reports.MarkedAsChecked') + (item.checkedDateTime ? ': ' + item.checkedDateTime : '')) : $t('Reports.MarkAsChecked')}}
                    </span>
                    </v-tooltip>
                  </template>
                </v-flex>

                <v-flex v-for="invoiceItem in load.invoicesArray"
                        shrink
                        class="pa-0"
                        :key="'inv'+invoiceItem.id">
                  <template v-if="invoiceItem.invoiceReceivedDate > 0 && $root.isInvoicesViewAllowed">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on"
                                    :color="$root.getInvoiceTypeColorByStatus(invoiceItem.invoiceStatus)"
                                    @click.stop="$root.onShowInvoicePreview (invoiceItem.invoiceId, invoiceItem.invoiceNumber)"><v-icon small>fa-file-invoice-dollar</v-icon></v-btn>
                      </template>
                      <span>{{$t('Loads.Invoice')}} #{{invoiceItem.invoiceNumber}} ${{invoiceItem.invoiceTotal}}&nbsp;<strong>{{invoiceItem.invoiceStatus}}</strong></span>
                    </v-tooltip>
                  </template>
                  <template v-else-if="!invoiceItem.invoiceReceivedDate && $root.isInvoicesViewAllowed">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on"
                               :color="invoiceItem.invoiceDueLeftSeconds < 0 ? 'red' : 'warning'"
                               @click.stop="$root.onShowInvoicePreview(invoiceItem.invoiceId, invoiceItem.invoiceNumber)"><v-icon small>fa-file-invoice-dollar</v-icon></v-btn>
                      </template>
                      <span v-if="invoiceItem.invoiceDueLeftSeconds < 0">{{$t('Loads.Invoice')}} #{{invoiceItem.invoiceNumber}} ${{invoiceItem.invoiceTotal}}&nbsp;{{$t('Invoices.Overdue')}}:&nbsp;-{{Math.ceil($root.secondsToHms(invoiceItem.invoiceDueLeftSeconds).hours / 24)}} d.</span>
                      <span v-if="invoiceItem.invoiceDueLeftSeconds > 0">{{$t('Loads.Invoice')}} #{{invoiceItem.invoiceNumber}} ${{invoiceItem.invoiceTotal}}&nbsp;{{$t('Invoices.DueDate')}}:&nbsp;+{{Math.ceil($root.secondsToHms(invoiceItem.invoiceDueLeftSeconds).hours / 24)}} d.</span>
                    </v-tooltip>
                  </template>
                </v-flex>
                <v-flex shrink v-if="!load.invoicesArray || load.invoicesArray.length === 0">
                  <v-btn icon disabled><v-icon small class="mr-1">fa-file-invoice-dollar</v-icon></v-btn>
                </v-flex>
                <v-flex shrink>
                  <template>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click.stop="$emit('on-show-documents', load)">
                          <v-icon small class="mr-1" :color="item.isAllRequiredDocsUploaded ? 'success' : 'warning'">mdi-file-multiple</v-icon>
                        </v-btn>
                      </template>
                      <span v-if="item.isAllRequiredDocsUploaded">{{$t('Loads.uploadDocuments')}}</span>
                      <span v-else>
                            <template v-for="(md, index) in item.missingDocs">
                              <span :key="'missingDocs_'+item.id+index" v-if="index === 'loadPhotos'">{{$t('Loads.LoadPhotos')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                              <span :key="'missingDocs_'+item.id+index" v-if="index === 'rateCon'">{{$t('Loads.RateCon')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                              <span :key="'missingDocs_'+item.id+index" v-if="index === 'do'">{{$t('Loads.DeliveryOrder')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                              <span :key="'missingDocs_'+item.id+index" v-if="index === 'pod'">{{$t('Loads.POD')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                              <span :key="'missingDocs_'+item.id+index" v-if="index === 'bol'">{{$t('Loads.BOL')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}</span>
                            </template>
                          </span>
                    </v-tooltip>
                  </template>
                </v-flex>
                <v-flex shrink>
                  <template>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click.stop="$emit('on-show-expenses', load.expensesJSON, load.finished, load.id)">
                          <v-icon small class="mr-1" :color="load.isPendingExpenses ? 'warning' : null">fa-coins</v-icon>
                        </v-btn>
                      </template>
                      <span>{{!load.isPendingExpenses ? $t('Loads.ExpensesTitle') : $t('Loads.ExpensesHasPending')}}</span>
                    </v-tooltip>
                  </template>
                </v-flex>
                <v-flex shrink>
                  <template>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn small icon v-on="on" @click.stop="$emit('on-show-financial-data', load)">
                          <v-icon small class="mr-1">mdi-finance</v-icon>
                        </v-btn>
                      </template>
                      <span>{{$t('Loads.FinancialData')}}</span>
                    </v-tooltip>
                  </template>
                </v-flex>
              </v-layout>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </div>
</template>

<script>
import OverriddenFieldsButton from '../../components/OverriddenFieldsButton';
import LoadLocationsTooltip from '../../components/LoadLocationsTooltip';
import reportsMixin from '../../mixins/reportsMixin.js';
export default {
  name: 'ShiftLoadsInfoTable',
  props: ['item', 'loads', 'driverId'],
  mixins: [ reportsMixin ],
  components: {
    OverriddenFieldsButton,
    LoadLocationsTooltip
  },
  data: function () {
    return {
    };
  },
  computed: {
  },
  mounted () {
    this.$root.overrideFieldCallback = () => this.$emit('fetch');
  },
  methods: {
  }
};
</script>
