<template>
  <div>
    <v-overlay :value="loading" :absolute="true">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <div>
      <v-list dence v-if="$root.isMobile" class="m-0">
        <v-list-item v-for="item in items" style="padding: 0px;"
                     @click="showReportsGroup(item.report_num, item.user_id, item.type_value, item.from_date, item.to_date, item.items_ids, item.id, item.isDOT)">
          <v-list-item-content>
            <v-list-item-title><b>#{{item.report_num}}</b> - {{item.createdPST}}</v-list-item-title>
            <br/>
            <v-list-item-subtitle>
              {{$root.formatDate(item.from_date)}} - {{$root.formatDate(item.to_date)}}
            </v-list-item-subtitle>
            <v-layout>
            <v-flex shrink v-if="item.items_number === item.countVerified || item.items_number === item.countPaid">
              <v-btn v-if="item.items_number !== item.countPaid" x-small class="mr-1 mt-1" @click.stop="onPaidReportGroup(item.id)">
                <v-icon small left color="success">mdi-currency-usd</v-icon>{{$t('Reports.Paid')}}
              </v-btn>
              <v-chip v-else small class="mr-1 mt-1" color="success"><v-icon small left>mdi-check</v-icon>{{ $t('Reports.Paid') }}</v-chip>
            </v-flex>
            <template v-if="type === 'truckMaintenance'">
              <v-flex shrink v-if="item.isDOT">
                <v-chip small color="success">{{ $t('DOTReport') }}</v-chip>
              </v-flex>
            </template>
            <template v-else>
              <v-flex shrink>
                <v-chip small class="mr-1 mt-1">{{item.countVerified}}<v-icon :color="item.items_number === item.countVerified ? 'success' : null" right small>mdi-check-all</v-icon></v-chip>
              </v-flex>
              <v-flex shrink>
                <v-chip small class="mr-1 mt-1">
                  {{item.countChecked}}<v-icon :color="item.items_number === item.countChecked ? 'success' : null" right small>mdi-check-decagram</v-icon>
                </v-chip>
              </v-flex>
            </template>
          </v-layout>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click.stop="showInfo(item)">
              <v-icon>mdi-information</v-icon>
            </v-btn>
            <v-layout>
              <v-flex shrink v-if="type === 'drivers'">
                <v-tooltip bottom v-if="$root.isOnline" class="ml-1">
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click.stop="onDownload(item)" class="ml-1"><v-icon>mdi-download</v-icon></v-btn>
                  </template>
                  <span>{{  $t('Download')}} </span>
                </v-tooltip>
              </v-flex>
              <!-- <v-flex shrink>
                <v-tooltip bottom v-if="$root.isOnline" class="ml-1">
                  <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on" @click.stop="onDelete(item)">
                      <v-icon color="error" v-html="parseInt(item.deleted) === 0 ? 'mdi-delete' : 'mdi-delete-off'">mdi-trash-can</v-icon>
                    </v-btn>
                  </template>
                  <span>{{parseInt(item.deleted) === 0 ? $t('Invoices.DeleteInvoice') : $t('Invoices.RestoreInvoice')}}</span>
                </v-tooltip>
              </v-flex> -->
            </v-layout>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-data-table
        v-else
        dense
        :key="'reportsKey' + items.length"
        :headers="headers"
        :items="items"
        :items-per-page="10"
        :footer-props="{'items-per-page-options': [10, 50, 100, 150, 200]}"
        @click:row="showReportsGroup($event.report_num, $event.user_id, $event.type_value, $event.from_date, $event.to_date, $event.items_ids, $event.id, $event.isDOT)"
      >
        <template v-slot:item.id="{ item }">
          <div class="text-no-wrap">#{{item.report_num}}</div>
        </template>
        <template v-slot:item.createdPST="{ item }">
          <span class="text-no-wrap">{{item.createdPST}}</span>
        </template>
        <template v-slot:item.from_date="{ item }">
          {{$root.formatDate(item.from_date)}}
        </template>
        <template v-slot:item.to_date="{ item }">
          {{$root.formatDate(item.to_date)}}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-layout>
            <v-flex grow>&nbsp;</v-flex>
            <v-flex shrink v-if="item.items_number === item.countVerified || item.items_number === item.countPaid">
              <v-btn v-if="item.items_number !== item.countPaid" x-small class="mr-1 mt-1" @click.stop="onPaidReportGroup(item.id)">
                <v-icon small left color="success">mdi-currency-usd</v-icon>{{$t('Reports.Paid')}}
              </v-btn>
              <v-chip v-else small class="mr-1 mt-1" color="success"><v-icon small left>mdi-check</v-icon>{{ $t('Reports.Paid') }}</v-chip>
            </v-flex>
            <template v-if="type === 'truckMaintenance'">
              <v-flex shrink v-if="item.isDOT">
                <v-chip small color="success">{{ $t('DOTReport') }}</v-chip>
              </v-flex>
            </template>
            <template v-else>
              <v-flex shrink>
                <v-chip small class="mr-1 mt-1">{{item.countVerified}}<v-icon :color="item.items_number === item.countVerified ? 'success' : null" right small>mdi-check-all</v-icon></v-chip>
              </v-flex>
              <v-flex shrink>
                <v-chip small class="mr-1 mt-1">
                  {{item.countChecked}}<v-icon :color="item.items_number === item.countChecked ? 'success' : null" right small>mdi-check-decagram</v-icon>
                </v-chip>
              </v-flex>
            </template>
            <v-flex shrink v-if="type === 'drivers'">
              <v-tooltip bottom v-if="$root.isOnline" class="ml-1">
                <template v-slot:activator="{ on }">
                  <v-btn icon small v-on="on" @click.stop="onDownload(item)" class="ml-1 mt-1"><v-icon>mdi-download</v-icon></v-btn>
                </template>
                <span>{{  $t('Download')}} </span>
              </v-tooltip>
            </v-flex>
            <v-flex shrink>
              <v-tooltip bottom v-if="$root.isOnline" class="ml-1">
                <template v-slot:activator="{ on }">
                  <v-btn icon small v-on="on" @click.stop="onDelete(item)">
                    <v-icon small color="error" v-html="parseInt(item.deleted) === 0 ? 'mdi-delete' : 'mdi-delete-off'">mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <span>{{parseInt(item.deleted) === 0 ? $t('Invoices.DeleteInvoice') : $t('Invoices.RestoreInvoice')}}</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="infoModal">
      <v-card>
        <v-card-title>
          {{ $t('Reports.Information') }}
          <v-spacer/>
          <v-btn icon @click="infoModal = false;">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list dense>
            <template v-for="hitem in headers">
              <v-list-item v-if="hitem.value !== 'report_num' && hitem.value !== 'createdPST' && hitem.value !== 'from_date' && hitem.value !== 'to_date' && hitem.text !== '#'">
                <v-list-item-title>{{hitem.text}}</v-list-item-title>
                <v-list-item-action class="text-no-wrap">{{infoItem[hitem.value]}}</v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import reportsMixin from '../../mixins/reportsMixin.js';
export default {
  name: 'ReportsGroupsList',
  props: ['userId', 'type', 'totalsCounts', 'totalsCountsNames', 'totalsCountsTypes'],
  components: { },
  mixins: [ reportsMixin ],
  data: function () {
    return {
      loading: false,
      items: [],
      headers: [],
      infoModal: false,
      infoItem: {}
    };
  },
  computed: {
  },
  mounted () {
    this.headers = [
      { text: '#', value: 'id' },
      { text: 'Created', value: 'createdPST' },
      { text: 'From', value: 'from_date' },
      { text: 'To', value: 'to_date' }
    ];
    for (let key in this.totalsCountsNames) {
      this.headers.push({ text: this.totalsCountsNames[key], value: key });
    }
    this.headers.push({ text: '', value: 'actions', sortable: false });
    this.fetchData();
  },
  methods: {
    showInfo(item) {
      this.infoItem = Object.assign({}, item);
      this.infoModal = true;
    },
    onDelete (item) {
      if (parseInt(item.deleted) === 0) {
        this.deleteReport(item.id).then(() => {
          this.fetchData();
        });
      } else {
        this.restoreReport(item.id).then(() => {
          this.fetchData();
        });
      }
    },
    showReportsGroup (number, userId, typeValue, fromDate, toDate, itemsIds, id, isDOT = false) {
      const selectedStr = JSON.parse(itemsIds).join(',');
      let path = '/reports/print/' + typeValue + '/' +
                                      this.$root.companyId + '/' +
                                      userId + '/' +
                                      fromDate.substring(0, 10) + '/' +
                                      toDate.substring(0, 10) + '/' +
                                      selectedStr + '/' +
                                      number + '/' +
                                      id;
      if (isDOT) {
        path += '/report/' + (isDOT ? '1' : '0');
      }
      window.open(path, '_blank');
    },
    onPaidReportGroup(reportId) {
      this.onPaidReport(reportId,
        this.userId,
        this.$store.getters['reports/savedReportsFieldName'],
        this.$store.getters['reports/savedReportsType']);
      /*this.$dialog.confirm(this.$root.t('Reports.MarkAsPaidWholeReportConfirmation')).then(dialog => {
        this.onPaidReport(reportId,
          this.userId,
          this.$store.getters['reports/savedReportsFieldName'],
          this.$store.getters['reports/savedReportsType']).then(response => {
          this.fetchData();
        });
      });*/
    },
    async onDownload(item) {
      const selectedStr = JSON.parse(item.items_ids).join(',');
      let userName = '';
      if (item.user_id > 0) {
        const userNameResponse = await this.$root.loadUserFullName(item.user_id);
        userName = userNameResponse.result.replace(' ', '_');
      }
      this.$root.currentReportFileName = (parseInt(item.user_id) > 0 ? userName : 'All Drivers') + '_' + this.$root.t('Report') + (parseInt(item.report_num) > 0 ? '_' + item.report_num : '') + '_';
      this.$root.currentReportFileName += item.from_date.substring(0, 10) + '_' + item.to_date.substring(0, 10).substring(0, 10);
      /*if (this.shifts && this.shifts.length === 1 && this.shifts[0].hasOwnProperty('startedPST')) {
        result += this.shifts[0].startedPST.substring(0, 10);
        this.$root.currentReportFileName += this.$root.parseDate(this.shifts[0].startedPST.substring(0, 10));
      }*/

      this.$root.onDownloadReport(item.type_value, item.user_id, item.from_date.substring(0, 10), item.to_date.substring(0, 10), selectedStr, item.report_num, item.id, false, false, true);
    },
    fetchData () {
      this.loading = true;
      this.getReportsGroupList(this.userId, this.type, this.isShowDeleted, this.$store.getters['reports/savedReportsFieldName']).then((response) => {
        let i = 0;
        let key = '';
        let item = {};
        let totalsCounts = {};

        if (response.status === 'ok') {
          this.items = [];
          this.currentDateTime = response.currentDateTime;
          for (i = 0; i < response.results.length; i++) {
            item = response.results[i];
            totalsCounts = JSON.parse(item['totals_counts']);
            item['items_number'] = JSON.parse(item['items_ids']).length;
            for (key in this.totalsCountsNames) {
              if (totalsCounts.hasOwnProperty(key)) {
                if (totalsCounts[key] === null || totalsCounts[key] === undefined) continue;
                item[key] = parseFloat(parseFloat(totalsCounts[key].toFixed(2))).toLocaleString();
                if (this.totalsCountsTypes && this.totalsCountsTypes.hasOwnProperty(key)) {
                  if (this.totalsCountsTypes[key] === 'Money') {
                    item[key] = '$' + item[key];
                  } else if (this.totalsCountsTypes[key] === 'Time') {
                    item[key] = this.$root.showDuration(totalsCounts[key] * 60, true);
                  } else if (this.totalsCountsTypes[key] === 'Miles') {
                    item[key] = item[key] + this.$root.t('milesShort');
                  }
                }
              }
            }
            this.items.push(item);
          }
        }
        this.loading = false;
      }).catch((e) => {
        console.error(e);
        this.loading = false;
      });
    }
  }
};
</script>
