<template>
  <div v-if="error">
    <v-flex class="text-center error pa-2">
      <h1 class="title">{{error}}</h1>
    </v-flex>
  </div>
  <v-container fluid class=pa-0 v-else>
    <v-overlay :value="loading" :absolute="true">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <template v-if="isReplyForm">
      <ReplyForm
        :msgId="msgId"
        :replySelectedSender="replySelectedSender"
        :senders_list="senders_list"
        :replyBody="replyBody"
        :replyEmailRecipient="replyEmailRecipient"
        :replyEmailCC="replyEmailCC"
        :replyEmailSubject="replyEmailSubject"
        @close = "() => { isReplyForm = false; }"
        @change="() => { isNewForm = false;$root.refresh(); }"
      />
      <v-divider/>
    </template>
    <v-card-text style="height: 80vh; overflow: auto;" class="pr-0">
      <v-layout wrap>
        <v-flex shrink class="ma-1">
          <v-btn icon class="float-right" @click="$emit('on-close')"><v-icon>mdi-arrow-left</v-icon></v-btn>
        </v-flex>
        <v-flex shrink class="ma-1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" @click.stop="isRead ? markAsUnRead() : markAsRead()" icon>
                <v-icon v-if="isRead">mdi-email-mark-as-unread</v-icon>
                <v-icon v-else>mdi-email-open</v-icon>
              </v-btn>
            </template>
            <!-- {{isRead ? $root.t('Mails.MarkAsUnreadTooltip') : $root.t('Mails.MarkAsReadTooltip')}} -->
          </v-tooltip>
        </v-flex>
        <v-flex shrink class="ma-1">
          <v-btn @click.stop="isReplyForm = true" class="ml-1" icon>
            <v-icon>mdi-reply</v-icon>
            <!-- {{$t("Mails.Reply")}} -->
          </v-btn>
        </v-flex>
        <v-flex shrink class="ma-1">
          <v-btn @click.stop="isForwardForm = !isForwardForm" class="ml-1" icon>
            <v-icon v-if="isForwardForm">mdi-close</v-icon>
            <v-icon v-else>mdi-forward</v-icon>
            <!-- {{$t("Mails.Forward")}} -->
          </v-btn>
        </v-flex>
        <v-flex grow v-if="!$root.isMobile"><h3 class="mt-3">{{subject}}</h3></v-flex>
      </v-layout>

      <h3 class="mt-3" v-if="$root.isMobile">{{subject}}</h3>

      <iframe class="text--primary mt-2" style="width: 100%; height: 85%; border: 0px;" :srcdoc="body"></iframe>
      <p v-if="files.length > 0">
        <v-subheader>{{$t('Mails.Attachments')}}</v-subheader>
        <v-layout wrap>
          <v-flex v-for="(file,index) in files" xs12 md4 class="text-center" :key="'files'+index">
            <img v-if="file.type.indexOf('image') >= 0"
                 class="v-responsive"
                 height="200px"
                 style="margin-bottom: 6px;"
                 v-img:mails
                 :src="$root.getMailAttUrl(recipient, sender, mailId, file.attachmentId, file.filename)"/>
            <template v-if="file.type.indexOf('pdf') >=0">
              <iframe v-if="isSafari || isMobile"
                      :src="$root.getMailAttUrl(recipient, sender, mailId, file.attachmentId, file.filename)"
                      width="100%"
                      height="200px">
              </iframe>
              <embed v-else
                     :src="$root.getMailAttUrl(recipient, sender, mailId, file.attachmentId, file.filename)"
                     width="100%"
                     height="200px" />
            </template>
            <v-btn :href="$root.getMailAttUrl(recipient, sender, mailId, file.attachmentId, file.filename, true)"
                   :download="file.filename"
                   class="mt-2" width="100%">
              <v-icon left v-if="file.type.indexOf('image') >= 0">mdi-image</v-icon>
              <v-icon left v-else-if="file.type.indexOf('pdf') >= 0">mdi-file-pdf</v-icon>
              <v-icon left v-else-if="file.type.indexOf('application') >= 0">mdi-file-document</v-icon>
              <v-icon left v-else>mdi-file</v-icon>
              {{file.filename}}
              <v-icon right>mdi-download</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </p>
    </v-card-text>
    <v-dialog v-model="isForwardForm"  width="500px">
      <v-card class="mx-auto">
        <v-card-title>
          <h4>{{$t('Mails.Forwarding')}}</h4>
          <v-spacer></v-spacer>
          <v-btn icon class="mr-4" @click="isForwardForm = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-layout>
            <v-flex xs10>
              <v-autocomplete
                width="100%"
                dense outlined
                item-text="name"
                item-value="name"
                multiple hide-details
                :search-input.sync="recipientsSearch"
                :disabled="isForwarding"
                v-model="forwardRecipient"
                :items="recipientsList"
                :label="$t('Mails.To')"
                :rules="[$root.rules.required]"
                autocomplete="nope"
                :no-data-text="$t('TypeToSearch')"
              ></v-autocomplete>
              <!--<v-combobox :label="$t('Mails.To')"
                        v-model="forwardRecipient"
                        width="100%"
                        :items="recipientsList.concat([replySelectedSender])"
                        :rules="[$root.rules.required]"
                        outlined dense multiple/>-->
            </v-flex>
            <v-flex xs2>
              <v-btn color="primary" class="ml-2" :loading="isForwarding" :disabled="isForwarding" @click="forwardMail(forwardRecipient, msgId)"><v-icon>mdi-send</v-icon></v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ReplyForm from './ReplyForm.vue';

export default {
  name: 'MailMessage',
  props: ['msgId', 'msgStatus'],
  components: { ReplyForm },
  data: function () {
    return {
      loading: true,
      error: null,
      pooling: null,
      sender: '',
      recipient: '',
      subject: '',
      body: '',
      status: '',
      quoteVisibility: true,
      isReplyForm: false,
      isForwardForm: false,
      isForwarding: false,
      forwardRecipient: '',
      replySelectedSender: '',
      replyEmailRecipient: '',
      replyEmailCC: '',
      replyEmailSubject: '',
      replyBody: '',
      recipientsSearch: null,
      recipientsList: [],
      senders_list: [],
      mailId: '',
      files: []
    };
  },
  watch: {
    recipientsSearch (val) {
      if (!val || val.length === 0) return;
      this.$root.recipientsSearch(val).then(response => {
        if (response.status === 'ok') {
          this.recipientsList = response.result;
        }
      });
    }
  },
  computed: {
    isRead () {
      return this.status === 'readed' || this.status === 'sent';
    }
  },
  mounted () {
    this.$nextTick(() => {
      let i = 0;
      this.$root.getBidsRecepientsList().then((response) => {
        if (response.status === 'ok') {
          this.recipientsList = response.result;
        }
      });

      this.$root.getLinkedEmailsByUser(this.$store.getters.currentUser['id']).then(response => {
        const linkedAccountsList = response.result;
        for (i = 0; i < linkedAccountsList.length; i++) {
          if (!this.$route.params.id && this.$store.getters.currentUser['email'] === linkedAccountsList[i].email) {
            this.selectedSender = linkedAccountsList[i].email;
          }
          this.senders_list.push(linkedAccountsList[i].email);
        }
      });

      this.status = this.msgStatus;
      // Mark as read if its not
      if (!this.isRead) {
        this.markAsRead();
      }
      this.fetchData();
    });
  },
  methods: {
    forwardMail (to, msgId) {
      this.isForwarding = true;
      this.$root.forwardMail(to, msgId).then((response) => {
        if (response.status === 'ok') {
          this.$root.toast(this.$i18n.t('Mails.ForwardedSuccessfully'), { color: 'success' });
          this.isForwardForm = false;
          this.forwardRecipient = '';
        } else {
          this.$root.toast(this.$i18n.t('Mails.ForwardedError'), { color: 'error' });
        }
        this.isForwarding = false;
      }).catch(e => {
        this.isForwarding = false;
      });
    },
    markAsRead () {
      this.status = 'readed';
      this.$root.setMessageAsRead(this.msgId);
      this.$emit('statusUpdated', this.status);
    },
    markAsUnRead () {
      this.status = 'unreaded';
      this.$root.setMessageAsUnRead(this.msgId);
      this.$emit('statusUpdated', this.status);
    },
    fetchData () {
      if (this.msgId) {
        this.$root.getMailById(this.msgId).then((response) => {
          if (response.status === 'ok') {
            this.mailId = response.result.mail_id;
            this.sender = response.result.from_field;
            this.recipient = response.result.to_field;
            this.replySelectedSender = this.$root.getEmailFromEmailHeaderFormat(response.result.to_field);
            this.replyEmailRecipient = [response.result.from_field];
            this.replyEmailCC = response.result.cc_field;
            this.subject = response.result.subject_field;
            this.replyBody = '';
            this.replyEmailSubject = this.subject;
            if (this.subject && this.subject.indexOf('Re:') < 0) {
              this.replyEmailSubject = 'Re: ' + this.subject;
            }
            this.body = response.result.body;//.replace(/(?:\r\n|\r|\n)/g, '<br/>');
            if (response.result.files) {
              this.files = JSON.parse(response.result.files);
            }
          } else {
            this.body = response.msg;
          }

          this.loading = false;
        });
      }
    }
  }
};

</script>
