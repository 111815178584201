<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <template v-if="error">
        <v-chip v-on="on" class="ma-1" :large="large ? large : null" :small="small ? small : null" outlined color="error">
          <v-icon left :large="largeIcon ? largeIcon : null" :small="smallIcon ? smallIcon : null">{{icon}}</v-icon>
          Error
        </v-chip>
      </template>
      <template v-else>
        <v-chip v-on="on" class="ma-1" :large="large ? large : null" :small="small ? small : null" outlined :color="color" @click="onCopyToClipboard">
          <v-icon left :large="largeIcon ? largeIcon : null" :small="smallIcon ? smallIcon : null">{{icon}}</v-icon>
          {{text}}
        </v-chip>
      </template>
    </template>
    <span>{{tooltip}}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: [ 'tooltip', 'icon', 'text', 'largeIcon', 'smallIcon', 'small', 'large', 'color', 'error' ],
  data () {
    return {
      uid: 0
    };
  },

  mounted () {
    this.uid = new Date().getTime();
  },

  methods: {
    onCopyToClipboard () {
      if (this.text && this.text.length > 0) {
        this.$root.copyToClipboard(this.text);
      }
    }
  }
};
</script>
