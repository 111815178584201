<template>
  <v-list-item v-if="truckData.id">
    <v-list-item-avatar v-if="truckData.truckAvatarUPLOAD">
      <v-avatar tile
                :size="32"
                @click="$router.push('/trucks/page/'+truckData.id)">
        <v-img :src="$root.getTruckFirstUrl(truckData.truckAvatarUPLOAD)" v-if="truckData.truckAvatarUPLOAD"></v-img>
      </v-avatar>
    </v-list-item-avatar>
    <v-list-item-content class="text-no-wrap">
      <v-list-item-title>
          <a @click.stop="$root.route('/trucks/page/' + truckData.id)">{{truckData.name}}</a>
      </v-list-item-title>
      <v-list-item-subtitle v-if="odometerValue">
        <v-icon>mdi-counter</v-icon>&nbsp;{{odometerValue}}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'TruckSnippet',
  props: ['truckData', 'odometerValue'],
  data: function () {
    return {
    };
  },
  computed: {
  },
  mounted () {
  },
  methods: {

  }
};
</script>
