<template>
  <div>
    <v-simple-table dense class="ma-1" v-if="fields && fields.id">
      <template v-slot:default>
        <tbody>
        <tr>
          <td>{{$t('Trucks.Fields.isTrailer')}}</td>
          <td :style="{ fontWeight: 600, color: fields.isTrailer ? yesColor : noColor }">{{fields.isTrailer ? $t('Yes') : $t('No')}}</td>
        </tr>
        <tr v-if="fields.isTrailer">
          <td>{{$t('Trucks.Fields.trailerType')}}</td>
          <td>{{fields.trailerType ? fields.trailerType : ' - '}}</td>
        </tr>
        <tr v-if="!fields.isTrailer">
          <td>{{$t('Trucks.Fields.type')}}</td>
          <td>{{fields.type ? fields.type : ' - '}}</td>
        </tr>
        <tr v-if="fields.isTrailer">
          <td>{{$t('Trucks.Fields.linkedTruckId')}}</td>
          <td v-if="fields.linkedTruckId"><a href="" @click.stop="$root.route('/trucks/page/' + fields.linkedTruckId)">{{linkedTruckName}}</a></td>
          <td v-else>&nbsp;-&nbsp;</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.truckFleetNumber')}}</td>
          <td>{{fields.truckFleetNumber ? fields.truckFleetNumber : ' - '}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.licensePlate')}}</td>
          <td>{{fields.licensePlate ? fields.licensePlate : ' - '}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.year')}}</td>
          <td>{{fields.year ? fields.year : ' - '}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.model')}}</td>
          <td>{{fields.model ? fields.model : ' - '}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.usdot')}}</td>
          <td>{{fields.usdot ? fields.usdot : ' - '}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.owner')}}</td>
          <td>{{fields.owner ? fields.owner : ' - '}}</td>
        </tr>

        <tr>
          <td>{{$t('Trucks.Fields.vin')}}</td>
          <td>{{fields.vin ? fields.vin : ' - '}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.lastAnnualInspectionDATE')}}</td>
          <td>
            {{fields.lastAnnualInspectionMDY ? fields.lastAnnualInspectionMDY : ' - '}}
            <span v-if="fields.lastAnnualInspectionMDY"  :style="{ fontWeight: 600, color: $root.daysLeftToNextAnnualInspection(fields.lastAnnualInspectionDATE, globalSettingsFields.annualInspectionIntervalInDays) <= 0 ? noColor : yesColor }">
              ({{lastAnnualInspectionDaysLeft}})
            </span>
          </td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.registrationCardExpiredDATE')}}</td>
          <td>
            {{fields.registrationCardExpiredMDY ? fields.registrationCardExpiredMDY : ' - '}}
            <span v-if="fields.registrationCardExpiredMDY" :style="{ fontWeight: 600, color: $root.isDateExpired(fields.registrationCardExpiredDATE) ? noColor : yesColor }">
              ({{$root.daysLeft(fields.registrationCardExpiredDATE)}})
            </span>
          </td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.insuranceExpiredDATE')}}</td>
          <td>
            {{fields.insuranceExpiredMDY ? fields.insuranceExpiredMDY : ' - '}}
            <span v-if="fields.insuranceExpiredMDY" :style="{ fontWeight: 600, color: $root.isDateExpired(fields.insuranceExpiredDATE) ? noColor : yesColor }">
              ({{$root.daysLeft(fields.insuranceExpiredDATE)}})
            </span>
          </td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.nextOilReplacementIntervalMiles')}}</td>
          <td>{{fields.nextOilReplacementIntervalMiles ? parseInt(fields.nextOilReplacementIntervalMiles).toLocaleString() : ' - '}} {{$t('milesShort')}}</td>
        </tr>

       <!-- <tr>
          <td colspan="2">
            <v-subheader>
              {{$t('Trucks.Oil')}}
            </v-subheader>
          </td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.lastOilReplacementDATE')}}</td>
          <td>
            {{fields.lastOilReplacementMDY ? fields.lastOilReplacementMDY : ' - '}}
          </td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.lastOilReplacementMiles')}}</td>
          <td>{{fields.lastOilReplacementMiles ? parseInt(fields.lastOilReplacementMiles).toLocaleString() : ' - '}} {{$t('milesShort')}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.nextOilReplacementIntervalMiles')}}</td>
          <td>{{fields.nextOilReplacementIntervalMiles ? parseInt(fields.nextOilReplacementIntervalMiles).toLocaleString() : ' - '}} {{$t('milesShort')}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.oilReplacementAfterMiles')}}</td>
          <td>
            {{nextOilChangeOdometerMiles}} {{$t('milesShort')}}
            <span :style="{ fontWeight: 600, color: isOilChangeWarning ? noColor : yesColor }">
              ({{$tc('User.MilesLeft', parseInt(parseInt(fields.lastOilReplacementMiles) + parseInt(fields.nextOilReplacementIntervalMiles) - parseInt(fields.miles)).toLocaleString())}})
            </span>
          </td>
        </tr>-->
        <tr>
          <td colspan="2">
            <v-subheader>
              {{$t('Trucks.Properties')}}
            </v-subheader>
          </td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.miles')}}</td>
          <td>{{fields.miles ? fields.miles.toLocaleString() : ' - '}} {{$t('milesShort')}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.length')}}</td>
          <td>{{fields.length ? fields.length : ' - '}} {{$t('feetShort')}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.dimensions')}}</td>
          <td>{{fields.dimensionsL ? fields.dimensionsL : '-'}} x {{fields.dimensionsW ? fields.dimensionsW : '-'}} x {{fields.dimensionsH ? fields.dimensionsH : '-'}} {{$t('inchShort')}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.dimensionsDetail')}}</td>
          <td>{{fields.dimensionsDetailW ? fields.dimensionsDetailW : ' - '}} x {{fields.dimensionsDetailH ? fields.dimensionsDetailH : ' - '}} {{$t('inchShort')}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.tireSize')}}</td>
          <td>{{fields.tireSize ? fields.tireSize : ' - '}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.emptyScaleWeight')}}</td>
          <td>{{fields.emptyScaleWeight ? fields.emptyScaleWeight : ' - '}} {{$t('poundShort')}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.emptyScaleTicketUPLOAD')}}</td>
          <td v-if="fields.emptyScaleTicketUPLOAD && fields.emptyScaleTicketUPLOAD.indexOf('[') === 0 && JSON.parse(fields.emptyScaleTicketUPLOAD).length > 0">
            <v-icon x-small left>fa-link</v-icon>
            <a :href="$root.getImageUrl('emptyScaleTicket',JSON.parse(fields.emptyScaleTicketUPLOAD)[0].hash)" target="_blank">{{JSON.parse(fields.emptyScaleTicketUPLOAD)[0].name}}</a>
          </td>
          <td v-else> - </td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.distanceBetween')}}</td>
          <td>{{fields.distanceBetween ? fields.distanceBetween : ' - '}} {{$t('inchShort')}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.maxLoadWeight')}}</td>
          <td>{{fields.maxLoadWeight ? fields.maxLoadWeight : ' - '}} {{$t('poundShort')}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.strapsQuantity')}}</td>
          <td>{{fields.strapsQuantity ? fields.strapsQuantity : ' - '}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.blanketsQuantity')}}</td>
          <td>{{fields.blanketsQuantity ? fields.blanketsQuantity : ' - '}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.eTrackQuantity')}}</td>
          <td>{{fields.eTrackQuantity ? fields.eTrackQuantity : ' - '}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.loadBarsQuantity')}}</td>
          <td>{{fields.loadBarsQuantity ? fields.loadBarsQuantity : ' - '}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.spareWheels')}}</td>
          <td>{{fields.spareWheels ? fields.spareWheels : ' - '}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.snowChains')}}</td>
          <td>{{fields.snowChains ? fields.snowChains : ' - '}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.sleeperSpace')}}</td>
          <td :style="{ fontWeight: 600, color: fields.sleeperSpace ? yesColor : noColor }">{{fields.sleeperSpace ? $t('Yes') : $t('No')}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.liftGate')}}</td>
          <td :style="{ fontWeight: 600, color: fields.liftGate ? yesColor : noColor }">{{fields.liftGate ? $t('Yes') : $t('No')}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.palletJack')}}</td>
          <td :style="{ fontWeight: 600, color: fields.palletJack ? yesColor : noColor }">{{fields.palletJack ? $t('Yes') : $t('No')}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.airRide')}}</td>
          <td :style="{ fontWeight: 600, color: fields.airRide ? yesColor : noColor }">{{fields.airRide ? $t('Yes') : $t('No')}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.iccBars')}}</td>
          <td :style="{ fontWeight: 600, color: fields.iccBars ? yesColor : noColor }">{{fields.iccBars ? $t('Yes') : $t('No')}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.reefer')}}</td>
          <td :style="{ fontWeight: 600, color: fields.reefer ? yesColor : noColor }">{{fields.reefer ? $t('Yes') : $t('No')}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.isSwingDoors')}}</td>
          <td :style="{ fontWeight: 600, color: fields.isSwingDoors ? yesColor : noColor }">{{fields.isSwingDoors ? $t('Yes') : $t('No')}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.isRollupDoors')}}</td>
          <td :style="{ fontWeight: 600, color: fields.isRollupDoors ? yesColor : noColor }">{{fields.isRollupDoors ? $t('Yes') : $t('No')}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.isVented')}}</td>
          <td :style="{ fontWeight: 600, color: fields.isVented ? yesColor : noColor }">{{fields.isVented ? $t('Yes') : $t('No')}}</td>
        </tr>
        <tr>
          <td>{{$t('Trucks.Fields.notes')}}</td>
          <td v-html="fields.notes ? $root.newLineToBR(fields.notes) : ' - '"></td>
        </tr>
        <tr>
          <td>{{$t('Trucks.MaintenanceInformation')}}</td>
          <td v-html="fields.maintenanceNote ? $root.newLineToBR(fields.maintenanceNote) : ' - '"></td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>

export default {
  name: 'TruckInformation',
  props: ['fields'],
  computed: {
    /*nextOilChangeOdometerMiles () {
      if (this.fields.lastOilReplacementMiles && this.fields.nextOilReplacementIntervalMiles) {
        return parseInt(parseInt(this.fields.lastOilReplacementMiles) + parseInt(this.fields.nextOilReplacementIntervalMiles)).toLocaleString();
      }
      return ' - ';
    },
    isOilChangeWarning () {
      const milesLeft = parseInt(this.fields.lastOilReplacementMiles) + parseInt(this.fields.nextOilReplacementIntervalMiles) - parseInt(this.fields.miles);
      return milesLeft <= parseInt(this.globalSettingsFields.oilReplacementWarningWhenMilesLeft)
    },*/
    lastAnnualInspectionDaysLeft () {
      // console.error(this.$root.getTimestampFromDateTimeString('2022-01-01 07:46:00'));
      const value = this.$root.daysLeftToNextAnnualInspection(this.fields.lastAnnualInspectionDATE, this.globalSettingsFields.annualInspectionIntervalInDays);
      if (value >= 0) {
        return this.$t('User.DaysLeft', { n: value });
      }
      return this.$t('User.DaysOverdue', { n: Math.abs(value) });
    }
  },
  data: function () {
    return {
      yesColor: '#4caf50',
      noColor: '#f44336',
      linkedTruckName: '',
      globalSettingsFields: {}
    };
  },
  watch: {
  },
  mounted () {
    this.$root.loadGlobalSettingsFields().then((response) => {
      if (response.status === 'ok') {
        this.globalSettingsFields = {};
        response.result.forEach((item) => {
          this.globalSettingsFields[item.name] = item.value;
        });
      }
    });
    if (this.fields.linkedTruckId > 0) {
      this.$root.getTruck(this.fields.linkedTruckId).then((response) => {
        if (response.status === 'ok') {
          this.linkedTruckName = response.result.name;
        }
      });
    }
  },
  methods: {
  }

};
</script>
