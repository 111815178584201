<template>
  <v-card>
    <v-card-text class="mx-auto" elevation="0" xs-12>
      <v-card-title v-if="isInModal">
        {{$t('Trucks.FaultCodes')}} - {{truckData.name}}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-title v-else>
        {{$t('Trucks.FaultCodes')}}
      </v-card-title>
      <v-card-text style="max-height: 500px; overflow-y: auto;">
          <v-simple-table dense>
            <thead>
            <tr>
              <th class="pa-0">Fault Code</th>
              <th class="pa-0">FMI</th>
              <th class="pa-0">Description</th>
              <th class="pa-0">Source</th>
              <th class="pa-0">First Detected</th>
              <th class="pa-0">Last Observer</th>
              <th class="pa-0">Type</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="item in faultCodes">
                <td>{{item.fault_code.code_label}}</td>
                <td>{{item.fault_code.fmi}}</td>
                <td>{{item.fault_code.code_description}}</td>
                <td>{{item.fault_code.source_address_label}}</td>
                <td>
                  {{$moment.moment(item.fault_code.first_observed_at).utcOffset($store.getters.timezoneOffsetMinutes).format('MM/DD/YYYY')}}
                </td>
                <td>
                  {{$moment.moment(item.fault_code.last_observed_at).utcOffset($store.getters.timezoneOffsetMinutes).format('MM/DD/YYYY')}}
                </td>
                <td>{{item.fault_code.type}}</td>
              </tr>
            </tbody>
          </v-simple-table>
      </v-card-text>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'TruckFaultCodes',
  props: ['faultCodes', 'truckData', 'isInModal'],
  computed: {
  },
  data: function () {
    return {
    };
  },
  watch: {
  },
  mounted () {
  },
  methods: {
  }

};
</script>
