/*import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import UserSettings from './views/User/Settings.vue';
import UserPage from './views/User/Page.vue';
import UserCreate from './views/User/CreateNew.vue';
import ChartCreateEditForm from './views/Charts/CreateEditForm.vue';
import ChartList from './views/Charts/List.vue';
import UsersList from './views/User/List.vue';
import Dashboard from './views/Dashboard.vue';
import CompanyDocuments from './views/CompanyDocuments.vue';
import AccessRights from './views/AccessRights.vue';
import LogsList from './views/LogsList.vue';
import GlobalSettings from './views/GlobalSettings.vue';
import TrucksList from './views/Trucks/List.vue';
import TruckPage from './views/Trucks/Page.vue';
import TruckCreateEditForm from './views/Trucks/CreateEditForm.vue';
import BidCreateEditForm from './views/Bids/CreateEditForm.vue';
import LoadCreateEditForm from './views/Loads/CreateEditForm.vue';
import InvoiceCreateEditForm from './views/Invoices/CreateEditForm.vue';
import InvoicePage from './views/Invoices/Page.vue';
import InvoicesList from './views/Invoices/List.vue';
import BidsList from './views/Bids/List.vue';
import LoadsList from './views/Loads/List.vue';
import DriverLoadsList from './views/Loads/DriverLoadsList.vue';
import LoadPage from './views/Loads/Page.vue';
import LoadsCalendar from './components/LoadsCalendar.vue';
import DriverLoadPageOld from './views/Loads/DriverLoadPage.vue';
import DriverLoadPage from './views/Loads/DriverLoadPage/Page.vue';
import MailsList from './views/Mails/List.vue';
import MailsPage from './views/Mails/Page.vue';
import ReportIndex from './views/Reports/Index.vue';
import ReportPrint from './views/Reports/ReportPrint.vue';
import NotificationsList from './views/Notifications/List.vue';
import ShiftsList from './views/Shifts/List.vue';
import ShiftsListByTrucks from './views/Shifts/ListByTrucks.vue';
import DriverShiftsList from './views/Shifts/DriversList.vue';
import driverShift from './views/Loads/DriverLoadPage/ShiftV2.vue';
import NotFoundPage from './views/Errors/404.vue';
import Login from './views/Login.vue';
import ChatsList from './views/Chat/List.vue';
import TrucksCalendar from './views/Dashboard/CalendarWidget';
import TaskCreateEditForm from './views/Tasks/CreateEditForm.vue';
import TaskPage from './views/Tasks/Page.vue';
import TaskList from './views/Tasks/List.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/login', name: 'login', component: Login },
    {
      path: '/',
      name: 'app',
      component: Home,
      children: [
        { path: 'user/dashboard', name: 'userDashboard', component: Dashboard },
        { path: 'user/settings', name: 'userSettings', component: UserSettings },
        { path: 'user/page', name: 'myPage', component: UserPage },
        { path: 'user/page/:id', name: 'userPage', component: UserPage },
        { path: 'user/edit/:id', name: 'userEdit', component: UserSettings },
        { path: 'user/create/:role?', name: 'userCreate', component: UserCreate },
        { path: 'chart/list', name: 'chartList', component: ChartList },
        { path: 'chart/create', name: 'chartCreate', component: ChartCreateEditForm },
        { path: 'chart/edit/:id', name: 'chartEdit', component: ChartCreateEditForm },
        { path: 'users', name: 'users', component: UsersList },
        { path: 'users/:role', name: 'usersRole', component: UsersList, props: true },
        { path: 'company_documents', name: 'companyDocuments', component: CompanyDocuments },
        { path: 'settings/access_rights', name: 'accessRights', component: AccessRights },
        { path: 'logs_list', name: 'logsList', component: LogsList },
        { path: 'settings/global', name: 'globalSettings', component: GlobalSettings },
        { path: 'trucks/list', name: 'trucksList', component: TrucksList },
        { path: 'trucks/page/:id', name: 'truckPage', component: TruckPage },
        { path: 'trucks/create', name: 'truckCreate', component: TruckCreateEditForm },
        { path: 'trucks/edit/:id', name: 'truckEdit', component: TruckCreateEditForm },
        { path: 'trucks/calendar', name: 'TrucksCalendar', component: TrucksCalendar },
        { path: 'bids/list', name: 'bidsList', component: BidsList },
        { path: 'bids/create', name: 'bidCreate', component: BidCreateEditForm },
        { path: 'bids/edit/:id', name: 'bidEdit', component: BidCreateEditForm },
        { path: 'loads/list', name: 'loadsList', component: LoadsList },
        { path: 'driverLoads/list/:date?', name: 'driverLoadsList', component: DriverLoadsList },
        { path: 'loads/create/:bidId/:brokerId?/:newEmail?', name: 'loadCreate', component: LoadCreateEditForm },
        { path: 'loads/edit/:id', name: 'loadEdit', component: LoadCreateEditForm },
        { path: 'loads/page/:id', name: 'loadPage', component: LoadPage },
        { path: 'loads/driverPageOld/:id', name: 'driverLoadPageOld', component: DriverLoadPageOld },
        { path: 'loads/driverLoads/shifts/:id/:isModal?', name: 'driverShift', component: driverShift },
        { path: 'loads/driverPage/:id', name: 'driverLoadPage', component: DriverLoadPage },
        { path: 'loads/calendar', name: 'LoadsCalendar', component: LoadsCalendar },
        { path: 'invoices/list', name: 'invoicesList', component: InvoicesList },
        { path: 'invoices/create', name: 'invoiceCreate', component: InvoiceCreateEditForm },
        { path: 'invoices/create/:loadId', name: 'invoiceCreateFromLoad', component: InvoiceCreateEditForm },
        { path: 'invoices/createNewVersion/:parentInvoiceId', name: 'invoiceCreateNewVersionFromInvoice', component: InvoiceCreateEditForm },
        { path: 'invoices/edit/:id', name: 'invoiceEdit', component: InvoiceCreateEditForm },
        { path: 'invoices/page/:id', name: 'invoicePage', component: InvoicePage },
        { path: 'invoices/print/:id', name: 'invoicePrint', component: InvoicePage },
        { path: 'mails/list/:user?', name: 'mailsList', component: MailsList },
        { path: 'mails/page/:msgId/:threadId?', name: 'mailPage', component: MailsPage },
        { path: 'reports', name: 'reports', component: ReportIndex },
        { path: 'reports/:type?', name: 'reportsByType', component: ReportIndex },
        { path: 'reports/:type?/:item?', name: 'reportsByTypeAndItem', component: ReportIndex },
        { path: 'reports/print/:type/:item/:from/:to/:selected?/:report_number?', name: 'reportPrint', component: ReportPrint },
        { path: 'notifications/list', name: 'NotificationsList', component: NotificationsList },
        { path: 'shifts/listByDrivers/:shiftId?/:driver?', name: 'ShiftsListByDrivers', component: ShiftsList },
        { path: 'shifts/listByTrucks/:showRoutes?/:truck?', name: 'ShiftsListByTrucks', component: ShiftsListByTrucks },
        { path: 'shifts/driverList/:isModal?', name: 'DriverShiftsList', component: DriverShiftsList },
        { path: 'chats/list/:threadId?/:userThreadId?', name: 'ChatsList', component: ChatsList },
        { path: 'tasks/create/:itemType?/:itemId?', name: 'taskCreate', component: TaskCreateEditForm },
        { path: 'tasks/edit/:id', name: 'TaskEdit', component: TaskCreateEditForm },
        { path: 'tasks/page/:id', name: 'TaskPage', component: TaskPage },
        { path: 'tasks/list', name: 'TaskList', component: TaskList },
        { path: ':pathMatch(.*)*', name: 'defaultPath', component: NotFoundPage }
      ]
    },
  ]
});

export default router;

*/

import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/login', name: 'login', component: () => import('./views/Login.vue') },
    { path: '/driverRegistration', name: 'driverRegistration', component: () => import('./views/DriverRegistration.vue') },
    {
      path: '/',
      name: 'app',
      component: Home,
      children: [
        { path: 'OSM', name: 'OSM', component: () => import('./views/OSM.vue') },
        { path: 'user/dashboard', name: 'userDashboard', component: () => import('./views/Dashboard.vue') },
        { path: 'user/settings', name: 'userSettings', component: () => import('./views/User/Settings.vue') },
        { path: 'user/page', name: 'myPage', component: () => import('./views/User/Page.vue') },
        { path: 'user/page/:id', name: 'userPage', component: () => import('./views/User/Page.vue') },
        { path: 'user/edit/:id', name: 'userEdit', component: () => import('./views/User/Settings.vue') },
        { path: 'user/create/:role?', name: 'userCreate', component: () => import('./views/User/CreateNew.vue') },
        { path: 'chart/list', name: 'chartList', component: () => import('./views/Charts/List.vue') },
        { path: 'chart/create', name: 'chartCreate', component: () => import('./views/Charts/CreateEditForm.vue') },
        { path: 'chart/edit/:id', name: 'chartEdit', component: () => import('./views/Charts/CreateEditForm.vue') },
        { path: 'users', name: 'users', component: () => import('./views/User/List.vue') },
        { path: 'users/:role', name: 'usersRole', component: () => import('./views/User/List.vue'), props: true },
        { path: 'company_documents', name: 'companyDocuments', component: () => import('./views/CompanyDocuments.vue') },
        { path: 'settings/access_rights', name: 'accessRights', component: () => import('./views/AccessRights.vue') },
        { path: 'logs_list', name: 'logsList', component: () => import('./views/LogsList.vue') },
        { path: 'settings/global', name: 'globalSettings', component: () => import('./views/GlobalSettings.vue') },
        { path: 'trucks/list/:isTrailer?', name: 'trucksList', component: () => import('./views/Trucks/List.vue') },
        { path: 'trucks/page/:id', name: 'truckPage', component: () => import('./views/Trucks/Page.vue') },
        { path: 'trucks/create/:isTrailer?', name: 'truckCreate', component: () => import('./views/Trucks/CreateEditForm.vue') },
        { path: 'trucks/edit/:id', name: 'truckEdit', component: () => import('./views/Trucks/CreateEditForm.vue') },
        { path: 'trucks/calendar', name: 'TrucksCalendar', component: () => import('./views/Dashboard/CalendarWidget') },
        { path: 'bids/list/:showOnlyToday?', name: 'bidsList', component: () => import('./views/Bids/List.vue') },
        { path: 'bids/create/:feedId?/:truckId?/:useTruckLocation?', name: 'bidCreate', component: () => import('./views/Bids/CreateEditForm.vue') },
        { path: 'bids/edit/:id', name: 'bidEdit', component: () => import('./views/Bids/CreateEditForm.vue') },
        { path: 'loads/list/:showOnlyToday?', name: 'loadsList', component: () => import('./views/Loads/List.vue') },
        { path: 'driverLoads/list/:date?', name: 'driverLoadsList', component: () => import('./views/Loads/DriverLoadsList.vue') },
        { path: 'loads/create/:bidId/:brokerId?/:newEmail?', name: 'loadCreate', component: () => import('./views/Loads/CreateEditForm.vue') },
        { path: 'loads/edit/:id', name: 'loadEdit', component: () => import('./views/Loads/CreateEditForm.vue') },
        { path: 'loads/page/:id/:truckId?', name: 'loadPage', component: () => import('./views/Loads/Page.vue') },
        { path: 'loads/driverPageOld/:id', name: 'driverLoadPageOld', component: () => import('./views/Loads/DriverLoadPage.vue') },
        { path: 'loads/driverLoads/shifts/:id/:isModal?', name: 'driverShift', component: () => import('./views/Loads/DriverLoadPage/ShiftV2.vue') },
        { path: 'loads/driverPage/:id', name: 'driverLoadPage', component: () => import('./views/Loads/DriverLoadPage/Page.vue') },
        { path: 'loads/calendar', name: 'LoadsCalendar', component: () => import('./components/LoadsCalendar.vue') },
        { path: 'loads/feed', name: 'loadsFeed', component: () => import('./views/LoadsFeed/List.vue') },
        { path: 'invoices/list/:showOnlyToday?', name: 'invoicesList', component: () => import('./views/Invoices/List.vue') },
        { path: 'invoices/create', name: 'invoiceCreate', component: () => import('./views/Invoices/CreateEditForm.vue') },
        { path: 'invoices/create/:loadId', name: 'invoiceCreateFromLoad', component: () => import('./views/Invoices/CreateEditForm.vue') },
        { path: 'invoices/createNewVersion/:parentInvoiceId', name: 'invoiceCreateNewVersionFromInvoice', component: () => import('./views/Invoices/CreateEditForm.vue') },
        { path: 'invoices/edit/:id', name: 'invoiceEdit', component: () => import('./views/Invoices/CreateEditForm.vue') },
        { path: 'invoices/page/:id', name: 'invoicePage', component: () => import('./views/Invoices/Page.vue') },
        { path: 'invoices/print/:id', name: 'invoicePrint', component: () => import('./views/Invoices/Page.vue') },
        { path: 'mails/list/:user?/:showOnlyToday?', name: 'mailsList', component: () => import('./views/Mails/List.vue') },
        // { path: 'mails/page/:msgId/:threadId?', name: 'mailPage', component: () => import('./views/Mails/Page.vue') },
        { path: 'reports', name: 'reports', component: () => import('./views/Reports/Index.vue') },
        { path: 'reports/:type?', name: 'reportsByType', component: () => import('./views/Reports/Index.vue') },
        { path: 'reports/:type?/:item?', name: 'reportsByTypeAndItem', component: () => import('./views/Reports/Index.vue') },
        { path: 'reports/print/:type/:companyId/:item/:from/:to/:selected?/:report_number?/:id?/:filename?/:isDOT?', name: 'reportPrint', component: () => import('./views/Reports/ReportPrint.vue') },
        { path: 'notifications/list', name: 'NotificationsList', component: () => import('./views/Notifications/List.vue') },
        { path: 'shifts/listByDrivers/:shiftId?/:driver?', name: 'ShiftsListByDrivers', component: () => import('./views/Shifts/List.vue') },
        { path: 'shifts/listByTrucks/:showRoutes?/:truck?', name: 'ShiftsListByTrucks', component: () => import('./views/Shifts/ListByTrucks.vue') },
        { path: 'shifts/driverList/:isModal?', name: 'DriverShiftsList', component: () => import('./views/Shifts/DriversList.vue') },
        { path: 'chats/list/:threadId?/:userThreadId?', name: 'ChatsList', component: () => import('./views/Chat/List.vue') },
        { path: 'tasks/create/:itemType?/:itemId?', name: 'taskCreate', component: () => import('./views/Tasks/CreateEditForm.vue') },
        { path: 'tasks/edit/:id', name: 'TaskEdit', component: () => import('./views/Tasks/CreateEditForm.vue') },
        { path: 'tasks/page/:id', name: 'TaskPage', component: () => import('./views/Tasks/Page.vue') },
        { path: 'tasks/list', name: 'TaskList', component: () => import('./views/Tasks/List.vue') },
        { path: 'documents/list', name: 'DocumentsList', component: () => import('./views/Documents/List.vue') },
        { path: 'companies/list', name: 'CompaniesList', component: () => import('./views/Companies/List.vue') },
        { path: 'companies/edit/:id', name: 'CompaniesEdit', component: () => import('./views/Companies/CreateEditForm.vue') },
        { path: 'companies/create', name: 'CompaniesCreate', component: () => import('./views/Companies/CreateEditForm.vue') },
        { path: ':pathMatch(.*)*', name: 'defaultPath', component: () => import('./views/Errors/404.vue') }
      ]
    },
  ]
});

export default router;
