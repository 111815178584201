<template>
  <div>
    <template v-if="load.additionalPayments !== 0 || load.rateBonus > 0 || load.RTLRate">
      <template v-if="load.isShared">
        <!--<div style="white-space: nowrap;" v-if="load.additionalPayments > 0">
          ({{parseFloat(load.Rate.toFixed(2)).toLocaleString()}}{{load.additionalPayments > 0?'+':'-'}}{{parseFloat(load.additionalPayments.toFixed(2)).toLocaleString()}})/{{(load.sharedWith.length+1)}}=
        </div>-->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-chip small v-on="on" :color="load.isLoadInvoiceNotMatch ? 'error' : load.invoiceId > 0 ? 'success' : null" style="white-space: nowrap;">
              ${{getTotalRate(load)}}
              <v-icon small class="ml-2">mdi-information</v-icon>
            </v-chip>
          </template>
          <span>
            <template v-if="load.sharedWith.length > 0">
              <b>{{$t('Loads.SharedBetweenCount')}}</b>: {{load.sharedWith.length+1}}<br/>
            </template>
            <template v-if="load.Rate">
              <b>{{$t('Loads.Rate')}}</b>: ${{load.Rate}}<br/>
            </template>
            <template v-if="load.RTLRate">
              <b>{{$t('Loads.RTLRate')}}</b>: ${{load.RTLRate}}<br/>
            </template>
            <template v-if="load.isDriverOwnerOperator === 1 && load.rateBonus">
              <b>{{$t('Loads.driverOwnerOperatorBonus')}}</b>: ${{load.rateBonus}}<br/>
            </template>
            <template v-for="additional_payment_item in ['Detention', 'Layover', 'Fine', 'DriverAssist', 'Idle']">
              <template v-if="load.hasOwnProperty(additional_payment_item)">
                <b :key="additional_payment_item + load.id">{{$t('Loads.'+additional_payment_item)}}</b>: ${{load[additional_payment_item]}}<br/>
              </template>
            </template>
          </span>
        </v-tooltip>
      </template>
      <template v-else>
        <!--<div style="white-space: nowrap;" v-if="load.additionalPayments > 0">
          {{parseFloat(load.Rate.toFixed(2)).toLocaleString()}}{{load.additionalPayments > 0?'+':'-'}}{{parseFloat(load.additionalPayments.toFixed(2)).toLocaleString()}}=
        </div>-->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-chip small v-on="on" :color="load.isLoadInvoiceNotMatch ? 'error' : load.invoiceId > 0 ? 'success' : null" style="white-space: nowrap;">
              ${{getTotalRate(load)}}
              <v-icon small class="ml-2">mdi-information</v-icon>
            </v-chip>
          </template>
          <span>
                    <template v-if="load.Rate">
                      <b>{{$t('Loads.Rate')}}</b>: ${{load.Rate}}<br/>
                    </template>
                    <template v-if="load.RTLRate">
                      <b>{{$t('Loads.RTLRate')}}</b>: ${{load.RTLRate}}<br/>
                    </template>
                    <template v-if="load.isDriverOwnerOperator === 1 && load.rateBonus">
                      <b>{{$t('Loads.driverOwnerOperatorBonus')}}</b>: ${{load.rateBonus}}<br/>
                    </template>
                    <template v-for="additional_payment_item in ['Detention', 'Layover', 'Fine', 'DriverAssist', 'Idle']">
                      <template v-if="load[additional_payment_item]">
                        <b :key="additional_payment_item + load.id">{{ $t('Loads.'+additional_payment_item)}}</b>: ${{load[additional_payment_item] }}<br/>
                      </template>
                    </template>
                  </span>
        </v-tooltip>
      </template>
    </template>
    <template v-else>
      <template v-if="load.isShared">
        <div style="white-space: nowrap;">
          {{$root.moneyValue(load.Rate)}}/{{parseInt(load.sharedWith.length + 1)}}=
        </div>
        <v-chip small
                :color="load.isLoadInvoiceNotMatch ? 'error' : load.invoiceId > 0 ? 'success' : null"
                style="white-space: nowrap;">
          {{$root.moneyValue(load.Rate / (load.sharedWith.length + 1))}}
        </v-chip>
      </template>
      <template v-else>
        <v-chip small
                :color="load.isLoadInvoiceNotMatch ? 'error' : load.invoiceId > 0 ? 'success' : null"
                style="white-space: nowrap;">
          {{$root.moneyValue(load.Rate)}}
        </v-chip>
      </template>
    </template>
  </div>
</template>

<script>
import reportsMixin from '../mixins/reportsMixin.js';

export default {
  name: 'LoadRateSnippet',
  props: [ 'load' ],
  mixins: [reportsMixin],
  data () {
    return {
    };
  },
};
</script>
