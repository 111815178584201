<template>
  <v-container>
    <v-row align="center" justify="center" width="100%" style="page-break-inside: avoid;">
      <!--<table><tr><td>-->
      <v-card light :flat="print" class="mb-1" :style="cardStyle" ref="cardPage">
        <v-card-text :style="{ backgroundImage: `url('${invoiceBgPath}')`, backgroundSize: `100% 100%`, backgroundRepeat: `no-repeat`, backgroundPosition: `bottom` }">
          <v-row dense>
            <v-col xs="6" class="text-left pr-0 pl-4">
              <img responsive width="80%" :src="logoPath"/>
              <br/>
              <div class="text-left" style="width: 80%;">
                <span v-html="invoiceContactInfo ? $root.newLineToBR(invoiceContactInfo) : null"></span>
              </div>
            </v-col>
            <v-col xs="6" class="ma-1 text-right">
              <h1>Invoice</h1>
              <v-simple-table class="mt-2 text-left" dense style="border: 1px solid #000;">
                <tbody>
                <tr style="background:#015aaa;">
                  <td style="border-right: 1px solid #000;color:#fff;"><span class="ml-1">{{fields.headers.DateHeader}}</span></td>
                  <td style="color:#fff;"><span class="ml-1">{{fields.headers.InvoiceNumberHeader}}</span></td>
                </tr>
                <tr>
                  <td style="border-right: 1px solid #000;" class="text-center">
                    {{formattedDate}}
                  </td>
                  <td class="text-center">
                    {{fields.invoiceNumber.toString().padStart(4, '0')}}
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
              <!--<v-simple-table class="mt-2 text-left" dense style="border: 1px solid #000;">
                <tbody>
                <tr style="background:#015aaa;">
                  <td colspan="2" style="border: 1px solid #000;color:#fff;">
                    <h4>Load Unique Identifier</h4>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    {{fields.loadUID}}
                  </td>
                </tr>
                </tbody>
              </v-simple-table>-->
            </v-col>
          </v-row>
          <v-row dense>
            <v-col xs="6" class="ma-1">
              <v-simple-table dense class="text-left" style="border: 1px solid #000;">
                <tbody>
                <tr style="background:#015aaa;color:#fff;">
                  <td><span class="ml-1">{{fields.headers.BillToHeader}}</span></td>
                </tr>
                <tr>
                  <td v-html="fields.billTo ? $root.newLineToBR(fields.billTo) : null">
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col xs="6" class="ma-1">
              <v-simple-table dense class="text-left" style="border: 1px solid #000;color:#000;">
                <tbody>
                <template v-if="!isFactoring || invoiceFactoringShipTo === ''">
                  <tr style="background:#015aaa;color:#fff">
                    <td><span class="ml-1">{{fields.headers.ShipToHeader}}</span></td>
                  </tr>
                  <tr>
                    <td v-html="fields.shipTo ? $root.newLineToBR(fields.shipTo) : null">
                    </td>
                  </tr>
                </template>
                <div v-else v-html="invoiceFactoringShipTo" class="text-center text-caption"/>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col xs="6" class="ma-1">
              <v-simple-table class="mt-2 text-left" dense style="border: 1px solid #000;">
                <tbody>
                <tr style="background:#015aaa;">
                  <td style="border-right: 1px solid #000;color:#fff;"><span class="ml-1">{{fields.headers.TermsHeader}}</span></td>
                  <td style="color:#fff;"><span class="ml-1">{{fields.headers.ShipDateHeader}}</span></td>
                </tr>
                <tr>
                  <td style="border-right: 1px solid #000;" class="text-center">
                    {{fields.terms}}
                  </td>
                  <td class="text-center">
                    {{formattedShipDate}}
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col xs="6" class="ma-1">
              <v-simple-table dense class="mt-2 text-left" style="border: 1px solid #000;color:#fff;">
                <tbody>
                <tr style="background:#015aaa;">
                  <td><span class="ml-1">{{fields.headers.LoadNumberHeader}}</span></td>
                </tr>
                <tr>
                  <td style="color: #000;" class="text-center">
                    {{fields.loadNumber}}
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs="12" class="mt-1">
              <v-simple-table style="border: 1px solid #000; width:100%;background: transparent;" class="text-left" ref="mainTable" :key="'entries'+entries.length">
                <tbody>
                <tr style="background:#015aaa;">
                  <td style="border-right: 1px solid #000; border-bottom: 1px solid #000;color:#fff;width:40%;"><span class="ml-1">{{fields.headers.ShipperHeader}}</span></td>
                  <td style="border-right: 1px solid #000; border-bottom: 1px solid #000;color:#fff;width:40%;"><span class="ml-1">{{fields.headers.ConsigneeHeader}}</span></td>
                  <td style="border-right: 1px solid #000; border-bottom: 1px solid #000;color:#fff;width:10%;"><span class="ml-1">{{fields.headers.DescriptionHeader}}</span></td>
                  <td style="border-bottom: 1px solid #000;color:#fff;width:10%;"><span class="ml-1">{{fields.headers.AmountHeader}}</span></td>
                </tr>
                <tr v-for="(entry, index) in entries" :key="'entry' + index">
                  <td style="border-right: 1px solid #000;background: transparent;width:30%;" v-html="entry.shipper ? $root.newLineToBR(entry.shipper) : null">
                  </td>
                  <td style="border-right: 1px solid #000;background: transparent;width:40%;" v-html="entry.consignee ? $root.newLineToBR(entry.consignee) : null">
                  </td>
                  <td style="border-right: 1px solid #000;background: transparent;width:20%;" v-html="entry.descriptionDimensions ? $root.newLineToBR(entry.descriptionDimensions) : null">
                  </td>
                  <td style="background: transparent;width:10%;">
                    $&nbsp;{{new Number(entry.amount).toFixed(2)}}
                  </td>
                </tr>
                <tr v-if="lastEmptyEntryHeight" :style="{height: lastEmptyEntryHeight + 'px'}">
                  <td style="border-right: 1px solid #000;background: transparent;">&nbsp;</td>
                  <td style="border-right: 1px solid #000;">&nbsp;</td>
                  <td style="border-right: 1px solid #000;">&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr style="background:#015aaa;">
                  <td colspan="3" class="text-center" style="color:#fff;border-right: 1px solid #000;border-top: 1px solid #000;">
                    <h2>{{fields.headers.ThankYouHeader}}</h2>
                  </td>
                  <td class="text-left" style="color:#fff;border-top: 1px solid #000;">
                      <h2>{{fields.headers.TotalHeader}}<br/>${{new Number(fields.total).toFixed(2)}}</h2>
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
              <div class="text-left" v-if="fields.loadUID">{{fields.loadUID}} - #{{fields.load_id}}</div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!--</td></tr></table>-->
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'InvoiceTemplate',
  props: ['fields', 'print', 'headers', 'logoPath', 'invoiceContactInfo', 'invoiceFactoringShipTo'],
  components: {},
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      entries: [],
      formattedDate: '',
      formattedShipDate: '',
      lastEmptyEntryHeight: 0,
      ppi: 72,
      templateScale: 1
    };
  },
  computed: {
    invoiceBgPath () {
      return process.env.BASE_URL + 'img/invoiceBg.png';
    },
    isFactoring () {
      return this.fields.factoring >= 0;
    },
    cardStyle () {
      let v = 1;
      if (this.print) {
        return {
          width: '21cm',
          minHeight: '23cm'
        };
      } else {
        if (this.$root.isMobile) {
          v = 21 * this.templateScale;
          return {
            maxWidth: v.round(0) + 'cm',
            maxHeight: '23cm'
          };
        }
      }
      return {
        width: '21cm',
        minHeight: '23cm'
      };
    }
  },
  mounted () {
    if (this.fields.date) {
      let _dateArray = this.fields.date.split('T')[0].split('-');
      this.formattedDate = _dateArray[1] + '/' + _dateArray[2] + '/' + _dateArray[0];
    }

    if (this.fields.shipDate) {
      let _dateArray = this.fields.shipDate.split('T')[0].split('-');
      this.formattedShipDate = _dateArray[1] + '/' + _dateArray[2] + '/' + _dateArray[0];
    }
    this.entries = JSON.parse(this.fields.entries.replace(/\n/g, '<br/>').replace(/\r/g, '\\r').replace(/\t/g, '\\t'));

    this.$nextTick(() => {
      this.calcSize();
    });
  },
  methods: {
    calcSize () {
      let windowWPixels = 0;
      let windowWInches = 0.0;
      let windowWCM = 0.0;
      let pageRect = 0;
      let tableRect = 0;
      let table_height = 0;

      if (this.$refs.cardPage && this.$refs.mainTable) {
        pageRect = this.$refs.cardPage.$el.getBoundingClientRect();
        tableRect = this.$refs.mainTable.$el.getBoundingClientRect();
        table_height = (pageRect.height - (tableRect.y - pageRect.y)) - tableRect.height;
        //console.log(table_height, ' = (', pageRect.height, ' - (', tableRect.y, '-', pageRect.y, ')) - ', tableRect.height);
        this.lastEmptyEntryHeight = parseInt(Math.abs(table_height)).toFixed(0);

        this.ppi = parseFloat(window.calcScreenDPI());
        windowWPixels = parseFloat(this.$root.windowWidth);
        windowWInches = windowWPixels / this.ppi;
        windowWCM = windowWInches * 2.54;
        this.$set(this, 'templateScale', Math.min(1.0, windowWCM / 21.0));
      }
    }
  }
};

</script>
