export default {
  data: function () {
  },
  methods: {
    getTrucksList (limitForUser = false, filterDriverOO = 0, isTrailer = 0) {
      if (limitForUser || filterDriverOO > 0) {
        return this.get('/api/trucks/list/1/' + filterDriverOO + '/0/' + isTrailer);
      }
      return this.get('/api/trucks/list/0/0/0/' + isTrailer);
    },
    getTrucksByType (type) {
      return this.get('/api/trucks/list/0/0/' + type);
    },
    getTruck (id) {
      return this.get('/api/trucks/get/' + id);
    },
    getTruckMiles (id) {
      return this.get('/api/trucks/getTruckOdometer/' + id);
    },
    getTruckOilReplacement (id) {
      return this.get('/api/trucks/getOilChangeHistory/' + id);
    },
    deleteTruck (id) {
      return this.post('/api/trucks/delete', { id: id });
    },
    setTruckStatus (id, status) {
      return this.post('/api/trucks/status', { id: id, status: status });
    },
    getAvailableTrucks () {
      return this.get('/api/trucks/getAvailableTrucks');
    },
    getAnnualInspectionsList (truckId) {
      return this.get('/api/maintenance/annualInspectionsList/' + truckId);
    },
    getAnnualInspectionsReport (reportId) {
      return this.$root.get('/api/maintenance/getAnnualInspectionsReport/' + reportId);
    },
    getMaintenanceList (truckId, date = '') {
      if (date !== '') {
        return this.get('/api/maintenance/list/' + truckId + '/' + date);
      }
      return this.get('/api/maintenance/list/' + truckId);
    },
    getMaintenance (id) {
      return this.$root.get('/api/maintenance/get/' + id);
    },
    getMaintenanceUsageData (truckId, maintenanceType) {
      return this.$root.post('/api/maintenance/usageData', {
        truckId: truckId,
        maintenanceType: maintenanceType
      });
    },
    getPartsByTruckId(id) {
      return this.$root.get('/api/maintenance/getTruckParts/' + id);
    },
    updateMaintenanceRecord (id, fields, parts, isScheduledDoneId) {
      return new Promise((resolve, reject) => {
        this.$root.post('/api/maintenance/save/' + id, { fields: fields, parts: parts, isScheduledDoneId: isScheduledDoneId }).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Trucks.TruckMaintenanceSaved'), { color: 'success' });
            resolve(response.result.processedId);
          } else {
            this.$root.toast(this.$i18n.t('Trucks.ErrorWhenSavingTheTruckMaintenance'), { color: 'error' });
          }
        }, (error) => {
          console.error('ERROR', error);
          reject(error);
        });
      });
    },
    createMaintenanceRecord (fields, parts, isScheduledDoneId) {
      return new Promise((resolve, reject) => {
        this.$root.post('/api/maintenance/save', { fields: fields, parts: parts, isScheduledDoneId: isScheduledDoneId }).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Trucks.NewTruckMaintenanceAdded'), { color: 'success' });
            resolve(response.result.processedId);
          } else {
            this.$root.toast(this.$i18n.t('Trucks.ErrorWhenAddingTheTruckMaintenance'), { color: 'error' });
          }
        }, (error) => {
          console.error('ERROR', error);
          reject(error);
        });
      });
    },
    getAnnualInspectionReportItems (reportId = 0) {
      return this.$root.get('/api/maintenance/getAnnualInspectionReportItems/' + reportId);
    },
    removeTruckMaintenance (id) {
      return this.post('/api/maintenance/removeTruckMaintenance', { id: id });
    },
    createTruck (fields) {
      return new Promise((resolve, reject) => {
        this.post('/api/trucks/save', { fields: fields }).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Trucks.NewTruckAdded'), { color: 'success' });
            resolve(response.result.processedId);
          } else {
            this.$root.toast(this.$i18n.t('Trucks.ErrorWhenAddingTheTruck'), { color: 'error' });
          }
        }, (error) => {
          console.error('ERROR', error);
          reject(error);
        });
      });
    },
    saveTruck (fields, id, cb = null) {
      return new Promise((resolve, reject) => {
        this.post('/api/trucks/save/' + id, { fields: fields }).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Trucks.TruckDataSaved'), { color: 'success' });
            resolve();
          } else {
            this.$root.toast(this.$i18n.t('Trucks.TruckDataSaveError'), { color: 'error' });
          }
          if (cb) {
            cb();
          }
        }, (error) => {
          console.error('ERROR', error);
          reject(error);
        });
      });
    },
    getTruckFirstUrl (field) {
      const docs = this.getDocumentsFromField(field, 'truckAvatar', '');
      if (docs.length > 0) {
        return docs[0].url + '/filename/1';
      }

      return '';
    },
    getTruckStatusName (value) {
      return '';
    },
    insertTruckTelemetry (item) {
      item.isCoordsAvailable = item.lat !== 0 || item.lng !== 0;
      item.truckTelemetry = {
        rotation: 0,
        speed: 0,
        locationName: '',
        engineStates: '', // On, Off, Idle
        fuelPercent: 0,
        fuelGalons: 0,
        odometerMiles: 0,
        batteryMilliVolts: 0
      };
      try {
        if (item.samsaraid > 0 && item.samsaradata && item.samsaradata.indexOf('{') === 0 &&
            item.keeptruckinid > 0 && item.keeptruckindata && item.keeptruckindata.indexOf('{') === 0) {
          const samsaradata = JSON.parse(item.samsaradata);
          const keeptruckindata = JSON.parse(item.keeptruckindata);
          item.truckTelemetry.locationName = keeptruckindata.description;
          item.truckTelemetry.rotation = samsaradata.heading;
          item.truckTelemetry.speed = samsaradata.speed ? samsaradata.speed.toFixed(0) : '-';
          item.truckTelemetry.engineStates = samsaradata.engineStates;
          item.truckTelemetry.fuelPercent = samsaradata.fuelPercents;
          item.truckTelemetry.odometerMiles = this.$root.metersToMiles(samsaradata.obdOdometerMeters);
          item.truckTelemetry.batteryMilliVolts = samsaradata.batteryMilliVolts;
        } else if (item.samsaraid && item.samsaradata && item.samsaradata.indexOf('{') === 0) {
          const samsaradata = JSON.parse(item.samsaradata);
          item.truckTelemetry.rotation = samsaradata.heading;
          item.truckTelemetry.speed = samsaradata.speed ? samsaradata.speed.toFixed(0) : '-';
          item.truckTelemetry.locationName = samsaradata.reverseGeo.formattedLocation;
          item.truckTelemetry.engineStates = samsaradata.engineStates;
          item.truckTelemetry.fuelPercent = samsaradata.fuelPercents;
          item.truckTelemetry.odometerMiles = this.$root.metersToMiles(samsaradata.obdOdometerMeters);
          item.truckTelemetry.batteryMilliVolts = samsaradata.batteryMilliVolts;
        } else if (item.keeptruckinid && item.keeptruckindata && item.keeptruckindata.indexOf('{') === 0) {
          const keeptruckindata = JSON.parse(item.keeptruckindata);
          item.truckTelemetry.rotation = keeptruckindata.bearing;
          item.truckTelemetry.locationName = keeptruckindata.description;
          item.truckTelemetry.speed = keeptruckindata.speed ? keeptruckindata.speed.toFixed(0) : '-';
          item.truckTelemetry.fuelGalons = keeptruckindata.fuel;
          item.truckTelemetry.odometerMiles = keeptruckindata.odometer;
        }
      } catch (error) {
        console.warn(error);
      }
      return item;
    },

    insertLoadsData (item) {
      let j = 0;
      let ret = [];
      for (j = 0; j < item.loads.length; j++) {
        ret.push({
          id: item.loads[j]['id'],
          uid: item.loads[j]['uid'],
          title: item.loads[j]['title'],
          dispatcher: item.loads[j]['dispatcher'],
          dispatcherFirstName: item.loads[j]['dispatcherFirstName'],
          dispatcherLastName: item.loads[j]['dispatcherLastName'],
          dispatcherAvatarUPLOAD: item.loads[j]['dispatcherAvatarUPLOAD'],
          status: this.$root.getLoadStatus(item.loads[j].locations),
          loadPhotosArray: JSON.parse(item.loads[j]['loadPhotosUPLOAD']) || [],
          distanceInMiles: item.loads[j]['distanceInMiles'],
          RatePerMile: item.loads[j]['RatePerMile'],
          Rate: item.loads[j]['Rate']
        });
      }
      return ret;
    },

    async getTrucksNotificationsData (truckId = 0) {
      if (truckId === 0) {
        return this.get('/api/maintenance/getMaintenanceNotifications');
      }
      return this.get('/api/maintenance/getMaintenanceNotifications/' + truckId);
    },

    async getTuckNotifications (truckFields, settingsFields, notifications = undefined) {
      let i = 0;
      let ret = [];

      // Maintenance
      if (!notifications) {
        const response = await this.getTrucksNotificationsData(truckFields['id']);
        if (response.status === 'ok') {
          notifications = response.results;
        }
      }
      if (!notifications) return ret;

      for (i = 0; i < notifications.length; i++) {
        const result = notifications[i];
        let type = '';
        let msg = '';
        let icon = 'mdi-hammer-wrench';
        let scheduledOverdueDays = 0;
        if (result.hasOwnProperty('isScheduled')) {
          type = 'scheduled';
          icon = 'mdi-progress-wrench';
          msg = this.$root.t('scheduled') + ' "' + result.type + '" ' + this.$root.t('for') + ' ' + this.$root.formatDate(result.scheduledDATE);
          if (result.scheduledDATE) {
            scheduledOverdueDays = this.$moment.moment().utcOffset(this.$store.getters.timezoneOffsetMinutes).diff(this.$moment.moment(result.scheduledDATE), 'days');
          }
        } else if (result.hasOwnProperty('isDanger')) {
          type = 'error';
          msg = this.$i18n.t('Trucks.Notifications.maintenanceExpired', { type: result.type, value: result.overdueMiles });
        } else if (result.hasOwnProperty('isWarning')) {
          type = 'warning';
          msg = this.$i18n.t('Trucks.Notifications.maintenanceExpiredSoon', { type: result.type, value: result.overdueMiles });
        } else {
          type = 'success';
        }

        ret.push({
          type: type,
          icon: result.type === 'Oil Replacement' ? 'mdi-oil' : icon,
          name: result.type,
          id: result.id,
          scheduledDATE: result.scheduledDATE,
          scheduledMiles: result.scheduledMiles,
          scheduledOverdueDays: scheduledOverdueDays,
          scheduledOverdueMiles: result.scheduledOverdueMiles,
          overdueMiles: result.overdueMiles,
          parts: result.hasOwnProperty('parts') ? result.parts : [],
          note: result.hasOwnProperty('note') ? result.note : '',
          msg: msg
        });
      }

/*
            // OIL
            if (truckFields.lastOilReplacementMiles && truckFields.nextOilReplacementIntervalMiles) {
              const nextMilesForOilReplacement = truckFields.lastOilReplacementMiles + truckFields.nextOilReplacementIntervalMiles;
              if (truckFields.miles > nextMilesForOilReplacement) {
                ret.push({
                  type: 'error',
                  msg: this.$i18n.tc('Trucks.Notifications.oilReplacementExpired', parseInt(truckFields.miles - nextMilesForOilReplacement))
                });
              } else if (settingsFields && settingsFields.hasOwnProperty('oilReplacementWarningWhenMilesLeft') && nextMilesForOilReplacement - truckFields.miles <= parseInt(settingsFields.oilReplacementWarningWhenMilesLeft)) {
                ret.push({
                  type: 'warning',
                  msg: this.$i18n.tc('Trucks.Notifications.oilReplacementExpiredSoon', parseInt(nextMilesForOilReplacement - truckFields.miles))
                });
              }
            }
*/

      // Annual Inspection
      if (truckFields.lastAnnualInspectionDATE &&
          settingsFields && settingsFields.hasOwnProperty('annualInspectionIntervalInDays') && settingsFields.hasOwnProperty('annualInspectionWarningWhenDaysLeft')) {
        const nowTS = new Date().getTime() / 1000;
        const lastAnnualInspectionTS = this.getTimestampFromDateTimeString(truckFields.lastAnnualInspectionDATE);
        const daysSinseLastAnnualInspection = parseInt((nowTS - lastAnnualInspectionTS) / 60 / 60 / 24);
        const daysLeftToNextInspection = settingsFields.annualInspectionIntervalInDays - daysSinseLastAnnualInspection;

        if (daysSinseLastAnnualInspection >= settingsFields.annualInspectionIntervalInDays) {
          ret.push({
            type: 'error',
            icon: 'mdi-format-list-checkbox',
            name: this.$root.t('Trucks.Fields.lastAnnualInspectionDATE'),
            overdueDays: parseInt(daysLeftToNextInspection),
            msg: this.$i18n.tc('Trucks.Notifications.annualInspectionExpired', Math.abs(parseInt(daysSinseLastAnnualInspection - settingsFields.annualInspectionIntervalInDays)))
          });
        } else if (daysLeftToNextInspection < settingsFields.annualInspectionWarningWhenDaysLeft) {
          ret.push({
            type: 'warning',
            icon: 'mdi-format-list-checkbox',
            name: this.$root.t('Trucks.Fields.lastAnnualInspectionDATE'),
            overdueDays: parseInt(daysLeftToNextInspection),
            msg: this.$i18n.tc('Trucks.Notifications.annualInspectionExpiredSoon', parseInt(daysLeftToNextInspection))
          });
        } else {
          ret.push({
            type: 'success',
            icon: 'mdi-format-list-checkbox',
            name: this.$root.t('Trucks.Fields.lastAnnualInspectionDATE'),
            overdueDays: parseInt(daysLeftToNextInspection)
          });
        }
      }

      // Registration card
      if (truckFields.registrationCardExpiredDATE &&
          settingsFields && settingsFields.hasOwnProperty('registrationCardExpiredWarningWhenDaysLeft')) {
        const nowTS = new Date().getTime() / 1000;
        const expireTS = this.getTimestampFromDateTimeString(truckFields.registrationCardExpiredDATE);
        const daysLeft = (expireTS - nowTS) / 60 / 60 / 24;

        if (daysLeft < 0) {
          ret.push({
            type: 'error',
            icon: 'mdi-card-bulleted-outline',
            name: this.$root.t('Trucks.Fields.registrationCardExpiredDATE'),
            overdueDays: parseInt(daysLeft),
            msg: this.$i18n.tc('Trucks.Notifications.registrationCardExpired', Math.abs(parseInt(daysLeft)))
          });
        } else if (daysLeft < settingsFields.registrationCardExpiredWarningWhenDaysLeft) {
          ret.push({
            type: 'warning',
            icon: 'mdi-card-bulleted-outline',
            name: this.$root.t('Trucks.Fields.registrationCardExpiredDATE'),
            overdueDays: parseInt(daysLeft),
            msg: this.$i18n.tc('Trucks.Notifications.registrationCardExpiredSoon', parseInt(daysLeft))
          });
        } else {
          ret.push({
            type: 'success',
            icon: 'mdi-card-bulleted-outline',
            name: this.$root.t('Trucks.Fields.registrationCardExpiredDATE'),
            overdueDays: parseInt(daysLeft)
          });
        }
      }

      // Insuranse card
      if (truckFields.insuranceExpiredDATE && settingsFields && settingsFields.hasOwnProperty('registrationCardExpiredWarningWhenDaysLeft')) {
        const nowTS = new Date().getTime() / 1000;
        const expireTS = this.getTimestampFromDateTimeString(truckFields.insuranceExpiredDATE);
        const daysLeft = (expireTS - nowTS) / 60 / 60 / 24;

        if (daysLeft < 0) {
          ret.push({
            type: 'error',
            icon: 'mdi-shield-car',
            name: this.$root.t('Trucks.Fields.insuranceExpiredDATE'),
            overdueDays: parseInt(daysLeft),
            msg: this.$i18n.tc('Trucks.Notifications.insuranceExpiredExpired', Math.abs(parseInt(daysLeft)))
          });
        } else if (daysLeft < settingsFields.registrationCardExpiredWarningWhenDaysLeft) {
          ret.push({
            type: 'warning',
            icon: 'mdi-shield-car',
            name: this.$root.t('Trucks.Fields.insuranceExpiredDATE'),
            overdueDays: parseInt(daysLeft),
            msg: this.$i18n.tc('Trucks.Notifications.insuranceExpiredExpiredSoon', parseInt(daysLeft))
          });
        } else {
          ret.push({
            type: 'success',
            icon: 'mdi-shield-car',
            name: this.$root.t('Trucks.Fields.insuranceExpiredDATE'),
            overdueDays: parseInt(daysLeft)
          });
        }
      }

      return ret;
    },
    getTruckLastPath (truckId, loadId = 0, loadStarted = 0, loadEnded = 0) {
      if (loadStarted > 0 && loadEnded > 0) {
        if (loadId > 0 && !truckId) {
          return this.get('/api/trucks/getLastPath/0/' + loadId + '/' + loadStarted + '/' + loadEnded);
        } else if (!loadId && truckId > 0) {
          return this.get('/api/trucks/getLastPath/' + truckId + '/0/' + loadStarted + '/' + loadEnded);
        }
      }
      return this.get('/api/trucks/getLastPath/' + truckId);
    },
    getTrucksLocationData () {
      return this.get('/api/trucks/getTrucksLocationData');
    },
    updateOilChange (truckId, date, miles) {
      return this.post('/api/trucks/addNewOilReplacement/' + truckId, {
        fields: {
          lastOilReplacementDATE: date,
          lastOilReplacementMiles: miles
        }
      });
    },
    getTruckStatusNameByValue (status) {
      if (status === 0) {
        return this.$root.t('Trucks.Statuses.Active');
      } else if (status === 10) {
        return this.$root.t('Trucks.Statuses.Abandoned');
      } else if (status === 20) {
        return this.$root.t('Trucks.Statuses.Maintenance');
      } else if (status === -1) {
        return this.$root.t('Trucks.Statuses.Deactivated');
      }
    },
    getTruckStatusColorByValue (status) {
      if (status === 0) {
        return 'success';
      } else if (status === 10) {
        return 'info';
      } else if (status === 20) {
        return 'warning';
      } else if (status === -1) {
        return 'error';
      }
    },
    saveAnnualInspectionReport (id, fields) {
      return this.$root.post('/api/maintenance/saveAnnualInspectionReport/' + id, fields);
    },
    removeAnnualInspectionReport (id) {
      return this.$root.post('/api/maintenance/removeAnnualInspectionReport', { id: id });
    },
    saveAnnualInspectionReportAnswers (id, answers) {
      return this.$root.post('/api/maintenance/saveAnnualInspectionReportAnswers/' + id, answers);
    },
    saveLastAnnualInspectionReportFile (truckId, fields) {
      return this.$root.post('/api/maintenance/saveLastAnnualInspectionReportFile/' + truckId, fields);
    },
    updateMaintenanceNote (truckId, maintenanceType, note) {
      return new Promise(resolve => {
        this.$root.post('/api/maintenance/updateMaintenanceNote', {
          truckId: truckId,
          maintenanceType: maintenanceType,
          note: note
        }).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Trucks.TruckMaintenanceSaved'), { color: 'success' });
            resolve();
          }
        });
      });
    }
  }
};
