<template>
  <div>
    <v-expansion-panels v-if="$root.isMobile">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Trucks ({{ item.trucks.length }})
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <template v-for="truck in item.trucks">
            <v-simple-table style="width: 100%; border-bottom: 1px solid #000;" dense :key="'shiftTruck' + truck.id">
              <template v-slot:default>
                <tbody>
                <tr>
                  <td>{{$root.t('Shifts.Truck')}}</td>
                  <td><a href="#" @click.stop="$root.onShowTruckPreview(truck.truckId)">{{truck.truckName}}</a></td>
                </tr>
                <tr>
                  <td>{{$root.t('Shifts.Started')}}</td>
                  <td>{{truck.createdPST}}</td>
                </tr>
                <tr>
                  <td>{{$root.t('Shifts.Ended')}}</td>
                  <td>{{truck.endedPST}}</td>
                </tr>
                <tr>
                  <td>{{$root.t('Shifts.Origin')}}</td>
                  <td>{{truck.startLocation}}</td>
                </tr>
                <tr>
                  <td>{{$root.t('Shifts.Finish')}}</td>
                  <td>{{truck.endLocation}}</td>
                </tr>
                <tr>
                  <td>{{$root.t('Shifts.OdometerStart')}}</td>
                  <td class="text-no-wrap">
                    {{truck.odometerStart}}
                    <img v-img:images style="height:24px;" :src="$root.getImageUrl('odometerStart', truck.odometerStartUPLOAD)" v-if="truck.odometerStartUPLOAD"/>
                  </td>
                </tr>
                <tr>
                  <td>{{$root.t('Shifts.OdometerEnd')}}</td>
                  <td class="text-no-wrap">
                    {{truck.odometerEnd}}
                    <img v-img:images style="height:24px;" :src="$root.getImageUrl('odometer', truck.odometerEndUPLOAD)" v-if="truck.odometerEndUPLOAD"/>
                  </td>
                </tr>
                <tr>
                  <td>{{$root.t('Shifts.Distance')}}</td>
                  <td>
                    {{truck.distance}}{{truck.distance ? $t('milesShort') : ''}}
                  </td>
                </tr>
                <tr>
                  <td>{{$root.t('Shifts.Duration')}}</td>
                  <td>
                    {{$root.showDuration(truck.shiftDurationSeconds)}}
                  </td>
                </tr>
                <tr>
                  <td>{{$root.t('Shifts.Status')}}</td>
                  <td>
                    <span v-if="truck.ended !== null">{{$t('Shifts.Finished')}}</span>
                    <span v-else>{{$t('Shifts.InProgress')}}</span>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <template v-if="!$root.isMobile">
      <v-subheader><h4>Trucks</h4></v-subheader>
      <v-simple-table style="width: 100%;" dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="pl-0">{{$root.t('Shifts.Truck')}}</th>
            <th class="pl-0">{{$root.t('Shifts.Started')}}</th>
            <th class="pl-0">{{$root.t('Shifts.Ended')}}</th>
            <th class="pl-0">{{$root.t('Shifts.Origin')}}</th>
            <th class="pl-0">{{$root.t('Shifts.Finish')}}</th>
            <th class="pl-0">{{$root.t('Shifts.OdometerStart')}}</th>
            <th class="pl-0">{{$root.t('Shifts.OdometerEnd')}}</th>
            <th class="pl-0">{{$root.t('Shifts.Distance')}}</th>
            <th class="pl-0">{{$root.t('Shifts.Duration')}}</th>
            <th class="pl-0">{{$root.t('Shifts.Status')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="truck in item.trucks" :key="truck.id"  style="width: 100%;">
            <td>{{truck.truckName}}</td>
            <td>{{truck.createdPST}}</td>
            <td>{{truck.endedPST}}</td>
            <td>{{truck.startLocation}}</td>
            <td>{{truck.endLocation}}</td>
            <td class="text-no-wrap">
              {{truck.odometerStart}}
              <img v-img:images style="height:24px;" :src="$root.getImageUrl('odometerStart', truck.odometerStartUPLOAD)" v-if="truck.odometerStartUPLOAD"/>
            </td>
            <td class="text-no-wrap">
              {{truck.odometerEnd}}
              <img v-img:images style="height:24px;" :src="$root.getImageUrl('odometer', truck.odometerEndUPLOAD)" v-if="truck.odometerEndUPLOAD"/>
            </td>
            <td>
              {{truck.distance}}{{truck.distance ? $t('milesShort') : ''}}
            </td>
            <td>
              {{$root.showDuration(truck.shiftDurationSeconds)}}
            </td>
            <td>
              <span v-if="truck.ended !== null">{{$t('Shifts.Finished')}}</span>
              <span v-else>{{$t('Shifts.InProgress')}}</span>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </div>
</template>

<script>
import OverriddenFieldsButton from '../../components/OverriddenFieldsButton';
export default {
  name: 'ShiftTrucksInfoTable',
  props: ['item'],
  mixins: [ ],
  components: {
    OverriddenFieldsButton
  },
  data: function () {
    return {
    };
  },
  computed: {
  },
  mounted () {
  },
  methods: {
  }
};
</script>
