import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import drivingWheel from './../components/customIcons/drivingWheel.vue';
import drivingWheelBig from './../components/customIcons/drivingWheelBig.vue';
import driverAssist from './../components/customIcons/driverAssist.vue';
import truckAlert from './../components/customIcons/truckAlert';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: {
      drivingWheelIcon: {
        component: drivingWheel
      },
      drivingWheelLargeIcon: {
        component: drivingWheelBig
      },
      driverAssist: {
        component: driverAssist
      },
      truckAlert: {
        component: truckAlert
      }
    }
  }
});
