export default {
  data: function () {
    return {
      isFilesUpdated: {}
    };
  },
  computed: {
    paymentDetailsUPLOADCount () {
      return this.fields.paymentDetailsUPLOAD ? JSON.parse(this.fields.paymentDetailsUPLOAD).length || 0 : 0;
    },
    agreementUPLOADCount () {
      return this.fields.agreementUPLOAD ? JSON.parse(this.fields.agreementUPLOAD).length || 0 : 0;
    },
    passportUPLOADCount () {
      return this.fields.passportUPLOAD ? JSON.parse(this.fields.passportUPLOAD).length || 0 : 0;
    },
    resumeUPLOADCount () {
      return this.fields.resumeUPLOAD ? JSON.parse(this.fields.resumeUPLOAD).length || 0 : 0;
    },
    W2FormUPLOADCount () {
      return this.fields.W2FormUPLOAD ? JSON.parse(this.fields.W2FormUPLOAD).length || 0 : 0;
    },
    W9FormUPLOADCount () {
      return this.fields.W9FormUPLOAD ? JSON.parse(this.fields.W9FormUPLOAD).length || 0 : 0;
    },
    driverDrivingLicenseUPLOADCount () {
      return this.fields.driverDrivingLicenseUPLOAD ? JSON.parse(this.fields.driverDrivingLicenseUPLOAD).length || 0 : 0;
    },
    driverMedicalCardUPLOADCount () {
      return this.fields.driverMedicalCardUPLOAD ? JSON.parse(this.fields.driverMedicalCardUPLOAD).length || 0 : 0;
    },
    driverWorkPermitOrGreenCardUPLOADCount () {
      return this.fields.driverWorkPermitOrGreenCardUPLOAD ? JSON.parse(this.fields.driverWorkPermitOrGreenCardUPLOAD).length || 0 : 0;
    },
    brokerSetupPackageUPLOADCount () {
      return this.fields.brokerSetupPackageUPLOAD ? JSON.parse(this.fields.brokerSetupPackageUPLOAD).length || 0 : 0
    },
    driverOwnerOperatorIENUPLOADCount () {
      return this.fields.driverOwnerOperatorIENUPLOAD ? JSON.parse(this.fields.driverOwnerOperatorIENUPLOAD).length || 0 : 0
    },
    driverOwnerOperatorInsuranceUPLOADCount () {
      return this.fields.driverOwnerOperatorInsuranceUPLOAD ? JSON.parse(this.fields.driverOwnerOperatorInsuranceUPLOAD).length || 0 : 0
    },
    driverOwnerOperatorAuthorityFormUSDOTUPLOADCount () {
      return this.fields.driverOwnerOperatorAuthorityFormUSDOTUPLOAD ? JSON.parse(this.fields.driverOwnerOperatorAuthorityFormUSDOTUPLOAD).length || 0 : 0
    },
    driverOwnerOperatorAuthorityFormMCUPLOADCount () {
      return this.fields.driverOwnerOperatorAuthorityFormMCUPLOAD ? JSON.parse(this.fields.driverOwnerOperatorAuthorityFormMCUPLOAD).length || 0 : 0
    },
    driverOwnerOperatorAuthorityFormCAUPLOADCount () {
      return this.fields.driverOwnerOperatorAuthorityFormCAUPLOAD ? JSON.parse(this.fields.driverOwnerOperatorAuthorityFormCAUPLOAD).length || 0 : 0
    },
    driverOwnerOperatorStatementOfInformationCorporationUPLOADCount () {
      return this.fields.driverOwnerOperatorStatementOfInformationCorporationUPLOAD ? JSON.parse(this.fields.driverOwnerOperatorStatementOfInformationCorporationUPLOAD).length || 0 : 0
    }
  },
  methods: {
    saveFields () {
      let successCount = 0;
      let errorCount = 0;
      let _r = {};
      if (!this.updatingEnabled) return;
      this.setLoadingField('saveBtn', true);
      this.$root.updateUserFields(this.fields, this.userId).then((response) => {
        this.setLoadingField('saveBtn', false);

        for (let k in response) {
          if (response[k].status && response[k].status === 'ok') {
            successCount++;
          } else {
            errorCount += 1;
            _r = this.$root.getAccessRightsArrayFromNumber(response[k].rights);
            if (_r[1] === '0') this.setFieldHidden(k, true);
            if (_r[2] === '0') this.setReadOnlyField(k, true);
          }
        }
        if (successCount > 0) {
          this.$root.toast(this.$i18n.t('ChangesAreSaved'), { color: 'success' });
        } else {
          this.$root.toast(this.$i18n.t('FieldAccessError'), { color: 'error' });
        }
        /*if (successCount > 0 && errorCount === 0) {
          this.$root.toast(this.$i18n.t('ChangesAreSaved'), { color: 'success' });
        } else if (successCount > 0 && errorCount > 0) {
          this.$root.toast(this.$i18n.t('ChangesAreSavedWithAccessErrors'), { color: 'warning' });
        } else {
          this.$root.toast(this.$i18n.t('FieldAccessError'), { color: 'error' });
        }*/
      });
    },
    fetchDataForSettingsModal () {
      // TODO handle reject
      if (!this.$root.isSettingsAllowed()) {
        return new Promise((resolve, reject) => { resolve(''); });
      }
      return new Promise((resolve, reject) => {
        if (this.isOwnSettings) {
          this.$root.loadSettingsFields().then((response) => {
            if (response.status === 'ok') {
              this.setFields(response.fields, response.role, response.rights);
            }
            this.loading = false;
            resolve(response);
          });
        } else {
          this.$root.loadUserFields(this.$route.params.id).then((response) => {
            if (response.status === 'ok') {
              this.loading = false;
              this.setFields(response.fields, response.role, response.rights);
            }
            this.loading = false;
            resolve(response);
          });
        }
      });
    },
    fileChange (fieldName, fieldValue, triggerSave = false, cb = null) {
      this.fields[fieldName] = fieldValue;
      if (triggerSave && cb) {
        cb();
      } else {
        this.$set(this.isFilesUpdated, fieldName, true);
      }
    },
    fileUploaded (file, fieldName, folderName) {
      if (!file) {
        this[fieldName + 'Url'] = '';
        return;
      }
      // Check is file has extention
      if (file.name.lastIndexOf('.') <= 0) {
        // TODO add error message here
        return;
      }
      this.setLoadingField(fieldName, true);
      let ext = '.' + file.name.split('.').pop();
      // Load file to coresponding img
      this.$root.readWithFileReader(file, (result) => {
        this[fieldName + 'Url'] = result;
      });
      // Upload actual file
      this.$root.uploadUserFile(folderName, file, (result) => {
        this.fields[fieldName] = result.fileHash ? (result.fileHash + ext) : '';
        this.setLoadingField(fieldName, false);
        this.loadImages();
        this.isFilesUpdated[fieldName] = true;
      }, (error) => {
        this.isFilesUpdated[fieldName] = false;
        console.error(error);
        this.$root.toast(error, { color: 'error' });
      });
    },
    loadImages () {
      for (let key in this.fields) {
        if (key.endsWith('UPLOAD')) {
          let folderName = key.substr(0, key.length - 6);
          if (this.fields[key]) {
            this[key + 'Url'] = this.$root.getImageUrl(folderName, this.fields[key]);
          }
        }
      }
    },
    decryptField (field, userId) {
      // TODO refactor this hash
      const _tmp = this.updatingEnabled;
      this.updatingEnabled = true;
      if (!this.isFieldEncrypted(field)) {
        this.fields[field] = 'encrypted';
        this.setEncryptedField(field, true);
      } else {
        this.$root.showPasswordDialog((password) => {
          if (password.length > 0) {
            this.$root.decryptUserField(field, password, userId).then((response) => {
              this.$store.commit('setPasswordDialog', false);
              if (response.status === 'ok') {
                this.fields[field] = response.result;
                this.setEncryptedField(field, false);
                this.$root.toast(this.$i18n.t('FieldDecrypted'), { color: 'success' });
              } else {
                this.setEncryptedField(field, true);
                this.$root.toast(this.$i18n.t('FieldDecryptinError'), { color: 'error' });
              }
            });
          } else {
            this.$root.toast(this.$i18n.t('PasswordFieldIsEmpty'), { color: 'error' });
          }
        });
      }
      // TODO refactor this hash
      this.updatingEnabled = _tmp;
    }
  }
};
