export default {
  data: function () {
    return {
    };
  },
  computed: {
    doneLocationColor () {
      return 'green lighten-1';
    },
    startLocationColor () {
      // return 'blue lighten-1';
      return '#42A5F5';
    },
    inProgressColor () {
      return 'amber darken-3';
    },
    pickupColor () {
      // return 'pink lighten-1';
      return '#ec407a';
    },
    deliveryColor () {
      // return 'cyan lighten-1';
      return '#26c6da';
    },
    parkingColor () {
      // return 'amber lighten-1';
      return '#ffca28';
    },
    exchangeColor () {
      // return 'purple lighten-1';
      return '#ab47bc';
    },
    returnColor () {
      // return 'blue-grey darken-2';
      return '#455a64';
    }
  },
  methods: {
    getDutyColor (status) {
      // off_duty, on_duty, sleeper, driving, and waiting.
      if (status === 'off_duty') {
        return '#666666';
      }
      if (status === 'on_duty') {
        return 'blue';
      }
      if (status === 'sleeper') {
        return '#283593';
      }
      if (status === 'driving') {
        return 'green';
      }
      if (status === 'waiting') {
        return '90CAF9';
      }
      return '#000000';
    },
    getLocationIcon (location) {
      if (location.pickup) {
        return 'fa-people-carry';
      } else if (location.delivery) {
        return 'fa-truck-loading';
      } else if (location.parking) {
        return 'mdi-parking';
      } else if (location.exchange) {
        return 'mdi-swap-horizontal-bold';
      } else if (location.returnToHQ) {
        return 'mdi-redo-variant';
      } else {
        return 'mdi-map-marker';
      }
    },
    getLocationTypeName (location) {
      if (location.pickup) {
        return this.$root.t('Loads.Pickup');
      } else if (location.delivery) {
        return this.$root.t('Loads.Delivery');
      } else if (location.parking) {
        return this.$root.t('Loads.Parking');
      } else if (location.exchange) {
        return this.$root.t('Loads.Exchange');
      } else if (location.returnToHQ) {
        return this.$root.t('Loads.ReturnToHQ');
      } else {
        return 'N/A';
      }
    },
    getLocationColor (location, index) {
      if (index === 0) {
        return this.startLocationColor;
      }
      if (location) {
        if (location.pickup) {
          return this.pickupColor;
        } else if (location.delivery) {
          return this.deliveryColor;
        } else if (location.parking) {
          return this.parkingColor;
        } else if (location.exchange) {
          return this.exchangeColor;
        } else if (location.returnToHQ) {
          return this.returnColor;
        }
      }
      return 'grey';
    },
    activeToggle (user, currentStatus, id) {
      this.$dialog.confirm(currentStatus ? this.$root.t('User.DeactivateConfirmation') : this.$root.t('User.ActivateConfirmation')).then(dialog => {
        this.$root.userSetStatusIsActive(!currentStatus, id).then((response) => {
          this.fetchData();
          if (currentStatus) {
            this.$root.toast(this.$i18n.t('User.UserDeactivated', { user: user }), { color: 'success' });
          } else {
            this.$root.toast(this.$i18n.t('User.UserActivated', { user: user }), { color: 'success' });
          }
        });
      }).catch(() => {
      });
    },
    daysLeftToNextAnnualInspection (lastAnnualInspectionDATE, annualInspectionIntervalInDays) {
      const nowTS = parseInt(new Date().getTime() / 1000);
      const lastAnnualInspectionTS = this.getTimestampFromDateTimeString(lastAnnualInspectionDATE);
      const daysSinseLastAnnualInspection = parseInt((nowTS - lastAnnualInspectionTS) / 60 / 60 / 24);
      // console.warn(nowTS, lastAnnualInspectionDATE, daysSinseLastAnnualInspection);
      return parseInt(annualInspectionIntervalInDays - daysSinseLastAnnualInspection);
    },
    isDateExpired (dateStr) {
      const date = new Date(dateStr);
      if (date.getTime() - (((7 * 60) - new Date().getTimezoneOffset()) * 60 * 1000) >= new Date().getTime() - (((7 * 60) - new Date().getTimezoneOffset()) * 60 * 1000)) return false;
      return true;
    },
    formatDate (date) {
      if (!date) return null;
      if (date.indexOf('/') >= 0) return date;
      let [year, month, day] = date.substr(0, 10).split('-');
      return `${month}/${day}/${year}`;
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    parseDate (date) {
      if (!date) return null;
      const [month, day, year] = date.split('/');
      if (year && month && day) {
        return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      }
      return '';
    },
    daysLeft (dateStr) {
      if (!dateStr) return '';
      const date = new Date(dateStr);
      const msLeft = ((date.getTime() - (((7 * 60) - new Date().getTimezoneOffset()) * 60 * 1000) - new Date().getTime() - (((7 * 60) - new Date().getTimezoneOffset()) * 60 * 1000)));
      if (msLeft >= 0) {
        return this.$root.t('User.DaysLeft', { n: Math.round((msLeft / 1000) / 60 / 60 / 24) });
      }
      return this.$root.t('User.DaysOverdue', { n: Math.abs(Math.round((msLeft / 1000) / 60 / 60 / 24)) });
    },
    getDocumentsFromField (field, folder, name) {
      let ret = [];
      if (!field) return ret;
      let _d = JSON.parse(field);
      // this.$store.getters.token + '/' + folder + '/' + file + '/' + encodeURIComponent(filename);
      if (_d) {
        for (let k = 0; k < _d.length; k++) {
          if (_d[k].name === '' || _d[k].hash === '' || _d[k].name.split('.') <= 1) continue;
          ret.push({
            name: name,
            folder: folder,
            filename: _d[k].name,
            hash: _d[k].hash,
            isPdf: !!_d[k].name.split('.').pop().match(/pdf/gi),
            url: this.$root.getImageUrl(folder, _d[k].hash)
          });
        }
      }
      return ret;
    },
    newLineToBR (value) {
      if (value && value.length > 0) {
        return value.replace(/(?:\r\n|\r|\n)/g, '<br/>');
      }
      return '';
    },
    metersToMiles (d) {
      return Math.round(d / 1609.34);
    },
    secondsToHms (d) {
      d = Number(d);
      let h = Math.floor(d / 3600);
      let m = Math.floor(d % 3600 / 60);
      let s = Math.floor(d % 3600 % 60);
      return { hours: String(h).padStart(2, '0'), minutes: String(m).padStart(2, '0'), second: String(s).padStart(2, '0') };
    },
    hmsToSeconds (hms) {
      const a = hms.toString().split(':');
      return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    },
    showDuration (duration, withDays = false) {
      if (!duration) {
        return '0 ' + this.$root.t('minutesShort');
      }
      const d = this.secondsToHms(duration);
      let ret = '';
      if (d.hours > 24 && withDays) {
        ret = Math.floor(d.hours / 24) + 'd ' + (d.hours % 24) + 'h';
        if (d.minutes > 0) {
          ret += ' ' + d.minutes + 'm';
        }
      } else if (d.hours > 0) {
        // ret = String(d.hours).padStart(2, '0') + ':' + String(d.minutes).padStart(2, '0');
        ret = d.hours + 'h';
        if (d.minutes > 0) {
          ret += ' ' + d.minutes + 'm';
        }
      } else {
        ret = d.minutes + ' ' + this.$root.t('minutesShort');
      }
      return ret;
    },
    // TODO update to use momentjs
    getCurrentTimestamp () {
      const minutesOffset = this.$store.getters.timezoneOffsetMinutes;
      const jsTimestamp = new Date().getTime() - ((new Date().getTimezoneOffset() + minutesOffset) * 60 * 1000);
      return parseInt(jsTimestamp / 1000);
    },
    getTimestampFromDateTimeString (string) {
      const minutesOffset = this.$store.getters.timezoneOffsetMinutes;
      // console.log(minutesOffset);
      const result = this.$moment.moment.utc(string).subtract(minutesOffset, 'minutes');
      // console.log(result);
      return result.format('X');
    },
    getDateTimeStringFromTimestamp (timestampInSec, applyTimezone = true) {
      const minutesOffset = applyTimezone ? parseInt(this.$store.getters.timezoneOffsetMinutes) : 0;
      const dateTime = new Date((parseInt(timestampInSec) + (minutesOffset * 60)) * 1000);
      let hours = dateTime.getUTCHours();
      let minutes = dateTime.getUTCMinutes();
      let ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours || 12; // the hour '0' should be '12'
      hours = String(hours).padStart(2, '0');
      minutes = String(minutes).padStart(2, '0');

      return String(dateTime.getUTCMonth() + 1).padStart(2, '0') + '/' +
             String(dateTime.getUTCDate()).padStart(2, '0') + '/' +
             dateTime.getUTCFullYear() + ' ' + hours + ':' + minutes + ' ' + ampm;
    },
    openExternal (path) {
      window.open(window.location.origin + path, '_blank');
    },
    htmlEncodeSpecialCharacters (str) {
      str = str.replace(/&/g, '&amp;');
      str = str.replace(/>/g, '&gt;');
      str = str.replace(/</g, '&lt;');
      str = str.replace(/"/g, '&quot;');
      str = str.replace(/'/g, '&#039;');
      return str;
    },
    getEmailFromEmailHeaderFormat (emailHeader) {
      return emailHeader;
    },
    extractEmailFromEmailHeaderFormat (emailHeader) {
      let emailHeaderArray = emailHeader.replace(/,/g, '').replace(/\"/g, '').trim().match(/[^@<\s]+@[^@\s>]+/g);
      return emailHeaderArray && emailHeaderArray.length > 0 ? emailHeaderArray[0] : emailHeader;
    },
    copyToClipboard (text) {
      let value = text.replace('&nbsp;', ' ');
      let data = [new ClipboardItem({ 'text/plain': new Blob([value], { type: 'text/plain' }) })]
      navigator.clipboard.write(data).then(() => {
        this.$root.toast(this.$root.t('copiedToClipboard'), { color: 'info' });
      }, (e) => {
        this.$root.toast(this.$root.t('error'), { color: 'error' });
      });
      /*
      let temp = document.createElement('input');
      document.body.focus();
      document.body.append(temp);
      temp.value = text.replace(/[\n\r]/g, ' ').replace('&nbsp;', ' ');
      temp.select();
      document.execCommand('copy');
      this.$root.toast(this.$root.t('copiedToClipboard'), { color: 'info' });
      document.body.removeChild(temp);
      */
      // $temp.remove();
      /* if (document.selection) {
        var range = document.body.createTextRange();
        range.moveToElementText(document.getElementById(containerid));
        range.select().createTextRange();
        document.execCommand("copy");

      } else if (window.getSelection) {
        var range = document.createRange();
        range.selectNode(document.getElementById(containerid));
        console.log(range);
        window.getSelection().addRange(range);
        document.execCommand("copy");
      } */
    },
    trimAddress (address) {
      let result = address.replace(/, USA/gi, '');
      if (result.split(',').length > 2) {
        result = result.substring(result.indexOf(',') + 1);
      }
      return result;
    },
    stringToColour (str) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var colour = '#';
      for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
      }
      return colour;
    },
    floatToLocaleString (v) {
      return parseFloat(parseFloat(v).toFixed(2)).toLocaleString();
    },
    serviceWorkerNotification (title, options) {
      if (Notification.permission === 'granted') {
        if (process.env.NODE_ENV === 'production') {
          navigator.serviceWorker.getRegistration().then(function (reg) {
            reg.showNotification(title, {
              body: 'Here is a notification body!',
              icon: 'images/example.png',
              vibrate: [100, 50, 100],
              data: {
                dateOfArrival: Date.now(),
                primaryKey: 1
              }
            });
          });
        } else {
          // eslint-disable-next-line no-new
          new Notification(title, options);
        }
      }
    },
    showNotification (title, options) {
      if (!('Notification' in window && navigator.serviceWorker)) {
        alert('Notifications are now supported by current browser version.');
      } else if (Notification.permission === 'granted') {
        this.$root.serviceWorkerNotification(title, options);
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission((permission) => {
          if (permission === 'granted') {
            this.$root.serviceWorkerNotification(title, options);
          } else {
            alert('Notificaitons denied');
          }
        });
      } else {
        // alert('Notificaitons denied previosly');
      }
    },
    isSubscribedToPushSubscription () {
      return new Promise((resolve, reject) => {
        // Ignore when in view as mode
        if (this.$cookies.get('ogt')) {
          resolve({});
          return;
        }
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.register('/service-worker.js').then((reg) => {
            console.log('Service Worker Registered!', reg);
            if (reg.pushManager) {
              reg.pushManager.getSubscription().then((sub) => {
                if (sub === null) {
                  // Update UI to ask user to register for Push
                  console.log('Not subscribed to push service!');
                  resolve({ msg: 'Not subscribed to push service!' });
                } else {
                  // We have a subscription, update the database
                  console.log('Subscription object: ', sub);
                  resolve(sub);
                }
              });
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              // reject('No pushManager in service-worker registration');
              resolve({});
            }
          }).catch((err) => {
            console.error('Service Worker registration failed: ', err);
            reject(err);
          });
        }
      });
    },
    subscribeUser () {
      return new Promise((resolve, reject) => {
        // Ignore when in view as mode
        if (this.$cookies.get('ogt')) {
          resolve({});
          return;
        }
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.ready.then(function (reg) {
            reg.pushManager.subscribe({
              userVisibleOnly: true,
              // eslint-disable-next-line no-undef
              applicationServerKey: urlBase64ToUint8Array('BOVgzn9DR0vf1UVoU-vmNIjSS7g4_ZDyQgC-PJnXp7wve735D53yYD7oj8VPVAurBbLhLmVOzQn6S5sEiRxV13g')
            }).then(function (sub) {
              console.log('Endpoint URL: ', sub.endpoint, sub);
              resolve(sub);
            }).catch(function (e) {
              if (Notification.permission === 'denied') {
                // console.warn({ msg:'Permission for notifications was denied' });
                // eslint-disable-next-line prefer-promise-reject-errors
                resolve({ msg: 'Permission for notifications was denied' });
              } else {
                // console.warn({ msg: 'Unable to subscribe to push', e: e});
                resolve({ msg: 'Unable to subscribe to push', e: e });
              }
            });
          });
        }
      });
    },
    unSubscribeUser () {
      return new Promise((resolve, reject) => {
        // Ignore when in view as mode
        if (this.$cookies.get('ogt')) {
          resolve({});
          return;
        }
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.ready.then(function (reg) {
            reg.pushManager.getSubscription().then((subscription) => {
              subscription.unsubscribe().then((successful) => {
                // You've successfully unsubscribed
                resolve();
              }).catch((e) => {
                // Unsubscription failed
                console.error('Unsubscription failed', e);
                reject(e);
              });
            });
          });
        }
      });
    }
  }
};
