export default {
  data: function () {
  },
  methods: {
    getChartData (id) {
      return this.get('/api/chart/get/' + id);
    },
    getChartList (id) {
      return this.get('/api/chart/list');
    },
    deleteChart (id) {
      return this.post('/api/chart/delete/' + id);
    },
    updateChart (id, name, type, dataLabels, data, timeframe, url) {
      return this.post('/api/chart/edit/' + id, {
        name: name,
        type: type,
        dataLabels: dataLabels,
        data: data,
        timeframe: timeframe,
        url: url
      });
    },
    createChart (name, type, dataLabels, data, timeframe, url) {
      return this.post('/api/chart/new', {
        name: name,
        type: type,
        dataLabels: dataLabels,
        data: data,
        timeframe: timeframe,
        url: url
      });
    },
    getTypeFromId (id) {
      if (id === 1) {
        return 'area';
      } else if (id === 2) {
        return 'bar';
      }
      return 'line';
    },
    getDatasets (xAxisField = '') {
      if (xAxisField === '') {
        return this.$root.get('/api/chart/datasets');
      } else {
        return this.$root.get('/api/chart/datasets/' + xAxisField);
      }
    },
    /* getOperationById (id) {
            if (id === 1) {
                return 'AVG';
            } else if (id === 2) {
                return 'MIN';
            } else if (id === 3) {
                return 'MAX';
            }
            return 'SUM';
        }, */
    getOperationById (id) {
      if (id === 1) {
        return 'SUM';
      } else if (id === 2) {
        return 'AVG';
      } else if (id === 3) {
        return 'MIN';
      } else if (id === 4) {
        return 'MAX';
      }
      return 'COUNT';
    },
    generateChartData (charts, timeframe = 'day', minDateParam = 'null', maxDateParam = 'null') {
      return new Promise(async (resolve, reject) => {
        let categories = [];
        let seriesArray = [];
        let maxDate = '';
        let minDate = '';
        let xAxis = '';
        let yAxisFormating = '';
        for (let c = 0; c < charts.length; c++) {
          const response = await this.$root.post('/api/chart/data/' + timeframe + '/' + minDateParam + '/' + maxDateParam, {
            dataset_id: charts[c].dataset,
            operation_name: this.getOperationById(charts[c].operation)
          });
          minDate = response.min_date;
          maxDate = response.max_date;
          xAxis = response.xAxis;
          yAxisFormating = response.yAxisFormating;
          let series = {};
          let chartDataMap = {};
          // let chart_ts_data = [];

          if (response.status === 'ok') {
            for (let i = 0; i < response.result.length; i++) {
              const entry = response.result[i];
              let seriesName = charts[c].name ? charts[c].name : 'Default';
              if (entry.hasOwnProperty('seriesName')) {
                seriesName = entry['seriesName'];
              }

              // Prepare series object
              if (!series.hasOwnProperty(seriesName)) {
                series[seriesName] = {
                  name: seriesName,
                  data: []
                };
                // if type is line, area or bat do not use type in series
                if (charts[c].type <= 2) {
                  series[seriesName]['type'] = this.getTypeFromId(charts[c].type);
                }
              }

              // add x axis value if not exist
              if (!chartDataMap.hasOwnProperty(entry.x)) {
                // chart_ts_data.push(parseInt(entry.ts) * 1000)
                chartDataMap[entry.x] = {};
                chartDataMap[entry.x][seriesName] = {
                  name: seriesName,
                  data: []
                };
                // if type is line, area or bat do not use type in series
                if (charts[c].type <= 2) {
                  chartDataMap[entry.x][seriesName]['type'] = this.getTypeFromId(charts[c].type);
                }
              }
              // add new series if not exist
              if (!chartDataMap[entry.x].hasOwnProperty(seriesName)) {
                chartDataMap[entry.x][seriesName] = {
                  name: seriesName,
                  data: []
                };
                // if type is line, area or bat do not use type in series
                if (charts[c].type <= 2) {
                  chartDataMap[entry.x][seriesName]['type'] = this.getTypeFromId(charts[c].type);
                }
              }
              chartDataMap[entry.x][seriesName].data.push(parseInt(entry.y));
            }
          }

          categories = Object.keys(chartDataMap);
          /* const ckeys = Object.keys(chartDataMap);
          for(let ckey in ckeys) {
            categories.push(new Date().getTime());
          } */
          // categories = chart_ts_data;

          for (let i = 0; i < Object.keys(chartDataMap).length; i++) {
            const category = Object.keys(chartDataMap)[i];
            const seriesList = Object.keys(series);
            for (let k = 0; k < seriesList.length; k++) {
              const seriesName = seriesList[k];
              if (chartDataMap[category].hasOwnProperty(seriesName)) {
                // If data for seriesName avalable push it
                for (let j = 0; j < chartDataMap[category][seriesName].data.length; j++) {
                  const value = chartDataMap[category][seriesName].data[j];
                  series[seriesName].data.push(parseInt(value));
                }
              } else {
                // Set value to 0 if seriesName not exists
                series[seriesName].data.push(0);
              }
            }
          }

          /* console.log(chartDataMap);
          console.log(categories);
          console.log(series); */
          let orderedSeries = {};
          Object.keys(series).sort().forEach(function (key) {
            orderedSeries[key] = series[key];
          });
          for (let key in orderedSeries) {
            if (key !== 'Default' || Object.keys(orderedSeries).length <= 1) {
              seriesArray.push(orderedSeries[key]);
            }
          }
        }
        resolve({ series: seriesArray, categories: categories, min_date: minDate, max_date: maxDate, xAxis: xAxis, yAxisFormating: yAxisFormating });
      });
    }
  }
};
