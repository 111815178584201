<template>
  <div style="max-width: 800px;">
    <v-list flat subheader three-line>
      <v-subheader>{{$t('Invoices.LoadDocumentsList')}}</v-subheader>
      <v-list-item-group v-model="selected" multiple active-class="">
        <template v-if="!files || files.length ===0">
          <h3>There are no files in the selected load.</h3>
        </template>
        <template v-for="(item, index) in files">
          <v-list-item :key="'doc'+index">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title class="text-left"><strong>{{filesInfo[index].groupName}}</strong>&nbsp;&mdash;&nbsp;{{item.name}}</v-list-item-title>
                <v-list-item-subtitle>
                  <iframe v-if="filesInfo[index].url && filesInfo[index].isPdf"
                          :src="'/pdfjs/web/viewer.html?file=' + encodeURIComponent(filesInfo[index].url)"
                          width="100%"
                          :height="'500px'"></iframe>
                  <!--<embed v-if="filesInfo[index].url && filesInfo[index].isPdf" :src="filesInfo[index].url" width="100%" height="300px" />-->
                  <v-skeleton-loader type="image" style="width:auto;height:300px;" v-if="filesInfo[index].url && !filesInfo[index].isPdf && !filesInfo[index].isImageLoaded"></v-skeleton-loader>
                  <img v-img:images contain style="width:auto;max-height:300px;" :src="filesInfo[index].url" @load="()=>{filesInfo[index].isImageLoaded=true;}" v-if="filesInfo[index].url && !filesInfo[index].isPdf"></img>
                </v-list-item-subtitle>
              </v-list-item-content>

            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'LoadDocumentsList',
  props: ['fields', 'currentInvoiceDocumentsField', 'showAllFiles'],
  components: {
  },
  data: function () {
    return {
      selected: [],
      files: [],
      filesInfo: []
    };
  },
  watch: {
    selected (val) {
      this.onSelected();
    }
  },
  mounted () {
    if (this.fields) {
      this.appendFilesFromLoadFields('rateConUPLOAD', 'rateCon', this.$root.t('Loads.RateCon'));
      this.appendFilesFromLoadFields('publicRateConUPLOAD', 'publicRateCon', this.$root.t('Loads.PublicRateCon'));
      this.appendFilesFromLoadFields('doUPLOAD', 'deliveryOrder', this.$root.t('Loads.DeliveryOrder'));
      this.appendFilesFromLoadFields('bolUPLOAD', 'billOfLading', this.$root.t('Loads.BOL'));
      this.appendFilesFromLoadFields('podUPLOAD', 'proofOfDelivery', this.$root.t('Loads.POD'));
      this.appendFilesFromLoadFields('loadPhotosUPLOAD', 'loadPhotos', this.$root.t('Loads.LoadPhotos'));
    }

    if (this.currentInvoiceDocumentsField) {
      const _curDocuments = JSON.parse(this.currentInvoiceDocumentsField);
      for (let i = 0; i < this.files.length; i++) {
        for (let j = 0; j < _curDocuments.length; j++) {
          if (this.files[i].hash === _curDocuments[j].hash &&
                            this.files[i].name === _curDocuments[j].name) {
            this.selected.push(i);
          }
        }
      }
    }
  },
  methods: {
    onSelected () {
      let selectedFiles = [];
      for (let i = 0; i < this.selected.length; i++) {
        const sel = this.selected[i];
        let d = this.files[sel];
        d['uploadFolder'] = this.filesInfo[sel].uploadFolder;
        selectedFiles.push(d);
      }
      this.$emit('selected', selectedFiles);
    },
    appendFilesFromLoadFields (fieldsKey, uploadFolder, group) {
      if (this.fields.hasOwnProperty(fieldsKey) && this.fields[fieldsKey] && this.fields[fieldsKey].indexOf('[') === 0) {
        const filesJSON = JSON.parse(this.fields[fieldsKey]);
        for (let i = 0; i < filesJSON.length; i++) {
          // Show only pdfs
          if (!this.showAllFiles && !filesJSON[i].name.split('.').pop().match(/pdf/gi)) continue;
          this.files.push(filesJSON[i]);
          this.filesInfo.push({
            url: this.$root.getImageUrl(uploadFolder, filesJSON[i].hash),
            downloadUrl: this.$root.getImageUrl(uploadFolder, filesJSON[i].hash, filesJSON[i].name),
            isPdf: !!filesJSON[i].name.split('.').pop().match(/pdf/gi),
            isImageLoaded: false,
            uploadFolder: uploadFolder,
            groupName: group
          });
        }
      }
    }
  }

};
</script>
