<template>
  <v-container grid-list-md style="max-width:3840px;" class="ma-0 pa-0">
    <div v-if="error">
      <v-flex class="text-center error pa-2">
        <h1 class="title">{{error}}</h1>
      </v-flex>
    </div>
    <div v-else>
      <v-layout v-if="!hideFilters">
        <v-flex xs12>
          <FiltersPanel
            ref="filtersPanel"
            prefix="invoicesList"
            outlined
            :isExpansionPanel="$root.isMobile"
            :loading="loading"
            :items="filtersItems"
            :key="filtersKey"
            @filterBrokerKeyUp="filterBrokerSearch"
            @change="onFilterChanged"/>

        </v-flex>
      </v-layout>

      <!-- Selected filters -->
      <!--<v-chip v-if="deletedFilter > 0" close @click:close="deletedFilter = 0; fetchData()">
        {{deletedFilter}}
      </v-chip>-->
      <v-layout v-if="!hideTitle">
        <v-flex xs12 offset-md-6 md4 offset-xl-8 xl2 v-if="sendViaMailAllowed">
          <v-btn @click="showBatchSendingModal" width="100%" :disabled="selected.length === 0">
            <v-icon left>mdi-send-circle</v-icon>
            {{$t('Invoices.BatchSend')}}
          </v-btn>
        </v-flex>
        <v-flex xs12 md4 xl2 v-if="recordPaymentsAllowed">
          <v-btn @click="openBatchPaymentModal"  width="100%" :disabled="selected.length === 0">
            <v-icon left>mdi-cash-usd</v-icon>
            {{$t('Invoices.batchPaymentRecordBtn')}}
          </v-btn>
        </v-flex>
      </v-layout>

      <v-card v-if="!hideTitle">
        <v-card-title>
          <v-layout wrap>
            <v-flex :shrink="!$root.isMobile">
              <h4 v-if="$root.isMobile">
                {{$t('Invoices.List')}}
                <v-btn
                  v-if="$root.isMobile"
                  small
                  class="float-right"
                  @click="$refs.filtersPanel.showFiltersDrawer()">
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </h4>
              <h2 v-else>{{$t('Invoices.List')}}</h2>
            </v-flex>
            <v-flex v-if="!$root.isMobile" shrink>
              <v-btn small color="success" class="ml-2" @click="$root.route('/invoices/create')"><v-icon left>mdi-plus</v-icon>{{$t('Create')}}</v-btn>
            </v-flex>
            <v-flex :grow="!$root.isMobile" :xs12="$root.isMobile">
              <v-text-field
                class="pt-0"
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                dense
                outlined
                hide-details
              />
            </v-flex>
          </v-layout>
        </v-card-title>
      </v-card>

      <InvoicesTotals :totalsCounts="totalsCounts" v-if="totalsCounts && fullFinancialInformationAllowed && !hideTotals" :small="true"/>

      <v-progress-linear v-if="loading && !error && $root.isMobile" indeterminate/>
      <v-list v-if="!error && $root.isMobile">
        <v-list-item v-if="!hideTitle">
          <v-list-item-content>
              <v-switch
                data-cy="cyIsShowNotPayedSwitch"
                class="ml-2"
                v-model="isShowNotPayedSWITCH"
                @change="isShowNotPayedChanged"
                inset hide-details>
                <template v-slot:append>
                  <span class="mt-1 text-no-wrap">{{$t('Invoices.ShowNotPayed')}}</span>
                </template>
              </v-switch>
              <br/>
            <v-switch
              data-cy="cyReportMode"
              class="ml-2"
              v-model="isReportMode"
              @change="onReportModeChange"
              inset hide-details>
              <template v-slot:append>
                <span class="mt-1 text-no-wrap">{{$t('Invoices.ReportMode')}}</span>
              </template>
            </v-switch>
            <br/>
            <v-switch
              v-if="isReportMode"
              data-cy="cyDateRange"
              class="ml-2"
              v-model="isDateRangeSWITCH"
              inset>
              <template v-slot:append>
                <span class="mt-1 text-no-wrap">{{$t('Invoices.DateRange')}}</span>
              </template>
            </v-switch>
            <v-toolbar flat v-if="isReportMode">
              <v-btn icon
                     @click="onReportOpen"
                     v-if="isReportMode">
                <v-icon>mdi-file-chart</v-icon>
              </v-btn>
              <v-menu v-model="reportDATEMenu"
                      v-if="isReportMode && !isDateRangeSWITCH"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    class="ml-2"
                    style="max-width:200px"
                    v-model="reportDATEFormatted"
                    @blur="reportDATE = parseDate(reportDATEFormatted)"
                    persistent-hint v-on="on" dense outlined hide-details></v-text-field
                  >
                  <!--hint="MM/DD/YYYY" -->
                </template>
                <v-date-picker
                  @change="reportDateChange"
                  v-model="reportDATE"
                  no-title
                  @input="reportDATEMenu = false"></v-date-picker>
              </v-menu>

              <date-range-picker v-if="isReportMode && isDateRangeSWITCH"
                                 :key="dateRangePickerKey"
                                 v-model="dateRange"
                                 :options="dateRangeOptions"
                                 format="MM/DD/YYYY"
                                 :style="$vuetify.theme.dark ? {color:'#fff', border: '1px #ccc solid', padding: '10px', borderRadius: '2px'} : {border: '1px #ccc solid', padding: '10px', borderRadius: '2px'}"
                                 @input="dateRangeChange"/>
            </v-toolbar>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="itemsCount > itemsPerPage && !isReportMode && !hidePagination && !$root.isMobile">
          <Pagination v-if="itemsCount > itemsPerPage"
                      :key="'Pagination' + page"
                      :value="page"
                      :itemsCount="itemsCount"
                      :itemsPerPage="itemsPerPage"
                      @page-changed="pageChanged"></Pagination>
        </v-list-item>
      </v-list>

      <v-list v-if="!error && $root.isMobile">
        <v-list-item-group
          v-model="listSelected"
          multiple
          active-class=""
        >
          <template v-for="(item,index) in items.concat(totalItems)">
            <v-list-item :key="item.id" v-if="!item.totalRow">
              <template v-slot:default="{ active }">
                <v-list-item-icon style="margin-right: 16px;">

                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-icon>
                <v-list-item-content @click="rowClick(item.id, item.invoiceNumber)">
                  <InvoiceStatusSnippet :item="item"></InvoiceStatusSnippet>
                  <v-layout>
                    <v-flex>
                      #{{item.invoiceNumber}}
                    </v-flex>
                    <v-flex>
                      <v-list-item-subtitle class="float-right">{{item.createdPST.substring(0,10)}}</v-list-item-subtitle>
                    </v-flex>
                  </v-layout>
                  <v-list-item-subtitle class="text-no-wrap">
                    <v-layout>
                      <v-flex>
                        <b>L/N:</b>&nbsp;{{!item.loadNumber ? 'N/A' : item.loadNumber}}
                      </v-flex>
                      <v-flex class="text-right">
                        <b>{{$t('Load')}}:</b>&nbsp;<a @click.stop="$root.onShowLoadPreview(item.load_id)">#{{item.load_id}}</a>
                      </v-flex>
                    </v-layout>
                  </v-list-item-subtitle>
                  {{item.creatorName}}<br/>
                  {{item.name}}<br/>
                  <v-list-item-subtitle>
                    {{item.brokerName}}
                  </v-list-item-subtitle>
                  <br/>
                  <v-list-item-title class="text-right">
                    <v-layout>
                      <v-flex class="text-left pt-3 font-weight-bold">
                        ${{item.parentTotal > 0 ? parseFloat(parseFloat(item.total-item.parentTotal).toFixed(2)).toLocaleString() : parseFloat(item.total.toFixed(2)).toLocaleString()}}
                      </v-flex>
                      <v-flex v-if="item.scheduleNumber > 0" class="text-center pt-3">
                        #{{item.scheduleNumber}}
                      </v-flex>
                      <v-flex class="text-right">
                        <template v-if="item.parentReceivedDate > 0">
                          <v-chip v-if="item.total <= item.amountReceived + item.parentAmountReceived" class="text-no-wrap success" dense>${{parseFloat(item.amountReceived.toFixed(2)).toLocaleString()}}</v-chip>
                          <v-chip v-else class="text-no-wrap error" dense success>${{parseFloat(item.parentAmountReceived.toFixed(2)).toLocaleString()}}(-#{{parseFloat(item.total - item.amountReceived - item.parentAmountReceived).toLocaleString()}})</v-chip>
                        </template>
                        <template v-else-if="item.receivedDate > 0">
                          <v-chip v-if="item.totalWithoutFees <= item.amountReceived" class="text-no-wrap success" dense>${{parseFloat(item.factReceived.toFixed(2)).toLocaleString()}}</v-chip>
                          <v-chip v-else class="text-no-wrap error" dense success>${{parseFloat(item.factReceived.toFixed(2)).toLocaleString()}}(-${{parseFloat(item.total - item.amountReceived).toLocaleString()}})</v-chip>
                        </template>
                        <template v-else>
                          <v-chip class="text-no-wrap warning" dense>
                            ${{parseFloat(item.expectToReceive.toFixed(2)).toLocaleString()}}
                          </v-chip>
                        </template>
                      </v-flex>
                    </v-layout>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
            <v-divider :key="'invoiceDivider' + index"/>
            <!--<v-list-item v-if="item.totalRow" :key="'totalRow'+index">
                <v-list-item-content>
                  <h3>
                  <span class="text-no-wrap">
                    {{$t('Invoices.Total')}}
                    <template v-if="item.totalExpected > 0">
                      {{$t('Expected')}}/{{$t('Received')}}
                    </template>
                    <template v-else>{{$t('Received')}}</template>
                  </span>
                  <br/>
                  <span v-if="item.totalExpected > 0" class="text-no-wrap">
                    ${{parseFloat(item.totalExpected.toFixed(2).toLocaleString())}} / ${{ item.totalFactReceived.toFixed(2).toLocaleString() }}
                  </span>
                  <span  class="text-no-wrap" v-else>
                    ${{parseFloat(item.totalFactReceived.toFixed(2)).toLocaleString()}}
                    <span v-if="item.totalReceived < item.total" style="color:red;">
                      (${{parseFloat((item.totalReceived - item.total).toFixed(2)).toLocaleString()}})
                    </span>
                  </span>
                  </h3>
                </v-list-item-content>
            </v-list-item>-->
          </template>
        </v-list-item-group>
        <v-list-item v-if="itemsCount > itemsPerPage && !isReportMode && !hidePagination">
          <Pagination v-if="itemsCount > itemsPerPage"
                      :key="'Pagination' + page"
                      :value="page"
                      :itemsCount="itemsCount"
                      :itemsPerPage="itemsPerPage"
                      @page-changed="pageChanged"></Pagination>
        </v-list-item>
      </v-list>

      <v-data-table v-if="!error && !$root.isMobile"
                    style="width:100%;"
                    :headers="headers"
                    :items="items.concat(totalItems)"
                    v-model="selected"
                    :options.sync="options"
                    :footer-props="footer_props"
                    :server-items-length="itemsCount"
                    :loading="loading"
                    :items-per-page="itemsPerPage"
                    item-key="id"
                    class="elevation-1"
                    :show-select="!hideSelect"
                    dense
                    :hide-default-footer="isReportMode || hidePagination"
                    @click:row="rowClick($event.id, $event.invoiceNumber)"
        >

        <template v-slot:top>
          <v-toolbar flat v-if="!hidePagination" :style="{ backgroundColor: $root.currentTheme.background }">
            <v-switch
              v-if="isShowNotPayedSWITCH || fullFinancialInformationAllowed"
              data-cy="cyIsShowNotPayedSwitch"
              class="ml-5 mt-5"
              v-model="isShowNotPayedSWITCH"
              @change="isShowNotPayedChanged"
              inset>
              <template v-slot:append>
                <span class="mt-1 text-no-wrap">{{$t('Invoices.ShowNotPayed')}}</span>
              </template>
            </v-switch>
            <!--<v-toolbar-title>{{ $t('Invoices.ListTitle') }}</v-toolbar-title>-->
            <v-spacer></v-spacer>
            <Pagination v-if="!isReportMode && itemsCount > itemsPerPage"
                        :key="'Pagination' + page"
                        :value="page"
                        :itemsCount="itemsCount"
                        :itemsPerPage="itemsPerPage"
                        @page-changed="pageChanged"></Pagination>
            <v-spacer></v-spacer>
            <v-switch
              v-if="isReportMode"
              data-cy="cyDateRange"
              class="ml-5 mt-5"
              v-model="isDateRangeSWITCH"
              inset>
              <template v-slot:prepend>
                <span class="mt-1 text-no-wrap">{{$t('Invoices.DateRange')}}</span>
              </template>
            </v-switch>

            <template v-if="isReportMode && !isDateRangeSWITCH">
              <v-btn text icon class="ma-0" @click="incrementDate(-1)">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-menu v-model="reportDATEMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    class="ml-0"
                    style="max-width:200px"
                    v-model="reportDATEFormatted"
                    @blur="reportDATE = parseDate(reportDATEFormatted)"
                    persistent-hint v-on="on" dense outlined hide-details></v-text-field
                    >
                  <!--hint="MM/DD/YYYY" -->
                </template>
                <v-date-picker
                  @change="reportDateChange"
                  v-model="reportDATE"
                  no-title
                  @input="reportDATEMenu = false"></v-date-picker>
              </v-menu>
              <v-btn text icon class="ma-0" @click="incrementDate(1)">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </template>

            <template v-if="isReportMode && isDateRangeSWITCH">
              <v-btn text icon class="ma-0" @click="prevInterval()">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <date-range-picker :key="dateRangePickerKey"
                                 v-model="dateRange"
                                 :options="dateRangeOptions"
                                 format="MM/DD/YYYY"
                                 :style="$vuetify.theme.dark ? {color:'#fff'} : null"
                                 @input="dateRangeChange"/>
                <v-btn text icon class="ma-0" @click="nextInterval()">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </template>
            <v-btn icon
                   @click="onReportOpen"
                   :disabled="!isReportMode && selected.length === 0">
              <v-icon>mdi-file-chart</v-icon>
            </v-btn>
            <!--v-if="isReportMode || fullFinancialInformationAllowed"-->
            <v-switch
              data-cy="cyReportMode"
              class="ml-5 mt-5"
              v-model="isReportMode"
              @change="onReportModeChange"
              inset
              :label="$t('Invoices.ReportMode')"></v-switch>
          </v-toolbar>
        </template>

        <template v-slot:footer="{ props: { options: { page }} }">
          <Pagination v-if="!isReportMode && itemsCount > itemsPerPage && !hidePagination"
                      :key="'Pagination' + page"
                      :value="page"
                      :itemsCount="itemsCount"
                      :itemsPerPage="itemsPerPage"
                      @page-changed="pageChanged"></Pagination>
        </template>

        <template v-slot:item.data-table-select="{ isSelected, select, item }">
          <v-simple-checkbox v-if="!item.totalRow" :value="isSelected" @input="select($event)"></v-simple-checkbox>
        </template>

        <template v-slot:item.creator="{ item }">
          <template v-if="item.userCreated">
            <v-layout>
              <v-flex>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-avatar v-on="on"
                              @click.stop="$root.onShowUserPreview(item.userCreated, item.creatorName)"
                              style="cursor: pointer;"
                              class="ml-1"
                              :size="32"
                              :color="(item.creatorInitials && !item.creatorAvatar)?'#015aaa':null">
                      <v-img :src="$root.getImageUrl('avatar', item.creatorAvatar)" v-if="item.creatorAvatar"/>
                      <span class="white--text" v-else-if="item.creatorInitials">{{item.creatorInitials}}</span>
                      <v-icon large v-else>account_circle</v-icon>
                    </v-avatar>
                  </template>
                  <span>{{item.creatorName}}</span>
                </v-tooltip>
              </v-flex>
              <v-flex>
                <template v-if="item.createdPST">
                  {{item.createdPST.substring(0,10)}}
                  <br/>
                  {{item.createdPST.substring(11)}}
                </template>
              </v-flex>
            </v-layout>
          </template>
        </template>

        <template v-slot:item.sender="{ item }">
          <template v-if="item.userSended">
            <v-layout>
              <v-flex>
                <v-tooltip bottom v-if="$root.isOnline && $root.isInvoicesEditAllowed">
                  <template v-slot:activator="{ on }">
                    <v-avatar v-on="on"
                              @click.stop="$root.onShowUserPreview(item.userSended, item.senderName)"
                              style="cursor: pointer;"
                              class="ml-1"
                              :size="32"
                              :color="(item.senderInitials && !item.senderAvatar)?'#015aaa':null">
                      <v-img :src="$root.getImageUrl('avatar', item.senderAvatar)" v-if="item.senderAvatar"/>
                      <span class="white--text" v-else-if="item.senderInitials">{{item.senderInitials}}</span>
                      <v-icon large v-else>account_circle</v-icon>
                    </v-avatar>
                  </template>
                  <span>{{item.senderName}}</span>
                </v-tooltip>
              </v-flex>
              <v-flex>
                <template v-if="item.sentDatePST">
                  {{item.sentDatePST.substring(0,10)}}
                  <br/>
                  {{item.sentDatePST.substring(11)}}
                </template>
              </v-flex>
            </v-layout>
          </template>
        </template>

        <template v-slot:item.broker="{ item }">
          <template v-if="item.broker">
            <!--<v-layout>
              <v-flex>
                <v-avatar @click="$router.push('/user/page/'+item.broker)"
                          style="cursor: pointer;"
                          class="ml-1"
                          :size="32">
                  <img v-img:images :src="$root.getImageUrl('avatar', item.brokerAvatar)"
                         v-if="item.brokerAvatar"/>
                  <v-icon v-else>mdi-account-cash</v-icon>
                </v-avatar>
              </v-flex>
              <v-flex>
                {{item.brokerName}}
              </v-flex>
            </v-layout>-->
            <div class="text-no-wrap">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <p v-on="on" style="width:100px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                    <a @click.stop="$root.onShowUserPreview(item.broker, item.brokerName)">{{item.brokerName}}</a>
                  </p>
                </template>
                <span>{{item.brokerName}}<br/>{{item.terms}}</span>
              </v-tooltip>
            </div>
          </template>
        </template>
        <template v-slot:item.status="{ item }">
          <InvoiceStatusSnippet :item="item"></InvoiceStatusSnippet>
        </template>
        <template v-slot:item.schedule="{ item }">
          <template v-if="item.totalRow">
            <h3>
              <span class="text-no-wrap">
                {{$t('Invoices.FactoringFeesShort')}}:
              </span>
              <br/>
              <span v-if="item.expectedFactoringExpenses > 0" class="text-no-wrap">
                {{$t('ReceivedShort')}}: ${{parseFloat(item.factoringExpenses).round(2).toLocaleString()}}<br/>
                {{$t('ExpectedShort')}}: ${{ parseFloat(item.expectedFactoringExpenses).round(2).toLocaleString()}}
              </span>
              <span class="text-no-wrap" v-else>
                {{$t('ReceivedShort')}}: ${{parseFloat(item.factoringExpenses).round(2).toLocaleString()}}
              </span>
            </h3>
          </template>
          <template v-else>
            <template v-if="item.scheduleNumber > 0">#{{item.scheduleNumber}}</template>
            <template v-else-if="!item.totalRow">N/A</template>
            <br/>{{item.paymentType}}
          </template>
        </template>

        <template v-slot:item.amount="{ item }">
          <template v-if="item.totalRow">
            <h3><span class="text-no-wrap">Total:</span><br/>${{parseFloat(item.total).round(2).toLocaleString()}}</h3>
          </template>
          <template v-else>
            <span class="text-no-wrap">
              ${{item.parentTotal > 0 ? parseFloat(item.total-item.parentTotal).round(2).toLocaleString() : parseFloat(item.total).round(2).toLocaleString()}}
            </span>
          </template>
        </template>
        <template v-slot:item.received="{ item }">
          <template v-if="item.totalRow">
            <h3>
              <span v-if="item.totalExpected > 0" class="text-no-wrap">
                &nbsp;<br/>
                {{$t('ReceivedShort')}}: ${{parseFloat(item.totalFactReceived).round(2).toLocaleString()}}<br/>
                {{$t('ExpectedShort')}}: ${{parseFloat(item.totalExpected).round(2).toLocaleString()}}
              </span>
              <span  class="text-no-wrap" v-else>
                &nbsp;<br/>
                {{$t('ReceivedShort')}}: ${{parseFloat(item.totalFactReceived.round(2)).toLocaleString()}}
                <span v-if="item.totalReceived < item.total" style="color:red;">
                  (${{parseFloat((item.totalReceived - item.total).round(2)).toLocaleString()}})
                </span>
              </span>
            </h3>
          </template>
          <template v-else>
            <template v-if="item.parentReceivedDate > 0">
              <v-chip v-if="item.total <= item.amountReceived + item.parentAmountReceived" class="text-no-wrap success" dense><v-icon small class="mr-1 white--text">fa-dollar-sign</v-icon>{{parseFloat(item.amountReceived.toFixed(2)).toLocaleString()}}</v-chip>
              <v-chip v-else class="text-no-wrap error" dense success>
                <v-icon small class="mr-1 white--text">fa-dollar-sign</v-icon>
                {{parseFloat(item.parentAmountReceived.toFixed(2)).toLocaleString()}}
                (-<v-icon small class="mr-1">fa-dollar-sign</v-icon>{{parseFloat(item.total - item.amountReceived - item.parentAmountReceived).toLocaleString()}})
              </v-chip>
            </template>
            <template v-else-if="item.receivedDate > 0">
              <v-chip v-if="item.totalWithoutFees <= item.amountReceived" class="text-no-wrap success" dense><v-icon small class="mr-1 white--text">fa-dollar-sign</v-icon>{{parseFloat(item.factReceived.toFixed(2)).toLocaleString()}}</v-chip>
              <v-chip v-else class="text-no-wrap error" dense success>
                <v-icon small class="mr-1 white--text">fa-dollar-sign</v-icon>
                {{parseFloat(item.factReceived.toFixed(2)).toLocaleString()}}
                (-<v-icon small class="mr-1">fa-dollar-sign</v-icon>{{parseFloat(item.total - item.amountReceived).toLocaleString()}})
              </v-chip>
            </template>
            <template v-else>
              <v-chip class="text-no-wrap warning" dense>
                <v-icon small class="mr-1 white--text">fa-dollar-sign</v-icon>{{parseFloat(item.expectToReceive.toFixed(2)).toLocaleString()}}
              </v-chip>
            </template>
          </template>
        </template>
        <template v-slot:item.factoring="{ item }">
          <template v-if="item.totalRow">
            <h3>
              <span class="text-no-wrap">
                {{$t('Invoices.FactoringFees')}}:
              </span>
              <br/>
              <span v-if="item.expectedFactoringExpenses > 0" class="text-no-wrap">
                ${{ parseFloat(item.expectedFactoringExpenses).round(2).toLocaleString}}/${{parseFloat(item.factoringExpenses).round(2).toLocaleString()}}
              </span>
              <span class="text-no-wrap" v-else>
                ${{parseFloat(item.factoringExpenses).round(2).toLocaleString()}}
              </span>
            </h3>
          </template>
          <template v-else>
            <template v-if="item.factoring >= 0">
              <span class="text-no-wrap">
                <v-icon x-small class="mr-1">mdi-percent</v-icon>{{item.factoring}}
                <br/>
                <v-icon small class="mr-1">fa-dollar-sign</v-icon>{{parseFloat(parseFloat((item.amountReceived > 0 ? item.amountReceived : item.total) * (item.factoring / 100.0)).toFixed(2)).toLocaleString()}}
              </span>
            </template>
          </template>
        </template>
        <template v-slot:item.invoiceNumber="{ item }">
          <span v-if="item.invoiceNumber">#{{item.invoiceNumber}}</span>
        </template>
        <template v-slot:item.terms="{ item }">
          {{item.terms}}
        </template>
        <template v-slot:item.dueDate="{ item }">
          {{item.dueDate ? item.dueDate.split('T')[0] : ''}}
        </template>
        <template v-slot:item.loadUID="{ item }">
          <template v-if="!item.totalRow">
            <div class="text-no-wrap">
              {{$t('Load')}}:&nbsp;
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a v-on="on" @click.stop="$root.onShowLoadPreview(item.load_id)">#{{item.load_id}}</a>
                </template>
                <span>{{item.name}}</span>
              </v-tooltip>

              <v-tooltip bottom v-if="item.loadUID">
                <template v-slot:activator="{ on }">
                  <v-btn x-small icon v-on="on" @click.stop="$root.addNewPageTab('/loads/page/' + item.load_id)" v-if="$root.isLoadsViewAllowed">
                    <v-icon x-small class="mr-1">mdi-open-in-new</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('OpenExternal')}}</span>
              </v-tooltip>
              <template v-if="item.updateNumber > 0">
                <div class="text-no-wrap">
                  {{$t('Version')}} {{item.updateNumber}} <a @click.stop="$root.route('/invoices/page/'+item.parent)">{{$t('Invoices.parentInvoice')}}&nbsp;#{{item.parentNumber}}</a>
                </div>
              </template>
            </div>
            <div class="text-no-wrap">
              <!--{{$t('Load')}}:&nbsp;<a @click.stop="$root.route('/loads/page/'+item.load_id)">#{{item.load_id}}</a><br/>-->
              {{$t('Loads.loadNumber')}}:&nbsp;{{!item.loadNumber ? 'N/A' : item.loadNumber}}
            </div>
          </template>
        </template>
        <!--<template v-slot:item.name="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <p v-on="on" style="width:200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                {{item.name}}
              </p>
            </template>
            <span>{{item.name}}</span>
          </v-tooltip>
          <template v-if="item.updateNumber > 0">
            <div class="text-no-wrap">
              {{$t('Version')}} {{item.updateNumber}}
            </div>
          </template>
        </template>-->

        <template v-slot:item.trucks="{ item }">

          <template v-for="(truck, index) in item.trucks">
            <!--<v-layout>-->
            <!--<v-flex xs6>
              <v-avatar :size="32" tile style="cursor: pointer;" @click.stop="$root.route('/trucks/page/' + index)">
                <v-img v-if="truck.truckAvatar" :src="$root.getTruckFirstUrl(truck.truckAvatar)"></v-img>
                <v-icon v-else>fa-truck</v-icon>
              </v-avatar>
            </v-flex>-->
            <v-layout :key="'truck_' + truck.id+'_' + item.id + '_' + index">
              <a v-if="$root.isTrucksViewAllowed"
                 @click.stop="$root.onShowTruckPreview(truck.id, truck.truckName)">{{truck.truckName}}</a>
              <span v-else>{{truck.truckName}}</span>&nbsp;
            </v-layout>
            <!--</v-layout>-->
          </template>

          <v-layout>
            <template v-for="(driver, index) in item.drivers">
              <v-flex v-if="index" xs2 :key="'driver' + driver.id"><!--  && $root.isAccessRightAllowView($store.getters.menuRights['Driver'])"-->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-avatar v-on="on"
                              @click.stop="$root.onShowUserPreview(driver.id, driver.driverName)"
                              style="cursor: pointer;"
                              class="ml-1"
                              :size="24"
                              :color="(driver.driverInitials && !driver.driverAvatar)?'#015aaa':null">
                      <v-img :src="$root.getImageUrl('avatar', driver.driverAvatar)" v-if="driver.driverAvatar"/>
                      <span style="color:#fff;" v-else-if="driver.driverInitials">{{driver.driverInitials}}</span>
                      <v-icon large v-else>account_circle</v-icon>
                    </v-avatar>
                  </template>
                  <span>{{driver.driverName}}</span>
                </v-tooltip>
              </v-flex>
            </template>
          </v-layout>
        </template>

        <template v-slot:item.actions="{ item }" v-if="!isReportMode">
          <!--<v-tooltip bottom v-if="$root.isInvoicesViewAllowed">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click.stop="$root.route('/invoices/page/' + item.id)"><v-icon small class="mr-1">fa-eye</v-icon></v-btn>
            </template>
            <span>{{$t('Invoices.View')}}</span>
          </v-tooltip>-->
          <v-layout  v-if="!item.totalRow">
            <v-flex class="pa-0 pl-1">
              <VerificationButtons :item-type="$store.getters.troubleShootingTypes.INVOICES"
                                   :item-id="item.id"
                                   :small="true"
                                   :lastCommentWithFlag="item.lastTroubleShootingCommentWithFlag"
                                   @fetch="fetchData()"
                                   @refine="onTroubleshootingComment"/>
            </v-flex>
          </v-layout>
          <v-layout v-if="!item.totalRow">
            <v-flex class="pa-0" v-if="item.notes" shrink>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon small v-on="on" @click.stop="">
                    <v-icon small color="warning">mdi-alert</v-icon>
                  </v-btn>
                </template>
                <span>{{item.notes}}</span>
              </v-tooltip>
            </v-flex>
            <v-flex class="pa-0" v-if="item.isEditAllowed" shrink>
              <v-tooltip bottom v-if="$root.isOnline && $root.isInvoicesEditAllowed">
                <template v-slot:activator="{ on }">
                  <v-btn icon small v-on="on" @click.stop="$root.onShowInvoiceEditPreview(item.id, item.invoiceNumber)"><v-icon small>mdi-pen</v-icon></v-btn>
                </template>
                <span>{{$t('Invoices.Edit')}}</span>
              </v-tooltip>
            </v-flex>
            <v-flex class="pa-0" shrink>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon small v-on="on" @click.stop="$root.addNewPageTab('/invoices/page/' + item.id)" v-if="$root.isInvoicesViewAllowed">
                    <v-icon small>mdi-open-in-new</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('OpenExternal')}}</span>
              </v-tooltip>
            </v-flex>
            <v-flex class="pa-0" shrink>
              <v-tooltip bottom v-if="$root.isOnline && $root.isInvoicesEditAllowed">
                <template v-slot:activator="{ on }">
                  <v-btn icon small v-on="on" @click.stop="parseInt(item.deleted) === 0 ? deleteInvoice(item.id) : restoreInvoice(item.id)">
                    <v-icon small color="error" v-html="parseInt(item.deleted) === 0 ? 'mdi-delete' : 'mdi-delete-off'">mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <span>{{parseInt(item.deleted) === 0 ? $t('Invoices.DeleteInvoice') : $t('Invoices.RestoreInvoice')}}</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="reportTemplateModal" scrollable fullscreen persistent hide-overlay transition="dialog-bottom-transition">
      <v-card tile>
        <v-toolbar dark color="#015aaa" dense style="flex:0;" class="no-print">
          <v-btn icon dark @click="() => { reportTemplateModal=false; fetchData(); }">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <template v-if="isReportMode">
              {{$t('Invoices.InvoicesReport')}}&nbsp;{{dateRange[0]}}&nbsp;{{dateRange[0] !== dateRange[1] ? ' &mdash; ' + dateRange[1] : ''}}
            </template>
            <template v-else-if="selected.length > 0">
              {{$t('Invoices.InvoicesReport')}}
            </template>
            <v-spacer></v-spacer>
          </v-toolbar-title>
          <v-btn @click="$root.print()" text>
            <v-icon left>mdi-printer</v-icon><template v-if="!$root.isMobile">{{$t('Print')}}</template>
          </v-btn>
          <v-btn @click="downloadReport(selected.length === 0 ? items : selected)" text>
            <v-icon left>mdi-download</v-icon><template v-if="!$root.isMobile">{{$t('Download')}}</template>
          </v-btn>
          <v-btn @click="sendReport(selected.length === 0 ? items : selected)" text>
            <v-icon left>mdi-send</v-icon><template v-if="!$root.isMobile">{{$t('Send')}}</template>
          </v-btn>
          <!--<v-toolbar-items>
            <v-btn icon @click="$root.print()">
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </v-toolbar-items>-->
        </v-toolbar>
        <v-card-text class="pt-2">
          <InvoicesReportTemplate :entries="selected.length === 0 ? items : selected"
                                  :totalsCounts="selected.length === 0 ? totalItems[0] : calcTotalItem(selected)"
                                  :key="'reportTemplateKey' + (selected.length === 0 ? selected.length : items.length)"
                                  :overrideTitle="$t('Invoices.InvoicesReport') + '_' + $root.parseDate(dateRange[0]) + (dateRange[0] !== dateRange[1] ? '_' + $root.parseDate(dateRange[1]) : '')"
                                  :simplified="true"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sendViaEmailModal" scrollable fullscreen persistent hide-overlay transition="dialog-bottom-transition" v-if="sendViaMailAllowed">
      <v-card tile>
        <v-toolbar dark color="#015aaa" dense style="flex:0;">
          <v-btn icon dark @click="sendViaEmailModal=false;">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{$t('BatchSendEmailWithAttTitle')}}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <template v-if="isSentAll">
              <v-btn disabled>
                <v-icon left color="success">mdi-send-check</v-icon>{{$t('EmailsWasSent')}}
              </v-btn>
            </template>
            <template v-else>
              <v-btn @click="sendAllInvoices" dark text :loading="isSendingAll">
                <v-icon left>mdi-send</v-icon>{{$t('SendAll')}}
              </v-btn>
            </template>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form"
                  v-model="valid"
                  lazy-validation>
            <v-layout wrap>
              <v-flex xs12 md6 class="pa-2">
                <v-select
                  :label="$t('Bids.SelectSender')"
                  v-model="emailSender"
                  :items="sendersList"
                  :rules="[$root.rules.required]"
                  @change="onSendFormSenderChanged"
                  item-text="text"
                  item-value="value"
                  outlined dense
                ></v-select>
                <div style="margin-top:-20px;"></div>
              </v-flex>
              <v-flex xs12 md6 class="pa-2">
                <v-combobox
                  dense multiple
                  v-model="emailRecipient"
                  @change="onSendFormRecipientChanged"
                  :items="recipientsList"
                  :rules="[$root.rules.required]"
                  outlined :label="$t('Bids.EmailRecipient')"
                ></v-combobox>
                <div style="margin-top:-20px;"></div>
              </v-flex>
            </v-layout>
          </v-form>
          <v-expansion-panels class="mt-2" multiple v-model="sendInvoicesPanels">
            <template v-for="(item, index) in selected">
              <v-expansion-panel v-if="!item.totalRow" :key="index">
                <v-expansion-panel-header>{{item.sendTitle}}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <EmailSendForm ref="emailSendFormRef"
                                 :attFilesArray="item.attArray"
                                 :invoice="item.id"
                                 :recipientsList="recipientsList"
                                 :title="item.sendTitle"
                                 :sender="emailSender"
                                 :recipient="emailRecipient"
                                 @closed="onEmailInExpansionClosed(index)"
                  ></EmailSendForm>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="batchPaymentModal" width="300px" v-if="recordPaymentsAllowed">
      <v-card tile>
        <v-toolbar dark color="#015aaa" dense style="flex:0;">
          <v-toolbar-title>
            {{$t('Invoices.batchPaymentRecordTitle')}}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="batchPaymentModal=false;">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-2">
          <v-form ref="form"
                  v-model="valid"
                  lazy-validation>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  class="ma-1"
                  data-cy="receivedDate"
                  prepend-icon="mdi-calendar"
                  v-model="receivedDateBatch"
                  :label="$t('Invoices.receivedDate')"
                  :rules="[rules.datetimeUSA]"
                  outlined dense hide-details></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-combobox
                  class="ma-1"
                  v-model="paymentTypeBatch"
                  prepend-icon="mdi-credit-card-settings-outline"
                  :items="paymentTypesItems"
                  :label="$t('Invoices.paymentType')"
                  outlined dense hide-details></v-combobox>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-if="isBatchFactoringPayment"
                  class="ma-1"
                  data-cy="scheduleNumber"
                  v-model="scheduleNumberBatch"
                  :label="$t('Invoices.scheduleNumber')"
                  prefix="#"
                  type="number" step="1" @mousewheel.prevent="" outlined dense hide-details></v-text-field>
                <v-text-field
                  v-if="!isBatchFactoringPayment"
                  class="ma-1"
                  data-cy="fees"
                  v-model="feesBatch"
                  :label="$t('Invoices.Fees')"
                  prefix="%"
                  type="number" step="1" @mousewheel.prevent="" outlined dense hide-details></v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
          <v-card-actions>
            <v-btn color="primary"
                   width="100%"
                   :loading="recordBatchPaymentLoading"
                   @click="recordBatchPayment"><v-icon left>mdi-cash-usd</v-icon>{{$t('Invoices.RecordPayments')}}</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <TroubleShootingComments ref="troubleShootingComments"
                             :item-type="$store.getters.troubleShootingTypes.INVOICES"
                             :item-id="troubleShootingCommentsSelectedItem"
                             :onlyModal="true"
                             :key="'troubleShootingComments' + troubleShootingCommentsSelectedItem"/>

  </v-container>
</template>

<script>
import fieldAccessRightMixin from './../../mixins/fieldAccessRightsMixin';
import invoicesMixin from './../../mixins/invoicesMixin';
import EmailSendForm from './../../components/EmailSendForm.vue';
import userFunctionsMixin from './../../mixins/userFunctionsMixin';
import InvoicesReportTemplate from './../Reports/InvoicesReportTemplate.vue';
import Pagination from '../../components/Pagination';
import InvoiceStatusSnippet from '../../components/InvoiceStatusSnipet';
import FiltersPanel from '../../components/FiltersPanel';
import VerificationButtons from '../../components/VerificationButtons';
import TroubleShootingComments from '../../components/TroubleShootingComments';
import InvoicesTotals from "./../Reports/InvoicesTotals";

export default {
  props: ['role', 'responseOverride', 'hideFilters', 'hideTitle', 'hidePagination', 'hideSelect', 'hideReportMode', 'hideTotals'],
  components: {TroubleShootingComments, VerificationButtons, InvoiceStatusSnippet, EmailSendForm, InvoicesReportTemplate, Pagination, FiltersPanel, InvoicesTotals },
  mixins: [invoicesMixin, fieldAccessRightMixin, userFunctionsMixin],
  data () {
    return {
      valid: true,
      polling: null,
      loading: true,
      isMounted: false,
      error: '',
      dialog: false,
      itemsPerPage: 25,
      page: 1,
      options: {},
      selected: [],
      listSelected: [],
      sendViaEmailModal: false,
      batchPaymentModal: false,
      reportTemplateModal: false,
      isSendingAll: false,
      isSentAll: false,
      isReportMode: false,
      sendInvoicesPanels: [],
      emailRecipient: [],
      emailSender: '',
      sendersList: [],
      recipientsList: [],
      filterBroker: 0,
      filterSchedule: 0,
      filterUserCreated: 0,
      filterUserSended: 0,
      filterTruck: 0,
      dateRangePickerKey: new Date().getTime(),
      dateRangeOptions: {},
      dateRange: [],
      headers: [
        { text: this.$root.t('Invoices.Status'), value: 'status', sortable: false },
        { text: this.$root.t('Invoices.Creator'), value: 'creator', sortable: false },
        { text: this.$root.t('Invoices.SentBy'), value: 'sender', sortable: false },
        { text: this.$root.t('Invoices.TrucksDrivers'), value: 'trucks', sortable: false },
        { text: this.$root.t('Invoices.Number'), value: 'invoiceNumber', sortable: false },
        { text: this.$root.t('Invoices.Broker'), value: 'broker', sortable: false },
        // { text: this.$root.t('Invoices.DueDate'), value: 'dueDate', sortable: false },
        // { text: this.$root.t('Invoices.name'), value: 'name', sortable: false },
        { text: this.$root.t('Invoices.LoadUID'), value: 'loadUID', sortable: false },
        { text: this.$root.t('Invoices.Amount'), value: 'amount', sortable: false },
        { text: this.$root.t('Invoices.scheduleNumber'), value: 'schedule', sortable: false },
        { text: this.$root.t('Invoices.Received'), value: 'received', sortable: false },
        { text: '', value: 'actions' }
      ],
      rules: {
        datetimeUSA: value => new Date(value).getTime() ? true : false || this.$root.t('Loads.DateFormatError')
      },
      receivedDateBatch: this.$root.getDateTimeStringFromTimestamp(parseInt(this.$moment.moment().utcOffset(this.$store.getters.timezoneOffsetMinutes).format('X'))),
      paymentTypeBatch: '',
      scheduleNumberBatch: 0,
      feesBatch: 0,
      recordBatchPaymentLoading: false,
      itemsCount: 0,
      items: [],
      totalItems: [],
      deletedFilter: 0,
      statusFilter: 'All',
      deletedFilterItems: [
        { value: 0, text: 'Not deleted' },
        { value: 1, text: 'Deleted' },
        { value: 2, text: 'All' }],
      loadIdFilter: 0,
      footer_props: {
        'items-per-page-options': [10, 25, 50],
        'items-per-page-text': '',
        'disable-items-per-page': false
      },
      isShowNotPayedSWITCH: false,
      isDateRangeSWITCH: false,
      reportDATEMenu: false,
      reportDATEFormatted: '',
      reportDATE: '',
      recordPaymentsAllowed: false,
      sendViaMailAllowed: false,
      fullFinancialInformationAllowed: false,
      search: '',
      awaitingSearch: false,
      paymentTypesItems: [],
      isFilterInitialized: false,
      filtersKey: new Date().getTime(),
      troubleShootingCommentsSelectedItem: 0,
      totalsCounts: {
        totalCount: 0,
        totalPaid: 0,
        totalAmount: 0,
        totalReceived: 0,
        totalReceivedAdv: 0,
        totalFactoring: 0,
        totalExpectedFactoring: 0,
        totalExpected: 0,
        totalDrivers: {},
        totalTrucks: {}
      },
      filtersItems: [
        {
          name: 'deletedFilter',
          label: 'Deleted',
          field: 0,
          list: [
            { id: 0, name: 'Not deleted' },
            { id: 1, name: 'Deleted' },
            { od: 2, name: 'All' }
          ]
        },
        {
          name: 'statusFilter',
          label: this.$root.t('Invoices.StatusFilter'),
          field: '',
          multiple: true,
          list: [
            { id: 'New', name: 'New' },
            { od: 'Ready', name: 'Ready' },
            { od: 'Verified', name: 'Verified' },
            { od: 'Sent', name: 'Sent' },
            { od: 'Not Sent', name: 'Not Sent' },
            { od: 'Pending', name: 'Pending' },
            { od: 'Paid', name: 'Paid' },
            { od: 'Underpaid', name: 'Underpaid' },
            { od: 'Not Paid', name: 'Not Paid' },
            { od: 'Overdue', name: 'Overdue' },
            { od: 'Billed directly', name: 'Billed directly' }
          ]
        },
        {
          name: 'filterUserCreated',
          label: this.$root.t('Invoices.ListUserCreated'),
          field: '',
          multiple: true,
          list: []
        },
        {
          name: 'filterUserSended',
          label: this.$root.t('Invoices.ListUserSended'),
          field: '',
          multiple: true,
          list: []
        },
        {
          name: 'filterTruck',
          label: 'Truck',
          fields: '',
          multiple: true,
          list: [
          ]
        },
        {
          name: 'filterBroker',
          label: this.$root.t('Invoices.ListBroker'),
          fields: '',
          multiple: true,
          isSearch: true,
          list: [
          ]
        },
        {
          name: 'filterSchedule',
          label: this.$root.t('Invoices.scheduleNumber'),
          field: '',
          multiple: true,
          list: []
        },
      ]
    };
  },
  computed: {
    isBatchFactoringPayment () {
      return this.paymentTypeBatch === 'Factoring';
    },
    getSubItemColor () {
      return '#222';
    }
  },
  watch: {
    search () {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.options.page = 1;
          this.page = 1;
          this.fetchData();
          this.awaitingSearch = false;
        }, 1000);
      }
      this.awaitingSearch = true;
    },
    listSelected () {
      this.selected = [];
      this.listSelected.forEach(el => {
        this.selected.push(this.items[el]);
      });
    },
    options: {
      handler () {
        let { page, itemsPerPage } = this.options;
        if (!this.isMounted) return;
        if (itemsPerPage) {
          this.$root.setLocalStorageItem('invoicesItemsPerPage', itemsPerPage);
          this.itemsPerPage = itemsPerPage;
        }
        if (page) {
          this.$root.setLocalStorageItem('invoicesPage', page);
        }
        this.fetchData().then(data => {
          this.items = data.items;
          this.itemsCount = data.total;
        });
      },
      deep: true
    }
  },
  mounted () {
    this.$root.setLastListPath(this.$route.path);
    this.$root.contextCreate = () => {
      this.$root.route('/invoices/create');
    };

    this.$root.getGlobalSettingsField('invoicePaymentTypes').then((response) => {
      if (response.status === 'ok') {
        this.paymentTypesItems = JSON.parse(response.result);
      }
    });

    this.reportDATE = this.$moment.moment().utcOffset(this.$store.getters.timezoneOffsetMinutes).format('YYYY-MM-DD');
    this.reportDATEFormatted = this.$root.formatDate(this.reportDATE);

    if (this.$root.getLocalStorageItem('isShowNotPayedSWITCH')) {
      this.isShowNotPayedSWITCH = this.$root.getLocalStorageItem('isShowNotPayedSWITCH') === 'true';
    }

    // set saved items per page if exist
    if (this.$root.getLocalStorageItem('invoicesItemsPerPage')) {
      this.itemsPerPage = parseInt(this.$root.getLocalStorageItem('invoicesItemsPerPage'));
    }

    // set saved page if exist
    if (this.$root.getLocalStorageItem('invoicesPage')) {
      this.options.page = parseInt(this.$root.getLocalStorageItem('invoicesPage'));
      this.page = parseInt(this.$root.getLocalStorageItem('invoicesPage'));
    }

    if (this.$root.getLocalStorageItem('invoicesReportMode')) {
      this.isReportMode = parseInt(this.$root.getLocalStorageItem('invoicesReportMode')) === 1;
    }
    if (this.$root.getLocalStorageItem('invoicesReportDateRange')) {
      this.dateRange = JSON.parse(this.$root.getLocalStorageItem('invoicesReportDateRange'));
      this.dateRangeOptions.startDate = this.dateRange[0];
      this.dateRangeOptions.endDate = this.dateRange[1];
      this.reportDATE = this.$root.parseDate(this.dateRange[0]);
      this.reportDATEFormatted = this.dateRange[0];
    }

    if (this.hideReportMode) {
      this.isReportMode = false;
    }

    this.$nextTick(() => {
      this.$root.getGlobalSettingsField('invoiceDefaultRecipientsList').then((response) => {
        if (response.status === 'ok') {
          if (response.result && response.result.indexOf('[') === 0) {
            this.recipientsList = JSON.parse(response.result);
          }
        }
      });

      this.fetchData();
      this.polling = setInterval(() => {
        // Do not call interval methods if page not visible
        if (!this.$store.getters.isPageVisible) return;
        if (this.sendViaEmailModal) return;
        this.fetchData(false);
      }, this.$store.getters.pullIntervalList);

      this.isMounted = true;
    });
  },
  beforeDestroy () {
    clearInterval(this.polling);
  },
  methods: {
    downloadReport(entries) {
      const selectedStr = entries.map((item) => { return item.id; }).join(',');
      // window.open('/reports/print/invoices/' + this.$root.companyId + '/0/' + this.$root.parseDate(this.dateRange[0]) + '/' + this.$root.parseDate(this.dateRange[1]) + '/' + selectedStr, '_blank');
      this.$root.onDownloadReport('invoices', '0', this.$root.parseDate(this.dateRange[0]), this.$root.parseDate(this.dateRange[1]), selectedStr, 0, 0, true)
    },
    sendReport(entries) {
      const selectedStr = entries.map((item) => { return item.id; }).join(',');
      this.$root.onSendReport('invoices', '0', this.$root.parseDate(this.dateRange[0]), this.$root.parseDate(this.dateRange[1]), selectedStr, 0, 0, true);
    },
    clearTotals () {
      this.totalsCounts = {
        totalCount: 0,
        totalPaid: 0,
        totalAmount: 0,
        totalReceived: 0,
        totalReceivedAdv: 0,
        totalFactoring: 0,
        totalExpectedFactoring: 0,
        totalExpected: 0,
        totalDrivers: {},
        totalTrucks: {}
      };
    },
    onTroubleshootingComment (itemType, itemId) {
      this.troubleShootingCommentsSelectedItem = itemId;
      this.$nextTick(() => {
        this.$refs.troubleShootingComments.selectedCommentFlag = this.$store.getters.troubleShootingFlags.REFINE;
        this.$refs.troubleShootingComments.commentModal = true;
      });
    },
    filterBrokerSearch (val) {
      if (!val || val.length === 0) return;
      this.$root.loadUsersList(this.$store.getters.roleBroker, false, true, 1, val).then(response => {
        if (response.status === 'ok') {
          this.filterBrokersList = response.result.data.map((item) => {
            return {
              id: item.id,
              name: item.firstName + ' ' + item.lastName
            };
          });
          this.$refs.filtersPanel.filters.forEach(el => {
            if (el.name === 'filterBroker') {
              el.list = this.filterBrokersList;
            }
          });
        }
      });
    },
    onFilterChanged (e) {
      let i = 0;
      let field = '';
      let value = '';
      const filtersFields = Object.keys(e);
      for (i = 0; i < filtersFields.length; i++) {
        field = filtersFields[i];
        value = Object.prototype.hasOwnProperty.call(e, field) ? e[field] : null;
        if (value !== '') {
          this[field] = Array.isArray(value) ? value.join(',') : value;
        }
      }
      if (this.isFilterInitialized) {
        this.options.page = 1;
        this.page = 1;
        this.fetchData();
      }
      this.isFilterInitialized = true;
    },
    isShowNotPayedChanged () {
      this.$root.setLocalStorageItem('isShowNotPayedSWITCH', this.isShowNotPayedSWITCH ? 'true' : 'false');
      this.fetchData().then(() => {
        this.$set(this, 'selected', []);
      });
    },
    reportDateChange () {
      this.reportDATEFormatted = this.$root.formatDate(this.reportDATE);
      this.dateRange[0] = this.$root.formatDate(this.reportDATE);
      this.dateRange[1] = this.$root.formatDate(this.reportDATE);
      this.$root.setLocalStorageItem('invoicesReportDateRange', JSON.stringify(this.dateRange));
      this.fetchData().then(() => {
        this.$set(this, 'selected', []);
      });
    },
    pageChanged (event) {
      this.options.page = event;
      this.page = event;
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
        this.fetchData().then(() => {
          this.$set(this, 'selected', []);
        });
      }
    },
    rowClick (id, number) {
      if (!id || id === 0) return;
      // this.$root.route('/invoices/page/' + id);
      this.$root.onShowInvoicePreview(id, number);
    },
    openBatchPaymentModal () {
      this.receivedDateBatch = this.$root.getDateTimeStringFromTimestamp(parseInt(this.$moment.moment().utcOffset(this.$store.getters.timezoneOffsetMinutes).format('X')));
      this.batchPaymentModal = true;
    },
    recordBatchPayment () {
      let i = 0;
      let receivedDateTS = '';
      let fn = () => {
        return new Promise(async (resolve, reject) => {
          for (i = 0; i < this.selected.length; i++) {
            if (this.selected[i].totalRow) continue;
            receivedDateTS = this.$root.getTimestampFromDateTimeString(this.receivedDateBatch);
            await this.$root.saveInvoice({
              status: 'Paid',
              paidFuse: 1,
              scheduleNumber: this.scheduleNumberBatch,
              fees: this.feesBatch,
              amountReceived: this.selected[i]['total'],
              receivedDate: receivedDateTS,
              paymentType: this.paymentTypeBatch
            }, this.selected[i]['id'], true);
          }
          resolve();
        });
      };
      this.recordBatchPaymentLoading = true;
      fn().then((response) => {
        this.recordBatchPaymentLoading = false;
        this.batchPaymentModal = false;
        this.$set(this, 'selected', []);
      });
    },
    onReportOpen () {
      this.reportTemplateModal = true;
      if (this.isReportMode) {
        this.fetchData(false, this.selected).then(() => {
          this.$set(this, 'selected', []);
        });
      }
    },
    onReportModeChange () {
      if (this.isReportMode) {
        this.dateRange[0] = this.$moment.moment().utcOffset(this.$store.getters.timezoneOffsetMinutes).format('MM/DD/YYYY');
        this.dateRange[1] = this.$moment.moment().utcOffset(this.$store.getters.timezoneOffsetMinutes).format('MM/DD/YYYY');
        this.dateRangeOptions.startDate = this.dateRange[0];
        this.dateRangeOptions.endDate = this.dateRange[1];
        this.dateRangePickerKey = new Date().getTime();
        this.reportDATE = this.$root.parseDate(this.dateRange[0]);
        this.reportDATEFormatted = this.dateRange[0];
      } else {
        this.$root.removeFromLocalStorageItem('invoicesReportDateRange');
      }
      this.$root.setLocalStorageItem('invoicesReportMode', this.isReportMode ? 1 : 0);
      this.fetchData().then(() => {
        this.$set(this, 'selected', []);
      });
    },
    dateRangeChange () {
      if (this.dateRange && this.dateRange[0] && this.dateRange[1]) {
        this.$root.setLocalStorageItem('invoicesReportDateRange', JSON.stringify(this.dateRange));
      }
    },
    incrementDate (days) {
      this.reportDATE = this.$moment.moment(this.reportDATE).clone().add(days, 'days').format('YYYY-MM-DD');
      this.reportDATEFormatted = this.$root.formatDate(this.reportDATE);
      this.dateRange[0] = this.$root.formatDate(this.reportDATE);
      this.dateRange[1] = this.$root.formatDate(this.reportDATE);
      this.$root.setLocalStorageItem('invoicesReportDateRange', JSON.stringify(this.dateRange));
      this.fetchData().then(() => {
        this.$set(this, 'selected', []);
      });
    },
    prevInterval () {
      let diffDays = Math.abs(this.$moment.moment(this.dateRange[0]).diff(this.$moment.moment(this.dateRange[1]), 'days'));
      // .utcOffset(this.$store.getters.timezoneOffsetMinutes)
      this.dateRange[0] = this.$moment.moment(this.dateRange[0]).clone().add(diffDays * -1, 'd').format('MM/DD/YYYY');
      this.dateRange[1] = this.$moment.moment(this.dateRange[1]).clone().add(diffDays * -1, 'd').format('MM/DD/YYYY');
      this.dateRangeOptions.startDate = this.dateRange[0];
      this.dateRangeOptions.endDate = this.dateRange[1];
      this.dateRangePickerKey = new Date().getTime();
      this.$root.setLocalStorageItem('invoicesReportDateRange', JSON.stringify(this.dateRange));
      this.fetchData().then(() => {
        this.$set(this, 'selected', []);
      });
      this.$forceUpdate();
    },
    nextInterval () {
      let diffDays = Math.abs(this.$moment.moment(this.dateRange[0]).diff(this.$moment.moment(this.dateRange[1]), 'days'));
      this.dateRange[0] = this.$moment.moment(this.dateRange[0]).clone().add(diffDays, 'd').format('MM/DD/YYYY');
      this.dateRange[1] = this.$moment.moment(this.dateRange[1]).clone().add(diffDays, 'd').format('MM/DD/YYYY');
      this.dateRangeOptions.startDate = this.dateRange[0];
      this.dateRangeOptions.endDate = this.dateRange[1];
      this.dateRangePickerKey = new Date().getTime();
      this.$root.setLocalStorageItem('invoicesReportDateRange', JSON.stringify(this.dateRange));
      this.fetchData().then(() => {
        this.$set(this, 'selected', []);
      });
      this.$forceUpdate();
    },
    onSendFormSenderChanged () {
      for (let i = 0; i < this.$refs.emailSendFormRef.length; i++) {
        this.$refs.emailSendFormRef[i].emailSender = this.emailSender;
      }
    },
    onSendFormRecipientChanged () {
      for (let i = 0; i < this.$refs.emailSendFormRef.length; i++) {
        this.$refs.emailSendFormRef[i].emailRecipient = this.emailRecipient;
      }
    },
    statusFilterChanged () {
      if (this.statusFilter !== 'All') {
        this.$root.setLocalStorageItem('invoicesFilterStatus', this.statusFilter);
      } else {
        this.$root.removeFromLocalStorageItem('invoicesFilterStatus');
      }
      this.fetchData().then(() => {
        this.$set(this, 'selected', []);
      });
    },
    sendAllInvoices () {
      if (!this.$refs.form.validate()) return;
      this.$dialog.confirm(this.$root.t('Invoices.SendAllInvoicesConfirmationMsg')).then(async (dialog) => {
        this.isSendingAll = true;
        for (let i = 0; i < this.$refs.emailSendFormRef.length; i++) {
          await this.$refs.emailSendFormRef[i].sendMessage(true);
          this.$set(this.$refs.emailSendFormRef[i], 'isSent', true);
          this.$root.markInvoiceAsSent(this.selected[i].id);
          this.onEmailInExpansionClosed(i);
        }
        this.isSendingAll = false;
        this.isSentAll = true;
      });
    },
    onEmailInExpansionClosed (index) {
      for (let i = 0; i < this.sendInvoicesPanels.length; i++) {
        if (this.sendInvoicesPanels[i] === index) {
          this.sendInvoicesPanels.splice(i, 1);
        }
      }
    },
    showBatchSendingModal (){
      this.$root.getLinkedEmailsByUser(this.$store.getters.currentUser['id']).then(response => {
        const linkedAccountsList = response.result;
        for (let i = 0; i < linkedAccountsList.length; i++) {
          this.sendersList.push(linkedAccountsList[i].email);
        }
      });
      this.sendViaEmailModal = true;
    },
    deleteInvoice (id) {
      this.$dialog.confirm(this.$root.t('Invoices.DeleteConfirmationMsg')).then(dialog => {
        this.$root.deleteInvoice(id);
        this.fetchData();
      });
    },
    restoreInvoice (id) {
      this.$dialog.confirm(this.$root.t('Invoices.RestoreConfirmationMsg')).then(dialog => {
        this.$root.restoreInvoice(id);
        this.fetchData();
      });
    },
    formatDate (date) {
      if (date) {
        let [year, month, day] = date.substr(0, 10).split('-');
        return `${month}/${day}/${year}`;
      }
      return '';
    },
    calcTotalItem (items) {
      let i = 0;
      let factoringValue = 1;
      let totalItem = {
        totalRow: true,
        totalAmount: 0,
        totalFactoring: 0,
        totalExpectedFactoring: 0,
        totalReceivedAdv: 0,
        total: 0,
        totalReceived: 0,
        totalFactReceived: 0,
        totalExpected: 0,
        factoringExpenses: 0,
        expectedFactoringExpenses: 0,
        totalCount: 0,
        totalPaid: 0
      };

      for (i = 0; i < items.length; i++) {
        if (items[i].totalRow) {
          continue;
        }
        // set factoring coefficient to 1 so it will be ignored by default
        factoringValue = 1;
        // If full financial information allowed calc real factoring coef
        if (this.fullFinancialInformationAllowed) {
          factoringValue = (items[i].factoring > 0 ? items[i].factoring : 0.0) / 100.0;
        }

        let _total = items[i].parentTotal > 0 ? parseFloat(items[i].total-items[i].parentTotal) : parseFloat(items[i].total);

        totalItem['totalCount'] += 1;
        totalItem['total'] += _total
        totalItem['totalAmount'] += _total

        if (items[i].amountReceived > 0) {
          totalItem['totalPaid'] += 1;
          totalItem['totalReceived'] += items[i].amountReceived - items[i].amountUnderpaid;
          totalItem['totalFactReceived'] += items[i].factReceived;
          totalItem['totalReceivedAdv'] += items[i].factReceived;
          totalItem['factoringExpenses'] += (items[i].amountReceived * factoringValue) + items[i].fees;
          totalItem['totalFactoring'] += (items[i].amountReceived * factoringValue) + items[i].fees;
        } else {
          totalItem['totalExpected'] += items[i].expectToReceive;
          totalItem['expectedFactoringExpenses'] += (_total * factoringValue) + items[i].fees;
          totalItem['totalExpectedFactoring'] += (_total * factoringValue) + items[i].fees;
        }
      }
      return totalItem;
    },
    fetchData (showLoading = true, selected = []) {
      let isFilterDataUpdated = false;
      let factReceived = 0;
      let expectToReceive = 0;
      let factoringFees = 0;
      let i = 0;

      if (!this.isInvoicesViewAllowed) {
        this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
        this.loading = false;
        return;
      }

      return new Promise(async (resolve, reject) => {
        if (showLoading) this.loading = true;
        let { page, itemsPerPage } = this.options;
        // Set page and itemsPerPage from values, not component until page is mounted
        if (!this.isMounted) {
          page = this.page;
          itemsPerPage = this.itemsPerPage;
          this.options.page = page;
          this.options.itemsPerPage = itemsPerPage;
        }
        this.error = null;
        let _newItems = [];

        let itemsPage = this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ? this.page - 1 : page - 1;
        let itemsPP = itemsPerPage < 0 ? 0 : itemsPerPage;
        let isShowOnlyToday = this.$route.params.showOnlyToday ? 1 : 0;

        if (this.isReportMode) {
          itemsPage = 0;
          itemsPP = 9999999;
        }

        let startDateFormatted = 0;
        let endDateFormatted = 0;

        if (this.isReportMode) {
          startDateFormatted = this.parseDate(this.dateRange[0] ? this.dateRange[0] : this.$moment.moment().utcOffset(this.$store.getters.timezoneOffsetMinutes).format('MM/DD/YYYY'));
          endDateFormatted = this.parseDate(this.dateRange[1] ? this.dateRange[1] : this.$moment.moment().utcOffset(this.$store.getters.timezoneOffsetMinutes).format('MM/DD/YYYY'));
        }

        let response = {};
        if (this.responseOverride) {
          response = this.responseOverride;
        } else {
          response = await this.$root.getInvoicesList(this.deletedFilter,
            this.statusFilter,
            this.loadIdFilter,
            startDateFormatted,
            endDateFormatted,
            itemsPage || 0,
            itemsPP || this.itemsPerPage,
            this.filterBroker,
            this.filterTruck,
            this.filterUserCreated,
            this.filterUserSended,
            this.filterSchedule,
            this.isShowNotPayedSWITCH ? 1 : 0,
            isShowOnlyToday, this.search);
        }

        if (response.status && response.status === 'error')reject(response.msg);

        this.recordPaymentsAllowed = response.recordPaymentsAllowed;
        this.sendViaMailAllowed = response.sendViaMailAllowed;
        this.fullFinancialInformationAllowed = response.fullFinancialInformationAllowed;

        if (response.status === 'ok') {
          this.clearTotals();
          this.totalItems = [];
          for (i = 0; i < response.result.length; i++) {
            if (selected.length > 0) {
              if (!selected.find(e => e.id === response.result[i].id)) {
                continue;
              }
            }
            let fees = 0;
            if (parseFloat(response.result[i].fees) > 0) {
              fees = fees = response.result[i].total * parseFloat(response.result[i].fees) / 100;
            } else {
              fees = parseFloat(response.result[i].factoringScheduleFee) + parseFloat(response.result[i].factoringFee);
            }

            const sendTitle = this.$root.genInvoiceEmailSubject(response.result[i].invoiceNumber, response.result[i].total);

            let attArray = [];
            attArray.push(this.$root.genInvoiceFileAttObject(response.result[i].id, response.result[i].invoiceNumber));
            attArray = attArray.concat(this.$root.genInvoiceDocumentsAttObject(response.result[i].invoiceDocumentsUPLOAD));

            factReceived = response.result[i].amountReceived - response.result[i].amountUnderpaid - fees - (response.result[i].amountReceived * (response.result[i].factoring / 100.0));
            if (response.result[i].factoring <= 0 || !this.fullFinancialInformationAllowed) {
              factReceived = response.result[i].amountReceived - response.result[i].amountUnderpaid;
            }

            expectToReceive = response.result[i].total - fees - (response.result[i].total * (response.result[i].factoring / 100.0));
            if (response.result[i].factoring <= 0 || !this.fullFinancialInformationAllowed) {
              expectToReceive = response.result[i].total;
            }

            _newItems.push({
              totalRow: false,
              id: response.result[i].id,
              isEditAllowed: response.result[i].isEditAllowed,
              parent: response.result[i].parent,
              parentNumber: response.result[i].parentNumber,
              userCreated: response.result[i].userCreated,
              creatorName: response.result[i].creatorName,
              creatorAvatar: response.result[i].creatorAvatar,
              creatorInitials: response.result[i].creatorInitials,
              userSended: response.result[i].userSended,
              senderName: response.result[i].senderName,
              senderAvatar: response.result[i].senderAvatar,
              senderInitials: response.result[i].senderInitials,
              load_id: response.result[i].load_id,
              deleted: response.result[i].deleted,
              status: response.result[i].status,
              terms: response.result[i].terms,
              broker: response.result[i].broker,
              brokerName: response.result[i].brokerName,
              name: response.result[i].name,
              brokerDaysToPay: response.result[i].brokerDaysToPay,
              brokerAvatar: response.result[i].brokerAvatar,
              invoiceNumber: response.result[i].invoiceNumber,
              date: response.result[i].datePST,
              dueDate: this.formatDate(response.result[i].dueDate),
              sentDatePST: response.result[i].sentDatePST,
              createdPST: response.result[i].createdDatePST,
              dueLeftSeconds: response.result[i].dueLeftSeconds,
              loadUID: response.result[i].loadUID,
              loadNumber: response.result[i].loadNumber,
              scheduleNumber: response.result[i].scheduleNumber,
              amount: response.result[i].amount,
              total: response.result[i].total,
              totalWithoutFees: parseFloat(response.result[i].fees) > 0 ? (response.result[i].total - (response.result[i].total * (parseFloat(response.result[i].fees) / 100.0))) : response.result[i].total,
              factoring: response.result[i].factoring,
              amountReceived: response.result[i].amountReceived,
              parentAmountReceived: response.result[i].parentAmountReceived ? response.result[i].parentAmountReceived : 0,
              parentTotal: response.result[i].parentTotal ? response.result[i].parentTotal : 0,
              factReceived: factReceived,
              expectToReceive: expectToReceive,
              receivedDate: response.result[i].receivedDate,
              receivedDatePST: response.result[i].receivedDatePST,
              parentReceivedDate: response.result[i].parentReceivedDate ? response.result[i].parentReceivedDate : 0,
              paymentType: response.result[i].paymentType,
              notes: parseInt(response.result[i].load_id) === 0 ? response.result[i].notes : response.result[i].loadNotes,
              linked: response.result[i].linked,
              updateNumber: response.result[i].updateNumber,
              sendTitle: sendTitle,
              attArray: attArray,
              drivers: response.result[i].driversData,
              trucks: response.result[i].trucksData,
              fees: fees,
              lastTroubleShootingCommentWithFlag: response.result[i].lastTroubleShootingCommentWithFlag,
              resendRequired: response.result[i].resendRequired
            });
            this.sendInvoicesPanels.push(i);

            factoringFees = parseFloat((response.result[i].amountReceived > 0 ? response.result[i].amountReceived : response.result[i].total) * (response.result[i].factoring / 100.0));
            if (parseInt(response.result[i].factoring) >= 0) {
              factoringFees += fees;
            }
            // Calc totals
            this.totalsCounts.totalCount += 1;
            this.totalsCounts.totalPaid += parseFloat(response.result[i].amountReceived) > 0 ? 1 : 0;
            this.totalsCounts.totalAmount += response.result[i].parentTotal > 0 ? parseFloat(response.result[i].total - response.result[i].parentTotal) : parseFloat(response.result[i].total);


            this.totalsCounts.totalReceived += parseFloat(response.result[i].amountReceived - response.result[i].amountUnderpaid);
            this.totalsCounts.totalFactoring += parseFloat(response.result[i].amountReceived) > 0 ? parseFloat(factoringFees) : 0;
            this.totalsCounts.totalExpectedFactoring += parseFloat(response.result[i].amountReceived) > 0 ? 0 : parseFloat(factoringFees > 0 ? factoringFees : 0);
            this.totalsCounts.totalExpected += parseFloat(response.result[i].amountReceived) > 0 ? 0 : parseFloat(expectToReceive);
            this.totalsCounts.totalReceivedAdv += parseFloat(response.result[i].amountReceived) > 0 ? parseFloat(parseFloat(response.result[i].amountReceived - response.result[i].amountUnderpaid) - parseFloat(factoringFees)) : 0;
          }

          this.totalItems.push(this.calcTotalItem(_newItems));
          this.$set(this, 'items', _newItems);
          this.$set(this, 'itemsCount', response.count);

          isFilterDataUpdated = false;
          for (let f = 0; f < this.filtersItems.length; f++) {
            if (this.filtersItems[f].name === 'filterDispatcher') {
              if (!this.filtersItems[f].list || this.filtersItems[f].list.length === 0) {
                this.$set(this.filtersItems[f], 'list', response.dispatchersList);
                isFilterDataUpdated = true;
              }
            }

            if (this.filtersItems[f].name === 'filterSchedule') {
              if (!this.filtersItems[f].list || this.filtersItems[f].list.length === 0) {
                this.$set(this.filtersItems[f], 'list', response.schedules);
                isFilterDataUpdated = true;
              }
            }

            if (this.filtersItems[f].name === 'filterUserCreated') {
              if (!this.filtersItems[f].list || this.filtersItems[f].list.length === 0) {
                this.$set(this.filtersItems[f], 'list', response.usersCreated);
                isFilterDataUpdated = true;
              }
            }

            if (this.filtersItems[f].name === 'usersSended') {
              if (!this.filtersItems[f].list || this.filtersItems[f].list.length === 0) {
                this.$set(this.filtersItems[f], 'list', response.usersSended);
                isFilterDataUpdated = true;
              }
            }

            if (this.filtersItems[f].name === 'filterTruck') {
              if (!this.filtersItems[f].list || this.filtersItems[f].list.length === 0) {
                this.$set(this.filtersItems[f], 'list', response.trucksList);
                isFilterDataUpdated = true;
              }
            }
          }
          if (isFilterDataUpdated) {
            this.isFilterInitialized = false;
            this.filtersKey = new Date().getTime();
          }

          this.$forceUpdate();
          this.loading = false;
          resolve({ items: this.items, total: this.itemsCount });
        }
      });
    }
  }
};
</script>
