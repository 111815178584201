export default {
  methods: {
    getLoadsFeedList (page = 0, itemsPerPage = 10, filtersArray = [], selectedFilterIndex = -1, currentLocation = null, search = '', isOnlyCounts = false, lastFilterTS = 0) {
      return this.$root.post('/api/loadsFeed/list/' + page + '/' + itemsPerPage, {
        filtersArray: filtersArray,
        selectedFilterIndex: selectedFilterIndex,
        currentLocation: currentLocation || {},
        search: search,
        isOnlyCounts: isOnlyCounts,
        lastFilterTS: lastFilterTS
      });
    },
    /*
    filterEntity
    {
      filterPickupAddress:[],
      filterPickupState: [],
      filterPickupZip: [],
      filterPickupRadiusMiles: 0,
      filterDeliveryAddress: [],
      filterDeliveryState: [],
      filterDeliveryZip: [],
      filterTruckType: [],
      filterLoadType: [],
      filterBroker: [],
      filterTruckId: 0,
      filterHideExpired: 0,
      filterUseTruckLocation: 0,
      filterPickupDate: '',
      filterDeliveryDate: '',
    }
    * */
    getLoadsFeedMsg (id) {
      return this.$root.get('/api/loadsFeed/getFullMsg/' + id);
    },
    getLoadsFeed (id) {
      return this.$root.get('/api/loadsFeed/get/' + id);
    },
    loadsFeedFilterSearch (type, val) {
      return this.$root.get('/api/loadsFeed/getFilterData/' + type + '/' + val);
    }
  }
};
