var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pl-5 pa-2 mr-5"},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md6":""}},[[_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.$t('Loads.ToLocationTarget')))]),_vm._v(":  "),_c('v-icon',{staticClass:"ml-3",attrs:{"small":""}},[_vm._v("fa-route")]),_vm._v(" "+_vm._s(_vm.$root.metersToMiles(_vm.locations[_vm.index].distance).toLocaleString())+" "+_vm._s(_vm.$t('milesShort'))+"  "),_c('v-icon',{staticClass:"ml-3"},[_vm._v("mdi-clock-fast")]),_vm._v(" "+_vm._s(_vm.$root.showDuration(_vm.locations[_vm.index].duration))+" ")],(_vm.locations[_vm.index+1].startedByDriverTS > 0 && !_vm.locations[_vm.index+1].onSiteByDriverTS)?[_c('br'),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.$t('Loads.ToLocationLeft')))]),_vm._v(":  "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("fa-route")]),_vm._v(" "+_vm._s(_vm.$root.metersToMiles(_vm.locations[_vm.index+1].currentDistance).toLocaleString())+" "+_vm._s(_vm.$t('milesShort'))+" "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-clock-fast")]),_vm._v(" "+_vm._s(_vm.$root.showDuration(_vm.locations[_vm.index+1].currentDuration))+"   "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$emit('refreshDistanceAndDuration', _vm.index + 1)}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,2036465474)},[_c('span',[_vm._v(_vm._s(_vm.$t('Refresh')))])])]:_vm._e()],2),_c('v-flex',{staticClass:"text-right",attrs:{"xs12":"","md6":""}},[(!_vm.edit)?[(!_vm.locationStatusHidden &&
                        _vm.prevLocation &&
                        (_vm.prevLocation.dispatcherOnTheWayCheck > 0 || _vm.prevLocation.canceled === 1) &&
                        _vm.prevLocation.truck > 0 &&
                        _vm.prevLocation.driver > 0)?_c('v-btn',{staticClass:"mb-2",attrs:{"small":"","disabled":_vm.locations[_vm.index+1].driverOnTheWayCheck > 0 ||
                            _vm.locationStatusReadOnly ||
                            _vm.readonly ||
                            _vm.locations[_vm.index + 1].canceled === 1},on:{"click":function($event){return _vm.onStartClick(_vm.index + 1)}}},[(_vm.locations[_vm.index+1].driverOnTheWayCheck)?_c('v-icon',{attrs:{"left":"","color":"success"}},[_vm._v("mdi-check")]):_vm._e(),(_vm.locations[_vm.index+1].driverOnTheWayCheck)?_c('span',[_vm._v(_vm._s(_vm.$t('Loads.LocationStarted')))]):_c('span',[_vm._v(_vm._s(_vm.getStartBtnToString(_vm.index + 1)))]),(_vm.locations[_vm.index+1].driverOnTheWayCheck && !_vm.$vuetify.breakpoint.xs)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('At'))+" "+_vm._s(_vm.$root.getDateTimeStringFromTimestamp(_vm.locations[_vm.index+1].driverOnTheWayCheck)))]):_vm._e()],1):_vm._e(),(_vm.locations[_vm.index + 1].driverOnTheWayCheck > 0 && !_vm.locations[_vm.index + 1].driverOnSiteCheck && !_vm.locationStatusHidden)?_c('v-btn',{staticClass:"mb-2",attrs:{"icon":"","disabled":_vm.locationStatusReadOnly || _vm.readonly || _vm.locations[_vm.index + 1].canceled === 1},on:{"click":function($event){return _vm.onStartCancelClick(_vm.index + 1)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-cancel")])],1):_vm._e(),(_vm.locations[_vm.index+1].startedByDriverTS && _vm.locations[_vm.index+1].startedByDriver && _vm.usersInfo[_vm.locations[_vm.index+1].startedByDriver])?_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t('by'))+" "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();return _vm.$root.route('/user/page/' + _vm.usersInfo[_vm.locations[_vm.index+1].startedByDriver].id)}}},[_vm._v(_vm._s(_vm.usersInfo[_vm.locations[_vm.index+1].startedByDriver].name))])]):_vm._e()]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }