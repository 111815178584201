<template>
  <div>
    <!--<v-overlay :value="isLoading" :absolute="false">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>-->
    <v-text-field
      v-model="search"
      label="Search string"
      class="ma-3"
      :loading="isLoading"
      v-on:keyup.enter="doSearch(search)"
      outlined dense>
      <template v-slot:prepend-inner>
        <v-icon large>mdi-magnify</v-icon>
      </template>
      <template v-slot:append>
        <v-btn @click="doSearch(search)" small color="primary" :loading="isLoading">{{$t('Search')}}</v-btn>
      </template>
    </v-text-field>

    <v-tabs v-model="panel" show-arrows grow>
      <v-tab :key="'loadsSearch'">{{$t('LeftMenu.Loads')}} <v-badge v-if="loadsResponse && loadsResponse.hasOwnProperty('result')" :content="loadsResponse.result.length" inline/></v-tab>
      <v-tab :key="'invoicesSearch'">{{$t('LeftMenu.Invoices')}} <v-badge v-if="invoicesResponse && invoicesResponse.hasOwnProperty('result')" :content="invoicesResponse.result.length" inline/></v-tab>
      <v-tab :key="'usersSearch'">{{$t('LeftMenu.Users')}} (Not Implemented)</v-tab>
      <v-tab :key="'bidsSearch'">{{$t('LeftMenu.Bids')}} (Not Implemented)</v-tab>
      <v-tab :key="'documentsSearch'">{{$t('LeftMenu.Documents')}} (Not Implemented)</v-tab>
      <v-tab :key="'trucksSearch'">{{$t('LeftMenu.Trucks')}} (Not Implemented)</v-tab>
      <v-tab :key="'trailersSearch'">{{$t('LeftMenu.Trailers')}} (Not Implemented)</v-tab>
    </v-tabs>
    <v-tabs-items v-model="panel">
      <v-tab-item :key="'loadsSearch'">
        <LoadsList v-if="loadsResponse && loadsResponse.hasOwnProperty('result')"
                   :responseOverride="loadsResponse"
                   :hideFilters="true"
                   :hideTitle="true"
                   :hidePagination="true"
                   :hideNextActions="true"
                   :hideNotEndedLoad="true"
        />
      </v-tab-item>
      <v-tab-item :key="'invoicesSearch'">
        <InvoicesList v-if="invoicesResponse && invoicesResponse.hasOwnProperty('result')"
                      :responseOverride="invoicesResponse"
                      :hideFilters="true"
                      :hideTitle="true"
                      :hidePagination="true"
                      :hideSelect="true"
                      :hideReportMode="true"
                      :hideTotals="true"
        />
      </v-tab-item>
    </v-tabs-items>

    <!--<v-dialog v-model="bidInformationDialog" width="500">
      <BidInformation :bidId="bidInformationId" :key="'bid_dlg' + bidInformationId"></BidInformation>
    </v-dialog>

    <v-dialog v-model="loadInformationDialog" width="500">
      <LoadInformation :loadId="loadInformationId" :key="'load_dlg' + loadInformationId"></LoadInformation>
    </v-dialog>-->

  </div>
</template>

<script>
import BidInformation from './BidInformation';
import LoadInformation from './LoadInformation';
import LoadsList from './../views/Loads/List';
import InvoicesList from './../views/Invoices/List';

export default {
  props: [],
  components: { BidInformation, LoadInformation, LoadsList, InvoicesList },
  data: () => ({
    isLoading: false,
    search: '',
    panel: 'loadsSearch',
    loadsResponse: {},
    invoicesResponse: {},
    typeTimer: null
  }),
  computed: {
  },
  watch: {
    panel (val) {
      console.log(val === 1 ? 'loads' : 'inv');
    }
    /* search (val) {
      clearTimeout(this.typeTimer);
      this.typeTimer = setTimeout(() => {
        if (val.length < 1) return;
        this.doSearch(val);
      }, 500);
    } */
  },
  mounted () {
    if (this.$root.searchQuery.length > 0) {
      this.search = this.$root.searchQuery;
      this.doSearch(this.search);
    }
  },
  methods: {
    async doSearch (val) {
      let response = null;
      if (val.length < 1) return;
      this.isLoading = true;

      response = await this.$root.getLoadList(0, 0, 50, 0, 0, 0, 0, -1, true, 'all', 0, val);
      this.$set(this, 'loadsResponse', response);

      response = await this.$root.getInvoicesList(0, 'All', 0, 0, 0, 0, 50, 0, 0, 0, 0, 0, 0, 0, val);
      this.$set(this, 'invoicesResponse', response);


      /*this.$root.searchByAll(val).then((response) => {
        if (response.status === 'ok') {
          if (response.hasOwnProperty('invoicesItems')) {
            this.invoicesItems = response.invoicesItems;
          }
          if (response.hasOwnProperty('bidsItems')) {
            this.bidsItems = response.bidsItems;
          }
          if (response.hasOwnProperty('usersItems')) {
            this.usersItems = response.usersItems;
          }
        }
        this.isLoading = false;
      });*/
      this.isLoading = false;
    },
    onShowLoadInformation (id) {
      this.loadInformationId = id;
      this.loadInformationDialog = true;
    },
    onShowBidInformation (id) {
      this.bidInformationId = id;
      this.bidInformationDialog = true;
    },
    onResultRowClick (path) {
      this.$root.searchDialog = false;
      this.$root.route(path);
    }
  }
};
</script>
