<template>
  <div class="text-center">
    <UsersFormSaveCancelButtons v-if="isSaveAllowed && updatingEnabled"
                                :loading="isFieldLoading('saveBtn')"
                                @save="saveFields"
                                @cancel="$root.route('/user/page/' + userId)"/>
    <v-layout>
      <v-flex xs12 md6 offset-md-3>
        <v-btn data-cy="backBtn" v-if="!updatingEnabled" style='width:100%;margin-bottom: 10px;' @click="$emit('back')">
          <v-icon>mdi-chevron-up</v-icon>{{$t('Back')}}
        </v-btn>
      </v-flex>
    </v-layout>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-layout>
        <v-flex xs12 md6 offset-md-3>
          <v-switch
            data-cy="driverOwnerOperatorSWITCH"
            :disabled="isFieldReadOnly('driverOwnerOperatorSWITCH')"
            v-if="!isFieldHidden('driverOwnerOperatorSWITCH') && !updatingEnabled"
            class="ml-5"
            v-model="isDriverOwnerOperator"
            @change="onOwnerOperatorSwitch"
            inset
            :label="$t('User.Fields.driverOwnerOperatorSWITCH')"></v-switch>
          <!--<DriverRates
            v-if="!isFieldHidden('driverRatesJSON') || !isFieldReadOnly('driverRatesJSON')"
            :readonly="isFieldReadOnly('driverRatesJSON')"
            :currentSoloRate="fields.driverCurrentSoloRate"
            :currentTeamRate="fields.driverCurrentTeamRate"
            :driverRatesJSON="fields.driverRatesJSON"
            :userId="userId"
            @changed="(e) => {fields.driverRatesJSON = JSON.stringify(e);}"
            >
          </DriverRates>
          <br/>-->
          <v-layout wrap>
            <!--<v-flex xs12 md4>
              <v-switch
                data-cy="driverIsWorkOnPercentSWITCH"
                :disabled="isFieldReadOnly('driverIsWorkOnPercentSWITCH')"
                v-if="!isFieldHidden('driverIsWorkOnPercentSWITCH')"
                class="ml-10"
                v-model="isDriverWorkOnPercent"
                inset
                :label="$t('User.Fields.driverIsWorkOnPercentSWITCH')"></v-switch>
            </v-flex>
            <v-flex xs12 md4>
              <v-text-field
                data-cy="driverPercentRate"
                class="mb-2"
                :disabled="isFieldReadOnly('driverPercentRate')"
                v-if="fields.driverIsWorkOnPercentSWITCH && !isFieldHidden('driverPercentRate')"
                v-model="fields.driverPercentRate"
                :label="$t('User.Fields.driverPercentRate')"
                suffix="%"
                prepend-icon="fa-percent"
                type="number" step="0.1" @mousewheel.prevent="" outlined hide-details dense>
              </v-text-field>
            </v-flex>
            <v-flex xs12 md4>
              <v-text-field
                data-cy="driverTeamPercentRate"
                class="mb-2"
                :disabled="isFieldReadOnly('driverTeamPercentRate')"
                v-if="fields.driverIsWorkOnPercentSWITCH && !isFieldHidden('driverTeamPercentRate')"
                v-model="fields.driverTeamPercentRate"
                :label="$t('User.Fields.driverTeamPercentRate')"
                suffix="%"
                prepend-icon="fa-percent"
                type="number" step="0.1" @mousewheel.prevent="" outlined hide-details dense>
              </v-text-field>
            </v-flex>-->
            <v-flex xs12 md6>
              <v-menu v-if="!isFieldHidden('driverDrivingLicenseValidDATE')"
                      v-model="driverDrivingLicenseValidDATEMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    data-cy="driverDrivingLicenseValidDATE"
                    class="mr-2 mb-1"
                    :disabled="isFieldReadOnly('driverDrivingLicenseValidDATE')"
                    :loading="isFieldLoading('driverDrivingLicenseValidDATE')"
                    v-model="driverDrivingLicenseValidDATEFormatted"
                    @blur="fields.driverDrivingLicenseValidDATE = parseDate(driverDrivingLicenseValidDATEFormatted)"
                    :label="$t('User.Fields.driverDrivingLicenseValidDATE')"
                    persistent-hint prepend-icon="event" v-on="on" outlined dense></v-text-field>
                  <!--hint="MM/DD/YYYY" -->
                </template>
                <v-date-picker
                  @change="drivingLicenseDateChange"
                  v-model="fields.driverDrivingLicenseValidDATE"
                  no-title
                  @input="driverDrivingLicenseValidDATEMenu = false"></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 md6>
              <v-menu v-if="!isFieldHidden('driverMedicalCardValidDATE')"
                      v-model="driverMedicalCardValidDATEMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    data-cy="driverMedicalCardValidDATE"
                    :disabled="isFieldReadOnly('driverMedicalCardValidDATE')"
                    :loading="isFieldLoading('driverMedicalCardValidDATE')"
                    v-model="driverMedicalCardValidDATEFormatted"
                    @blur="fields.driverMedicalCardValidDATE = parseDate(driverMedicalCardValidDATEFormatted)"
                    :label="$t('User.Fields.driverMedicalCardValidDATE')"
                    persistent-hint v-on="on" outlined dense></v-text-field>
                  <!--hint="MM/DD/YYYY"-->
                </template>
                <v-date-picker
                  @change="medicalCardDateChange"
                  v-model="fields.driverMedicalCardValidDATE"
                  no-title
                  @input="driverMedicalCardValidDATEMenu = false"></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>

          <v-text-field
            data-cy="driverDrivingLicense"
            class="mb-1"
            :disabled="isFieldReadOnly('driverDrivingLicense')"
            v-if="!isFieldHidden('driverDrivingLicense')"
            v-model="fields.driverDrivingLicense"
            :label="$t('User.Fields.driverDrivingLicense')"
            prepend-icon="fa-id-card"
            type="text"
            outlined dense></v-text-field>

          <!-- UNIVERSAL USER FIELDS -->

          <v-text-field
            data-cy="phoneNumber"
            class="mb-1"
            :disabled="isFieldReadOnly('phoneNumber')"
            v-if="!isFieldHidden('phoneNumber')"
            v-model="fields.phoneNumber"
            :label="$t('User.Fields.phoneNumber')"
            prepend-icon="fa-phone"
            type="text"
            outlined dense></v-text-field>

          <v-text-field
            data-cy="SSN"
            class="mb-1"
            :disabled="isFieldReadOnly('SSN')"
            v-if="!isFieldHidden('SSN')"
            v-model="fields.SSN"
            :label="$t('User.Fields.SSN')"
            prepend-icon="fa-id-card"
            :append-icon="updatingEnabled ? (isFieldEncrypted('SSN') ? 'mdi-eye-off' : 'mdi-eye') : null"
            @click:append="decryptField('SSN',$route.params.id)"
            :type="isFieldEncrypted('SSN') ? 'password' : 'text'"
            outlined dense>
          </v-text-field>

          <v-textarea
            data-cy="paymentDetails"
            class="mb-1"
            :disabled="isFieldReadOnly('paymentDetails')"
            v-if="!isFieldHidden('paymentDetails')"
            v-model="fields.paymentDetails"
            :label="$t('User.Fields.paymentDetails')"
            prepend-icon="fa-file-invoice-dollar"
            outlined hide-details/>
        </v-flex>
      </v-layout>
    </v-form>
    <UsersFormSaveCancelButtons v-if="isSaveAllowed && updatingEnabled"
                                :loading="isFieldLoading('saveBtn')"
                                @save="saveFields"
                                @cancel="$root.route('/user/page/' + userId)"/>
  </div>
</template>

<script>

import fieldLoadingMixin from '../../mixins/fieldLoadingMixin';
import fieldAccessRightMixin from '../../mixins/fieldAccessRightsMixin';
import userFormsCommonMixin from '../../mixins/userFormsCommonMixin';
import UsersFormSaveCancelButtons from '../../components/UsersFormSaveCancelButtons';

export default {
  components: { UsersFormSaveCancelButtons },
  props: ['updatingEnabled', 'userId', 'ownerOperator', 'isOwnSettings', 'isNew'],
  mixins: [fieldLoadingMixin, fieldAccessRightMixin, userFormsCommonMixin],
  data: () => ({
    valid: true,
    requiredEnabled: true,
    fields: {
      driverCurrentSoloRate: 0,
      driverCurrentTeamRate: 0,
      paymentDetails: '',
      phoneNumber: '',
      SSN: '',
      driverOwnerOperatorSWITCH: 0,
      driverDrivingLicense: '',
      driverDrivingLicenseValidDATE: '',
      driverMedicalCardValidDATE: '',
      driverRatesJSON: '',
      driverTruck: 0,
      driverIsWorkOnPercentSWITCH: 0,
      driverPercentRate: 0,
      driverTeamPercentRate: 0
    },

    driverDrivingLicenseValidDATEMenu: false,
    driverDrivingLicenseValidDATEFormatted: '',

    driverMedicalCardValidDATEMenu: false,
    driverMedicalCardValidDATEFormatted: '',

    trucks: [],
  }),
  computed: {
    isDriverOwnerOperator: {
      set (val) {
        this.fields.driverOwnerOperatorSWITCH = val ? 1 : 0;
      },
      get () {
        return this.fields.driverOwnerOperatorSWITCH === 1;
      }
    },
    isDriverWorkOnPercent: {
      set (val) {
        this.fields.driverIsWorkOnPercentSWITCH = val ? 1 : 0;
      },
      get () {
        return this.fields.driverIsWorkOnPercentSWITCH === 1;
      }
    }
  },
  mounted () {
    if (!this.isNew) {
      this.fetchDataForSettingsModal();
    }
  },
  methods: {
    truckChanged (value) {
      this.fields.driverTruck = value;
    },
    onOwnerOperatorSwitch () {
      this.$emit('ownerOperatorEnable');
    },
    initData () {
      if (this.fields.driverDrivingLicenseValidDATE) {
        this.driverDrivingLicenseValidDATEFormatted = this.formatDate(this.fields.driverDrivingLicenseValidDATE);
      }
      if (this.fields.driverMedicalCardValidDATE) {
        this.driverMedicalCardValidDATEFormatted = this.formatDate(this.fields.driverMedicalCardValidDATE);
      }
    },
    isValid () {
      return this.$refs.form.validate();
    },
    next () {
      if (!this.$refs.form.validate()) {
        this.$root.toast(this.$i18n.t('FormValidationFailed'), { color: 'error' });
        return;
      }
      this.$emit('next');
    },
    drivingLicenseDateChange () {
      this.driverDrivingLicenseValidDATEFormatted = this.formatDate(this.fields.driverDrivingLicenseValidDATE);
    },
    medicalCardDateChange () {
      this.driverMedicalCardValidDATEFormatted = this.formatDate(this.fields.driverMedicalCardValidDATE);
    },
    formatDate (date) {
      if (!date) return null;
      let [year, month, day] = date.substr(0, 10).split('-');
      return `${month}/${day}/${year}`;
    },
    parseDate (date) {
      if (!date) return null;
      const [month, day, year] = date.split('/');
      return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    },
    setFields (fields, userRole, rights = null) {
      for (let key in this.fields) {
        this.fields[key] = fields[key] !== null ? fields[key] : '';
      }
      this.initData();
      this.loadImages();
      this.applyRights(this.isOwnSettings ? '' : userRole, this.isOwnSettings ? 'settings' : 'users', { deleteUsersDocuments: 0 });
    }
  }
};
</script>
