<template>
  <v-card :style="cardStyle" class="fill-height">
    <v-card-title class="pa-0 pl-3">
      <v-list-item-avatar tile>
        <v-img v-if="truckData.truckAvatarUPLOAD" :src="$root.getTruckFirstUrl(truckData.truckAvatarUPLOAD)"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          <h3>
            <a @click="$root.onShowTruckPreview(truckData.id, truckData.name, parseInt(truckData.isTrailer) === 1)">{{truckData.name}}</a>
            <!--<v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon small v-on="on" class="mb-1" @click.stop="$root.addNewPageTab('/trucks/page/' + truckData.id)">
                  <v-icon small>mdi-open-in-new</v-icon>
                </v-btn>
              </template>
              <span>{{$t('OpenExternal')}}</span>
            </v-tooltip>-->
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon small v-on="on" class="mb-1" @click.stop="showDynamicMap = !showDynamicMap;">
                  <v-icon small>mdi-map</v-icon>
                </v-btn>
              </template>
              <span>{{$t('ShowDynamicMap')}}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon x-small v-on="on" class="mb-1"  @click.stop="$root.onShowTruckEditPreview(truckData.id, truckData.name)" v-if="isTrucksEditAllowed && edit_icons">
                  <v-icon>mdi-pen</v-icon>
                </v-btn>
              </template>
              <span>{{$t('Edit')}}</span>
            </v-tooltip>
          </h3>
          <template v-if="truckData.truckLinkedToTruckId">
            <v-icon small>mdi-link</v-icon>
            <v-chip small class="ma-1" @click="$root.route('/trucks/page/' + truckData.truckLinkedToTruckId)"><v-icon small>mdi-truck-trailer</v-icon>{{truckData.truckLinkedToTruckName}}</v-chip>
          </template>
          <template v-if="truckData.linkedTruckId">
            <v-icon>mdi-link</v-icon>
            <v-chip small class="ma-1" @click="$root.route('/trucks/page/' + truckData.linkedTruckId)"><v-icon small>fa-truck-moving</v-icon>{{truckData.linkedTruckName}}</v-chip>
          </template>
        </v-list-item-title>
        <v-list-item-subtitle v-if="!truckData.isTrailer && truckData && truckData.truckTelemetry && truckData.truckTelemetry.locationName">
          <label v-if="$vuetify.breakpoint.xs" class="mr-2"
                 :style="{color:(truckData.truckTelemetry && truckData.truckTelemetry.speed > 0) ? '#4caf50' : '#cccccc'}"
                 v-html="(truckData.truckTelemetry && truckData.truckTelemetry.speed > 0) ? truckData.truckTelemetry.speed + ' ' + $root.t('mphShort') : '0 ' + $root.t('mphShort')"></label>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <label v-on="on">{{truckData.location}}</label>
            </template>
            <span>{{truckData.location}}</span>
          </v-tooltip>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn v-if="showCloseBtn"
               icon
               small
               @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <TooltipedChip
          v-if="!truckData.isTrailer && !$vuetify.breakpoint.xs"
          icon="mdi-speedometer"
          :text="(truckData.truckTelemetry && truckData.truckTelemetry.speed > 0) ? truckData.truckTelemetry.speed + ' ' + $t('mphShort') : '0 ' + $t('mphShort')"
          :tooltip="$t('Trucks.SpeedTooltip', { data: (truckData.truckTelemetry && truckData.truckTelemetry.speed > 0) ? truckData.truckTelemetry.speed : 0 })"
          :color="(truckData.truckTelemetry && truckData.truckTelemetry.speed > 0) ? '#4caf50' : '#cccccc'"
        ></TooltipedChip>
        <v-chip style="margin-right: 5px; border-radius: 5px; border-color: #000000 !important; border-width: 2px; border-style: solid; background-color: #ffffff;" v-if="truckData.licensePlate">
          <div class="black--text">{{truckData.licensePlate}}</div>
        </v-chip>
      </v-list-item-action>
    </v-card-title>
    <v-img v-if="(truckData.truckAvatarUPLOAD && showTrucksImages) || (!truckData.isCoordsAvailable && showDynamicMap)"
           :src="$root.getTruckFirstUrl(truckData.truckAvatarUPLOAD)"
           contain height="250px"></v-img>

    <v-card class="text-center" v-if="showDynamicMap && truckData">
      <GoogleMapLoader
        v-if="(truckData.isCoordsAvailable || truckData.samsaraid) && (!showTrucksImages || !truckData.truckAvatarUPLOAD)"
        ref="map"
        :truck="true"
        :truckId="truckData.id"
        height="250px"
        :zoom="15"
        :marker="{lat: truckData.lat, lng: truckData.lng}"
        :truckTelemetry="truckData.truckTelemetry"
        :markerName="truckData.name"/>
      <div align="center" justify="center" v-if="truckData">
        <TooltipedChip
          icon="fa-map-marker-alt"
          :text="location_address ? location_address : (truckData.truckTelemetry && truckData.truckTelemetry.locationName ? truckData.truckTelemetry.locationName : $t('UnknownAddress'))"
          :tooltip="$t('Trucks.LocationTooltip', { data: location_address ? location_address : (truckData.truckTelemetry && truckData.truckTelemetry.locationName ? truckData.truckTelemetry.locationName : $t('UnknownAddress')) })"
          :small="true"
          :smallIcon="true"
        ></TooltipedChip>
      </div>
    </v-card>

    <v-card-text class="text--primary">
      <v-carousel v-model="loadsCarousel"
                  v-if="isLoadAvailable"
                  :hide-delimiters="!isLoadsMoreThenOne"
                  delimiter-icon="mdi-dolly"
                  style="height:260px;"
                  :show-arrows="false"
                  :show-arrows-on-hover="false">
        <v-carousel-item v-for="(loadItem, index) in loadsData" :key="index">
          <v-list style="height:260px;" :light="!$vuetify.theme.dark" :dark="$vuetify.theme.dark">
            <v-list-item>
              <v-list-item-avatar tile>
                <template v-if="loadItem.loadPhotosArray.length > 0">
                  <img v-img :src="$root.getImageUrl('loadPhotos', loadItem.loadPhotosArray[0].hash)"/>
                </template>
                <v-icon large v-else>mdi-image-off</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <h4>{{loadItem.title}}</h4>
                <v-list-item-subtitle style="height: 50px;">
                  <v-layout>
                    <v-flex grow>
                    <UserSnippet :userData="{id: loadItem.dispatcher, firstName: loadItem.dispatcherFirstName, lastName: loadItem.dispatcherLastName, avatarUPLOAD: loadItem.dispatcherAvatarUPLOAD}"
                                 :avatarOnly="true"/>
                    <a class="ml-2" @click.stop="$root.onShowLoadPreview(loadItem.id)">{{$t('Load')}}&nbsp;#{{loadItem.id}}</a>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <p v-on="on" class="mb-1">
                          {{loadItem.distanceInMiles}}{{$t('milesShort')}}&nbsp;X&nbsp;${{loadItem.RatePerMile}}&nbsp;=&nbsp;${{loadItem.Rate}}
                        </p>
                      </template>
                      <span>
                        {{loadItem.distanceInMiles}}{{$t('milesShort')}}&nbsp;X&nbsp;${{loadItem.RatePerMile}}&nbsp;=&nbsp;${{loadItem.Rate}}
                      </span>
                    </v-tooltip>
                    </v-flex>
                    <v-flex shrink>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn icon small v-on="on" class="mb-1" @click.stop="$root.addNewPageTab('/loads/page/'+loadItem.id)">
                            <v-icon small>mdi-open-in-new</v-icon>
                          </v-btn>
                        </template>
                        <span>{{$t('OpenExternal')}}</span>
                      </v-tooltip>
                    </v-flex>
                  </v-layout>
                  <br/>
                  <i>{{loadItem.description}}</i>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content style="max-width: 50px;">
                <div class="text-no-wrap">
                  <v-icon v-if="loadItem.status.phaseIcon" v-html="loadItem.status.phaseIcon" :color="loadItem.status.phaseColor" class="mr-1"></v-icon>
                  <v-icon v-if="loadItem.status.stageIcon" small v-html="loadItem.status.stageIcon" :color="loadItem.status.stageColor" class="mr-1"></v-icon>
                  <v-icon v-if="loadItem.isReturnToHQ === 1" color="success" class="mr-1">mdi-redo-variant</v-icon>
                </div>
              </v-list-item-content>
              <v-list-item-content v-html="loadItem.status.status">
              </v-list-item-content>
              <NextTruckLoadSnippet :truck-id="truckData.id" :current-load-id="loadItem.id"/>
            </v-list-item>
          </v-list>
        </v-carousel-item>
      </v-carousel>
      <v-list  style="height:260px;" v-else>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon>fa-box</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            {{$t('Loads.NoLoadRightNow')}}
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider class="mb-2 mt-2"/>
      <div align="center" justify="center" class="d-flex flex-nowrap">
        <template v-if="truckData.samsaraid || truckData.keeptruckinid">
          <TooltipedChip
            v-if="truckData.hasOwnProperty('truckTelemetry') && truckData.truckTelemetry.hasOwnProperty('odometerMiles')"
            icon="mdi-counter"
            :error="!truckData.truckTelemetry.odometerMiles ? true : null"
            :text="(truckData.truckTelemetry.odometerMiles ? Number(truckData.truckTelemetry.odometerMiles.toFixed(0)).toLocaleString() : '-') + ' ' + $t('milesShort')"
            :tooltip="$t('Trucks.OdometerTooltip', { data: truckData.truckTelemetry.odometerMiles ? Number(truckData.truckTelemetry.odometerMiles.toFixed(0)).toLocaleString() : '-' })"
            :small="true"
          />

          <TooltipedChip
            v-if="truckData.samsaradata && truckData.hasOwnProperty('truckTelemetry') && truckData.truckTelemetry.hasOwnProperty('fuelPercent')"
            icon="mdi-fuel"
            :error="!truckData.truckTelemetry.fuelPercent ? true : null"
            :text="truckData.truckTelemetry.fuelPercent ? Number(truckData.truckTelemetry.fuelPercent.toFixed(1)).toLocaleString() + '%' : '-'"
            :tooltip="$t('Trucks.FuelPercentTooltip', { data: truckData.truckTelemetry.fuelPercent ? Number(truckData.truckTelemetry.fuelPercent.toFixed(1)).toLocaleString() : '-'})"
            :small="true"
          />

          <TooltipedChip
            v-if="truckData.keeptruckindata && !truckData.samsaradata && truckData.hasOwnProperty('truckTelemetry') && truckData.truckTelemetry.hasOwnProperty('fuelGalons')"
            icon="mdi-fuel"
            :error="!truckData.truckTelemetry.fuelGalons ? true : null"
            :text="truckData.truckTelemetry.fuelGalons ? Number(truckData.truckTelemetry.fuelGalons.toFixed(0)).toLocaleString() + ' ' + $t('gallonsShort') : '-'"
            :tooltip="$t('Trucks.FuelTooltip', { data: truckData.truckTelemetry.fuelGalons ? Number(truckData.truckTelemetry.fuelGalons.toFixed(0)).toLocaleString() : '-'})"
            :small="true"
          />
        </template>
        <!--
        <TooltipedChip
            icon="mdi-engine"
            :text="((truckData.truckTelemetry && truckData.truckTelemetry.engine_hours) ? Number(truckData.truckTelemetry.engine_hours.toFixed(0)).toLocaleString() : '-') + ' ' + $t('hoursShort')"
            :tooltip="$t('Trucks.EngineHoursTooltip', { data: (truckData.truckTelemetry && truckData.truckTelemetry.engine_hours) ? Number(truckData.truckTelemetry.engine_hours.toFixed(0)).toLocaleString() : '-' })"
            :small="true"
         ></TooltipedChip>
         -->
      </div>
      <div v-if="!$store.getters.isUserRoleDriverOO">
        <div v-if="truckData.drivers && truckData.drivers.length > 0">
          <template v-for="driver in truckData.drivers">
            <DriverHOSCard :userId="driver.id" v-if="driver.keeptruckinid" :hideLoading="true" :tiny="true" :key="'hos'+driver.id">
              <template v-slot:user>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-avatar v-on="on"
                              @click.stop="$root.onShowUserPreview(driver.id, driver.firstName+' '+driver.LastName)"
                              style="cursor: pointer;"
                              class="ml-1"
                              :size="32"
                              :color="(driver.FirstName && driver.LastName && !driver.avatarUPLOAD)?'#015aaa':null">
                      <v-img :src="$root.getImageUrl('avatar', driver.avatarUPLOAD)" v-if="driver.avatarUPLOAD"></v-img>
                      <span class="white--text" v-else-if="driver.FirstName && driver.LastName">{{driver.FirstName[0]+driver.LastName[0]}}</span>
                      <v-icon large v-else>account_circle</v-icon>
                    </v-avatar>
                  </template>
                  <span>{{driver.FirstName}}&nbsp;{{driver.LastName}}</span>
                </v-tooltip>
              </template>
            </DriverHOSCard>
          </template>
          <v-layout style="margin-top:18px;" v-if="truckData.drivers.length === 1">
            <!-- TODO align cards different, ere its dirty hack (( -->
            {{$t('Trucks.NoCoDrivers')}}
          </v-layout>
        </div>
        <div v-else>
          <v-layout wrap>
            <v-flex xs12 class="mt-2">
              {{$t('Trucks.NoDrivers')}}
            </v-flex>
            <v-flex xs12 class="mt-2">
              {{$t('Trucks.NoCoDrivers')}}
            </v-flex>
          </v-layout>
        </div>
      </div>
      <v-layout>
        <!--<v-flex grow v-if="truckData.keeptruckinid">
          <v-switch class="mt-1" style="height:24px;"
                    data-cy="syncDriversWithKT"
                    :disabled="isFieldReadOnly('syncDriversWithKT')"
                    v-if="!isFieldHidden('syncDriversWithKT')"
                    v-model="syncDriversWithKT"
                    @change="saveSyncDriversWithKT(truckData.id)"
                    :label="$t('Trucks.Fields.syncDriversWithKTShort')"></v-switch>
        </v-flex>-->
        <v-flex v-if="faultCodes.length > 0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="faultCodesModal = true;">
                <v-icon v-on="on" :size="24">$vuetify.icons.truckAlert</v-icon>
              </v-btn>
            </template>
            <span>{{$t('Trucks.FaultCodes')}}</span>
          </v-tooltip>
        </v-flex>
        <v-flex grow class="text-center" v-if="notifications">
          <v-tooltip bottom v-for="notification in notifications" :key="notification.msg">
            <template v-slot:activator="{ on }">
              <v-icon @click="$root.onShowTruckPreview(truckData.id, truckData.name, parseInt(truckData.isTrailer) === 1)"
                      class="mt-2"
                      v-on="on"
                      v-if="notification.type === 'error' || notification.type === 'warning' || notification.type === 'scheduled'"
                      :color="getNotificationColor(notification)"
                      v-html="getNotificationIcon(notification)"/>
            </template>
            <span>{{notification.msg}}</span>
          </v-tooltip>
        </v-flex>
        <v-flex shrink v-if="originalParts && Object.keys(originalParts).length > 0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn  v-on="on" icon @click="partsModal = true">
                <v-icon>mdi-car-cog</v-icon>
              </v-btn>
            </template>
            <span >{{$t('Trucks.MaintenanceOriginalParts')}}</span>
          </v-tooltip>
        </v-flex>
        <v-flex shrink>
          <v-btn icon @click="onTruckChat" :small="$vuetify.breakpoint.xs">
            <v-icon>mdi-chat</v-icon>
          </v-btn>
        </v-flex>
        <!--<v-flex xs1>
          <v-btn icon @click.stop="$root.onShowTruckEditPreview(truckData.id, truckData.name)" v-if="isTrucksEditAllowed && edit_icons" :small="$vuetify.breakpoint.xs">
            <v-icon>mdi-pen</v-icon>
          </v-btn>
        </v-flex>-->
        <!--<v-flex xs1>
          <v-btn icon @click.stop="deleteTruck(truckData.id)" v-if="isTrucksEditAllowed && edit_icons" color="error" :small="$vuetify.breakpoint.xs">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-flex>-->
        <v-flex xs1>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click.stop="isTruckHidden(truckData.id) ? unHideTruck(truckData.id) : hideTruck(truckData.id)" v-if="isTrucksEditAllowed && edit_icons" :small="$vuetify.breakpoint.xs">
                <v-icon v-if="isTruckHidden(truckData.id)">mdi-eye</v-icon>
                <v-icon v-else>mdi-eye-off</v-icon>
              </v-btn>
            </template>
            <span v-if="isTruckHidden(truckData.id)">{{$t('unhide')}}</span>
            <span v-else>{{$t('hide')}}</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
      <v-select
        v-model="status"
        :label="$root.t('Trucks.Status')"
        @change="onTruckStatus"
        dense outlined hide-details
        class="ml-1 mr-1"
        :items="truckStatusesItems"
      />
      <v-layout v-if="truckData.samsaraid || truckData.keeptruckinid">
        <v-flex xs6 class="text-center">
          <v-btn text @click="openSamsaraWindow" v-if="truckData.samsaraid" :dark="isNotificationHighlight">
            <v-icon small>mdi-open-in-new</v-icon>
            Samsara
          </v-btn>
        </v-flex>
        <v-flex xs6 class="text-center">
          <v-btn text @click="openKeepTruckin" v-if="truckData.keeptruckinid" :dark="isNotificationHighlight">
            <v-icon small>mdi-open-in-new</v-icon>
            KTrucking
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-dialog v-model="partsModal" max-width="480px" v-if="originalParts">
      <TruckOriginalParts :original-parts="originalParts" :truck-data="truckData" @close="partsModal=false;" :isInModal="true"/>
    </v-dialog>
    <v-dialog v-model="faultCodesModal" v-if="originalParts">
      <TruckFaultCodes :fault-codes="faultCodes" :truck-data="truckData" @close="faultCodesModal=false;" :isInModal="true"/>
    </v-dialog>
  </v-card>

</template>

<script>
import GoogleMapLoader from './GoogleMapLoader.vue';
import TooltipedChip from './TooltipedChip.vue';
import fieldAccessRightMixin from './../mixins/fieldAccessRightsMixin';
import userFunctionsMixin from './../mixins/userFunctionsMixin';
import DriverHOSCard from './../components/DriverHOSCard.vue';
import UserSnippet from './UserSnippet';
import NextTruckLoadSnippet from './NextTruckLoadSnippet';
import TruckOriginalParts from './TruckOriginalParts';
import TruckFaultCodes from './TruckFaultCodes';

export default {
  name: 'TruckCard',
  props: ['truckData', 'showTrucksImages', 'edit_icons', 'loadsData', 'notifications', 'showMap', 'showCloseBtn', 'originalParts'],
  components: { TruckFaultCodes, TruckOriginalParts, NextTruckLoadSnippet, UserSnippet, GoogleMapLoader, TooltipedChip, DriverHOSCard },
  mixins: [ fieldAccessRightMixin, userFunctionsMixin ],
  data: function () {
    return {
      location_address: null,
      syncDriversWithKT: false,
      loadsCarousel: 0,
      showDynamicMap: false,
      status: 0,
      partsModal: false,
      faultCodesModal: false,
      truckStatusesItems: [
        { value: 0, text: this.$root.t('Trucks.Statuses.Active') },
        { value: 10, text: this.$root.t('Trucks.Statuses.Abandoned') },
        { value: 20, text: this.$root.t('Trucks.Statuses.Maintenance') },
        { divider: true },
        { value: -1, text: this.$root.t('Trucks.Statuses.Deactivated') }
      ],
      faultCodes: []
    };
  },
  computed: {
    isLoadAvailable () {
      return this.loadsData && this.loadsData.length > 0;
    },
    isLoadsMoreThenOne () {
      return this.loadsData && this.loadsData.length > 1;
    },
    loadID () {
      return this.loadData.id;
    },
    isNotificationHighlight () {
      if (!this.notifications) return false;
      let i = 0;
      let entry = {};
      for (i = 0; i < this.notifications.length; i++) {
        entry = this.notifications[i];
        if (entry.type === 'error') {
          return true;
        }
      }
      for (i = 0; i < this.notifications.length; i++) {
        entry = this.notifications[i];
        if (entry.type === 'warning') {
          return true;
        }
      }
      return false;
    },
    cardStyle () {
      let i = 0;
      let c = '#ffffff';
      let minHeight = '550px';
      let entry = {};

      if (this.$store.getters.isUserRoleDriverOO) {
        minHeight = '400px';
      }
      if (this.$root.isDark) {
        c = 'transparent';
      }
      if (this.notifications) {
        for (i = 0; i < this.notifications.length; i++) {
          entry = this.notifications[i];
          if (entry.type === 'error') {
            return {
              boxShadow: '0px 0px 5px 5px #D50000',
              minHeight: minHeight,
              background: 'linear-gradient(' + c + ' 80%, #D50000 100%)'
            };
          }
        }
        for (i = 0; i < this.notifications.length; i++) {
          entry = this.notifications[i];
          if (entry.type === 'warning') {
            return {
              boxShadow: '0px 0px 5px 5px #FF6F00',
              minHeight: minHeight,
              background: 'linear-gradient(' + c + ' 80%, #FF6F00 100%)'
            };
          }
        }
      }
      return { minHeight: minHeight };
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.updateMarker();
      this.syncDriversWithKT = this.truckData.syncDriversWithKT === 1;
      if (this.showMap) {
        this.showDynamicMap = true;
      }
      this.status = this.truckData.status;

      if (this.truckData.hasOwnProperty('keeptruckingFaultCodes') && this.truckData.keeptruckingFaultCodes) {
        const codes = JSON.parse(this.truckData.keeptruckingFaultCodes);
        if (codes) {
          this.faultCodes = codes;
        }
      }
    });
  },
  methods: {
    getNotificationColor (notification) {
      if (notification && notification.type === 'error') return 'error';
      if (notification && notification.type === 'scheduled') return 'info';
      return 'warning';
    },
    getNotificationIcon (notification) {
      if (notification && notification.icon) return notification.icon;

      if (notification && notification.type === 'error') return 'mdi-alert';
      if (notification && notification.type === 'scheduled') return 'mdi-wrench-clock';
      return 'mdi-alert-circle';
    },
    onTruckChat () {
      const truckId = this.truckData.id;
      this.$root.createNewThreadForItem(4, truckId).then((response) => {
        if (response.status === 'ok') {
          this.$root.route('/chats/list/' + response.threadId + '/' + response.userThreadId);
        }
      });
    },
    openSamsaraWindow () {
      window.open('https://cloud.samsara.com/o/6005213/devices/' + this.truckData.samsaraid + '/vehicle', '_blank',
        'height=800,width=600,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
    },
    openKeepTruckin () {
      window.open('https://web.keeptruckin.com/#/vehicles/' + this.truckData.keeptruckinid, '_blank',
        'height=800,width=600,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
    },
    onTruckStatus () {
      this.$dialog.confirm(this.$i18n.t('Trucks.ChangeTruckStatusConfirmation')).then(dialog => {
        this.$root.setTruckStatus(this.truckData.id, this.status).then(() => {
          this.$emit('fetchData');
        });
      }).catch(e => {
        this.$emit('fetchData');
      });
    },
    deleteTruck (id) {
      this.$dialog.confirm(this.$i18n.t('Trucks.DeleteConfirmation')).then(dialog => {
        this.$root.deleteTruck(id).then((r) => {
          this.$emit('fetchData');
        });
      }).catch(() => {

      });
    },
    saveSyncDriversWithKT (id) {
      this.$root.saveTruck({ syncDriversWithKT: this.syncDriversWithKT ? '1' : '0' }, id, () => {
        this.$emit('fetchData');
      });
    },
    isTruckHidden (id) {
      return this.$root.getLocalStorageItem('truckHidden' + id);
    },
    hideTruck (id) {
      this.$root.setLocalStorageItem('truckHidden' + id, 1);
      this.$emit('fetchData');
    },
    unHideTruck (id) {
      this.$root.removeFromLocalStorageItem('truckHidden' + id);
      this.$emit('fetchData');
    },
    updateMarker () {
      if (this.$refs.map) {
        this.$refs.map.updateMarker();
        /* this.$root.getAddressByCoords(this.truckData.lat, this.truckData.lng).then((response) => {
          this.location_address = response.formatted_address.replace(', USA', '');
        }).catch(() => {
        }); */
      }
    }
  }
};
</script>
