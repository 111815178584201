<template>
  <div>
    <v-overlay :value="loading" :absolute="true">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <div v-if="error">
      <v-flex class="text-center error pa-2">
        <h1 class="title">{{error}}</h1>
      </v-flex>
    </div>
    <div v-else-if="!small && !tiny">
      <v-card v-if="!availableTime" width="100%" class="mb-1 mt-1">
        <v-card-text>
          {{$t('KeepTruckinUserEmailNoFound')}}
        </v-card-text>
      </v-card>
      <v-card v-else-if="showHOSData" width="100%" class="mb-1 mt-1">
        <v-card-title>
          <v-avatar :size="32" :color="(driverFields.fullName && !driverFields.avatar)?'#015aaa':null">
            <v-img :src="$root.getImageUrl('avatar', driverFields.avatar)" v-if="driverFields.avatar"></v-img>
            <span class="white--text" style="font-size:16px;" v-else-if="driverFields.fullName">{{driverFields.avatarLetters}}</span>
            <v-icon large v-else>account_circle</v-icon>
          </v-avatar>
          <span class="title font-weight-light">&nbsp;{{driverFields.firstName}}&nbsp;{{driverFields.lastName}}</span>
          &nbsp;&mdash;&nbsp;
          <v-chip v-if="dutyStatus.length > 0" class="white--text" :color="$root.getDutyColor(dutyStatus)">{{$t('KeepTruckin.' + dutyStatus)}}</v-chip>
        </v-card-title>
        <v-card-text>
          <v-layout>
            <v-flex xs12>
              <v-simple-table dense class="ma-1">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="text-center" style="white-space:nowrap;">
                        {{$t('KeepTruckin.timeAvailableToday')}}
                        <br/>
                        {{$root.showDuration(hoursAvailableTomorrow)}}
                      </td>
                      <td class="text-center" style="white-space:nowrap;">
                        {{$t('KeepTruckin.timeAvailableTomorrow')}}
                        <br/>
                        {{$root.showDuration(hoursAvailableTomorrow)}}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center pa-1">
                        <v-progress-circular
                          :indeterminate="false"
                          :rotate="-90"
                          :value="(availableTime.break / maxBreakTime * 100)+'%'"
                          size="100"
                          color="purple lighten-2"
                        >{{$t('KeepTruckin.break')}}<br/>{{$root.showDuration(availableTime.break)}}</v-progress-circular>
                      </td>
                      <td class="text-center pa-1">
                        <v-progress-circular
                          :indeterminate="false"
                          :rotate="-90"
                          :value="(availableTime.drive / maxDriveTime * 100)+'%'"
                          size="100"
                          color="green"
                        >{{$t('KeepTruckin.drive')}}<br/>{{$root.showDuration(availableTime.drive)}}</v-progress-circular>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center pa-1">
                        <v-progress-circular
                          :indeterminate="false"
                          :rotate="-90"
                          :value="(availableTime.shift / maxShiftTime * 100)+'%'"
                          size="100"
                          color="light-blue"
                        >{{$t('KeepTruckin.shift')}}<br/>{{$root.showDuration(availableTime.shift)}}</v-progress-circular>
                      </td>
                      <td class="text-center pa-1">
                        <v-progress-circular
                          :indeterminate="false"
                          :rotate="-90"
                          :value="(availableTime.cycle / maxCycleTime * 100)+'%'"
                          size="100"
                          color="red"
                        >{{$t('KeepTruckin.cycle')}}<br/>{{$root.showDuration(availableTime.cycle)}}</v-progress-circular>
                      </td>
                    </tr>
                    <tr v-if="recapOrdered && recapOrdered==='hide this temp'">
                      <td colspan="2" class="pa-0">
                        <!--<v-expansion-panels class="ma-0">
                          <v-expansion-panel>
                            <v-expansion-panel-header>{{$t('KeepTruckin.recap')}}</v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-simple-table width="100%">
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-center">{{$t('KeepTruckin.date')}}</th>
                                      <th class="text-center">{{$t('KeepTruckin.on_duty')}}</th>
                                      <th class="text-center">{{$t('KeepTruckin.driving')}}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(item, key) in recapOrdered" :key="'recapOrder' + key">
                                      <td class="text-center">{{key}}</td>
                                      <td class="text-center" style="white-space:nowrap;">{{$root.showDuration(item.onDutyDuration)}}</td>
                                      <td class="text-center" style="white-space:nowrap;">{{$root.showDuration(item.driveDuration)}}</td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>-->
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </div>
    <div v-else-if="availableTime">
      <v-layout>
        <v-flex xs2>
        <slot name="user"></slot>
        </v-flex>
        <v-flex xs2 class="pt-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip small v-on="on" :color="$root.getDutyColor(dutyStatus)" class="font-weight-bold white--text">
                {{$t('KeepTruckin.' + dutyStatus + '_short')}}
              </v-chip>
            </template>
            <span>{{$t('KeepTruckin.' + dutyStatus)}}</span>
          </v-tooltip>
        </v-flex>
        <v-flex xs8 class="mt-1">
          <v-layout>
            <v-flex xs3>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-chip color="purple lighten-2" class="font-weight-bold white--text" small v-on="on">
                    <v-avatar left v-if="!tiny">
                      <v-progress-circular size="20"
                                           :indeterminate="false"
                                           :rotate="-90"
                                           :value="(availableTime.break / maxBreakTime * 100)+'%'"></v-progress-circular>
                    </v-avatar>
                    {{$root.showDuration(availableTime.break)}}
                  </v-chip>
                </template>
                <span>{{$t('KeepTruckin.break')}}</span>
              </v-tooltip>
            </v-flex>
            <v-flex xs3>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-chip color="green" class="font-weight-bold white--text" small v-on="on">
                    <v-avatar left v-if="!tiny">
                      <v-progress-circular size="20"
                                           :indeterminate="false"
                                           :rotate="-90"
                                           :value="(availableTime.drive / maxDriveTime * 100)+'%'"></v-progress-circular>
                    </v-avatar>
                    {{$root.showDuration(availableTime.drive)}}
                  </v-chip>
                </template>
                <span>{{$t('KeepTruckin.drive')}}</span>
              </v-tooltip>
            </v-flex>
            <v-flex xs3>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-chip color="light-blue" class="font-weight-bold white--text" small v-on="on">
                    <v-avatar left v-if="!tiny">
                      <v-progress-circular size="20"
                                           :indeterminate="false"
                                           :rotate="-90"
                                           :value="(availableTime.shift / maxShiftTime * 100)+'%'"></v-progress-circular>
                    </v-avatar>
                    {{$root.showDuration(availableTime.shift)}}
                  </v-chip>
                </template>
                <span>{{$t('KeepTruckin.shift')}}</span>
              </v-tooltip>
            </v-flex>
            <v-flex xs3>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-chip color="red" class="font-weight-bold white--text" small v-on="on">
                    <v-avatar left v-if="!tiny">
                      <v-progress-circular size="20"
                                           :indeterminate="false"
                                           :rotate="-90"
                                           :value="(availableTime.cycle / maxCycleTime * 100)+'%'"></v-progress-circular>
                    </v-avatar>
                    {{$root.showDuration(availableTime.cycle)}}
                  </v-chip>
                </template>
                <span>{{$t('KeepTruckin.cycle')}}</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
    <DriverActivityChart v-if="showActivityChart" :driverId="userId" :height="200"/>
  </div>
</template>

<script>

import DriverActivityChart from './DriverActivityChart.vue';

export default {
  name: 'DriverHOSCard',
  props: ['userId', 'recap', 'hideLoading', 'small', 'tiny', 'preloadActivityChart', 'showActivityChart', 'showHOSData'],
  components: { DriverActivityChart },
  data: function () {
    return {
      loading: true,
      error: null,
      pooling: null,
      dutyStatus: '',
      todayDateString: '',
      todayDrivingHours: 0,
      todayOnDutyHours: 0,
      hoursAvailable: 0,
      hoursAvailableTomorrow: 0,
      availableTime: null,
      recapOrdered: null,
      driverFields: null,
      maxDriveTime: 11 * 3600,
      maxBreakTime: 8 * 3600,
      maxShiftTime: 14 * 3600,
      maxCycleTime: 70 * 3600
    };
  },
  watch: {},
  mounted () {
    this.$nextTick(() => {
      this.fetchData();
      this.polling = setInterval(() => {
        // Do not call interval methods if page not visible
        if (!this.$store.getters.isPageVisible) return;
        this.fetchData();
      }, this.$store.getters.pullInterval);
    });
  },
  beforeDestroy () {
    clearInterval(this.polling);
  },
  methods: {

    fetchData () {
      this.$root.loadUserFields(this.userId).then((response) => {
        if (response.status === 'ok') {
          this.driverFields = response.fields;
          this.driverFields['avatarLetters'] = (this.driverFields.firstName && this.driverFields.lastName) ? this.driverFields.firstName.charAt(0) + this.driverFields.lastName.charAt(0) : '';
          this.driverFields['fullName'] = this.driverFields.firstName + ' ' + this.driverFields.lastName;

          if (response.fields['keeptruckindata']) {
            const keeptruckindata = JSON.parse(response.fields['keeptruckindata']);
            if (keeptruckindata) {
              this.dutyStatus = keeptruckindata.duty_status;
              this.hoursAvailable = keeptruckindata.recap.seconds_available;
              this.hoursAvailableTomorrow = keeptruckindata.recap.seconds_tomorrow;
              this.availableTime = keeptruckindata.available_time;
              if (this.recap) {
                this.recapOrdered = {};
                let recap = {};
                for (let k in keeptruckindata.recap.on_duty_duration) {
                  const date = keeptruckindata.recap.on_duty_duration[k].date;
                  if (!recap[date]) {
                    recap[date] = {};
                  }
                  recap[date].onDutyDuration = keeptruckindata.recap.on_duty_duration[k].duration;
                }
                for (let k in keeptruckindata.recap.driving_duration) {
                  const date = keeptruckindata.recap.driving_duration[k].date;
                  if (!recap[date]) {
                    recap[date] = {};
                  }
                  recap[date].driveDuration = keeptruckindata.recap.driving_duration[k].duration;
                }
                Object.keys(recap).sort().reverse().forEach((key) => {
                  this.recapOrdered[key] = recap[key];
                });
              }
            }
          }
        }
        this.loading = false;
      });
    }
  }
};
</script>
