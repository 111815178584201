<template>
  <div>
    <template v-if="childLocations.length > 0">
      <v-subheader>Child locations</v-subheader>
      <v-list dense outlined class="mr-2">
        <template v-for="child in childLocations">
          <v-list-item :key="child.name+child.id">
            <v-list-item-icon><v-icon :color="$root.returnColor">mdi-redo-variant</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{child.name}}
                <v-btn icon small class="ml-2" @click="onDelete(child.loadId, child.id)">
                  <v-icon small color="error">mdi-delete</v-icon>
                </v-btn>
              </v-list-item-title>
              <v-list-item-title>
                <v-btn
                  small
                  class="mb-1 mt-1"
                  :disabled="child.startedByDriverTS > 0 || child.canceled === 1"
                  @click="onStatusToggleClick(child.loadId, child.id, 'startedByDriverTS')"
                >
                  {{$t('Loads.startStatus')}}
                  <span v-if="child.startedByDriverTS > 0 && !$vuetify.breakpoint.xs">&nbsp;at&nbsp;{{$root.getDateTimeStringFromTimestamp(child.startedByDriverTS)}}</span>
                </v-btn>
                <v-btn icon small
                       v-if="child.startedByDriverTS > 0"
                       :disabled="child.canceled === 1 || child.onSiteByDriverTS > 0"
                       @click="onStatusToggleClick(child.loadId, child.id, 'startedByDriverTS')"
                >
                  <v-icon color="error">mdi-cancel</v-icon>
                </v-btn>
                <span class="caption" v-if="child.startedByDriverTS > 0 && child.startedByDriver && driversInfo[child.startedByDriver]">
                    <br/>{{$t('by')}}&nbsp;<a href="#" @click.stop="$root.route('/user/page/' + driversInfo[child.startedByDriver].id)">{{driversInfo[child.startedByDriver].name}}</a>
                  </span>

                <template v-if="child.startedByDriverTS > 0">
                  <br/>
                  <v-btn
                    small
                    class="mb-1 mt-1"
                    :disabled="child.onSiteByDriverTS > 0 || child.canceled === 1"
                    @click="onStatusToggleClick(child.loadId, child.id, 'onSiteByDriverTS')"
                  >
                    {{$t('Loads.onSiteStatus')}}
                    <span v-if="child.onSiteByDriverTS > 0 && !$vuetify.breakpoint.xs">&nbsp;at&nbsp;{{$root.getDateTimeStringFromTimestamp(child.onSiteByDriverTS)}}</span>
                  </v-btn>
                  <v-btn icon small
                         v-if="child.onSiteByDriverTS > 0"
                         :disabled="child.canceled === 1 || child.approvedByDispatcherTS > 0"
                         @click="onStatusToggleClick(child.loadId, child.id, 'onSiteByDriverTS')"
                  >
                    <v-icon color="error">mdi-cancel</v-icon>
                  </v-btn>
                  <span class="caption" v-if="child.onSiteByDriverTS > 0 && child.onSiteByDriver && driversInfo[child.onSiteByDriver]">
                    <br/>{{$t('by')}}&nbsp;<a href="#" @click.stop="$root.route('/user/page/' + driversInfo[child.onSiteByDriver].id)">{{driversInfo[child.onSiteByDriver].name}}</a>
                  </span>
                </template>

                <template v-if="child.onSiteByDriverTS > 0">
                  <br/>
                  <v-btn small
                         class="mb-1 mt-1"
                         :disabled="child.approvedByDispatcherTS > 0 || child.canceled === 1"
                         @click="onStatusToggleClick(child.loadId, child.id, 'approvedByDispatcherTS')"
                  >
                    {{$t('Loads.MarkAsApproved')}}
                    <span v-if="child.approvedByDispatcherTS > 0 && !$vuetify.breakpoint.xs">&nbsp;at&nbsp;{{$root.getDateTimeStringFromTimestamp(child.approvedByDispatcherTS)}}</span>
                  </v-btn>
                  <v-btn icon small
                         v-if="child.approvedByDispatcherTS > 0"
                         :disabled="child.canceled === 1"
                         @click="onStatusToggleClick(child.loadId, child.id, 'approvedByDispatcherTS')"
                  >
                    <v-icon color="error">mdi-cancel</v-icon>
                  </v-btn>
                </template>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>

            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </template>
    <v-btn v-else-if="!isLocationSameAsParking && parentLocation.approvedByDispatcherTS > 0" @click="onInsertChildLocation(parentLocation)" small class="ml-7 mt-2" color="success">
      <v-icon left>mdi-plus</v-icon>
      {{$t('Loads.ReturnToHQLocation')}}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ChildLocationsProgress',
  props: ['childLocations', 'driversInfo', 'parentLocation'],
  data: function () {
    return {
    };
  },
  computed: {
    isLocationSameAsParking () {
      let i = 0;
      for (i = 0; i < this.$root.defaultHQPlace.length; i++) {
        if (this.$root.defaultHQPlace[i].name === this.parentLocation.name) {
          return true;
        }
      }
      return false;
    }
  },
  mounted () {
  },
  methods: {
    onInsertChildLocation (location) {
      this.$root.setDefaultHQ = async (place) => {
        const defaultHQPlace = place.name;
        const defaultHQPlaceCoords = place.location;
        this.$root.calcDistance([{ lat: location.lat, lng: location.lng }, { lat: defaultHQPlaceCoords.lat, lng: defaultHQPlaceCoords.lng }], null, false, null).then((response2) => {
          if (response2.routes && response2.routes.length > 0 && response2.routes[0].legs) {
            const legs = response2.routes[0].legs;
            console.warn(legs)
            if (legs && legs.length > 0 && legs[0].hasOwnProperty('duration') && legs[0].hasOwnProperty('distance')) {
              const _duration = this.$root.isUseGoogleMaps ? (legs[0].duration_in_traffic ? legs[0].duration_in_traffic.value : legs[0].duration.value) : legs[0].duration;
              const _distance = this.$root.isUseGoogleMaps ? legs[0].distance.value : legs[0].distance;

              this.$root.insertLocation(location, defaultHQPlaceCoords, defaultHQPlace, _duration, _distance).then((response) => {
                if (response.status === 'ok') {
                  this.$store.dispatch('loads/insertChildLocation', { location: response.result })
                }
              });
            }
          }
        });
      };
      this.$root.getDefaultHQ();
    },
    onStatusToggleClick (loadId, locationId, statusField) {
      this.$store.dispatch('loads/setChildLocationStatus', { loadId: loadId, locationId: locationId, value: statusField, userId: this.$store.getters.currentUser.id }).then((result) => {
        this.$root.setLoadLocationFieldsById(loadId, locationId, result).then((response) => {
          if (response.status === 'ok') {
            this.$store.commit('loads/updateLoadUpdated', response.updated);
          }
        });
      });
    },
    onDelete (loadId, locationId) {
      this.$dialog.confirm(this.$root.t('DeleteConfirmation')).then(dialog => {
        this.$store.dispatch('loads/removeChildLocation', { locationId: locationId }).then(() => {
          this.$root.setLoadLocationFieldsById(loadId, locationId, { deleted: 1}).then((response) => {
            if (response.status === 'ok') {
              this.$store.commit('loads/updateLoadUpdated', response.updated);
            }
          });
        });
      });
    }

  }
};
</script>
