<template>
  <div>
    <v-card style="width:400px;" :dark="false" :light="true">
      <!--<v-skeleton-loader type="image" v-if="!imgLoaded" height="150"></v-skeleton-loader>-->
      <!--<v-img :src="truckImage" @load="() => {imgLoaded = true; imgHeight='150px';}" cover width="400px" :height="imgHeight" class="white&#45;&#45;text align-end"/>-->
      <v-card-title>{{truckData.name}}</v-card-title>

      <v-card-subtitle class="pb-0 text-center" style="color:#000;">
        <v-icon>mdi-map-marker</v-icon>
        {{truckData.truckTelemetry.locationName}}
      </v-card-subtitle>
      <v-card-text>
        <v-sheet v-if="isLoadAvailable" :elevation="0" flat>
          <!-- LOADS LIST-->
          <v-carousel v-model="loadsCarousel"
                      light
                      :hide-delimiters="!isLoadsMoreThenOne"
                      delimiter-icon="mdi-dolly"
                      :style="isLoadsMoreThenOne ? 'height:200px;' : 'height:160px;'"
                      :show-arrows="false" :show-arrows-on-hover="true">
            <v-carousel-item v-for="(loadItem, index) in truckData.loadsData" :key="index">
              <v-list :style="isLoadsMoreThenOne ? 'height:200px;' : 'height:160px;'">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>fa-box</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <h4>#{{loadItem.id}}&nbsp;{{loadItem.title}}</h4>
                    <v-list-item-subtitle>
                      <a @click.stop="root.onShowLoadPreview(loadItem.id)">{{loadItem.uid}}</a>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn icon small v-on="on" class="mb-1" @click.stop="root.addNewPageTab('/loads/page/'+loadItem.id)">
                            <v-icon small>mdi-open-in-new</v-icon>
                          </v-btn>
                        </template>
                        <span>{{t('OpenExternal')}}</span>
                      </v-tooltip>
                      <br/>
                      <i>{{loadItem.description}}</i>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content style="max-width: 50px;">
                    <div class="text-no-wrap">
                      <v-icon v-if="loadItem.status.phaseIcon" v-html="loadItem.status.phaseIcon" :color="loadItem.status.phaseColor" class="mr-1"></v-icon>
                      <v-icon v-if="loadItem.status.stageIcon" small v-html="loadItem.status.stageIcon" :color="loadItem.status.stageColor" class="mr-1"></v-icon>
                      <v-icon v-if="loadItem.isReturnToHQ === 1" color="success" class="mr-1">mdi-redo-variant</v-icon>
                    </div>
                  </v-list-item-content>
                  <v-list-item-content v-html="loadItem.status.status">
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-carousel-item>
          </v-carousel>
        </v-sheet>
        <v-chip class="ma-1" :color="(truckData.truckTelemetry && truckData.truckTelemetry.speed > 0) ? '#4caf50' : '#cccccc'">
          <v-icon >mdi-speedometer</v-icon>
          {{(truckData.truckTelemetry && truckData.truckTelemetry.speed > 0) ? truckData.truckTelemetry.speed + ' ' + t('mphShort') : '0 ' + t('mphShort')}}
        </v-chip>
        <v-chip class="ma-1">
          <v-icon>mdi-counter</v-icon>
          {{((truckData.truckTelemetry && truckData.truckTelemetry.odometerMiles) ? Number(truckData.truckTelemetry.odometerMiles.toFixed(0)).toLocaleString() : '-') + ' ' + t('milesShort')}}
        </v-chip>
        <v-chip class="ma-1" v-if="truckData.truckTelemetry && truckData.truckTelemetry.fuelGalons">
          <v-icon>mdi-fuel</v-icon>
          {{Number(truckData.truckTelemetry.fuelGalons.toFixed(0)).toLocaleString() + ' ' + t('gallonsShort')}}
        </v-chip>
        <v-chip class="ma-1" v-if="truckData.truckTelemetry && truckData.truckTelemetry.fuelPercent">
          <v-icon>mdi-fuel</v-icon>
          {{Number(truckData.truckTelemetry.fuelPercent.toFixed(1)).toLocaleString() + '%'}}
        </v-chip>
        <br/>
        <v-tooltip bottom v-for="notification in truckData.notifications" :key="notification.msg">
          <template v-slot:activator="{ on }">
            <v-icon class="mt-2"  v-on="on"
                    :color="notification.type === 'error' ? 'error' : 'warning'">
              {{ (notification.type === 'error' ? 'mdi-alert' : 'mdi-alert-circle') }}
            </v-icon>
          </template>
          <span>{{notification.msg}}</span>
        </v-tooltip>

      </v-card-text>
      <v-card-actions>
        <v-btn icon @click="root.route('/trucks/page/'+truckData.id)"><v-icon>mdi-eye</v-icon></v-btn>
        <v-btn icon @click="root.route('/trucks/edit/'+truckData.id)"><v-icon>mdi-pencil</v-icon></v-btn>
        <v-btn text @click="openSamsaraWindow" v-if="truckData.samsaraid">
          <v-icon small>mdi-open-in-new</v-icon>
          Samsara
        </v-btn>
        <v-btn text @click="openKeepTruckin" v-if="truckData.keeptruckinid">
          <v-icon small>mdi-open-in-new</v-icon>
          KTrucking
        </v-btn>
      </v-card-actions>

    </v-card>
  </div>
</template>

<script>
module.exports = {
  name: 'InfoWindowTruck',
  props: [
    'truckData', 't', 'truckImage', 'root'
  ],
  data: () => {
    return {
      imgLoaded: false,
      imgHeight: '1px',
      loadsCarousel: 0
    };
  },
  computed: {
    isLoadsMoreThenOne () {
      return this.truckData.loadsData && this.truckData.loadsData.length > 1;
    },
    isLoadAvailable () {
      return this.truckData.loadsData && this.truckData.loadsData.length > 0;
    }
  },
  methods: {
    openSamsaraWindow () {
      window.open('https://cloud.samsara.com/o/6005213/devices/' + this.truckData.samsaraid + '/vehicle', '_blank',
        'height=800,width=600,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
    },
    openKeepTruckin () {
      window.open('https://web.keeptruckin.com/#/vehicles/' + this.truckData.keeptruckinid, '_blank',
        'height=800,width=600,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
    }
  }
};
</script>
