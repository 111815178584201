<template>
  <div>
    <v-overlay :value="loading" :absolute="true">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>

    <v-card>
      <!--<v-layout v-if="driverId > 0">
        <v-flex xs12>
          <v-card class="ma-2">
            <ReportsGroupsList :type="$route.params.type" :userId="driverId" :totalsCountsNames="totalsCountsNames" :totalsCounts="totalsCounts" :key="reportsGroupsListKey"></ReportsGroupsList>
          </v-card>
        </v-flex>
      </v-layout>-->

      <!--<v-tabs :value="1" grow class="mt-2" show-arrows>
        <v-tab @click="$root.route('/reports/drivers' + ($route.params.item ? '/' + $route.params.item : ''))"><v-icon left>mdi-map-marker-path</v-icon>{{$t('Reports.DriverShifts')}}</v-tab>
        <v-tab @click="$root.route('/reports/driversLoads' + ($route.params.item ? '/' + $route.params.item : ''))"><v-icon left>mdi-dolly</v-icon>{{$t('Reports.DriverLoads')}}</v-tab>
      </v-tabs>-->

      <v-list>
        <v-list-item :style="{backgroundColor: $root.currentTheme.secondary}" dark>
          <v-list-item-content>
            <v-list-item-title><h3>{{$t('Total')}}</h3></v-list-item-title>
            <v-list-item-title class="white--text">
              <b>{{totalsCountsNames.loads}}</b>: {{totalsCounts.loads}}
            </v-list-item-title>
            <v-list-item-title class="white--text">
              <b>{{totalsCountsNames.distance}}</b>: {{totalsCounts.distance.toLocaleString()}}
            </v-list-item-title>
            <v-list-item-title class="white--text">
              <b>{{totalsCountsNames.earned}}</b>: ${{parseFloat(parseFloat(totalsCounts.earned).toFixed(0)).toLocaleString()}}
            </v-list-item-title>
            <v-list-item-title class="white--text">
              <b>{{totalsCountsNames.compensate}}</b>: ${{parseFloat(parseFloat(totalsCounts.compensate).toFixed(0)).toLocaleString()}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <hr/>
        <template v-for="shift in shifts">
          <v-list-item :key="shift.id" @click="$root.route('/loads/driverLoads/shifts/'+shift.id)">
            <v-list-item-content>
              <v-list-item-title>{{shift.pickupLocation}}</v-list-item-title>
              <v-list-item-title>{{shift.deliveryLocation}}</v-list-item-title>
              <v-list-item-subtitle>
                {{shift.createdPST}}&nbsp;-&nbsp;{{shift. endedPST}}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <b>{{$t('Shifts.Distance')}}</b>: {{parseInt(shift.shiftDistance).toLocaleString()}} {{$t('milesShort')}}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <b>{{$t('Shifts.Earned')}}</b>: ${{parseFloat(parseFloat(shift.shiftRate).toFixed(0)).toLocaleString()}}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <b>{{$t('Shifts.ToCompensate')}}</b>: ${{parseFloat(parseFloat(shift.shiftToCompensate).toFixed(0)).toLocaleString()}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
        </template>
      </v-list>

      <v-layout wrap>
        <v-flex xs12 md6>
        </v-flex>
      </v-layout>

    </v-card>
  </div>
</template>

<script>
import reportsMixin from '../../mixins/reportsMixin.js';

export default {
  name: 'DriverLoadsReport',
  props: ['from', 'to', 'driverId', 'driverName', 'selected', 'driverSoloRate', 'driverTeamRate'],
  components: {},
  mixins: [reportsMixin],
  data: function () {
    return {
      loading: false,
      totalsCounts: {
        loads: 0,
        distance: 0,
        earned: 0,
        compensate: 0
      },
      totalsCountsNames: {
        loads: 'Loads',
        distance: this.$root.t('Shifts.Distance'),
        earned: this.$root.t('Shifts.Earned'),
        compensate: this.$root.t('Shifts.ToCompensate')
      },
      shifts: [],
      selectedItems: [],
      search: '',
      expensesModal: false,
      expensesModalKey: new Date().getTime(),
      selectedExpenses: [],
      selectedExpensesLoadId: 0,
      selectedFinishedField: 0,
      documentsModal: false,
      documentsModalKey: new Date().getTime(),
      selectedELoadFields: {},
      milesDiffHighlightThreshold: 30,
      loadsFinances: {}
    };
  },
  computed: {
  },
  mounted () {
    this.$root.setLastListPath(this.$route.path);
    this.$root.getGlobalSettingsField('milesDiffHighlightThreshold').then((response) => {
      if (response.status === 'ok') {
        this.milesDiffHighlightThreshold = parseInt(response.result);
      }
    });
    this.$nextTick(() => {
      this.fetchData().then(() => {
        //this.updateLastReportsGroupNumber(this.driverId, this.$route.params.type);
      });
    });
  },
  methods: {
    clearTotals () {
      this.totalsCounts = {
        shifts: 0,
        distance: 0,
        earned: 0,
        compensate: 0
      };
    },
    isMapsOdometerTreshholdReached (mapsMiles, odometerMiles) {
      return Math.abs(mapsMiles - odometerMiles) > this.milesDiffHighlightThreshold;
    },
    onItemSelected () {
      this.$nextTick(() => {
        let selected = [];
        for (let i = 0; i < this.selectedItems.length; i++) {
          selected.push(parseInt(this.selectedItems[i]['id']));
        }
        this.$emit('selected', selected);
      });
    },
    onShowExpenses (expensesJSON, finishedField, loadId) {
      if (expensesJSON) {
        this.selectedExpensesLoadId = loadId;
        this.selectedExpenses = JSON.parse(expensesJSON);
        this.selectedFinishedField = finishedField;
        this.expensesModalKey = new Date().getTime();
        this.expensesModal = true;
      }
    },
    onShowDocuments (fields) {
      if (fields) {
        this.selectedELoadFields = fields;
        this.documentsModalKey = new Date().getTime();
        this.documentsModal = true;
      }
    },
    fetchData (skipSelected = false, selected = []) {
      return new Promise((resolve, reject) => {
        let i = 0;
        this.loading = true;
        this.getDriverShiftsReportData(this.from, this.to, this.driverId).then((response) => {
          if (response.status === 'ok') {
            this.clearTotals();
            this.shifts = [];
            for (i = 0; i < response.shifts.length; i++) {
              let shift = response.shifts[i];

              this.$set(this.totalsCounts, 'shifts', this.totalsCounts.shifts + 1);
              this.$set(this.totalsCounts, 'distance', this.totalsCounts.distance + shift.shiftDistance);
              this.$set(this.totalsCounts, 'earned', this.totalsCounts.earned + shift.shiftRate);
              this.$set(this.totalsCounts, 'compensate', this.totalsCounts.compensate + shift.shiftToCompensate);

              this.shifts.push(shift);
            }

            this.totalsCounts.shifts = this.shifts.length;
          }
          this.onItemSelected();
          this.$forceUpdate();
          this.loading = false;
          resolve(this.totalsCounts);
        });
      });
    }
  }
};
</script>
