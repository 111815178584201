export default {
  data () {
    return {
      flags: [
        { value: 10, text: 'Edit Request' },
        { value: 20, text: 'Refine' },
        { value: 30, text: 'Refined' },
        { value: 40, text: 'Wait Further Actions' },
        { value: 100, text: 'Verified' }
      ]
    };
  },
  methods: {
    getTroubleShootingComments (itemType, itemId) {
      return this.$root.get('/api/troubleShootingComments/getComments/' + itemType + '/' + itemId);
    },
    removeTroubleShootingComment (id) {
      return this.$root.post('/api/troubleShootingComments/removeComment', { id: id });
    },
    restoreTroubleShootingComment (id) {
      return this.$root.post('/api/troubleShootingComments/restoreComment', { id: id });
    },
    sendTroubleShootingComment (parent, itemType, itemId, comment, attUPLOAD, flag) {
      return new Promise((resolve) => {
        this.$root.post('/api/troubleShootingComments/postComment', {
          fields: {
            parent: parent,
            itemType: itemType,
            itemId: itemId,
            comment: comment,
            attUPLOAD: attUPLOAD,
            flag: flag
          }
        }).then((response) => {
          if (response.status === 'ok') {
            resolve();
          }
        });
      });
    },
    updateTroubleShootingComment (id, comment, attUPLOAD) {
      return new Promise((resolve) => {
        this.$root.post('/api/troubleShootingComments/updateComment', {
          id: id,
          fields: {
            comment: comment,
            attUPLOAD: attUPLOAD
          }
        }).then((response) => {
          if (response.status === 'ok') {
            resolve();
          }
        });
      });
    }
  }
};
