<template>
  <v-container grid-list-md style="padding:0px; max-width:100%;">
    <v-container fill-height grid-list-md text-xs-center v-if="loading">
      <div class="lds-facebook" style="margin:auto;"><div></div><div></div><div></div></div>
    </v-container>
    <div v-else-if="error">
      {{error}}
    </div>
    <v-sheet v-else>
      <v-layout wrap>
        <v-flex xs12 md6>
          <v-switch
            data-cy="logsActionsOnlySwitch"
            class="ml-5 mt-2"
            v-model="changesOnly"
            @change="changesOnlyChange"
            inset
            :label="$t('Logs.ChangesOnly')"></v-switch>
        </v-flex>
        <v-flex xs12 md6>
          <v-select
            class="mt-2"
            v-model="actionsFilter"
            @change="changedTypeFilter"
            :items="logNames"
            :label="$t('Logs.FilterByActionType')"
            outlined
            dense>
            <template v-slot:selection="data">
              {{$t('Logs.' + data.item)}}
            </template>
            <template v-slot:item="data">
              {{$t('Logs.' + data.item)}}
            </template>
          </v-select>
        </v-flex>
      </v-layout>
      <v-data-table v-if="!loading && !error"
                    dense
                    style="width:100%;"
                    :headers="headers"
                    :items="items"
                    :options.sync="options"
                    :server-items-length="itemsCount"
                    :loading="itemsLoading"
                    :items-per-page="itemsPerPage"
                    :footer-props="{
                      itemsPerPageOptions: [20,50,100]
                    }"
                    item-key="id"
                    show-expand
                    :expanded.sync="expanded"
                    class="elevation-1">
        >
        <template v-slot:item.date="{ item }">
          {{new Date(item.date).toLocaleString()}}
        </template>
        <template v-slot:item.role="{ item }">
          {{$t('Roles.' + item.role)}}
        </template>
        <template v-slot:item.userName="{ item }">
          <a @click.stop="$root.onShowUserPreview(item.user, item.userName)">{{item.userName}}</a>
        </template>
        <template v-slot:item.name="{ item }">
          <v-icon x-small :color="getObjectIconColor(item.name)">{{getObjectIconName(item.name)}}</v-icon>
          {{getAccessRightsLogName(item.name, item.value)}}
          <v-icon x-small>{{getActionIconName(item.name)}}</v-icon>
        </template>
        <template v-slot:item.value="{ item }">
          <v-btn v-if="isPathChange(item.name)" x-small class="ma-1" @click="$router.push(item.value)">{{$t('Logs.GoToPath',{path: item.value})}}</v-btn>
          <v-btn v-if="isPageExist(item.name)" @click="goToPage(item.name, item.processedId)"  x-small class="ma-1">{{$t('Logs.OpenPage')}} {{getObjectName(item.name)}} #{{item.processedId}}</v-btn>
        </template>
        <template v-slot:expanded-item="{ item, headers }">
          <td :colspan="headers.length">
            <v-simple-table dense class="ma-1" v-if="isPageExist(item.name) || isAccessRightsEvent(item.name)">
              <template v-slot:default>
                <tbody v-if="isAccessRightsEvent(item.name)">
                <tr v-for="(field,index) in generateDetails(item.name, item.updated)" :key="'access' + index">
                  <td>{{ field.text }}</td>
                  <td><v-icon small :color="field.color">{{field.icon}}</v-icon></td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr v-for="(field, key) in generateDetails(item.name, item.updated)" :key="'fields' + key">
                  <td>{{ key }}</td>
                  <td v-html="field"></td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div v-if="item.name === 'PATH_CHANGED'">
              {{$t('Logs.PathChangedDetails')}} <a :href="$store.getters.location.host + item.value" target="_blank">{{$store.getters.location.host}}{{item.value}}</a>
            </div>
          </td>
        </template>
        <template v-slot:top  v-if="title">
          <v-toolbar flat>
            <v-toolbar-title>{{ $t('Logs.ListTitle') }}</v-toolbar-title>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-sheet>
  </v-container>
</template>

<script>

export default {
  name: 'UserLogs',
  props: ['user', 'role', 'title'],
  components: {
  },
  data: function () {
    return {
      polling: null,
      loading: true,
      error: '',
      options: {},
      headers: [
        { text: this.$root.t('Logs.Date'), value: 'date', sortable: false },
        { text: this.$root.t('Logs.Role'), value: 'role', sortable: false },
        { text: this.$root.t('Logs.User'), value: 'userName', sortable: false },
        { text: this.$root.t('Logs.Name'), value: 'name', sortable: false },
        { text: this.$root.t('Logs.Value'), value: 'value', sortable: false }
      ],
      itemsCount: 0,
      items: [],
      itemsPerPage: 50,
      itemsLoading: false,
      expanded: [],
      changesOnly: true,
      actionsFilter: 'ALL',
      logNames: [
        'ALL',
        'ONLINE_STATUS_CHANGED',
        'PATH_CHANGED',
        'USER_CREATED',
        'USER_UPDATED',
        'SETTINGS_UPDATED',
        'PASSWORD_UPDATED',
        'BID_CREATED',
        'BID_UPDATED',
        'TRUCK_CREATED',
        'TRUCK_UPDATED',
        'LOAD_CREATED',
        'LOAD_UPDATED',
        'INVOICE_CREATED',
        'INVOICE_UPDATED'
      ]
    };
  },
  watch: {
    options: {
      handler () {
        let { page, itemsPerPage } = this.options;
        if (itemsPerPage) {
          this.$root.setLocalStorageItem('userLogsItemsPerPage', itemsPerPage);
        }
        this.fetchData(true).then(data => {
          this.items = data.items;
          this.itemsCount = data.total;
        });
      },
      deep: true
    }
  },
  mounted () {
    if (this.$root.getLocalStorageItem('userLogsItemsPerPage')) {
      this.itemsPerPage = parseInt(this.$root.getLocalStorageItem('userLogsItemsPerPage'));
    }
    this.loading = false;
    if (this.$vuetify.breakpoint.xs) {
      this.fetchData();
    }
  },
  methods: {
    changedTypeFilter (value) {
      this.fetchData(true);
    },
    changesOnlyChange (value) {
      this.fetchData(true);
    },
    fetchData (itemsLoading = false) {
      return new Promise((resolve, reject) => {
        let { page, itemsPerPage } = this.options;
        this.itemsLoading = true;
        if (!itemsLoading) {
          this.loading = true;
        }
        const user = this.user ? this.user : null;
        const role = this.role ? this.role : null;
        this.$root.logList(user, role, itemsPerPage, page, this.changesOnly, this.actionsFilter === 'ALL' ? null : this.actionsFilter).then((response) => {
          if (response.status === 'ok') {
            this.itemsCount = response.result.count;
            this.items = [];
            for (let i = 0; i < response.result.data.length; i++) {
              let item = response.result.data[i];
              item['id'] = i;
              this.items.push(item);
            }
            this.loading = false;
            this.itemsLoading = false;
            resolve({ items: this.items, total: this.itemsCount });
          }
        });
      });
    },
    itemExpanded (item) {
      // if(!item.value)return;
    },
    getObjectIconColor (name) {
      if (name.indexOf('USER_IS_ONLINE') === 0) {
        return 'green';
      } else if (name.indexOf('USER_IS_OFFLINE') === 0) {
        return 'red';
      } else if (name.indexOf('TRUCK_CREATED') === 0) {
        return 'green';
      } else if (name.indexOf('TRUCK_UPDATED') === 0) {
        return 'orange';
      } else if (name.indexOf('BID_CREATED') === 0) {
        return 'green';
      } else if (name.indexOf('BID_UPDATED') === 0) {
        return 'orange';
      } else if (name.indexOf('LOAD_CREATED') === 0) {
        return 'green';
      } else if (name.indexOf('LOAD_UPDATED') === 0) {
        return 'orange';
      } else if (name.indexOf('INVOICE_CREATED') === 0) {
        return 'green';
      } else if (name.indexOf('INVOICE_UPDATED') === 0) {
        return 'orange';
      } else if (name.indexOf('USER_CREATED') === 0) {
        return 'green';
      } else if (name.indexOf('USER_UPDATED') === 0) {
        return 'orange';
      } else if (name.indexOf('SETTINGS_') === 0) {
        return 'orange';
      } else if (name.indexOf('PATH_') === 0) {
        return null;
      }
    },
    getObjectIconName (name) {
      if (name.indexOf('USER_IS_ONLINE') === 0) {
        return 'fa-wifi';
      } else if (name.indexOf('USER_IS_OFFLINE') === 0) {
        return 'fa-wifi';
      } else if (name.indexOf('TRUCK_') === 0) {
        return 'fa-truck-moving';
      } else if (name.indexOf('BID_') === 0) {
        return 'mdi-file-question';
      } else if (name.indexOf('LOAD_') === 0) {
        return 'mdi-dolly';
      } else if (name.indexOf('INVOICE_') === 0) {
        return 'fa-file-invoice-dollar';
      } else if (name.indexOf('USER_') === 0) {
        return 'fa-user';
      } else if (name.indexOf('SETTINGS_') === 0) {
        return 'fa-cog';
      } else if (name.indexOf('ACCESS_RIGHTS_') === 0) {
        return 'fa-user-lock';
      } else if (name.indexOf('PATH_') === 0) {
        return 'fa-link';
      }
    },
    getObjectName (name) {
      if (name.indexOf('TRUCK_') === 0) {
        return this.$root.t('Logs.Truck');
      } else if (name.indexOf('BID_') === 0) {
        return this.$root.t('Logs.Bid');
      } else if (name.indexOf('LOAD_') === 0) {
        return this.$root.t('Logs.Load');
      } else if (name.indexOf('INVOICE_') === 0) {
        return this.$root.t('Logs.Invoice');
      } else if (name.indexOf('USER_') === 0) {
        return this.$root.t('Logs.User');
      }
    },
    getActionIconName (name) {
      if (name.indexOf('_CREATED') > 0) {
        return 'mdi-plus';
      } else if (name.indexOf('_UPDATED') > 0) {
        return 'mdi-pen';
      }
    },
    goToPage (name, processedId) {
      if (name.indexOf('TRUCK_') === 0) {
        this.$root.onShowTruckPreview(processedId);
      } else if (name.indexOf('BID_') === 0) {
        this.$router.push('/bids/edit/' + processedId);
      } else if (name.indexOf('USER_') === 0) {
        this.$root.onShowUserPreview(processedId);
      } else if (name.indexOf('LOAD_') === 0) {
        this.$root.onShowLoadPreview(processedId);
      } else if (name.indexOf('INVOICE_') === 0) {
        this.$router.push('/invoices/page/' + processedId);
      }
    },
    isPageExist (name) {
      if (name.indexOf('TRUCK_') === 0 ||
          name.indexOf('BID_') === 0 ||
          name.indexOf('LOAD_') === 0 ||
          name.indexOf('USER_') === 0 ||
          name.indexOf('SETTINGS_') === 0 ||
          name.indexOf('PASSWORD_') === 0 ||
          name.indexOf('INVOICE_')) {
        return true;
      }
      return false;
    },
    isAccessRightsEvent (name) {
      if (name.indexOf('ACCESS_RIGHTS_') === 0) {
        return true;
      }
      return false;
    },
    getAccessRightsLogName (name, value) {
      if (this.isAccessRightsEvent(name)) {
        // console.warn(value);
        const _v = JSON.parse(value).updated;
        if (_v['0'] && _v['0'].role) {
          return this.$root.t('Logs.' + name) + ': ' + this.$root.t('Roles.' + _v['0'].role);
        } else if (_v['0'] && _v['0'].user) {
          return this.$root.t('Logs.' + name) + ': ' + _v['0'].user;
        }
      }
      return this.$root.t('Logs.' + name);
    },
    isPathChange (name) {
      return name === 'PATH_CHANGED';
    },
    generateDetails (name, value) {
      let ret = [];
      if (this.isAccessRightsEvent(name)) {
        // console.warn(value)
        const items = JSON.parse(value);
        for (let k in items) {
          const _r = this.$root.rightNumberToUIRightsNumber(items[k].rights);
          let icon = 'fa-ban';
          let color = 'red';
          if (_r === 1) {
            icon = 'fa-eye';
            color = 'green';
          } else if (_r === 2) {
            icon = 'fa-file-download';
            color = 'green';
          } else if (_r === 3) {
            icon = 'fa-edit';
            color = 'green';
          }
          ret.push({
            text: this.$root.t('AccessRightsGroup.' + items[k].tableName) + ' > ' + this.$root.getAccessRightsDisplayName(items[k].tableName, items[k].fieldName),
            role: items[k].role ? items[k].role : null,
            user: items[k].user ? items[k].user : null,
            color: color,
            icon: icon
          });
        }

        // [{"tableName":"leftMenu","fieldName":"Dashboard","role":"Driver","rights":0}]
        return { updated: ret };
      }
      // console.error(JSON.parse(value));
      return JSON.parse(value.replace(/(?:\r\n|\r|\n)/g, '<br/>').replace(/(?:\t)/g, ''));
    }
  }
};
</script>
