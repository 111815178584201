import Vue from 'vue';
import Vuex from 'vuex';
import vuexUserModule from './vuex/usersModule';
import vuexLoadsModule from './vuex/loadsModule';
import vuexChatsModule from './vuex/chatsModule';
import vuexReportsModule from './vuex/reportsModule';
import * as Sentry from '@sentry/browser';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    token: '',
    authenticated: false,
    role: '',
    languages: [
      { flag: 'us', locale: 'en-US', name: 'English' },
      { flag: 'ru', locale: 'ru-RU', name: 'Русский' }
    ],
    timezoneOffsetMinutes: 0,
    pullInterval: 60 * 1000, // 60 seconds
    pullIntervalList: 10 * 1000, // 10 seconds
    pullIntervalStatus: 10 * 1000, // 10 seconds
    pullIntervalChat: 5 * 1000, // 5 seconds
    checkInterval: 5 * 1000, // 10 seconds
    checkPushNotificationsStatus: 30 * 60 * 1000, // 30 minutes
    isPageVisible: true,
    usersByRoles: { online: { all: 0 }, total: { all: 0 } },
    menuRights: null,
    loadsRights: null,
    invoicesRights: null,
    reportsRights: null,
    currentUser: {},
    pushNotificationsEndpointUrl: '',
    isCurrentFormUpdated: false,
    newMailsCount: 0,
    newNotificationsCount: 0,
    unreadChatMessages: 0,
    unreadTasks: 0,
    activeShiftId: 0,
    topNotifications: [],
    needApprovalLoads: [],
    unfinishedLoads: [],
    globalSettingsFields: {},
    passwordDialog: false,
    passwordDialogCb: (password) => {},
    hostDev: 'http://127.0.0.1:3000',
    host: 'https://api.tglcrm.com',
    appHostDev: 'http://localhost:9999/',
    appHost: 'https://tglcrm.com/',
    appVersion: null,
    appDriverVersion: null,
    roles: [
      'Administrator',
      'Engineer',
      'Dispatcher',
      'Driver',
      'DriverOwnerOperator',
      'AccountingManager',
      'Assistant',
      'Broker'
    ],
    roleSuperAdministrator: 'SuperAdministrator',
    roleAdministrator: 'Administrator',
    roleEngineer: 'Engineer',
    roleDispatcher: 'Dispatcher',
    roleDriver: 'Driver',
    roleDriverOwnerOperator: 'DriverOwnerOperator',
    roleAccountingManager: 'AccountingManager',
    roleBroker: 'Broker',
    roleAssistant: 'Assistant',
    editOverrideOptions: [
      { value: 0, text: 'Default' },
      { value: 1, text: 'Allow Editing' },
      { value: 10, text: 'Block for drivers' },
      { value: 20, text: 'Block for Dispatchers' },
      { value: -1, text: 'Block for all' }
    ],
    VerifyStatuses: {
      UNVERIFIED: -1,
      REFINE: 0,
      VERIFIED: 1,
      REFINED: 2,
      EDITING_REQUEST: 3,
      EDITING_OVERRIDE: 4,
      WAITING_FURTHER_ACTIONS: 50
    },
    troubleShootingTypes: {
      BIDS: 10,
      LOADS: 20,
      INVOICES: 30,
      TRUCKS: 40
    },
    troubleShootingFlags: {
      EDIT_REQUEST: 10,
      EDIT_OVERRIDE: 20,
      REFINE: 30,
      REFINED: 40,
      WAITING_FURTHER_ACTIONS: 50,
      VERIFIED: 100
    },
    RateTypes: {
      TYPE_BY_MILES: 10,
      TYPE_FIXED: 20,
      TYPE_FIXED_PLUS_MILES: 30,
      TYPE_FIXED_PLUS_MILES_MORE_THAN: 40,
      TYPE_FIXED_OR_BY_MILES_MORE_THAN: 50
    },
    overriddenFieldsTypes: {
      LOADS: 10,
      TRUCKS: 20,
      INVOICES: 30,
      SHIFTS: 40,
      LOADS_LOCATIONS: 50
    }
  },
  modules: {
    user: vuexUserModule,
    loads: vuexLoadsModule,
    chats: vuexChatsModule,
    reports: vuexReportsModule
  },
  getters: {
    host: function (state) {
      if (window.location.host.indexOf('localhost') >= 0) {
        return state.hostDev;
      }
      return state.host;
    },
    appHost: function (state) {
      if (window.location.host.indexOf('localhost') >= 0) {
        return state.appHostDev;
      }
      return state.appHost;
    },
    appVersion: function (state) {
      return state.appVersion;
    },
    appDriverVersion: function (state) {
      return state.appDriverVersion;
    },
    location: function (state) {
      return window.location;
    },
    token: function (state) {
      return state.token;
    },
    pullInterval: function (state) {
      return state.pullInterval;
    },
    pullIntervalList: function (state) {
      return state.pullIntervalList;
    },
    pullIntervalStatus: function (state) {
      return state.pullIntervalStatus;
    },
    pullIntervalChat: function (state) {
      return state.pullIntervalChat;
    },
    checkPushNotificationsStatus: function (state) {
      return state.checkPushNotificationsStatus;
    },
    checkInterval: function (state) {
      return state.checkInterval;
    },
    languages: function (state) {
      return state.languages;
    },
    rolesList: function (state) {
      return state.roles;
    },
    menuRights: function (state) {
      return state.menuRights;
    },
    loadsRights: function (state) {
      return state.loadsRights;
    },
    invoicesRights: function (state) {
      return state.invoicesRights;
    },
    reportsRights: function (state) {
      return state.reportsRights;
    },
    currentUser: function (state) {
      return state.currentUser;
    },
    newMailsCount: function (state) {
      return state.newMailsCount;
    },
    newNotificationsCount (state) {
      return state.newNotificationsCount;
    },
    isHaveNewifications (state) {
      return state.newNotificationsCount > 0;
    },
    unreadChatMessages (state) {
      return state.unreadChatMessages;
    },
    isHaveUnreadChatMessages (state) {
      return state.unreadChatMessages > 0;
    },
    unreadTasks (state) {
      return state.unreadTasks;
    },
    isHaveUnreadTasks (state) {
      return state.unreadTasks > 0;
    },
    authenticated: function (state) {
      return state.authenticated;
    },
    role: function (state) {
      return state.role;
    },
    usersByRoles: function (state) {
      return state.usersByRoles;
    },
    passwordDialog: function (state) {
      return state.passwordDialog;
    },
    passwordDialogCb: function (state) {
      return state.passwordDialogCb;
    },
    isPageVisible: function (state) {
      return state.isPageVisible;
    },
    timezoneOffsetMinutes: function (state) {
      return state.timezoneOffsetMinutes;
    },
    isCurrentFormUpdated: function (state) {
      return state.isCurrentFormUpdated;
    },
    pushNotificationsEndpointUrl: function (state) {
      return state.pushNotificationsEndpointUrl;
    },
    isUserRoleSuperAdmin: function (state) {
      return state.currentUser.role === state.roleSuperAdministrator;
    },
    isUserRoleAdmin: function (state) {
      return state.currentUser.role === state.roleAdministrator;
    },
    isUserRoleEngineer: function (state) {
      return state.currentUser.role === state.roleEngineer;
    },
    isUserRoleDriver: function (state) {
      return state.currentUser.role === state.roleDriver;
    },
    isUserRoleDispatcher: function (state) {
      return state.currentUser.role === state.roleDispatcher;
    },
    isUserRoleAccountingManager: function (state) {
      return state.currentUser.role === state.roleAccountingManager;
    },
    isUserRoleBroker: function (state) {
      return state.currentUser.role === state.roleBroker;
    },
    isUserRoleDriverOO: function (state) {
      return state.currentUser.role === state.roleDriverOwnerOperator;
    },
    currentUserId: function (state) {
      return state.currentUser.id;
    },
    currentUserName: function (state) {
      return state.currentUser.firstName + ' ' + state.currentUser.lastName ;
    },
    roleAdministrator: function (state) {
      return state.roleAdministrator;
    },
    roleDriver: function (state) {
      return state.roleDriver;
    },
    roleDriverOwnerOperator: function (state) {
      return state.roleDriverOwnerOperator;
    },
    roleDispatcher: function (state) {
      return state.roleDispatcher;
    },
    roleBroker: function (state) {
      return state.roleBroker;
    },
    roleAccountingManager: function (state) {
      return state.roleAccountingManager;
    },
    roleEngineer: function (state) {
      return state.roleEngineer;
    },
    roleAssistant: function (state) {
      return state.roleAssistant;
    },
    topNotifications: function (state) {
      return state.topNotifications;
    },
    needApprovalLoads: function (state) {
      return state.needApprovalLoads;
    },
    unfinishedLoads: function (state) {
      return state.unfinishedLoads;
    },
    isStatusAway: function (state) {
      return parseInt(state.currentUser.statusIsAway) === 1;
    },
    activeShiftId: function (state) {
      return parseInt(state.activeShiftId);
    },
    globalSettingsFields: function (state) {
      return state.globalSettingsFields;
    },
    mailLabels: function (state) {
      return ['All', 'INBOX', 'SENT', 'UNREAD', 'TRASH', 'IMPORTANT', 'STARRED', 'SPAM', 'DRAFT'];
    },
    editOverrideOptions: function (state) {
      return state.editOverrideOptions;
    },
    verifyStatuses: function (state) {
      return state.VerifyStatuses;
    },
    troubleShootingFlags: function (state) {
      return state.troubleShootingFlags;
    },
    troubleShootingTypes: function (state) {
      return state.troubleShootingTypes;
    },
    RateTypes: function (state) {
      return state.RateTypes;
    },
    currentRateName: function (state) {
      return 'Current Rate';
    },
    defaultRateName: function (state) {
      return 'Default Miles';
    },
    overriddenFieldsTypes: function (state) {
      return state.overriddenFieldsTypes;
    },
    driversReportsTotalsCountsNames: function (state) {
      return state.driversReportsTotalsCountsNames
    },
    driversReportsTotalsCountsTypes: function (state) {
      return state.driversReportsTotalsCountsTypes;
    }
  },
  mutations: {
    setAppVersion: function (state, value) {
      state.appVersion = value;
    },
    setAppDriverVersion: function (state, value) {
      state.appDriverVersion = value;
    },
    setTimezoneOffsetMinutes: function (state, value) {
      state.timezoneOffsetMinutes = value;
    },
    setToken: function (state, value) {
      state.token = value;
    },
    setViewAsUserToken (state, value) {
      state.viewAsUserToken = value;
    },
    setAuthenticated: function (state, value) {
      state.authenticated = value;
    },
    setRole: function (state, value) {
      state.role = value;
    },
    setMenuAccessRights: function (state, data) {
      state.menuRights = [];
      data.forEach((element) => {
        state.menuRights[element.fieldName] = element.hasOwnProperty('userRights') && element.userRights !== null ? element.userRights : element.roleRights;
      });
    },
    setLoadsAccessRights: function (state, data) {
      state.loadsRights = [];
      data.forEach((element) => {
        state.loadsRights[element.fieldName] = element.hasOwnProperty('userRights') && element.userRights !== null ? element.userRights : element.roleRights;
      });
    },
    setInvoicesAccessRights: function (state, data) {
      state.invoicesRights = [];
      data.forEach((element) => {
        state.invoicesRights[element.fieldName] = element.hasOwnProperty('userRights') && element.userRights !== null ? element.userRights : element.roleRights;
      });
    },
    setReportsAccessRights: function (state, data) {
      state.reportsRights = [];
      data.forEach((element) => {
        state.reportsRights[element.fieldName] = element.hasOwnProperty('userRights') && element.userRights !== null ? element.userRights : element.roleRights;
      });
    },
    setPasswordDialog: function (state, data) {
      state.passwordDialog = data;
    },
    setPasswordDialogCb: function (state, data) {
      state.passwordDialogCb = data;
    },
    setCurrentUser: function (state, data) {
      state.currentUser = data;
      /*Sentry.setUser({
        id: data['id'],
        username: data['firstName'] + ' ' + data['lastName'],
        email: data['email']
      });*/
    },
    setMobileIntervals: function (state) {
      state.pullInterval = 600 * 1000; // 600 seconds
      state.pullIntervalList = 300 * 1000; // 300 seconds
      state.pullIntervalStatus = 300 * 1000; // 300 seconds
      state.checkInterval = 300 * 1000; // 300 seconds
    },
    setNewMailsCount: function (state, data) {
      state.newMailsCount = data;
    },
    setNewNotificationsCount: function (state, data) {
      state.newNotificationsCount = data;
    },
    setUnreadChatMessagesCount: function (state, data) {
      state.unreadChatMessages = data;
    },
    decreaseUnreadChatMessagesCount: function (state) {
      if (state.unreadChatMessages > 0) {
        state.unreadChatMessages -= 1;
      }
    },
    setUnreadTasksCount: function (state, data) {
      state.unreadTasks = data;
    },
    setTopNotifications: function (state, data) {
      state.topNotifications = data;
    },
    setNeedApprovalLoads: function (state, data) {
      state.needApprovalLoads = data;
    },
    setUnfinishedLoads: function (state, data) {
      state.unfinishedLoads = data;
    },
    setIsCurrentFormUpdated: function (state, data) {
      state.isCurrentFormUpdated = data;
    },
    pushNotificationsEndpointUrl: function (state, data) {
      state.pushNotificationsEndpointUrl = data;
    },
    setActiveShiftId: function (state, data) {
      state.activeShiftId = data;
    },
    setGlobalSettingsFields: function (state, data) {
      state.globalSettingsFields = data;
    },
    setUsersByRoles: function (state, data) {
      state.usersByRoles = { online: { all: 0 }, total: { all: 0 } };
      state.roles.forEach((element) => {
        state.usersByRoles.total[element] = 0;
        state.usersByRoles.online[element] = 0;
      });
      data.forEach((element) => {
        if (element.sinceLastActivitySec !== null && element.sinceLastActivitySec < 100) {
          state.usersByRoles['online']['all'] += element.count;
          if (state.usersByRoles['online'][element.role]) {
            state.usersByRoles['online'][element.role] += element.count;
          } else {
            state.usersByRoles['online'][element.role] = element.count;
          }
        } else {
          if (!state.usersByRoles['online'][element.role]) {
            state.usersByRoles['online'][element.role] = 0;
          }
        }
        state.usersByRoles['total']['all'] += element.count;
        if (state.usersByRoles['total'][element.role]) {
          state.usersByRoles['total'][element.role] += element.count;
        } else {
          state.usersByRoles['total'][element.role] = element.count;
        }
      });
    }
  },
  actions: {
  }
});

export default store;
