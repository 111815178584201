<template>
  <div>
    <!--<v-tabs v-model="itemTab" grow show-arrows>
      <v-tab key="trucks">Trucks</v-tab>
      <v-tab key="locations">Locations</v-tab>
    </v-tabs>-->
    <ShiftInfoTable :item="item"
                    :driverId="driverId"
                    @change="onFetch"
                    />
    <ShiftLoadsInfoTable :item="item"
                         :loads="loads"
                         :driverId="driverId"
                         @show-map-modal= "(item, floating, event) => $emit('show-map-modal', item, floating, event)"
                         @on-show-expenses="(expensesJSON, finished, id) => $emit('on-show-expenses', expensesJSON, finished, id)"
                         @on-show-financial-data="(load) => onShowFinancialData(load)"
                         @on-show-documents="(load) => onShowDocuments(load)"
                         @fetch="onFetch"
                   />
    <ShiftTrucksInfoTable :item="item"
                   />
    <!--<template v-for="(location,index) in item.locations">
      <v-toolbar class="white&#45;&#45;text" :style="{cursor: 'pointer',background: $root.getLocationColor(location, index)}" v-if="location.number > 0" :key="location.name+index" @click="$root.route('/loads/page/'+location.loadId)">
        <template v-slot:default>
          <v-layout wrap>
            <v-flex xs12 class="mb-1">
              <span v-if="location.pickup"><b>#{{location.loadId}}</b>&nbsp;<b>{{$t('Loads.PickupAt')}}:&nbsp;</b>{{$root.getDateTimeStringFromTimestamp(location.targetStartTimestamp)}}</span>
              <span v-if="location.delivery"><b>#{{location.loadId}}</b>&nbsp;<b>{{$t('Loads.DeliveryAt')}}:&nbsp;</b>{{$root.getDateTimeStringFromTimestamp(location.targetStartTimestamp)}}</span>
              <span v-if="location.parking"><b>#{{location.loadId}}</b>&nbsp;<b>{{$t('Loads.ParkingAt')}}:&nbsp;</b>{{$root.getDateTimeStringFromTimestamp(location.targetStartTimestamp)}}</span>
              <span v-if="location.exchange"><b>#{{location.loadId}}</b>&nbsp;<b>{{$t('Loads.ExchangeAt')}}:&nbsp;</b>{{$root.getDateTimeStringFromTimestamp(location.targetStartTimestamp)}}</span>
              <span v-if="location.returnToHQ"><b>#{{location.loadId}}</b>&nbsp;<b>{{$t('Loads.ReturnAt')}}:&nbsp;</b>{{$root.getDateTimeStringFromTimestamp(location.targetStartTimestamp)}}</span>
            </v-flex>
            <v-flex xs12>
              <h3>{{location.name}}
                <template v-if="index > 0 && !item.locations[index - 1].approvedByDispatcherTS">
                  <v-icon class="white&#45;&#45;text" left>
                    mdi-timer-sand
                  </v-icon>
                  <b>{{$t('Waiting')}}</b>
                </template>
              </h3>
            </v-flex>
          </v-layout>
        </template>
      </v-toolbar>
    </template>-->
    <v-subheader><h4>Path</h4></v-subheader>
    <v-tabs v-model="tabs">
      <v-tab href="#trip" key="trip">Trip</v-tab>
      <template v-for="load in loads">
        <v-tab :href="'#load' + load.id" :key="'#load' + load.id">Load #{{ load.id }}</v-tab>
      </template>
    </v-tabs>

    <v-tabs-items v-model="tabs" >
      <v-tab-item value="trip">
        <LocationsTimeline v-if="tabs === 'trip'"
                           :locations="item.locations"
                           :started="item.started"
                           :driverId="driverId"
                           :ended="!item.ended ? $root.getCurrentTimestamp() : item.ended"
                           :trucks="item.trucks ? item.trucks.map((obj) => { return obj.truckId; }) : []"
                           :key="locationsTimelineKey"
                           :loadId="0"
                           @change="onFetch"/>
      </v-tab-item>
      <template v-for="load in loads">
        <v-tab-item :value="'load' + load.id">
          <LocationsTimeline v-if="tabs === 'load' + load.id"
                             :locations="load.locations"
                             :started="load.started"
                             :driverId="driverId"
                             :ended="!load.ended ? $root.getCurrentTimestamp() : load.ended"
                             :trucks="load.trucks ? load.trucks.map((obj) => { return obj.truckId; }) : []"
                             :key="locationsTimelineKey"
                             :loadId="load.id"
                             @change="onFetch"/>
        </v-tab-item>
      </template>
    </v-tabs-items>
  </div>
</template>

<script>
import LocationsTimeline from './../../components/LocationsTimeline';
import OverriddenFieldsButton from '../../components/OverriddenFieldsButton';
import ShiftInfoTable from './ShiftInfoTable';
import ShiftLoadsInfoTable from './ShiftLoadsInfoTable';
import ShiftTrucksInfoTable from './ShiftTrucksInfoTable';

export default {
  name: 'ShiftTrucksAndLoads',
  props: ['item', 'loads', 'driverId'],
  components: { LocationsTimeline, OverriddenFieldsButton, ShiftInfoTable, ShiftLoadsInfoTable, ShiftTrucksInfoTable },
  data: function () {
    return {
      locationsTimelineKey: 'LocationsTimeline' + new Date().getTime(),
      tabs: 'trip'
    };
  },
  computed: {
  },
  mounted () {
    this.locationsTimelineKey = 'LocationsTimeline' + new Date().getTime();
  },
  methods: {
    onFetch () {
      this.$emit('fetch');
    },
    onShowDocuments (fields) {
      this.$emit('on-show-documents', fields);
    },
    onShowFinancialData (loadData) {
      this.$emit('on-show-financial-data', loadData);
    }
  }
};
</script>
