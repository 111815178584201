<template>
  <div>
    <v-select
      class="mb-1"
      v-if="showTraffic && !hideTrafficControls"
      v-model="trafficModel"
      @change="onTrafficModelChange"
      item-text="value"
      item-value="key"
      :items="[{key:'best_guess', value:$t('Bids.TrafficBestGuess')}, {key:'pessimistic', value:$t('Bids.TrafficpPessimistic')}, {key: 'optimistic', value:$t('Bids.TrafficOptimistic')}]"
      prepend-icon="fa-traffic-light"
      outlined dense hide-details></v-select>
    <div class="google-map" ref="googleMap" :style="{ width: '100%', height: height }"></div>
    <v-switch
      data-cy="showTrafficSwitch"
      v-if="showTraffic && !hideTrafficControls"
      v-model="showTrafficSwitch"
      @change="onShowTrafficChange"
      inset dense hide-details
      :label="$t('Maps.ShowTraffic')"></v-switch>
    <label id="mapLabelTextId" style="position:absolute;height: auto;width: auto;white-space: nowrap;visibility:hidden"></label>
    <!--{{$props}}-->
  </div>
</template>

<script>
import Vue from 'vue';
import InfoWindowTruck from './InfoWindowTruck.vue';

export default {
  props: [ 'mapConfig', 'height', 'marker', 'markerName', 'waypoints',
    'showTraffic', 'trafficModelValue', 'hideTrafficControls', 'zoom', 'truck', 'truckId', 'trucks', 'loadId', 'loadStarted',
    'loadEnded', 'showTrucks', 'selectedTrucks', 'loadTruck', 'loadName', 'truckTelemetry', 'openedTrucksInfoProp', 'noUI', 'showCustomIcons', 'locations', 'loads', 'selectedLoads', 'dontDrawTruckPaths', 'drawPathByWaypoints' ],
  data () {
    return {
      trafficModel: 'best_guess',
      showTrafficSwitch: false,
      markerPosition: null,
      openedTrucksInfosIds: [],
      map: null,
      truckIcon: {
        path: 'M12,2L4.5,20.29L5.21,21L12,18L18.79,21L19.5,20.29L12,2Z',
        fillOpacity: 1.0,
        scale: 1,
        strokeColor: '#fff',
        fillColor: '#ff5252',
        anchor: { x: 10, y: 10 },
        // eslint-disable-next-line no-undef
        labelOrigin: new google.maps.Point(0, 0)
      },
      circleIcon: {
        // eslint-disable-next-line no-undef
        path: google.maps.SymbolPath.CIRCLE,
        fillOpacity: 0.8,
        scale: 8.0,
        strokeWeight: 2.0,
        strokeColor: '#fff',
        fillColor: '#ff5252'
        // anchor: {x:-10,y:-10}
      }
    };
  },

  mounted () {
    if (this.trafficModelValue) {
      this.trafficModel = this.trafficModelValue;
    }
    this.init();
    if (this.openedTrucksInfoProp) {
      this.openedTrucksInfosIds = this.openedTrucksInfoProp;
    }
  },

  methods: {
    onTrafficModelChange () {
      this.$emit('change', this.trafficModel);
    },
    onShowTrafficChange () {
      this.$emit('change', this.showTrafficSwitch);
    },
    updateMarker () {
      if (this.marker) {
        // eslint-disable-next-line no-undef
        this.markerPosition.setPosition(new google.maps.LatLng(this.marker.lat, this.marker.lng));
        // eslint-disable-next-line no-undef
        this.map.setCenter(new google.maps.LatLng(this.marker.lat, this.marker.lng));
      }
    },
    getTextSize (text, fontSize) {
      if (!document.getElementById('mapLabelTextId')) {
        return { w: 0, h: 0 };
      }
      document.getElementById('mapLabelTextId').innerHTML = text;
      var test = document.getElementById('mapLabelTextId');
      test.style.fontSize = fontSize;
      var height = (test.clientHeight + 1);
      var width = (test.clientWidth + 1);
      return { w: width, h: height };
    },
    genTruckIcon (telemetry = null) {
      let icon = this.truck ? this.truckIcon : null;
      if (!telemetry) {
        telemetry = this.truckTelemetry;
      }
      if (telemetry) {
        if (telemetry.speed > 0) {
          icon = this.truckIcon;
          icon.strokeColor = '#fff';
          icon.fillColor = '#4caf50';
        } else if (telemetry.engineStates && telemetry.engineStates !== 'Off') {
          icon = this.circleIcon;
          icon.strokeColor = '#fff';
          icon.fillColor = '#4caf50';
        } else {
          icon = this.circleIcon;
          icon.strokeColor = '#fff';
          icon.fillColor = '#999';
        }
        icon.rotation = telemetry.rotation;
      }
      return icon;
    },
    createTruckMarker (item) {
      let marker = null;
      let labelSizes = null;
      const icon = this.genTruckIcon(item.truckTelemetry);
      let labelContent = item.name + '&bull;' + (item.driverName ? item.driverName : 'No Driver');
      if (item.loads.length > 0) {
        labelContent += '&bull;Have a load';
      } else {
        labelContent += '&bull;Empty';
      }
      // for (let ld = 0; ld < item.loads.length; ld++) {
      // labelContent += '<br/>' + item.loads[ld].uid;
      // }
      labelSizes = this.getTextSize(labelContent, 16);

      marker = new MarkerWithLabel({
        position: { lat: item ? item.lat : 0, lng: item ? item.lng : 0 },
        labelContent: labelContent,
        labelClass: item.loads.length > 0 ? 'mapLabelLoaded' : 'mapLabel',
        // eslint-disable-next-line no-undef
        labelAnchor: new google.maps.Point(labelSizes.w / 2, labelSizes.h / 2 * -1),
        map: this.map,
        icon: icon
      });

      marker.addListener('click', () => {
        let infoCmp, info, newInfoWindow;
        if (this.openedTrucksInfosIds.indexOf(parseInt(item['id'])) < 0) {
          this.openedTrucksInfosIds.push(parseInt(item['id']));
          this.$emit('openedTrucksInfo', this.openedTrucksInfosIds);
        }
        infoCmp = Vue.extend(InfoWindowTruck);
        // eslint-disable-next-line new-cap
        info = new infoCmp({
          propsData: {
            truckData: item,
            truckImage: this.$root.getTruckFirstUrl(item.truckAvatarUPLOAD),
            t: this.$root.t,
            root: this.$root
          }
        }).$mount();
        // eslint-disable-next-line no-undef
        newInfoWindow = new google.maps.InfoWindow({
          content: info.$el
        });

        // eslint-disable-next-line no-undef
        google.maps.event.addListener(newInfoWindow, 'closeclick', () => {
          let index = this.openedTrucksInfosIds.indexOf(parseInt(item['id']));
          if (index !== -1) this.openedTrucksInfosIds.splice(index, 1);
          this.$emit('openedTrucksInfo', this.openedTrucksInfosIds);
        });
        newInfoWindow.open(this.map, marker);

        // this.$emit("truckSelected", response.result[i]);
      });
    },
    init () {
      let i = 0;
      let _trucks = [];
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(this.$refs.googleMap, {
        zoom: this.zoom ? this.zoom : 13,
        center: this.marker ? this.marker : { lat: 34.2276, lng: -118.4424 },
        disableDefaultUI: !!this.noUI
      });
      if (this.locations && this.locations.length > 0) {
        for (i = 0; i < this.locations.length; i++) {
          if (this.loads && typeof this.loads === 'object' && this.selectedLoads && this.selectedLoads.indexOf(this.locations[i].loadId.toString()) < 0) continue;
          new google.maps.Marker({
            icon: this.$root.getLocationMapIcon(this.locations[i]),
            position: { lat: this.locations[i].lat, lng: this.locations[i].lng },
            map: this.map,
            title: 'Load: #' + this.locations[i].loadId + ' ' + this.$root.getLocationTypeName(this.locations[i]) + ' ' + this.locations[i].name
          });
        }
      }
      if (this.marker) {
        // eslint-disable-next-line no-undef
        this.markerPosition = new google.maps.Marker({
          position: this.marker,
          map: this.map,
          /* label: {
                      text: this.markerName ? this.markerName : '',
                      color: "#000",
                      fontSize: "30px",
                      fon tWeight: "bold"
                      x
                    }, */
          icon: this.genTruckIcon()
          // animation: google.maps.Animation.DROP
        });
      }

      if (this.loadTruck) {
        let labelSizes = this.getTextSize(this.loadName ? this.loadName : 'Current load', 16);
        // eslint-disable-next-line no-undef,no-new
        new MarkerWithLabel({
          position: { lat: this.loadTruck ? this.loadTruck.lat : 0, lng: this.loadTruck ? this.loadTruck.lng : 0 },
          labelContent: this.loadName ? this.loadName : 'Current load',
          labelClass: 'mapLabel',
          // eslint-disable-next-line no-undef
          labelAnchor: new google.maps.Point(labelSizes.w / 2, labelSizes.h / 2 * -1),
          map: this.map,
          icon: this.genTruckIcon(this.truckTelemetry)
        });

        if (this.truckId && !this.dontDrawTruckPaths) {
          this.$root.getTruckLastPath(this.truckId).then((response) => {
            if (response.status === 'ok' && response.results && response.results.length > 0) {
              // waypoints.push({ lat: this.loadTruck.lat, lng: this.loadTruck.lng });
              this.$root.drawRouteByCoords(response.results, this.map);
            }
          });
        }
      }
      if (this.drawPathByWaypoints) {
        this.$root.drawRouteByCoords(this.waypoints, this.map);
      }
      // Show history path for all trucks participated in the load
      if (this.showTrucks) {
        this.$root.getTrucksList().then(async (response) => {
          let i = 0;
          let j = 0;
          let item = {};
          let marker;
          let truckData = {};
          let _truckId = 0;
          let markers = [];
          if (response.status === 'ok') {
            // eslint-disable-next-line no-unused-vars
            for (i = 0; i < response.result.length; i++) {
              if (this.selectedTrucks && this.selectedTrucks.indexOf(response.result[i].id) < 0) continue;
              truckData = response.result[i];
              _truckId = parseInt(truckData['id']);
              item = this.$root.insertTruckTelemetry(response.result[i]);
              item['loadsData'] = this.$root.insertLoadsData(item);
              item['notifications'] = [];// await this.$root.getTuckNotifications(item, this.$store.getters['globalSettingsFields']);

              marker = this.createTruckMarker(item);

              if (this.openedTrucksInfosIds.indexOf(parseInt(item['id'])) >= 0) {
                // eslint-disable-next-line no-undef
                google.maps.event.trigger(marker, 'click');
              }
              markers.push(marker);

              if (_truckId && !this.dontDrawTruckPaths) {
                let _loadStarted = 0;
                let _loadEnded = 0;
                if (truckData.loads) {
                  for (j = 0; j < truckData.loads.length; j++) {
                    if (_loadStarted === 0) {
                      _loadStarted = truckData.loads[j].started;
                      _loadEnded = parseInt(new Date().getTime() / 1000);
                    } else {
                      _loadStarted = Math.min(_loadStarted, truckData.loads[j].started);
                    }
                  }
                }
                this.$root.getTruckLastPath(_truckId, 0, _loadStarted, _loadEnded).then((response) => {
                  if (response.status === 'ok' && response.results && response.results.length > 0) {
                    // waypoints.push({ lat: item.lat, lng: item.lng });
                    this.$root.drawRouteByCoords(response.results, this.map);
                  }
                });
              }
            }
            // eslint-disable-next-line no-undef,no-new
            new MarkerClusterer(this.map, markers,
              { gridSize: 20, averageCenter: false, imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });
          }
        });
      } else if (!this.dontDrawTruckPaths) {
        if (!this.truckId && this.trucks) {
          _trucks = this.trucks;
        } else {
          _trucks.push(this.truckId);
        }

        for (i = 0; i < _trucks.length; i++) {
          this.$root.getTruckLastPath(_trucks[i], 0, this.loadStarted, this.loadEnded).then((response) => {
            let waypoints;
            if (response.status === 'ok' && response.results && response.results.length > 0) {
              waypoints = response.results;
              if (this.loadTruck) {
                // waypoints.push({ lat: this.loadTruck.lat, lng: this.loadTruck.lng });
              }
              this.$root.drawRouteByCoords(waypoints, this.map);
            }
          });
        }
      }

      if (this.waypoints) {
        this.$root.calcDistance(this.waypoints, this.map, this.showTrafficSwitch, null, this.showCustomIcons).catch((msg) => {
          this.$root.toast(this.$i18n.t('DirectionsServiceError'), { color: 'error' });
        });
      }

      if (this.loads && typeof this.loads === 'object') {
        this.drawLoadsRoutes(this.loads, this.map, this.selectedLoads, this.showTraffic);
      }
    },
    async drawLoadsRoutes (loads, map, selectedLoads = [], showTraffic = false) {
      const max = 10; // 25 - 1;
      let key = '';
      let colorsPallete = ['#1f77b4', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a', '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf', '#9edae5'];
      let iter = 1;
      for (key in loads) {
        if (selectedLoads && selectedLoads.indexOf(key) < 0) continue;
        let i = 0;
        let j = 0;
        let parts = [];
        let waypts = [];
        let request = {};
        let _waypoints = [];
        let color = colorsPallete[iter];
        iter++;
        if (iter >= colorsPallete.length) {
          iter = 0;
        }

        if (showTraffic) {
          var trafficLayer = new google.maps.TrafficLayer();
          trafficLayer.setMap(map);
          request['drivingOptions'] = {
            departureTime: new Date(Date.now()),
            trafficModel: google.maps.TrafficModel.BEST_GUESS
          };
        }

        if (loads.hasOwnProperty(key) && loads[key] && loads[key].length > 0) {
          for (i = 0; i < loads[key].length; i++) {
            waypts.push({ location: loads[key][i], stopover: true });
          }
        }
        // Generate route parts to avoid max waypoint limitation
        for (i = 0, parts = []; i < waypts.length; i = i + max) {
          parts.push(waypts.slice(i, i + max + 1));
        }

        for (i = 0; i < parts.length; i++) {
          _waypoints = [];
          for (j = 1; j < parts[i].length - 1; j++) {
            _waypoints.push(parts[i][j]);
          }

          request = {
            origin: parts[i][0].location,
            destination: parts[i][parts[i].length - 1].location,
            waypoints: _waypoints,
            optimizeWaypoints: false,
            travelMode: google.maps.TravelMode.DRIVING
          };

          let directionsService = new google.maps.DirectionsService();
          directionsService.route(request, (response, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              if (map) {
                let directionsDisplay = new google.maps.DirectionsRenderer();
                directionsDisplay.setDirections(response);
                directionsDisplay.setMap(map);
                if (this.showCustomIcons) {
                  directionsDisplay.setOptions({
                    suppressMarkers: true,
                    polylineOptions: {
                      strokeOpacity: 0,
                      icons: [
                        {
                          icon: {
                            path: 'M 0,-1 0,1',
                            strokeOpacity: 1,
                            strokeColor: color,
                            scale: 4
                          },
                          offset: '0',
                          repeat: '20px'
                        }
                      ]
                    }
                  });
                }
              }
            } else {
              this.$root.toast(this.$i18n.t('DirectionsServiceError'), { color: 'error' });
            }
          });

          await this.$root.sleep(200);
        }
      }
    }
  }
};
</script>
