<template>
  <v-layout class="mb-2">
    <v-flex xs12 md6 offset-md-3 class="text-right">
      <v-btn :loading="loading"
             data-cy="userNextBtn"
             class="ma-1 float-right"
             small
             v-confirm="{ok: dialog => { $emit('save')}, cancel: () => {}, message: $t('CreateUser.SaveSettingsConfirmMsg')}"
             color="primary">
        <v-icon left>fa-save</v-icon>{{$t('Save')}}
      </v-btn>
      <v-btn small class="ma-1" @click="$emit('cancel')">
        <v-icon left color="error">mdi-cancel</v-icon>{{$t('Cancel')}}
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'UserFormSaveCancelButton',
  props: ['loading']
}
</script>
