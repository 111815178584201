export default {
  data: function () {
    return {
    };
  },
  methods: {
    getInvoicesList (deletedFilter = 0, statusFilter = 0, loadId = 0, startDate = 0, endDate = 0, page = 0, itemsPerPage = 10, broker = 0, truck = 0, userCreated = 0, userSended = 0, schedule = 0, showNotPayed = 0, isShowOnlyToday = 0, search = '') {
      const statusFilterParam = statusFilter === '' ? 0 : statusFilter;
      const brokerParam = broker === '' ? 0 : broker;
      const truckParam = truck === '' ? 0 : truck;
      const userCreatedParam = userCreated === '' ? 0 : userCreated;
      const userSendedParam = userSended === '' ? 0 : userSended;
      const scheduleParam = schedule === '' ? 0 : schedule;

      return this.get('/api/invoices/list/' +
        deletedFilter + '/' +
        loadId + '/' +
        statusFilterParam + '/' +
        page + '/' +
        itemsPerPage + '/' +
        startDate + '/' +
        endDate + '/' +
        brokerParam + '/' +
        truckParam + '/' +
        userCreatedParam + '/' +
        userSendedParam + '/' +
        scheduleParam + '/' +
        showNotPayed + '/' +
        isShowOnlyToday + '/' +
        search);
    },
    getInvoice (id) {
      return this.get('/api/invoices/get/' + id);
    },
    getInvoiceByNumber (invoiceNumber) {
      return this.get('/api/invoices/getByNumber/' + invoiceNumber);
    },
    getMaxInvoiceNumber () {
      return this.get('/api/invoices/getMaxNumber');
    },
    getInvoiceCountByLoadId (loadId) {
      return this.get('/api/invoices/getInvoicesCountByLoadId/' + loadId);
    },
    genInvoiceEmailSubject (invoiceNumber, total, companyId) {
      // TODO extract to global setting template line
      if (this.$root.companyName.toLowerCase().indexOf('titan') >= 0) {
        return 'TGL Invoice ' + invoiceNumber;
      }
      return 'invoice ' + invoiceNumber + ' for $' + total;
    },
    genInvoiceFileAttObject (id, invoiceNumber) {
      // Add invoice pdf
      const invoiceUrl = this.$store.getters.host + '/api/invoices/pdf/' + this.$store.getters.token + '/' + this.$store.getters.currentUser.id + '/' + id;
      return {
        attUrl: invoiceUrl,
        downloadAttUrl: invoiceUrl,
        isPdf: true,
        folder: true,
        file: true,
        directDownload: true,
        filename: 'invoice ' + invoiceNumber + '.pdf'
      };
    },
    genInvoiceDocumentsAttObject (invoiceDocumentsUploadField) {
      let ret = [];
      if (invoiceDocumentsUploadField && invoiceDocumentsUploadField.indexOf('[') === 0) {
        const filesArray = JSON.parse(invoiceDocumentsUploadField);
        for (let i = 0; i < filesArray.length; i++) {
          const fileData = filesArray[i];
          let uploadFolder = fileData.uploadFolder ? fileData.uploadFolder : 'invoiceDocumentsUPLOAD';

          ret.push({
            attUrl: this.$root.getImageUrl(uploadFolder, fileData.hash),
            downloadAttUrl: this.$root.getImageUrl(uploadFolder, fileData.hash, fileData.name),
            isPdf: !!fileData.name.split('.').pop().match(/pdf/gi),
            folder: uploadFolder,
            file: fileData.hash,
            filename: fileData.name,
            isImageLoaded: false
          });
        }
      }
      return ret;
    },
    createInvoice (fields, additionalLinkedLoadsIds = []) {
      return new Promise((resolve, reject) => {
        this.post('/api/invoices/save', {
          fields: fields,
          linkedLoadsIds: additionalLinkedLoadsIds
        }).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Invoices.InvoiceDataCreated'), { color: 'success' });
            resolve(response);
          } else {
            this.$root.toast(this.$i18n.t('Invoices.InvoiceDataCreateError'), { color: 'error' });
            reject(response);
          }
        }, (error) => {
          console.error('ERROR', error);
          reject(error);
        });
      });
    },
    markInvoiceAsSent (id) {
      return new Promise((resolve, reject) => {
        this.post('/api/invoices/markAsSent/' + id).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Invoices.MarkedAsSent'), { color: 'success' });
            resolve(response);
          } else {
            this.$root.toast(this.$i18n.t('Invoices.InvoiceDataSaveError') + ': ' + response.msg, { color: 'error' });
            reject(response);
          }
        }, (error) => {
          console.error('ERROR', error);
          reject(error);
        });
      });
    },
    markInvoiceAsReady (id) {
      return new Promise((resolve, reject) => {
        this.post('/api/invoices/markAsReady/' + id).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Invoices.MarkedAsReady'), { color: 'success' });
            resolve(response);
          } else {
            this.$root.toast(this.$i18n.t('Invoices.InvoiceDataSaveError') + ': ' + response.msg, { color: 'error' });
            reject(response);
          }
        }, (error) => {
          console.error('ERROR', error);
          reject(error);
        });
      });
    },
    markInvoiceAsVerified (id) {
      return new Promise((resolve, reject) => {
        this.post('/api/invoices/markAsVerified/' + id).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Invoices.MarkedAsVerified'), { color: 'success' });
            resolve(response);
          } else {
            this.$root.toast(this.$i18n.t('Invoices.InvoiceDataSaveError') + ': ' + response.msg, { color: 'error' });
            reject(response);
          }
        }, (error) => {
          console.error('ERROR', error);
          reject(error);
        });
      });
    },
    markInvoiceAsRefine (id) {
      return new Promise((resolve, reject) => {
        this.post('/api/invoices/markAsRefine/' + id).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Invoices.MarkedAsRefine'), { color: 'success' });
            resolve(response);
          } else {
            this.$root.toast(this.$i18n.t('Invoices.InvoiceDataSaveError') + ': ' + response.msg, { color: 'error' });
            reject(response);
          }
        }, (error) => {
          console.error('ERROR', error);
          reject(error);
        });
      });
    },
    markInvoiceAsRefined (id) {
      return new Promise((resolve, reject) => {
        this.post('/api/invoices/markAsRefined/' + id).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Invoices.MarkedAsRefined'), { color: 'success' });
            resolve(response);
          } else {
            this.$root.toast(this.$i18n.t('Invoices.InvoiceDataSaveError') + ': ' + response.msg, { color: 'error' });
            reject(response);
          }
        }, (error) => {
          console.error('ERROR', error);
          reject(error);
        });
      });
    },
    markInvoiceAsWaiting (id) {
      return new Promise((resolve, reject) => {
        this.post('/api/invoices/markAsWaiting/' + id).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Invoices.MarkedAsWaiting'), { color: 'success' });
            resolve(response);
          } else {
            this.$root.toast(this.$i18n.t('Invoices.InvoiceDataSaveError') + ': ' + response.msg, { color: 'error' });
            reject(response);
          }
        }, (error) => {
          console.error('ERROR', error);
          reject(error);
        });
      });
    },
    saveInvoice (fields, id, isRecordPayment = false, additionalLinkedLoadsIds = null) {
      return new Promise((resolve, reject) => {
        this.post('/api/invoices/save/' + id + (isRecordPayment ? '/is_record_payment' : ''), {
          fields: fields,
          linkedLoadsIds: additionalLinkedLoadsIds
        }).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Invoices.InvoiceDataSaved'), { color: 'success' });
            resolve(response);
          } else {
            this.$root.toast(this.$i18n.t('Invoices.InvoiceDataSaveError') + ': ' + response.msg, { color: 'error' });
            reject(response);
          }
        }, (error) => {
          console.error('ERROR', error);
          reject(error);
        });
      });
    },
    restoreInvoice (id) {
      this.post('/api/invoices/save/' + id, { fields: { deleted: 0 } }).then((response) => {
        if (response.status === 'ok') {
          this.$root.toast(this.$i18n.t('Invoices.InvoiceRestored'), { color: 'success' });
        } else {
          this.$root.toast(this.$i18n.t('Invoices.InvoiceRestoreError'), { color: 'error' });
        }
      }, (error) => {
      });
    },
    deleteInvoice (id) {
      this.post('/api/invoices/save/' + id, { fields: { deleted: 1 } }).then((response) => {
        if (response.status === 'ok') {
          this.$root.toast(this.$i18n.t('Invoices.InvoiceDeleted'), { color: 'success' });
        } else {
          this.$root.toast(this.$i18n.t('Invoices.InvoiceDeleteError'), { color: 'error' });
        }
      }, (error) => {
      });
    },
    getInvoiceTypeColorByStatus (status) {
      if (status === 'Paid') {
        return 'success';
      } else if (status === 'Underpaid') {
        return 'info';
      } else if (status === 'Not Paid') {
        return 'error';
      }
      return 'grey';
    },
    getInvoicesToRefine () {
      return this.$root.get('/api/invoices/getInvoicesToRefine');
    },
    getExpensesToApprove () {
      return this.$root.get('/api/loads/getPendingExpenses');
    }

  }
};
