<template>
  <v-tooltip bottom v-if="userData.id && avatarOnly">
    <template v-slot:activator="{ on }">
      <v-avatar v-on="on"
                @click.stop="overrideClick ? $emit('click') : $root.onShowUserPreview(userData.id, fullName)"
                style="cursor: pointer;"
                class="ml-1"
                :size="small ? 16 : 24"
                :color="(avatarLetters && !userData.avatarUPLOAD)?'#015aaa':null">
          <v-img :src="$root.getImageUrl('avatar', userData.avatarUPLOAD)" v-if="userData.avatarUPLOAD"></v-img>
        <span :style="{color: '#fff', fontSize: small ? '10px' : '14px'}" v-else-if="avatarLetters">{{avatarLetters}}</span>
        <v-icon large v-else>account_circle</v-icon>
      </v-avatar>
    </template>
    <span v-if="!overrideTooltip">{{fullName}}</span>
    <span v-else>{{overrideTooltip}}</span>
  </v-tooltip>
  <v-list-item v-else-if="userData.id && !avatarOnly">
    <v-list-item-avatar @click.stop="overrideClick ? $emit('click') : $root.onShowUserPreview(userData.id, fullName)">
      <v-avatar :size="32" :color="(avatarLetters && !userData.avatarUPLOAD)?'#015aaa':null" v-if="userData.id > 0">
        <v-img :src="$root.getImageUrl('avatar', userData.avatarUPLOAD)" v-if="userData.avatarUPLOAD"></v-img>
        <span class="white--text" v-else-if="avatarLetters">{{avatarLetters}}</span>
        <v-icon large v-else>account_circle</v-icon>
      </v-avatar>
      <v-avatar v-else>
        <v-icon>fa-times</v-icon>
      </v-avatar>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title><a @click.stop="$root.onShowUserPreview(userData.id, fullName)">{{fullName}}</a></v-list-item-title>
      <v-list-item-subtitle v-html="userData.role"></v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'UserSnippet',
  props: ['userData', 'avatarOnly', 'small', 'overrideClick', 'overrideTooltip'],
  data: function () {
    return {
    };
  },
  computed: {
    avatarLetters () {
      if (this.userData.firstName && this.userData.lastName) {
        return this.userData.firstName[0] + this.userData.lastName[0];
      }
      return false;
    },
    fullName () {
      return this.userData.firstName + ' ' + this.userData.lastName;
    }
  },
  mounted () {
  },
  methods: {

  }
};
</script>
