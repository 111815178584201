<template>
  <div>
  <v-autocomplete
    autocomplete="off"
    aria-autocomplete="none"
    class="mt-1 mb-1"
    data-cy="UserAutocomplete"
    :key="key"
    :rules="rules"
    :value="selectedUser"
    @change="selectUser"
    :loading="loading"
    :label="label"
    :items="users"
    height="30px"
    item-text="fullName"
    item-value="id"
    :multiple="multiple"
    :disabled="disabled"
    :prepend-icon="hidePrependIcon ? null : getPrependIcon"
    @click:prepend="multiple ? prependClick() : null"
    outlined
    hide-details
    dense>

    <template v-slot:prepend v-if="!hidePrependIcon">
      <v-icon v-if="role === 'Driver'" class="mt-1 ml-2" @click="prependClick()">$vuetify.icons.drivingWheelIcon</v-icon>
      <v-icon v-else :style="iconSize ? { width: iconSize+'px', height: '26px' } : { width: '40px', height: '26px' }" @click="prependClick()">{{getPrependIcon}}</v-icon>
    </template>

    <template v-slot:selection="data">
      <template>
        <v-list-item-avatar>
          <v-avatar :size="26" :color="(data.item.avatarLetters && !data.item.avatar)?'#015aaa':null" v-if="data.item.id > 0">
            <v-img :src="$root.getImageUrl('avatar', data.item.avatar)" v-if="data.item.avatar"></v-img>
            <span class="white--text" v-else-if="data.item.avatarLetters">{{data.item.avatarLetters}}</span>
            <v-icon large v-else>account_circle</v-icon>
          </v-avatar>
          <v-avatar v-else>
            <v-icon>fa-times</v-icon>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.fullName" style="font-size:0.8rem;"></v-list-item-title>
          <v-list-item-subtitle v-html="data.item.role" style="font-size:0.7rem;"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>

    <template v-slot:item="data">
      <template>
        <v-list-item-avatar>
          <v-avatar :size="26" :color="(data.item.avatarLetters && !data.item.avatar)?'#015aaa':null">
            <v-img  :src="$root.getImageUrl('avatar', data.item.avatar)" v-if="data.item.avatar"></v-img>
            <span class="white--text" v-else-if="data.item.avatarLetters">{{data.item.avatarLetters}}</span>
            <v-icon large v-else>account_circle</v-icon>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.fullName"></v-list-item-title>
          <v-list-item-subtitle v-html="data.item.role"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
    <!--<div style="margin-top:-25px;width:100%;text-align:right;" v-if="createNew">
      <v-btn x-small @click="dialog=true">{{role ? $t('CreateNewUserWithRole',{role: $t('Roles.'+role)}) : $t('CreateNewUserFormHeader')}}</v-btn>
    </div>-->
    <!--<v-dialog v-model="dialog" v-if="createNew">
      <v-sheet>
        <CreateNew @created="onCreated()" :noredirect="true" :preselectedRole="role"></CreateNew>
      </v-sheet>
    </v-dialog>-->
  </div>
</template>

<script>
// import CreateNew from './../views/User/CreateNew.vue';

export default {
  name: 'SelectUser',
  props: ['disabled', 'label', 'value', 'role', 'loadAll', 'createNew', 'includeDriverOwnerOperator', 'multiple', 'hidePrependIcon', 'rules', 'excludeRole', 'showDeactivated', 'iconSize', 'required', 'forBiding'],
  // components: { CreateNew },
  data: function () {
    return {
      loading: false,
      userInfo: {},
      selectedUser: 0,
      selectedUserName: '',
      users: [],
      dialog: false,
      key: 'SelectUser' + new Date().getTime()
    };
  },
  watch: {
  },
  computed: {
    getPrependIcon () {
      if (!this.role) {
        return 'fa-user';
      } else {
        if (this.role === 'Administrator') return 'fa-crown';
        if (this.role === 'Dispatcher') return 'mdi-face-agent';
        if (this.role === 'Broker') return 'mdi-account-cash';
        if (this.role === 'Driver') return 'mdi-car';
        if (this.role === 'DriverOwnerOperator') return 'mdi-car-multiple';
        if (this.role === 'Engineer') return 'fa-user-cog';
        if (this.role === 'AccountingManager') return 'fa-user-tie';
      }
      return 'mdi-account';
    }
  },
  mounted () {
    if (this.forBiding) {
      this.loadUsersForBiding();
    } else {
      this.loadUsers(this.role);
    }
  },
  methods: {
    prependClick () {
      this.$root.onShowUserPreview(this.selectedUser, this.selectedUserName);
      // this.$root.addNewPageTab('/user/page/' + this.selectedUser);
      // window.open(window.location.origin + '/user/page/' + this.selectedUser, '_blank');
    },
    filter (item, queryText, itemText) {
      return queryText.length > 2 && item.name.toLowerCase().indexOf(queryText.toLowerCase()) > -1;
    },
    onCreated () {
      this.dialog = false;
      this.loadUsers(this.role);
    },
    selectUser (value) {
      let i = 0;
      if (value === 999999999) {
        this.$emit('loadAll');
        return;
      }
      this.$emit('change', value);

      if (this.includeDriverOwnerOperator) {
        for (i = 0; i < this.users.length; i++) {
          if (this.users[i].id === value) {
            if (this.users[i].role === 'Driver') {
              this.$emit('on-driver');
            } else if (this.users[i].role === 'DriverOwnerOperator') {
              this.$emit('on-driver-oo');
            }
          }
        }
      }
      this.selectedUser = value;
    },
    loadUsersForBiding () {
      this.loading = true;
      this.$root.loadUsersBidding().then(response => {
        this.users = [];
        if (!this.multiple && !this.required) {
          this.users.push({
            fullName: this.$root.t('User.NoUser'),
            id: 0
          });
        }

        this.selectedUser = 0;
        for (let k in Object.keys(response.result.data)) {
          if (!response.result.data[k].id) continue;
          const userData = response.result.data[k];
          this.users.push({
            fullName: userData.firstName + ' ' + (userData.lastName ? userData.lastName : ''),
            id: userData.id,
            firstName: userData.firstName,
            lastName: userData.lastName,
            avatarLetters: (userData.firstName && userData.lastName) ? userData.firstName[0] + userData.lastName[0] : '',
            avatar: userData.avatarUPLOAD,
            email: userData.email,
            role: userData.role
          });
        }
      }).finally(() => {
        if (this.value) {
          this.$set(this, 'selectedUser', this.multiple ? this.value : parseInt(this.value));
          for (let i = 0; i < this.users.length; i++) {
            if (this.users[i].id === this.selectedUser) {
              this.selectedUserName = this.users[i].fullName;
              break;
            }
          }
        }
        this.$set(this, 'key', 'SelectUser' + new Date().getTime());
        this.$emit('usersLoaded');
        this.loading = false;
      });
    },
    loadUsers (role = null) {
      let i = 0;
      this.loading = true;
      this.$root.loadUsersList(role, false, true, this.showDeactivated ? 0 : 1).then((response) => {
        if (response.status === 'ok') {
          this.users = [];
          if (!this.multiple && !this.required) {
            this.users.push({
              fullName: this.$root.t('User.NoUser'),
              id: 0
            });
          }
          this.selectedUser = 0;
          for (let k in response.result.data) {
            if (!response.result.data[k].id) continue;
            // If user assistant and selected role is dispatcher show only distapchers to

            if (this.excludeRole && this.excludeRole === response.result.data[k].role) continue;
            if (!this.showDeactivated && parseInt(response.result.data[k].statusIsActive) !== 1 && parseInt(response.result.data[k].id) !== parseInt(this.value)) continue;

            const userData = response.result.data[k];
            this.users.push({
              fullName: userData.firstName + ' ' + (userData.lastName ? userData.lastName : ''),
              id: userData.id,
              firstName: userData.firstName,
              lastName: userData.lastName,
              avatarLetters: (userData.firstName && userData.lastName) ? userData.firstName[0] + userData.lastName[0] : '',
              avatar: userData.avatarUPLOAD,
              email: userData.email,
              role: userData.role
            });
          }
          if (this.includeDriverOwnerOperator) {
            this.$root.loadUsersList('DriverOwnerOperator', false, true, this.showDeactivated ? 1 : 0).then((response2) => {
              if (response2.status === 'ok') {
                for (let k in response2.result.data) {
                  if (!response2.result.data[k].id) continue;
                  const userData = response2.result.data[k];
                  if (!this.showDeactivated && parseInt(response.result.data[k].statusIsActive) !== 1 && parseInt(response.result.data[k].id) !== parseInt(this.value)) continue;
                  this.users.push({
                    fullName: userData.firstName + ' ' + userData.lastName,
                    id: userData.id,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    avatarLetters: (userData.firstName && userData.lastName) ? userData.firstName[0] + userData.lastName[0] : '',
                    avatar: userData.avatarUPLOAD,
                    email: userData.email,
                    role: userData.role
                  });
                }
              }
            });
          }

          /*
          this.$root.loadUsersList('SuperAdministrator').then((response2) => {
            if (response2.status === 'ok') {
              for (let k in response2.result.data) {
                if (!response2.result.data[k].id) continue;
                const userData = response2.result.data[k];
                if (!this.showDeactivated && parseInt(response.result.data[k].statusIsActive) !== 1 && parseInt(response.result.data[k].id) !== parseInt(this.value)) continue;
                this.users.push({
                  fullName: userData.firstName + ' ' + userData.lastName,
                  id: userData.id,
                  firstName: userData.firstName,
                  lastName: userData.lastName,
                  avatarLetters: (userData.firstName && userData.lastName) ? userData.firstName[0] + userData.lastName[0] : '',
                  avatar: userData.avatarUPLOAD,
                  email: userData.email,
                  role: userData.role
                });
              }
            }
          });
          */

          if (role && this.loadAll) {
            this.users.push({
              fullName: this.$i18n.t('AllUsers'),
              id: 999999999
            });
          }
          if (this.value) {
            this.$set(this, 'selectedUser', this.multiple ? this.value : parseInt(this.value));
            for (i = 0; i < this.users.length; i++) {
              if (this.users[i].id === this.selectedUser) {
                this.selectedUserName = this.users[i].fullName;
                break;
              }
            }
          }
          this.$set(this, 'key', 'SelectUser' + new Date().getTime());
          this.loading = false;
        } else {
          this.users.push({
            fullName: this.$root.t('Error'),
            id: 0,
            role: response.msg
          });
          // TODO add error in list
        }
      }).finally(() => {
        this.$emit('usersLoaded');
      });
    }
  }

};
</script>
