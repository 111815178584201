<template>
  <v-layout>
    <v-flex shrink>
      <template>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn  v-on="on"
                    :loading="item.loading"
                    @click.stop="isVerifyAllowed ? onMarkAsVerified(item, item.dispatcher, item.id) : null"
                    icon
                    :small="small">
              <v-icon v-if="item.verifiedStatus !== 1"
                      class="mr-1 mt-1"
                      :small="small">
                mdi-check-decagram
              </v-icon>
              <v-icon v-else-if="item.verifiedStatus === 1"
                      class="ml-1 mr-1 mt-1"
                      color="success"
                      :small="small">
                mdi-check-decagram
              </v-icon>
            </v-btn>
          </template>
          <span>
            <div v-if="item.verifiedStatus !== 1">{{$t('Loads.MarkAsVerified')}}</div>
            <div v-if="item.verifiedStatus === 1">{{$t('Loads.Verified')}}</div>
          </span>
        </v-tooltip>
      </template>
    </v-flex>
    <v-flex shrink>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-if="item.verifiedStatus === 0"
                 @click.stop="isVerifyAllowed ? onMarkAsRefine(item.dispatcher, item.id, item.refineNotes) : null"
                 icon v-on="on" :small="small">
            <v-icon class="mr-1 mt-1" :small="small" color="error">mdi-alert-decagram</v-icon>
          </v-btn>
          <v-btn v-else-if="item.verifiedStatus === 2 || item.verifiedStatus === 4 || item.verifiedStatus === 4"
                 @click.stop="isVerifyAllowed ? onMarkAsRefined(item.dispatcher, item.id, item.refinedNotes) : null"
                 icon v-on="on" :small="small">
            <v-icon class="mr-1 mt-1" :small="small" color="warning">mdi-alert-decagram</v-icon>
          </v-btn>
          <v-btn v-else
                 @click.stop="isVerifyAllowed ? onMarkAsRefine(item.dispatcher, item.id, item.refineNotes) : null"
                 icon v-on="on" :small="small">
            <v-icon class="mr-1 mt-1" :small="small">mdi-alert-decagram</v-icon>
          </v-btn>
        </template>
        <span v-if="item.verifiedStatus !== 1 && item.verifiedStatus !== -1">
          {{(item.verifiedStatus === 0 ? $root.t('Loads.Refine') : $root.t('Loads.Refined'))}} {{item.verifiedStatus === 0 ? (': '+item.refineNotes) : (': '+item.refinedNotes)}}
        </span>
        <span v-else>{{$t('Loads.MarkAsRefine')}}</span>
      </v-tooltip>
    </v-flex>

    <v-dialog v-model="verifyModal" max-width="500">
      <v-card>
        <v-card-title>
          <h2>{{$t('Loads.Refine')}}</h2><v-spacer></v-spacer><v-btn icon @click="verifyModal=false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-layout>
            <v-flex xs12>
              <b>{{$t('Loads.RefinedNotes')}}</b>:<p>{{verifyModalRefinedNote}}</p>
              <v-textarea dense outlined
                          :label="$root.t('Loads.RefineNotes')"
                          :error-messages="verifyModalNoteErrorMsg"
                          v-model="verifyModalNote" ></v-textarea>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12>
              <v-btn @click="markAsRefine" color="primary" width="100%">
                {{$t('Loads.MarkAsRefine')}}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>

export default {
  name: 'LoadProgressCard',
  props: ['item', 'small'],
  data () {
    return {
      verifyModal: false,
      verifyModalUserId: 0,
      verifyModalLoadId: 0,
      verifyModalNote: '',
      verifyModalRefinedNote: '',
      verifyModalNoteErrorMsg: ''
    };
  },
  computed: {
    isVerifyAllowed () {
      return this.$root.isAccessRightAllowEdit(this.$store.getters.loadsRights['isVerifyAllowed']);
    }
  },
  methods: {
    onMarkAsVerified (item, userId, loadId) {
      item.loading = true;
      this.markAsVerified(userId, loadId, item.verifiedStatus !== 1 ? 1 : -1).then((response) => {
        if (response.status === 'ok') {
          item.verifiedStatus = item.verifiedStatus !== 1 ? 1 : -1;
          item.loading = false;
          this.$forceUpdate();
        }
      });
    },
    onMarkAsRefine (userId, loadId, notes) {
      this.verifyModalUserId = userId;
      this.verifyModalLoadId = loadId;
      if (notes) {
        this.verifyModalNote = notes;
      }
      this.verifyModal = true;
    },
    onMarkAsRefined (userId, loadId, notes) {
      this.verifyModalRefinedNote = notes;
      this.onMarkAsRefine(userId, loadId, '');
    },
    markAsRefine () {
      if (this.verifyModalNote.length === 0) {
        this.verifyModalNoteErrorMsg = this.$root.t('Validators.FieldIsRequired');
        return;
      }
      this.verifyModalNoteErrorMsg = '';
      this.markAsVerified(this.verifyModalUserId, this.verifyModalLoadId, 0, this.verifyModalNote).then((response) => {
        this.verifyModal = false;
        this.verifyModalUserId = 0;
        this.verifyModalLoadId = 0;
        this.verifyModalNote = '';
        this.$emit('fetch-data');
      });
    },
    markAsVerified (userId, loadId, status = 1, note = '') {
      return new Promise((resolve) => {
        return this.$root.post('/api/reports/markAsVerified', {
          userId: userId,
          loadId: loadId,
          status: status,
          note: note
        }).then(response => {
          this.$emit('fetch-data');
          resolve(response);
        });
      });
    }
  }
}
</script>
