<template>
  <div class="text-center">
    <UsersFormSaveCancelButtons v-if="isSaveAllowed && updatingEnabled"
                               :loading="isFieldLoading('saveBtn')"
                               @save="saveFields"
                               @cancel="$root.route('/user/page/' + userId)"/>
    <v-layout wrap>
      <v-flex xs12 md6 offset-md-3>
        <v-btn data-cy="backBtn" v-if="!updatingEnabled" style='width:100%;margin-bottom: 10px;' @click="$emit('back')">
          <v-icon>mdi-chevron-up</v-icon>{{$t('Back')}}
        </v-btn>
      </v-flex>
    </v-layout>

    <v-form ref="form" v-model="valid" lazy-validation>
      <v-layout wrap>
        <v-flex xs12 md6 offset-md-3>
        <!-- UNIVERSAL USER FIELDS -->
        <br/>
        <v-text-field
          data-cy="phoneNumber"
          class="mb-2"
          :disabled="isFieldReadOnly('phoneNumber')"
          v-if="!isFieldHidden('phoneNumber')"
          v-model="fields.phoneNumber"
          :label="$t('User.Fields.phoneNumber')"
          prepend-icon="fa-phone"
          type="text" outlined hide-details dense></v-text-field>

        <v-text-field
          data-cy="SSN"
          class="mb-2"
          :disabled="isFieldReadOnly('SSN')"
          v-if="!isFieldHidden('SSN')"
          v-model="fields.SSN"
          :label="$t('User.Fields.SSN')"
          prepend-icon="fa-id-card"
          :append-icon="updatingEnabled ? (isFieldEncrypted('SSN') ? 'mdi-eye-off' : 'mdi-eye') : null"
          @click:append="decryptField('SSN',$route.params.id)"
          :type="isFieldEncrypted('SSN') ? 'password' : 'text'"
          outlined hide-details dense></v-text-field>

        <v-textarea
          data-cy="paymentDetails"
          class="mb-2"
          :disabled="isFieldReadOnly('paymentDetails')"
          v-if="!isFieldHidden('paymentDetails')"
          v-model="fields.paymentDetails"
          :label="$t('User.Fields.paymentDetails')"
          prepend-icon="fa-file-invoice-dollar" outlined hide-details></v-textarea>
        </v-flex>
      </v-layout>
    </v-form>

    <UsersFormSaveCancelButtons v-if="isSaveAllowed && updatingEnabled"
                               :loading="isFieldLoading('saveBtn')"
                               @save="saveFields"
                               @cancel="$root.route('/user/page/' + userId)"/>
  </div>
</template>

<script>
import fieldLoadingMixin from '../../mixins/fieldLoadingMixin';
import fieldAccessRightMixin from '../../mixins/fieldAccessRightsMixin';
import userFormsCommonMixin from '../../mixins/userFormsCommonMixin';
import UsersFormSaveCancelButtons from '../../components/UsersFormSaveCancelButtons';

export default {
  components: { UsersFormSaveCancelButtons },
  props: ['updatingEnabled', 'userId', 'isOwnSettings', 'isNew'],
  mixins: [fieldLoadingMixin, fieldAccessRightMixin, userFormsCommonMixin],
  data: () => ({
    valid: true,
    requiredEnabled: true,
    fields: {
      paymentDetails: '',
      paymentDetailsUPLOAD: '',
      agreementUPLOAD: '',
      passportUPLOAD: '',
      phoneNumber: '',
      SSN: '',
      resumeUPLOAD: '',
      W2FormUPLOAD: '',
      W9FormUPLOAD: ''
    },
    documentsTab: 0
  }),
  computed: {
  },
  mounted () {
    if (!this.isNew) {
      this.fetchDataForSettingsModal();
    }
  },
  methods: {
    isValid () {
      return this.$refs.form.validate();
    },
    next () {
      if (!this.$refs.form.validate()) {
        this.$root.toast(this.$i18n.t('FormValidationFailed'), { color: 'error' });
        return;
      }
      this.$emit('next');
    },
    setFields (fields, userRole, rights = null) {
      for (let key in this.fields) {
        this.fields[key] = fields[key] !== null ? fields[key] : '';
      }
      this.loadImages();
      this.applyRights(this.isOwnSettings ? '' : userRole, this.isOwnSettings ? 'settings' : 'users', { deleteUsersDocuments: 0 });
    }
  }
};
</script>
