<template>
  <v-layout>
    <v-flex shrink>
      {{ currentDate }}
      <v-btn @click.stop="onOverrideField()"
             v-if="$root.isOverrideSalaryAllowed"
             small icon
             :color="parseFloat(value) >= 0 || showCancel ? 'success' : null"
      >
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
    </v-flex>
    <v-flex shrink v-if="showCancel || (parseFloat(value) >= 0 && $root.isOverrideSalaryAllowed)">
      <v-btn @click.stop="overrideFieldCancel()"small icon>
        <v-icon small color="red">mdi-close-thick</v-icon>
      </v-btn>
    </v-flex>
    <v-flex shrink v-if="currentNote">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon small v-on="on"><v-icon small class="mr-1">mdi-information</v-icon></v-btn>
        </template>
        <span>{{currentNote}}</span>
      </v-tooltip>
    </v-flex>
  </v-layout>
</template>

<script>

export default {
  name: 'OverriddenFieldsButton',
  props: ['userId', 'itemType', 'itemId', 'fieldName', 'showCancel', 'currentValue', 'currentDate', 'currentNote', 'showDate', 'isTime', 'prefix'],
  data () {
    return {
      value: null,
      lastUdated: ''
    };
  },
  mounted () {
  },
  methods: {
    overrideFieldCancel () {
      this.$dialog.confirm(this.$root.t('Loads.ReseySalaryOverrideConfirmationMsg')).then(dialog => {
        this.$root.removeOverriddenFieldValue(this.userId, this.itemType, this.itemId, this.fieldName).then((response) => {
          this.$emit('change');
        });
      });
    },
    onOverrideField () {
      this.fetchData().then(() => {
        this.$root.overrideFieldHaveDate = false;
        if (this.showDate) {
          this.$root.overrideFieldHaveDate = true;
        }
        this.$root.overrideFieldPrefix = this.prefix || '';
        this.$root.overrideFieldIsTimeFormat = this.isTime;
        this.$root.overrideFieldDialog = true;
        this.$root.overrideFieldKey = new Date().getTime();
      });
    },
    fetchData () {
      return new Promise(resolve => {
        this.$root.getOverriddenFieldValue(this.userId, this.itemType, this.itemId, this.fieldName).then(response => {
          if (response.status === 'ok') {
            this.$root.overrideFieldUser = this.userId;
            this.$root.overrideFieldName = this.fieldName;
            this.$root.overrideFieldItemType = this.itemType;
            this.$root.overrideFieldItemId = this.itemId;
            if (this.$root.overrideFieldIsTimeFormat) {
              this.$set(this.$root, 'overrideFieldValue', this.$root.minutesToHHMM(parseInt(this.currentValue)));
            } else {
              this.$root.overrideFieldValue = this.currentValue;
            }
            this.$root.overrideFieldDate = this.currentDate;
            if (response.result) {
              if (this.$root.overrideFieldIsTimeFormat) {
                this.$set(this.$root, 'overrideFieldValue', this.$root.minutesToHHMM(parseInt(response.result.customValue)));
              } else {
                this.$root.overrideFieldValue = response.result.customValue;
              }
              this.$root.overrideFieldDate = response.result.customDate;
              this.$root.overrideFieldNote = response.result.note;
              this.$set(this.$root, 'overrideFieldKey', new Date().getTime());
            }
            resolve();
          }
        });
      });
    }
  }
}
</script>
