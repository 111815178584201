<template>
  <div v-if="!isDriverOO">
    <template v-for="(truck, trucksIndex) in trucks">
      <v-layout :key="'trucks'+trucksIndex" class="pr-5" v-if="!selectedTruck || parseInt(selectedTruck) === parseInt(truck.id)">
        <v-flex class="text-left text-no-wrap" grow v-if="!onlyDrivers">
          <a @click.stop="$root.onShowTruckPreview(truck.id, truck.truckName)">{{truck.truckName}}</a>&nbsp;
          <NextTruckLoadSnippet v-if="loadId" :current-load-id="loadId" :truck-id="truck.id" is-tooltip-btn="true"/>
        </v-flex>
        <v-spacer/>
        <template v-for="(driver, driverIndex) in truck.drivers">
          <v-flex class="text-center" style="max-width: 25px;" :key="'truck.drivers'+driverIndex"><!--  && $root.isAccessRightAllowView($store.getters.menuRights['Driver'])"-->
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-avatar v-on="on"
                          @click.stop="$root.onShowUserPreview(driver.id, driver.driverName)"
                          style="cursor: pointer;"
                          class="ml-1"
                          :size="24"
                          :color="(driver.driverInitials && !driver.driverAvatar)?'#015aaa':null">
                  <v-img :src="$root.getImageUrl('avatar', driver.driverAvatar)" v-if="driver.driverAvatar"></v-img>
                  <span style="color:#fff;" v-else-if="driver.driverInitials">{{driver.driverInitials}}</span>
                </v-avatar>
              </template>
              <span>{{driver.driverName}}</span>
            </v-tooltip>
          </v-flex>
        </template>
      </v-layout>
      <v-layout v-if="$root.isMobile">
        <v-flex>
          <v-icon x-small left :color="$root.pickupColor">fa-people-carry</v-icon><small>{{truck.pickupDT}}</small>
        </v-flex>
        <v-flex>
          <v-icon x-small left :color="$root.deliveryColor">fa-truck-loading</v-icon><small>{{truck.deliveryDT}}</small>
        </v-flex>
      </v-layout>
      <v-divider v-if="!onlyDrivers" :key="'trucks_divider'+trucksIndex"/>
    </template>
  </div>
  <div v-else>
    <v-layout class="pr-1">
      <v-flex class="text-left text-no-wrap" v-if="!onlyDrivers">
        <a v-if="truckOO" @click.stop="$root.onShowTruckPreview(truckOO.id, truckOO.truckName)">{{truckOO.truckName}}</a>&nbsp;
      </v-flex>
      <v-spacer/>
      <v-flex class="text-center" style="max-width: 25px;">
        <v-tooltip bottom v-if="driverOO">
          <template v-slot:activator="{ on }">
            <v-avatar v-on="on"
                      @click.stop="$root.onShowUserPreview(driverOO.id, driverOO.driverName)"
                      style="cursor: pointer;"
                      class="mr-1"
                      :size="24"
                      :color="(driverOO.driverInitials && !driverOO.driverAvatar)?'#015aaa':null">
              <v-img :src="$root.getImageUrl('avatar', driverOO.driverAvatar)" v-if="driverOO.driverAvatar"></v-img>
              <span style="color:#fff;" v-else-if="driverOO.driverInitials">{{driverOO.driverInitials}}</span>
            </v-avatar>
          </template>
          <span>{{driverOO.driverName}}</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import NextTruckLoadSnippet from './NextTruckLoadSnippet';
export default {
  name: 'TruckDriversSnippet',
  components: { NextTruckLoadSnippet },
  props: ['trucks', 'truckOO', 'driverOO', 'isDriverOO', 'selectedTruck', 'onlyDrivers', 'loadId'],
  data: function () {
    return {
    };
  },
  computed: {
  },
  mounted () {
  },
  methods: {

  }
};
</script>
