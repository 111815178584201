<template>
  <div>
    <v-list>
      <template v-for="item in vrData">
        <v-list-item :key="'vrData'+item.id">
          <template v-if="item.status === $store.getters.verifyStatuses.REFINE">
            <v-list-item-icon>
              <v-icon color="error">mdi-alert</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                <UserSnippet :user-data="{id: item.userCreated, avatarUPLOAD: item.avatarUPLOAD, firstName: item.firstName, lastName: item.lastName}" :avatar-only="true"/>
                {{$t('Loads.Refine')}}
                <v-btn icon small v-if="!readonly && (item.userCreated === $store.getters.currentUser.id || $store.getters.isUserRoleSuperAdmin)" @click="onVerifyEdit(item)"><v-icon small>mdi-pen</v-icon></v-btn>
              </v-list-item-title>
              <v-list-item-content v-html="item.refineNote ? item.refineNote.replace(/(?:\r\n|\r|\n)/g, '<br/>') : ''"/>
            </v-list-item-content>
          </template>

          <template v-if="item.status === $store.getters.verifyStatuses.UNVERIFIED">
            <v-list-item-icon>
              <v-icon>mdi-check-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                <UserSnippet :user-data="{id: item.userCreated, avatarUPLOAD: item.avatarUPLOAD, firstName: item.firstName, lastName: item.lastName}" :avatar-only="true"/>
                {{$t('Loads.UndoVerified')}}
                <v-btn icon small v-if="!readonly && (item.userCreated === $store.getters.currentUser.id || $store.getters.isUserRoleSuperAdmin)" @click="onVerifyEdit(item)"><v-icon small>mdi-pen</v-icon></v-btn>
              </v-list-item-title>
              <v-list-item-content v-html="item.note ? item.note.replace(/(?:\r\n|\r|\n)/g, '<br/>') : ''"/>
            </v-list-item-content>
          </template>

          <template v-if="item.status === $store.getters.verifyStatuses.VERIFIED">
            <v-list-item-icon>
              <v-icon color="success">mdi-check-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                <UserSnippet :user-data="{id: item.userCreated, avatarUPLOAD: item.avatarUPLOAD, firstName: item.firstName, lastName: item.lastName}" :avatar-only="true"/>
                {{$t('Loads.Verified')}}
                <v-btn icon small v-if="!readonly && (item.userCreated === $store.getters.currentUser.id || $store.getters.isUserRoleSuperAdmin)" @click="onVerifyEdit(item)"><v-icon small>mdi-pen</v-icon></v-btn>
              </v-list-item-title>
              <v-list-item-content v-html="item.note ? item.note.replace(/(?:\r\n|\r|\n)/g, '<br/>') : ''"/>
            </v-list-item-content>
          </template>

          <template v-if="item.status === $store.getters.verifyStatuses.REFINED">
            <v-list-item-icon>
              <v-icon color="warning">mdi-alert</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                <UserSnippet :user-data="{id: item.userCreated, avatarUPLOAD: item.avatarUPLOAD, firstName: item.firstName, lastName: item.lastName}" :avatar-only="true"/>
                {{$t('Loads.Refined')}}
                <v-btn icon small v-if="!readonly && (item.userCreated === $store.getters.currentUser.id || $store.getters.isUserRoleSuperAdmin)" @click="onVerifyEdit(item)"><v-icon small>mdi-pen</v-icon></v-btn>
              </v-list-item-title>
              <v-list-item-content v-html="item.refinedNote ? item.refinedNote.replace(/(?:\r\n|\r|\n)/g, '<br/>') : ''"/>
            </v-list-item-content>
          </template>

          <template v-if="item.status === $store.getters.verifyStatuses.EDITING_REQUEST">
            <v-list-item-icon>
              <v-icon color="info">mdi-information</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                <UserSnippet :user-data="{id: item.userCreated, avatarUPLOAD: item.avatarUPLOAD, firstName: item.firstName, lastName: item.lastName}" :avatar-only="true"/>
                {{$t('Loads.EditingUnlockRequested')}}
                <v-btn icon small v-if="!readonly && (item.userCreated === $store.getters.currentUser.id || $store.getters.isUserRoleSuperAdmin)" @click="onVerifyEdit(item)"><v-icon small>mdi-pen</v-icon></v-btn>
              </v-list-item-title>
              <v-list-item-content v-html="item.vrEditingRequestNote ? item.vrEditingRequestNote.replace(/(?:\r\n|\r|\n)/g, '<br/>') : ''"/>
            </v-list-item-content>
          </template>

          <template v-if="item.status === $store.getters.verifyStatuses.EDITING_OVERRIDE">
            <v-list-item-icon>
              <v-icon color="info">mdi-information</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                <UserSnippet :user-data="{id: item.userCreated, avatarUPLOAD: item.avatarUPLOAD, firstName: item.firstName, lastName: item.lastName}" :avatar-only="true"/>
                {{$t('Loads.OverrideEditLock')}}
                <v-btn icon small v-if="!readonly && (item.userCreated === $store.getters.currentUser.id || $store.getters.isUserRoleSuperAdmin)" @click="onVerifyEdit(item)"><v-icon small>mdi-pen</v-icon></v-btn>
              </v-list-item-title>
              <v-list-item-content v-html="item.note ? item.note.replace(/(?:\r\n|\r|\n)/g, '<br/>') : ''"/>
            </v-list-item-content>
          </template>

          <template v-if="item.status === $store.getters.verifyStatuses.WAITING_FURTHER_ACTIONS">
            <v-list-item-icon>
              <v-icon color="warning">mdi-alert</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                <UserSnippet :user-data="{id: item.userCreated, avatarUPLOAD: item.avatarUPLOAD, firstName: item.firstName, lastName: item.lastName}" :avatar-only="true"/>
                {{$t('Loads.MarkAsWaitingFurtherActions')}}
                <v-btn icon small v-if="!readonly && (item.userCreated === $store.getters.currentUser.id || $store.getters.isUserRoleSuperAdmin)" @click="onVerifyEdit(item)"><v-icon small>mdi-pen</v-icon></v-btn>
              </v-list-item-title>
              <v-list-item-content v-html="item.note ? item.note.replace(/(?:\r\n|\r|\n)/g, '<br/>') : ''"/>
            </v-list-item-content>
          </template>
          <v-list-item-action>
            {{item.createdPST}}
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="'vrDataDivider'+item.id"/>
      </template>
    </v-list>
  </div>
</template>

<script>
import UserSnippet from './UserSnippet';

export default {
  name: 'LoadRefinedHistory',
  props: ['vrData', 'readonly'],
  components: { UserSnippet },
  data () {
    return {
    };
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    onVerifyEdit (item) {
      this.$emit('on-verify-edit', item);
    }
  }
};
</script>
