<template>
  <v-layout v-if="$root.isFullFinancialDataAllowedToView && userId >= 0" wrap>
    <v-flex shrink>
      <v-btn class="ma-1"
             :disabled="selectedItems.length === 0"
             color="success"
             @click="$emit('create-report-group', selectedItems, userId, paramsType)">
        <v-icon :left="!$root.isMobile">mdi-clipboard-check-outline</v-icon>
        <template v-if="!$root.isMobile">{{$t('Reports.CreateReportsGroup')}}</template>
      </v-btn>
    </v-flex>
    <v-flex v-if="createDOTReport" shrink>
      <v-btn class="ma-1"
             :disabled="selectedItems.length === 0"
             color="success"
             @click="$emit('create-report-group-dot', selectedItems, userId, paramsType)">
        <v-icon :left="!$root.isMobile">mdi-clipboard-check-outline</v-icon>
        <template v-if="!$root.isMobile">{{$t('Reports.CreateReportsGroupDOT')}}</template>
      </v-btn>
    </v-flex>
    <v-flex shrink class="pt-1" v-if="!hideActionsBtn">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" :disabled="selectedItems.length === 0" class="ma-1 mt-0">
            Actions<v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item class="ma-1"
                       :disabled="!isSelectedHaveNotPayed"
                       @click="$emit('mark-selected-as-paid', selectedItems, userId, overrideType, overrideFieldName)">
            <v-icon left>mdi-cash-check</v-icon>
            {{$t('Reports.MarkSelectedAsPayed')}}
          </v-list-item>
          <v-list-item class="ma-1"
                       :disabled="!isSelectedHavePayed"
                       @click="$emit('mark-selected-as-not-paid', selectedItems, userId, overrideType, overrideFieldName)">
            <v-icon left>mdi-cash-remove</v-icon>
            {{$t('Reports.MarkSelectedAsNotPayed')}}
          </v-list-item>
          <v-list-item class="ma-1"
                       :disabled="selectedItems.length === 0"
                       @click="$emit('mark-selected-as-payed-and-create-report-group', selectedItems, userId, overrideType, overrideFieldName, paramsType)">
            <v-icon left>mdi-clipboard-check-multiple-outline</v-icon>
            {{$t('Reports.MarkAsPayedAndCreateReportsGroup')}}
          </v-list-item>
          <!--<v-list-item class="ma-1"
                       :disabled="selectedItems.length === 0"
                       @click="$emit('paidDTChangeMulti', selectedItems, userId, selectedItems[0].paidDateTime)">
            <v-icon left>mdi-calendar-edit</v-icon>
            {{$t('Reports.EditPayedDateOnSelectedLoads')}}
          </v-list-item>-->
        </v-list>
      </v-menu>
    </v-flex>
    <v-flex shrink>
      <v-btn v-if="userId => 0 && !hideReportsGroups"
             class="ma-1"
             @click="$emit('show-saved-reports', overrideFieldName, overrideType)">
        <v-icon :left="!$root.isMobile">mdi-file-document</v-icon>
        <template v-if="!$root.isMobile">{{$t('Reports.SavedReports')}}</template>
      </v-btn>
    </v-flex>
    <!--<v-flex shrink>
      <v-btn class="ma-1" @click="onPrintBtn"><v-icon left>mdi-printer</v-icon>Print</v-btn>
    </v-flex>-->
    <v-flex shrink>
      <v-btn class="ma-1" @click="onPrintSelectedBtn" :disabled="selectedItems.length === 0">
        <v-icon :left="!$root.isMobile">mdi-printer</v-icon>
        <template v-if="!$root.isMobile">{{$t('Reports.PrintSelected')}}</template>
      </v-btn>
    </v-flex>
  </v-layout>
  <v-layout v-else-if="$root.isDispatcherFinancialDataAllowedToView" wrap>
    <v-flex shrink>
      <v-btn v-if="userId > 0 && !hideReportsGroups"
             class="ma-1"
             @click="$emit('show-saved-reports', overrideFieldName, overrideType)">
        <v-icon :left="!$root.isMobile">mdi-file-document</v-icon>
        <template v-if="!$root.isMobile">{{$t('Reports.SavedReports')}}</template>
      </v-btn>
    </v-flex>
    <!--<v-flex shrink>
      <v-btn class="ma-1" @click="onPrintBtn"><v-icon left>mdi-printer</v-icon>Print</v-btn>
    </v-flex>-->
    <v-flex shrink>
      <v-btn class="ma-1" @click="onPrintSelectedBtn" :disabled="selectedItems.length === 0">
        <v-icon :left="!$root.isMobile">mdi-printer</v-icon>
        <template v-if="!$root.isMobile">{{$t('Reports.PrintSelected')}}</template>
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'ReportActions',
  props: ['selectedItems',
          'userId',
          'paramsType',
          'overrideType',
          'overrideFieldName',
          'isSelectedHaveNotPayed',
          'isSelectedHavePayed',
          'from',
          'to',
          'hideReportsGroups',
          'hideActionsBtn',
          'createDOTReport'],
  data: function () {
    return {
    };
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    onPrintBtn () {
      const url = '/reports/print/' + this.paramsType + '/' + this.$root.companyId + '/' + this.userId + '/' + this.from + '/' + this.to;
      window.open(url, '_blank');
      // this.$root.route('/reports/print/' + this.paramsType + '/' + this.userId + '/' + this.from + '/' + this.to);
    },
    onPrintSelectedBtn () {
      const url = '/reports/print/' + this.paramsType + '/' + this.$root.companyId + '/' + this.userId + '/' + this.from + '/' + this.to + '/' + this.selectedItems.map(el => el.id).join(',');
      window.open(url, '_blank');
      // this.$root.route(url);
    },
  }
};
</script>
