<template>
  <div v-if="$root.isMobile">
    <v-skeleton-loader type="list-item" class="ma-2" v-if="loading"/>
    <v-list class="list-more-dense">
      <v-list-item>
        <v-list-item-title>Trips Count</v-list-item-title>
        <v-list-item-action v-if="totalsCounts.hasOwnProperty('trips')">{{totalsCounts.trips.toLocaleString()}}</v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>Loads Count</v-list-item-title>
        <v-list-item-action v-if="totalsCounts.hasOwnProperty('loads')">{{totalsCounts.loads.toLocaleString()}}</v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>Miles Driven By Odometer</v-list-item-title>
        <v-list-item-action v-if="totalsCounts.hasOwnProperty('totalMilesByOdometer')">{{totalsCounts.totalMilesByOdometer.toLocaleString()}}{{$t('milesShort')}}</v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>Drivers Rate By Odometer</v-list-item-title>
        <v-list-item-action v-if="totalsCounts.hasOwnProperty('totalRateByOdometer')">${{totalsCounts.totalRateByOdometer.round(2).toLocaleString()}}</v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>Drivers Detention</v-list-item-title>
        <v-list-item-action v-if="totalsCounts.hasOwnProperty('totalDriverDetention')">${{totalsCounts.totalDriverDetention.round(2).toLocaleString()}}</v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>To Compensate</v-list-item-title>
        <v-list-item-action v-if="totalsCounts.hasOwnProperty('totalToCompensate')">${{totalsCounts.totalToCompensate.round(2).toLocaleString()}}</v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>Bonuses</v-list-item-title>
        <v-list-item-action v-if="totalsCounts.hasOwnProperty('totalBonuses')">${{totalsCounts.totalBonuses.round(2).toLocaleString()}}</v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>Deductions</v-list-item-title>
        <v-list-item-action v-if="totalsCounts.hasOwnProperty('totalDeductions')">{{totalsCounts.totalDeductions.round(2).toLocaleString()}}</v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>Salary</v-list-item-title>
        <v-list-item-action v-if="totalsCounts.hasOwnProperty('totalSalary')">{{totalsCounts.totalSalary.round(2).toLocaleString()}}</v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
  <div v-else>
    <v-skeleton-loader type="table-row" class="ma-2" v-if="loading"/>
    <v-simple-table dense class="mb-5" v-else>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-center">Trips Count</th>
          <th class="text-center">Loads Count</th>
          <th class="text-center">Miles Driven By Odometer</th>
          <th class="text-center">Drivers Rate By Odometer</th>
          <th class="text-center">Drivers Detention</th>
          <th class="text-center">To Compensate</th>
          <th class="text-center">Bonuses</th>
          <th class="text-center">Deductions</th>
          <th class="text-center">Salary</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td v-if="totalsCounts.hasOwnProperty('trips')" class="text-center">
            <h2>{{totalsCounts.trips.toLocaleString()}}</h2>
          </td>
          <td v-if="totalsCounts.hasOwnProperty('loads')" class="text-center">
            <h2>{{totalsCounts.loads.toLocaleString()}}</h2>
          </td>
          <td v-if="totalsCounts.hasOwnProperty('totalMilesByOdometer')" class="text-center">
            <h2>{{totalsCounts.totalMilesByOdometer.toLocaleString()}}{{$t('milesShort')}}</h2>
          </td>
          <td v-if="totalsCounts.hasOwnProperty('totalRateByOdometer')" class="text-center">
            <h2>${{totalsCounts.totalRateByOdometer.round(2).toLocaleString()}}</h2>
          </td>
          <td v-if="totalsCounts.hasOwnProperty('totalDriverDetention')" class="text-center">
            <h2>${{totalsCounts.totalDriverDetention.round(2).toLocaleString()}}</h2>
          </td>
          <td v-if="totalsCounts.hasOwnProperty('totalToCompensate')" class="text-center">
            <h2>${{totalsCounts.totalToCompensate.round(2).toLocaleString()}}</h2>
          </td>
          <td v-if="totalsCounts.hasOwnProperty('totalBonuses')" class="text-center">
            <h2>${{totalsCounts.totalBonuses.round(2).toLocaleString()}}</h2>
          </td>
          <td v-if="totalsCounts.hasOwnProperty('totalDeductions')" class="text-center">
            <h2>${{totalsCounts.totalDeductions.round(2).toLocaleString()}}</h2>
          </td>
          <td v-if="totalsCounts.hasOwnProperty('totalSalary')" class="text-center">
            <h2>${{totalsCounts.totalSalary.round(2).toLocaleString()}}</h2>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import reportsMixin from '../../mixins/reportsMixin.js';
export default {
  name: 'DriverTotals',
  props: ['to', 'from', 'driverId'],
  mixins: [ reportsMixin ],
  components: {
  },
  data: function () {
    return {
      loading: false,
      totalsCounts: {}
    };
  },
  mounted () {
    this.setDriversTotalsHeaders();
    this.fetchData();
  },
  methods: {
    fetchData () {
      if (!this.from || !this.to) return;
      this.loading = true;
      this.getDriversReportTotalData(this.from, this.to, this.driverId || 0).then((response) => {
        if (response.status === 'ok') {
          this.totalsCounts = response.results;
        }
      }).finally(() => {
        this.loading = false;
      });
    }

  }
};
</script>
