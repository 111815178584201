<template>
  <div v-if="!hideFilters">
    <v-navigation-drawer
      v-if="isExpansionPanel"
      v-model="filtersPanel"
      absolute
      temporary
    >
      <v-layout>
        <v-flex grow>
          <h2 class="ml-1">{{$t('Filters')}}</h2>
        </v-flex>
        <v-flex shrink>
          <v-btn icon @click="filtersPanel = false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-flex>
      </v-layout>
      <v-list>
            <template v-for="(item, index) in filters">
              <v-list-item :key="'filter' + index" class="pa-1">
                <v-autocomplete
                  :key="item.name"
                  :filled="!outlined"
                  :outlined="outlined"
                  dense
                  :ref="item.name"
                  :clearable="item.multiple"
                  :multiple="item.multiple"
                  :hide-details="!item.hint"
                  :hint="item.hint"
                  :persistent-hint="item.hint ? true : false"
                  item-text="name"
                  item-value="id"
                  @keyup="(val) => { $emit(item.name + 'KeyUp', val); }"
                  @change="(v) => onFilterChanged(index, item.name, v)"
                  :search-input.sync="item.search"
                  v-model="item.field"
                  :items="item.list"
                  :label="item.label + (item.isSearch ? ' Type to search...' : '')"
                />
              </v-list-item>
            </template>
      </v-list>
    </v-navigation-drawer>
    <v-layout v-else :wrap="$root.isMobile">
      <slot name="prefix_items_slot"></slot>
      <template v-for="(item, index) in filters">
        <v-flex :xs12="$root.isMobile" :shrink="!$root.isMobile" :key="'filter' + index" class="pa-1">
          <v-autocomplete
            :key="item.name"
            :filled="!outlined"
            :outlined="outlined"
            dense
            :ref="item.name"
            :clearable="item.multiple"
            :multiple="item.multiple"
            :hide-details="!item.hint"
            :hint="item.hint"
            :persistent-hint="item.hint ? true : false"
            item-text="name"
            item-value="id"
            @keyup="(val) => { $emit(item.name + 'KeyUp', item.search); }"
            @change="(v) => onFilterChanged(index, item.name, v)"
            :search-input.sync="item.search"
            v-model="item.field"
            :items="item.list"
            :label="item.label + (item.isSearch ? ' (Type to search...)' : '')"
          />
        </v-flex>
        <slot name="postfix_items_slot"></slot>
      </template>
    </v-layout>
  </div>
</template>

<!--
item format

name
label
list

-->
<script>
export default {
  name: 'FiltersPanel',
  props: ['hideFilters', 'loading', 'items', 'prefix', 'isExpansionPanel', 'outlined'],
  data: function () {
    return {
      filtersPanel: false,
      filters: [],
      selectedFilters: []
    };
  },
  computed: {
  },
  mounted () {
    let i = 0;
    let j = 0;
    let result = [];
    let filterVal = 0;
    this.filters = [];
    if (this.items) {
      for (i = 0; i < this.items.length; i++) {
        this.filters.push({
          field: this.items[i].field,
          multiple: this.items[i].multiple,
          hint: this.items[i].hint,
          isSearch: this.items[i].isSearch,
          name: this.items[i].name,
          label: this.items[i].label,
          list: this.items[i].list
        });
      }
      for (i = 0; i < this.filters.length; i++) {
        filterVal = this.$root.getSessionStorageItem(this.prefix + this.filters[i].name);
        if (filterVal) {
          if (this.filters[i].multiple) {
            this.filters[i].field = [];
            let valuesArray = filterVal.split(',');
            valuesArray.forEach(el => {
              if (isNaN(parseInt(el))) {
                this.filters[i].field.push(el);
              } else {
                this.filters[i].field.push(parseInt(el));
              }
            });
          } else if (isNaN(parseInt(filterVal))) {
            this.filters[i].field = filterVal;
          } else {
            this.filters[i].field = parseInt(filterVal);
          }
          for (j = 0; j < this.filters[i].list.length; j++) {
            this.filters[i].fieldName = this.getFieldName(i, filterVal);
          }
        }
      }
    }
    for (i = 0; i < this.filters.length; i++) {
      result[this.filters[i].name] = this.filters[i].field;
    }
    this.$emit('change', result);
  },
  methods: {
    showFiltersDrawer () {
      this.filtersPanel = true;
    },
    getFieldName (index, val) {
      let j = 0;
      for (j = 0; j < this.filters[index].list.length; j++) {
        if (!isNaN(parseInt(val)) && parseInt(this.filters[index].list[j].id) === parseInt(val)) {
          return this.filters[index].list[j].name;
        }
        if (isNaN(parseInt(val)) && this.filters[index].list[j].id === val) {
          return this.filters[index].list[j].name;
        }
      }
    },
    onClearFilter (index) {
      let i = 0;
      let result = {};
      this.filters[index].field = '';
      this.filters[index].fieldName = '';
      this.$root.removeFromSessionStorageItem(this.prefix + this.filters[index].name);
      for (i = 0; i < this.filters.length; i++) {
        result[this.filters[i].name] = this.filters[i].field;
      }
      this.$emit('change', result);
    },
    onFilterChanged (index, name, value) {
      let i = 0;
      let result = {};
      this.filters[index].fieldName = this.getFieldName(index, value);
      if (value === '' || value === 'All') {
        this.$root.removeFromSessionStorageItem(this.prefix + this.filters[index].name);
      } else {
        this.$root.setSessionStorageItem(this.prefix + name, value);
      }
      for (i = 0; i < this.filters.length; i++) {
        result[this.filters[i].name] = this.filters[i].field;
      }
      if (this.$refs.hasOwnProperty(name)) {
        this.$refs[name][0].blur();
      }
      this.$emit('change', result);
    }
  }
};
</script>
