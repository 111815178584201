export default {
  data: function () {
  },
  methods: {
    storeDataInIndexedDB (dbName, storageName, key, data) {
      return new Promise(resolve => {
        let indexedDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.OIndexedDB || window.msIndexedDB;
        let IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.OIDBTransaction || window.msIDBTransaction;
        let dbVersion = 1.0;

        // Create/open database
        let request = indexedDB.open(dbName, dbVersion);
        let db;
        let createObjectStore = function (dataBase) {
          // Create an objectStore
          // console.log('Creating objectStore')
          dataBase.createObjectStore(storageName);
        };

        let putDataInDb = function (blob) {
          // console.log('Putting elephants in IndexedDB');

          // Open a transaction to the database
          let readWriteMode = typeof IDBTransaction.READ_WRITE === 'undefined' ? 'readwrite' : IDBTransaction.READ_WRITE;
          let transaction = db.transaction([storageName], readWriteMode);

          // Put the blob into the dabase
          transaction.objectStore(storageName).put(blob, key).onsuccess = function () {
            resolve({ status: 'ok' });
          };;
        };

        request.onerror = function (event) {
          console.error('Error creating/accessing IndexedDB database');
        };

        request.onsuccess = function (event) {
          // console.log("Success creating/accessing IndexedDB database");
          db = request.result;

          db.onerror = function (event) {
            console.error('Error creating/accessing IndexedDB database');
          };

          // Interim solution for Google Chrome to create an objectStore. Will be deprecated
          if (db.setVersion) {
            if (db.version !== dbVersion) {
              var setVersion = db.setVersion(dbVersion);
              setVersion.onsuccess = function () {
                createObjectStore(db);
                putDataInDb(data);
              };
            }
            else {
              putDataInDb(data);
            }
          } else {
            putDataInDb(data);
          }
        }

        // For future use. Currently only in latest Firefox versions
        request.onupgradeneeded = function (event) {
          createObjectStore(event.target.result);
        };
      });
    },
    retrieveDataFromIndexedDB (dbName, storageName, key) {
      return new Promise(resolve => {
        let indexedDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.OIndexedDB || window.msIndexedDB;
        let IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.OIDBTransaction || window.msIDBTransaction;
        let dbVersion = 1.0;

        // Create/open database
        let request = indexedDB.open(dbName, dbVersion);
        let db;
        let createObjectStore = function (dataBase) {
          // Create an objectStore
          // console.log('Creating objectStore')
          dataBase.createObjectStore(storageName);
        };

        let pullElephantInDb = function () {
          // Open a transaction to the database
          let readWriteMode = typeof IDBTransaction.READ_WRITE === 'undefined' ? 'readwrite' : IDBTransaction.READ_WRITE;
          let transaction = db.transaction([storageName], readWriteMode);
          // Retrieve the file that was just stored
          transaction.objectStore(storageName).get(key).onsuccess = function (event) {
            resolve(event.target.result);
          };
        };

        request.onerror = function (event) {
          console.error('Error creating/accessing IndexedDB database');
        };

        request.onsuccess = function (event) {
          // console.log("Success creating/accessing IndexedDB database");
          db = request.result;

          db.onerror = function (event) {
            console.error('Error creating/accessing IndexedDB database');
          };

          // Interim solution for Google Chrome to create an objectStore. Will be deprecated
          if (db.setVersion) {
            if (db.version !== dbVersion) {
              let setVersion = db.setVersion(dbVersion);
              setVersion.onsuccess = function () {
                createObjectStore(db);
                pullElephantInDb();
              };
            }
            else {
              pullElephantInDb();
            }
          } else {
            pullElephantInDb();
          }
        }

        // For future use. Currently only in latest Firefox versions
        request.onupgradeneeded = function (event) {
          createObjectStore(event.target.result);
        };

        request.onupgradeneeded = function (event) {
          createObjectStore(event.target.result);
        };
      });
    },
    deleteDataInIndexedDB (dbName, storageName, key) {
      return new Promise(resolve => {
        let indexedDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.OIndexedDB || window.msIndexedDB;
        let IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.OIDBTransaction || window.msIDBTransaction;
        let dbVersion = 1.0;

        // Create/open database
        let request = indexedDB.open(dbName, dbVersion);
        let db;
        let createObjectStore = function (dataBase) {
          // Create an objectStore
          // console.log('Creating objectStore')
          dataBase.createObjectStore(storageName);
        };

        let pullElephantInDb = function () {
          // Open a transaction to the database
          let readWriteMode = typeof IDBTransaction.READ_WRITE === 'undefined' ? 'readwrite' : IDBTransaction.READ_WRITE;
          let transaction = db.transaction([storageName], readWriteMode);
          // Retrieve the file that was just stored
          transaction.objectStore(storageName).delete(key).onsuccess = function (event) {
            resolve({ status: 'ok' });
          };
        };

        request.onerror = function (event) {
          console.error('Error creating/accessing IndexedDB database');
        };

        request.onsuccess = function (event) {
          // console.log("Success creating/accessing IndexedDB database");
          db = request.result;

          db.onerror = function (event) {
            console.error('Error creating/accessing IndexedDB database');
          };

          // Interim solution for Google Chrome to create an objectStore. Will be deprecated
          if (db.setVersion) {
            if (db.version !== dbVersion) {
              let setVersion = db.setVersion(dbVersion);
              setVersion.onsuccess = function () {
                createObjectStore(db);
                pullElephantInDb();
              };
            }
            else {
              pullElephantInDb();
            }
          } else {
            pullElephantInDb();
          }
        }

        // For future use. Currently only in latest Firefox versions
        request.onupgradeneeded = function (event) {
          createObjectStore(event.target.result);
        };

        request.onupgradeneeded = function (event) {
          createObjectStore(event.target.result);
        };
      });
    },
    getAllKeysFromIndexedDB (dbName, storageName) {
      return new Promise(resolve => {
        let indexedDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.OIndexedDB || window.msIndexedDB;
        let IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.OIDBTransaction || window.msIDBTransaction;
        let dbVersion = 1.0;

        // Create/open database
        let request = indexedDB.open(dbName, dbVersion);
        let db;
        let createObjectStore = function (dataBase) {
          // Create an objectStore
          // console.log('Creating objectStore')
          dataBase.createObjectStore(storageName);
        };

        let pullElephantInDb = function () {
          // Open a transaction to the database
          let readWriteMode = typeof IDBTransaction.READ_WRITE === 'undefined' ? 'readwrite' : IDBTransaction.READ_WRITE;
          let transaction = db.transaction([storageName], readWriteMode);
          // Retrieve the file that was just stored
          transaction.objectStore(storageName).getAllKeys().onsuccess = function (event) {
            resolve(event.target.result);
          };
        };

        request.onerror = function (event) {
          console.error('Error creating/accessing IndexedDB database');
        };

        request.onsuccess = function (event) {
          // console.log("Success creating/accessing IndexedDB database");
          db = request.result;

          db.onerror = function (event) {
            console.error('Error creating/accessing IndexedDB database');
          };

          // Interim solution for Google Chrome to create an objectStore. Will be deprecated
          if (db.setVersion) {
            if (db.version !== dbVersion) {
              let setVersion = db.setVersion(dbVersion);
              setVersion.onsuccess = function () {
                createObjectStore(db);
                pullElephantInDb();
              };
            }
            else {
              pullElephantInDb();
            }
          } else {
            pullElephantInDb();
          }
        }

        // For future use. Currently only in latest Firefox versions
        request.onupgradeneeded = function (event) {
          createObjectStore(event.target.result);
        };

        request.onupgradeneeded = function (event) {
          createObjectStore(event.target.result);
        };
      });
    }
  }
};
