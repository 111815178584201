<template>
  <div v-if="timeDiff > 0" class="text-no-wrap">
    <b>{{$t('Loads.OnLocationTime')}}</b>:
    <span :style="{color: timeDiff > (hoursUntilDetention * 60 * 60) ? '#f44336' : '#4caf50'}">
      {{$root.showDuration(timeDiff)}}
    </span>
  </div>
</template>

<script>
export default {
  name: 'TimeOnLocationSnippet',
  props: ['location', 'hoursUntilDetention'],
  computed: {
    timeDiff () {
      const endTS = Math.max(location.doneByDriverTS, location.notDoneByDriverTS);
      return endTS - location.onSiteByDriverTS;
    }
  }
};
</script>
