export default {
  methods: {
    notificationsList (page = 0, itemsPerPage = 25, endpointUrl = '', statusFilter = 'Unread', userFilter = 0, search = '') {
      return this.$root.post('/api/notifications/list/' + page + '/' + itemsPerPage, {
        endpointUrl: endpointUrl,
        statusFilter: statusFilter,
        userFilter: userFilter,
        search: search
      });
    },
    getNewNotifications () {
      return this.$root.get('/api/notifications/newList');
    },
    updateNotificationToShowedById (id) {
      this.$root.post('/api/notifications/updateToShowed', { id: id });
    },
    updateUserSubscriptionData (endpoint, subscriptionData) {
      this.$root.post('/api/notifications/setEndpoint', { endpoint: endpoint, subscriptionData: subscriptionData, userAgent: navigator.userAgent });
    },
    removeUserSubscriptionData (endpoint) {
      this.$root.post('/api/notifications/removeEndpoint', { endpoint: endpoint });
    },
    markAsRead (uid, userId = 0) {
      return this.$root.get('/api/notifications/markAsRead/' + uid + '/' + userId);
    },
    markAllAsRead () {
      return this.$root.get('/api/notifications/markAllRead');
    },
    getNotificationsTypes () {
      return this.$root.get('/api/notifications/typesList');
    },
    addNotification (title, options, users = [], role = '') {
      return new Promise((resolve, reject) => {
        this.$root.post('/api/notifications/create', {
          users: users,
          role: role,
          title: title,
          options: options
        }).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Notifications.Created'), { color: 'success' });
            resolve(response);
          } else {
            this.$root.toast(this.$i18n.t('Notifications.CreateError'), { color: 'error' });
            reject(response);
          }
        }, (error) => {
          console.error('ERROR', error);
          reject(error);
        });
      });
    },
  }
};
