<template>
  <v-layout>
    <v-flex grow>
      <v-layout>
        <v-flex grow>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <div v-on="on" :style="{ maxWidth: maxWidth, overflow: 'hidden', whiteSpace: 'nowrap',textOverflow: 'ellipsis',marginBottom: '0px' }">
                {{loadName.split('--')[0]}}
              </div>
            </template>
            <span>{{loadName.split('--')[0]}}</span>
          </v-tooltip>
        </v-flex>
        <v-flex shrink v-if="!hideCopyIcons">
          <v-btn small icon @click.stop="$root.copyToClipboard(loadName.split('--')[0])"><v-icon small>mdi-content-copy</v-icon></v-btn>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex grow>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <div v-on="on" :style="{ maxWidth: maxWidth, overflow: 'hidden', whiteSpace: 'nowrap',textOverflow: 'ellipsis',marginBottom: '0px' }">
                {{loadName.split('--')[1]}}
              </div>
            </template>
            <span>{{loadName.split('--')[1]}}</span>
          </v-tooltip>
        </v-flex>
        <v-flex shrink v-if="!hideCopyIcons">
          <v-btn small icon @click.stop="$root.copyToClipboard(loadName.split('--')[1])"><v-icon small>mdi-content-copy</v-icon></v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex shrink class="text-right" v-if="gmapsDirectionsBtn">
      <v-btn icon small @click.stop="$emit('open-gmaps-directions')"><v-icon small>mdi-directions</v-icon></v-btn><br/>
      <v-btn icon small @click.stop="$emit('show-map-modal')"
                        @mouseenter="(event) => { $emit('show-map-div', event) }"
                        @mouseout="() => { $root.floatingMap = false; }">
        <v-icon small @mouseenter="(event) => { $emit('show-map-div', event) }">mdi-map</v-icon></v-btn>
    </v-flex>
  </v-layout>
</template>

<script>

export default {
  name: 'LoadLocationsStartEnd',
  props: ['loadName', 'maxWidth', 'gmapsDirectionsBtn', 'hideCopyIcons'],
  components: {
  },
  data: function () {
    return {
    };
  },
  methods: {
  }
};
</script>
