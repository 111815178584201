<template>
    <router-view/>
</template>

<script>
export default {
  props: {
  }
};
</script>
