export default {
  data: function () {
    return {
      fieldsLoading: {}
    };
  },
  methods: {
    setLoadingField (fieldName, value) {
      this.fieldsLoading[fieldName] = value;
      this.$forceUpdate();
    },
    isFieldLoading (fieldName) {
      if (this.fieldsLoading[fieldName]) {
        return this.fieldsLoading[fieldName];
      }
      return false;
    }
  }
};
