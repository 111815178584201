export default {
  data: function () {
    return {
      pagesTabs: {},
      pageTabIndex: {},
      prevPageTabIndex: {}
    };
  },
  methods: {
    loadTabsFromStorage () {
      let i = 0;
      let _tmp = {};
      const defaultPath = '/user/dashboard';
      const newPath = this.$route.path === '' || this.$route.path === '/' ? defaultPath : this.$route.path;// this.$route.path;
      let isTabFoundByPath = false;
      this.pageTabIndex = {};

      /* if (this.$root.getLocalStorageItem('selectedTab') >= 0) {
                  this.pageTabIndex = parseInt(this.$root.getLocalStorageItem('selectedTab'));
            } */
      if (JSON.parse(this.$root.getLocalStorageItem('tabs_by_company'))) {
        this.pagesTabs = JSON.parse(this.$root.getLocalStorageItem('tabs_by_company'));
        if (this.pagesTabs.hasOwnProperty(this.$root.companyId)) {
          // Clear duplicates
          _tmp = {};
          for (i = 0; i < this.pagesTabs[this.$root.companyId].length; i++) {
            if (_tmp.hasOwnProperty(this.pagesTabs[this.$root.companyId][i].path)) {
              this.pagesTabs[this.$root.companyId].splice(i, 1);
            } else {
              _tmp[this.pagesTabs[this.$root.companyId][i].path] = 1;
            }
          }

          for (i = 0; i < this.pagesTabs[this.$root.companyId].length; i++) {
            if (newPath === this.pagesTabs[this.$root.companyId][i].path) {
              this.pageTabIndex[this.$root.companyId] = i;
              isTabFoundByPath = true;
              this.$root.route(this.pagesTabs[this.$root.companyId][this.pageTabIndex[this.$root.companyId]].path);
              break;
            }
          }
        }
        if (!isTabFoundByPath) {
          this.addNewPageTab(newPath);
        }

        if (!this.pagesTabs.hasOwnProperty(this.$root.companyId)) {
          this.pagesTabs[this.$root.companyId] = [];
        }

        /*
          if (this.pagesTabs.length > this.pageTabIndex) {
              this.$root.route(this.pagesTabs[this.pageTabIndex].path);
          }
          */
      }
    },
    saveTabsToStorage () {
      this.$root.setLocalStorageItem('tabs_by_company', JSON.stringify(this.pagesTabs));
      this.$root.setLocalStorageItem('selectedTabByCompany', this.pageTabIndex);
    },
    getPagePath () {
      if (this.pagesTabs.hasOwnProperty(this.$root.companyId) &&
          this.pageTabIndex.hasOwnProperty(this.$root.companyId)) {
        if (this.pagesTabs[this.$root.companyId][this.pageTabIndex[this.$root.companyId]]) {
          this.saveTabsToStorage();
          return this.pagesTabs[this.$root.companyId][this.pageTabIndex[this.$root.companyId]].path;
        }
      }
      return '';
    },
    setPageRoute (path) {
      if (!this.pagesTabs.hasOwnProperty(this.$root.companyId)) return;
      if (!this.pagesTabs[this.$root.companyId][this.pageTabIndex[this.$root.companyId]]) return;

      this.getTitleFromPath(path).then((title) => {
        this.pagesTabs[this.$root.companyId][this.pageTabIndex[this.$root.companyId]].path = path;
        this.pagesTabs[this.$root.companyId][this.pageTabIndex[this.$root.companyId]].title = title;
        this.saveTabsToStorage();
      });
    },
    addNewPageTab (path = '') {
      const defaultPath = '/user/dashboard';
      const newPath = path === '' || path === '/' ? defaultPath : path;// this.$route.path;

      if (!this.pagesTabs.hasOwnProperty(this.$root.companyId)) {
        this.pagesTabs[this.$root.companyId] = [];
      }
      if (!this.pageTabIndex.hasOwnProperty(this.$root.companyId)) {
        this.pageTabIndex[this.$root.companyId] = 0;
      }

      this.getTitleFromPath(newPath).then((title) => {
        this.pagesTabs[this.$root.companyId].push({
          title: title,
          path: newPath
        });
        // remove first tab if tabs more that 10
        if (this.pagesTabs[this.$root.companyId].length > 10) {
          this.pagesTabs[this.$root.companyId].splice(0, 1);
        }
        this.pageTabIndex[this.$root.companyId] = this.pagesTabs[this.$root.companyId].length - 1;
        this.$root.route(this.pagesTabs[this.$root.companyId][this.pageTabIndex[this.$root.companyId]].path);
        this.saveTabsToStorage();
      });
    },
    returnToPrevTab () {
      if (this.prevPageTabIndex.hasOwnProperty(this.$root.companyId)) {
        this.pageTabIndex[this.$root.companyId] = this.prevPageTabIndex[this.$root.companyId];
      } else {
        this.pageTabIndex[this.$root.companyId] = 0;
      }
    },
    setToPrevTab () {
      if (this.pageTabIndex.hasOwnProperty(this.$root.companyId)) {
        this.prevPageTabIndex[this.$root.companyId] = this.pageTabIndex[this.$root.companyId];
      } else {
        this.prevPageTabIndex[this.$root.companyId] = 0;
      }
    },
    removePageTab (index) {
      if (!this.pagesTabs.hasOwnProperty(this.$root.companyId)) return;
      this.$dialog.confirm(this.$root.t('PageTabs.CloseTabConfirmationMessage')).then(dialog => {
        this.pagesTabs[this.$root.companyId].splice(index, 1);
        if (this.pageTabIndex[this.$root.companyId] === index) {
          this.pageTabIndex[this.$root.companyId]--;
          if (this.pagesTabs[this.$root.companyId].length > this.pageTabIndex[this.$root.companyId]) {
            this.$root.route(this.pagesTabs[this.$root.companyId][this.pageTabIndex[this.$root.companyId]].path);
          }
        }
        this.saveTabsToStorage();
      }).catch(() => {
      });
    },

    /*
    user/dashboard', name: 'userDashboard', component: Dashboard },
        { path: 'user/settings', name: 'userSettings', component: UserSettings },
        { path: 'user/page', name: 'myPage', component: UserPage },
        { path: 'user/page/:id', name: 'userPage', component: UserPage },
        { path: 'user/edit/:id', name: 'userEdit', component: UserSettings },
        { path: 'user/create/:role?', name: 'userCreate', component: UserCreate },
        { path: 'chart/list', name: 'chartList', component: ChartList },
        { path: 'chart/create', name: 'chartCreate', component: ChartCreate },
        { path: 'chart/edit/:id', name: 'chartEdit', component: ChartCreate },
        { path: 'company_documents', name: 'companyDocuments', component: CompanyDocuments },
        { path: 'settings/access_rights', name: 'accessRights', component: AccessRights },
        { path: 'logs_list', name: 'logsList', component: LogsList },
        { path: 'settings/global', name: 'globalSettings', component: GlobalSettings },
        { path: 'invoices/list', name: 'invoicesList', component: InvoicesList},
        { path: 'invoices/create', name: 'invoiceCreate', component: InvoiceCreateEditForm },
        { path: 'invoices/create/:loadId', name: 'invoiceCreateFromLoad', component: InvoiceCreateEditForm },
        { path: 'invoices/edit/:id', name: 'invoiceEdit', component: InvoiceCreateEditForm },
        { path: 'invoices/page/:id', name: 'invoicePage', component: InvoicePage },
        { path: 'invoices/print/:id
    * */
    getTitleFromPath (path) {
      return new Promise((resolve, reject) => {
        const pathData = path.split('/');

        if (pathData[1] === 'settings' && pathData[2] === 'access_rights') {
          resolve('Access rights');
        } else if (pathData[1] === 'logs_list') {
          resolve('User logs');
        } else if (pathData[1] === 'mails') {
          resolve('Mails');
        } else if (pathData[1] === 'users' && pathData.length > 2) { // Users Lists
          resolve(pathData[2] + 's');
        } else if (pathData[1] === 'users') {
          resolve('Users List');
        } else if (pathData[1] === 'settings' && pathData[2] === 'global') {
          resolve('Global settings');
        } else if (pathData[1] === 'user') { // User
          if (pathData[2] === 'dashboard') {
            resolve('Dashboard');
          } else if (pathData[2] === 'settings') {
            resolve('My Settings');
          } else if (pathData[2] === 'page' && pathData.length === 3) {
            resolve('My Page');
          } else if ((pathData[2] === 'page' || pathData[2] === 'edit') && pathData.length > 3) {
            if (pathData[3] && parseInt(pathData[3]) > 0) {
              this.$root.loadUserFields(parseInt(pathData[3])).then((response) => {
                if (response.status === 'ok') {
                  let userName = pathData[3];
                  if (response.fields.firstName) {
                    userName = response.fields.firstName;
                  }
                  if (response.fields.firstName && response.fields.lastName) {
                    userName = response.fields.firstName + ' ' + response.fields.lastName;
                  }
                  if (pathData[2] === 'page') {
                    resolve('Page ' + userName);
                  }
                  if (pathData[2] === 'edit') {
                    resolve('Edit ' + userName);
                  }
                }
              });
            }
          } else if (pathData[2] === 'create') {
            resolve(this.$root.t('Creating') + (pathData[3] ? ' ' + pathData[3] : ' ' + this.$root.t('UserLabel')));
          }
        } else if (pathData[1] === 'trucks') { // Trucks
          if (pathData[2] === 'list') {
            resolve('Trucks list');
          } else if (pathData[2] === 'calendar') {
            resolve('Trucks Schedule');
          } else if (pathData[2] === 'page' || pathData[2] === 'edit') {
            if (pathData[3] && parseInt(pathData[3]) > 0) {
              this.$root.getTruck(parseInt(pathData[3])).then((response) => {
                if (response.status === 'ok') {
                  let truckName = pathData[3];
                  if (response.result.name) {
                    truckName = response.result.name;
                  }
                  if (pathData[2] === 'page') {
                    resolve('Truck ' + truckName);
                  } else if (pathData[2] === 'edit') {
                    resolve('Edit ' + truckName);
                  }
                }
              });
            }
          } else if (pathData[2] === 'create') {
            resolve('Truck create');
          }
        } else if (pathData[1] === 'chats') { // Chats
          if (pathData[2] === 'list') {
            resolve('Chat');
          }
        } else if (pathData[1] === 'documents') {
          resolve('Documents');
        } else if (pathData[1] === 'chart') { // Charts
          if (pathData[2] === 'list') {
            resolve('Charts list');
          } else if (pathData[2] === 'edit') {
            resolve('Chart edit #' + pathData[3]);
          } else if (pathData[2] === 'create') {
            resolve('Chart create');
          }
        } else if (pathData[1] === 'bids') { // Bids
          if (pathData[2] === 'list') {
            resolve('Bids list');
          } else if (pathData[2] === 'page') {
            resolve('Bid #' + pathData[3]);
          } else if (pathData[2] === 'edit') {
            resolve('Bid edit #' + pathData[3]);
          } else if (pathData[2] === 'create') {
            resolve('Bid create');
          }
        } else if (pathData[1] === 'loads') { // Loads
          if (pathData[2] === 'list') {
            resolve('Loads list');
          } else if (pathData[2] === 'page') {
            resolve('Load #' + pathData[3]);
          } else if (pathData[2] === 'edit') {
            resolve('Load edit #' + pathData[3]);
          } else if (pathData[2] === 'create') {
            resolve('Load create');
          } else if (pathData[2] === 'calendar') {
            resolve('Loads Schedule');
          } else if (pathData[2] === 'feed') {
            resolve('Loads Feed');
          }
        } else if (pathData[1] === 'shifts') { // Loads
          if (pathData[2] === 'listByDrivers') {
            resolve('Drivers Trips');
          } else if (pathData[2] === 'listByTrucks') {
            resolve('Trucks Trips');
          } else if (pathData[2] === 'create') {
            resolve('Trip create');
          }
        } else if (pathData[1] === 'tasks') { // Tasks
          if (pathData[2] === 'list') {
            resolve('Tasks List');
          } else if (pathData[2] === 'page') {
            resolve('Task #' + pathData[3]);
          } else if (pathData[2] === 'edit') {
            resolve('Task edit #' + pathData[3]);
          } else if (pathData[2] === 'create') {
            resolve('Task create');
          }
        } else if (pathData[1] === 'invoices') { // Invoices
          if (pathData[2] === 'list') {
            resolve('Invoices list');
          } else if (pathData[2] === 'page' || pathData[2] === 'edit') {
            if (pathData[3] && parseInt(pathData[3]) > 0) {
              let invoiceNumber = 0;
              this.$root.getInvoice(parseInt(pathData[3])).then((response) => {
                if (response.status === 'ok') {
                  invoiceNumber = response.result.invoiceNumber;
                  if (pathData[2] === 'page') {
                    resolve('Invoice #' + invoiceNumber);
                  } else if (pathData[2] === 'edit') {
                    resolve('Invoice edit #' + invoiceNumber);
                  }
                }
              });
            }
          } else if (pathData[2] === 'create') {
            resolve('Invoice create');
          } else if (pathData[2] === 'createNewVersion') {
            resolve('Invoice New Ver.');
          }
        } else if (pathData[1] === 'notifications') { // Notifications
          if (pathData[2] === 'list') {
            resolve('Notifications list');
          }
        } else if (pathData[1] === 'reports') { // Reports
          if (pathData[2] && pathData[3]) {
            if (pathData[2] === 'loadsRefines') {
              resolve('Loads Refines Report');
            } else if (pathData[2] === 'trucks') {
              this.$root.getTruck(parseInt(pathData[3])).then((response) => {
                if (response.status === 'ok') {
                  let truckName = pathData[3];
                  if (response.result.name) {
                    truckName = response.result.name;
                  }
                  resolve(truckName + ' Report');
                } else {
                  resolve(pathData[2] + ' Report');
                }
              }).catch((e) => {
                resolve(pathData[2] + ' Report');
              }); ;
            } else if (pathData[2] === 'drivers' || pathData[2] === 'dispatchers') {
              this.$root.loadUserFields(parseInt(pathData[3])).then((response) => {
                if (response.status === 'ok') {
                  let userName = pathData[3];
                  if (response.fields.firstName) {
                    userName = response.fields.firstName;
                  }
                  if (response.fields.firstName && response.fields.lastName) {
                    userName = response.fields.firstName + ' ' + response.fields.lastName;
                  }
                  resolve(userName + ' Report');
                } else {
                  resolve(pathData[2] + ' Report');
                }
              }).catch((e) => {
                resolve(pathData[2] + ' Report');
              });
            } else {
              resolve(pathData[2] + ' Report');
            }
          } else if (pathData[2]) {
            resolve(pathData[2] + ' Report');
          } else {
            resolve('Reports');
          }
        } else if (pathData[1] === 'companies') {
          if (pathData[2] === 'list') {
            resolve('Company list');
          } else if (pathData[2] === 'page' || pathData[2] === 'edit') {
            if (pathData[3] && parseInt(pathData[3]) > 0) {
              resolve('Company #' + pathData[3] + ' edit');
            }
          } else if (pathData[2] === 'create') {
            resolve('Company create');
          }
        } else {
          resolve('New Tab');
        }
      });
    }
  }
};
