<template>
  <div>
    <v-card class="pa-2 mt-1 mb-1">
      <v-layout wrap>
        <v-flex shrink class="pr-2 pt-3">
          <v-switch
            v-if="!readonly"
            class="ml-10"
            @change="onDriverWorkOnPercentChange"
            v-model="isDriverWorkOnPercent"
            inset
            :label="$t('User.Fields.driverIsWorkOnPercentSWITCH')"/>
        </v-flex>
        <v-flex shrink class="pr-2" v-if="isDriverWorkOnPercent">
          <small>{{$t('User.Fields.driverPercentRate')}}</small>
          <v-text-field
            style="width: 90px;"
            :disabled="readonly"
            v-model="driverPercentRate"
            suffix="%"
            type="number" step="0.1" @mousewheel.prevent="" outlined hide-details dense>
          </v-text-field>
        </v-flex>
        <v-flex shrink class="pr-2" v-if="isDriverWorkOnPercent">
          <small>{{$t('User.Fields.driverTeamPercentRate')}}</small>
          <v-text-field
            style="width: 90px;"
            :disabled="readonly"
            v-model="driverTeamPercentRate"
            suffix="%"
            type="number" step="0.1" @mousewheel.prevent="" outlined hide-details dense>
          </v-text-field>
        </v-flex>
        <v-flex shrink class="pr-2" v-if="!isDriverWorkOnPercent">
          <small>{{$t('User.Fields.driverCurrentSoloRate')}}</small>
          <v-text-field
            v-model="driverCurrentSoloRate"
            style="width: 100px;"
            type="number"
            :disabled="readonly"
            outlined
            dense/>
        </v-flex>
        <v-flex shrink class="pr-2" v-if="!isDriverWorkOnPercent">
          <small>{{$t('User.Fields.driverCurrentTeamRate')}}</small>
          <v-text-field
            v-model="driverCurrentTeamRate"
            style="width: 100px;"
            type="number"
            :disabled="readonly"
            outlined
            dense/>
        </v-flex>
        <v-flex shrink v-if="!isDriverWorkOnPercent">
          <small>{{$t('User.Fields.driverCurrentRateExpiredDATE')}}</small>
          <v-menu v-model="driverCurrentRateExpiredDATEMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="driverCurrentRateExpiredDATEFormatted"
                @blur="driverCurrentRateExpiredDATE = $root.parseDate(driverCurrentRateExpiredDATEFormatted)"
                hide-details
                clearable
                :disabled="readonly"
                style="max-width: 250px;"
                @click:clear="onExpiredDateClear"
                v-on="on"
                outlined dense/>
              <!--hint="MM/DD/YYYY" -->
            </template>
            <v-date-picker
              @change="onExpiredDateChange"
              v-model="driverCurrentRateExpiredDATE"
              no-title
              @input="driverCurrentRateExpiredDATEMenu = false"></v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex shrink class="pt-6 pl-1" v-if="!readonly">
          <v-btn color="primary" @click="applyCustomCurrentRate()">{{$t('Apply')}}</v-btn>
        </v-flex>
        <v-flex shrink class="pt-6 pl-1" v-if="isCurrentRateActive && !readonly">
          <v-btn color="error" @click="clearCustomCurrentRate()">{{$t('Clear')}}</v-btn>
        </v-flex>
        <v-flex grow>&nbsp;</v-flex>
        <v-flex shrink class="pr-2" v-if="!isDriverWorkOnPercent">
          <small>{{$t('User.Fields.driverDetentionRate')}}</small>
          <v-text-field
            v-model="driverDetentionRate"
            style="width: 130px;"
            type="number"
            outlined
            dense/>
        </v-flex>
        <v-flex shrink class="pt-6 pl-1" v-if="!readonly">
          <v-btn color="primary" @click="applyDetentionRate()">{{$t('Apply')}}</v-btn>
        </v-flex>
      </v-layout>
      <v-simple-table dense>
        <thead>
          <tr class="text-left">
            <th class="pl-0">Name</th>
            <th class="pl-0">Type</th>
            <th class="pl-0">Solo Rate</th>
            <th class="pl-0">Team Rate</th>
            <th class="pl-0">Fixed Solo Rate</th>
            <th class="pl-0">Fixed Team Rate</th>
            <th class="pl-0">Miles</th>
            <th class="pl-0" v-if="userId > 0 && !readonly">Is Current</th>
            <!--<th class="pl-0" v-if="userId > 0"></th>-->
            <th v-if="!readonly"></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(rate) in driverRates">
            <tr :key="'rate' + rate.id" @click="() => {}" class="text-left">
              <td>{{rate.name}}</td>
              <td>{{ $t('User.RateTypes.' + typeNames[rate.type])}}</td>
              <td>${{rate.soloRate}}</td>
              <td>${{rate.teamRate}}</td>
              <td>${{rate.soloRateFixed}}</td>
              <td>${{rate.teamRateFixed}}</td>
              <td>{{rate.minMiles}} {{$t('milesShort')}}</td>
              <td v-if="userId > 0 && !readonly">
                <v-switch inset dense @change="(v) => onSetCurrentRate(v, rate)" v-model="rate.isCurrent"/>
              </td>
              <!--<td v-if="userId > 0" style="min-width: 250px;">
                <v-menu v-if="rate.isCurrent"
                        v-model="driverCurrentRateExpiredDATEMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="driverCurrentRateExpiredDATEFormatted"
                      @blur="driverCurrentRateExpiredDATE = $root.parseDate(driverCurrentRateExpiredDATEFormatted)"
                      :label="$t('User.Fields.driverCurrentRateExpiredDATE')"
                      hide-details
                      prepend-icon="event"
                      clearable
                      style="max-width: 250px;"
                      @click:clear="onExpiredDateClear"
                      v-on="on"
                      outlined dense></v-text-field>
                    &lt;!&ndash;hint="MM/DD/YYYY" &ndash;&gt;
                  </template>
                  <v-date-picker
                    @change="onExpiredDateChange"
                    v-model="driverCurrentRateExpiredDATE"
                    no-title
                    @input="driverCurrentRateExpiredDATEMenu = false"></v-date-picker>
                </v-menu>
              </td>-->
              <td v-if="!readonly">
                <v-btn small icon @click="editRate(rate.id, rate)"><v-icon small>mdi-pen</v-icon></v-btn>
                <v-btn small icon @click="removeRate(rate.id)"><v-icon small color="error">mdi-delete</v-icon></v-btn>
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>

      <v-layout wrap class="mt-2" v-if="!readonly">
        <v-flex xs12 class="text-right">
          <!--<SelectUser role="Driver" :label="$root.t('User.CopyRatesFromDriver')" @change="copyFromUserChanged"></SelectUser>-->
          <v-btn v-if="!readonly && userId > 0" class="mt-1 ml-1 primary"
                 @click="setDefaultRates()">{{$t('User.SetDefaultRates')}}</v-btn>
          <!--<v-btn v-if="!readonly" class="mt-1 ml-1"
                 @click="applyRatesToAllDrivers"><v-icon left>mdi-account-multiple-check</v-icon>{{$t('User.ApplyRatesToAllDrivers')}}</v-btn>
          <v-btn color="success" class="ml-1 mt-1"
                 v-if="!readonly"
                 @click="onIncreaseRates()"><v-icon left>mdi-unfold-more-horizontal</v-icon>{{$t('CreateUser.ChangeRates')}}</v-btn>-->
          <v-btn color="success" class="ml-1 mt-1"
                 v-if="!readonly"
                 @click="createNewRate()"><v-icon left>mdi-plus</v-icon>{{$t('CreateUser.AddRate')}}</v-btn>
        </v-flex>
        <!--<v-flex xs12 v-if="userId > 0">
          <v-btn width="100%" class="mb-5 mt-2" color="primary"
                 v-if="!readonly"
                 :disabled="!isChanged"
                 :loading="savingLoading"
                 @click="save()"><v-icon left>mdi-content-save</v-icon>{{$t('Save')}}</v-btn>
        </v-flex>-->
      </v-layout>

    </v-card>

    <v-dialog v-model="modal" width="450" v-if="!readonly">
      <v-card>
        <v-card-title>
          {{editRateId ? $t('CreateUser.UpdateRate') : $t('CreateUser.NewRate')}}
          <v-spacer></v-spacer>
          <v-btn icon @click="modal=false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-layout wrap style="text-align: left;">
            <v-flex xs12>
              <v-combobox
                :disabled="editRateId"
                v-model="fields.name"
                :items="ratesNames"
                :rules="[$root.rules.required]"
                :label="$t('User.RateName')"
                outlined dense hide-details/>
            </v-flex>
            <v-flex xs12>
              <v-select
                class="mt-2 mb-2"
                v-model="fields.type"
                :items="types"
                :rules="[$root.rules.required]"
                :label="$t('User.RateType')"
                dense outlined hide-details
              />
            </v-flex>
            <v-flex xs6 v-if="fields.type !== 20">
              <v-text-field
                prefix="$"
                :step="0.1"
                @mousewheel.prevent=""
                type="number"
                class="mr-2 mb-2"
                :rules="$root.numberRule"
                v-model="fields.soloRate"
                :label="$t('User.SoloRate')"
                outlined dense hide-details persistent-hint
              />
            </v-flex>
            <v-flex xs6 v-if="fields.type !== 20">
              <v-text-field
                prefix="$"
                :step="0.1"
                @mousewheel.prevent=""
                type="number"
                class="mb-1"
                :rules="$root.numberRule"
                v-model="fields.teamRate"
                :label="$t('User.TeamRate')"
                outlined dense hide-details persistent-hint
              />
            </v-flex>
            <v-flex xs6 v-if="fields.type !== 10">
              <v-text-field
                prefix="$"
                :step="0.1"
                @mousewheel.prevent=""
                type="number"
                class="mr-2 mb-2"
                :rules="$root.numberRule"
                v-model="fields.soloRateFixed"
                :label="$t('User.SoloRateFixed')"
                outlined dense hide-details persistent-hint
              />
            </v-flex>
            <v-flex xs6 v-if="fields.type !== 10">
              <v-text-field
                data-cy="codriverRateValue"
                prefix="$"
                :step="0.1"
                @mousewheel.prevent=""
                type="number"
                class="mb-1"
                :rules="$root.numberRule"
                v-model="fields.teamRateFixed"
                :label="$t('User.TeamRateFixed')"
                outlined dense hide-details persistent-hint
              />
            </v-flex>
            <v-flex xs12 v-if="fields.type === 30 || fields.type === 40 || fields.type === 50">
              <v-text-field
                prefix="mi"
                :step="1"
                @mousewheel.prevent=""
                type="number"
                class="mb-1"
                :rules="$root.numberRule"
                v-model="fields.minMiles"
                :label="$t('User.RateMinMiles')"
                outlined dense hide-details persistent-hint
              />
            </v-flex>
            <v-flex xs12>
              <v-textarea
                class="mb-1"
                v-model="fields.note"
                :label="$t('User.RateNote')"
                outlined dense hide-details
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="!editRateId" @click="addRate" color="primary" class="mb-1" :disabled="!fields.type">{{$t('CreateUser.AddRate')}}</v-btn>
          <!--<v-btn v-if="updatingEnabled && editRateMode" data-cy="driverUpdateAllRatesBtn" @click="updateAllRate" color="primary" class="mb-1 ml-1" :loading="isFieldLoading('driverRates')">{{$t('CreateUser.UpdateAllRates')}}</v-btn>-->
          <!--<v-btn v-if="editRateId" @click="updateRate" color="secondary" class="mb-1 ml-1">{{$t('User.DriverSetAsCurrentRate')}}</v-btn>-->
          <v-btn v-if="editRateId" @click="updateRate" color="primary" class="mb-1 ml-1">{{$t('CreateUser.UpdateRate')}}</v-btn>
          <v-btn data-cy="rateCancelBtn" text class="mb-1 ml-1" @click="cancelEditRate">{{$t('Cancel')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'DriverRates',
  components: { },
  props: ['readonly', 'userId'],
  mixins: [],
  data: () => ({
    types: [],
    typeNames: {
      '10': 'TYPE_BY_MILES',
      '20': 'TYPE_FIXED',
      '30': 'TYPE_FIXED_PLUS_MILES',
      '40': 'TYPE_FIXED_PLUS_MILES_MORE_THAN',
      '50': 'TYPE_FIXED_OR_BY_MILES_MORE_THAN'
    },
    fields: {
      name: '',
      type: 10,
      soloRate: 0,
      teamRate: 0,
      soloRateFixed: 0,
      teamRateFixed: 0,
      minMiles: 0,
      note: ''
    },
    savingLoading: false,
    driverCurrentSoloRate: 0.0,
    driverCurrentTeamRate: 0.0,
    driverPercentRate: 0,
    driverTeamPercentRate: 0,
    driverDetentionRate: 0,
    editRateId: 0,
    increaseType: 'increase',
    modal: false,
    increaseModal: false,
    fixedRateMode: false,
    isChanged: false,
    driverRatesHeaders: [
      { text: 'Type', value: 'type', divider: false },
      { text: '$ Solo/Team', value: 'rate', divider: false },
      { text: 'Actions', value: 'actions', divider: false }
    ],
    driverRates: [],
    ratesNames: ['Default Miles', 'All Miles', 'Zip To Zip Miles', 'Local Miles', 'Empty Miles', 'Return Miles', 'Pickup Miles', 'Delivery Miles'],
    driverCurrentRateExpiredDATEMenu: false,
    driverCurrentRateExpiredDATEFormatted: '',
    driverCurrentRateExpiredDATE: '',
    isCurrentRateActive: false,
    isDriverWorkOnPercent: false
  }),
  mounted () {
    this.types = [
      { text: this.$root.t('User.RateTypes.TYPE_BY_MILES'), value: 10 },
      { text: this.$root.t('User.RateTypes.TYPE_FIXED'), value: 20 },
      { text: this.$root.t('User.RateTypes.TYPE_FIXED_PLUS_MILES'), value: 30 },
      { text: this.$root.t('User.RateTypes.TYPE_FIXED_PLUS_MILES_MORE_THAN'), value: 40 },
      { text: this.$root.t('User.RateTypes.TYPE_FIXED_OR_BY_MILES_MORE_THAN'), value: 50 }
    ];
    this.fetchData();
  },
  watch: {
  },
  methods: {
    onExpiredDateClear () {
      this.driverCurrentRateExpiredDATEFormatted = '';
      this.driverCurrentRateExpiredDATE = '';
    },
    onExpiredDateChange () {
      this.driverCurrentRateExpiredDATEFormatted = this.$root.formatDate(this.driverCurrentRateExpiredDATE);
    },
    async setDefaultRates () {
      /*let response = await this.$root.getGlobalSettingsField('driverRatesDefaultsJSON');
      if (response.status === 'ok') {
        if (response.result) {
          this.driverRates = JSON.parse(response.result);
        }
      }
      response = await this.$root.getGlobalSettingsField('driverCurrentSoloRate');
      if (response.status === 'ok') {
        this.driverCurrentSoloRate = parseFloat(response.result);
      }
      response = await this.$root.getGlobalSettingsField('driverCurrentTeamRate');
      if (response.status === 'ok') {
        this.driverCurrentTeamRate = parseFloat(response.result);
      }
      this.isChanged = true;*/

      this.$dialog.confirm(this.$i18n.t('PleaseConfirmYourChoice')).then(dialog => {
        this.$root.setDriverDefaultsRates(this.userId).then(() => {
          this.fetchData();
        });
      });
    },
    /*onIncreaseRates () {
      this.increaseModal = true;
    },*/
    /*applyRatesChanges () {
      let i = 0;
      for (i = 0; i < this.driverRates.length; i++) {
        if (this.driverRates[i].fixed) continue;
        if (this.increaseType === 'increase') {
          this.driverRates[i].soloRate = parseFloat(parseFloat(parseFloat(this.driverRates[i].soloRate) + parseFloat(this.soloRateIncreaseValue)).toFixed(2));
          this.driverRates[i].teamRate = parseFloat(parseFloat(parseFloat(this.driverRates[i].teamRate) + parseFloat(this.teamRateIncreaseValue)).toFixed(2));
        } else {
          this.driverRates[i].soloRate = parseFloat(parseFloat(parseFloat(this.driverRates[i].soloRate) - parseFloat(this.soloRateIncreaseValue)).toFixed(2));
          this.driverRates[i].teamRate = parseFloat(parseFloat(parseFloat(this.driverRates[i].teamRate) - parseFloat(this.teamRateIncreaseValue)).toFixed(2));
        }
        this.isChanged = true;
      }
      this.increaseModal = false;
    },*/
    /*copyFromUserChanged (value) {
      this.$root.loadUserFields(value).then((response) => {
        if (response.status === 'ok') {
          if (response.fields.hasOwnProperty('driverCurrentSoloRate')) {
            this.driverCurrentSoloRate = parseFloat(response.fields['driverCurrentSoloRate']);
          }
          if (response.fields.hasOwnProperty('driverCurrentTeamRate')) {
            this.driverCurrentTeamRate = parseFloat(response.fields['driverCurrentTeamRate']);
          }
          if (response.fields.hasOwnProperty('driverRates')) {
            this.driverRates = JSON.parse(response.fields.driverRatesJSON);
            if (!this.driverRates) {
              this.driverRates = [];
            }
            this.isChanged = true;
          }
        }
      });
    },*/
    onSetCurrentRate (val, item) {
      if (val) {
        this.driverRates.forEach(el => {
          el.isCurrent = item.id === el.id;
        });
      } else {
        this.driverRates.forEach(el => {
          if (item.id === el.id) el.isCurrent = false;
        });
      }
      this.$root.setDriverRateAsCurrent(this.userId, item.id, val).then(response => {
        this.$root.toast(this.$i18n.t('ChangesAreSaved'), { color: 'success' });
        this.fetchData();
        this.$emit('fetch');
      });
    },
    /*applyRatesToAllDrivers () {
      this.$dialog.confirm(this.$root.t('User.ApplyRatesToAllDriversConfirmation')).then(dialog => {
        this.$root.applyRatesToAllDrivers(this.driverRates, this.driverCurrentSoloRate, this.driverCurrentTeamRate).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$root.t('User.ApplyRatesToAllDriversSuccess'), { color: 'success' });
          } else {
            this.$root.toast(response.msg, { color: 'error' });
          }
        });
      }).catch(() => {
      });
    },
    applyOneRateToAllDrivers (rateName, soloRateValue, teamRateValue) {
      this.$dialog.confirm(this.$root.t('User.ApplyOneRateToAllDriversConfirmation')).then(dialog => {
        this.$root.applyOneRateToAllDrivers(rateName, soloRateValue, teamRateValue).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$root.t('User.ApplyOneRateToAllDriversSuccess'), { color: 'success' });
          } else {
            this.$root.toast(response.msg, { color: 'error' });
          }
        });
      }).catch(() => {
      });
    },*/
    /*save () {
      this.$dialog.confirm(this.$root.t('SaveDriverRatesConfirmationMsg')).then(dialog => {
        this.savingLoading = true;
        this.$root.updateUserFields({
          driverRatesJSON: JSON.stringify(this.driverRates),
          driverCurrentSoloRate: parseFloat(this.driverCurrentSoloRate),
          driverCurrentTeamRate: parseFloat(this.driverCurrentTeamRate),
          fixed: this.fixedRateMode
        }, this.userId).then((response) => {
          let successCount = 0;
          for (let k in response) {
            if (response[k].status && response[k].status === 'ok' && response[k].fieldName === 'driverRatesJSON') {
              successCount++;
              break;
            }
          }
          if (successCount > 0) {
            this.isChanged = false;
            this.$root.toast(this.$i18n.t('ChangesAreSaved'), { color: 'success' });
          } else {
            this.$root.toast(this.$i18n.t('FieldAccessError'), { color: 'error' });
          }

          this.savingLoading = false;
        });
      }).catch(() => {
      });
    },*/
    createNewRate () {
      this.fields = {
        name: '',
        type: 10,
        soloRate: 0,
        teamRate: 0,
        soloRateFixed: 0,
        teamRateFixed: 0,
        minMiles: 0,
        note: '',
        isCurrent: false
      };
      this.editRateId = 0;
      this.modal = true;
    },
    addRate () {
      this.$root.addDriverRate(this.userId, this.fields).then(() => {
        this.modal = false;
        this.fetchData();
      });
    },
    editRate (id, rateData) {
      this.fields.name = rateData.name;
      this.fields.type = rateData.type;
      this.fields.soloRate = rateData.soloRate;
      this.fields.teamRate = rateData.teamRate;
      this.fields.soloRateFixed = rateData.soloRateFixed;
      this.fields.teamRateFixed = rateData.teamRateFixed;
      this.fields.minMiles = rateData.minMiles;
      this.fields.note = rateData.note;
      this.editRateId = id;
      this.modal = true;
    },
    cancelEditRate () {
      this.editRateId = 0;
      this.modal = false;
    },
    updateRate () {
      return new Promise((resolve) => {
        let fields = Object.assign({}, this.fields);
        fields.isCurrent = fields.isCurrent ? 1 : 0;
        this.$root.updateDriverRate(this.editRateId, this.userId, fields).then(() => {
          this.editRateId = 0;
          this.modal = false;
          this.fetchData().then(() => {
            resolve();
          });
        });
      });
    },
    onDriverWorkOnPercentChange() {
      this.$root.updateUserFields({
        driverIsWorkOnPercentSWITCH: this.isDriverWorkOnPercent ? 1 : 0
      }, this.userId).then((response) => {
        if (response.status === 'ok') {
          this.$root.toast(this.$i18n.t('ChangesAreSaved'), { color: 'success' });
          this.fetchData();
          this.$emit('fetch');
        }
      });
    },
    applyCustomCurrentRate () {
      let fields = {};
      if (this.isDriverWorkOnPercent) {
        fields = {
          driverPercentRate: this.driverPercentRate,
          driverTeamPercentRate: this.driverTeamPercentRate
        };
      } else {
        fields = {
          driverCurrentSoloRate: this.driverCurrentSoloRate,
          driverCurrentTeamRate: this.driverCurrentTeamRate,
          driverCurrentRateExpiredDATE: this.driverCurrentRateExpiredDATE
        };
      }
      this.$root.updateUserFields(fields, this.userId).then((response) => {
        if (response.status === 'ok') {
          this.$root.toast(this.$i18n.t('ChangesAreSaved'), { color: 'success' });
          this.fetchData();
          this.$emit('fetch');
        }
      });
    },
    applyDetentionRate () {
      this.$root.updateUserFields({
        driverDetentionRate: this.driverDetentionRate
      }, this.userId).then((response) => {
        if (response.status === 'ok') {
          this.$root.toast(this.$i18n.t('ChangesAreSaved'), { color: 'success' });
          this.fetchData();
          this.$emit('fetch');
        }
      });
    },
    clearCustomCurrentRate () {
      this.$root.clearDriverRateAsCurrent(this.userId);
      this.$root.updateUserFields({
        driverCurrentSoloRate: 0,
        driverCurrentTeamRate: 0,
        driverCurrentRateExpiredDATE: ''
      }, this.userId).then((response) => {
        if (response.status === 'ok') {
          this.$root.toast(this.$i18n.t('ChangesAreSaved'), { color: 'success' });
          this.isCurrentRateActive = false;
          this.fetchData();
          this.$emit('fetch');
        }
      });
    },
    /*// TODO make this function to update selected rate for all drivers
    updateAllRate () {
      this.driverRates.forEach((element) => {
        element.soloRate = parseFloat(this.soloRateValue);
        element.teamRate = parseFloat(this.teamRateValue);
      });
      this.$emit('changed', this.driverRates);
      this.rateType = '';
      this.fixedRateMode = false;
      this.soloRateValue = 0;
      this.teamRateValue = 0;
      this.editRateMode = false;
    },*/
    removeRate (id) {
      this.$root.deleteDriverRate(id).then(() => {
        this.fetchData();
      });
    },
    fetchData () {
      return new Promise((resolve) => {
        this.$root.getDriverRatesList(this.userId).then(response => {
          if (response.status === 'ok') {
            this.$set(this, 'driverRates', []);
            for (let i = 0; i < response.results.length; i++) {
              let item = Object.assign({}, response.results[i]);
              item.isCurrent = parseInt(item.isCurrent) === 1;
              this.driverRates.push(item);
            }

            this.$set(this, 'driverPercentRate', response.driverPercentRate > 0 ? response.driverPercentRate : '');
            this.$set(this, 'driverTeamPercentRate', response.driverTeamPercentRate > 0 ? response.driverTeamPercentRate : '');
            this.$set(this, 'driverDetentionRate', response.driverDetentionRate > 0 ? response.driverDetentionRate : '');

            this.isDriverWorkOnPercent = response.driverIsWorkOnPercentSWITCH === 1;

            this.$set(this, 'driverCurrentRateExpiredDATE', '');
            this.$set(this, 'driverCurrentRateExpiredDATEFormatted', '');
            if (response.driverCurrentRateExpiredDATE) {
              this.$set(this, 'driverCurrentRateExpiredDATE', response.driverCurrentRateExpiredDATE);
              this.$set(this, 'driverCurrentRateExpiredDATEFormatted', this.$root.formatDate(response.driverCurrentRateExpiredDATE));
            }
            this.$set(this, 'driverCurrentSoloRate', response.driverCurrentSoloRate > 0 ? response.driverCurrentSoloRate : '');
            this.$set(this, 'driverCurrentTeamRate', response.driverCurrentTeamRate > 0 ? response.driverCurrentTeamRate : '');

            if (this.driverCurrentRateExpiredDATE || this.driverCurrentSoloRate > 0 || this.driverCurrentTeamRate > 0) {
              this.isCurrentRateActive = true;
            }
            resolve();
          }
        });
      });
    }
  }
};
</script>
