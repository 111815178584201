<template>
  <div class="text-center">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        data-cy="dispatcherTitle"
        class="mb-2"
        :disabled="isFieldReadOnly('dispatcherTitle')"
        v-if="!isFieldHidden('dispatcherTitle')"
        v-model="fields.dispatcherTitle"
        :label="$t('User.Fields.dispatcherTitle')"
        type="text" outlined hide-details dense></v-text-field>

      <v-layout wrap>
        <v-flex xs6>
          <v-menu
            ref="menu"
            v-if="!isFieldHidden('dispatcherWorkHoursStart')"
            v-model="menuStart"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="fields.dispatcherWorkHoursStart"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="mr-2 mb-2"
                :disabled="isFieldReadOnly('dispatcherWorkHoursStart')"
                data-cy="dispatcherWorkHoursStart"
                v-model="fields.dispatcherWorkHoursStart"
                :label="$t('User.Fields.dispatcherWorkHoursStart')"
                prepend-icon="fa-user-clock"
                readonly
                v-on="on"
                outlined hide-details dense
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="menuStart"
              v-model="fields.dispatcherWorkHoursStart"
              @click:minute="$refs.menu.save(fields.dispatcherWorkHoursStart)"
            ></v-time-picker>
          </v-menu>
        </v-flex>

        <v-flex xs6>
          <v-menu
            ref="menu2"
            v-if="!isFieldHidden('dispatcherWorkHoursEnd')"
            v-model="menuEnd"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="fields.dispatcherWorkHoursEnd"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                data-cy="dispatcherWorkHoursEnd"
                class="mb-2"
                :disabled="isFieldReadOnly('dispatcherWorkHoursEnd')"
                v-model="fields.dispatcherWorkHoursEnd"
                :label="$t('User.Fields.dispatcherWorkHoursEnd')"
                readonly
                v-on="on"
                outlined hide-details dense
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="menuEnd"
              v-model="fields.dispatcherWorkHoursEnd"
              @click:minute="$refs.menu2.save(fields.dispatcherWorkHoursEnd)"
            ></v-time-picker>
          </v-menu>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex xs4>
          <v-text-field class="mr-2 mb-2"
                        data-cy="dispatcherPaymentPercent"
                        :disabled="isFieldReadOnly('dispatcherPaymentPercent')"
                        v-if="!isFieldHidden('dispatcherPaymentPercent')"
                        v-model="fields.dispatcherPaymentPercent"
                        :label="$t('User.Fields.dispatcherPaymentPercent')"
                        prepend-icon="fa-percent"
                        suffix="%"
                        type="number" step="0.1" @mousewheel.prevent="" outlined hide-details dense></v-text-field>
        </v-flex>
        <v-flex xs4>
          <v-text-field class="mr-2 mb-2"
                        data-cy="dispatcherPaymentPercentWithOO"
                        :disabled="isFieldReadOnly('dispatcherPaymentPercentWithOO')"
                        v-if="!isFieldHidden('dispatcherPaymentPercentWithOO')"
                        v-model="fields.dispatcherPaymentPercentWithOO"
                        :label="$t('User.Fields.dispatcherPaymentPercentWithOO')"
                        suffix="%"
                        type="number" step="0.1" @mousewheel.prevent="" outlined hide-details dense></v-text-field>
        </v-flex>
        <v-flex xs4>
          <v-text-field
            data-cy="dispatcherPaymentPercentFromBonus"
            :disabled="isFieldReadOnly('dispatcherPaymentPercentFromBonus')"
            v-if="!isFieldHidden('dispatcherPaymentPercentFromBonus')"
            v-model="fields.dispatcherPaymentPercentFromBonus"
            :label="$t('User.Fields.dispatcherPaymentPercentFromBonus')"
            suffix="%"
            type="number" step="0.1" @mousewheel.prevent="" outlined hide-details dense></v-text-field>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex xs4>
          <v-text-field class="mr-2 mb-2"
                        data-cy="dispatcherPaymentPercentForPartialLoad"
                        :disabled="isFieldReadOnly('dispatcherPaymentPercentForPartialLoad')"
                        v-if="!isFieldHidden('dispatcherPaymentPercentForPartialLoad')"
                        v-model="fields.dispatcherPaymentPercentForPartialLoad"
                        :label="$t('User.Fields.dispatcherPaymentPercentForPartialLoad')"
                        prepend-icon="fa-percent"
                        suffix="%"
                        type="number" step="0.1" @mousewheel.prevent="" outlined hide-details dense></v-text-field>
        </v-flex>
        <v-flex xs4>
          <v-text-field class="mr-2 mb-2"
                        data-cy="dispatcherPaymentPercentForLTLLoad"
                        :disabled="isFieldReadOnly('dispatcherPaymentPercentForLTLLoad')"
                        v-if="!isFieldHidden('dispatcherPaymentPercentForLTLLoad')"
                        v-model="fields.dispatcherPaymentPercentForLTLLoad"
                        :label="$t('User.Fields.dispatcherPaymentPercentForLTLLoad')"
                        suffix="%"
                        type="number" step="0.1" @mousewheel.prevent="" outlined hide-details dense></v-text-field>
        </v-flex>
        <v-flex xs4>
          <v-text-field class="mr-0"
                        data-cy="dispatcherPaymentPercentForReturnLoad"
                        :disabled="isFieldReadOnly('dispatcherPaymentPercentForReturnLoad')"
                        v-if="!isFieldHidden('dispatcherPaymentPercentForReturnLoad')"
                        v-model="fields.dispatcherPaymentPercentForReturnLoad"
                        :label="$t('User.Fields.dispatcherPaymentPercentForReturnLoad')"
                        suffix="%"
                        type="number" step="0.1" @mousewheel.prevent="" outlined hide-details dense></v-text-field>
        </v-flex>
      </v-layout>

      <v-text-field
        data-cy="dispatcherEmail"
        class="mb-2"
        :disabled="isFieldReadOnly('dispatcherEmail')"
        v-if="!isFieldHidden('dispatcherEmail')"
        v-model="fields.dispatcherEmail"
        :label="$t('User.Fields.dispatcherEmail')"
        :rules="!requiredEnabled?[]:$root.emailRules"
        :required="requiredEnabled?true:null"
        prepend-icon="fa-envelope"
        type="text" outlined hide-details dense></v-text-field>

      <!-- UNIVERSAL USER FIELDS -->

      <v-text-field
        data-cy="phoneNumber"
        class="mb-2"
        :disabled="isFieldReadOnly('phoneNumber')"
        v-if="!isFieldHidden('phoneNumber')"
        v-model="fields.phoneNumber"
        :label="$t('User.Fields.phoneNumber')"
        prepend-icon="fa-phone"
        type="text" outlined hide-details dense>
      </v-text-field>

      <v-text-field
        data-cy="SSN"
        class="mb-2"
        :disabled="isFieldReadOnly('SSN')"
        v-if="!isFieldHidden('SSN')"
        v-model="fields.SSN"
        :label="$t('User.Fields.SSN')"
        prepend-icon="fa-id-card"
        :append-icon="updatingEnabled ? (isFieldEncrypted('SSN') ? 'mdi-eye-off' : 'mdi-eye') : null"
        @click:append="decryptField('SSN',$route.params.id)"
        :type="isFieldEncrypted('SSN') ? 'password' : 'text'"
        outlined hide-details dense>
      </v-text-field>
      <v-textarea
        data-cy="dispatcherEmailSignature"
        class="mb-2"
        :disabled="isFieldReadOnly('dispatcherEmailSignature')"
        v-if="!isFieldHidden('dispatcherEmailSignature')"
        v-model="fields.dispatcherEmailSignature"
        :label="$t('User.Fields.dispatcherEmailSignature')"
        prepend-icon="fa-signature" outlined hide-details>
      </v-textarea>

      <v-textarea
        data-cy="paymentDetails"
        class="mb-2"
        :disabled="isFieldReadOnly('paymentDetails')"
        v-if="!isFieldHidden('paymentDetails')"
        v-model="fields.paymentDetails"
        :label="$t('User.Fields.paymentDetails')"
        prepend-icon="fa-file-invoice-dollar" outlined hide-details></v-textarea>
      <v-text-field
        data-cy="dispatcherEmailSubjectTemplate"
        :disabled="isFieldReadOnly('dispatcherEmailSubjectTemplate')"
        v-if="!isFieldHidden('dispatcherEmailSubjectTemplate')"
        v-model="fields.dispatcherEmailSubjectTemplate"
        :label="$t('User.Fields.dispatcherEmailSubjectTemplate')"
        prepend-icon="mdi-text-subject"
        class="mb-2"
        hint="{Departure} {Destination} {OptionalText} {MilesOut} {PickupInTime} {TotalRate} {TruckType} {TruckLength} {TruckDimensions} {TruckPropsList} {LiftGate} {PalletJack} {AirRide} {ICCBars} {Reefer}"
        type="text" outlined hide-details dense>
      </v-text-field>
      <v-input
        data-cy="dispatcherEmailTemplate"
        :disabled="isFieldReadOnly('dispatcherEmailTemplate')"
        v-if="!isFieldHidden('dispatcherEmailTemplate')"
        prepend-icon="fa-envelope-open-text" outlined hide-details
        messages="{Departure} {Destination} {OptionalText} {MilesOut} {PickupInTime} {TotalRate} {TruckType} {TruckLength} {TruckDimensions} {TruckPropsList} {LiftGate} {PalletJack} {AirRide} {ICCBars} {Reefer}">
        <template v-slot:default>
          <div style="background-color:#fff;color: #000;">
            <VueEditor v-model="fields.dispatcherEmailTemplate"
                     :editorToolbar="$root.customToolbar"
                     :disabled="isFieldReadOnly('dispatcherEmailTemplate')">
            </VueEditor>
          </div>
        </template>
      </v-input>

      <SelectUser
        class="mt-2"
        :multiple="true"
        :disabled="isFieldReadOnly('dispatcherassistants')"
        v-if="!isFieldHidden('dispatcherassistants')"
        :label="$root.t('User.Fields.assistant')"
        role="Assistant"
        :value="assistants"
        :icon-size="24"
        @change="assistantsChanged"/>

      <v-select
        class="mt-2"
        :disabled="isFieldReadOnly('dispatcherhiddenTrucks')"
        v-if="!isFieldHidden('dispatcherhiddenTrucks')"
        :label="$root.t('User.Fields.hiddenTrucks')"
        v-model="hiddenTrucks"
        item-text="name"
        item-value="id"
        prepend-icon="mdi-truck"
        multiple dense outlined hide-details
        @change="onHiddenTrucksChanged"
        :items="trucksList"
      >
        <template v-slot:selection="{ item }">
          <v-chip class="ma-1">
            <span>{{ item.name }}</span>
          </v-chip>
        </template>
      </v-select>

      <v-select
        class="mt-2"
        :disabled="isFieldReadOnly('dispatcherOwnerOperatorBidAutoSend')"
        v-if="!isFieldHidden('dispatcherOwnerOperatorBidAutoSend')"
        :label="$root.t('User.Fields.dispatcherOwnerOperatorBidAutoSend')"
        v-model="dispatcherOwnerOperatorBidAutoSend"
        item-text="fullName"
        item-value="id"
        prepend-icon="mdi-car-multiple"
        multiple dense outlined
        @change="ondOwnerOperatorBidAutoSendChanged"
        :items="driverOOList"
      >
        <template v-slot:selection="{ item }">
          <v-chip class="ma-1">
            <span>{{ item.fullName }}</span>
          </v-chip>
        </template>
      </v-select>
      <br/>

      <!--<FileUpload
        :disabled="isFieldReadOnly('dispatcherCompanyLogoUPLOAD')"
        v-if="!isFieldHidden('dispatcherCompanyLogoUPLOAD')"
        :image="true"
        :avatar="false"
        height="400px"
        :label="$t('User.Fields.dispatcherCompanyLogoUPLOAD')"
        :addNewBtnLabel="$t('User.Fields.dispatcherCompanyLogoUPLOAD')"
        :url="dispatcherCompanyLogoUPLOADUrl"
        :field="fields.dispatcherCompanyLogoUPLOAD"
        @change="fileUploaded($event, 'dispatcherCompanyLogoUPLOAD', 'dispatcherCompanyLogo')"
        @remove="fields.dispatcherCompanyLogoUPLOAD='';dispatcherCompanyLogoUPLOADUrl=''"></FileUpload>-->

      <v-btn data-cy="backBtn" v-if="!updatingEnabled" style='width:100%;margin-top: 10px;' text @click="$emit('back')">{{$t('Back')}}</v-btn>
      <v-btn v-if="isSaveAllowed && updatingEnabled" style='width:100%;margin-top: 10px;'
             :loading="isFieldLoading('saveBtn')"
             data-cy="userNextBtn"
             v-confirm="{ok: dialog => saveDispatcherFields(), cancel: () => {}, message: $t('CreateUser.SaveSettingsConfirmMsg')}"
             color="primary"><v-icon left>fa-save</v-icon>{{$t('Save')}}</v-btn>
    </v-form>
  </div>
</template>

<script>
import FileUpload from '../../components/FileUpload.vue';
import MultiFileUpload from '../../components/MultiFileUpload.vue';
import fieldLoadingMixin from '../../mixins/fieldLoadingMixin';
import fieldAccessRightMixin from '../../mixins/fieldAccessRightsMixin';
import userFormsCommonMixin from '../../mixins/userFormsCommonMixin';
import SelectUser from '../../components/SelectUser.vue';
import { VueEditor } from 'vue2-editor';

export default {
  props: ['updatingEnabled', 'userId', 'isOwnSettings', 'isNew'],
  components: { FileUpload, MultiFileUpload, VueEditor, SelectUser },
  mixins: [fieldLoadingMixin, fieldAccessRightMixin, userFormsCommonMixin],
  data: () => ({
    menuStart: false,
    menuEnd: false,
    valid: true,
    requiredEnabled: true,
    fields: {
      paymentDetails: '',
      paymentDetailsUPLOAD: '',
      agreementUPLOAD: '',
      passportUPLOAD: '',
      phoneNumber: '',
      SSN: '',
      resumeUPLOAD: '',
      W2FormUPLOAD: '',
      W9FormUPLOAD: '',
      dispatcherTitle: '',
      dispatcherEmail: '',
      dispatcherEmailTemplate: '',
      dispatcherEmailSubjectTemplate: '',
      dispatcherEmailSignature: '',
      dispatcherCompanyLogoUPLOAD: '',
      dispatcherPaymentPercent: 0.0,
      dispatcherPaymentPercentWithOO: 0.0,
      dispatcherPaymentPercentFromBonus: 0.0,
      dispatcherPaymentPercentForPartialLoad: 0.0,
      dispatcherPaymentPercentForLTLLoad: 0.0,
      dispatcherPaymentPercentForReturnLoad: 0.0,
      dispatcherWorkHoursStart: '',
      dispatcherWorkHoursEnd: '',
      assistants: '[]',
      hiddenTrucks: '[]',
      dispatcherOwnerOperatorBidAutoSend: '[]'
    },
    dispatcherCompanyLogoUPLOADUrl: '',
    documentsTab: 0,
    assistants: [],
    hiddenTrucks: [],
    dispatcherOwnerOperatorBidAutoSend: [],
    trucksList: [],
    driverOOList: []
  }),
  computed: {
  },
  mounted () {
    if (!this.isNew) {
      this.fetchDataForSettingsModal();
    }

    this.$root.getTrucksList().then(response => {
      if (response.status === 'ok') {
        this.trucksList = response.result;
      }
    });

    this.$root.loadUsersList(this.$store.getters.roleDriverOwnerOperator, false, true).then(response => {
      if (response.status === 'ok') {
        this.driverOOList = response.result.data;
      }
    });
  },
  methods: {
    onHiddenTrucksChanged () {
      this.fields.hiddenTrucks = JSON.stringify(this.hiddenTrucks);
    },
    ondOwnerOperatorBidAutoSendChanged () {
      this.fields.dispatcherOwnerOperatorBidAutoSend = JSON.stringify(this.dispatcherOwnerOperatorBidAutoSend);
    },
    assistantsChanged (value) {
      this.assistants = value;
      this.fields.assistants = JSON.stringify(value);
    },
    saveDispatcherFields () {
      this.saveFields();
    },
    isValid () {
      return this.$refs.form.validate();
    },
    next () {
      if (!this.$refs.form.validate()) {
        this.$root.toast(this.$i18n.t('FormValidationFailed'), { color: 'error' });
        return;
      }
      this.$emit('next');
    },
    setFields (fields, userRole, rights = null) {
      let key = '';
      for (key in this.fields) {
        this.fields[key] = fields[key] !== null ? fields[key] : '';
        if (key === 'assistants' && this.fields[key]) {
          this.assistants = JSON.parse(this.fields[key]);
        }
        if (key === 'hiddenTrucks' && this.fields[key]) {
          this.hiddenTrucks = JSON.parse(this.fields[key]);
        }
        if (key === 'dispatcherOwnerOperatorBidAutoSend' && this.fields[key]) {
          this.dispatcherOwnerOperatorBidAutoSend = JSON.parse(this.fields[key]);
        }
      }
      this.loadImages();
      this.applyRights(this.isOwnSettings ? '' : userRole, this.isOwnSettings ? 'settings' : 'users', { deleteUsersDocuments: 0 });
    }
  }
};
</script>
