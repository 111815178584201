export default {
  namespaced: true,
  state: {
    started: 0,
    ended: 0,
    locations: [],
    childLocations: [],
    distanceCalculationLoading: false,
    truckLat: 0.0,
    truckLng: 0.0,
    locationsKey: 'locations',
    maxWaitingHoursUntilDetentionInSeconds: 2 * 60 * 60,
    detentionPrice: 0.0,
    trucksIds: [],
    lastLoadUpdated: ''
  },
  getters: {
    getLastLoadUpdated (state) {
      return state.lastLoadUpdated;
    },
    getLocationsKey (state) {
      return state.locationsKey;
    },
    getLocations (state) {
      return state.locations;
    },
    getChildLocations (state) {
      return state.childLocations;
    },
    getStarted (state) {
      return state.started;
    },
    getEnded (state) {
      return state.ended;
    },
    getTrucksIds (state) {
      return state.trucksIds;
    },
    getFirstPickupLocationIndex (state) {
      for (let i = 1; i < state.locations.length; i++) {
        if (state.locations[i].pickup) {
          return i;
        }
      }
      return 0;
    },
    getLastDeliveryLocationIndex (state) {
      for (let i = state.locations.length - 1; i >= 0; i--) {
        if (state.locations[i].delivery) {
          return i;
        }
      }
      return 0;
    },
    getLocation (state) {
      return index => {
        return state.locations[index];
      };
    },
    getLocationTruck (state) {
      return index => {
        return state.locations[index].truck;
      };
    },
    getDistanceCalculationLoading (state) {
      return state.distanceCalculationLoading;
    },
    getMaxWaitingHoursUntilDetentionInSeconds (state) {
      return state.maxWaitingHoursUntilDetentionInSeconds;
    },
    getDetentionPrice (state) {
      return state.detentionPrice;
    }
  },
  mutations: {
    updateLoadUpdated (state, value) {
      state.lastLoadUpdated = value;
    },
    updateLocationsKey (state) {
      state.locationsKey = 'locations' + new Date().getTime();
      // this.commit('setIsCurrentFormUpdated', true, { root: true });
    },
    setTruckLatLng (state, value) {
      state.truckLat = value.lat;
      state.truckLng = value.lng;
    },
    setDistanceCalculationLoading (state) {
      state.distanceCalculationLoading = true;
    },
    clearDistanceCalculationLoading (state) {
      state.distanceCalculationLoading = false;
    },
    updateTrucksIds (state) {
      let ret = {};
      for (let i = 0; i < state.locations.length; i++) {
        if (state.locations[i].truck > 0) {
          ret[state.locations[i].truck] = true;
        }
      }
      state.trucksIds = Object.keys(ret);
    }
  },
  actions: {
    set ({ state }, data) {
      state[data.field] = data.value;
    },
    setLocations ({ commit, state }, data) {
      state.locations = [];
      for (let i = 0; i < data.length; i++) {
        state.locations.push(data[i]);
      }
      commit('updateLocationsKey');
      commit('updateTrucksIds');
    },
    setChildLocations ({ commit, state }, data) {
      state.childLocations = data;
      commit('updateLocationsKey');
      commit('updateTrucksIds');
    },
    pushLocations ({ commit, state }, data) {
      state.locations.push(data);
      commit('updateLocationsKey');
      commit('updateTrucksIds');
    },
    removeReturnToHQLocations ({ commit, state }) {
      for (let i = 0; i < state.locations.length; i++) {
        if (state.locations[i].returnToHQ > 0) {
          state.locations.splice(i, 1);
        }
      }
      // state.locations.pop();
      commit('updateLocationsKey');
      commit('updateTrucksIds');
    },
    setLocationField ({ commit, state }, data) {
      state.locations[data.index][data.field] = data.value;
      if (!data.hasOwnProperty('dont_update_key')) {
        // commit('updateLocationsKey');
        commit('updateTrucksIds');
      }
    },
    setAppoinment ({ commit, state }, data) {
      if (data.value === '' && state.locations[data.index]['details'] !== null) {
        state.locations[data.index]['details'] = null;
      } else {
        state.locations[data.index]['details'] = data.value;
      }
      commit('updateLocationsKey');
      commit('updateTrucksIds');
    },
    statusChange ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        if (!state.locations[data.index][data.value]) {
          state.locations[data.index][data.value] = parseInt(new Date().getTime() / 1000);
        } else {
          state.locations[data.index][data.value] = 0;
        }
        // If location type is parking or exchange or return to HQ and status updated is `driverOnSiteCheck` then update `driverDoneCheck` too
        if (data.value === 'driverOnSiteCheck' &&
          (state.locations[data.index].parking || state.locations[data.index].exchange || state.locations[data.index].returnToHQ)) {
          state.locations[data.index]['driverDoneCheck'] = state.locations[data.index][data.value];
        }
        // If userId available, set user which change the status
        if (data.userId) {
          if (data.value === 'dispatcherOnTheWayCheck') state.locations[data.index]['approvedByDispatcher'] = data.userId;
          if (data.value === 'driverOnTheWayCheck') state.locations[data.index]['startedByDriver'] = data.userId;
          if (data.value === 'driverOnSiteCheck') state.locations[data.index]['onSiteByDriver'] = data.userId;
          if (data.value === 'driverDoneCheck') state.locations[data.index]['doneByDriver'] = data.userId;
          if (data.value === 'driverNotDoneCheck') state.locations[data.index]['notDoneByDriver'] = data.userId;
        }
        // Do not update key on status change since load update time check timer added which refresh ui
        commit('updateLocationsKey');
        commit('updateTrucksIds');
        resolve();
      });
    },
    setLocationEndDateTime ({ commit, state }, data) {
      state.locations[data.index]['targetDatetimes'][1] = data.value;
      let lastDeliveryLocationsIndex = -1;
      for (let i = 1; i < state.locations.length; i++) {
        if (state.locations[i].delivery) {
          lastDeliveryLocationsIndex = i;
        }
      }
      // Set vaue as load ended if last location
      if (data.index === lastDeliveryLocationsIndex) {
        state.ended = data.value;
      }
      commit('updateLocationsKey');
      commit('updateTrucksIds');
    },
    setLocationDateTime ({ commit, state }, data) {
      state.locations[data.index]['targetDatetimes'][0] = data.value;
      let firstPickupLocationsIndex = -1;
      for (let i = 1; i < state.locations.length; i++) {
        if (state.locations[i].pickup) {
          firstPickupLocationsIndex = i;
          break;
        }
      }
      // Set value as load started if first pickup location
      if (data.index === firstPickupLocationsIndex) {
        state.started = data.value;
      }
      commit('updateLocationsKey');
      commit('updateTrucksIds');
    },
    setStarted ({ state, getters, commit }, value) {
      const firstPickupIndex = getters['getFirstPickupLocationIndex'];
      if (state.locations[firstPickupIndex]) {
        state.locations[firstPickupIndex].targetDatetimes[0] = value;
        commit('updateLocationsKey');
        commit('updateTrucksIds');
      }
      state.started = value;
    },
    setEnded ({ state, getters, commit }, value) {
      const lastDeliveryIndex = getters['getLastDeliveryLocationIndex'];
      if (state.locations[lastDeliveryIndex]) {
        state.locations[lastDeliveryIndex].targetDatetimes[0] = value;
        commit('updateLocationsKey');
        commit('updateTrucksIds');
      }
      state.ended = value;
    },
    setPickupLocation ({ commit, state }, data) {
      state.locations[data.index]['pickup'] = true;
      state.locations[data.index]['delivery'] = false;
      state.locations[data.index]['parking'] = false;
      state.locations[data.index]['exchange'] = false;
      commit('updateLocationsKey');
      commit('updateTrucksIds');
    },
    setDeliveryLocation ({ commit, state }, data) {
      state.locations[data.index]['pickup'] = false;
      state.locations[data.index]['delivery'] = true;
      state.locations[data.index]['parking'] = false;
      state.locations[data.index]['exchange'] = false;
      commit('updateLocationsKey');
      commit('updateTrucksIds');
    },
    setParkingLocation ({ commit, state }, data) {
      state.locations[data.index]['pickup'] = false;
      state.locations[data.index]['delivery'] = false;
      state.locations[data.index]['parking'] = true;
      state.locations[data.index]['exchange'] = false;
      commit('updateLocationsKey');
      commit('updateTrucksIds');
    },
    setExchangeLocation ({ commit, state }, data) {
      state.locations[data.index]['pickup'] = false;
      state.locations[data.index]['delivery'] = false;
      state.locations[data.index]['parking'] = false;
      state.locations[data.index]['exchange'] = true;
      commit('updateLocationsKey');
      commit('updateTrucksIds');
    },
    clearLocationType ({ commit, state }, data) {
      state.locations[data.index]['pickup'] = false;
      state.locations[data.index]['delivery'] = false;
      state.locations[data.index]['parking'] = false;
      state.locations[data.index]['exchange'] = false;
      state.locations[data.index]['returnToHQ'] = false;
      commit('updateLocationsKey');
      commit('updateTrucksIds');
    },
    populateLocationSettingsDown ({ commit, state }, data) {
      let i = 0;
      let j = 0;
      let rateObject = {};
      // By default copy all needed fields


      let fieldsToCopyArray = ['truck', 'driver', 'driverRateType', 'driverRateName', 'driverRate', 'driverRateFixed', 'driverPercentRate', 'driverRateMinMiles', 'coDriver', 'coDriverRateType', 'coDriverRateName', 'coDriverRate', 'coDriverRateFixed', 'coDriverPercentRate', 'coDriverRateMinMiles'];
      const baseLocation = state.locations[data.index];
      if (data.hasOwnProperty('fieldsToCopyArray') && data['fieldsToCopyArray'].length > 0) {
        fieldsToCopyArray = data['fieldsToCopyArray'];
      }
      for (i = data.index + 1; i < state.locations.length - 1; i++) {
        // Do not allow update location data if its already started
        if (state.locations[i].startedByDriverTS > 0) {
          // continue;
        }
        for (j = 0; j < fieldsToCopyArray.length; j++) {
          const fieldName = fieldsToCopyArray[j];
          if (baseLocation.hasOwnProperty(fieldName)) {
            state.locations[i][fieldName] = baseLocation[fieldName];
          }
        }
        // Set driver return to hq rate
        if (state.locations[i + 1].returnToHQ && state.locations[i + 1].returnToHQ === true && data.driverRates) {
          if (baseLocation.driver > 0) {
            rateObject = data.driverRates[baseLocation.driver].find((el) => { return el.type === 'Return Miles'; });
            if (rateObject && rateObject.hasOwnProperty('soloRate') && rateObject.hasOwnProperty('teamRate')) {
              state.locations[i].driverRateType = 'Return Miles';
              state.locations[i].driverRate = baseLocation.codriver ? rateObject.teamRate : rateObject.soloRate;
            }
          }
          if (baseLocation.codriver > 0) {
            rateObject = data.driverRates[baseLocation.codriver].find((el) => { return el.type === 'Return Miles'; });
            if (rateObject && rateObject.hasOwnProperty('soloRate') && rateObject.hasOwnProperty('teamRate')) {
              state.locations[i].codriverRateType = 'Return Miles';
              state.locations[i].codriverRate = baseLocation.driver ? rateObject.teamRate : rateObject.soloRate;
            }
          }
        }
      }
      commit('updateLocationsKey');
      commit('updateTrucksIds');
    },
    locationInserted ({ commit, state }, data) { // place, index) {
      return new Promise((resolve, reject) => {
        const index = data.index;
        const place = data.place;
        state.locations.splice(index + 1, 0, {
          lat: place.location.lat(),
          lng: place.location.lng(),
          name: place.name,
          pickup: false,
          delivery: false,
          returnToHQ: false,
          timestamp: 0,
          details: null,
          status: 0,
          canceled: 0,
          targetDatetimes: [parseInt(new Date().getTime() / 1000), parseInt(new Date().getTime() / 1000)]
        });
        commit('updateLocationsKey');
        commit('updateTrucksIds');
        resolve();
      });
    },
    locationDeleted ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        const index = data.index;
        state.locations.splice(index, 1);
        commit('updateLocationsKey');
        commit('updateTrucksIds');
        resolve();
      });
    },
    locationMovedUp ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        const index = data.index;
        state.locations.swap(index, index - 1);
        commit('updateLocationsKey');
        resolve();
      });
    },
    locationMovedDown ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        const index = data.index;
        state.locations.swap(index, index + 1);
        commit('updateLocationsKey');
        resolve();
      });
    },
    insertChildLocation ({ commit, state }, data) {
      state.childLocations.push(data.location);
    },
    removeChildLocation ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        let i = 0;
        let indexToDelete = 0;
        for (i = 0; i < state.childLocations.length; i++) {
          if (parseInt(state.childLocations[i].id) === parseInt(data.locationId)) {
            indexToDelete = i;
            break;
          }
        }
        state.childLocations.splice(indexToDelete, 1);
        resolve();
      });
    },
    setChildLocationStatus ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        let i = 0;
        let fields = {};
        for (i = 0; i < state.childLocations.length; i++) {
          if (state.childLocations[i].id === data.locationId) {
            if (!state.childLocations[i][data.value]) {
              state.childLocations[i][data.value] = parseInt(new Date().getTime() / 1000);
            } else {
              state.childLocations[i][data.value] = 0;
            }

            fields[data.value] = state.childLocations[i][data.value];

            // If userId available, set user which change the status
            if (data.userId) {
              if (data.value === 'approvedByDispatcherTS') {
                state.childLocations[i]['approvedByDispatcher'] = data.userId;
                fields['approvedByDispatcher'] = data.userId;
              }
              if (data.value === 'startedByDriverTS') {
                state.childLocations[i]['startedByDriver'] = data.userId;
                fields['startedByDriver'] = data.userId;
              }
              if (data.value === 'onSiteByDriverTS') {
                state.childLocations[i]['onSiteByDriver'] = data.userId;
                fields['onSiteByDriver'] = data.userId;
              }
              if (data.value === 'doneByDriverTS') {
                state.childLocations[i]['doneByDriver'] = data.userId;
                fields['doneByDriver'] = data.userId;
              }
              if (data.value === 'notDoneByDriverTS') {
                state.childLocations[i]['notDoneByDriver'] = data.userId;
                fields['notDoneByDriver'] = data.userId;
              }
            }

            // If location type is parking or exchange or return to HQ and status updated is `driverOnSiteCheck` then update `driverDoneCheck` too
            if (data.value === 'onSiteByDriverTS' &&
              (state.childLocations[i].parking || state.childLocations[i].exchange || state.childLocations[i].returnToHQ)) {
              state.childLocations[i]['doneByDriverTS'] = state.childLocations[i][data.value];
              fields['doneByDriverTS'] = state.childLocations[i]['doneByDriverTS'];
              if (data.userId) {
                state.childLocations[i]['doneByDriver'] = data.userId;
                fields['doneByDriver'] = data.userId;
              }
            }
            break;
          }
        }

        // Do not update key on status change since load update time check timer added which refresh ui
        commit('updateLocationsKey');
        commit('updateTrucksIds');
        resolve(fields);
      });
    }
  }
};
