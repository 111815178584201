<template>
  <v-container>
    <v-row align="center" justify="center" width="100%">
      <v-card light :flat="print" style="width: 28cm;">
        <v-card-text>
          <h1 style="line-height: 32px;" v-html="title"></h1>
          <v-row>
            <v-col xs="12" class="mt-1 pa-0">
              <v-row>
                <v-col xs="6" class="text-left pr-0 pl-4">
                  <img responsive width="80%" class="mt-1" :src="logoPath"/>
                  <br/>
                  <div class="text-left" style="width: 80%;">
                    <span v-html="contactInfo ? $root.newLineToBR(contactInfo) : null"></span>
                  </div>
                  <!--<v-simple-table dense style="width: 200px">
                    <template v-slot:default>
                      <tbody>
                        <tr :key="'byStatusCounts'+item.key" v-for="item in byStatusCounts">
                          <td  class="text-left" >
                            {{item.key}}
                          </td>
                          <td>
                            {{item.value}}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>-->
                </v-col>
                <v-col xs="6" class="pt-5">
                  <div style="border-left: 4px #999 solid" class="pl-2">
                    <v-simple-table dense class="mb-5" :key="Object.keys(byStatusCounts).length">
                      <template v-slot:default>
                        <tbody>
                        <tr>
                          <th class="text-left" colspan="2">
                            <h1>Totals</h1>
                          </th>
                        </tr>
                        <tr>
                          <td class="text-left">Invoices Count</td>
                          <td class="text-left">{{parseInt(totalsCounts.totalCount).toLocaleString()}}</td>
                        </tr>
                        <tr v-if="!simplified">
                          <td class="text-left">Invoices Paid</td>
                          <td class="text-left">{{parseInt(totalsCounts.totalPaid).round(2).toLocaleString()}}</td>
                        </tr>
                        <tr>
                          <td class="text-left">Amount</td>
                          <td class="text-left"><v-icon small class="mr-1">fa-dollar-sign</v-icon>{{parseFloat(totalsCounts.totalAmount).round(2).toLocaleString()}}</td>
                        </tr>
                        <tr v-if="!simplified">
                          <td class="text-left">Received</td>
                          <td class="text-left"><v-icon small class="mr-1">fa-dollar-sign</v-icon>{{parseFloat(totalsCounts.totalReceived).round(2).toLocaleString()}}</td>
                        </tr>
                        <tr v-if="!simplified">
                          <td class="text-left">Expected</td>
                          <td class="text-left"><v-icon small class="mr-1">fa-dollar-sign</v-icon>{{parseFloat(totalsCounts.totalExpected).round(2).toLocaleString()}}</td>
                        </tr>
                        <tr v-if="!simplified">
                          <td class="text-left">Factoring Fees</td>
                          <td class="text-left"><v-icon small class="mr-1">fa-dollar-sign</v-icon>{{parseFloat(totalsCounts.totalFactoring).round(2).toLocaleString()}}</td>
                        </tr>
                        <tr v-if="!simplified">
                          <td class="text-left">Expected Factoring Fees</td>
                          <td class="text-left"><v-icon small class="mr-1">fa-dollar-sign</v-icon>{{parseFloat(totalsCounts.totalExpectedFactoring).round(2).toLocaleString()}}</td>
                        </tr>
                        <tr v-if="!simplified">
                          <td class="text-left">Received Adv.</td>
                          <td class="text-left"><v-icon small class="mr-1">fa-dollar-sign</v-icon>{{parseFloat(totalsCounts.totalReceivedAdv).round(2).toLocaleString()}}</td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </v-col>
              </v-row>
              <br/>
              <v-simple-table dense
                              style="border: 1px solid #000; width:100%;background: transparent;"
                              class="text-left">
                <tbody>
                <tr style="background:#015aaa;color:#fff;page-break-inside: avoid;">
                  <th class="report_font_size_normal text-center"><h4>Invoice #</h4></th>
                  <th class="report_font_size_normal text-center"><h4>Status</h4></th>
                  <th class="report_font_size_normal text-center"><h4>Broker</h4></th>
                  <th class="report_font_size_normal text-center"><h4>Sent Date</h4></th>
                  <th class="report_font_size_normal text-center"><h4>Amount</h4></th>
                  <th class="report_font_size_normal text-center" v-if="!simplified"><h4>Factoring</h4></th>
                  <th class="report_font_size_normal text-center" v-if="!simplified"><h4>Received</h4></th>
                </tr>
                <tr v-for="entry in entries" style="page-break-inside: avoid;" :key="entry.id+'_'+entry.invoiceNumber">
                  <td class="report_font_size_normal">#{{entry.invoiceNumber}}</td>
                  <td class="report_font_size_normal">{{entry.status}}</td>
                  <td class="report_font_size_normal">{{entry.brokerName}}</td>
                  <td class="report_font_size_normal">{{entry.sentDatePST ? entry.sentDatePST.substring(0,10) : 'N/A'}}</td>
                  <td class="report_font_size_normal">${{parseFloat(parseFloat(entry.total).toFixed(2)).toLocaleString()}}</td>
                  <td class="report_font_size_normal" v-if="!simplified">{{parseFloat(entry.factoring) < 0 ? '' : parseFloat(entry.factoring).toFixed(1)+'%'}}</td>
                  <td class="report_font_size_normal" v-if="!simplified">${{parseFloat(parseFloat(entry.factReceived).toFixed(2)).toLocaleString()}}</td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'InvoicesReportTemplate',
  props: ['entries', 'totalsCounts', 'print', 'companyId', 'simplified', 'overrideTitle'],
  components: {},
  data: function () {
    return {
      byStatusCounts: [],
      publicPath: process.env.BASE_URL,
      contactInfo: '',
      logoPath: '',
      title: ''
    };
  },
  computed: {
    from () {
      return this.entries.length > 0 ? this.entries[0].sentDatePST : '';
    },
    to () {
      return this.entries.length > 0 ? this.entries[this.entries.length - 1].sentDatePST : '';
    }
  },
  mounted () {
    this.$nextTick(() => {
      let i = 0;
      let data = {};
      this.$root.getInvoiceLogoPath(this.companyId).then(path => {
        this.logoPath = path;
      });
      this.$root.getGlobalSettingsField('invoiceContactInfo', this.companyId).then((response) => {
        if (response.status === 'ok') {
          this.contactInfo = response.result;
        }
      });
      for (i = 0; i < this.entries.length; i++) {
        const status = this.entries[i].status;
        if (!data.hasOwnProperty(status)) {
          data[status] = 0;
        }
        data[status] += 1;
      }
      for (let k in data) {
        this.byStatusCounts.push({ key: k, value: data[k] });
      }
    });
    this.generateReportTitle();
    document.title = this.title;
  },
  methods: {
    generateReportTitle () {
      if (this.overrideTitle) {
        this.title = this.overrideTitle;
        this.$root.currentReportFileName = this.overrideTitle;
      } else {
        this.title = 'Invoices ' + this.$root.t('Report') + ' ' + (parseInt(this.$route.params.report_number) > 0 ? '#' + this.$route.params.report_number : '') + ': ';
        this.$root.currentReportFileName = 'Invoices_' + this.$root.t('Report') + (parseInt(this.$route.params.report_number) > 0 ? '_' + this.$route.params.report_number : '') + '_';
        if (this.entries && this.entries.length > 1) {
          this.title += this.entries[0].startedPST.substring(0, 10) + ' - ' + this.entries[this.entries.length - 1].startedPST.substring(0, 10);
          this.$root.currentReportFileName += this.$root.parseDate(this.entries[0].startedPST.substring(0, 10)) + '-' + this.$root.parseDate(this.entries[this.entries.length - 1].endedPST.substring(0, 10));
        }
        if (this.entries && this.entries.length === 1) {
          this.title += this.entries[0].startedPST.substring(0, 10);
          this.$root.currentReportFileName += this.$root.parseDate(this.entries[0].startedPST.substring(0, 10));
        }
      }
    }
  }
};

</script>
