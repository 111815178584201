<template>
  <v-layout>
    <v-flex shrink class="pa-0">
      <template>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn  v-on="on"
                    :loading="loadingVerify"
                    @click.stop="isVerifyAllowed ? onMarkAsVerified() : null"
                    icon
                    :disabled="!isVerifyAllowed"
                    :small="small">
              <v-icon :color="lastCommentWithFlag.flag === $store.getters.troubleShootingFlags.VERIFIED || isVerifiedClicked ? 'success' : null"
                      :small="small">
                mdi-check-decagram
              </v-icon>
            </v-btn>
          </template>
          <span>
            <div v-if="lastCommentWithFlag.flag !== $store.getters.troubleShootingFlags.VERIFIED">{{$t('Loads.MarkAsVerified')}}</div>
            <div v-if="lastCommentWithFlag.flag === $store.getters.troubleShootingFlags.VERIFIED">{{$t('Loads.Verified')}}</div>
          </span>
        </v-tooltip>
      </template>
    </v-flex>
    <v-flex shrink class="pa-0" v-if="lastCommentWithFlag.comment && lastCommentWithFlag.flag !== $store.getters.troubleShootingFlags.VERIFIED">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn :loading="loadingRefine"
                 @click.stop="isVerifyAllowed ? onMarkAsRefine() : null"
                 icon v-on="on" :small="small">
            <v-icon :small="small" :color="isVerifiedClicked ? 'success' : lastCommentColor">mdi-comment-alert</v-icon>
          </v-btn>
        </template>
        <span v-if="lastCommentWithFlag.flag !== $store.getters.troubleShootingFlags.VERIFIED"
              v-html="lastCommentWithFlag.comment">
        </span>
        <span v-else>{{$t('Loads.MarkAsRefine')}}</span>
      </v-tooltip>
    </v-flex>
  </v-layout>
</template>

<script>

import troubleShootingMixin from '../mixins/troubleShootingMixin';

export default {
  name: 'VerificationButtons',
  props: ['small', 'itemType', 'itemId', 'lastCommentWithFlag'],
  mixins: [troubleShootingMixin],
  data () {
    return {
      loadingVerify: false,
      loadingRefine: false,
      isVerifiedClicked: false
    };
  },
  computed: {
    isVerifyAllowed () {
      if (this.itemType === this.$store.getters.troubleShootingTypes.LOADS) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.loadsRights['isVerifyAllowed']);
      } else if (this.itemType === this.$store.getters.troubleShootingTypes.INVOICES) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.invoicesRights['isVerifyAllowed']);
      }
      return false;
    },
    lastCommentColor () {
      if (this.lastCommentWithFlag.flag === this.$store.getters.troubleShootingFlags.EDIT_REQUEST) {
        return 'info';
      }

      if (this.lastCommentWithFlag.flag === this.$store.getters.troubleShootingFlags.EDIT_OVERRIDE) {
        return 'info';
      }

      if (this.lastCommentWithFlag.flag === this.$store.getters.troubleShootingFlags.VERIFIED) {
        return 'success';
      }

      if (this.lastCommentWithFlag.flag === this.$store.getters.troubleShootingFlags.REFINE) {
        return 'error';
      }

      if (this.lastCommentWithFlag.flag === this.$store.getters.troubleShootingFlags.REFINED) {
        return 'info';
      }

      if (this.lastCommentWithFlag.flag === this.$store.getters.troubleShootingFlags.WAITING_FURTHER_ACTIONS) {
        return 'warning';
      }

      return null;
    },
  },
  methods: {
    onMarkAsVerified () {
      if (this.lastCommentWithFlag.flag !== this.$store.getters.troubleShootingFlags.VERIFIED) {
        this.sendTroubleShootingComment(0, this.itemType, this.itemId, 'Verified', '[]', this.$store.getters.troubleShootingFlags.VERIFIED).then(() => {
          this.isVerifiedClicked = true;
          this.$emit('fetch');
        });
      }
    },
    onMarkAsRefine () {
      this.$emit('refine', this.itemType, this.itemId);
    }
  }
}
</script>
