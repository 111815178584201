<template>
  <v-card tile>
    <v-toolbar dark color="#015aaa" dense style="flex:0;">
      <v-btn icon dark @click="$emit('closed')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{$t('SendEmailWithAttTitle')}}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <template v-if="isSent">
          <v-btn disabled>
            <v-icon left color="success">mdi-send-check</v-icon>{{$t('EmailWasSent')}}
          </v-btn>
        </template>
        <template v-else>
          <v-btn @click="sendMessage(false)" dark text :loading="isSending">
              <v-icon left >mdi-send</v-icon>{{$t('SendEmail')}}
          </v-btn>
        </template>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-layout wrap class="mt-2">
        <v-flex xs12 md6>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout wrap>
              <v-flex xs12 md6 class="pa-2">
                <v-select
                  :label="$t('Bids.SelectSender')"
                  v-model="emailSender"
                  :items="sendersList"
                  :rules="[$root.rules.required]"
                  item-text="text"
                  item-value="value"
                  :key="sender"
                  outlined hide-details dense
                ></v-select>
              </v-flex>
              <v-flex xs12 md6 class="pa-2">
                <v-combobox
                  dense multiple
                  v-model="emailRecipient"
                  :items="recipientsList"
                  :rules="[$root.rules.required]"
                  :key="recipient"
                  outlined hide-details :label="$t('Bids.EmailRecipient')"
                ></v-combobox>
              </v-flex>
              <v-flex xs12 class="pa-2">
                <v-text-field dense v-model="emailCC" outlined hide-details :label="$t('Bids.EmailCC')"></v-text-field>
              </v-flex>
              <v-flex xs12 class="pa-2">
                <v-text-field v-model="emailSubject"
                              :rules="[$root.rules.required]"
                              outlined hide-details dense
                              :label="$t('Bids.EmailSubject')"></v-text-field>
                <div style="background-color:#fff;color: #000;">
                  <VueEditor v-model="body" :editorToolbar="$root.customToolbar"></VueEditor>
                </div>
                <v-checkbox
                  v-model="isGmailSignature"
                  :label="$t('Bids.UseGmailSignature')">
                </v-checkbox>
              </v-flex>
            </v-layout>
          </v-form>
        </v-flex>

        <v-flex xs12 md6 class="text-right">
          <v-carousel v-model="attCarousel"
                      delimiter-icon="mdi-file-document"
                      v-if="attArray.length > 0"
                      :key="'attCarousel' + attArray.length"
                      height="1000px"
                      :show-arrows="false"
                      :show-arrows-on-hover="false">
            <template v-for="(item, index) in attArray" >
              <v-carousel-item :key="index" v-if="item.attUrl && item.folder && item.file">
                <v-btn @click="removeAtt(index)" icon color="error"><v-icon left>mdi-delete</v-icon></v-btn>
                <div :style="{ backgroundColor: `#ffffff`,backgroundImage: `url('${invoiceLoadingBgPath}')`, backgroundRepeat: `no-repeat`, backgroundPosition: `center`}">
                  <embed v-if="item.attUrl && item.isPdf" :src="item.attUrl" width="100%" height="900px" />
                <!--<v-skeleton-loader type="image" style="width:auto;" v-if="item.attUrl && !item.isPdf && !item.isImageLoaded"></v-skeleton-loader>-->
                <v-img contain style="width:auto;" :src="item.attUrl" @load="()=>{item.isImageLoaded=true;}" v-if="item.attUrl && !item.isPdf"></v-img>
                </div>
              </v-carousel-item>
            </template>
          </v-carousel>
          <v-btn @click="refreshAttFiles" text><v-icon left>mdi-refresh-circle</v-icon>{{$t('Invoices.ReloadFiles')}}</v-btn>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import fieldAccessRightMixin from './../mixins/fieldAccessRightsMixin';
import userFunctionsMixin from './../mixins/userFunctionsMixin';
import { VueEditor, Quill } from 'vue2-editor';

export default {
  name: 'EmailSendForm',
  props: ['attFilesArray', 'recipientsList', 'title', 'sender', 'recipient', 'bid', 'load', 'invoice'],
  components: { VueEditor },
  mixins: [ fieldAccessRightMixin, userFunctionsMixin ],
  data: function () {
    return {
      isImageLoaded: false,
      isSending: false,
      emailSender: '',
      emailRecipient: [],
      emailCC: '',
      emailSubject: '',
      body: '',
      valid: true,
      isSent: false,
      isGmailSignature: true,
      sendersList: [],
      selectedSender: '',
      attArray: [],
      attCarousel: 0,
      bid_id: 0,
      load_id: 0,
      invoice_id: 0
    };
  },
  computed: {
    invoiceLoadingBgPath () {
      return process.env.BASE_URL + 'img/loader.gif';
    }
  },
  mounted () {
    let i = 0;
    this.$root.getLinkedEmailsByUser(this.$store.getters.currentUser['id']).then(response => {
      const linkedAccountsList = response.result;
      for (i = 0; i < linkedAccountsList.length; i++) {
        if (!this.sender) {
          if (this.$store.getters.currentUser['email'] === linkedAccountsList[i].email) {
            this.emailSender = linkedAccountsList[i].email;
          }
        }
        this.sendersList.push(linkedAccountsList[i].email);
      }
    });
    if (this.title) {
      this.emailSubject = this.title;
    }

    if (this.sender) {
      this.emailSender = this.sender;
    }

    if (this.recipient) {
      this.emailRecipient = this.recipient;
    }

    if (this.bid) {
      this.bid_id = this.bid;
    }

    if (this.load) {
      this.load_id = this.load;
    }

    if (this.invoice) {
      this.invoice_id = this.invoice;
    }

    this.refreshAttFiles();
  },
  methods: {
    removeAtt (index) {
      this.attArray.splice(index, 1);
      this.$forceUpdate();
    },
    refreshAttFiles () {
      let i = 0;
      if (this.attFilesArray) {
        this.attArray = [];
        for (i = 0; i < this.attFilesArray.length; i++) {
          this.attArray.push(this.attFilesArray[i]);
        }
      }
    },
    sendMessage (fromBatch = false) {
      if (!this.$refs.form.validate()) return;
      if (fromBatch) {
        return new Promise((resolve, reject) => {
          this.isSending = true;
          this.body = this.body.replace(/<p><br><\/p>/img, '').replace(/(<p[^>]+?>|<p>)/img, '').replace(/<\/p>/img, '<br>').trim();
          this.$root.sendWithAtt(this.emailSender,
            this.emailRecipient.join(','),
            this.emailCC,
            this.emailSubject,
            this.body.replace(/<p><br><\/p>/img, '').replace(/(<p[^>]+?>|<p>)/img, '').replace(/<\/p>/img, '<br>').trim(),
            this.attArray,
            this.bid_id,
            this.load_id,
            this.invoice_id,
            this.isGmailSignature).then((response) => {
            if (response.status === 'ok') {
              this.$root.toast(this.$i18n.t('EmailWasSent'), { color: 'success' });
              this.$emit('sent');
              resolve();
              this.isSending = false;
            } else if (response.status === 'error') {
              this.$root.toast(response.msg, { color: 'error' });
              reject(response.msg);
              this.isSending = false;
            }
            resolve();
            this.isSending = false;
          }).catch((err) => {
            this.$root.toast(this.$i18n.t('SendError'), { color: 'error' });
            this.isSending = false;
            reject(err);
          });
        });
      } else {
        this.$dialog.confirm(this.$root.t('Mails.SendEmailConfirmationMsg', { 'email': this.emailSender })).then(dialog => {
          this.isSending = true;
          this.$root.sendWithAtt(this.emailSender,
            this.emailRecipient.join(','),
            this.emailCC,
            this.emailSubject,
            this.body.replace(/<p><br><\/p>/img, '').replace(/(<p[^>]+?>|<p>)/img, '').replace(/<\/p>/img, '<br>').trim(),
            this.attArray,
            this.bid_id,
            this.load_id,
            this.invoice_id,
            this.isGmailSignature).then((response) => {
            if (response.status === 'ok') {
              this.$root.toast(this.$i18n.t('EmailWasSent'), { color: 'success' });
              this.$emit('sent');
              this.$emit('closed');
            } else if (response.status === 'error') {
              this.$root.toast(response.msg, { color: 'error' });
            }
            this.isSending = false;
          }).catch((err) => {
            this.isSending = false;
            this.$root.toast(this.$i18n.t('SendError'), { color: 'error' });
          });
        });
      }
    }
  }
};
</script>
