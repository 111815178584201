export default {
  data: function () {
  },
  methods: {
    getBidsList (deletedFilter = 0, page = 0, itemsPerPage = 10, dispatcher = 0, broker = 0, truck = 0, wLoad = -1, showOnlyToday = 0) {
      const deletedParam = deletedFilter === '' ? 0 : deletedFilter;
      const dispatcherParam = dispatcher === '' ? 0 : dispatcher;
      const brokerParam = broker === '' ? 0 : broker;
      const truckParam = truck === '' ? 0 : truck;
      const wLoadParam = wLoad === '' ? -1 : wLoad;
      return this.get('/api/bids/list/' + deletedParam + '/' + page + '/' + itemsPerPage + '/' + dispatcherParam + '/' + brokerParam + '/' + truckParam + '/' + wLoadParam + '/' + showOnlyToday);
    },
    getBid (id) {
      return this.get('/api/bids/get/' + id);
    },
    getBidsBySubject (departure, destination) {
      return this.post('/api/bids/listBySubject', { departure: departure, destination: destination });
    },
    getBidsRecepientsList () {
      return this.get('/api/bids/recipientsFromBids');
    },
    getBrokersByEmail (email) {
      return this.post('/api/bids/brokersByEmail', { email: email });
    },
    createBid (fields, redirect = true, append_signature = false, user_id = false) {
      return new Promise((resolve, reject) => {
        this.post('/api/bids/save', { fields: fields, append_signature: append_signature, userId: user_id }).then((response) => {
          if (response.status === 'ok') {
            if (fields['status'] === 'to sent') {
              this.$root.toast(this.$i18n.t('Bids.BidDataCreated'), { color: 'success' });
            } else {
              this.$root.toast(this.$i18n.t('Bids.BidDataSaved'), { color: 'success' });
            }
            if (redirect) {
              this.$router.push('/bids/list');
            }
            resolve();
          } else if (response.status === 'warning') {
            this.$root.toast(response.msg, { color: 'warning' });
            if (redirect) {
              this.$router.push('/bids/list');
            }
            resolve();
          } else {
            this.$root.toast(this.$i18n.t('Bids.BidDataCreateError'), { color: 'error' });
          }
        }, (error) => {
          this.$root.toast(error, { color: 'error' });
        });
      });
    },
    createBidFromCoordsAndLoadFeed (startCoords, loadsFeedId, rate, notes = '', bidPostedLoadTruck = 0, bidPostedLoadDispatcher = 0) {
      return new Promise((resolve) => {
        this.post('/api/bids/createFromCoordsAndLoadsFeed/' + loadsFeedId, {
          startCoords: startCoords,
          rate: rate,
          notes: notes,
          bidPostedLoadTruck: bidPostedLoadTruck,
          bidPostedLoadDispatcher: bidPostedLoadDispatcher
        }).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Bids.BidDataSaved'), { color: 'success' });
          } else {
            this.$root.toast(this.$i18n.t('Bids.BidDataSaveError'), { color: 'error' });
          }
        }, (error) => {
          this.$root.toast(error, { color: 'error' });
        }).finally(() => {
          resolve();
        });
      });
    },
    saveBid (fields, id, user_id = false) {
      this.post('/api/bids/save/' + id, { fields: fields, userId: user_id }).then((response) => {
        if (response.status === 'ok') {
          this.$root.toast(this.$i18n.t('Bids.BidDataSaved'), { color: 'success' });
        } else {
          this.$root.toast(this.$i18n.t('Bids.BidDataSaveError'), { color: 'error' });
        }
      }, (error) => {
        this.$root.toast(error, { color: 'error' });
      });
    },
    restoreBid (id) {
      this.post('/api/bids/save/' + id, { fields: { deleted: 0 } }).then((response) => {
        if (response.status === 'ok') {
          this.$root.toast(this.$i18n.t('Bids.BidRestored'), { color: 'success' });
        } else {
          this.$root.toast(this.$i18n.t('Bids.BidRestoreError'), { color: 'error' });
        }
      }, (error) => {
        this.$root.toast(error, { color: 'error' });
      });
    },
    deleteBid (id) {
      this.post('/api/bids/save/' + id, { fields: { deleted: 1 } }).then((response) => {
        if (response.status === 'ok') {
          this.$root.toast(this.$i18n.t('Bids.BidDeleted'), { color: 'success' });
        } else {
          this.$root.toast(this.$i18n.t('Bids.BidDeleteError'), { color: 'error' });
        }
      }, (error) => {
        this.$root.toast(error, { color: 'error' });
      });
    },
    getAddressStrFromComponentsObject (addressComponnents) {
      let i = 0;
      let results = [];
      let postalCode = addressComponnents.find((el) => { return el.types.indexOf('postal_code') >= 0; });
      for (i = 0; i < addressComponnents.length; i++) {
        if (addressComponnents[i].types.indexOf('country') < 0 &&
            addressComponnents[i].types.indexOf('plus_code') < 0 &&
            addressComponnents[i].types.indexOf('postal_code') < 0 &&
            addressComponnents[i].types.indexOf('administrative_area_level_2') < 0) {
          if (addressComponnents[i].types.indexOf('administrative_area_level_1') >= 0) {
            results.push((addressComponnents[i].short_name || addressComponnents[i].long_name) + (postalCode ? ' ' + postalCode.short_name : ''));
          } else {
            results.push(addressComponnents[i].short_name || addressComponnents[i].long_name);
          }
        }
      }
      return results.join(', ');
    },
    getGeneratedBidMessage (dispatcherId, bidRate, truckId, useTruckAdvDimensions, isGmailSignature, signature, waypointsInfo, waypointsNames, waypointsCityStateNames, emailCustomText) {
      return this.post('/api/bids/bidMessageText/' + dispatcherId, {
        bidRate: bidRate,
        truckId: truckId,
        useTruckAdvDimensions: useTruckAdvDimensions,
        isGmailSignature: isGmailSignature,
        signature: signature,
        waypointsInfo: waypointsInfo,
        waypointsNames: waypointsNames,
        waypointsCityStateNames: waypointsCityStateNames,
        emailCustomText: emailCustomText
      });
    },
    bidsFilterSearch (type, val) {
      return this.$root.get('/api/bids/getFilterData/' + type + '/' + val);
    }
  }
};
