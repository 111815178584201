<template>
  <v-container>
    <v-simple-table dense>
      <template v-slot:default>
        <tbody>
        <tr class="text-left">
          <td><b>{{$t('Loads.ExpensesAdded')}}</b></td>
          <td>{{$root.getDateTimeStringFromTimestamp(item.timestamp)}}</td>
        </tr>
        <tr class="text-left" v-if="item.compensate !== 2 && item.compensate !== 3">
          <td><b>{{$t('PaymentType')}}</b></td>
          <td>{{item.paymentType}}</td>
        </tr>
        <tr class="text-left" v-if="item.compensate !== 2 && item.compensate !== 3">
          <td><b>{{isShowGallons ? $t('Loads.GallonsAmount') : $t('Loads.ExpensesAmount')}}</b></td>
          <td>x{{item.amount}}</td>
        </tr>
        <tr class="text-left" v-if="item.compensate !== 2 && item.compensate !== 3">
          <td><b>{{isShowGallons ? $t('Loads.ExpensesPerGallon') : $t('Loads.ExpensesPerItem')}}</b></td>
          <td>${{parseFloat(parseFloat(item.perItem).toFixed(2)).toLocaleString()}}</td>
        </tr>
        <tr class="text-left">
          <td><b>{{$t('Loads.ExpensesTotal')}}</b></td>
          <td>${{parseFloat(parseFloat(item.total).toFixed(2)).toLocaleString()}}</td>
        </tr>
        <tr class="text-center" v-if="item.addToRate">
          <td colspan="2">
            <v-chip small color="warning">
              {{$t('Loads.IsAddedExpenseToRate')}}
            </v-chip>
          </td>
        </tr>
        <tr class="text-left" v-if="item.addToDispCalc">
          <td colspan="2">
            <b>{{$t('Loads.IsCalcInDispatcherRate')}}</b>
          </td>
        </tr>
        <tr class="text-left" v-if="item.hasOwnProperty('dbData')">
          <td><b>Transaction Date</b></td>
          <td>{{item.dbData.TrxDate}}</td>
        </tr>
        <tr class="text-left" v-if="item.hasOwnProperty('dbData')">
          <td><b>Transaction #</b></td>
          <td>{{item.dbData.Transaction}}</td>
        </tr>
        <tr class="text-left" v-if="item.hasOwnProperty('dbData')">
          <td><b>Card Description</b></td>
          <td>{{item.dbData.CardDescription}}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!--<template v-if="!approveOnly">
      <v-layout v-if="!readOnly || item.pending" wrap>
        <v-flex v-if="!readOnly">
          <v-btn @click.stop="$emit('edit', item.timestamp)" :icon="$root.isMobile">
            <v-icon>mdi-pen</v-icon>{{$root.isMobile ? '' : $t('Edit')}}
          </v-btn>
        </v-flex>
        <v-flex v-if="item.pending">
          <v-btn v-if="$root.isPendingExpensesApproveAllowed" @click="$emit('approve', item.timestamp)" :icon="$root.isMobile">
            <v-icon color="success" :icon="$root.isMobile">mdi-check</v-icon>{{$root.isMobile ? '' : $t('Loads.DoApproveExpense')}}
          </v-btn>
        </v-flex>
        <v-flex v-else >
          <v-btn v-if="$root.isPendingExpensesApproveAllowed" @click="$emit('reset', item.timestamp)" :icon="$root.isMobile">
            <v-icon color="error" :icon="$root.isMobile">mdi-cancel</v-icon>{{$root.isMobile ? '' : $t('Loads.DoResetExpense')}}
          </v-btn>
        </v-flex>
        <v-flex v-if="!readOnly">
          <v-btn @click.stop="$emit('delete', item.timestamp)" :icon="$root.isMobile">
            <v-icon color="red">mdi-delete</v-icon>{{$root.isMobile ? '' : $t('Remove')}}
          </v-btn>
        </v-flex>
      </v-layout>
    </template>-->
    <!--<template>
      <v-layout>
        <v-flex xs12 v-if="item.pending" class="text-center">
          <v-btn v-if="$root.isPendingExpensesApproveAllowed" @click="onApprove(item)">
            <v-icon color="success">mdi-check</v-icon>{{$t('Loads.DoApproveExpense')}}
          </v-btn>
        </v-flex>
      </v-layout>
    </template>-->
      <MultiFileUpload :field="item.checkUPLOAD"
                       fieldName="checkUPLOAD"
                       uploadFolder="check"
                       height="350px"
                       :label="$t('Loads.ExpensesAddCheck')"
                       :deleteAllowed="false"
                       :downloadAllowed="true"
                       :readonly="true"/>
  </v-container>
</template>

<script>
import MultiFileUpload from '../components/MultiFileUpload.vue';

export default {
  name: 'ExpenseInfo',
  props: ['item', 'loadId', 'readOnly', 'approveOnly'],
  components: { MultiFileUpload },
  data () {
    return {
      isShowGallons: false
    };
  },
  computed: {

  },
  mounted () {
    if (this.item.name.toLowerCase() === 'fuel' || this.item.name.toLowerCase() === 'def') {
      this.isShowGallons = true;
    }
  },
  methods: {
    onApprove (item) {
      this.$dialog.confirm(this.$root.t('Loads.ApproveExpense')).then((dialog) => {
        this.$root.approveExpense(item.loadId, item.timestamp).then((response) => {
          if (response.status === 'ok') {
            this.$emit('approved');
          }
        });
      });
    }
  }
};
</script>
