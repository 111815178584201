<template>
  <div>
    <v-overlay :value="loading" :absolute="true">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-card v-if="fieldsArray.length > 1 && isFullFinancialData">
      <v-card-title>{{$t('Loads.TotalWithLinkedLoadsTitle')}}</v-card-title>
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
          <tr>
            <td colspan="2" class="text-left">{{$t('Loads.TotalIncomeWithLinkedLoads')}}</td>
            <td style="color:#4caf50;">$+{{getTotalIncluding().toLocaleString()}}</td>
          </tr>
          <tr>
            <td colspan="2" class="text-left">{{$t('Loads.TotalExpensesWithLinkedLoads')}}</td>
            <td style="color:#f44336;">$-{{getTotalExcluding().toLocaleString()}}</td>
          </tr>
          <tr style="background:#015aaa;color:#fff;">
            <td colspan="2" class="text-left">{{$t('Loads.TotalWithLinkedLoads')}}</td>
            <td>${{getTotalIncome().toLocaleString()}}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <v-card class="mt-2 pa-2 pt-0" v-if="isDriverFinancialData && !isFullFinancialData">
      <v-card-title>
        {{$t('Loads.FinancialData')}}
      </v-card-title>
      <!--For drivers show data only for one loads-->
      <!-- 0 is for first fin report if nested loads here have few reports combined, keep in mind check how its implemented in full fin report below-->
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
          <template v-if="excludingArray[0] && excludingArray[0].length > 0">
            <template v-for="(excludingItem, excludingItemIndex) in excludingArray[0]">
              <tr v-if="!excludingItem.isSeparator" :key="'excludingItemIndex'+excludingItemIndex">
                <td class="text-left" v-if="excludingItem.namei18n">{{$t(excludingItem.namei18n)}}</td>
                <td class="text-left" v-else>{{excludingItem.name}}</td>
                <td class="text-left">$&nbsp;{{parseFloat(excludingItem.rate).round(2).toLocaleString()}}</td>
                <!--<td>
                  <template v-if="driversDataArray[0][excludingItem.details]">
                    <a href="#" @click="$root.route('/user/page/'+excludingItem.details)">{{driversDataArray[0][excludingItem.details].userName}}</a>
                  </template>
                </td>-->
              </tr>
            </template>
          </template>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <v-card class="mt-2 pa-2 pt-0" v-if="isDispatcherFinancialData && !isFullFinancialData">
      <v-card-title>
        {{$t('Loads.FinancialData')}}
      </v-card-title>
      <template v-for="(fields, index) in fieldsArray">
        <!--For dispather show data only for one loads-->
        <template v-if="index === 0">
          <v-simple-table dense :key="'field'+index">
            <template v-slot:default>
              <tbody>
              <tr>
                <td class="text-left">{{$t('Loads.Rate')}}</td>
                <td></td>
                <td class="text-left">${{parseFloat(fields.Rate).round(2).toLocaleString()}}</td>
              </tr>
              <tr v-if="fields.type === 'TL_RTL'">
                <td class="text-left">{{$t('Loads.RTLRate')}}</td>
                <td></td>
                <td class="text-left">${{parseFloat(fields.RTLRate).round(2).toLocaleString()}}</td>
              </tr>
              <template v-if="excludingArray[index] && excludingArray[index].length > 0">
                <template v-for="(excludingItem, excludingItemIndex) in excludingArray[index]">
                  <tr v-if="excludingItem.namei18n === 'Loads.DispatcherPayment' ||
                            excludingItem.namei18n === 'Loads.DispatcherPaymentRTL' ||
                            excludingItem.namei18n === 'Loads.DispatcherPaymentFromBonus'" :key="'excludingItemIndex'+excludingItemIndex">
                    <td class="text-left" v-if="excludingItem.namei18n">{{$t(excludingItem.namei18n)}}</td>
                    <td class="text-left" v-else>{{excludingItem.name}}</td>
                    <td class="text-left">$&nbsp;{{parseFloat(excludingItem.rate).round(2).toLocaleString()}}</td>
                    <td>
                      <template v-if="driversDataArray[index][excludingItem.details]">
                        <a href="#" @click="$root.route('/user/page/'+excludingItem.details)">{{driversDataArray[index][excludingItem.details].userName}}</a>
                      </template>
                    </td>
                  </tr>
                </template>
                <tr style="background:#015aaa;color:#fff;">
                  <td colspan="2" class="text-left">{{$t('Total')}}</td>
                  <td class="text-left font-weight-bold" style="color:#fff;">${{parseFloat(excludingTotal[index]).round(2).toLocaleString()}}</td>
                </tr>
              </template>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </template>
    </v-card>

    <v-card class="mt-2 pa-2 pt-0" v-if="isFullFinancialData">
      <!--<v-card-title>
        {{$t('Loads.FinancialData')}}
        <v-spacer></v-spacer>
        <v-switch
          style="margin-top: 0px;"
          v-model="isGMapsDistance"
          :label="$t('Loads.GMapsDistance')"
          hide-details
          @change="onGMapsDistanceChange"
        ></v-switch>
      </v-card-title>-->
      <template v-for="(fields, index) in fieldsArray">
        <v-simple-table dense :key="'field'+index">
          <template v-slot:default>
            <tbody>
            <template v-if="index > 0">
              <tr>
                <td  class="text-left">
                  <v-btn small @click.stop="$root.addNewPageTab('/loads/page/' + fields.id)" v-if="$root.isLoadsViewAllowed">
                    <v-icon small left>mdi-open-in-new</v-icon>
                    {{$t('Open')}} {{fields.uid}}
                  </v-btn>
                </td>
                <td></td>
                <td></td>
              </tr>

            </template>
            <tr>
              <td class="text-left">{{$t('Loads.LoadRate')}}</td>
              <td class="text-left">${{parseFloat(fields.Rate).round(2).toLocaleString()}}</td>
              <td></td>
            </tr>
            <tr v-if="fields.type === 'TL_RTL'">
              <td class="text-left">{{$t('Loads.RTLRate')}}</td>
              <td class="text-left">${{parseFloat(fields.RTLRate).round(2).toLocaleString()}}</td>
              <td></td>
            </tr>
            <!--<tr>
              <td class="text-left">{{$t('Loads.plannedRatePerMile')}}</td>
              <td></td>
              <td class="text-left">${{parseFloat(fields.RatePerMile).round(2).toLocaleString()}}</td>
            </tr>
            <tr>
              <td class="text-left">{{$t('Loads.RatePerMile')}}</td>
              <td></td>
              <td class="text-left">${{parseInt(actualDistanceInMiles) > 0 ? parseFloat(parseFloat(fields.Rate + fields.RTLRate)/parseFloat(actualDistanceInMiles)).round(2).toLocaleString() : 0}}</td>
            </tr>-->
            <tr v-if="parseInt(fields.finished) === 2">
              <td class="text-left">{{$t('Loads.RateTONU')}}</td>
              <td class="text-left">${{new Number(fields.RateTONU).toFixed(2).toLocaleString()}}</td>
              <td></td>
            </tr>
            <tr v-if="parseInt(fields.isDriverOwnerOperator) === 1">
              <td class="text-left">{{$t('Loads.LoadRateOO')}}</td>
              <td class="text-left">${{new Number(fields.Rate - fields.driverOwnerOperatorBonus).toFixed(2).toLocaleString()}}</td>
              <td></td>
            </tr>

            <!-- Additional payments-->
            <template v-for="additional_payment_item in ['Detention', 'Layover', 'Fine', 'DriverAssist', 'Idle']">
              <tr v-if="fields[additional_payment_item] > 0" :key="additional_payment_item">
                <td class="text-left">{{$t('Loads.'+additional_payment_item)}}</td>
                <td class="text-left">${{new Number(fields[additional_payment_item]).toFixed(2).toLocaleString()}}</td>
                <td></td>
              </tr>
            </template>

            <tr>
              <td class="text-left">{{$t('Loads.plannedDistanceInMiles')}}</td>
              <td class="text-left">{{plannedDistanceInMiles.toFixed(0).toLocaleString()}} {{$t('milesShort')}} (${{parseFloat(fields.RatePerMile).round(2)}} per mi.)</td>
              <td></td>
            </tr>
            <tr>
              <td class="text-left">{{$t('Loads.ActualDistanceInMiles')}}</td>
              <td class="text-left">{{parseInt(actualDistanceInMiles).toLocaleString()}} {{$t('milesShort')}} (${{parseInt(actualDistanceInMiles) > 0 ? parseFloat(parseFloat(fields.Rate + fields.RTLRate)/parseFloat(actualDistanceInMiles)).round(2).toLocaleString() : 0}} per mi.)</td>
              <td></td>
            </tr>
            <tr>
              <td colspan="3"></td>
            </tr>
            <template v-if="includingArray[index] && includingArray[index].length > 0">
              <tr style="background:#4caf50;color:#fff;">
                <td></td>
                <td class="text-left font-weight-bold">{{$t('Loads.Including')}}</td>
                <td></td>
              </tr>
              <tr v-for="(includingItem, includingItemIndex) in includingArray[index]" :key="'includingItemIndex'+includingItemIndex" class="text-left">
                <td class="text-left">{{$t(includingItem.namei18n)}}</td>
                <td class="text-left"><span :class="includingItem.isCanceled ? 'text-decoration-line-through' : null">$ {{parseFloat(includingItem.rate).round(2).toLocaleString()}}</span>&nbsp;<v-chip v-if="includingItem.isCanceled" x-small color="error">{{$t('Loads.Canceled')}}</v-chip></td>
                <td class="text-left"></td>
              </tr>
              <tr>
                <td class="text-left">{{$t('Loads.TotalIncluding')}}</td>
                <td></td>
                <td class="text-left font-weight-bold" style="color:#4caf50;">$+{{parseFloat(includingTotal[index]).round(2).toLocaleString()}}</td>
              </tr>
            </template>
                <tr><td colspan="3"></td></tr>

                <!--Expenses-->
                <template v-if="excludingArray[index] && excludingArray[index].length > 0">
                  <tr style="background:#f44336;color:#fff;">
                    <td></td>
                    <td class="text-left font-weight-bold">{{$t('Loads.Excluding')}}</td>
                    <td></td>
                  </tr>
                  <template v-for="(excludingItem, excludingItemIndex) in excludingArray[index]">
                    <tr v-if="excludingItem.isSeparator" style="background-color: #bbb; color: #000;font-weight:500;" :key="'excludingItemIndex'+excludingItemIndex">
                      <td>
                      </td>
                      <td class="text-left font-weight-bold">{{$t(excludingItem.namei18n)}}</td>
                      <td>
                      </td>
                    </tr>
                    <tr v-else
                        :key="'excludingItemIndex'+excludingItemIndex"
                        :style="(excludingItem.namei18n === 'Loads.DriversPayments' || excludingItem.namei18n === 'Loads.DispatcherPayments') ? { backgroundColor: '#eee', fontWeight: 700 } : {}">
                        <td class="text-left" v-if="excludingItem.namei18n">
                          <template v-if="excludingItem.namei18n === 'Loads.DriversPayments' || excludingItem.namei18n === 'Loads.DispatcherPayments'">{{$t('Total')}}&nbsp;</template>{{$t(excludingItem.namei18n)}}
                        </td>
                      <td class="text-left" v-else>{{excludingItem.name}}</td>
                      <td class="text-left text-no-wrap">
                        <v-layout>
                          <v-flex shrink>
                            <v-tooltip bottom v-if="excludingItem.tooltipStr">
                              <template v-slot:activator="{ on }">
                                <div v-on="on" class="mt-1">
                                  $&nbsp;{{parseFloat(parseFloat(excludingItem.overrides) >= 0 ? excludingItem.overrides : excludingItem.rate).round(2).toLocaleString()}}<template v-if="excludingItem.amount > 1">(x{{excludingItem.amount}})</template>
                                </div>
                              </template>
                              <span>{{excludingItem.tooltipStr}}</span>
                            </v-tooltip>
                            <div v-else class="mt-1">
                              $&nbsp;{{parseFloat(parseFloat(excludingItem.overrides) >= 0 ? excludingItem.overrides : excludingItem.rate).round(2).toLocaleString()}}<template v-if="excludingItem.amount > 1">(x{{excludingItem.amount}})</template>
                            </div>
                          </v-flex>
                          <v-flex shrink v-if="excludingItem.isOverridable && $root.isOverrideSalaryAllowed">
                            <!--<OverriddenFieldsButton
                              :key="$root.overrideFieldKey"
                              :userId="excludingItem.userId"
                              :itemType="$store.getters.overriddenFieldsTypes.LOADS"
                              :itemId="loadId"
                              :fieldName="excludingItem.namei18n"
                              :currentValue="excludingItem.rate"
                              :showCancel="parseFloat(excludingItem.overrides) >= 0"
                              @change="fetchData"
                            />-->
                          </v-flex>
                          <!--<v-flex shrink>
                            <v-btn @click="onOverrideSalary(excludingItem.details, excludingItem.namei18n ? excludingItem.namei18n : excludingItem.name, parseFloat(excludingItem.overrides) >= 0 ? excludingItem.overrides : excludingItem.rate, excludingItem.notes)"
                                   v-if="excludingItem.isOverridable && $root.isOverrideSalaryAllowed"
                                   small icon
                                   >
                              <v-icon small>mdi-pen</v-icon>
                            </v-btn>
                          </v-flex>
                          <v-flex shrink>
                            <v-btn @click="onOverrideSalaryCancel(excludingItem.details, excludingItem.namei18n ? excludingItem.namei18n : excludingItem.name)"
                                   small icon
                                   v-if="parseFloat(excludingItem.overrides) >= 0 && $root.isOverrideSalaryAllowed"
                            >
                              <v-icon small color="red">mdi-close-thick</v-icon>
                            </v-btn>
                          </v-flex>-->
                          <v-flex shrink>
                            <v-tooltip bottom v-if="excludingItem.notes && excludingItem.notes !== ''">
                              <template v-slot:activator="{ on }">
                                <v-btn icon small v-on="on"><v-icon small class="mr-1">mdi-information</v-icon></v-btn>
                              </template>
                              <span>
                                {{excludingItem.notes}}
                              </span>
                            </v-tooltip>
                          </v-flex>
                        </v-layout>
                      </td>
                      <td class="text-left">
                        <v-icon v-if="excludingItem.isImported" left small>mdi-import</v-icon>
                        <div v-else style="width: 16px"></div>
                        <span v-if="excludingItem.type === 0">{{$t('Expenses')}} &mdash; </span>
                        <span v-else-if="excludingItem.type === 1">{{$t('Compensate')}} &mdash; </span>
                        <span v-else-if="excludingItem.type === 2">{{$t('AddToSalary')}} &mdash; </span>
                        <span v-else-if="excludingItem.type === 3">{{$t('Fine')}} &mdash; </span>
                        <span v-else-if="excludingItem.type">{{excludingItem.type}} &mdash; </span>
                        <template v-if="driversDataArray[index][excludingItem.details]">
                          <a @click="$root.onShowUserPreview(excludingItem.details, driversDataArray[index][excludingItem.details].userName)">
                            {{driversDataArray[index][excludingItem.details].userName}}
                          </a>
                        </template>
                      </td>
                    </tr>
                  </template>
                  <tr>
                    <td class="text-left">{{$t('Loads.TotalExpenses')}}</td>
                    <td></td>
                    <td class="text-left font-weight-bold" style="color:#f44336;">$&mdash;{{parseFloat(excludingTotal[index]).round(2).toLocaleString()}}</td>
                  </tr>
                </template>
                <tr style="background:#015aaa;color:#fff;">
                  <td class="text-left" colspan="2">{{$t('Loads.Income')}}</td>
                  <td class="text-left font-weight-bold">${{parseFloat(incomeArray[index]).round(2).toLocaleString()}}</td>
                </tr>
                <tr>
                  <td class="text-center" colspan="3">

                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

            <template v-if="invoicesArray.length > 0">
              <h4>{{$t('Invoices.List')}}</h4>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">{{$t('Invoices.Status')}}</th>
                      <th class="text-center">{{$t('Invoices.Creator')}}</th>
                      <th class="text-center">{{$t('Invoices.SentBy')}}</th>
                      <th class="text-center">{{$t('Invoices.Number')}}</th>
                      <th class="text-center">{{$t('Invoices.Broker')}}</th>
                      <th class="text-center">{{$t('Invoices.Amount')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in invoicesArray" @click="$root.onShowInvoicePreview(item.id, item.invoiceNumber)">
                      <td class="text-center">{{item.status}}</td>
                      <td class="text-center">
                          {{item.createdPST}}
                          <br/>
                          {{item.creatorName}}
                      </td>
                      <td class="text-center">
                        <template v-if="item.sentDatePST">
                          {{item.sentDatePST}}
                          <br/>
                          {{item.senderName}}
                        </template>
                      </td>
                      <td class="text-center">{{item.invoiceNumber}}</td>
                      <td class="text-center"><a @click.stop="$root.onShowUserPreview(item.broker, item.brokerName)">{{item.brokerName}}</a></td>
                      <td class="text-center">${{item.total}}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          <!--</v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>-->
      </template>
    </v-card>

    <!--<v-dialog v-model="overrideDialog" width="350" v-if="$root.isOverrideSalaryAllowed">
      <v-card>
        <v-card-title>
          <h3>{{$t('Loads.OverrideSalary')}}</h3><v-spacer></v-spacer><v-btn icon @click="overrideDialog=false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-layout wrap class="pa-2">
          <v-flex xs12>
            <v-text-field class="mt-2"
                          v-model="overrideAmount"
                          prefix="$"
                          :rules="$root.numberRule"
                          v-on:keyup.enter="overrideSalary()"
                          type="number" step="0.1" @mousewheel.prevent="" outlined dense></v-text-field>
            <v-textarea :label="$t('Invoices.Notes')"
                        outlined dense
                        v-on:keyup.enter="overrideSalary()"
                        v-model="overrideNotes">
            </v-textarea>
          </v-flex>
          <v-flex xs12>
            <v-btn width="100%" @click="overrideSalary()" class="primary"><v-icon left>mdi-content-save</v-icon>&nbsp;{{$t('Save')}}</v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>-->
  </div>
</template>

<script>

import OverriddenFieldsButton from './OverriddenFieldsButton';
export default {
  name: 'FinancialDataForLoad',
  props: ['loadId', 'isFullFinancialData', 'isDispatcherFinancialData', 'isDriverFinancialData', 'excludeDispatcherPayment', 'user', 'isDriverOwnerOperator', 'rateOO'],
  components: { OverriddenFieldsButton },
  data: function () {
    return {
      loading: true,
      EXPENSE: 0,
      COMPENSATE: 1,
      ADDTOSALARY: 2,
      FINE: 3,
      excludingArray: [],
      includingArray: [],
      includingTotal: [],
      excludingTotal: [],
      freeMilesToPickup: 0,
      driverExpenseTotal: [],
      driverCompensateTotal: [],
      driverAddToSalary: [],
      driverFine: [],
      isGMapsDistance: false,
      fieldsArray: [],
      locationsArray: [],
      expensesArray: [],
      incomeArray: [],
      panels: [0],
      driversDataArray: [],
      /*overrideDialog: false,
      overrideUser: 0,
      overrideName: '',
      overrideAmount: 0,
      overrideNotes: '',*/
      plannedDistanceInMiles: 0,
      actualDistanceInMiles: 0,
      invoicesArray: []
    };
  },
  watch: {},
  computed: {
  },
  mounted () {
    this.$nextTick(async () => {
      if (this.$store.getters.role === 'Driver' || this.$store.getters.role === 'DriverOwnerOperator') {
        this.isGMapsDistance = false;
      }
      this.$root.overrideFieldCallback = this.fetchData;
      this.$root.getGlobalSettingsField('freeMilesToPickup').then((response) => {
        if (response.status === 'ok') {
          this.freeMilesToPickup = parseFloat(response.result);
        }
        this.fetchData();
      });
    });
  },
  beforeDestroy () {
    this.$root.overrideFieldCallback = () => {};
  },
  methods: {
    /*overrideSalary () {
      this.$dialog.confirm(this.$root.t('Loads.OverrideSalaryConfirmationMsg')).then(dialog => {
        if (this.overrideUser !== 0 && this.overrideName !== '') {
          this.$root.proccedOverrideSalary(this.overrideUser, this.overrideName, this.overrideAmount, this.loadId, this.overrideNotes).then((response) => {
            this.overrideDialog = false;
            this.fetchData();
          });
        }
      });
    },
    onOverrideSalaryCancel (userId, name) {
      this.$dialog.confirm(this.$root.t('Loads.ReseySalaryOverrideConfirmationMsg')).then(dialog => {
        this.$root.proccedOverrideSalary(userId, name, -1, this.loadId, '').then((response) => {
          this.fetchData();
        });
      });
    },
    onOverrideSalary (userId, name, amount, notes) {
      this.overrideUser = userId;
      this.overrideName = name;
      this.overrideAmount = parseFloat(parseFloat(amount).toFixed(2));
      this.overrideNotes = notes;
      this.overrideDialog = true;
    },*/
    onGMapsDistanceChange () {
      this.loading = true;
      setTimeout(() => {
        this.fetchData();
      }, 1000);
    },
    getTotal (index) {
      const fields = this.fieldsArray[index];
      let result = parseFloat(fields.Rate);

      result += this.includingTotal[index];

      return parseFloat(result);
    },
    getTotalIncome () {
      let result = 0.0;
      for (let i = 0; i < this.fieldsArray.length; i++) {
        result += this.incomeArray[i];
      }
      return parseFloat(parseFloat(result).toFixed(2));
    },
    getTotalIncluding () {
      let result = 0.0;
      for (let i = 0; i < this.includingTotal.length; i++) {
        result += this.includingTotal[i];
      }
      return parseFloat(result.toFixed(2));
    },
    getTotalExcluding () {
      let i = 0;
      let result = 0.0;
      for (i = 0; i < this.excludingTotal.length; i++) {
        result += this.excludingTotal[i];
      }
      return parseFloat(parseFloat(result).toFixed(2));
    },

    fetchData () {
      return new Promise(async (resolve, reject) => {
        if (this.loadId) {
          this.loading = true;
          this.excludingArray = [];
          this.includingArray = [];
          this.excludingTotal = [];
          this.includingTotal = [];
          this.driverExpenseTotal = [];
          this.driverCompensateTotal = [];
          this.driverAddToSalary = [];
          this.driverFine = [];

          this.fieldsArray = [];
          this.locationsArray = [];
          this.expensesArray = [];
          this.driversDataArray = [];
          this.incomeArray = [];

          this.invoicesArray = [];

          this.$root.get('/api/reports/getFinancialReportForLoad/' + this.loadId + '/' + this.isGMapsDistance.toString()).then((response) => {
            if (response.status === 'ok') {
              this.plannedDistanceInMiles = response.plannedDistanceInMiles;
              this.actualDistanceInMiles = response.actualDistanceInMiles;
              this.$set(this, 'invoicesArray', response.invoicesArray);
              this.fieldsArray.push(response.fields);
              this.includingArray.push(response.including.including);
              this.includingTotal.push(response.including.includingTotal);
              this.excludingArray.push(response.excluding.excluding);
              this.excludingTotal.push(response.excluding.excludingTotal);
              this.driversDataArray.push(response.users);
              this.incomeArray.push(response.netIncome);
              if (response.childLoads && response.childLoads.length > 0) {
                for (let i = 0; i < response.childLoads.length; i++) {
                  this.$root.get('/api/reports/getFinancialReportForLoad/' + response.childLoads[i].id + '/' + this.isGMapsDistance.toString()).then((response2) => {
                    if (response2.status === 'ok') {
                      this.fieldsArray.push(response2.fields);
                      this.includingArray.push(response2.including.including);
                      this.includingTotal.push(response2.including.includingTotal);
                      this.excludingArray.push(response2.excluding.excluding);
                      this.excludingTotal.push(response2.excluding.excludingTotal);
                      this.driversDataArray.push(response2.users);
                      this.incomeArray.push(response2.netIncome);
                    }
                  });
                }
              }
            }
            this.loading = false;
            // console.warn(response);
          });
        }
        resolve();
      });
    }
  }
};
</script>
