import { render, staticRenderFns } from "./SelectTruck.vue?vue&type=template&id=d3c8d166"
import script from "./SelectTruck.vue?vue&type=script&lang=js"
export * from "./SelectTruck.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports