<template>
  <div v-if="error">
    <v-flex class="text-center error pa-2">
      <h1 class="title">{{error}}</h1>
    </v-flex>
  </div>
  <div v-else>
    <v-overlay :value="loading" :absolute="true">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-simple-table dense>
      <template v-slot:default>
        <tbody>
        <tr v-if="loadId">
          <td class="text-left">#</td>
          <td class="text-left">
            <span v-if="isDriver">{{loadId}}</span>
            <a @click="$root.addNewPageTab('/loads/page/' + loadId)" v-else>{{loadId}}</a>
          </td>
        </tr>
        <tr v-if="fields.parent && !isDriver">
          <td class="text-left">{{$t('Loads.parentLoad')}}</td>
          <td class="text-left text-no-wrap">
            {{fields.parentLoadTitle}}
            <br/>
            <template>
              <a @click="$root.route('/loads/page/'+parentLoadId)">{{fields.parentLoadUID}}</a>
              <v-btn small icon @click="$root.addNewPageTab('/loads/page/' + fields.parentLoadId)"><v-icon small class="mr-1">mdi-open-in-new</v-icon></v-btn>
            </template>
          </td>
        </tr>
        <tr v-if="fields.uid">
          <td class="text-left">{{$t('Loads.UID')}}</td>
          <td class="text-left text-no-wrap">
            {{fields.uid}} <v-btn small icon @click.stop="$root.copyToClipboard(fields.uid)"><v-icon small>mdi-content-copy</v-icon></v-btn>
          </td>
        </tr>
        <tr v-if="fields.bid && !isDriver">
          <td class="text-left">{{$t('Loads.Bid')}}</td>
          <td class="text-left text-no-wrap">
            <span v-if="isDriver">{{fields.bidSubject}}</span>
            <template v-else>
              <a v-if="fields.bid" @click="$root.route('/bids/edit/'+fields.bid)">{{fields.bidSubject}}</a>
              <span v-else v-html="'&mdash;'"></span>
              <v-btn v-if="fields.bid" small icon @click="$root.addNewPageTab('/bids/edit/'+fields.bid)"><v-icon small class="mr-1">mdi-open-in-new</v-icon></v-btn>
            </template>
          </td>
        </tr>
        <tr v-if="fields.dispatcher">
          <td class="text-left">{{$t('Loads.Dispatcher')}}</td>
          <td class="text-left text-no-wrap">
            <span v-if="isDriver">{{fields.dispatcherName}}</span>
            <template v-else>
              <a v-if="fields.dispatcher" @click="$root.route('/user/page/'+fields.dispatcher)">{{fields.dispatcherName}}</a>
              <span v-else v-html="'&mdash;'"></span>
              <v-btn v-if="fields.dispatcher" small icon @click="$root.addNewPageTab('/user/page/'+fields.dispatcher)"><v-icon small class="mr-1">mdi-open-in-new</v-icon></v-btn>
            </template>
          </td>
        </tr>
        <tr v-if="fields.broker">
          <td class="text-left">{{$t('Loads.Broker')}}</td>
          <td class="text-left text-no-wrap">
            <span v-if="isDriver">{{fields.brokerName}}</span>
            <template v-else>
              <a v-if="fields.broker" @click="$root.route('/user/page/'+fields.broker)">{{fields.brokerName}}</a>
              <span v-else v-html="'&mdash;'"></span>
              <v-btn v-if="fields.broker" small icon @click="$root.addNewPageTab('/user/page/'+fields.broker)"><v-icon small class="mr-1">mdi-open-in-new</v-icon></v-btn>
            </template>
          </td>
        </tr>
        <tr v-if="fields.truck">
          <td class="text-left">{{$t('Loads.Truck')}}</td>
          <td class="text-left text-no-wrap">
            <template v-for="truck in loadTrucks">
              <span :key="'truck' + truck.id"
                    v-if="isDriver">{{truck.name}}</span>
              <div :key="'truck' + truck.id" v-else>
                <a @click="$root.route('/trucks/page/'+truck.id)">{{truck.name}}</a>
                <v-btn small icon @click="$root.addNewPageTab('/trucks/page/'+truck.id)"><v-icon small class="mr-1">mdi-open-in-new</v-icon></v-btn>
              </div>
              <br v-if="loadTrucks && loadTrucks.length > 1"/>
            </template>
          </td>
        </tr>
        <tr>
          <td class="text-left">{{$t('Loads.Driver')}}</td>
          <td class="text-left text-no-wrap">
            <template v-for="driver in loadDrivers">
              <span :key="'driver' + driver.id"
                    v-if="isDriver">{{driver.fullName}}</span>
              <div :key="'driver' + driver.id" v-else>
                <a @click="$root.route('/user/page/'+driver.id)">{{driver.fullName}}</a>
                <v-btn small icon @click="$root.addNewPageTab('/user/page/'+driver.id)"><v-icon small class="mr-1">mdi-open-in-new</v-icon></v-btn>
              </div>
              <br v-if="loadDrivers && loadDrivers.length > 1"/>
            </template>
          </td>
        </tr>
        <tr v-if="fields.driverOO">
          <td class="text-left">{{$t('Loads.Driver')}}</td>
          <td class="text-left text-no-wrap">
            <span v-if="isDriver">{{fields.driverOOName}}</span>
            <template v-else>
              <a v-if="fields.driverOO" @click="$root.route('/user/page/'+fields.driverOO)">{{fields.driverOOName}}</a>
              <span v-else v-html="'&mdash;'"></span>
              <v-btn v-if="fields.driverOO" small icon @click="$root.addNewPageTab('/user/page/'+fields.driverOO)"><v-icon small class="mr-1">mdi-open-in-new</v-icon></v-btn>
            </template>
          </td>
        </tr>

        <tr v-if="fields.isDriverOwnerOperator">
          <td class="text-left">{{$t('Loads.isDriverOwnerOperator')}}</td>
          <td class="text-left">{{fields.isDriverOwnerOperator === 1 ? $t('Yes') : $t('No')}}</td>
        </tr>

        <tr v-if="fields.isDriverOwnerOperator === 1 && $root.isFullFinancialDataAllowedToView">
          <td class="text-left">{{$t('Loads.driverOwnerOperatorPercent')}}</td>
          <td class="text-left">%{{fields.driverOwnerOperatorPercent}}</td>
        </tr>
        <tr v-if="fields.isDriverOwnerOperator === 1 && $root.isFullFinancialDataAllowedToView">
          <td class="text-left">{{$t('Loads.driverOwnerOperatorBonus')}}</td>
          <td class="text-left">${{fields.driverOwnerOperatorBonus}}</td>
        </tr>
        <!--isLocalLoad-->
 <!--       <tr v-if="fields.isLocalLoad">>
          <td class="text-left">{{$t('Loads.isLocalLoad')}}</td>
          <td class="text-left">{{fields.isLocalLoad === 1 ? $t('Yes') : $t('No')}}</td>
        </tr>
        <tr v-if="fields.driver > 0 && fields.isLocalLoad === 1 && fields.driverLocalRate">
          <td class="text-left">{{$t('Loads.driverLocalRate')}}</td>
          <td class="text-left">${{fields.driverLocalRate}}</td>
        </tr>
        <tr v-if="fields.codriver > 0 && fields.isLocalLoad === 1 && fields.codriverLocalRate">
          <td class="text-left">{{$t('Loads.codriverLocalRate')}}</td>
          <td class="text-left">${{fields.codriverLocalRate}}</td>
        </tr>
        &lt;!&ndash;isAllMiles&ndash;&gt;
        <tr v-if="fields.driver > 0 && fields.isAllMiles === 1 && fields.driverAllMilesRate">
          <td class="text-left">{{$t('Loads.driverAllMilesRate')}}</td>
          <td class="text-left">${{fields.driverAllMilesRate}}</td>
        </tr>
        <tr v-if="fields.codriver > 0 && fields.isAllMiles === 1 && fields.codriverAllMilesRate">
          <td class="text-left">{{$t('Loads.codriverAllMilesRate')}}</td>
          <td class="text-left">${{fields.codriverAllMilesRate}}</td>
        </tr>
        &lt;!&ndash;isZipToZipMiles&ndash;&gt;
        <tr v-if="fields.driver > 0 && fields.isZipToZipMiles === 1 && fields.driverZipToZipRate">
          <td class="text-left">{{$t('Loads.driverZipToZipRate')}}</td>
          <td class="text-left">${{fields.driverZipToZipRate}}</td>
        </tr>
        <tr v-if="fields.codriver > 0 && fields.isZipToZipMiles === 1 && fields.codriverZipToZipRate">
          <td class="text-left">{{$t('Loads.codriverZipToZipRate')}}</td>
          <td class="text-left">${{fields.codriverZipToZipRate}}</td>
        </tr>

        &lt;!&ndash;isEmptyMiles&ndash;&gt;
        <tr v-if="fields.driver > 0 && fields.isEmptyMiles === 1 && fields.driverEmptyRate">
          <td class="text-left">{{$t('Loads.driverEmptyRate')}}</td>
          <td class="text-left">${{fields.driverEmptyRate}}</td>
        </tr>
        <tr v-if="fields.codriver > 0 && fields.isEmptyMiles === 1 && fields.codriverEmptyRate">
          <td class="text-left">{{$t('Loads.codriverEmptyRate')}}</td>
          <td class="text-left">${{fields.codriverEmptyRate}}</td>
        </tr>

        &lt;!&ndash;Default Rates&ndash;&gt;
        <tr v-if="fields.driver > 0 && fields.isLocalLoad === 0 && fields.isAllMiles === 0 && fields.isZipToZipMiles === 0 && fields.isEmptyMiles === 0 && fields.driverRate">
          <td class="text-left">{{$t('Loads.driverRate')}}</td>
          <td class="text-left">${{fields.driverRate}}</td>
        </tr>
        <tr v-if="fields.codriver > 0 && fields.isLocalLoad === 0 && fields.isAllMiles === 0 && fields.isZipToZipMiles === 0 && fields.isEmptyMiles === 0 && fields.codriverRate">
          <td class="text-left">{{$t('Loads.codriverRate')}}</td>
          <td class="text-left">${{fields.codriverRate}}</td>
        </tr>-->

        <tr v-if="fields.isDedicatedTruck">
          <td class="text-left">{{$t('Loads.isDedicatedTruck')}}</td>
          <td class="text-left">{{fields.isDedicatedTruck === 1 ? $t('Yes') : $t('No')}}</td>
        </tr>
        <tr v-if="fields.isPartial === 1 && $root.isFullFinancialDataAllowedToView">
          <td class="text-left">{{$t('Loads.dispatcherPartialRate')}}</td>
          <td class="text-left">${{fields.dispatcherPartialRate}}</td>
        </tr>

        <tr v-if="fields.isFactoring && $root.isFullFinancialDataAllowedToView">
          <td class="text-left">{{$t('Loads.isFactoring')}}</td>
          <td class="text-left">{{fields.isFactoring === 1 ? $t('Yes') : $t('No')}}</td>
        </tr>
        <tr v-if="fields.isDriverAssist">
          <td class="text-left">{{$t('Loads.isDriverAssist')}}</td>
          <td class="text-left">{{fields.isDriverAssist === 1 ? $t('Yes') : $t('No')}}</td>
        </tr>
        <tr v-if="fields.isFactoring && fields.factoringPercent && $root.isFullFinancialDataAllowedToView">
          <td class="text-left">{{$t('User.Fields.brokerWithFactoringSWITCH')}}</td>
          <td class="text-left">{{fields.factoringPercent}}%</td>
        </tr>
        <tr v-if="fields.dispatcherPaymentPercent && $root.isFullFinancialDataAllowedToView">
          <td class="text-left">{{$t('Loads.dispatcherPaymentPercent')}}</td>
          <td class="text-left">{{fields.dispatcherPaymentPercent}}%</td>
        </tr>
        <tr v-if="fields.dispatcherPaymentPercentWithOO && $root.isFullFinancialDataAllowedToView">
          <td class="text-left">{{$t('Loads.dispatcherPaymentPercentWithOO')}}</td>
          <td class="text-left">{{fields.dispatcherPaymentPercentWithOO}}%</td>
        </tr>
        <tr v-if="fields.dispatcherPaymentPercentFromBonus && $root.isFullFinancialDataAllowedToView">
          <td class="text-left">{{$t('Loads.dispatcherPaymentPercentFromBonus')}}</td>
          <td class="text-left">{{fields.dispatcherPaymentPercentFromBonus}}%</td>
        </tr>
        <tr v-if="fields.Rate">
          <td class="text-left">{{$t('Loads.Rate')}}</td>
          <td class="text-left">${{new Number(fields.Rate).toLocaleString()}}</td>
        </tr>
        <tr v-if="fields.RTLRate">
          <td class="text-left">{{$t('Loads.RTLRate')}}</td>
          <td class="text-left">${{new Number(fields.RTLRate).toLocaleString()}}</td>
        </tr>
        <tr v-if="fields.RateTONU">
          <td class="text-left">{{$t('Loads.RateTONU')}}</td>
          <td class="text-left">${{new Number(fields.RateTONU).toLocaleString()}}</td>
        </tr>
        <tr v-if="fields.distanceInMiles">
          <td class="text-left">{{$t('Loads.distanceInMiles')}}</td>
          <td class="text-left">{{new Number(fields.distanceInMiles).toLocaleString()}} {{$t('milesShort')}}</td>
        </tr>
        <tr v-if="fields.started">
          <td class="text-left">{{$t('Loads.Started')}}</td>
          <td class="text-left">{{$root.getDateTimeStringFromTimestamp(fields.started)}}</td>
        </tr>
        <tr v-if="fields.ended">
          <td class="text-left">{{$t('Loads.Ended')}}</td>
          <td class="text-left">{{$root.getDateTimeStringFromTimestamp(fields.ended)}}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import fieldAccessRightMixin from '../mixins/fieldAccessRightsMixin';

export default {
  name: 'LoadInformation',
  props: ['loadId'],
  components: {
  },
  mixins: [fieldAccessRightMixin],
  data: function () {
    return {
      loading: false,
      error: false,
      fields: {},
      yesColor: '#4caf50',
      noColor: '#f44336',
      globalSettingsFields: {},
      oilReplacementHistory: [],
      loadDrivers: [],
      loadTrucks: []
    };
  },
  computed: {
    isDriver () {
      return this.$store.getters.role === 'Driver' || this.$store.getters.role === 'DriverOwnerOperator';
    }
  },
  watch: {
  },
  mounted () {
    this.$nextTick(() => {
      this.fetchData();
    });
  },
  methods: {
    fetchData () {
      if (!this.isLoadsViewAllowed) {
        this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
        this.loading = false;
      } else if (this.loadId) {
        this.loading = true;
        this.$root.getLoad(this.loadId).then((response) => {
          if (response.status === 'ok') {
            this.loadDrivers = response.loadDrivers;
            this.loadTrucks = response.loadTrucks;
            this.fields = response.result;
          }
          this.loading = false;
        });
      }
    }
  }

};
</script>
