<template>
  <div class="ma-1">
    <v-layout v-if="$vuetify.breakpoint.xs">
      <v-flex xs4 class="text-right">
        <v-btn :disabled="page <= 1" @click="prev"><v-icon>mdi-chevron-left</v-icon></v-btn>
      </v-flex>
      <v-flex xs4 class="text-center">
        <v-select v-model="page"
          :items="items"
          @change="pageChanged"
          outlined dense>
        </v-select>
      </v-flex>
      <v-flex xs4 class="text-left">
        <v-btn :disabled="page >= Math.ceil(itemsCount / itemsPerPage)" @click="next"><v-icon>mdi-chevron-right</v-icon></v-btn>
      </v-flex>
    </v-layout>
    <v-pagination v-if="!$vuetify.breakpoint.xs"
                v-model="page"
                @input="pageChanged"
                :length="Math.ceil(itemsCount/itemsPerPage)"
                total-visible="11"></v-pagination>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: ['itemsCount', 'itemsPerPage', 'value'],
  data () {
    return {
      page: 1
    }
  },
  computed: {
    items () {
      return [...Array(Math.ceil(this.itemsCount / this.itemsPerPage)).keys()].map(i => i + 1);
    }
  },
  mounted () {
    this.page = this.value;
  },
  methods: {
    pageChanged (event) {
      this.page = event;
      this.$emit('page-changed', this.page);
    },
    next () {
      if (this.page < Math.ceil(this.itemsCount / this.itemsPerPage)) {
        this.page += 1
        this.$emit('page-changed', this.page);
      }
    },
    prev () {
      if (this.page > 0) {
        this.page -= 1;
        this.$emit('page-changed', this.page);
      }
    }
  }
}
</script>
