export default {
  data: function () {
  },
  methods: {
    getDashboard () {
      return this.$root.get('/api/dashboard/get');
    },
    getCRMDashboard () {
      return this.$root.get('/api/dashboard/crm_dashboard');
    },
    saveDashboard (layout) {
      return this.$root.post('/api/dashboard/save', { layout: layout });
    }
  }
};
