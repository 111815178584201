<template>
  <div>
    <v-chip small v-if="item.resendRequired === 1" class="text-caption ml-2 info"><v-icon small class="white--text" left>mdi-email-sync</v-icon>{{$t('Invoices.ResendRequired')}}</v-chip>
    <template v-if="item.status !== 'Underpaid' && item.receivedDate > 0 && item.amountReceived > 0">
      <v-alert type="success" class="ma-1 caption text-no-wrap" dense border="left">
        {{$t('Invoices.Paid')}}<br/>{{item.receivedDatePST}}
        <template v-if="item.factoring < 0">
          <br/>
          <v-chip small class="ml-2 text-caption warning">{{$t('Invoices.BilledDirectly')}}</v-chip>
        </template>
      </v-alert>
    </template>
    <template v-else-if="item.status === 'Waiting'">
      <v-alert type="warning" class="ma-1 caption text-no-wrap" dense border="left">
        <template v-slot:prepend>
          <v-icon color="white" class="ma-1">mdi-alert</v-icon>
        </template>
        {{$t('Invoices.Waiting')}}
        <template v-if="item.factoring < 0">
          <br/>
          <v-chip small class="ml-2 text-caption warning">{{$t('Invoices.BilledDirectly')}}</v-chip>
        </template>
      </v-alert>
    </template>
    <template v-else-if="item.status === 'Underpaid' && item.receivedDate > 0 && item.amountReceived > 0">
      <v-alert type="success" class="ma-1 caption text-no-wrap" dense border="left">
        <template v-slot:prepend>
          <v-icon color="error" class="ma-1">mdi-alert</v-icon>
        </template>
        {{$t('Invoices.PartiallyPaid')}}
        <template v-if="item.factoring < 0">
          <br/>
          <v-chip small class="ml-2 text-caption warning">{{$t('Invoices.BilledDirectly')}}</v-chip>
        </template>
      </v-alert>
    </template>
    <template v-else-if="item.status === 'Not Paid' && item.receivedDate > 0">
      <v-alert type="error" class="ma-1 caption text-no-wrap" dense border="left">Not Paid</v-alert>
      <template v-if="item.factoring < 0">
        <br/>
        <v-chip small class="ml-2 text-caption warning">{{$t('Invoices.BilledDirectly')}}</v-chip>
      </template>
    </template>
    <template v-else-if="item.status === 'Sent' && ((item.dueLeftSeconds > (7 * 24 * 60 * 60) && item.factoring > 0) || (item.dueLeftSeconds > 0 && item.factoring < 0))">
      <v-alert type="info" class="ma-1 caption text-no-wrap" dense border="left" icon="mdi-send-check" :style="$root.isMobile ? alertMobileStyle : {}">
        <template v-slot:prepend>
          <v-icon color="white" class="mr-3">mdi-email-send</v-icon>
        </template>
        {{$t('Invoices.Sent')}}<span v-if="item.factoring < 0">&nbsp;&nbsp;&nbsp;{{$t('Invoices.DueDate')}}:+{{Math.ceil($root.secondsToHms(item.dueLeftSeconds).hours / 24)}} {{$t('daysShort')}}</span>
        <template v-if="item.factoring < 0">
          <br/>
          <v-chip small class="text-caption warning">{{$t('Invoices.BilledDirectly')}}</v-chip>
        </template>
      </v-alert>
    </template>
    <template v-else-if="item.lastTroubleShootingCommentWithFlag && item.lastTroubleShootingCommentWithFlag.flag === $store.getters.troubleShootingFlags.VERIFIED">
      <v-alert class="ma-1 caption text-no-wrap" light dense border="left" :style="$root.isMobile ? alertMobileStyle : {}">
        <template v-slot:prepend>
          <v-icon color="success" class="mr-3">mdi-check-decagram</v-icon>
        </template>
        {{$t('Invoices.Verified')}}
        <template v-if="item.factoring < 0">
          <br/>
          <v-chip small class="ml-2 text-caption warning">{{$t('Invoices.BilledDirectly')}}</v-chip>
        </template>
      </v-alert>
    </template>
    <template v-else-if="item.lastTroubleShootingCommentWithFlag && item.lastTroubleShootingCommentWithFlag.flag === $store.getters.troubleShootingFlags.REFINE">
      <v-alert class="ma-1 caption text-no-wrap" light dense border="left" :style="$root.isMobile ? alertMobileStyle : {}">
        <template v-slot:prepend>
          <v-icon color="error" class="mr-3">mdi-alert-decagram</v-icon>
        </template>
        {{$t('Invoices.Refine')}}
        <template v-if="item.factoring < 0">
          <br/>
          <v-chip small class="ml-2 text-caption warning">{{$t('Invoices.BilledDirectly')}}</v-chip>
        </template>
      </v-alert>
    </template>
    <template v-else-if="item.lastTroubleShootingCommentWithFlag && item.lastTroubleShootingCommentWithFlag.flag === $store.getters.troubleShootingFlags.REFINED">
      <v-alert class="ma-1 caption text-no-wrap" light dense border="left" :style="$root.isMobile ? alertMobileStyle : {}">
        <template v-slot:prepend>
          <v-icon color="warning" class="mr-3">mdi-check-decagram</v-icon>
        </template>
        {{$t('Invoices.Refined')}}
        <template v-if="item.factoring < 0">
          <br/>
          <v-chip small class="ml-2 text-caption warning">{{$t('Invoices.BilledDirectly')}}</v-chip>
        </template>
      </v-alert>
    </template>
    <template v-else-if="item.status === 'New' || item.status === 'new'">
      <v-alert class="ma-1 caption text-no-wrap" light dense border="left" :style="$root.isMobile ? alertMobileStyle : {}">
        <template v-slot:prepend>
          <v-icon class="mr-3">mdi-new-box</v-icon>
        </template>
        {{$t('Invoices.Created')}}
        <template v-if="item.factoring < 0">
          <br/>
          <v-chip small class="ml-2 text-caption warning">{{$t('Invoices.BilledDirectly')}}</v-chip>
        </template>
      </v-alert>
    </template>
    <template v-else-if="item.status === 'Ready' || item.status === 'ready'">
      <v-alert class="ma-1 caption text-no-wrap" light dense border="left" :style="$root.isMobile ? alertMobileStyle : {}">
        <template v-slot:prepend>
          <v-icon color="primary" class="mr-3">mdi-check-decagram</v-icon>
        </template>
        {{$t('Invoices.Ready')}}
        <template v-if="item.factoring < 0">
          <br/>
          <v-chip small class="ml-2 text-caption warning">{{$t('Invoices.BilledDirectly')}}</v-chip>
        </template>
      </v-alert>
    </template>
    <template v-else-if="item.amountReceived === 0">
      <v-alert v-if="item.dueLeftSeconds < 0" type="error" class="ma-1 caption text-no-wrap" dense border="left" :style="$root.isMobile ? alertMobileStyle : {}">
        <span class="text-no-wrap">{{$t('Invoices.Overdue')}}:&nbsp;-{{Math.ceil($root.secondsToHms(item.dueLeftSeconds).hours / 24)}} {{$t('daysShort')}}</span>
        <br/>
        <span class="text-no-wrap">{{$t('Invoices.BrokerTerms')}} {{item.brokerDaysToPay}} {{$t('daysShort')}}</span>
        <template v-if="item.factoring < 0">
          <br/>
          <v-chip small class="ml-2 text-caption warning">{{$t('Invoices.BilledDirectly')}}</v-chip>
        </template>
      </v-alert>
      <v-alert v-if="item.dueLeftSeconds > 0" type="warning" class="ma-1 caption text-no-wrap" dense border="left" :style="$root.isMobile ? alertMobileStyle : {}">
        <span class="text-no-wrap">{{$t('Invoices.DueDate')}}:&nbsp;+{{Math.ceil($root.secondsToHms(item.dueLeftSeconds).hours / 24)}} {{$t('daysShort')}}</span>
        <template v-if="item.factoring < 0">
          <br/>
          <v-chip small class="ml-2 text-caption warning">{{$t('Invoices.BilledDirectly')}}</v-chip>
        </template>
      </v-alert>
    </template>
  </div>
</template>

<script>
export default {
  name: 'InvoiceStatusSnippet',
  props: ['item', 'receivedDate'],
  data: function () {
    return {
    };
  },
  computed: {
    alertMobileStyle () {
      return { height: '2rem', paddingTop: '0.2rem'};
    }
  },
  mounted () {
  },
  methods: {

  }
};
</script>
