<template>
  <div class="text-center">
    <UsersFormSaveCancelButtons v-if="isSaveAllowed && updatingEnabled"
                                :loading="isFieldLoading('saveBtn')"
                                @save="saveFields"
                                @cancel="$root.route('/user/page/' + userId)"/>
    <v-layout wrap>
      <v-flex xs12 md6 offset-md-3>
        <v-btn data-cy="backBtn" v-if="!updatingEnabled" style='width:100%;margin-bottom: 10px;' @click="$emit('back')">
          <v-icon>mdi-chevron-up</v-icon>{{$t('Back')}}
        </v-btn>
      </v-flex>
    </v-layout>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-layout>
        <v-flex xs12 md6 offset-md-3>
          <v-text-field
            data-cy="accountingManagerTitle"
            class="mb-2 ml-8"
            :disabled="isFieldReadOnly('accountingManagerTitle')"
            v-if="!isFieldHidden('accountingManagerTitle')"
            v-model="fields.accountingManagerTitle"
            :label="$t('User.Fields.accountingManagerTitle')"
            type="text"
            outlined hide-details dense></v-text-field>
          <v-layout wrap>
            <v-flex xs12 md6>
              <v-menu
                v-if="!isFieldHidden('accountingManagerWorkHoursStart')"
                ref="menu"
                v-model="menuStart"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="fields.accountingManagerWorkHoursStart"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    data-cy="accountingManagerWorkHoursStart"
                    class="mr-2 mb-1"
                    :disabled="isFieldReadOnly('accountingManagerWorkHoursStart')"
                    v-model="fields.accountingManagerWorkHoursStart"
                    :loading="isFieldLoading('accountingManagerWorkHoursStart')"
                    :label="$t('User.Fields.accountingManagerWorkHoursStart')"
                    prepend-icon="fa-user-clock"
                    readonly
                    v-on="on"
                    outlined hide-details dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menuStart"
                  v-model="fields.accountingManagerWorkHoursStart"
                  @click:minute="$refs.menu.save(fields.accountingManagerWorkHoursStart)"
                ></v-time-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 md6>
              <v-menu
                v-if="!isFieldHidden('accountingManagerWorkHoursEnd')"
                ref="menu2"
                v-model="menuEnd"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="fields.accountingManagerWorkHoursEnd"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    data-cy="accountingManagerWorkHoursEnd"
                    class="ml-2 mb-2"
                    :disabled="isFieldReadOnly('accountingManagerWorkHoursEnd')"
                    v-model="fields.accountingManagerWorkHoursEnd"
                    :loading="isFieldLoading('accountingManagerWorkHoursEnd')"
                    :label="$t('User.Fields.accountingManagerWorkHoursEnd')"
                    readonly
                    v-on="on"
                    outlined hide-details dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menuEnd"
                  v-model="fields.accountingManagerWorkHoursEnd"
                  @click:minute="$refs.menu2.save(fields.accountingManagerWorkHoursEnd)"
                ></v-time-picker>
              </v-menu>
            </v-flex>
          </v-layout>

          <v-text-field
            data-cy="accountingManagerEmail"
            class="mb-2"
            :disabled="isFieldReadOnly('accountingManagerEmail')"
            v-if="!isFieldHidden('accountingManagerEmail')"
            v-model="fields.accountingManagerEmail"
            :label="$t('User.Fields.accountingManagerEmail')"
            :rules="!requiredEnabled?[]:$root.emailRules"
            :required="requiredEnabled?true:null"
            prepend-icon="fa-envelope"
            type="text"
            outlined hide-details dense></v-text-field>
          <!-- UNIVERSAL USER FIELDS -->

          <v-text-field
            data-cy="phoneNumber"
            class="mb-2"
            :disabled="isFieldReadOnly('phoneNumber')"
            v-if="!isFieldHidden('phoneNumber')"
            v-model="fields.phoneNumber"
            :label="$t('User.Fields.phoneNumber')"
            prepend-icon="fa-phone"
            type="text"
            outlined hide-details dense></v-text-field>

          <v-text-field
            data-cy="SSN"
            class="mb-2"
            :disabled="isFieldReadOnly('SSN')"
            v-if="!isFieldHidden('SSN')"
            v-model="fields.SSN"
            :label="$t('User.Fields.SSN')"
            prepend-icon="fa-id-card"
            :append-icon="updatingEnabled ? (isFieldEncrypted('SSN') ? 'mdi-eye-off' : 'mdi-eye') : null"
            @click:append="decryptField('SSN',$route.params.id)"
            :type="isFieldEncrypted('SSN') ? 'password' : 'text'"
            outlined hide-details dense></v-text-field>

          <v-textarea
            data-cy="accountingManagerEmailSignature"
            class="mb-2"
            :disabled="isFieldReadOnly('accountingManagerEmailSignature')"
            v-if="!isFieldHidden('accountingManagerEmailSignature')"
            v-model="fields.accountingManagerEmailSignature"
            :label="$t('User.Fields.accountingManagerEmailSignature')"
            prepend-icon="fa-signature"
            outlined hide-details></v-textarea>
          <v-textarea
            data-cy="paymentDetails"
            class="mb-2"
            :disabled="isFieldReadOnly('paymentDetails')"
            v-if="!isFieldHidden('paymentDetails')"
            v-model="fields.paymentDetails"
            :label="$t('User.Fields.paymentDetails')"
            prepend-icon="fa-file-invoice-dollar"
            outlined hide-details></v-textarea>
          <!--<FileUpload
            :disabled="isFieldReadOnly('accountingManagerCompanyLogoUPLOAD')"
            v-if="!isFieldHidden('accountingManagerCompanyLogoUPLOAD')"
            :image="true"
            :label="$t('User.Fields.accountingManagerCompanyLogoUPLOAD')"
            :url="accountingManagerCompanyLogoUPLOADUrl"
            :field="fields.accountingManagerCompanyLogoUPLOAD"
            :loading="isFieldLoading('accountingManagerCompanyLogoUPLOAD')"
            @change="fileUploaded($event, 'accountingManagerCompanyLogoUPLOAD', 'accountingManagerCompanyLogo')"
            @remove="fields.accountingManagerCompanyLogoUPLOAD='';accountingManagerCompanyLogoUPLOADUrl=''"></FileUpload>
          -->
        </v-flex>
      </v-layout>
    </v-form>
    <UsersFormSaveCancelButtons v-if="isSaveAllowed && updatingEnabled"
                                :loading="isFieldLoading('saveBtn')"
                                @save="saveFields"
                                @cancel="$root.route('/user/page/' + userId)"/>
  </div>
</template>

<script>
import UsersFormSaveCancelButtons from '../../components/UsersFormSaveCancelButtons.vue';
import fieldLoadingMixin from '../../mixins/fieldLoadingMixin';
import fieldAccessRightMixin from '../../mixins/fieldAccessRightsMixin';
import userFormsCommonMixin from '../../mixins/userFormsCommonMixin';

export default {
  components: { UsersFormSaveCancelButtons },
  mixins: [fieldLoadingMixin, fieldAccessRightMixin, userFormsCommonMixin],
  props: ['updatingEnabled', 'userId', 'isOwnSettings', 'isNew'],
  data: () => ({
    menuStart: false,
    menuEnd: false,
    valid: true,
    requiredEnabled: true,
    fields: {
      paymentDetails: '',
      paymentDetailsUPLOAD: '',
      agreementUPLOAD: '',
      passportUPLOAD: '',
      phoneNumber: '',
      SSN: '',
      resumeUPLOAD: '',
      W2FormUPLOAD: '',
      W9FormUPLOAD: '',
      accountingManagerTitle: '',
      accountingManagerEmail: '',
      accountingManagerEmailSignature: '',
      accountingManagerCompanyLogoUPLOAD: '',
      accountingManagerWorkHoursStart: '',
      accountingManagerWorkHoursEnd: ''
    },
    accountingManagerCompanyLogoUPLOADUrl: '',
    documentsTab: 0
  }),
  computed: {
  },
  mounted () {
    if (!this.isNew) {
      this.fetchDataForSettingsModal();
    }
  },
  methods: {
    isValid () {
      return this.$refs.form.validate();
    },
    next () {
      if (!this.$refs.form.validate()) {
        this.$root.toast(this.$i18n.t('FormValidationFailed'), { color: 'error' });
        return;
      }
      this.$emit('next');
    },
    setFields (fields, userRole, rights = null) {
      for (let key in this.fields) {
        this.fields[key] = fields[key] !== null ? fields[key] : '';
      }
      this.loadImages();
      this.applyRights(this.isOwnSettings ? '' : userRole, this.isOwnSettings ? 'settings' : 'users', { deleteUsersDocuments: 0 });
    }
  }
};
</script>
