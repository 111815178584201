<template>
  <div>
    <v-card class="pa-2 mt-1 mb-1" flat>
      <v-card-title v-if="!hideTitle">{{$t('User.UserInformation')}}</v-card-title>
      <v-simple-table dense class="ma-1" v-if="fields && fields.id">
        <template v-slot:default>
          <tbody>
          <tr v-if="!isFieldHidden('email') && fields && fields.email">
            <td>
              {{$t('User.Fields.email')}}
            </td>
            <td>
              <template v-if="fields.email.indexOf(',') > 0">
                <div v-for="email_val in fields.email.split(',')" :key="email_val">
                  {{email_val}}<v-btn :href="'mailto:'+email_val" small icon><v-icon small>fa-paper-plane</v-icon></v-btn>
                </div>
              </template>
              <template v-else-if="fields">
                {{fields.email}}<v-btn :href="'mailto:'+fields.email" small icon><v-icon small>fa-paper-plane</v-icon></v-btn>
              </template>
            </td>
          </tr>
          <tr v-if="fields.dob && !isFieldHidden('dob')">
            <td>{{$t('User.Fields.dob')}}</td>
            <td>{{$root.formatDate(fields.dob)}}</td>
          </tr>
          <tr v-if="fields.phoneNumber && !isFieldHidden('phoneNumber')">
            <td>{{$t('User.Fields.phoneNumber')}}</td>
            <td>{{fields.phoneNumber}}<v-btn :href="'tel:'+fields.phoneNumber" small icon><v-icon small color="success">fa-phone</v-icon></v-btn></td>
          </tr>
          <tr v-if="fields.SSN && !isFieldHidden('SSN')">
            <td>{{$t('User.Fields.SSN')}}</td>
            <td>
              {{fieldsEncrypted['SSN'] ? '******' : fields.SSN}}
              <v-btn small icon @click="decryptField('SSN', userId)" v-if="$root.isOnline">
                <v-icon small>{{fieldsEncrypted['SSN'] ? 'mdi-eye-off' : 'mdi-eye'}}</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr v-if="fields.paymentDetails && !isFieldHidden('paymentDetails')">
            <td>{{$t('User.Fields.paymentDetails')}}</td>
            <td>{{fields.paymentDetails}}</td>
          </tr>
          <template v-if="userRole === $store.getters.roleDriver">
            <tr v-if="fields.driverDrivingLicenseValidDATE && !isFieldHidden('driverDrivingLicenseValidDATE')">
              <td>{{$t('User.Fields.driverDrivingLicenseValidDATE')}}</td>
              <td>
                <v-chip small :color="isDateExpired(fields.driverDrivingLicenseValidDATE) ? 'danger' : 'success'">
                  {{fields.driverDrivingLicenseValidDATE.split('T')[0]}} {{isDateExpired(fields.driverDrivingLicenseValidDATE) ? $t('User.Expired'): daysLeft(fields.driverDrivingLicenseValidDATE)}}
                </v-chip>
              </td>
            </tr>
            <tr v-if="fields.driverMedicalCardValidDATE && !isFieldHidden('driverMedicalCardValidDATE')">
              <td>{{$t('User.Fields.driverMedicalCardValidDATE')}}</td>
              <td>
                <v-chip small :color="isDateExpired(fields.driverMedicalCardValidDATE) ? 'danger' : 'success'">
                  {{fields.driverMedicalCardValidDATE.split('T')[0]}} {{isDateExpired(fields.driverMedicalCardValidDATE) ? $t('User.Expired'):daysLeft(fields.driverMedicalCardValidDATE)}}
                </v-chip>
              </td>
            </tr>
          </template>
          <template v-if="userRole === $store.getters.roleDriver || userRole === $store.getters.roleDriverOwnerOperator">
            <tr>
              <td colspan="2">
                <v-subheader>{{$t('CreateUser.DriverInformation')}}</v-subheader>
              </td>
            </tr>
            <tr v-if="!isFieldHidden('driverTruckTypeAppliedFor')">
              <td>{{$t('User.Fields.TruckTypeAppliedFor')}}</td>
              <td>{{fields.driverTruckTypeAppliedFor || '-'}}</td>
            </tr>
            <tr v-if="!isFieldHidden('driverDrivingExperience')">
              <td>{{$t('User.Fields.DrivingExperience')}}</td>
              <td>{{fields.driverDrivingExperience || '-'}}</td>
            </tr>
            <tr v-if="!isFieldHidden('driverAccidentsInLast3Years') && fields.hasOwnProperty('driverAccidentsInLast3Years')">
              <td>{{$t('User.Fields.AccidentsInLast3Years')}}</td>
              <td>
                <v-chip small color="warning" v-if="fields.driverAccidentsInLast3Years">Yes</v-chip>
                <v-chip small color="success" v-else>No</v-chip>
              </td>
            </tr>
            <tr v-if="!isFieldHidden('driverOwnerOperatorPercent')">
              <td>{{$t('User.Fields.driverOwnerOperatorPercent')}}</td>
              <td>{{fields.driverOwnerOperatorPercent || '-'}} %</td>
            </tr>
            <tr v-if="!isFieldHidden('driverOwnerOperatorNameOfBank')">
              <td>{{$t('User.Fields.driverOwnerOperatorNameOfBank')}}</td>
              <td>{{fields.driverOwnerOperatorNameOfBank || '-'}}</td>
            </tr>
            <tr v-if="!isFieldHidden('driverOwnerOperatorBusinessName')">
              <td>{{$t('User.Fields.driverOwnerOperatorBusinessName')}}</td>
              <td>{{fields.driverOwnerOperatorBusinessName || '-'}}</td>
            </tr>
            <tr v-if="!isFieldHidden('driverOwnerOperatorAddress')">
              <td>{{$t('User.Fields.driverOwnerOperatorAddress')}}</td>
              <td>{{fields.driverOwnerOperatorAddress || '-'}}</td>
            </tr>
            <tr v-if="!isFieldHidden('driverOwnerOperatorAccount')">
              <td>{{$t('User.Fields.driverOwnerOperatorAccount')}}</td>
              <td>{{fields.driverOwnerOperatorAccount || '-'}}</td>
            </tr>
            <tr v-if="!isFieldHidden('driverOwnerOperatorRouting')">
              <td>{{$t('User.Fields.driverOwnerOperatorRouting')}}</td>
              <td>{{fields.driverOwnerOperatorRouting || '-'}}</td>
            </tr>
          </template>
          <template v-if="userRole === $store.getters.roleBroker">
            <tr v-if="!isFieldHidden('brokerBillTo')">
              <td>{{$t('User.Fields.brokerBillTo')}}</td>
              <td>{{fields.brokerBillTo || '-'}}</td>
            </tr>
            <tr v-if="!isFieldHidden('brokerDaysToPay')">
              <td>{{$t('User.Fields.brokerDaysToPay')}}</td>
              <td>{{fields.brokerDaysToPay || '-'}}</td>
            </tr>
            <tr v-if="!isFieldHidden('brokerWithFactoringSWITCH')">
              <td>{{$t('User.Fields.brokerFactoringPercent')}}</td>
              <td>{{fields.brokerFactoringPercent || '-'}} %</td>
            </tr>
            <tr v-if="!isFieldHidden('brokerFactoringPaymentDetails')">
              <td>{{$t('User.Fields.brokerFactoringPaymentDetails')}}</td>
              <td>{{fields.brokerFactoringPaymentDetails || '-'}}</td>
            </tr>
            <tr v-if="!isFieldHidden('brokerCompanyName')">
              <td>{{$t('User.Fields.brokerCompanyName')}}</td>
              <td>{{fields.brokerCompanyName || '-'}}</td>
            </tr>
            <tr v-if="!isFieldHidden('brokerContactPerson')">
              <td>{{$t('User.Fields.brokerContactPerson')}}</td>
              <td>{{fields.brokerContactPerson || '-'}}</td>
            </tr>
          </template>

          <template v-if="userRole === $store.getters.roleDispatcher">
            <tr v-if="(fields.dispatcherWorkHoursStart || fields.dispatcherWorkHoursEnd) && !isFieldHidden('dispatcherWorkHoursStart') && !isFieldHidden('dispatcherWorkHoursEnd')">
              <td>{{$t('User.WorkHours')}}</td>
              <td>
                <v-chip class="white--text font-weight-bold pa-1" :class="isWorkHours(fields.dispatcherWorkHoursStart,fields.dispatcherWorkHoursEnd) ? 'green accent-4' : 'red accent-4'">
                  {{fields.dispatcherWorkHoursStart.split(':')[0]}}:{{fields.dispatcherWorkHoursStart.split(':')[1]}} - {{fields.dispatcherWorkHoursEnd.split(':')[0]}}:{{fields.dispatcherWorkHoursEnd.split(':')[1]}}
                  {{isWorkHours(fields.dispatcherWorkHoursStart,fields.dispatcherWorkHoursEnd) ? $t('User.OnWork'):$t('User.OffWork')}}
                </v-chip>
              </td>
            </tr>
            <tr v-if="fields.dispatcherEmail && !isFieldHidden('dispatcherEmail')">
              <td>{{$t('User.Fields.dispatcherEmail')}}</td>
              <td>{{fields.dispatcherEmail}}<v-btn :href="'mailto:'+fields.dispatcherEmail" small icon><v-icon small>fa-paper-plane</v-icon></v-btn></td>
            </tr>
            <tr v-if="fields.dispatcherTitle && !isFieldHidden('dispatcherTitle')">
              <td>{{$t('User.Fields.dispatcherTitle')}}</td>
              <td>{{fields.dispatcherTitle}}</td>
            </tr>
            <tr v-if="fields.dispatcherPaymentPercent && !isFieldHidden('dispatcherPaymentPercent')">
              <td>{{$t('User.Fields.dispatcherPaymentPercent')}}</td>
              <td>{{fields.dispatcherPaymentPercent}} %</td>
            </tr>
            <tr v-if="fields.dispatcherPaymentPercentFromBonus && !isFieldHidden('dispatcherPaymentPercentFromBonus')">
              <td>{{$t('User.Fields.dispatcherPaymentPercentFromBonus')}}</td>
              <td>{{fields.dispatcherPaymentPercentFromBonus}} %</td>
            </tr>
            <tr v-if="fields.dispatcherPaymentPercentForPartialLoad && !isFieldHidden('dispatcherPaymentPercentForPartialLoad')">
              <td>{{$t('User.Fields.dispatcherPaymentPercentForPartialLoad')}}</td>
              <td>{{fields.dispatcherPaymentPercentForPartialLoad}} %</td>
            </tr>
            <tr v-if="fields.dispatcherPaymentPercentForLTLLoad && !isFieldHidden('dispatcherPaymentPercentForLTLLoad')">
              <td>{{$t('User.Fields.dispatcherPaymentPercentForLTLLoad')}}</td>
              <td>{{fields.dispatcherPaymentPercentForLTLLoad}} %</td>
            </tr>
          </template>

          <template v-if="userRole === $store.getters.roleAccountingManager">
            <tr v-if="(fields.accountingManagerWorkHoursStart || fields.accountingManagerWorkHoursEnd) && !isFieldHidden('accountingManagerWorkHoursStart')  && !isFieldHidden('accountingManagerWorkHoursEnd')">
              <td>{{$t('User.WorkHours')}}</td>
              <td :style="isWorkHours(fields.accountingManagerWorkHoursStart,fields.accountingManagerWorkHoursEnd)? 'color:#0f0':'color:#f00'">
                {{fields.accountingManagerWorkHoursStart.split(':')[0]}}:{{fields.accountingManagerWorkHoursStart.split(':')[1]}} - {{fields.accountingManagerWorkHoursEnd.split(':')[0]}}:{{fields.accountingManagerWorkHoursEnd.split(':')[1]}}
                {{isWorkHours(fields.accountingManagerWorkHoursStart,fields.accountingManagerWorkHoursEnd) ? $t('User.OnWork'):$t('User.OffWork')}}
              </td>
            </tr>
            <tr v-if="fields.accountingManagerEmail && !isFieldHidden('accountingManagerEmail')">
              <td>{{$t('User.Fields.accountingManagerEmail')}}</td>
              <td>{{fields.accountingManagerEmail}}<v-btn :href="'mailto:'+fields.accountingManagerEmail" small icon><v-icon small>fa-paper-plane</v-icon></v-btn></td>
            </tr>
            <tr v-if="fields.accountingManagerTitle && !isFieldHidden('accountingManagerTitle')">
              <td>{{$t('User.Fields.accountingManagerTitle')}}</td>
              <td>{{fields.accountingManagerTitle}}</td>
            </tr>
          </template>

          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import fieldAccessRightMixin from '../../mixins/fieldAccessRightsMixin';

export default {
  name: 'UserInformation',
  props: ['fields', 'isOwnSettings', 'userRole', 'hideTitle'],
  mixins: [fieldAccessRightMixin],
  data: function () {
    return {
    };
  },
  watch: {
  },
  mounted () {
    this.updatingEnabled = true;
    this.applyRights(this.isOwnSettings ? '' : this.userRole, this.isOwnSettings ? 'settings' : 'users', { });
  },
  methods: {
    isWorkHours (startTimeStr, endTimeStr) {
      if (startTimeStr.indexOf(':') === -1 || endTimeStr.indexOf(':') === -1) return false;

      let startTimeArray = startTimeStr.split(':');
      let endTimeArray = endTimeStr.split(':');

      const curDate = new Date(new Date().getTime() - (((7 * 60) - new Date().getTimezoneOffset()) * 60 * 1000));

      let startDate = new Date();
      startDate.setHours(parseInt(startTimeArray[0]));
      startDate.setMinutes(parseInt(startTimeArray[1]));

      let endDate = new Date();
      endDate.setHours(parseInt(endTimeArray[0]));
      endDate.setMinutes(parseInt(endTimeArray[1]));

      if (curDate.getTime() >= startDate.getTime() && curDate.getTime() <= endDate.getTime()) return true;
      return false;
    },
  }
};
</script>
