export default {
  data: function () {
    return {
      fieldsEncrypted: { SSN: true },
      fieldsReadOnly: {},
      fieldsHidden: {},
      fieldsDownloadAllowed: {},
      isAtLeastOneFieldAvailable: false
    };
  },
  computed: {
    isUserDashboardViewAllowed () {
      if (!this.$root.isOnline) return false;
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.menuRights['Dashboard']);
      }
      return false;
    },
    isUserDashboardEditAllowed () {
      if (!this.$root.isOnline) return false;
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.menuRights['Dashboard']) &&
          this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['Dashboard']);
      }
      return false;
    },
    isSaveAllowed () {
      if (!this.$root.isOnline) return false;
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['Settings']);
      }
      return false;
    },
    isTrucksViewAllowed () {
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.menuRights['Trucks']);
      }
      return false;
    },
    isTrucksEditAllowed () {
      if (!this.$root.isOnline) return false;
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['Trucks']);
      }
      return false;
    },
    isTrucksCreateAllowed () {
      if (!this.$root.isOnline) return false;
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['CreateTruck']);
      }
      return false;
    },
    isChartViewAllowed () {
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.menuRights['Charts']);
      }
      return false;
    },
    isChartEditAllowed () {
      if (!this.$root.isOnline) return false;
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['Charts']);
      }
      return false;
    },
    isChartCreateAllowed () {
      if (!this.$root.isOnline) return false;
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['CreateChart']);
      }
      return false;
    },
    isBidsViewAllowed () {
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.menuRights['Bids']);
      }
      return false;
    },
    isBidsEditAllowed () {
      if (!this.$root.isOnline) return false;
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['Bids']);
      }
      return false;
    },
    isBidsCreateAllowed () {
      if (!this.$root.isOnline) return false;
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['CreateBid']);
      }
      return false;
    },
    isLoadsViewAllowed () {
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.menuRights['Loads']);
      }
      return false;
    },
    isLoadsEditAllowed () {
      if (!this.$root.isOnline) return false;
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['Loads']);
      }
      return false;
    },
    isLoadsCreateAllowed () {
      if (!this.$root.isOnline) return false;
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['CreateLoad']);
      }
      return false;
    },
    isInvoicesViewAllowed () {
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.menuRights['Invoices']);
      }
      return false;
    },
    isInvoicesEditAllowed () {
      if (!this.$root.isOnline) return false;
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['Invoices']);
      }
      return false;
    },
    isInvoicesCreateAllowed () {
      if (!this.$root.isOnline) return false;
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['CreateInvoice']);
      }
      return false;
    },
    isGlobalSettingsViewAllowed () {
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.menuRights['GlobalSettings']);
      }
      return false;
    },
    isGlobalSettingsEditAllowed () {
      if (!this.$root.isOnline) return false;
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['GlobalSettings']);
      }
      return false;
    },
    isLogsViewAllowed () {
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.menuRights['Logs']);
      }
      return false;
    },
    isLogsEditAllowed () {
      if (!this.$root.isOnline) return false;
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['Logs']);
      }
      return false;
    },
    isMailsViewAllowed () {
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.menuRights['Mails']);
      }
      return false;
    },
    isNotificationsViewAllowed () {
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.menuRights['Notifications']);
      }
      return false;
    },
    isNotificationsCreateAllowed () {
      if (!this.$root.isOnline) return false;
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['CreateNotifications']);
      }
      return false;
    },
    isShiftsViewAllowed () {
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.menuRights['Shifts']);
      }
      return false;
    },
    isShiftsCreateAllowed () {
      if (!this.$root.isOnline) return false;
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['CreateShifts']);
      }
      return false;
    },
    isReportsViewAllowed () {
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.menuRights['Reports']);
      }
      return false;
    },
    isTasksViewAllowed () {
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.menuRights['Tasks']);
      }
      return false;
    },
    isTasksEditAllowed () {
      if (!this.$root.isOnline) return false;
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['Tasks']);
      }
      return false;
    },
    isTasksCreateAllowed () {
      if (!this.$root.isOnline) return false;
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['CreateTask']);
      }
      return false;
    },
    isLoadsFeedViewAllowed () {
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.menuRights['LoadsFeed']);
      }
      return false;
    },
    isDocumentsViewAllowed () {
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.menuRights['Documents']);
      }
      return false;
    }
  },
  methods: {
    isUserCreateAllowed (role = '') {
      if (!this.$root.isOnline) return false;
      if (role === '') {
        const roles = this.$store.getters.rolesList;
        let result = false;
        for (let i = 0; i < roles.length; i++) {
          if (this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['Create' + roles[i]])) {
            result = true;
            break;
          }
        }
        return result;
      }

      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['Create' + role]);
      }
      return false;
    },
    setEncryptedField (fieldName, value) {
      this.$set(this.fieldsEncrypted, fieldName, value);
    },
    isFieldEncrypted (fieldName) {
      if (!this.updatingEnabled) return false;
      if (this.fieldsEncrypted.hasOwnProperty(fieldName)) {
        if (!this.fields[fieldName] || this.fields[fieldName] === '') {
          return false;
        }
        return this.fieldsEncrypted[fieldName];
      }
      return false;
    },
    setReadOnlyField (fieldName, value) {
      this.$set(this.fieldsReadOnly, fieldName, value);
    },
    isFieldReadOnly (fieldName, overrideOfflineCheck = false) {
      if (!this.$root.isOnline && !overrideOfflineCheck) return true;
      if (!this.updatingEnabled) return false;
      if (this.fieldsReadOnly.hasOwnProperty(fieldName)) {
        return this.fieldsReadOnly[fieldName];
      }
      return true;
    },
    setDownloadAllowedField (fieldName, value) {
      this.$set(this.fieldsDownloadAllowed, fieldName, value);
    },
    isFieldDownloadAllowed (fieldName) {
      if (!this.updatingEnabled) return true;
      if (this.fieldsDownloadAllowed.hasOwnProperty(fieldName)) {
        return this.fieldsDownloadAllowed[fieldName];
      }
      return false;
    },
    setFieldHidden (fieldName, value) {
      this.$set(this.fieldsHidden, fieldName, value);
    },
    isFieldHidden (fieldName) {
      if (!this.updatingEnabled) return false;
      if (this.fieldsHidden.hasOwnProperty(fieldName)) {
        return this.fieldsHidden[fieldName];
      }
      return true;
    },
    // TODO refactor to fix this hack, make this fields shared between users or rename them with prefix of role name
    isCustomHacked (field) {
      if (field === 'assistants' || field === 'hiddenTrucks' || field === 'linkedToDispatcher') {
        return true;
      }
      return false;
    },
    isFieldBasic (field) {
      const basicFields = [
        'role',
        'password',
        'email',
        'firstName',
        'lastName',
        'avatarUPLOAD',
        'paymentDetails',
        'paymentDetailsUPLOAD',
        'agreementUPLOAD',
        'passportUPLOAD',
        'SSN',
        'phoneNumber',
        'resumeUPLOAD',
        'W2FormUPLOAD',
        'W9FormUPLOAD',
        'deleteUsersDocuments',
        'signature'
      ];
      return basicFields.find(function (element) { return element === field; }) !== undefined;
    },
    applyRightsBasic (role = '') {
      const isSetting = role === '';
      let fields = Object.assign({}, this.fields, { email: this.email, password: ''});
      for (let field in fields) {
        this.$root.getAccessRightsByField(
          isSetting ? 'settings' : 'users',
          isSetting ? field : role.charAt(0).toLowerCase() + role.slice(1) + field).then((response) => {
          let _r = this.$root.getAccessRightsArrayFromNumber(response.rights);
          this.setFieldHidden(field, parseInt(_r[0]) === 0 && parseInt(_r[1]) === 0);
          this.setReadOnlyField(field, parseInt(_r[0]) === 0 && parseInt(_r[2]) === 0);
        });
      }
    },
    /**
     * Apply right to user form filed, if role is set, the fields are actual for user with role
     * @param rights
     * @param role
     */
    applyRights (role = '', table = null, customFields = {}) {
      return new Promise((resolve, reject) => {
        let fieldName = '';
        const isSetting = role === '';
        const fieldRole = role === '' ? '' : role.charAt(0).toLowerCase() + role.slice(1);
        let totalFields = {};

        for (let field in this.fields) {
          totalFields[field] = this.fields[field];
        }
        for (let field in customFields) {
          totalFields[field] = this.fields[field] ? this.fields[field] : 0;
        }

        let tableName = table;
        let fieldsArray = [];

        if (table && table !== 'settings' && table !== 'users') {
          fieldsArray = Object.keys(totalFields);
        } else {
          if (!table) {
            tableName = isSetting ? 'settings' : 'users';
          }
          for (let field in totalFields) {
            if (isSetting || (!this.isFieldBasic(field) && !this.isCustomHacked(field))) {
              fieldsArray.push(field);
            } else {
              fieldsArray.push(fieldRole + field);
            }
          }
        }

        this.$root.getAccessRightsByFields(tableName, fieldsArray).then((response) => {
          if (response.status === 'ok') {
            for (fieldName in response.rights) {
              const rights = response.rights[fieldName];
              let _r = this.$root.getAccessRightsArrayFromNumber(rights);
              if (parseInt(_r[0]) === 0 && parseInt(_r[1]) === 1) {
                this.isAtLeastOneFieldAvailable = true;
              }
              // Remove role prefix from fieldName if available
              if (fieldName.indexOf(fieldRole) === 0 && this.isFieldBasic(fieldName.substr(fieldRole.length))) {
                fieldName = fieldName.substr(fieldRole.length);
              }
              // preset fields
              this.setFieldHidden(fieldName, true);
              this.setReadOnlyField(fieldName, true);
              this.setDownloadAllowedField(fieldName, false);

              if (parseInt(_r[0]) === 0 && parseInt(_r[1]) === 1) this.setFieldHidden(fieldName, false);
              if (parseInt(_r[0]) === 0 && parseInt(_r[2]) === 1) this.setReadOnlyField(fieldName, false);
              if (parseInt(_r[0]) === 0 && parseInt(_r[3]) === 1) this.setDownloadAllowedField(fieldName, true);
              //console.log(fieldName, _r, this.fieldsHidden[fieldName]);
            }
            this.$forceUpdate();
          }
          resolve();
        }).catch((e) => {
          reject(e);
        });

        /* for (let field in totalFields) {
          if (table) {
            this.$root.getAccessRightsByField(table, field).then((response) => {
              let _r = this.$root.getAccessRightsArrayFromNumber(response.rights);
              if (_r[0] === '0' && _r[1] === '1') {
                this.isAtLeastOneFieldAvailable = true;
              }
              if (_r[0] === '0' && _r[1] === '1') this.setFieldHidden(field, false);
              if (_r[0] === '0' && _r[2] === '1') this.setReadOnlyField(field, false);
              if (_r[0] === '0' && _r[3] === '1') this.setDownloadAllowedField(field, true);
            });
          } else {
            // If table not set use user fields
            this.$root.getAccessRightsByField(isSetting ? 'settings' : 'users', (isSetting || !this.isFieldBasic(field)) ? field : role.charAt(0).toLowerCase() + role.slice(1) + field).then((response) => {
              let _r = this.$root.getAccessRightsArrayFromNumber(response.rights);
              if (_r[0] === '0' && _r[1] === '1') {
                this.isAtLeastOneFieldAvailable = true;
              }
              if (_r[0] === '0' && _r[1] === '1') this.setFieldHidden(field, false);
              if (_r[0] === '0' && _r[2] === '1') this.setReadOnlyField(field, false);
              if (_r[0] === '0' && _r[3] === '1') this.setDownloadAllowedField(field, true);
            });
          }
        } */
        /* if (rights === null) return;
        rights.forEach((element) => {
          for (let k in this.fields) {
            let field = k;
            if (role !== '' && field.indexOf(role.toLowerCase()) !== 0) {
              field = role.toLowerCase() + field;
            }
            if (element.fieldName === field) {
              let _r = this.$root.getAccessRightsArrayFromNumber(element.userRights ? element.userRights : element.roleRights);
              if (_r[0] === '0' && _r[1] === '1') {
                this.isAtLeastOneFieldAvailable = true;
              }
              if (_r[0] === '0' && _r[1] === '0') this.setFieldHidden(field, true);
              if (_r[0] === '0' && _r[2] === '0') this.setReadOnlyField(field, true);
              if (_r[0] === '0' && _r[3] === '1') this.setDownloadAllowedField(field, true);
            }
          }
        }); */
      });
    }
  }
};
