<template>
  <div>
    <!--<v-autocomplete
      v-model="model"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      color="white"
      hide-no-data
      hide-selected
      item-text="Description"
      item-value="ID"
      label="Public APIs"
      placeholder="Start typing to Search"
      prepend-icon="mdi-database-search"
      return-object
      outlined
      dense
      @change="changed"
    >
      <template v-slot:prepend>
        <v-icon x-large style="width:40px">mdi-dolly</v-icon>
      </template>
    </v-autocomplete>-->
    <v-autocomplete
      style="width: 100%;"
      data-cy="cyLoadsAutocomplete"
      v-model="model"
      @change="changed"
      :search-input.sync="search"
      :label="label"
      :items="entries"
      hide-details
      item-text="Description"
      item-value="ID"
      :disabled="disabled"
      :prepend-icon="hidePrependIcon ? null : 'mdi-dolly'"
      @click:prepend="prependClick()"
      outlined dense>

      <template v-slot:prepend>
        <v-icon v-if="!hidePrependIcon"
                large
                style="width:40px" @click="prependClick()">mdi-dolly</v-icon>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  props: ['excludeLoad', 'value', 'trucksIds', 'hidePrependIcon', 'disabled', 'label'],
  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    model: null,
    search: null
  }),

  computed: {
    fields () {
      if (!this.model) return [];

      return Object.keys(this.model).map(key => {
        return {
          key,
          value: this.model[key] || 'n/a'
        };
      });
    },
    items () {
      return this.entries.map(entry => {
        const Description = entry.Description.length > this.descriptionLimit
          ? entry.Description.slice(0, this.descriptionLimit) + '...'
          : entry.Description;

        return Object.assign({}, entry, { Description });
      });
    }
  },

  watch: {
    search (val) {
      // Items have already been loaded
      if (this.items.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      this.$root.searchLoad(val, this.excludeLoad ? this.excludeLoad : 0).then((response) => {
        if (response && response.status === 'ok') {
          for (let i = 0; i < response.result.length; i++) {
            const _date = new Date(response.result[i].date);
            if (response.result[i].id) {
              this.entries.push({
                ID: response.result[i].id,
                Description: '#' + response.result[i].id + ' ' + this.$root.getDateTimeStringFromTimestamp(_date.getTime() / 1000) + ' ' + response.result[i].title + ' ' + response.result[i].uid
              });
            }
          }
        }
        this.isLoading = false;
      });
    }
  },
  mounted () {
    if (parseInt(this.value) > 0) {
      this.entries = [{ ID: 0, Description: 'N/A' }];
    }
    this.setValue();
    /* if (this.trucksIds && this.trucksIds.length > 0) {
        for(let t = 0; t < this.trucksIds.length; t++) {
            const truckId = this.trucksIds[t];
            this.$root.getLoadList (0, 0, 50, 0, 0, 0, 0, 0, false).then((response) => {
                if (response && response.status === 'ok') {
                    for (let i = 0; i < response.result.length; i++) {
                        const result = response.result[i];
                        const _date = new Date(result.date);
                        this.entries.push({
                            ID: result.id,
                            Description: '#' + result.id + ' ' + this.$root.getDateTimeStringFromTimestamp(_date.getTime() / 1000) + ' ' + result.title + ' ' + result.uid
                        });
                        if (this.value) {
                            if (parseInt(this.value) === parseInt(result.id)) {
                                this.model = result.id;
                            }
                        } else if (!this.model) {
                            this.model = result.id;
                            this.$emit('change', result.id);
                        }
                    }
                }
            });
        }
    } else {
        this.setValue();
    } */
  },
  methods: {
    prependClick () {
      this.$root.addNewPageTab('/loads/page/' + this.model);
      // window.open(window.location.origin + '/trucks/page/' + this.selectedTruck, '_blank');
    },
    setValue () {
      if (this.value) {
        this.isLoading = true;
        this.$root.getLoad(this.value).then((response) => {
          if (response && response.status === 'ok') {
            const _date = new Date(response.result.date);

            /* var isFound = this.entries.filter(obj => {
                      return parseInt(obj.ID) === parseInt(response.result.id)
                  });

                  if (!isFound) { */
            this.entries.push({
              ID: response.result.id,
              Description: '#' + response.result.id + ' ' + this.$root.getDateTimeStringFromTimestamp(_date.getTime() / 1000) + ' ' + response.result.title + ' ' + response.result.uid
            });
            // }
            this.model = response.result.id;
          }
          this.isLoading = false;
        });
      }
    },
    changed (value) {
      this.$emit('change', value);
    }
  }
};
</script>
