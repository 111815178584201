<template>
  <div>
    <v-expansion-panels v-if="$root.isMobile">
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{ $t('Shifts.InformationTitle') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table style="width: 100%;" dense>
        <template v-slot:default>
          <tbody>
            <tr v-if="Object.keys(item.shiftLoads).length > 1">
              <td>{{ $t('Shifts.Shift') }}</td>
              <td>#{{item.id}}</td>
            </tr>
            <tr>
              <td>{{ $t('Shifts.Load') }}</td>
              <td>
                <template v-for="shiftLoadId in Object.keys(item.shiftLoads)">
                  <a @click.stop="$root.onShowLoadPreview(shiftLoadId)" :key="'shiftLoad'+shiftLoadId">#{{shiftLoadId}}</a>&nbsp;
                </template>
              </td>
            </tr>
            <tr>
              <td>{{ $t('Shifts.Dispatcher') }}</td>
              <td>
                <v-layout>
                  <v-flex xs12>
                    <template v-for="shiftLoadId in Object.keys(item.shiftLoads)">
                      <v-tooltip bottom :key="'shiftLoadDispatcher'+shiftLoadId">
                        <template v-slot:activator="{ on }">
                          <v-avatar v-on="on"
                                    style="cursor: pointer;"
                                    class="ml-1"
                                    :size="24"
                                    @click.stop="$root.onShowUserPreview(item.shiftLoads[shiftLoadId].dispatcher, item.shiftLoads[shiftLoadId].dispatcherName)"
                                    :color="(item.shiftLoads[shiftLoadId].dispatcherInitials && !item.shiftLoads[shiftLoadId].dispatcherAvatar)?'#015aaa':null">
                            <v-img :src="$root.getImageUrl('avatar', item.shiftLoads[shiftLoadId].dispatcherAvatar)" v-if="item.shiftLoads[shiftLoadId].dispatcherAvatar"></v-img>
                            <span class="white--text" v-else-if="item.shiftLoads[shiftLoadId].dispatcherInitials">{{item.shiftLoads[shiftLoadId].dispatcherInitials}}</span>
                            <v-icon large v-else>account_circle</v-icon>
                          </v-avatar>
                        </template>
                        <span>{{item.shiftLoads[shiftLoadId].dispatcherName}}</span>
                      </v-tooltip>
                    </template>
                  </v-flex>
                </v-layout>
              </td>
            </tr>
            <tr>
              <td>{{ $t('Shifts.Driver') }}</td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-avatar v-on="on"
                              style="cursor: pointer;"
                              class="ml-1"
                              :size="24"
                              @click.stop="$root.onShowUserPreview(item.driverId, item.driverFullName)"
                              :color="(item.driverInitials && !item.driverAvatarUPLOAD)?'#015aaa':null">
                      <v-img :src="$root.getImageUrl('avatar', item.driverAvatarUPLOAD)" v-if="item.driverAvatarUPLOAD"></v-img>
                      <span class="white--text" v-else-if="item.driverInitials">{{item.driverInitials}}</span>
                      <v-icon large v-else>account_circle</v-icon>
                    </v-avatar>
                  </template>
                  <span>{{item.driverFullName}}</span>
                </v-tooltip>
              </td>
            </tr>
            <tr>
              <td>{{ $t('Shifts.Trucks') }}</td>
              <td>
                <template v-for="(truck, index) in item.trucksDistinctList">
                  <a href="#" class="block" @click.stop="$root.route('/trucks/page/'+truck.truck)" :key="truck.truckName+'_'+index">{{truck.truckName}}</a>
                  <br :key="truck.truckName+'_'+index + '_br'"/>
                </template>
              </td>
            </tr>
            <tr>
              <td>{{ $t('Shifts.Started')}}</td>
              <td>
                {{item.startedPST}}
              </td>
            </tr>
            <tr>
              <td>{{$t('Shifts.Ended') }}</td>
              <td>
                {{item.endedPST}}
              </td>
            </tr>
            <tr>
              <td>{{ $t('Shifts.Origin') }}</td>
              <td>
                {{item.startLocation}}
              </td>
            </tr>
            <tr>
              <td>{{$t('Shifts.Finish') }}</td>
              <td>
                {{item.endLocation}}
              </td>
            </tr>
            <tr>
              <td>{{ $t('Shifts.Duration') }}</td>
              <td>
                {{$root.showDuration(item.shiftDuration * 60, true)}}
              </td>
            </tr>
            <tr v-if="!item.isShift">
              <td>{{ $t('Shifts.DistanceByMaps') }}</td>
              <td>
                <v-icon left small>mdi-map</v-icon>{{parseInt(item.loadsMapDistance).toLocaleString()}}&nbsp;{{$t('milesShort')}}
              </td>
            </tr>
            <tr>
              <td>{{ $t('Shifts.DistanceByOdometer') }}</td>
              <td>
                <v-layout>
                  <v-flex shrink class="pt-1">
                    <v-icon left small>mdi-counter</v-icon>
                  </v-flex>
                  <v-flex shrink class="mt-1">
                    {{parseInt(item.isShift ? item.shiftDistance : item.loadsOdometerDistance).toLocaleString()}}&nbsp;{{$t('milesShort')}}
                  </v-flex>
                  <v-flex shrink class="mt-1">
                    <template v-if="Math.abs(item.loadsMapDistance - (item.isShift ? item.shiftDistance : item.loadsOdometerDistance)) > 0">
                      <v-chip x-small :class="item.loadsMapDistance - (item.isShift ? item.shiftDistance : item.loadsOdometerDistance) >= 0 ? 'ml-1 success' : 'ml-1 error'">
                        <template v-if="item.isShift">
                          <template v-if="item.loadsMapDistance - item.shiftDistance >= 0">-</template>
                          <template v-else>+</template>
                          {{Math.abs(parseInt(item.loadsMapDistance - item.shiftDistance)).toLocaleString()}}&nbsp;{{$t('milesShort')}}
                        </template>
                        <template v-else>
                          <template v-if="item.loadsMapDistance - item.loadsOdometerDistance >= 0">-</template>
                          <template v-else>+</template>
                          {{Math.abs(parseInt(item.loadsMapDistance - item.loadsOdometerDistance)).toLocaleString()}}&nbsp;{{$t('milesShort')}}
                        </template>
                      </v-chip>
                    </template>
                  </v-flex>
                </v-layout>
              </td>
            </tr>
            <tr>
              <td>{{ $t('Shifts.RatePerMile') }}</td>
              <td>${{Object.keys(item.driverRates).join(',$')}}</td>
            </tr>
            <tr>
              <td>{{ $t('Shifts.DriversDetention') }}</td>
              <td>
                <v-layout>
                  <v-flex shrink class="pt-1 mr-2">
                    {{item.driverDetentionFormatted}}
                  </v-flex>
                  <v-flex shrink class="pt-1">
                    ${{parseFloat(item.driverDetention.toFixed(2)).toLocaleString()}}
                  </v-flex>
                </v-layout>
              </td>
            </tr>
            <tr>
              <td>{{ $t('Shifts.CompensationsShort') }}</td>
              <td>${{parseFloat(item.shiftToCompensate.toFixed(2)).toLocaleString()}}</td>
            </tr>
            <tr>
              <td>{{ $t('Shifts.Deductions') }}</td>
              <td>${{parseFloat(item.shiftDeductions.toFixed(2)).toLocaleString()}}</td>
            </tr>
            <tr>
              <td>{{ $t('Shifts.Bonuses') }}</td>
              <td>${{parseFloat(item.shiftBonuses.toFixed(2)).toLocaleString()}}</td>
            </tr>
            <tr>
              <td>{{ $t('Shifts.Rate') }}</td>
              <td>${{item.isShift ? parseFloat(parseFloat(item.shiftRate).toFixed()).toLocaleString() : parseFloat(parseFloat(item.loadsOdometerRate).toFixed()).toLocaleString()}}</td>
            </tr>
            <tr>
              <td>{{ $t('Shifts.Salary') }}</td>
              <td>${{item.toPayAmount.toLocaleString()}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <template v-if="!$root.isMobile">
      <v-subheader><h4>{{ $t('Shifts.InformationTitle') }}</h4></v-subheader>
      <v-simple-table style="width: 100%;" dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">{{ $t('Shifts.Duration') }}</th>
              <th class="text-center">{{ $t('Shifts.Distance') }}</th>
              <th class="text-center">{{ $t('Shifts.RatePerMile') }}</th>
              <th class="text-center">{{ $t('Shifts.DriversDetention') }}</th>
              <th class="text-center">{{ $t('Shifts.CompensationsShort') }}</th>
              <th class="text-center">{{ $t('Shifts.Deductions') }}</th>
              <th class="text-center">{{ $t('Shifts.Bonuses') }}</th>
              <th class="text-center">{{ $t('Shifts.Rate') }}</th>
              <th class="text-center">{{ $t('Shifts.Salary') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">
                <h2>
                  {{$root.showDuration(item.shiftDuration * 60, true)}}
                </h2>
              </td>
              <td class="text-center">
                <template v-if="!item.isShift"><h2><v-icon left small>mdi-map</v-icon>{{parseInt(item.loadsMapDistance).toLocaleString()}}&nbsp;{{$t('milesShort')}}</h2></template>
                <template v-else><h2><v-icon left small>mdi-counter</v-icon>{{parseInt(item.isShift ? item.shiftDistance : item.loadsOdometerDistance).toLocaleString()}}&nbsp;{{$t('milesShort')}}</h2></template>
                <template v-if="Math.abs(item.loadsMapDistance - (item.isShift ? item.shiftDistance : item.loadsOdometerDistance)) > 0">
                  <v-chip x-small :class="item.loadsMapDistance - (item.isShift ? item.shiftDistance : item.loadsOdometerDistance) >= 0 ? 'ml-1 success' : 'ml-1 error'">
                    <template v-if="item.isShift">
                      <template v-if="item.loadsMapDistance - item.shiftDistance >= 0">-</template>
                      <template v-else>+</template>
                      {{Math.abs(parseInt(item.loadsMapDistance - item.shiftDistance)).toLocaleString()}}&nbsp;{{$t('milesShort')}}
                    </template>
                    <template v-else>
                      <template v-if="item.loadsMapDistance - item.loadsOdometerDistance >= 0">-</template>
                      <template v-else>+</template>
                      {{Math.abs(parseInt(item.loadsMapDistance - item.loadsOdometerDistance)).toLocaleString()}}&nbsp;{{$t('milesShort')}}
                    </template>
                  </v-chip>
                </template>
              </td>
              <td class="text-center">
                <h2>
                  ${{Object.keys(item.driverRates).join(',$')}}
                </h2>
              </td>
              <td class="text-center">
                <h2>
                  {{item.driverDetentionFormatted}}&nbsp;/&nbsp;${{parseFloat(item.driverDetention.toFixed(2)).toLocaleString()}}
                </h2>
              </td>
              <td class="text-center">
                <h2>
                  ${{parseFloat(item.shiftToCompensate.toFixed(2)).toLocaleString()}}
                </h2>
              </td>
              <td class="text-center">
                <h2>
                  ${{parseFloat(item.shiftDeductions.toFixed(2)).toLocaleString()}}
                </h2>
              </td>
              <td class="text-center">
                <h2>
                  ${{parseFloat(item.shiftBonuses.toFixed(2)).toLocaleString()}}
                </h2>
              </td>
              <td class="text-center">
                <h2>
                  ${{item.isShift ? parseFloat(parseFloat(item.shiftRate).toFixed()).toLocaleString() : parseFloat(parseFloat(item.loadsOdometerRate).toFixed()).toLocaleString()}}
                </h2>
              </td>
              <td class="text-center">
                <h2>
                  ${{item.toPayAmount.toLocaleString()}}
                </h2>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ShiftInfoTable',
  props: ['item', 'driverId'],
  mixins: [ ],
  components: {},
  data: function () {
    return {
    };
  },
  computed: {
  },
  mounted () {
  },
  methods: {
  }
};
</script>
