<template>
  <div class="text-center">
    <UsersFormSaveCancelButtons v-if="isSaveAllowed && updatingEnabled"
                              :loading="isFieldLoading('saveBtn')"
                              @save="saveFields"
                              @cancel="$root.route('/user/page/' + userId)"/>

    <v-form ref="form" v-model="valid" lazy-validation>
        <v-layout wrap>
          <v-flex xs12 md6 offset-md-3>
            <v-sheet class="pt-5 mb-2">
              <FileUpload
                dataCy="avatarUPLOADField"
                :image="true"
                :avatar="true"
                :label="$t('CreateUser.avatarUPLOAD')"
                :placeholder="$t('CreateUser.PickAnAvatar')"
                :url="avatarUPLOADUrl"
                :field="fields.avatarUPLOAD"
                :loading="isFieldLoading('avatarUPLOAD')"
                @change="fileUploaded($event,'avatarUPLOAD','avatar')"
                @remove="avatarUPLOAD='';avatarUPLOADUrl=''"
                v-if="!isFieldHidden('avatarUPLOAD')"
                :avatarInitials="fields.firstName && fields.lastName ? fields.firstName[0] + fields.lastName[0] : null"
                :disabled="isFieldReadOnly('avatarUPLOAD')"
                :readonly="isFieldReadOnly('avatarUPLOAD')">
              </FileUpload>
              <v-layout wrap>
                <v-flex xs12 :md6="role !== 'Broker' ? true : null" :md12="role === 'Broker' ? true : null">
                  <v-text-field
                    data-cy="firstNameField"
                    id="first_name_id"
                    v-model="fields.firstName"
                    :label="role !== 'Broker' ? $t('CreateUser.FirstName') : $t('User.Fields.brokerName')"
                    :rules="!requiredEnabled ? [] : $root.fieldRequiredRules"
                    :required="requiredEnabled ? true : null"
                    :disabled="isFieldReadOnly('firstName')"
                    v-if="!isFieldHidden('firstName')"
                    class="ma-1 mb-2"
                    outlined dense hide-details
                  />
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    data-cy="lastNameField"
                    id="last_name_id"
                    class="ma-1"
                    v-model="fields.lastName"
                    :label="$t('CreateUser.LastName')"
                    :rules="!requiredEnabled ? [] : $root.fieldRequiredRules"
                    :required="requiredEnabled ? true : null"
                    :disabled="isFieldReadOnly('lastName')"
                    v-if="!isFieldHidden('lastName') && role !== 'Broker'"
                    outlined dense hide-details
                  />
                </v-flex>
              </v-layout>
              <v-text-field
                class="ma-1 mb-2"
                data-cy="emailField"
                id="email_id"
                v-model="email"
                :label="emailAvailable ? $root.t('CreateUser.EMail') : $root.t('Validators.EmailAlreadyUsed')"
                :required="requiredEnabled ? true : null"
                :rules="!requiredEnabled ? [] : $root.emailRules"
                :loading="emailLoading"
                @change="emailChange('email')"
                :error="!emailAvailable"
                :disabled="isFieldReadOnly('email')"
                v-if="!isFieldHidden('email') && role !== 'Broker'"
                outlined
                dense
                hide-details
                autocomplete="off"
              />
              <div v-if="!isFieldHidden('role') && !isOwnSettings"
                   class="pl-1 pr-1">
                <SelectRole :role="fields.role"
                            :disabled="isFieldReadOnly('role')"
                            :showDriverOwnerOperator="true"
                            :dropdown="true"
                            :key="fetchKey"
                            @changed="onRoleChanged"/>
              </div>

              <template v-if="isShowPassword && role !== 'Broker'">
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field
                      v-if="role !== 'Broker' && (!updatingEnabled || (!isFieldReadOnly('password') && !isFieldHidden('password')))"
                      data-cy="passwordField"
                      id="password_id"
                      v-model="password"
                      class="ma-1"
                      :label="$t('CreateUser.Password')"
                      :rules="(requiredEnabled || updatingEnabled) ? passwordRules : []"
                      required
                      :hint="$tc('CreateUser.Validators.PasswordMustHaveAtLeastSymbols',minPasswordLength)"
                      counter
                      onCopy="return false" onDrag="return false" autocomplete="off"
                      :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="passwordShow ? 'text' : 'password'"
                      @click:append="passwordShow = !passwordShow"
                      outlined dense
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-if="role !== 'Broker' && (!updatingEnabled || (!isFieldReadOnly('password') && !isFieldHidden('password')))"
                      data-cy="passwordRepeatField"
                      id="password_repeat_id"
                      v-model="passwordRepeat"
                      class="ma-1"
                      :label="$t('CreateUser.PasswordRepeat')"
                      :rules="(requiredEnabled || updatingEnabled) ? passwordRepeatRules : []"
                      required
                      :hint="$tc('CreateUser.Validators.PasswordMustHaveAtLeastSymbols',minPasswordLength)"
                      counter
                      onCopy="return false" onDrag="return false" autocomplete="off"
                      :append-icon="passwordRepeatShow ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="passwordRepeatShow ? 'text' : 'password'"
                      @click:append="passwordRepeatShow = !passwordRepeatShow"
                      outlined dense
                    />
                  </v-flex>
                  <v-flex xs12 class="text-right">
                    <v-btn v-if="updatingEnabled && !isFieldReadOnly('password') && !isFieldHidden('password') && role !== 'Broker'"
                           :loading="updatePasswordLoading"
                           class="ma-1"
                           small
                           color="primary"
                           @click="updatePassword"><v-icon left>fa-save</v-icon>{{ isShowPassword ? $t('CreateUser.SavePassword') : $t('CreateUser.UpdatePassword')}}</v-btn>
                  </v-flex>
                </v-layout>
              </template>
              <template v-else>
                <!--<v-btn data-cy="generatePasswordBtn" v-if="!updatingEnabled && (!isFieldReadOnly('password') && !isFieldHidden('password') && role !== 'Broker')" @click="generatePassword">{{$t('CreateUser.GeneratePassword')}}</v-btn>-->
                <v-btn v-if="updatingEnabled && !isFieldReadOnly('password') && !isFieldHidden('password') && role !== 'Broker'"
                       :loading="updatePasswordLoading"
                       class="ma-1" small
                       @click="updatePassword">{{ isShowPassword ? $t('CreateUser.SavePassword') : $t('CreateUser.UpdatePassword')}}</v-btn>
              </template>

              <v-combobox v-if="!isFieldHidden('email') && role === 'Broker'"
                          prepend-icon="fa-envelope"
                          :label="$t('CreateUser.EMail')"
                          :disabled="isFieldReadOnly('email')"
                          :items="emails"
                          :loading="emailLoading"
                          multiple
                          dense
                          outlined
                          hint="Comma separated emails"
                          persistent-hint
                          v-model="emails"
                          @change="emailsChange"></v-combobox>

              <v-switch v-model="isOpenItemsInModal"
                inset
                class="ml-2 mt-0"
                hide-details
                v-if="!isFieldHidden('isOpenItemsInModal') && role !== 'Broker'"
                @change="onIsOpenItemsInModal"
                :disabled="isFieldReadOnly('isOpenItemsInModal')"
                :label="$t('User.Fields.isOpenItemsInModal')"
              />
            </v-sheet>
          </v-flex>
          <!--<v-flex xs12 md6>
            <v-card flat v-if="!isFieldHidden('signature')">
              <v-card-title>Signature</v-card-title>
              <canvas id="signaturePad" style="border: 2px dashed #ccc; border-radius: 10px;" width="300px" height="200px"/>
            </v-card>
          </v-flex>-->
        </v-layout>
        <v-layout v-if="this.$root.isMailsViewAllowed && !isNew">
          <v-flex xs12 md3 offset-md-3 class="pa-1">
            <h1>SMTP Settings</h1>
            <v-switch v-model="isSendEmailViaSMTP"
                      @change="() => { this.fields.isSendEmailViaSMTP = this.isSendEmailViaSMTP ? 1 : 0 }"
                      inset
                      class="ml-2"
                      label="Use SMTP for sending emails"/>
            <v-text-field v-model="fields.smtpHost" dense outlined hide-details class="mb-1" label="SMTP Host" :disabled="!isSendEmailViaSMTP"/>
            <v-text-field v-model="fields.smtpPort" dense outlined hide-details class="mb-1" number label="SMTP Port" :disabled="!isSendEmailViaSMTP"/>
            <v-switch v-model="smtpIsSecure"
                      class="ml-2"
                      @change="() => { this.fields.smtpIsSecure = this.smtpIsSecure ? 1 : 0 }"
                      inset
                      label="Use Secure connection"
                      :disabled="!isSendEmailViaSMTP"/>
            <v-text-field v-model="fields.smtpLogin" dense outlined hide-details class="mb-1" label="SMTP Login" :disabled="!isSendEmailViaSMTP"/>
            <v-text-field v-model="fields.smtpPassword" dense outlined hide-details type="password" class="mb-1" label="SMTP Password" :disabled="!isSendEmailViaSMTP"/>
          </v-flex>
          <v-flex xs12 md3 class="pa-1">
            <h1>IMAP Settings</h1>
            <v-switch v-model="isReceiveEmailViaIMAP"
                      @change="() => { this.fields.isReceiveEmailViaIMAP = this.isReceiveEmailViaIMAP ? 1 : 0 }"
                      inset
                      class="ml-2"
                      label="Use IMAP for receiving emails"/>
            <v-text-field v-model="fields.imapHost" dense outlined hide-details class="mb-1" label="IMAP Host" :disabled="!isReceiveEmailViaIMAP"/>
            <v-text-field v-model="fields.imapPort" dense outlined hide-details class="mb-1" number label="IMAP Port" :disabled="!isReceiveEmailViaIMAP"/>
            <v-switch v-model="imapIsSecure"
                      class="ml-2"
                      @change="() => { this.fields.imapIsSecure = this.imapIsSecure ? 1 : 0 }"
                      inset
                      label="Use Secure connection"
                      :disabled="!isReceiveEmailViaIMAP"/>
            <v-text-field v-model="fields.imapLogin" dense outlined hide-details class="mb-1" label="IMAP Login" :disabled="!isReceiveEmailViaIMAP"/>
            <v-text-field v-model="fields.imapPassword" dense outlined hide-details type="password" class="mb-1" label="IMAP Password" :disabled="!isReceiveEmailViaIMAP"/>
          </v-flex>
      </v-layout>
    </v-form>

    <v-layout wrap>
      <v-flex xs12 md6 offset-md-3>
        <v-btn v-if="!updatingEnabled" style='width:100%;margin-top: 10px;' data-cy="userNextBtn" color="primary" @click="next">
          <template v-if="role === $store.getters.roleAssistant">
            {{$t('CreateUser.Submit')}}
          </template>
          <template v-else>
            {{$t('Next')}}<v-icon>mdi-chevron-down</v-icon>
          </template>
        </v-btn>
      </v-flex>
    </v-layout>

    <UsersFormSaveCancelButtons v-if="isSaveAllowed && updatingEnabled"
                              :loading="isFieldLoading('saveBtn')"
                              @save="saveFields"
                              @cancel="$root.route('/user/page/' + userId)"/>

    <template v-if="isOwnSettings">
      <v-layout wrap>
        <v-flex xs12 md6 offset-md-3 v-if="this.$root.isMailsViewAllowed">
          <v-sheet class="pa-2 mb-2" outlined>
            <h1>{{$t('LinkedAccounts')}}</h1>
            <template v-if="$store.getters.currentUser['oauthData'] && $store.getters.currentUser['oauthData'].indexOf('[') === 0">
              <template v-for="item in JSON.parse($store.getters.currentUser['oauthData'])">
                <v-list-item :key="item.email">
                  <v-list-item-icon>
                    <v-btn @click="removeLinkedAccount(item.email)" icon>
                      <v-icon color="error">mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-left">{{item.email}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
             </template>
             <v-btn @click="linkAccount"
                    class="ml-1 mb-2 mt-2"
                    :color="($store.getters.currentUser['oauthData']  && $store.getters.currentUser['oauthData'].indexOf('[') === 0) ? 'success' : 'error'">
              <v-icon left>mdi-google</v-icon>
              {{$t('LinkGGLAccount')}}
             </v-btn>
          </v-sheet>
        </v-flex>
        <v-flex xs12 md6 offset-md-3 v-if="this.$root.isNotificationsViewAllowed">
          <v-sheet class="pa-2 mb-2" outlined>
            <h1>{{$t('Notifications.SettingsTitle')}}</h1>
            <v-alert color="warning" v-if="notificationsErrMsg !== ''">{{notificationsErrMsg}}</v-alert>
            <v-switch
              v-model="isShowNotifications"
              inset
              @change="onShowNotificationsChanged"
              :label="$t('Notifications.ShowNotifications')">
            </v-switch>
            <v-divider></v-divider>
            <v-layout wrap>
              <v-overlay :value="notificationsLoading" :absolute="true">
                <v-progress-circular indeterminate></v-progress-circular>
              </v-overlay>
              <template v-for="(item, key) in notificationsTypes">
                <v-flex xs12 md6 :key="key">
                  <v-switch
                    v-model="notificationsTypes[key]"
                    inset
                    @change="onShowNotificationsTypeChanged"
                    :label="$t('Notifications.types.'+key)">
                  </v-switch>
                </v-flex>
              </template>
            </v-layout>
          </v-sheet>
        </v-flex>
      </v-layout>
    </template>
  </div>
</template>

<script>
import FileUpload from '../../components/FileUpload.vue';
import fieldLoadingMixin from '../../mixins/fieldLoadingMixin';
import fieldAccessRightMixin from '../../mixins/fieldAccessRightsMixin';
import userFormsCommonMixin from '../../mixins/userFormsCommonMixin';
//import SignaturePad from 'signature_pad';
import SelectRole from '../../components/SelectRole';
import UsersFormSaveCancelButtons from '../../components/UsersFormSaveCancelButtons';

export default {
  components: { UsersFormSaveCancelButtons, FileUpload, SelectRole },
  mixins: [fieldLoadingMixin, fieldAccessRightMixin, userFormsCommonMixin],
  props: ['updatingEnabled', 'userId', 'role', 'isOwnSettings', 'isNew'],
  data: () => ({
    loading: true,
    valid: true,
    requiredEnabled: true,
    minPasswordLength: 5,
    fields: {
      role: '',
      avatarUPLOAD: '',
      firstName: '',
      lastName: '',
      disabledNotificationTypes: '',
      isOpenItemsInModal: 0,
      signature: '',
      isSendEmailViaSMTP: 0,
      smtpHost: '',
      smtpPort: '',
      smtpIsSecure: 0,
      smtpLogin: '',
      smtpPassword: '',
      isReceiveEmailViaIMAP: 0,
      imapHost: '',
      imapPort: '',
      imapIsSecure: 0,
      imapLogin: '',
      imapPassword: ''
    },
    fetchKey: new Date().getTime(),
    emailLoading: false,
    avatarUPLOADUrl: '',
    email: '',
    password: '',
    passwordRepeat: '',
    updatePasswordLoading: false,
    emailAvailable: true,
    passwordShow: false,
    passwordRepeatShow: false,
    emails: [],
    isShowNotifications: false,
    isOpenItemsInModal: false,
    notificationsLoading: false,
    notificationsErrMsg: '',
    notificationsTypes: {},
    isShowPassword: false,
    signaturePad: null,
    isSendEmailViaSMTP: false,
    isReceiveEmailViaIMAP: false,
    smtpIsSecure: false,
    imapIsSecure: false
  }),
  computed: {
    passwordRules: function () {
      return [
        v => !!v || this.$i18n.t('CreateUser.Validators.PasswordIsRequired'),
        v => v.length >= this.minPasswordLength || this.$i18n.tc('CreateUser.Validators.PasswordMustHaveAtLeastSymbols', this.minPasswordLength)
      ];
    },
    passwordRepeatRules: function () {
      return [
        v => !!v || this.$i18n.t('CreateUser.Validators.PasswordIsRequired'),
        v => this.password === this.passwordRepeat || this.$i18n.t('CreateUser.Validators.PasswordsDoesNotMatch')
      ];
    }
  },
  watch: {
    fields (val) {
      //console.warn(val)
    }
  },
  mounted () {
    if (this.isNew) {
      this.isShowPassword = true;
      return;
    }
    this.$root.isSubscribedToPushSubscription().then((response) => {
      if (response.hasOwnProperty('msg') && response.msg === 'Not subscribed to push service!') {
        this.$root.subscribeUser().then((response2) => {

        }).catch(e => {
          this.notificationsErrMsg = e;
        });
      } else {
        this.isShowNotifications = true;
      }
    });
    this.loadImages();
    this.fetchDataForSettingsModal();
    this.initData();

    this.$nextTick(() => {
      //this.signaturePad = new SignaturePad(document.getElementById('signaturePad'));
    });

    //this.$root.post('/api/mail/SendViaSMTP', {});
  },
  methods: {
    signatureClear () {
      this.signaturePad.clear();
    },
    signatureUndo () {
      const data = this.signaturePad.toData();
      if (data) {
        data.pop(); // remove the last dot or line
        this.signaturePad.fromData(data);
      }
    },
    signatureSave () {
      const data = this.signaturePad.toDataURL();
      this.$root.updateUserFields({ signature: data }, this.userId).then((response) => {
        if (response.status === 'ok') {
          this.$root.toast(this.$i18n.t('Saved'), { color: 'success' });
        } else {
          this.$root.toast(response.msg, { color: 'error' });
        }
      });
    },
    onRoleChanged (value) {
      this.$set(this.fields, 'role', value);
    },
    onIsOpenItemsInModal () {
      this.fields.isOpenItemsInModal = this.isOpenItemsInModal ? 1 : 0;
    },
    required (value) {
      if (value instanceof Array && value.length === 0) {
        return this.$root.t('Validators.FieldIsRequired');
      }
      for (let i = 0; i < value.length; i++) {
        if (!/.+@.+\..+/.test(value[i])) {
          return this.$root.t('Validators.MustBeValidEMail');
        }
      }
      return !!value || this.$root.t('Validators.FieldIsRequired');
    },
    isValid () {
      // Do not check email availability if role is broker
      if (this.role === 'Broker') {
        return this.$refs.form.validate();
      }
      return this.$refs.form.validate() && this.emailAvailable;
    },
    next () {
      if (!this.isValid() || !this.emailAvailable) {
        this.$root.toast(this.$i18n.t('FormValidationFailed'), { color: 'error' });
        return;
      }
      this.$emit('next');
    },
    removeLinkedAccount (email) {
      this.$dialog.confirm(this.$root.t('RemoveGGLAccountDesc', { email: email })).then(dialog => {
        this.$root.post('/api/mail/unlink', { email: email }).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('LinkedAccountRemoved'), { color: 'success' });
          } else {
            this.$root.toast(response.msg, { color: 'error' });
          }
          this.$root.statusUpdate();
        });
      });
    },
    linkAccount () {
      this.$dialog.confirm(this.$root.t('LinkGGLAccountDesc')).then(dialog => {
        window.location.href = this.$store.getters.host + '/api/mail/auth/' + this.$store.getters.currentUser.id + '/' + this.$root.companyId;
      });
    },
    onShowNotificationsTypeChanged () {
      let key = '';
      let typesArray = [];
      for (key in this.notificationsTypes) {
        if (this.notificationsTypes.hasOwnProperty(key) && !this.notificationsTypes[key]) {
          typesArray.push(key);
        }
      }
      this.$root.updateDisabledNotificationsTypes(typesArray);
    },
    onShowNotificationsChanged () {
      if (this.isShowNotifications) {
        this.$root.isSubscribedToPushSubscription().then((response) => {
          if (response.hasOwnProperty('msg') && response.msg === 'Not subscribed to push service!') {
            this.$root.subscribeUser().then((response2) => {
              if (response2 && 'toJSON' in response2 && response2.toJSON().endpoint) {
                this.$root.updateUserSubscriptionData(response2.toJSON().endpoint, JSON.stringify(response2.toJSON()));
              }
            }).catch((e) => {
              this.notificationsErrMsg = e;
              this.isShowNotifications = false;
            });
          }
        });
      } else {
        this.$root.isSubscribedToPushSubscription().then((response) => {
          if (!response.hasOwnProperty('msg')) {
            this.$root.removeUserSubscriptionData(response.toJSON().endpoint);
          }
          this.$root.unSubscribeUser();
        });
      }
    },
    emailsChange () {
      // Set email filed if we edit user, if we create user email field passed directly in sumbit method in CreateNew.vue
      if (this.$route.params.id) {
        this.fields['email'] = this.emails.join(',');
      }
    },
    emailChange () {
      this.emailLoading = true;
      this.$root.checkEmailAvailability(this.email).then((response) => {
        if (response.result === 'available') {
          this.emailAvailable = true;
          this.fields['email'] = this.email;
        } else {
          this.$root.toast(this.$root.t('Validators.EmailAlreadyUsed'), { color: 'error' });
          this.emailAvailable = false;
        }
        this.emailLoading = false;
      });
    },
    updatePassword () {
      if (!this.isShowPassword) {
        this.isShowPassword = true;
        return;
      }
      if (this.$refs.form.validate()) {
        this.updatePasswordLoading = true;
        this.$root.updateUserPassword(this.password, this.userId ? this.userId : null).then((response) => {
          this.updatePasswordLoading = false;
          if (response.status && response.status === 'ok') {
            this.$root.toast(this.$i18n.t('PasswordUpdated'), { color: 'success' });
          } else {
            this.$root.toast(this.$i18n.t('FieldAccessError'), { color: 'error' });
            let _r = this.$root.getAccessRightsArrayFromNumber(response.rights);
            if (_r[1] === '0') {
              this.setFieldHidden('password', true);
            }
            if (_r[2] === '0') {
              this.setReadOnlyField('password', true);
            }
          }
        });
      }
    },
    generatePassword () {
      let password = Math.random().toString(36).slice(-8);
      this.password = password;
      this.passwordRepeat = password;
      this.passwordShow = true;
      this.passwordRepeatShow = true;
    },
    initData () {
      let i = 0;
      let arr = [];
      // load notifications types
      this.notificationsLoading = true;
      this.$root.getNotificationsTypes().then((response) => {
        if (response.status === 'ok') {
          for (i = 0; i < response.results.length; i++) {
            this.notificationsTypes[response.results[i]] = true;
          }
          if (this.fields.disabledNotificationTypes && this.fields.disabledNotificationTypes.indexOf('[') === 0) {
            arr = JSON.parse(this.fields.disabledNotificationTypes);
            if (arr) {
              for (i = 0; i < arr.length; i++) {
                if (this.notificationsTypes.hasOwnProperty(arr[i])) {
                  this.notificationsTypes[arr[i]] = false;
                }
              }
            }
          }
          this.notificationsLoading = false;
        }
      });
    },
    setFields (fields, userRole, rights = null) {
      let key = '';
      this.requiredEnabled = false;

      this.email = fields.email || '';

      this.disabledNotificationTypes = fields.disabledNotificationTypes;
      if (userRole === this.$store.getters.roleBroker) {
        this.emails = fields.email !== null ? fields.email.split(',') : '';
      }
      for (key in this.fields) {
        this.fields[key] = fields[key] !== null ? fields[key] : '';
      }
      this.isOpenItemsInModal = this.fields.isOpenItemsInModal === 1;

      this.applyRightsBasic(this.isOwnSettings ? '' : userRole);
      this.loadImages();
      this.initData();

      if (this.signaturePad !== null) {
        this.signaturePad.fromDataURL(this.fields.signature);
      }

      this.isSendEmailViaSMTP = this.fields.isSendEmailViaSMTP === 1;
      this.smtpIsSecure = this.fields.smtpIsSecure === 1;

      this.isReceiveEmailViaIMAP = this.fields.isReceiveEmailViaIMAP === 1;
      this.imapIsSecure = this.fields.imapIsSecure === 1;

      this.requiredEnabled = true;
      this.$set(this, 'fetchKey', new Date().getTime());
    }
  }
};
</script>
