<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-layout wrap>
        <v-flex shrink class="pt-2">
          <h2 class="ma-2">{{maintenanceType}}
            <v-chip v-if="isScheduledDoneId > 0 || record.scheduledId" color="info">{{$t('scheduled')}}</v-chip>
            <v-btn small class="ml-1" icon @click="$emit('on-delete')" color="error"><v-icon>mdi-delete</v-icon></v-btn></h2>
        </v-flex>
        <v-flex shrink class="pa-1">
          <v-chip v-if="isEnteredManuallyMiles === 0" class="mt-3">{{$t('Trucks.maintenanceMiles')}}:&nbsp;{{miles.toLocaleString()}}<v-btn icon small @click="isEnteredManuallyMiles = 1"><v-icon>mdi-pencil</v-icon></v-btn></v-chip>
          <v-text-field
            v-else
            class="ma-1"
            :readonly="isEnteredManuallyMiles === 0"
            v-model="miles"
            :label="$t('Trucks.maintenanceMiles')"
            type="number"
            suffix="mi."
            append-icon="mdi-close"
            @click:append="isEnteredManuallyMiles = 0"
            @change="isSaveAvailable = true"
            dense outlined hide-details/>
        </v-flex>
        <v-flex shrink class="pa-1">
          <v-chip v-if="isEnteredManuallyUsageMiles === 0" class="mt-3">{{$t('Trucks.maintenancePartUsageMiles')}}:&nbsp;{{partUsageMiles.toLocaleString()}}<v-btn icon small @click="isEnteredManuallyUsageMiles = 1"><v-icon>mdi-pencil</v-icon></v-btn></v-chip>
          <v-text-field
            v-else
            class="ma-1"
            :readonly="isEnteredManuallyUsageMiles === 0"
            v-model="partUsageMiles"
            :label="$t('Trucks.maintenancePartUsageMiles')"
            append-icon="mdi-close"
            @click:append="isEnteredManuallyUsageMiles = 0"
            @change="isSaveAvailable = true"
            dense outlined hide-details
          />
        </v-flex>
        <v-flex shrink class="pa-1">
          <v-chip v-if="isEnteredManuallyUsageTime === 0" class="mt-3">{{$t('Trucks.maintenancePartUsageSeconds')}}:&nbsp;{{getPartUsageSecondsFormatted(partUsageSeconds)}}<v-btn icon small @click="isEnteredManuallyUsageTime = 1"><v-icon>mdi-pencil</v-icon></v-btn></v-chip>
          <v-menu v-else
                  v-model="lastMaintenanceDATEMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                class="ma-1"
                v-model="lastMaintenanceDATEFormatted"
                @blur="lastMaintenanceDATE = $root.parseDate(lastMaintenanceDATEFormatted)"
                :label="$t('Trucks.maintenancePartPrevMaintenanceDate')"
                @click:append="isEnteredManuallyUsageTime = 0"
                append-icon="mdi-close"
                persistent-hint v-on="on" dense outlined hide-details/>
              <!--hint="MM/DD/YYYY" -->
            </template>
            <v-date-picker
              @change="onLastMaintenanceDateChange"
              v-model="lastMaintenanceDATE"
              no-title
              @input="lastMaintenanceDATEMenu = false"/>
          </v-menu>
        </v-flex>
      </v-layout>
      <v-layout class="mb-2" wrap>
        <v-flex xs12 v-if="isScheduledDoneId > 0 || record.scheduledId">
        </v-flex>
        <v-flex shrink class="pl-2">
          <v-btn @click="addNewPart" color="success" small><v-icon left small>mdi-plus</v-icon>Add Part</v-btn>
        </v-flex>
        <v-flex grow class="pl-2">
          <v-radio-group row hide-details class="mt-0" v-model="workType" @change="isSaveAvailable = true" :rules="$root.fieldRequiredRules">
            <v-radio value="I" label="Inspection"/>
            <v-radio value="R" label="Repair"/>
            <v-radio value="M" label="Maintenance"/>
          </v-radio-group>
        </v-flex>
      </v-layout>
      <template v-for="(part, index) in parts">
        <v-layout wrap>
          <v-flex xs12 md6 class="pa-1">
            <v-layout wrap>
              <v-flex xs12>
                <v-combobox class="ml-1 mb-1"
                            v-model="part.partName"
                            :label="$t('Trucks.maintenancePartName')"
                            :items="truckPartsList"
                            @change="($event) => onPartChanged($event, part)"
                            item-id="name"
                            item-text="name"
                            outlined
                            dense
                            hide-details
                            >
                  <template v-slot:prepend>
                    <h2>{{(index+1)}}.</h2>
                  </template>
                </v-combobox>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  class="ml-1 mb-1"
                  v-model="part.partCode"
                  @change="isSaveAvailable = true"
                  :label="$t('Trucks.maintenancePartCode')"
                  dense outlined hide-details
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  class="ml-1 mb-1"
                  v-model="part.partAmount"
                  @change="isSaveAvailable = true"
                  :label="$t('Trucks.maintenancePartAmount')"
                  dense outlined hide-details
                />
              </v-flex>
              <template v-if="maintenanceType && (maintenanceType.toLowerCase().indexOf('tires') >= 0 || maintenanceType.toLowerCase().indexOf('rims') >= 0)">
                <v-flex xs12>
                  <v-text-field
                    class="ml-1 mb-1"
                    v-model="part.partWear"
                    @change="isSaveAvailable = true"
                    :label="$t('Trucks.maintenancePartWear')"
                    dense outlined hide-details
                  />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    class="ml-1 mb-1"
                    v-model="part.partSize"
                    type="number"
                    :label="$t('Trucks.maintenancePartSize')"
                    dense outlined hide-details
                  />
                </v-flex>
              </template>
            </v-layout>
          </v-flex>
          <v-flex xs12 md6 class="pa-1">
            <v-layout wrap>
              <v-flex xs5>
                <v-checkbox
                  class="mt-2 ma-1 mb-2"
                  v-model="part.isReplaced"
                  @change="() => { isSaveAvailable = true; if(part.isReplaced) { part.isOriginal = false;}}"
                  :label="$t('Trucks.maintenanceIsReplaced')"
                  outlined hide-details
                />
              </v-flex>
              <v-flex xs5>
                <v-checkbox
                  class="mt-2 ma-1 mb-2"
                  v-model="part.isOriginal"
                  @change="() => { isSaveAvailable = true; if(part.isOriginal) { part.isReplaced = false;}}"
                  :label="$t('Trucks.maintenanceIsOriginal')"
                  outlined hide-details
                />
              </v-flex>
              <v-flex xs2>
                <v-btn small color="error" class="mt-2 float-right" @click="removePart(index)"><v-icon small left>mdi-delete</v-icon>Remove Part</v-btn>
              </v-flex>
              <v-flex xs12 v-if="part.isReplaced">
                <v-text-field
                  class="ml-1 mb-1"
                  v-model="part.newPartName"
                  @change="isSaveAvailable = true"
                  :label="$t('Trucks.maintenanceNewPartName')"
                  dense outlined hide-details
                />
              </v-flex>
              <v-flex xs12 v-if="part.isReplaced">
                <v-text-field
                  class="ml-1 mb-1"
                  v-model="part.newPartCode"
                  @change="isSaveAvailable = true"
                  :label="$t('Trucks.maintenanceNewPartCode')"
                  dense outlined hide-details
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-divider/>
      </template>
      <v-layout wrap class="mt-1">
        <v-flex xs12 class="pl-1">
          <v-textarea
            class="ml-1 mb-1 mr-1"
            rows="1"
            v-model="notes"
            @change="isSaveAvailable = true"
            :label="$t('Trucks.maintenanceNote')"
            outlined hide-details
          />
        </v-flex>
        <template v-if="!isScheduledDoneId">
          <v-flex shrink>
            <v-checkbox
              class="mt-2 ma-1 mb-2"
              v-model="isScheduleOnly"
              @change="() => { isSaveAvailable = true; }"
              :label="$t('Trucks.maintenanceScheduledOnly')"
              outlined hide-details
            />
          </v-flex>
          <v-flex shrink class="pl-1" v-if="maintenanceType === 'Oil Replacement' || isScheduleOnly || (isPresetMaintenance && nextMaintenanceMiles)">
            <v-text-field
              class="ma-1"
              v-model="nextMaintenanceMiles"
              @change="isSaveAvailable = true"
              :label="$t('Trucks.nextMaintenanceMiles')"
              type="number"
              suffix="mi."
              dense outlined hide-details/>
          </v-flex>
          <v-flex shrink class="pl-1" v-if="isScheduleOnly || (isPresetMaintenance && nextMaintenanceDATE)">
            <!--<v-text-field
              class="ma-1"
              v-model="nextMaintenanceDATE"
              :label="$t('Trucks.nextMaintenanceDATE')"
              type="text"
              dense outlined hide-details/>-->
            <v-menu v-model="nextMaintenanceDATEMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  class="ma-1"
                  v-model="nextMaintenanceDATEFormatted"
                  @blur="nextMaintenanceDATE = $root.parseDate(nextMaintenanceDATEFormatted)"
                  :label="$t('Trucks.nextMaintenanceDATE')"
                  @click:append="isEnteredManuallyUsageTime = 0"
                  append-icon="mdi-close"
                  persistent-hint v-on="on" dense outlined hide-details/>
                <!--hint="MM/DD/YYYY" -->
              </template>
              <v-date-picker
                @change="onNextMaintenanceDateChange"
                v-model="nextMaintenanceDATE"
                no-title
                @input="nextMaintenanceDATEMenu = false"/>
            </v-menu>
          </v-flex>
        </template>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12 md10>
          <MultiFileUpload :field="attUPLOAD"
                           height="300px"
                           @change="fileChange($event)"
                           fieldName="publicRateConUPLOAD"
                           uploadFolder="maintenanceAtt"
                           :label="$t('Trucks.maintenanceAtt')"
                           :deleteAllowed="true"
                           :downloadAllowed="true"
                           :addBtnWidth="'90%'"
          />
        </v-flex>
        <v-flex xs12 md2 class="pt-2 text-right" v-if="record.isScheduledDone">
          <v-chip color="info">Completed on schedule</v-chip>
        </v-flex>
        <v-flex xs12 md2 class="pt-2 text-right" v-else>
          <v-btn small @click="save" color="primary" v-if="isSaveAvailable"><v-icon left>mdi-content-save</v-icon>{{$t('Save')}}</v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import MultiFileUpload from './../../components/MultiFileUpload';
import trucksMixin from './../../mixins/trucksMixin';

export default {
  props: ['maintenanceType', 'maintenanceDATE', 'truckId', 'record', 'truckMaintenanceTypes'],
  components: { MultiFileUpload },
  mixins: [ trucksMixin ],
  data () {
    return {
      loading: false,
      error: '',
      valid: false,
      miles: 0,
      partUsageMiles: 0,
      partUsageSeconds: 0,
      attUPLOAD: '',
      notes: '',
      workType: '',
      isEnteredManuallyMiles: 0,
      isEnteredManuallyUsageMiles: 0,
      isEnteredManuallyUsageTime: 0,
      nextMaintenanceMiles: '',
      nextMaintenanceDATE: '',
      parts: [],
      truckPartsList: [],
      lastMaintenanceDATEMenu: false,
      lastMaintenanceDATEFormatted: '',
      lastMaintenanceDATE: '',
      nextMaintenanceDATEMenu: false,
      nextMaintenanceDATEFormatted: '',
      isSaveAvailable: false,
      isScheduleOnly: false,
      isScheduledDoneId: 0
    };
  },
  mounted () {
    this.$nextTick(() => {
      if (this.record && this.record.hasOwnProperty('id')) {
        this.miles = this.record.miles;
        /*
        this.partName = this.record.partName;
        this.partCode = this.record.partCode;
        this.partAmount = this.record.partAmount;
        this.isReplaced = parseInt(this.record.isReplaced) === 1;
        this.partWear = this.record.partWear;
        this.partSize = this.record.partSize;
        this.newPartName = this.record.newPartName;
        this.newPartCode = this.record.newPartCode;
        */
        this.parts = this.record.parts;
        this.partUsageMiles = this.record.partUsageMiles;
        this.$set(this, 'partUsageSeconds', this.record.partUsageSeconds);
        this.attUPLOAD = this.record.attUPLOAD;
        this.notes = this.record.notes;
        this.workType = this.record.workType;
        this.isEnteredManuallyMiles = this.record.isEnteredManuallyMiles;
        this.isEnteredManuallyUsageMiles = this.record.isEnteredManuallyUsageMiles;
        this.isEnteredManuallyUsageTime = this.record.isEnteredManuallyUsageTime;
        this.nextMaintenanceMiles = this.record.nextMaintenanceMiles > 0 ? this.record.nextMaintenanceMiles : '';
        this.nextMaintenanceDATE = this.record.nextMaintenanceDATE;
        this.nextMaintenanceDATEFormatted = this.$root.formatDate(this.nextMaintenanceDATE);
      } else {
        this.updateMetaData();
        this.isSaveAvailable = true;
      }
      this.isScheduleOnly = parseInt(this.record.status) === 10;
      this.isScheduledDoneId = this.record.hasOwnProperty('isScheduledDoneId') ? this.record.isScheduledDoneId : 0;

      this.$root.getPartsByTruckId(this.truckId).then( response => {
        if (response.status === 'ok') {
          this.truckPartsList = [];
          response.results.forEach(element => {
            if (element.partName && element.partCode) {
              this.truckPartsList.push({ name: element.partName + ' [' + element.partCode + ']' });
            } else if (element.partName) {
              this.truckPartsList.push({ name: element.partName });
            }
          });
        }
      });
    });
  },
  watch: {

  },
  computed: {
    isPresetMaintenance () {
      return this.truckMaintenanceTypes.find(el => el.name === this.maintenanceType);
    }
  },
  beforeDestroy () {
  },
  methods: {
    onPartChanged($event, part) {
      this.isSaveAvailable = true;
      part.partName = $event.hasOwnProperty('name') ? $event.name : $event.trim();
      if (part.partName.indexOf('[') > 0) {
        let _tmp = part.partName.substring(0, part.partName.length - 1).split('[');
        part.partName = _tmp[0].trim();
        part.partCode = _tmp[1].trim();
      }
    },
    updateMetaData () {
      this.getMaintenanceUsageData(this.truckId, this.maintenanceType).then((response) => {
        if (response.status === 'ok') {
          const typeData = this.truckMaintenanceTypes.find(e => { return e.name === this.maintenanceType; });
          if (response.results.hasOwnProperty('usageSeconds') && !this.record.hasOwnProperty('partUsageSeconds')) {
            this.$set(this, 'partUsageSeconds', response.results.usageSeconds);
          }
          /*if (response.results.hasOwnProperty('usageSeconds') && !this.record.hasOwnProperty('partUsageSeconds')) {
            const time = this.$root.secondsToHms(response.results.usageSeconds);
            if (time.hours > 24) {
              this.$set(this, 'partUsageSeconds', Math.floor(response.results.usageSeconds / (24 * 3600)) + ' ' + this.$root.t('daysShort'));
            } else {
              this.$set(this, 'partUsageSeconds', (time.hours < 10 ? '0' + time.hours : time.hours) + ':' + (time.minutes < 10 ? '0' + time.minutes : time.minutes));
            }
          }*/
          if (response.results.hasOwnProperty('usageMiles') && !this.record.hasOwnProperty('partUsageMiles')) {
            this.$set(this, 'partUsageMiles', response.results.usageMiles);
          }
          if (response.results.hasOwnProperty('truckMiles') && !this.record.hasOwnProperty('miles')) {
            this.$set(this, 'miles', response.results.truckMiles);
            if (this.maintenanceType === 'Oil Replacement' && response.results.hasOwnProperty('truckNextOilReplacementIntervalMiles') && parseInt(response.results.truckNextOilReplacementIntervalMiles) > 0) {
              console.warn(response.results);
              this.nextMaintenanceMiles = parseInt(response.results.truckMiles) + parseInt(response.results.truckNextOilReplacementIntervalMiles);
            } else {
              if (typeData && typeData.hasOwnProperty('milesInterval') && typeData.milesInterval > 0) {
                this.nextMaintenanceMiles = parseInt(response.results.truckMiles) + parseInt(typeData ? typeData.milesInterval : 0);
              }
            }
            if (typeData && typeData.hasOwnProperty('daysInterval') && typeData.daysInterval > 0) {
              this.nextMaintenanceDATE = this.$moment.moment().utcOffset(this.$store.getters.timezoneOffsetMinutes).add(parseInt(typeData ? typeData.daysInterval : 0), 'd').format('YYYY-MM-DD');
              this.nextMaintenanceDATEFormatted = this.$root.formatDate(this.nextMaintenanceDATE);
            }
          }
          this.parts = response.results.parts;
        }
      });
    },
    addNewPart () {
      this.parts.push({
        partName: '',
        partCode: '',
        partAmount: '',
        isReplaced: false,
        isOriginal: false,
        partWear: '',
        partSize: '',
        newPartName: '',
        newPartCode: ''
      });
      this.isSaveAvailable = true;
    },
    removePart (index) {
      this.parts.splice(index, 1)
      this.isSaveAvailable = true;
    },
    fileChange (fieldValue) {
      this.attUPLOAD = fieldValue;
      this.isSaveAvailable = true;
    },
    getPartUsageSecondsFormatted (seconds) {
      const time = this.$root.secondsToHms(seconds);
      if (time.hours > 24) {
        return Math.floor(seconds / (24 * 3600)) + ' ' + this.$root.t('daysShort');
      }
      return (time.hours < 10 ? '0' + time.hours : time.hours) + ':' + (time.minutes < 10 ? '0' + time.minutes : time.minutes);
    },
    onLastMaintenanceDateChange () {
      const start = this.$moment.moment(this.lastMaintenanceDATE, 'YYYY-MM-DD');
      const end = this.$moment.moment();
      const diff = this.$moment.moment.duration(end.diff(start)).asSeconds();
      if (diff > 0) {
        this.partUsageSeconds = parseInt(diff);
      } else {
        this.$root.toast('Date cannot be in the future', { color: 'error' });
      }

      this.lastMaintenanceDATEFormatted = this.$root.formatDate(this.lastMaintenanceDATE);
      this.isEnteredManuallyUsageTime = 0;
      this.isSaveAvailable = true;
    },
    onNextMaintenanceDateChange () {
      this.nextMaintenanceDATEFormatted = this.$root.formatDate(this.nextMaintenanceDATE);
      this.isSaveAvailable = true;
    },
    save () {
      if (!this.$refs.form.validate()) return;
      //this.$dialog.confirm(this.$root.t('Trucks.SaveMaintenanceConfirmation')).then((dialog) => {
      let fields = {
        truckId: this.truckId,
        miles: this.miles,
        maintenanceDATE: this.maintenanceDATE,
        maintenanceType: this.maintenanceType,
        /*partName: this.partName,
        partCode: this.partCode,
        partAmount: this.partAmount,
        isReplaced: this.isReplaced ? 1 : 0,
        partWear: this.partWear,
        partSize: this.partSize,
        newPartName: this.newPartName,
        newPartCode: this.newPartCode,*/
        partUsageMiles: this.partUsageMiles,
        partUsageSeconds: this.partUsageSeconds,
        notes: this.notes,
        workType: this.workType,
        attUPLOAD: this.attUPLOAD,
        isEnteredManuallyMiles: this.isEnteredManuallyMiles,
        isEnteredManuallyUsageMiles: this.isEnteredManuallyUsageMiles,
        isEnteredManuallyUsageTime: this.isEnteredManuallyUsageTime,
        nextMaintenanceMiles: this.nextMaintenanceMiles,
        nextMaintenanceDATE: this.nextMaintenanceDATE
      };
      if (this.isScheduleOnly) {
        fields.status = 10;
      }
      if (this.record && this.record.hasOwnProperty('id')) {
        this.updateMaintenanceRecord(this.record.id, fields, this.parts, this.isScheduledDoneId).then(response => {
          this.$emit('on-fetch', this.maintenanceDATE);
        });
      } else {
        this.createMaintenanceRecord(fields, this.parts, this.isScheduledDoneId).then(response => {
          this.$emit('on-fetch', this.maintenanceDATE);
        });
      }
      //});
    }
  }
};
</script>
