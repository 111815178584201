<template>
  <v-card class="mx-auto">
    <v-card-title class="pa-0 pl-3">
      <v-switch inset
                class="ml-4"
                :label="$root.t('Loads.TrackingNotifications')"
                data-cy="enableNotificationsSWITCH"
                v-model="isEnabled"></v-switch>
      <v-spacer></v-spacer>
      <v-btn icon class="mr-4" @click="$emit('closed')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="text--primary">
      <template>
        <v-combobox prepend-icon="fa-envelope"
                    :disabled="!isEnabled"
                    :label="$t('Loads.TrackingNotificationsRecipients')"
                    :items="emailsItems"
                    multiple
                    dense
                    v-model="emails"
                    outlined></v-combobox>

        <v-combobox prepend-icon="fa-envelope"
                    :disabled="!isEnabled"
                    :label="$t('Loads.TrackingNotificationsInCopyRecipients')"
                    :items="copyEmailsItems"
                    multiple
                    dense
                    v-model="copyEmails"
                    outlined></v-combobox>

        <v-select prepend-icon="fa-envelope"
                    :disabled="!isEnabled"
                    :label="$t('Loads.TrackingNotificationsInterval')"
                    :items="intervalItems"
                    item-text="text"
                    item-value="value"
                    dense
                    v-model="interval"
                    outlined></v-select>
        <div style="margin-top:-20px;"></div>
        <v-switch inset
                  class="ml-10"
                  :disabled="!isEnabled"
                  :label="$root.t('Loads.TrackingNotificationsSendByRequest')"
                  data-cy="enableNotificationsSWITCH"
                  v-model="isSendByRequest"></v-switch>

        <v-text-field
          :disabled="!isEnabled"
          data-cy="notificationEmailSubjectTemplate"
          v-model="notificationEmailSubjectTemplate"
          :label="$t('Loads.TrackingNotificationsEmailSubject')"
          prepend-icon="mdi-text-subject"
          hint="{LoadUID} {PickupLocation} {DeliveryLocation} {TruckSpeed} {TruckLocation} {DistanceLeftInMiles} {TimeLeft}"
          type="text" outlined dense>
        </v-text-field>

        <v-textarea
          :disabled="!isEnabled"
          data-cy="notificationEmailTemplate"
          v-model="notificationEmailTemplate"
          :label="$t('Loads.TrackingNotificationsEmailTemplate')"
          prepend-icon="fa-envelope-open-text" outlined
          hint="{LoadUID} {PickupLocation} {DeliveryLocation} {TruckSpeed} {TruckLocation} {DistanceLeftInMiles} {TimeLeft}">
        </v-textarea>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-layout>
        <v-flex xs6 class="text-center">
          <v-btn color="primary" class="ma-1 mb-3" width="90%" @click="setup()" :disabled="!$root.isAccessRightAllowEdit($store.getters.loadsRights['trackingNotifications'])">
            <v-icon left>fa-save</v-icon>{{$t('Loads.TrackingNotificationsSetup')}}
          </v-btn>
        </v-flex>
        <v-flex xs6 class="text-center">
          <v-btn width="90%" text class="ma-1 mb-3" @click="$emit('closed')">{{$t('Close')}}</v-btn>
        </v-flex>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
    import fieldAccessRightMixin from './../mixins/fieldAccessRightsMixin';
    import userFunctionsMixin from './../mixins/userFunctionsMixin';

    export default {
        name: 'TrackingNotifications',
        props: ['loadId', 'isTrackingNotifications', 'isTrackingNotificationsSendByRequest','trackingNotificationsRecipients','trackingNotificationsInCopy','trackingNotificationsInterval','trackingNotificationsSubject','trackingNotificationsMessage'],
        components: { },
        mixins: [ fieldAccessRightMixin, userFunctionsMixin ],
        data: function () {
            return {
                isEnabled: false,
                emails: [],
                emailsItems: [],
                copyEmails: [],
                copyEmailsItems: [],
                isSendByRequest: false,
                interval: 3600,
                notificationEmailSubjectTemplate: '',
                notificationEmailTemplate: '',
                intervalItems: [
                    { value: 0, text: 'Off'},
                    { value: 0.5 * 3600, text: '30 min'},
                    { value: 1 * 3600, text: '1 hour'},
                    { value: 2 * 3600, text: '2 hours'},
                    { value: 3 * 3600, text: '3 hours'},
                    { value: 6 * 3600, text: '6 hours'},
                    { value: 12 * 3600, text: '12 hours'},
                    { value: 24 * 3600, text: '24 hours'}
                ]
            };
        },
        computed: {
        },
        mounted () {
            if (this.loadId) {
                if (this.isTrackingNotifications) {
                    this.isEnabled = this.isTrackingNotifications;
                }
                if (this.isTrackingNotificationsSendByRequest) {
                    this.isSendByRequest = this.isTrackingNotificationsSendByRequest;
                }

                if (this.trackingNotificationsInterval >= 0) {
                    this.interval = this.trackingNotificationsInterval;
                }
                if (this.trackingNotificationsRecipients) {
                    this.emails = this.trackingNotificationsRecipients;
                }
                if (this.trackingNotificationsInCopy) {
                    this.copyEmails = this.trackingNotificationsInCopy;
                }
                if (this.trackingNotificationsSubject) {
                    this.notificationEmailSubjectTemplate = this.trackingNotificationsSubject;
                }
                if (this.trackingNotificationsMessage) {
                    this.notificationEmailTemplate = this.trackingNotificationsMessage;
                }

                this.$root.getLoad(this.loadId).then( async (response) => {
                    if (response.status === 'ok') {
                        const uid = response.result['uid'];
                        const broker = response.result['broker'];
                        const dispatcher = response.result['dispatcher'];
                        if (broker > 0) {
                            const broker_fields = await this.$root.loadUserFields(broker);
                            // Add broker emails
                            this.emailsItems = broker_fields.fields.email.split(',');
                            // Add broekrs manages emails
                            const brokerManagers = broker_fields.fields.brokerManagersJSON ? JSON.parse(broker_fields.fields.brokerManagersJSON) : [];
                            for (let i = 0; i < brokerManagers.length; i++) {
                                if (brokerManagers[i].email) {
                                    this.emailsItems.push(brokerManagers[i].email);
                                }
                            }
                        }


                        if (dispatcher > 0) {
                            const dispatcher_fields = await this.$root.loadUserFields(dispatcher);
                            // add dispatchers emails
                            this.copyEmailsItems.push(dispatcher_fields.fields.email);
                            if(dispatcher_fields.fields.dispatchersToSharePercent && dispatcher_fields.fields.dispatchersToSharePercent !== '{}') {
                                const disp_ids = JSON.parse(dispatcher_fields.fields.dispatchersToSharePercent);
                                for (let key in disp_ids) {
                                    if (key > 0) {
                                        const _fields = await this.$root.loadUserFields(key);
                                        this.copyEmailsItems.push(_fields.email);
                                    }
                                }
                            }
                            if(dispatcher_fields.fields.dispatchersSharedAccess && dispatcher_fields.fields.dispatchersSharedAccess !== '[]]') {
                                const disp_ids = JSON.parse(dispatcher_fields.fields.dispatchersSharedAccess);
                                for (let i = 0; i < disp_ids.length; i++) {
                                    const _fields = await this.$root.loadUserFields(disp_ids[i]);
                                    this.copyEmailsItems.push(_fields.email);
                                }
                            }
                        }

                    }
                });
            }
            //this.$forceUpdate();
        },
        methods: {
            setup() {
                this.$root.setupTrackingNotifications (this.loadId,
                                                       this.isEnabled ? 1 : 0,
                                                       this.interval,
                                                       this.isSendByRequest ? 1 : 0,
                                                       this.emails,
                                                       this.notificationEmailSubjectTemplate,
                                                       this.notificationEmailTemplate,
                                                       this.copyEmails).then((response) => {
                    if (response.status === 'ok') {
                        this.$emit("changed", this.isEnabled);
                    }
                });

            }
        }
    };
</script>
