export default {
  namespaced: true,
  state: {
    driverShiftId: 0
  },
  getters: {
    getCurrentDriverShiftId (state) {
      return state.driverShiftId;
    }
  },
  actions: {
    setCurrentDriverShiftId ({ state }, value) {
      state.driverShiftId = value;
    }
  }
};
