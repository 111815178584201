<template>
  <v-container fill-height grid-list-md text-xs-center v-if="loading">
    <div class="lds-facebook" style="margin:auto;"><div></div><div></div><div></div></div>
  </v-container>
  <div v-else-if="error">
    <v-flex class="text-center error pa-2">
      <h1 class="title">{{error}}</h1>
    </v-flex>
  </div>
  <div v-else>
    <v-simple-table dense>
      <template v-slot:default>
        <tbody>
        <tr v-if="bidId">
          <td class="text-left">#</td>
          <td class="text-left">
            <a link @click="$root.addNewPageTab('/bids/edit/' + bidId)">{{bidId}}</a>
          </td>
        </tr>
        <tr>
          <td class="text-left">{{$t('Bids.ListCreated')}}</td>
          <td class="text-left">
            <span v-if="fields.datePST">{{fields.datePST}}</span>
            <span v-else v-html="'&mdash;'"></span>
          </td>
        </tr>
        <tr>
          <td class="text-left">{{$t('Bids.ListStatus')}}</td>
          <td class="text-left">
            <template v-if="parseInt(fields.deleted) === 0">
              <v-icon v-if="fields.status === 'new'" color="warning">mdi-new-box</v-icon>
              <v-icon v-if="fields.status === 'sent'" color="success">mdi-email-check</v-icon>
              <v-icon v-if="fields.status === 'to sent'" color="warning">mdi-email-send</v-icon>
              <v-icon v-if="fields.loadId > 0" color="success">mdi-dolly</v-icon>
            </template>
            <template v-else>
              <v-icon color="error">mdi-delete-circle</v-icon>
            </template>
          </td>
        </tr>
        <tr>
          <td class="text-left">{{$t('Bids.ListDispatcher')}}</td>
          <td class="text-left">
            <a v-if="fields.dispatcher" @click="$root.route('/user/page/'+fields.dispatcher)">{{fields.dispatcherName}}</a>
            <span v-else v-html="'&mdash;'"></span>
            <v-btn v-if="fields.dispatcher" small icon @click="$root.addNewPageTab('/user/page/'+fields.dispatcher)"><v-icon small class="mr-1">mdi-open-in-new</v-icon></v-btn>
          </td>
        </tr>
        <tr>
          <td class="text-left">{{$t('Bids.ListTruck')}}</td>
          <td class="text-left">
            <a v-if="fields.truck" @click="$root.route('/trucks/page/'+fields.broker)">{{fields.truckName}}</a>
            <span v-else v-html="'&mdash;'"></span>
            <v-btn v-if="fields.truck" small icon @click="$root.addNewPageTab('/trucks/page/'+fields.truck)"><v-icon small class="mr-1">mdi-open-in-new</v-icon></v-btn>
          </td>
        </tr>
        <tr>
          <td class="text-left">{{$t('Bids.ListBroker')}}</td>
          <td class="text-left">
            <a v-if="fields.broker" @click="$root.route('/user/page/'+fields.broker)">{{fields.brokerName}}</a>
            <span v-else v-html="'&mdash;'"></span>
            <v-btn v-if="fields.broker" small icon @click="$root.addNewPageTab('/user/page/'+fields.broker)"><v-icon small class="mr-1">mdi-open-in-new</v-icon></v-btn>
          </td>
        </tr>
        <tr>
          <td class="text-left">{{$t('Bids.ListLoad')}}</td>
          <td class="text-left">
            <a v-if="fields.loadId" @click="$root.onShowLoadPreview(fields.loadId)">{{fields.loadUID}}</a>
            <span v-else v-html="'&mdash;'"></span>
            <v-btn v-if="fields.loadId" small icon @click="$root.addNewPageTab('/loads/page/'+fields.loadId)"><v-icon small class="mr-1">mdi-open-in-new</v-icon></v-btn>
          </td>
        </tr>
        <tr>
          <td class="text-left">{{$t('Bids.ListManager')}}</td>
          <td class="text-left">
            <span v-if="fields.brokerManager">{{fields.brokerManager}}</span>
            <span v-else v-html="'&mdash;'"></span>
          </td>
        </tr>
        <tr>
          <td class="text-left">{{$t('Bids.ListSender')}}</td>
          <td class="text-left">
            <span v-if="fields.sender">{{fields.sender}}</span>
            <span v-else v-html="'&mdash;'"></span>
          </td>
        </tr>
        <tr>
          <td class="text-left">{{$t('Bids.ListRecipient')}}</td>
          <td class="text-left">
            <span v-if="fields.recipient">{{fields.recipient}}</span>
            <span v-else v-html="'&mdash;'"></span>
          </td>
        </tr>
        <tr>
          <td class="text-left">{{$t('Bids.ListInCopy')}}</td>
          <td class="text-left">
            <span v-if="fields.incopy">{{fields.incopy}}</span>
            <span v-else v-html="'&mdash;'"></span>
          </td>
        </tr>
        <tr>
          <td class="text-left">{{$t('Bids.ListSubject')}}</td>
          <td class="text-left">
            <span v-if="fields.subject">{{fields.subject}}</span>
            <span v-else v-html="'&mdash;'"></span>
          </td>
        </tr>

        <tr>
          <td class="text-left">{{$t('Bids.ListDistance')}}</td>
          <td class="text-left">
            <span v-if="fields.totalDistance">
              {{$root.metersToMiles(fields.totalDistance).toLocaleString()}}&nbsp;{{$t('milesShort')}}
            </span>
            <span v-else v-html="'&mdash;'"></span>
          </td>
        </tr>
        <tr>
          <td class="text-left">{{$t('Bids.ListDistanceToPickup')}}</td>
          <td class="text-left">
            <span v-if="fields.distanceToPickup">
              {{$root.metersToMiles(fields.distanceToPickup).toLocaleString()}}&nbsp;{{$t('milesShort')}}
            </span>
            <span v-else v-html="'&mdash;'"></span>
          </td>
        </tr>
        <tr>
          <td class="text-left">{{$t('Bids.ListDuration')}}</td>
          <td class="text-left">
            <span v-if="fields.totalDuration">
              {{$root.showDuration(fields.totalDuration)}}&nbsp;{{$t('milesShort')}}
            </span>
            <span v-else v-html="'&mdash;'"></span>
          </td>
        </tr>

        <tr>
          <td class="text-left">{{$t('Bids.ListRatePerMile')}}</td>
          <td class="text-left">
            <span v-if="fields.totalDuration">
              ${{fields.ratePerMile.toLocaleString()}}
            </span>
            <span v-else v-html="'&mdash;'"></span>
          </td>
        </tr>
        <tr>
          <td class="text-left">{{$t('Bids.ListRate')}}</td>
          <td class="text-left">
            <span v-if="fields.totalDuration">
              ${{fields.totalRate.toLocaleString()}}
            </span>
            <span v-else v-html="'&mdash;'"></span>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import fieldAccessRightMixin from '../mixins/fieldAccessRightsMixin';

export default {
    name: 'BidInformation',
    props: ['bidId'],
    components: {
    },
    mixins: [fieldAccessRightMixin],
    data: function () {
        return {
            loading: false,
            error: false,
            fields: {},
            yesColor: '#4caf50',
            noColor: '#f44336'
        };
    },
    watch: {
    },
    mounted () {
        this.$nextTick(() => {
            this.fetchData();
        });
    },
    methods: {
        fetchData () {
            if (!this.isBidsViewAllowed) {
                this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
                this.loading = false;
            } else if (this.bidId) {
                this.loading = true;
                this.$root.getBid(this.bidId).then((response) => {
                    if (response.status === 'ok') {
                        this.fields = response.result;

                        for (let i = 0; i < response.result.length; i++) {
                            if (response.result[i].brokerManager) {
                                const managers = JSON.parse(response.result[i].brokerManagersJSON);
                                if (managers) {
                                    managers.forEach((item) => {
                                        if (item.email === response.result[i].brokerManager) {
                                            this.fields.brokerManagerName = item.name;
                                            this.fields.brokerManagerPhone = item.phone;
                                        }
                                    });
                                }
                            }
                        }
                    }
                    this.loading = false;
                });
            }
        }
    }

};
</script>
