<template>
  <v-layout>
    <div class="pt-1 pb-1">
      <FileUpload v-if="!odometerUPLOADHidden"
                  dataCy="odometerUPLOADField"
                  :disabled="odometerUPLOADReadOnly || disabledFileImage"
                  ref="odometerUpload"
                  :image="true"
                  :avatar="false"
                  :odometer="true"
                  :pickOnClick="false"
                  :url="fieldUPLOAD ? $root.getImageUrl (folderName, fieldUPLOAD) : null"
                  :field="fieldUPLOAD"
                  @change="odometerUploaded($event, folderName)"
                  @remove="$emit('odometerRemove')">
      </FileUpload>
    </div>
    <v-flex>
      <v-text-field
        v-if="!locationOdometerHidden"
        dense outlined hide-details
        type="number"
        step="1"
        :disabled="locationOdometerReadOnly || disabled"
        v-model="odometerValue"
        :label="label ? label : $t('Loads.odometerData')"
        :loading="odometerUPLOADLoading"
        :prepend-inner-icon="$vuetify.breakpoint.xs ? null : 'mdi-counter'"
        :append-icon="!hideUploadBtn ? (fieldUPLOAD && fieldUPLOAD !== '' ? 'mdi-close' : 'mdi-camera') : null"
        @click:append="!hideUploadBtn ? (fieldUPLOAD && fieldUPLOAD !== '' ? odometerRemove() : odometerUpload()) : null"
        @change="$emit('odometerValueChanged', odometerValue)">
      </v-text-field>
    </v-flex>
  </v-layout>
</template>

<script>
import FileUpload from './FileUpload.vue';
import userFormsCommonMixin from '../mixins/userFormsCommonMixin';
import fieldLoadingMixin from '../mixins/fieldLoadingMixin';

export default {
  props: ['field', 'fieldUPLOAD', 'folderName', 'odometerUPLOADReadOnly', 'odometerUPLOADHidden', 'locationOdometerReadOnly', 'locationOdometerHidden', 'label', 'disabled', 'disabledFileImage', 'hideUploadBtn'],
  components: { FileUpload },
  mixins: [userFormsCommonMixin, fieldLoadingMixin],
  data: () => ({
    loading: false,
    error: false,
    odometerValue: 0,
    odometerUPLOADLoading: false
  }),
  mounted () {
    this.odometerValue = this.field;
  },
  methods: {
    odometerUpload () {
      if (this.$refs.odometerUpload) {
        this.$refs.odometerUpload.pickFile();
      }
    },
    odometerRemove () {
      this.$dialog.confirm(this.$root.t('PleaseConfirmYourChoice')).then(dialog => {
        this.$emit('odometerRemove');
      });
    },
    odometerUploaded (file, folderName) {
      if (!file) {
        return;
      }
      // Check is file has extention
      if (file.name.lastIndexOf('.') <= 0) {
        // TODO add error message here
        return;
      }

      this.odometerUPLOADLoading = true;

      let ext = '.' + file.name.split('.').pop();
      // Load file to coresponding img
      /*this.$root.readImageWithFileReader(file, (result) => {
      });*/
      // Upload actual file
      this.$root.uploadUserFile(folderName, file, (result) => {
        this.odometerUPLOADLoading = false;
        this.$emit('odometerUploaded', result.fileHash ? (result.fileHash + ext) : '');
        this.$forceUpdate();
      }, (error) => {
        // TODO handle error
      });
    }
  }
};
</script>
