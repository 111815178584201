<template>
  <div>
    <v-tabs v-model="documentsTab" grow class="mt-2" show-arrows>
      <v-tab key="photos" v-if="isDocumentVisible.loadPhotosUPLOAD">
        <v-badge color="red" dot v-if="isLoadPhotosWarning">
          {{ $t('Loads.LoadPhotos') }}&nbsp;({{loadPhotosUPLOADCount}})
        </v-badge>
        <span v-else>
          {{ $t('Loads.LoadPhotos') }}&nbsp;({{loadPhotosUPLOADCount}})
        </span>
      </v-tab>
      <v-tab key="ratecons" v-if="isDocumentVisible.rateConUPLOAD">
        <v-badge color="red" dot v-if="isRateConWarning">
          {{ $t('Loads.RateCon') }}&nbsp;({{rateConUPLOADCount}})
        </v-badge>
        <span v-else>
          {{ $t('Loads.RateCon') }}&nbsp;({{rateConUPLOADCount}})
        </span>
      </v-tab>
      <v-tab key="publicratecons" v-if="!isDriverOO && isDocumentVisible.publicRateConUPLOAD">
        <v-badge color="red" dot v-if="isPublicRateConWarning">
          {{ $t('Loads.PublicRateCon') }}&nbsp;({{publicRateConUPLOADCount}})
        </v-badge>
        <span v-else>
          {{ $t('Loads.PublicRateCon') }}&nbsp;({{publicRateConUPLOADCount}})
        </span>
      </v-tab>
      <v-tab key="do" v-if="isDocumentVisible.doUPLOAD">
        <v-badge color="red" dot v-if="isDOWarning">
          {{ $t('Loads.DeliveryOrder') }}&nbsp;({{doUPLOADCount}})
        </v-badge>
        <span v-else>
          {{ $t('Loads.DeliveryOrder') }}&nbsp;({{doUPLOADCount}})
        </span>
      </v-tab>
      <v-tab key="bol" v-if="isDocumentVisible.bolUPLOAD">
        <v-badge color="red" dot v-if="isBOLWarning">
          {{ $t('Loads.BOL') }}&nbsp;({{bolUPLOADCount}})
        </v-badge>
        <span v-else>
          {{ $t('Loads.BOL') }}&nbsp;({{bolUPLOADCount}})
        </span>
      </v-tab>
      <v-tab key="pod" v-if="isDocumentVisible.podUPLOAD">
        <v-badge color="red" dot v-if="isPODWarning">
          {{ $t('Loads.POD') }}&nbsp;({{podUPLOADCount}})
        </v-badge>
        <span v-else>
          {{ $t('Loads.POD') }}&nbsp;({{podUPLOADCount}})
        </span>
      </v-tab>
      <v-tab key="driversDocuments" v-if="isDocumentVisible.driversReportsUPLOAD">
        <span>
          {{ $t('Loads.driversReports') }}&nbsp;({{driversReportsUPLOADCount}})
        </span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="documentsTab"  style="min-height: 500px;">
      <v-tab-item key="photos" v-if="isDocumentVisible.loadPhotosUPLOAD">
        <v-card class="pa-2">
          <MultiFileUpload :field="loadPhotosUPLOAD"
                           :label="$t('Loads.LoadPhotos')"
                           uploadFolder="loadPhotos"
                           :deleteAllowed="deleteLoadPhotosAllowed"
                           :downloadAllowed="isDocumentDownloadable.loadPhotosUPLOAD"
                           :sendViaEmailAllowed="isDocumentAllowedToSent.loadPhotosUPLOAD"
                           :readonly="!isDocumentEditable.loadPhotosUPLOAD || readonly"
                           height="650"
                           :isImageEditing="isDocumentEditable.loadPhotosUPLOAD"
                           @change="($event, triggerSave) => { fileChange('loadPhotosUPLOAD', $event, triggerSave, () => { saveFiles('loadPhotosUPLOAD', triggerSave) }) }"/>
          <!--<v-btn v-if="!readonly && loadId && isFilesUpdated['loadPhotosUPLOAD']" color="primary" width="100%" @click="saveFiles('loadPhotosUPLOAD')"><v-icon left>fa-save</v-icon>{{$t('Save')}}</v-btn>-->
        </v-card>
      </v-tab-item>

      <v-tab-item key="ratecons" v-if="isDocumentVisible.rateConUPLOAD">
        <v-card class="pa-2">
          <MultiFileUpload :field="fields.rateConUPLOAD"
                           :label="$t('Loads.RateCon')"
                           uploadFolder="rateCon"
                           :deleteAllowed="deleteLoadDocumentsAllowed"
                           :downloadAllowed="isDocumentDownloadable.rateConUPLOAD"
                           :sendViaEmailAllowed="isDocumentAllowedToSent.rateConUPLOAD"
                           :readonly="!isDocumentEditable.rateConUPLOAD || readonly"
                           height="650"
                           :isImageEditing="isDocumentEditable.rateConUPLOAD"
                           @change="($event, triggerSave) => { fileChange('rateConUPLOAD', $event, triggerSave, () => { saveFiles('rateConUPLOAD', triggerSave) }) }"/>
          <!--<v-btn v-if="!readonly && loadId && isFilesUpdated['rateConUPLOAD']" color="primary" width="100%" @click="saveFiles('rateConUPLOAD')"><v-icon left>fa-save</v-icon>{{$t('Save')}}</v-btn>-->
        </v-card>
      </v-tab-item>

      <v-tab-item key="publicratecons" v-if="!isDriverOO && isDocumentVisible.publicRateConUPLOAD">
        <v-card class="pa-1">
          <MultiFileUpload :field="fields.publicRateConUPLOAD"
                           :label="$t('Loads.PublicRateCon')"
                           uploadFolder="publicRateCon"
                           :deleteAllowed="deleteLoadDocumentsAllowed"
                           :downloadAllowed="isDocumentDownloadable.publicRateConUPLOAD"
                           :sendViaEmailAllowed="isDocumentAllowedToSent.publicRateConUPLOAD"
                           :readonly="!isDocumentEditable.publicRateConUPLOAD || readonly"
                           height="650"
                           :isImageEditing="isDocumentEditable.publicRateConUPLOAD"
                           @change="($event, triggerSave) => { fileChange('publicRateConUPLOAD', $event, triggerSave, () => { saveFiles('publicRateConUPLOAD', triggerSave) }) }"/>
          <!--<v-btn v-if="!readonly && loadId && isFilesUpdated['publicRateConUPLOAD']" color="primary" width="100%" @click="saveFiles('publicRateConUPLOAD')"><v-icon left>fa-save</v-icon>{{$t('Save')}}</v-btn>-->
        </v-card>
      </v-tab-item>

      <v-tab-item key="do" v-if="isDocumentVisible.doUPLOAD">
        <v-card class="pa-2">
          <MultiFileUpload :field="fields.doUPLOAD"
                           :label="$t('Loads.DeliveryOrder')"
                           uploadFolder="deliveryOrder"
                           :deleteAllowed="deleteLoadDocumentsAllowed"
                           :downloadAllowed="isDocumentDownloadable.doUPLOAD"
                           :sendViaEmailAllowed="isDocumentAllowedToSent.doUPLOAD"
                           :readonly="!isDocumentEditable.doUPLOAD || readonly"
                           height="650"
                           :isImageEditing="isDocumentEditable.doUPLOAD"
                           @change="($event, triggerSave) => { fileChange('doUPLOAD', $event, triggerSave, () => { saveFiles('doUPLOAD', triggerSave) }) }"/>
          <!--<v-btn v-if="!readonly && loadId && isFilesUpdated['doUPLOAD']" color="primary" width="100%" @click="saveFiles('doUPLOAD')"><v-icon left>fa-save</v-icon>{{$t('Save')}}</v-btn>-->
        </v-card>
      </v-tab-item>

      <v-tab-item key="bol" v-if="isDocumentVisible.bolUPLOAD">
        <v-card class="pa-2">
          <MultiFileUpload :field="fields.bolUPLOAD"
                           :label="$t('Loads.BOL')"
                           uploadFolder="billOfLading"
                           :deleteAllowed="deleteLoadDocumentsAllowed"
                           :downloadAllowed="isDocumentDownloadable.bolUPLOAD"
                           :sendViaEmailAllowed="isDocumentAllowedToSent.bolUPLOAD"
                           :readonly="!isDocumentEditable.bolUPLOAD || readonly"
                           height="650"
                           :isImageEditing="isDocumentEditable.bolUPLOAD"
                           @change="($event, triggerSave) => { fileChange('bolUPLOAD', $event, triggerSave, () => { saveFiles('bolUPLOAD', triggerSave) }) }"/>
          <!--<v-btn v-if="!readonly && loadId && isFilesUpdated['bolUPLOAD']" color="primary" width="100%" @click="saveFiles('bolUPLOAD')"><v-icon left>fa-save</v-icon>{{$t('Save')}}</v-btn>-->
        </v-card>
      </v-tab-item>

      <v-tab-item key="pod" v-if="isDocumentVisible.podUPLOAD">
        <v-card class="pa-2">
          <MultiFileUpload :field="fields.podUPLOAD"
                           :label="$t('Loads.POD')"
                           uploadFolder="proofOfDelivery"
                           :deleteAllowed="deleteLoadDocumentsAllowed"
                           :downloadAllowed="isDocumentDownloadable.podUPLOAD"
                           :sendViaEmailAllowed="isDocumentAllowedToSent.podUPLOAD"
                           :readonly="!isDocumentEditable.podUPLOAD || readonly"
                           height="650"
                           :isImageEditing="isDocumentEditable.podUPLOAD"
                           @change="($event, triggerSave) => { fileChange('podUPLOAD', $event, triggerSave, () => { saveFiles('podUPLOAD', triggerSave) }) }"/>
          <!--<v-btn v-if="!readonly && loadId && isFilesUpdated['podUPLOAD']" color="primary" width="100%" @click="saveFiles('podUPLOAD')"><v-icon left>fa-save</v-icon>{{$t('Save')}}</v-btn>-->
        </v-card>
      </v-tab-item>

      <v-tab-item key="driversDocuments" v-if="isDocumentVisible.driversReportsUPLOAD">
        <v-card class="pa-2">
          <MultiFileUpload :field="fields.driversReportsUPLOAD"
                           :label="$t('Loads.driversReportsUPLOAD')"
                           uploadFolder="driversReports"
                           :deleteAllowed="deleteLoadDocumentsAllowed"
                           :downloadAllowed="isDocumentDownloadable.driversReportsUPLOAD"
                           :sendViaEmailAllowed="isDocumentAllowedToSent.driversReportsUPLOAD"
                           :readonly="(!isDocumentEditable.driversReportsUPLOAD || readonly) && !allowUploadDriverReports"
                           height="650"
                           :isImageEditing="isDocumentEditable.driversReportsUPLOAD"
                           @change="($event, triggerSave) => { fileChange('driversReportsUPLOAD', $event, triggerSave, () => { saveFiles('driversReportsUPLOAD', triggerSave) }) }"/>
          <!--<v-btn v-if="!readonly && loadId && isFilesUpdated['driversReportsUPLOAD']" color="primary" width="100%" @click="saveFiles('driversReportsUPLOAD')"><v-icon left>fa-save</v-icon>{{$t('Save')}}</v-btn>-->
        </v-card>
      </v-tab-item>

    </v-tabs-items>
  </div>
</template>

<script>
import MultiFileUpload from '../components/MultiFileUpload.vue';

export default {
  name: 'LoadDocuments',
  props: ['loadId', 'fields', 'readonly', 'deleteLoadPhotosAllowed', 'deleteLoadDocumentsAllowed', 'isDriverOO', 'allowUploadDriverReports'],
  components: { MultiFileUpload },
  data () {
    return {
      documentsTab: '',
      loadPhotosUPLOADCount: 0,
      rateConUPLOADCount: 0,
      publicRateConUPLOADCount: 0,
      doUPLOADCount: 0,
      bolUPLOADCount: 0,
      podUPLOADCount: 0,
      driversReportsUPLOADCount: 0,
      loadPhotosUPLOAD: '',
      rateConUPLOAD: '',
      publicRateConUPLOAD: '',
      doUPLOAD: '',
      bolUPLOAD: '',
      podUPLOAD: '',
      driversReportsUPLOAD: '',
      globalSettingsFields: {},
      isFilesUpdated: {},
      isDocumentVisible: {},
      isDocumentEditable: {},
      isDocumentDownloadable: {},
      isDocumentAllowedToSent: {}
    };
  },
  computed: {
    isLoadPhotosWarning () {
      return parseInt(this.globalSettingsFields['isLoadPhotosRequired']) === 1 && this.loadPhotosUPLOADCount === 0;
    },
    isPublicRateConWarning () {
      return false;
    },
    isRateConWarning () {
      if (this.isDriverOO) {
        return parseInt(this.globalSettingsFields['isRateConRequired']) === 1 && this.publicRateConUPLOADCount === 0;
      } else {
        return parseInt(this.globalSettingsFields['isRateConRequired']) === 1 && this.rateConUPLOADCount === 0;
      }
    },
    isDOWarning () {
      return parseInt(this.globalSettingsFields['isDeliveryOrderRequired']) === 1 && this.doUPLOADCount === 0;
    },
    isBOLWarning () {
      return parseInt(this.globalSettingsFields['isBillOfLandingRequired']) === 1 && this.bolUPLOADCount === 0;
    },
    isPODWarning () {
      return parseInt(this.globalSettingsFields['isProfOfDeliveryRequired']) === 1 && this.podUPLOADCount === 0;
    }
  },
  mounted () {
    this.updateFields(this.fields);

    this.$root.loadGlobalSettingsFields().then((response) => {
      if (response.status === 'ok') {
        this.globalSettingsFields = {};
        response.result.forEach((item) => {
          this.globalSettingsFields[item.name] = item.value;
        });
      }
    });

    this.$root.getAccessRightsByFields('loads', ['loadPhotosUPLOAD', 'rateConUPLOAD', 'publicRateConUPLOAD', 'doUPLOAD', 'bolUPLOAD', 'podUPLOAD', 'driversReportsUPLOAD']).then((response) => {
      let fieldName = '';
      let rights = null;
      let _r = [];
      if (response.status === 'ok') {
        for (fieldName in response.rights) {
          rights = response.rights[fieldName];
          _r = this.$root.getAccessRightsArrayFromNumber(rights);

          if (parseInt(_r[0]) === 0 && parseInt(_r[1]) === 1) {
            this.$set(this.isDocumentVisible, fieldName, true);
          }
          if (parseInt(_r[0]) === 0 && parseInt(_r[2]) === 1) {
            this.$set(this.isDocumentEditable, fieldName, true);
          }
          if (parseInt(_r[0]) === 0 && parseInt(_r[3]) === 1) {
            this.$set(this.isDocumentDownloadable, fieldName, true);
            this.$set(this.isDocumentAllowedToSent, fieldName, true);
          }
        }
      }
    });
  },
  methods: {
    updateFields (_fields) {
      if (_fields.hasOwnProperty('loadPhotosUPLOAD')) {
        this.loadPhotosUPLOADCount = _fields['loadPhotosUPLOAD'] ? JSON.parse(_fields['loadPhotosUPLOAD']).length : 0;
        this.loadPhotosUPLOAD = _fields['loadPhotosUPLOAD'] ? _fields['loadPhotosUPLOAD'] : '';
      }
      if (_fields.hasOwnProperty('rateConUPLOAD')) {
        this.rateConUPLOADCount = _fields['rateConUPLOAD'] ? JSON.parse(_fields['rateConUPLOAD']).length : 0;
        this.rateConUPLOAD = _fields['rateConUPLOAD'] ? _fields['rateConUPLOAD'] : '';
      }
      if (_fields.hasOwnProperty('publicRateConUPLOAD')) {
        this.publicRateConUPLOADCount = _fields['publicRateConUPLOAD'] ? JSON.parse(_fields['publicRateConUPLOAD']).length : 0;
        this.publicRateConUPLOAD = _fields['publicRateConUPLOAD'] ? _fields['publicRateConUPLOAD'] : '';
      }
      if (_fields.hasOwnProperty('doUPLOAD')) {
        this.doUPLOADCount = _fields['doUPLOAD'] ? JSON.parse(_fields['doUPLOAD']).length : 0;
        this.doUPLOAD = _fields['doUPLOAD'] ? _fields['doUPLOAD'] : '';
      }
      if (_fields.hasOwnProperty('bolUPLOAD')) {
        this.bolUPLOADCount = _fields['bolUPLOAD'] ? JSON.parse(_fields['bolUPLOAD']).length : 0;
        this.bolUPLOAD = _fields['bolUPLOAD'] ? _fields['bolUPLOAD'] : '';
      }
      if (_fields.hasOwnProperty('podUPLOAD')) {
        this.podUPLOADCount = _fields['podUPLOAD'] ? JSON.parse(_fields['podUPLOAD']).length : 0;
        this.podUPLOAD = _fields['podUPLOAD'] ? _fields['podUPLOAD'] : '';
      }
      if (_fields.hasOwnProperty('driversReportsUPLOAD')) {
        this.driversReportsUPLOADCount = _fields['driversReportsUPLOAD'] ? JSON.parse(_fields['driversReportsUPLOAD']).length : 0;
        this.driversReportsUPLOAD = _fields['driversReportsUPLOAD'] ? _fields['driversReportsUPLOAD'] : '';
      }
    },
    fileChange (fieldName, fieldValue, triggerSave = false, cb = null) {
      this.$set(this, fieldName, fieldValue);
      if (triggerSave && cb) {
        cb();
      } else {
        this.$set(this.isFilesUpdated, fieldName, true);
      }
    },
    saveFiles (field, triggerSave = false) {
      const savePromise = () => {
        return new Promise((resolve, reject) => {
          let fields = {};
          fields[field] = this[field];
          this.$root.saveLoad(fields, this.loadId).then(() => {
            this.$set(this.isFilesUpdated, field, false);
            this.updateFields(fields);
            resolve();
          });
        });
      }
      if (!this.loadId) return;

      if (triggerSave) {
        return savePromise();
      }

      this.$dialog.confirm(this.$root.t('Loads.SaveFilesConfirmationMsg')).then(dialog => {
        return savePromise();
      }).catch(() => {
      });
    }
  }
};
</script>
