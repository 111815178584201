<template>
  <div v-if="location.onSiteByDriverTS > 0">
    <div class="text-no-wrap">
      <b>{{$t('Loads.ActualTime')}}</b>:&nbsp;
      <label v-if="location.startedByDriverTS > 0 && location.onSiteByDriverTS > 0">
        {{$root.showDuration(location.startedByDriverTS - location.startedByDriverTS)}}
        (<span v-if="prevLocation && prevLocation.duration >= (location.onSiteByDriverTS - location.startedByDriverTS)" style="color: #4caf50; font-weight:600;">
          -{{$root.showDuration(prevLocation.duration - (location.onSiteByDriverTS - location.startedByDriverTS))}}
        </span>
        <span v-else-if="prevLocation" style="color: #f44336; font-weight:600;">
          +{{$root.showDuration((location.onSiteByDriverTS - location.startedByDriverTS) - prevLocation.duration)}}
        </span>)
      </label>
      <br/>
      <span v-if="location.odometerEnd && location.odometerStart" class="text-no-wrap">
        <b>{{$t('Loads.ActualDistance')}}</b>:&nbsp; <span>{{location.odometerEnd - location.odometerStart}}{{$t('milesShort')}}</span>
        (<span v-if="$root.metersToMiles(prevLocation.distance) >= (location.odometerEnd - location.odometerStart)" style="color: #4caf50; font-weight:600;">
          -{{$root.metersToMiles(prevLocation.distance) - (location.odometerEnd - location.odometerStart)}}{{$t('milesShort')}}
        </span>
        <span v-else style="color: #f44336; font-weight:600;">
          +{{((location.odometerEnd - location.odometerStart) - $root.metersToMiles(prevLocation.distance))}}{{$t('milesShort')}}
        </span>)
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActualDurationAndDistanceSnippet',
  props: ['prevLocation', 'location']
};
</script>
