<template>
  <div class="text-left">
    <div v-if="error">
      <v-flex class="text-center error pa-2">
        <h1 class="title">{{error}}</h1>
      </v-flex>
    </div>
    <div v-else>
      <v-overlay :value="loading" :absolute="true">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>
      <template v-if="!onlyModal">
        <v-layout class="text-right" v-if="lastComment">
          <v-flex xs12>
            <v-alert v-if="lastComment.flag === $store.getters.troubleShootingFlags.EDIT_REQUEST"
                     @click="onComment(0)"
                     type="info"
                     dense
                     border="left"
                     prominent
                     class="text-left">
              <v-row align="center">
                <v-col class="grow">
                  <b>{{$t('Loads.EditingUnlockRequested')}}</b>&nbsp;<br/>
                  <span class="label">
                      <UserSnippet :user-data="{id: lastComment.createdBy, avatarUPLOAD: lastComment.userAvatar, firstName: lastComment.userFirstName, lastName: lastComment.userLastName}" :avatar-only="true"/>
                      &nbsp;<b>{{$t('Created')}}</b>: {{lastComment.createdPST}}</span>&nbsp;
                  <br/><br/>
                  <span v-html="lastComment.comment ? lastComment.comment.replace(/(?:\r\n|\r|\n)/g, '<br/>') : ''"></span>
                </v-col>
              </v-row>
            </v-alert>

            <v-alert v-if="lastComment.flag === $store.getters.troubleShootingFlags.EDIT_OVERRIDE"
                     @click="onComment(0)"
                     type="info"
                     dense
                     border="left"
                     prominent
                     class="text-left">
              <v-row align="center">
                <v-col class="grow">
                  <b>{{$t('Loads.OverrideEditLock')}}</b>&nbsp;<br/>
                  <span class="label">
                      <UserSnippet :user-data="{id: lastComment.createdBy, avatarUPLOAD: lastComment.userAvatar, firstName: lastComment.userFirstName, lastName: lastComment.userLastName}" :avatar-only="true"/>
                      &nbsp;<b>{{$t('Created')}}</b>: {{lastComment.createdPST}}</span>&nbsp;
                  <br/><br/>
                  <span v-html="lastComment.comment ? lastComment.comment.replace(/(?:\r\n|\r|\n)/g, '<br/>') : ''"></span>
                  <br/>
                  <small>{{$t('Loads.AfterEditingMarkAsRefined')}}</small>
                </v-col>
              </v-row>
            </v-alert>

            <v-alert v-if="lastComment.flag === $store.getters.troubleShootingFlags.VERIFIED"
                     @click="onComment(0)"
                     type="success"
                     dense
                     border="left"
                     prominent
                     class="text-left">
              <v-row align="center">
                <v-col class="grow">
                  <b>{{$t('Loads.Verified')}}</b>&nbsp;<br/>
                  <span class="label">
                      <UserSnippet :user-data="{id: lastComment.createdBy, avatarUPLOAD: lastComment.userAvatar, firstName: lastComment.userFirstName, lastName: lastComment.userLastName}" :avatar-only="true"/>
                      &nbsp;<b>{{$t('Created')}}</b>: {{lastComment.createdPST}}</span>&nbsp;
                  <br/><br/>
                  <span v-html="lastComment.comment ? lastComment.comment.replace(/(?:\r\n|\r|\n)/g, '<br/>') : ''"></span>
                </v-col>
              </v-row>
            </v-alert>

            <v-alert v-if="lastComment.flag === $store.getters.troubleShootingFlags.REFINE"
                     @click="onComment(0)"
                     type="error"
                     dense
                     border="left"
                     prominent
                     class="text-left">
              <v-row align="center">
                <v-col class="grow">
                  <b>{{$t('Loads.Refine')}}</b>&nbsp;<br/>
                  <span class="label">
                      <UserSnippet :user-data="{id: lastComment.createdBy, avatarUPLOAD: lastComment.userAvatar, firstName: lastComment.userFirstName, lastName: lastComment.userLastName}" :avatar-only="true"/>
                      &nbsp;<b>{{$t('Created')}}</b>: {{lastComment.createdPST}}</span>&nbsp;
                  <br/><br/>
                  <span v-html="lastComment.comment ? lastComment.comment.replace(/(?:\r\n|\r|\n)/g, '<br/>') : ''"></span>
                </v-col>
              </v-row>
            </v-alert>

            <v-alert v-if="lastComment.flag === $store.getters.troubleShootingFlags.REFINED"
                     @click="onComment(0)"
                     type="info"
                     dense
                     border="left"
                     prominent
                     class="text-left">
              <v-row align="center">
                <v-col class="grow">
                  <b>{{$t('Loads.Refined')}}</b>&nbsp;<br/>
                  <span class="label">
                      <UserSnippet :user-data="{id: lastComment.createdBy, avatarUPLOAD: lastComment.userAvatar, firstName: lastComment.userFirstName, lastName: lastComment.userLastName}" :avatar-only="true"/>
                      &nbsp;<b>{{$t('Created')}}</b>: {{lastComment.createdPST}}</span>&nbsp;
                  <br/><br/>
                  <span v-html="lastComment.comment ? lastComment.comment.replace(/(?:\r\n|\r|\n)/g, '<br/>') : ''"></span>
                </v-col>
              </v-row>
            </v-alert>

            <v-alert v-if="lastComment.flag === $store.getters.troubleShootingFlags.WAITING_FURTHER_ACTIONS"
                     @click="onComment(0)"
                     type="warning"
                     dense
                     border="left"
                     prominent
                     class="text-left">
              <v-row align="center">
                <v-col class="grow">
                  <b>{{$t('Loads.MarkAsWaitingFurtherActions')}}</b>&nbsp;<br/>
                  <span class="label">
                      <UserSnippet :user-data="{id: lastComment.createdBy, avatarUPLOAD: lastComment.userAvatar, firstName: lastComment.userFirstName, lastName: lastComment.userLastName}" :avatar-only="true"/>
                      &nbsp;<b>{{$t('Created')}}</b>: {{lastComment.createdPST}}</span>&nbsp;
                  <br/><br/>
                  <span v-html="lastComment.comment ? lastComment.comment.replace(/(?:\r\n|\r|\n)/g, '<br/>') : ''"></span>
                </v-col>
              </v-row>
            </v-alert>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12 class="text-center" v-if="$root.isMobile">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  dark
                  style="width: calc(100% - 1rem)"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{$t('Invoices.MarkCommentsActions')}}<v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="onComment(0)">
                  <v-list-item-title>
                    {{$t('Comments')}} ({{items.length}})
                  </v-list-item-title>
                </v-list-item>
                <template v-if="isVerifyAllowed">
                  <v-list-item @click="onComment($store.getters.troubleShootingFlags.VERIFIED)" v-if="lastComment.flag !== $store.getters.troubleShootingFlags.VERIFIED">
                    <v-list-item-title>
                      <v-icon left color="success">mdi-check-decagram</v-icon>
                      {{$t('Loads.MarkAsVerified')}}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-if="isVerifyAllowed">
                  <v-list-item @click="onComment($store.getters.troubleShootingFlags.REFINE)">
                    <v-list-item-title>
                      <v-icon left color="error">mdi-alert-decagram</v-icon>
                      {{$t('Loads.MarkAsRefine')}}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-if="lastComment.flag === $store.getters.troubleShootingFlags.REFINE || isOverrideEditLock > 0">
                  <v-list-item @click="onComment($store.getters.troubleShootingFlags.REFINED)">
                    <v-list-item-title>
                      <v-icon left color="info">mdi-check-decagram</v-icon>
                      {{$t('Loads.MarkAsRefined')}}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-if="!isOverrideEditLockAllowed && $root.isHideTopEditBtn && lastComment.flag !== $store.getters.troubleShootingFlags.EDIT_REQUEST">
                  <v-list-item @click="onComment($store.getters.troubleShootingFlags.EDIT_REQUEST)">
                    <v-list-item-title>
                      <v-icon left>mdi-lock-open-variant</v-icon>
                      {{$t('Loads.RequestEditUnlock')}}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-if="lastComment.flag !== $store.getters.troubleShootingFlags.WAITING_FURTHER_ACTIONS">
                  <v-list-item @click="onComment($store.getters.troubleShootingFlags.WAITING_FURTHER_ACTIONS)">
                    <v-list-item-title>
                      <v-icon left color="warning">mdi-check-decagram</v-icon>
                      {{$t('Loads.MarkAsWaitingFurtherActions')}}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <v-list-item @click="onComment(0)">
                  <v-list-item-title>
                    <v-icon left>mdi-comment-outline</v-icon>
                    {{$t('AddComment')}}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-flex>
          <v-flex xs12 class="text-right" v-else>
            <v-btn class="ma-1" @click="onComment(0)">
              {{$t('Comments')}} ({{items.length}})
            </v-btn>
            <template v-if="isVerifyAllowed">
              <v-btn class="ma-1" @click="onComment($store.getters.troubleShootingFlags.VERIFIED)" v-if="lastComment.flag !== $store.getters.troubleShootingFlags.VERIFIED">
                <v-icon left color="success">mdi-check-decagram</v-icon>
                {{$t('Loads.MarkAsVerified')}}
              </v-btn>
            </template>
            <template v-if="isVerifyAllowed">
              <v-btn class="ma-1" @click="onComment($store.getters.troubleShootingFlags.REFINE)">
                <v-icon left color="error">mdi-alert-decagram</v-icon>
                {{$t('Loads.MarkAsRefine')}}
              </v-btn>
            </template>
            <template v-if="lastComment.flag === $store.getters.troubleShootingFlags.REFINE || isOverrideEditLock > 0">
              <v-btn class="ma-1" @click="onComment($store.getters.troubleShootingFlags.REFINED)">
                <v-icon left color="info">mdi-check-decagram</v-icon>
                {{$t('Loads.MarkAsRefined')}}
              </v-btn>
            </template>

            <template v-if="!isOverrideEditLockAllowed && $root.isHideTopEditBtn && lastComment.flag !== $store.getters.troubleShootingFlags.EDIT_REQUEST">
              <v-btn class="mr-1 mt-1" @click="onComment($store.getters.troubleShootingFlags.EDIT_REQUEST)">
                <v-icon left>mdi-lock-open-variant</v-icon>
                {{$t('Loads.RequestEditUnlock')}}
              </v-btn>
            </template>
            <template v-if="lastComment.flag !== $store.getters.troubleShootingFlags.WAITING_FURTHER_ACTIONS">
              <v-btn class="ma-1" @click="onComment($store.getters.troubleShootingFlags.WAITING_FURTHER_ACTIONS)">
                <v-icon left color="warning">mdi-check-decagram</v-icon>
                {{$t('Loads.MarkAsWaitingFurtherActions')}}
              </v-btn>
            </template>
            <v-btn class="ma-1" @click="onComment(0)">
              <v-icon left>mdi-comment-outline</v-icon>
              {{$t('AddComment')}}
            </v-btn>
          </v-flex>
        </v-layout>
      </template>

      <v-dialog v-model="commentModal" scrollable>
        <v-card>
          <v-card-title>
            {{$t('Comments')}}&nbsp;({{items.length}})
            <v-spacer></v-spacer>
            <v-btn icon @click="commentModal = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <v-list three-line>
              <!--<v-list-item>
                <v-list-item-title></v-list-item-title>
              </v-list-item>-->
              <template v-for="(item, index) in items">
                <v-list-item :key="'comment'+index">
                  <v-list-item-avatar v-if="!$root.isMobile">
                    <UserSnippet :userData="{ id: item.createdBy, avatarUPLOAD: item.userAvatar, firstName: item.userFirstName, lastName: item.userLastName, role: item.userRole }" :avatarOnly="true"/>
                  </v-list-item-avatar>
                  <v-list-item-content color="alert">
                    <v-list-item-title>

                      <v-alert type="info" dense border="left" prominent class="text-left" v-if="item.flag === $store.getters.troubleShootingFlags.EDIT_REQUEST">
                        <b>{{$t('Loads.EditingUnlockRequested')}}</b>&nbsp;<br/>
                        <span v-html="item.comment"></span>
                      </v-alert>

                      <v-alert type="info" dense border="left" prominent class="text-left" v-else-if="item.flag === $store.getters.troubleShootingFlags.EDIT_OVERRIDE">
                        <b>{{$t('Loads.OverrideEditLock')}}</b>&nbsp;<br/>
                        <span v-html="item.comment"></span>
                      </v-alert>

                      <v-alert type="success" dense border="left" prominent class="text-left" v-else-if="item.flag === $store.getters.troubleShootingFlags.VERIFIED">
                        <b>{{$t('Loads.Verified')}}</b>&nbsp;<br/>
                        <span v-html="item.comment"></span>
                      </v-alert>

                      <v-alert type="error" dense border="left" prominent class="text-left" v-else-if="item.flag === $store.getters.troubleShootingFlags.REFINE">
                        <b>{{$t('Loads.Refine')}}</b>&nbsp;<br/>
                        <span v-html="item.comment"></span>
                      </v-alert>

                      <v-alert type="info" dense border="left" prominent class="text-left" v-else-if="item.flag === $store.getters.troubleShootingFlags.REFINED">
                        <b>{{$t('Loads.Refined')}}</b>&nbsp;<br/>
                        <span v-html="item.comment"></span>
                      </v-alert>

                      <v-alert type="warning" dense border="left" prominent class="text-left" v-else-if="item.flag === $store.getters.troubleShootingFlags.WAITING_FURTHER_ACTIONS">
                        <b>{{$t('Loads.MarkAsWaitingFurtherActions')}}</b>&nbsp;<br/>
                        <span v-html="item.comment"></span>
                      </v-alert>

                      <v-card
                        v-else
                        class="pa-5"
                        shaped outlined
                        v-html="item.comment"
                      ></v-card>
                    </v-list-item-title>
                    <v-layout wrap>
                      <template v-for="(file, index) in item.files">
                        <v-flex xs12 md6 lg4 xl3 :key="file.hash+index+item.id">
                          <img  v-img:checks :src="$root.getImageUrl('tasksAtt', file.hash, file.filename)"/>
                        </v-flex>
                      </template>
                    </v-layout>
                    <v-list-item-subtitle>
                      {{item.userFirstName}}&nbsp;{{item.userLastName}}&nbsp;&mdash;&nbsp;<span class="font-italic">{{item.createdPST}}</span>
                      <template v-if="$store.getters.currentUser.id === item.createdBy || $store.getters['isUserRoleSuperAdmin']">
                        <v-btn small icon @click="onEditComment(item)"><v-icon small>mdi-pencil</v-icon></v-btn>
                        <v-btn small icon @click="onRemoveComment(item.id)"><v-icon small color="error">mdi-delete</v-icon></v-btn>
                      </template>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider/>
              </template>
            </v-list>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title><h4>{{$t('Tasks.NewComment')}}</h4></v-list-item-title>
                  <v-layout>
                    <v-flex xs12 class="pl-2">
                      <v-radio-group
                        v-model="selectedCommentFlag"
                        row
                      >
                        <v-radio :value="0">
                          <template v-slot:label>
                            <v-icon left>mdi-comment-outline</v-icon>
                            {{$t('Comment')}}
                          </template>
                        </v-radio>
                        <v-radio :value="$store.getters.troubleShootingFlags.VERIFIED">
                          <template v-slot:label>
                            <v-icon left color="success">mdi-check-decagram</v-icon>
                            {{$t('Loads.MarkAsVerified')}}
                          </template>
                        </v-radio>
                        <v-radio :value="$store.getters.troubleShootingFlags.REFINE">
                          <template v-slot:label>
                            <v-icon left color="error">mdi-alert-decagram</v-icon>
                            {{$t('Loads.MarkAsRefine')}}
                          </template>
                        </v-radio>
                        <v-radio :value="$store.getters.troubleShootingFlags.REFINED">
                          <template v-slot:label>
                            <v-icon left color="info">mdi-check-decagram</v-icon>
                            {{$t('Loads.MarkAsRefined')}}
                          </template>
                        </v-radio>
                        <v-radio :value="$store.getters.troubleShootingFlags.EDIT_REQUEST">
                          <template v-slot:label>
                            <v-icon left>mdi-lock-open-variant</v-icon>
                            {{$t('Loads.RequestEditUnlock')}}
                          </template>
                        </v-radio>
                        <v-radio :value="$store.getters.troubleShootingFlags.WAITING_FURTHER_ACTIONS">
                          <template v-slot:label>
                            <v-icon left color="warning">mdi-check-decagram</v-icon>
                            {{$t('Loads.MarkAsWaitingFurtherActions')}}
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>

                  <v-textarea v-if="$root.isMobile"
                              v-model="newComment"
                              rows="4"
                              :placeholder="$root.t('Tasks.NewComment')"
                              outlined dense/>
                    <div style="background-color:#fff;color: #000;" class="vue-editor" v-else>
                      <VueEditor v-model="newComment" :editorToolbar="$root.customToolbar"/>
                    </div>
                  <MultiFileUpload :field="attUPLOAD"
                                   @change="fileChange('attUPLOAD', $event)"
                                   fieldName="attUPLOAD"
                                   :label="$t('Tasks.attUPLOAD')"
                                   uploadFolder="tasksAtt"
                                   :downloadAllowed="isFieldDownloadAllowed('attUPLOAD')"
                                   :deleteAllowed="!isFieldReadOnly('attUPLOAD')"
                                   :readonly="isFieldReadOnly('attUPLOAD')"
                                   v-if="!isFieldHidden('attUPLOAD')" ></MultiFileUpload>
                  <v-list-item-subtitle class="text-right mt-1">
                    <v-btn v-if="editCommentId > 0"
                           class="ma-1"
                           @click="() => { editCommentId = 0; newComment = ''; }"
                           :style="$root.isMobile ? { width: '100%' } : {}">
                      <v-icon left color="error">mdi-cancel</v-icon>{{$t('Tasks.CancelEditing')}}
                    </v-btn>
                    <v-btn v-if="editCommentId > 0"
                           class="success ma-1"
                           :disabled="!newComment"
                           @click="postComment"
                           :style="$root.isMobile ? { width: '100%' } : {}">
                      <v-icon left>mdi-comment</v-icon>{{$t('Tasks.UpdateComment')}}
                    </v-btn>
                    <v-btn v-else
                           class="primary ma-1"
                           :disabled="!newComment && isAttChanged"
                           @click="postComment"
                           :style="$root.isMobile ? { width: '100%' } : {}">
                      <v-icon left>mdi-comment</v-icon>{{$t('Tasks.PostComment')}}
                    </v-btn>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import MultiFileUpload from '../components/MultiFileUpload.vue';
import userFormsCommonMixin from '../mixins/userFormsCommonMixin';
import fieldLoadingMixin from '../mixins/fieldLoadingMixin';
import fieldAccessRightMixin from '../mixins/fieldAccessRightsMixin';
import troubleShootingMixin from '../mixins/troubleShootingMixin';
import UserSnippet from '../components/UserSnippet';
import { VueEditor } from 'vue2-editor';

export default {
  name: 'TroubleShootingComments',
  components: { UserSnippet, MultiFileUpload, VueEditor },
  mixins: [ userFormsCommonMixin, fieldLoadingMixin, fieldAccessRightMixin, troubleShootingMixin ],
  props: ['itemType', 'itemId', 'isOverrideEditLock', 'isOverrideEditLockAllowed', 'onlyModal'],
  data: () => ({
    items: [],
    newComment: '',
    attUPLOAD: '[]',
    editCommentId: 0,
    selectedCommentFlag: 0,
    isAttChanged: false,
    lastComment: {
      flag: 0
    },
    loading: false,
    error: false,
    valid: true,
    commentModal: false
  }),
  computed: {
    isVerifyAllowed () {
      if (this.itemType === this.$store.getters.troubleShootingTypes.LOADS) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.loadsRights['isVerifyAllowed']);
      } else if (this.itemType === this.$store.getters.troubleShootingTypes.INVOICES) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.invoicesRights['isVerifyAllowed']);
      }
      return false;
    },
  },
  watch: {
  },
  mounted () {
    this.fetchData();
  },
  methods: {
    onComment (flag) {
      if (flag === this.$store.getters.troubleShootingFlags.VERIFIED) {
        this.sendTroubleShootingComment(0, this.itemType, this.itemId, 'Verified', '[]', this.$store.getters.troubleShootingFlags.VERIFIED).then(() => {
          this.fetchData();
          this.$emit('fetch');
        });
      } else {
        this.selectedCommentFlag = flag;
        this.commentModal = true;
      }
    },
    fileChange (fieldName, fieldValue) {
      this.$set(this, fieldName, fieldValue);
      this.isAttChanged = true;
    },
    postComment () {
      if (this.editCommentId > 0) {
        this.updateTroubleShootingComment(this.editCommentId, this.newComment, this.attUPLOAD).then(() => {
          this.isAttChanged = false;
          this.selectedCommentFlag = 0;
          this.commentModal = false;
          this.newComment = '';
          this.attUPLOAD = '[]';
          this.editCommentId = 0;
          this.fetchData();
        });
      } else {
        this.sendTroubleShootingComment(0, this.itemType, this.itemId, this.newComment, this.attUPLOAD, this.selectedCommentFlag).then(() => {
          this.isAttChanged = false;
          this.selectedCommentFlag = 0;
          this.commentModal = false;
          this.newComment = '';
          this.attUPLOAD = '[]';
          this.editCommentId = 0;
          this.fetchData();
        });
      }
    },
    onRemoveComment (id) {
      this.$dialog.confirm(this.$root.t('Tasks.RemoveCommentConfirmationMsg')).then(dialog => {
        this.removeTroubleShootingComment(id).then(() => {
          this.$emit('removed');
          this.fetchData();
        });
      });
    },
    onEditComment (item) {
      this.editCommentId = item.id;
      this.newComment = item.comment;
      this.attUPLOAD = item.attUPLOAD;
    },
    fetchData () {
      let i = 0;
      if (!this.itemType || !this.itemId) return;
      /* if (!this.$route.params.id && !this.isInvoicesCreateAllowed) {
          this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
          this.loading = false;
          return;
        }

        if (this.$route.params.id) {
          if (!this.isInvoicesViewAllowed || !this.isInvoicesEditAllowed) {
            this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
            this.loading = false;
            return;
          }
        }

        this.updatingEnabled = true;
        this.applyRights('', 'invoices', {
          deleteDocuments: 0
        }); */
      this.loading = true;
      this.getTroubleShootingComments(this.itemType, this.itemId).then((response) => {
        if (response.status === 'ok') {
          this.$set(this, 'items', response.results);
          for (i = response.results.length - 1; i >= 0; i--) {
            if (response.results[i].flag > 0) {
              this.$set(this, 'lastComment', response.results[i]);
              break;
            }
          }
          for (i = 0; i < this.items.length; i++) {
            if (this.items[i].attUPLOAD && this.items[i].attUPLOAD !== '[]') {
              this.items[i].files = JSON.parse(this.items[i].attUPLOAD);
            }
          }
          this.loading = false;
        }
      });
    }
  }
};
</script>
