<template>
  <div>
    <v-overlay :value="loading" :absolute="true" v-if="$route.name !== 'reportPrint'">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <TruckReportTemplate v-if="$route.name === 'reportPrint'"
                         :entries="$route.params.report_number > 0 ? null : loads"
                         :totals="totalsCounts"
                         :reportData="reportData"
                         :from="from"
                         :to="to"
                         :truckName="truckName"
                         :currentDateTime="currentDateTime"
                         :key="'reportTemplateKey' + loads.length"
                         :companyId="companyId"
    />
    <v-card v-else>
      <v-card-title :style="{ backgroundColor: $root.currentTheme.background }" v-if="truckId > 0">
        <v-btn @click="$root.route('/reports/trucks')" icon><v-icon>mdi-chevron-left</v-icon></v-btn>
        <a @click="$root.onShowTruckPreview(truckId, truckName)">{{truckName}}</a>
      </v-card-title>
      <!--<v-switch
        label="By Invoices"
        v-model="isByInvoices"
        @change="onByInvoicesSWITCH"
      ></v-switch>-->

      <TruckTotals :from="from"
                   :to="to"
                   :key="from+to"
                   :truckId="$route.params.item"/>
      <v-divider/>
      <ReportActions v-if="truckId > 0"
                     :userId="truckId"
                     :from="from"
                     :to="to"
                     :paramsType="$route.params.type"
                     :selectedItems="selectedItems"
                     :hideActionsBtn="true"
                     @create-report-group="createReport"
                     @mark-selected-as-payed-and-create-report-group="markSelectedAsPayedAndCreateReportGroup"
                     @show-saved-reports="onShowReportsModal"
      />
      <!--<FiltersPanel
        ref="filtersPanel"
        prefix="loadsList"
        outlined
        :isExpansionPanel="$root.isMobile"
        :loading="loading"
        :items="filtersItems"
        :key="filtersKey"
        @filterBrokerKeyUp="filterBrokerSearch"
        @change="onFilterChanged"/>-->

      <v-layout v-if="truckId > 0">
          <v-flex shrink>
            <FiltersPanel
              ref="filtersPanel"
              prefix="dispatchersReport"
              outlined
              :isExpansionPanel="$root.isMobile"
              :loading="loading"
              :items="filtersItems"
              :key="filtersKey"
              @filterBrokerKeyUp="filterBrokerSearch"
              @change="onFilterChanged"/>
          </v-flex>
          <v-flex>
            <v-text-field
              class="mt-1"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Search"
              single-line
              outlined dense
              hide-details
            />
          </v-flex>
        <!--<v-flex shrink class="text-right" v-if="$root.isFullFinancialDataAllowedToView && truckId > 0">
          <v-btn class="ma-1" :disabled="selectedItems.length === 0" @click="createReportGroup(truckId, lastReportsGroupNumber + 1, $route.params.type, from, to)"><v-icon left>mdi-clipboard-check-outline</v-icon> {{$t('Reports.CreateReportsGroup')}}</v-btn>
        </v-flex>-->
      </v-layout>
      <!--<hr/>
      {{loads}}
      <hr/>-->
      <v-list v-if="$root.isMobile && !isByInvoices && truckId > 0">
        <v-list-item-group
          v-model="selectedItems"
          multiple
          active-class=""
        >
          <template v-for="item in loads">
            <v-list-item :key="item.id">
              <template v-slot:default="{ active }">
                <v-list-item-icon>
                  <v-checkbox :input-value="active"/>
                </v-list-item-icon>
                <v-list-item-content @click.stop="$root.onShowLoadPreview(item.id)">
                  <v-list-item-title>
                    <v-avatar style="cursor: pointer;"
                              class="mr-1"
                              :size="24"
                              @click.stop="$root.onShowUserPreview(item.dispatcher, item.dispatcherName)"
                              :color="(item.dispatcherInitials && !item.dispatcherAvatar)?'#015aaa':null">
                      <v-img :src="$root.getImageUrl('avatar', item.dispatcherAvatar)" v-if="item.dispatcherAvatar"></v-img>
                      <span class="white--text" v-else-if="item.dispatcherInitials">{{item.dispatcherInitials}}</span>
                      <v-icon large v-else>account_circle</v-icon>
                    </v-avatar>
                    <b>#{{item.id}}</b>
                    <v-chip small color="success" class="ml-1">{{$root.moneyValue(item.rate, 2)}}</v-chip>
                  </v-list-item-title>
                  <v-list-item-title>
                    {{item.pickupLocationName}}<br/>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{item.startedPST}}
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{item.deliveryLocationName}}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{item.endedPST}}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-layout>
                      <v-flex shrink>
                        {{$t('Reports.DistanceShort')}}:
                      </v-flex>
                      <v-flex shrink>
                          <v-icon left small>mdi-map</v-icon>{{item.totalLoadMilesByMaps.toLocaleString()}}&nbsp;{{$t('milesShort')}}
                      </v-flex>
                      <v-flex shrink>
                        <v-tooltip bottom v-if="isMapsOdometerTreshholdReached(item.milesByMaps, item.milesByOdometer)">
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" x-small color="warning" class="ml-1">
                              <v-icon left small>mdi-counter</v-icon>
                              {{item.milesByOdometer.toLocaleString()}}&nbsp;{{$t('milesShort')}}
                            </v-chip>
                          </template>
                          <span v-if="item.milesByMaps > item.milesByOdometer">{{$tc('Reports.MilesByMapsMoreThenByOdometer', item.milesByMaps - item.milesByOdometer)}}</span>
                          <span v-if="item.milesByMaps < item.milesByOdometer">{{$tc('Reports.MilesByOdometerMoreThenByMaps', item.milesByOdometer - item.milesByMaps)}}</span>
                        </v-tooltip>
                        <span v-else>
                          <v-chip x-small color="success" class="ml-1">
                            <v-icon left small>mdi-counter</v-icon>{{item.milesByOdometer.toLocaleString()}}&nbsp;{{$t('milesShort')}}
                          </v-chip>
                        </span>
                      </v-flex>
                      <v-flex shrink v-if="item.isOverriddenFromDriver">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-account-check</v-icon>
                          </template>
                          <span>Overridden from driver</span>
                        </v-tooltip>
                      </v-flex>
                    </v-layout>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{$t('Reports.RatePerMileShort')}}:&nbsp;
                    <v-icon left small>mdi-map</v-icon>${{parseFloat(item.truckRatePerMileByMaps).round(2).toLocaleString()}}&nbsp;
                    <v-icon left small>mdi-counter</v-icon>${{parseFloat(item.truckRatePerMileByOdometer).round(2).toLocaleString()}}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="text-wrap">
                    <v-chip class="ma-1" small>
                      {{parseFloat(item.truckDistancePercentByOdometer*100.0).toFixed(0)}}%
                    </v-chip>
                    <v-chip class="ma-1" small>
                      {{$t('Reports.ExpensesShort')}}: <b>{{$root.moneyValue(item.expenses, 2)}}</b>
                    </v-chip>
                    <v-chip class="ma-1" small>
                      {{$t('Reports.Net')}}: <b>{{$root.moneyValue(item.net, 2)}}</b>
                    </v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
            <v-divider :key="'div' + item.id"/>
          </template>
        </v-list-item-group>
      </v-list>
      <v-data-table
        v-else-if="!isByInvoices && truckId > 0"
        :headers="headers"
        :items="loads"
        :search="search"
        :items-per-page="-1"
        :hide-default-footer="true"
        v-model="selectedItems"
        height="calc(100vh - 200px)"
        fixed-header
        show-select
        @item-selected="onItemSelected"
        @toggle-select-all="onItemSelected"
        @click:row="$root.onShowLoadPreview($event.id, truckId)"
      >
        <template v-slot:item.status="{ item }">
          <div class="text-no-wrap">
            <v-icon v-if="item.status.phaseIcon" v-html="item.status.phaseIcon" :color="item.status.phaseColor" class="mr-1"></v-icon>
            <v-icon v-if="item.status.stageIcon" small v-html="item.status.stageIcon" :color="item.status.stageColor" class="mr-1"></v-icon>
            <v-icon v-if="item.isReturnToHQ === 1" color="success" class="mr-1">mdi-redo-variant</v-icon>
          </div>
        </template>

        <template v-slot:item.started="{ item }">
          <div class="text-no-wrap">{{item.startedPST}}</div>
          <div class="text-no-wrap">{{item.endedPST}}</div>
        </template>

        <template v-slot:item.uid_load_number="{ item }">
            <span class="text-no-wrap">
              {{item.loadNumber}}
              <v-btn v-if="item.loadNumber" small icon @click.stop="$root.copyToClipboard(item.loadNumber)"><v-icon small>mdi-content-copy</v-icon></v-btn>
            </span>
          <br/>
          <span class="text-no-wrap">
              {{item.uid}}
              <v-btn v-if="item.uid" small icon @click.stop="$root.copyToClipboard(item.uid)"><v-icon small>mdi-content-copy</v-icon></v-btn>
            </span>
        </template>

        <template v-slot:item.drivers_trucks="{ item }">
          <TruckDriversSnippet :trucks="item.trucks"
                               :isDriverOO="item.isDriverOwnerOperator === 1"
                               :truckOO="item.truckOOData"
                               :driverOO="item.driverOOData"
                               :selectedTruck="truckId"
                               :onlyDrivers="truckId > 0"/>
        </template>

        <template v-slot:item.name="{ item }">
          <!--<template v-if="truckId">
            <template v-for="locationEntry in item.locations">
              <v-icon small class="mr-2" :color="$root.getLocationColor(locationEntry)">{{$root.getLocationIcon(locationEntry)}}</v-icon>{{locationEntry.name}}<br/>
            </template>
          </template>
          <template v-else>-->
          <v-layout class="text-no-wrap">
            <v-flex shrink>
              <LoadLocationsTooltip :load-fields="item"/>
            </v-flex>
            <v-flex grow class="pa-1">
              <LoadLocationsStartEnd
                :load-name="item.name"
                :max-width="'200px'"
                :gmapsDirectionsBtn="true"
                @open-gmaps-directions="() => { $root.openGmapsDirections(item) }"
                @show-map-modal="() => { $emit('show-map-modal', item) }"
                @show-map-div="(event) => { $emit('show-map-modal', item, true, event) }"
              />
            </v-flex>
          </v-layout>
          <!--</template>-->
        </template>

        <template v-slot:item.dispatcher="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-avatar v-on="on"
                        style="cursor: pointer;"
                        class="mr-1"
                        :size="24"
                        @click.stop="$root.onShowUserPreview(item.dispatcher, item.dispatcherName)"
                        :color="(item.dispatcherInitials && !item.dispatcherAvatar)?'#015aaa':null">
                <v-img :src="$root.getImageUrl('avatar', item.dispatcherAvatar)" v-if="item.dispatcherAvatar"></v-img>
                <span class="white--text" v-else-if="item.dispatcherInitials">{{item.dispatcherInitials}}</span>
                <v-icon large v-else>account_circle</v-icon>
              </v-avatar>
            </template>
            <span>{{item.dispatcherName}}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.percent="{ item }">
          {{parseFloat(item.truckDistancePercentByOdometer*100.0).toFixed(0)}}%
        </template>

        <template v-slot:item.miles="{ item }">
          <v-icon left small>mdi-map</v-icon>{{item.totalLoadMilesByMaps.toLocaleString()}}&nbsp;{{$t('milesShort')}}
          <v-layout>
            <v-flex shrink>
              <v-tooltip bottom v-if="isMapsOdometerTreshholdReached(item.milesByMaps, item.milesByOdometer)">
                <template v-slot:activator="{ on }">
                  <v-chip v-on="on" small color="warning" class="mt-1">
                    <v-icon left small>mdi-counter</v-icon>
                    {{item.milesByOdometer.toLocaleString()}}&nbsp;{{$t('milesShort')}}
                  </v-chip>
                </template>
                <span v-if="item.milesByMaps > item.milesByOdometer">{{$tc('Reports.MilesByMapsMoreThenByOdometer', item.milesByMaps - item.milesByOdometer)}}</span>
                <span v-if="item.milesByMaps < item.milesByOdometer">{{$tc('Reports.MilesByOdometerMoreThenByMaps', item.milesByOdometer - item.milesByMaps)}}</span>
              </v-tooltip>
              <span v-else>
                <v-chip small color="success" class="mt-1">
                  <v-icon left small>mdi-counter</v-icon>{{item.milesByOdometer.toLocaleString()}}&nbsp;{{$t('milesShort')}}
                </v-chip>
              </span>
            </v-flex>
            <v-flex shrink class="pt-1" v-if="item.isOverriddenFromDriver">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-account-check</v-icon>
                </template>
                <span>Overridden from driver</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </template>

        <template v-slot:item.rate="{ item }">
            ${{parseFloat(item.truckRateByOdometer).round(0).toLocaleString()}}
        </template>

        <template v-slot:item.dispatchersSalary="{ item }">
          ${{parseFloat(item.dispatchersSalary).round(0).toLocaleString()}}
        </template>
        <template v-slot:item.driversSalary="{ item }">
          ${{parseFloat(item.driversSalary).round(0).toLocaleString()}}
        </template>

        <template v-slot:item.rate_per_mile="{ item }">
          <div style="white-space: nowrap;">
            <v-icon left small>mdi-map</v-icon>${{parseFloat(item.truckRatePerMileByMaps).round(2).toLocaleString()}}<br/>
            <v-icon left small>mdi-counter</v-icon>${{parseFloat(item.truckRatePerMileByOdometer).round(2).toLocaleString()}}
          </div>
        </template>

        <template v-slot:item.gross="{ item }">
          ${{parseFloat(item.gross).round(0).toLocaleString()}}
          <!--<template v-if="item.finished === 2"><v-chip color="warning" small>TONU</v-chip></template>-->
        </template>

        <template v-slot:item.expenses="{ item }">
          ${{parseFloat(item.expenses).round(0).toLocaleString()}}
        </template>

        <template v-slot:item.net="{ item }">
          <div style="white-space: nowrap;">
            <v-icon left small>mdi-map</v-icon>${{parseFloat(item.truckNetByMiles).round(0).toLocaleString()}}<br/>
            <v-icon left small>mdi-counter</v-icon>${{parseFloat(item.truckNetByOdometer).round(0).toLocaleString()}}
          </div>
        </template>

        <template v-slot:item.factoring="{ item }">
          {{item.factoringPercent}}%
        </template>

        <template v-slot:item.actions="{ item }">
          <v-layout>
            <v-flex shrink class="pt-1">
              <template>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn  v-on="on"
                            :loading="item.loading"
                            :disabled="!item.isChecked"
                            @click.stop="onReportToggleChecked(item, item.id, truckId, $store.getters.overriddenFieldsTypes.TRUCKS, 100)"
                            icon
                            small>
                      <v-icon :color="item.isVerified ? 'success': null">mdi-check-all</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{item.isVerified ? ($t('Reports.MarkedAsVerified') + (item.verifiedDateTime ? ': ' + item.verifiedDateTime : '')) : $t('Reports.MarkAsVerified')}}
                  </span>
                </v-tooltip>
              </template>
            </v-flex>
            <v-flex shrink class="pt-1">
              <template>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn  v-on="on"
                            :loading="item.loading"
                            @click.stop="onReportToggleChecked(item, item.id, truckId, $store.getters.overriddenFieldsTypes.TRUCKS)"
                            icon
                            small>
                      <v-icon small :color="item.isChecked ? 'success': null">mdi-check-decagram</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{item.isChecked ? ($t('Reports.MarkedAsChecked') + (item.checkedDateTime ? ': ' + item.checkedDateTime : '')) : $t('Reports.MarkAsChecked')}}
                  </span>
                </v-tooltip>
              </template>
            </v-flex>
            <v-flex shrink class="pt-1" v-for="invoiceItem in item.invoicesArray" :key="'inv'+invoiceItem.id">
              <template v-if="invoiceItem.invoiceReceivedDate > 0 && $root.isInvoicesViewAllowed">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on"
                           icon
                           small
                           :color="$root.getInvoiceTypeColorByStatus(invoiceItem.invoiceStatus)"
                           @click.stop="$root.onShowInvoicePreview (invoiceItem.invoiceId, invoiceItem.invoiceNumber)">
                      <v-icon small>fa-file-invoice-dollar</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('Loads.Invoice')}} #{{invoiceItem.invoiceNumber}} ${{invoiceItem.invoiceTotal}}&nbsp;<strong>{{invoiceItem.invoiceStatus}}</strong></span>
                </v-tooltip>
              </template>
              <template v-else-if="!invoiceItem.invoiceReceivedDate && $root.isInvoicesViewAllowed">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on"
                           icon
                           small
                           :color="invoiceItem.invoiceDueLeftSeconds < 0 ? 'red' : 'warning'"
                           @click.stop="$root.onShowInvoicePreview (invoiceItem.invoiceId, invoiceItem.invoiceNumber)">
                      <v-icon small>fa-file-invoice-dollar</v-icon>
                    </v-btn>
                  </template>
                  <span v-if="invoiceItem.invoiceDueLeftSeconds < 0">{{$t('Loads.Invoice')}} #{{invoiceItem.invoiceNumber}} ${{invoiceItem.invoiceTotal}}&nbsp;{{$t('Invoices.Overdue')}}:&nbsp;-{{Math.ceil($root.secondsToHms(invoiceItem.invoiceDueLeftSeconds).hours / 24)}} d.</span>
                  <span v-if="invoiceItem.invoiceDueLeftSeconds > 0">{{$t('Loads.Invoice')}} #{{invoiceItem.invoiceNumber}} ${{invoiceItem.invoiceTotal}}&nbsp;{{$t('Invoices.DueDate')}}:&nbsp;+{{Math.ceil($root.secondsToHms(invoiceItem.invoiceDueLeftSeconds).hours / 24)}} d.</span>
                </v-tooltip>
              </template>
            </v-flex>
            <v-flex shrink  class="pt-1" v-if="item.invoicesArray.length === 0">
              <v-btn icon small disabled><v-icon small class="mr-1">fa-file-invoice-dollar</v-icon></v-btn>
            </v-flex>
            <v-flex shrink class="pt-1">
              <template v-if="item.bid">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on" @click.stop="$root.route('/bids/edit/'+item.bid)"><v-icon small>mdi-file-question</v-icon></v-btn>
                  </template>
                  <span>Bid #{{item.bid}}</span>
                </v-tooltip>
              </template>
            </v-flex>
            <v-flex shrink>
              <template>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click.stop="onShowDocuments(item)">
                      <v-icon small class="mr-1" :color="item.isAllRequiredDocsUploaded ? 'success' : 'warning'">mdi-file-multiple</v-icon>
                    </v-btn>
                  </template>
                  <span v-if="item.isAllRequiredDocsUploaded">{{$t('Loads.uploadDocuments')}}</span>
                  <span v-else>
                    <template v-for="(md, index) in item.missingDocs">
                      <span :key="'missingDocs_'+item.id+index" v-if="index === 'loadPhotos'">{{$t('Loads.LoadPhotos')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                      <span :key="'missingDocs_'+item.id+index" v-if="index === 'rateCon'">{{$t('Loads.RateCon')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                      <span :key="'missingDocs_'+item.id+index" v-if="index === 'do'">{{$t('Loads.DeliveryOrder')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                      <span :key="'missingDocs_'+item.id+index" v-if="index === 'pod'">{{$t('Loads.POD')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                      <span :key="'missingDocs_'+item.id+index" v-if="index === 'bol'">{{$t('Loads.BOL')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}</span>
                    </template>
                  </span>
                </v-tooltip>
              </template>
            </v-flex>
            <v-flex shrink>
              <template>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click.stop="onShowExpenses(item.expensesJSON, item.finished, item.id, item.trucks)">
                      <v-icon small class="mr-1" :color="expensesColor(item, 0, truckId)">fa-coins</v-icon>
                    </v-btn>
                  </template>
                  <span>{{!item.isPendingExpenses ? $t('Loads.ExpensesTitle') : $t('Loads.ExpensesHasPending')}}</span>
                </v-tooltip>
              </template>
            </v-flex>
            <v-flex shrink class="pt-1">
              <template>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn small icon v-on="on" @click.stop="onShowFinancialData(item)">
                      <v-icon small class="mr-1">mdi-finance</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('Loads.FinancialData')}}</span>
                </v-tooltip>
              </template>
            </v-flex>
          </v-layout>
        </template>
      </v-data-table>

      <!--<v-layout v-if="$root.isFullFinancialDataAllowedToView && truckId > 0">
        <v-flex xs12 class="text-right">
          <v-btn class="ma-1" :disabled="selectedItems.length === 0" @click="createReportGroup(truckId, lastReportsGroupNumber + 1, $route.params.type, from, to)"><v-icon left>mdi-clipboard-check-outline</v-icon> {{$t('Reports.CreateReportsGroup')}}</v-btn>
        </v-flex>
      </v-layout>-->

    </v-card>
    <v-dialog v-model="expensesModal" scrollable max-width="1000px">
      <v-card>
        <v-card-title>
          <h2>{{$t('Loads.ExpensesTitle')}}</h2><v-spacer></v-spacer><v-btn icon @click="expensesModal=false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <LoadExpenses :key="expensesModalKey"
                      :expensesArray="selectedExpenses"
                      :readOnly="!$root.isLoadsEditAllowed"
                      :isFinished="selectedFinishedField !== 0"
                      :loadId="selectedExpensesLoadId"
                      :preselectedDriver="selectedExpensesUserId"
                      :preselectedTruck="truckId"
                      :filterByTruckId="truckId"
                      @expensesSaved="fetchData()"
        ></LoadExpenses>
      </v-card>
    </v-dialog>
    <v-dialog v-model="documentsModal" scrollable>
      <v-card>
        <v-card-title>
          <h2>{{$t('Loads.uploadDocuments')}}</h2><v-spacer></v-spacer><v-btn icon @click="documentsModal=false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <LoadDocuments :key="documentsModalKey" :fields="selectedELoadFields" :readonly="true"></LoadDocuments>
      </v-card>
    </v-dialog>

    <v-dialog v-model="financesModal" scrollable max-width="1000px">
      <v-card>
        <v-card-title>
          <h2>{{$t('Loads.FinancialData')}}</h2><v-spacer></v-spacer><v-btn icon @click="financesModal=false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <FinancialDataForLoad :loadId="selectedFinancesLoadId"
                              :expenses="selectedExpenses"
                              :key="financesModalKey"
                              :locations="selectedLoadLocations"
                              :isFullFinancialData="$root.isFullFinancialDataAllowedToView"
                              :isDispatcherFinancialData="$root.isDispatcherFinancialDataAllowedToView"
                              :isDriverFinancialData="$root.isDriverFinancialDataAllowedToView"
                              :excludeDispatcherPayment="parseInt(selectedLoadExcludeDispatcherPayment) === 1 ? true : false"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import reportsMixin from '../../mixins/reportsMixin.js';
import LoadExpenses from '../../components/LoadExpenses.vue';
import LoadDocuments from '../../components/LoadDocuments.vue';
import TruckReportTemplate from './TruckReportTemplate.vue';
import LoadLocationsTooltip from '../../components/LoadLocationsTooltip';
import TruckDriversSnippet from '../../components/TruckDriversSnippet.vue';
import TruckTotals from './TruckTotals.vue';
import LoadLocationsStartEnd from '../../components/LoadLocationsStartEnd';
import ReportActions from '../../components/ReportActions';
import FinancialDataForLoad from '../../components/FinancialDataForLoad';
import FiltersPanel from '../../components/FiltersPanel';

export default {
  name: 'TrucksReport',
  props: ['reportData', 'from', 'to', 'truckId', 'print', 'selected', 'companyId'],
  components: { FinancialDataForLoad, LoadLocationsStartEnd, TruckDriversSnippet, LoadLocationsTooltip, LoadExpenses, LoadDocuments, TruckReportTemplate, TruckTotals, ReportActions, FiltersPanel },
  mixins: [reportsMixin],
  data: function () {
    return {
      loading: false,
      isByInvoices: false,
      reportPrintModal: false,
      selectedItems: [],
      loads: [],
      invoices: [],
      search: '',
      expensesModal: false,
      expensesModalKey: new Date().getTime(),
      selectedExpenses: [],
      selectedExpensesLoadId: 0,
      selectedFinishedField: 0,
      selectedExpensesUserId: 0,
      documentsModal: false,
      documentsModalKey: new Date().getTime(),
      selectedELoadFields: {},
      milesDiffHighlightThreshold: 30,
      truckName: '',
      financesModal: false,
      financesModalKey: new Date().getTime(),
      selectedFinancesLoadId: 0,
      selectedLoadExcludeDispatcherPayment: 0,
      selectedLoadLocations: [],
      headers: [
        { text: '#', value: 'id' },
        { text: '', value: 'status' },
        { text: 'Started / Ended', value: 'started' },
        { text: 'Locations', value: 'name' },
        { text: 'Disp.', value: 'dispatcher' },
        { text: 'Drivers', value: 'drivers_trucks', sortable: false },
        { text: 'UID / Load Number', value: 'uid_load_number', sortable: false },
        { text: 'Percent', value: 'percent' },
        { text: 'Miles', value: 'miles' },
        { text: 'Rate Per Mile', value: 'rate_per_mile' },
        { text: 'Rate', value: 'rate' },
        { text: 'Gross', value: 'gross' },
        { text: 'Disp. Salary', value: 'dispatchersSalary' },
        { text: 'Driver Salary', value: 'driversSalary' },
        { text: 'Expenses', value: 'expenses' },
        { text: 'Net', value: 'net' },
        { text: '', value: 'actions', sortable: false }
      ],
      headersInvoices: [
        { text: this.$root.t('Invoices.Number'), value: 'invoiceNumber' },
        { text: this.$root.t('Invoices.SentDate'), value: 'sentDate' },
        { text: this.$root.t('Invoices.Creator'), value: 'creator' },
        { text: this.$root.t('Invoices.SentBy'), value: 'sender' },
        { text: this.$root.t('Invoices.Broker'), value: 'broker' },
        // { text: this.$root.t('Invoices.Created'), value: 'created' },
        // { text: this.$root.t('Invoices.name'), value: 'name' },
        { text: this.$root.t('Invoices.LoadUID'), value: 'loadUID' },
        { text: this.$root.t('Invoices.Factoring'), value: 'factoring' },
        { text: this.$root.t('Invoices.Amount'), value: 'total' },
        { text: this.$root.t('Invoices.Received'), value: 'amountReceived' }
      ]
    };
  },
  computed: {
    document () {
      return document;
    },
    totalsCounts () {
      return this.$store.getters['reports/totalsCounts'];
    }
  },
  mounted () {
    this.$set(this, 'filtersItems', this.filtersItems.filter((el) => { return el.name !== 'filterTruck'; }));
    this.setTrucksTotalsHeaders();

    this.$root.overrideFieldCallback = this.fetchData;
    this.$root.setLastListPath(this.$route.path);
    this.$root.getGlobalSettingsField('milesDiffHighlightThreshold').then((response) => {
      if (response.status === 'ok') {
        this.milesDiffHighlightThreshold = parseInt(response.result);
      }
    });
    if (this.truckId) {
      this.$root.getTruck(this.truckId).then((response) => {
        if (response.status === 'ok') {
          this.truckName = response.result.name;
        }
      });
    }
    this.$nextTick(() => {
      this.fetchData();
    });
  },
  methods: {
    onShowFinancialData (loadData) {
      this.selectedFinancesLoadId = loadData.id;
      this.selectedExpenses = JSON.parse(loadData.expensesJSON);
      this.selectedLoadLocations = loadData.locationsJSON;
      this.selectedLoadExcludeDispatcherPayment = loadData.isExcludeDispatcherPayment;
      this.financesModalKey = new Date().getTime();
      this.financesModal = true;
    },
    getEmptyTotals () {
      this.$store.dispatch('reports/totalsCounts', {
        loadsCount: 0,
        totalTruckMilesByMaps: 0,
        totalTruckMilesByOdometer: 0,
        totalLoadsRate: 0,
        totalAdditionalPayments: 0,
        avgTruckRatePerMileByMaps: 0,
        avgTruckRatePerMileByOdometer: 0,
        totalGrossIncome: 0,
        dispatchersSalary: 0,
        driversSalary: 0,
        totalExpenses: 0,
        totalNetIncome: 0
      });
      return this.$store.getters['reports/totalsCounts'];
    },
    isMapsOdometerTreshholdReached (mapsMiles, odometerMiles) {
      return Math.abs(mapsMiles - odometerMiles) > this.milesDiffHighlightThreshold;
    },
    onItemSelected () {
      this.$nextTick(() => {
        let selected = [];
        for (let i = 0; i < this.selectedItems.length; i++) {
          selected.push(parseInt(this.selectedItems[i]['id']));
        }
        this.$emit('selected', selected);
      });
    },
    printOut (elem, title) {
      this.$root.route('/reports/print/trucks/' + this.$root.companyId + '/' + this.truckId + '/' + this.from + '/' + this.to);
    },
    onShowExpenses (expensesJSON, finishedField, loadId, trucksDrivers = []) {
      this.selectedExpensesLoadId = loadId;
      this.selectedExpenses = JSON.parse(expensesJSON) || [];
      this.selectedFinishedField = finishedField;
      this.expensesModalKey = new Date().getTime();
      if (trucksDrivers && trucksDrivers.length > 0) {
        if (trucksDrivers[0].drivers && trucksDrivers[0].drivers.length > 0) {
          this.selectedExpensesUserId = trucksDrivers[0].drivers[0].id;
        }
      }
      this.expensesModal = true;
    },
    onShowDocuments (fields) {
      if (fields) {
        this.selectedELoadFields = fields;
        this.documentsModalKey = new Date().getTime();
        this.documentsModal = true;
      }
    },
    onFilterChanged (e) {
      let i = 0;
      let field = '';
      let value = '';
      const filtersFields = Object.keys(e);
      for (i = 0; i < filtersFields.length; i++) {
        field = filtersFields[i];
        value = Object.prototype.hasOwnProperty.call(e, field) ? e[field] : null;
        if (value !== '') {
          this[field] = Array.isArray(value) ? value.join(',') : value;
        }
      }
      if (this.isFilterInitialized) {
        this.fetchData();
      }
      this.isFilterInitialized = true;
    },
    onByInvoicesSWITCH () {
      this.fetchData();
    },
    fetchData () {
      if (!this.from || !this.to) return;
      return new Promise((resolve, reject) => {
        this.loading = true;

        /* if (this.isByInvoices) {
          this.getInvoicesReportData(this.from, this.to, this.truckId).then((response) => {
            if (response.status === 'ok') {
              this.clearTotals();
              this.invoices = [];

              let selectedArray = [];
              if (this.selected) {
                selectedArray = this.selected.split(',').map(v => {
                  return parseInt(v);
                });
              }
              this.selectedItems = [];

              this.totalsCounts.loads = this.invoices.length;

              for (let i = 0; i < response.invoices.length; i++) {
                let invoice = response.invoices[i];
                if (selectedArray.length > 0 && selectedArray.indexOf(parseInt(invoice.id)) < 0) continue;

                invoice.date = this.$root.formatDate(invoice.date);
                invoice.dueDate = this.$root.formatDate(invoice.dueDate);
                invoice.sentDate = this.$root.formatDate(invoice.sentDatePST);

                this.totalsCounts.totalDrivers = Object.assign(this.totalsCounts.totalDrivers, invoice.driversData);
                this.totalsCounts.totalTrucks = Object.assign(this.totalsCounts.totalTrucks, invoice.trucksData);

                const factoringFees = parseFloat((invoice.amountReceived > 0 ? invoice.amountReceived : invoice.total) * (invoice.factoring / 100.0));
                this.totalsCounts.totalExpenses += parseFloat(factoringFees);
                this.totalsCounts.totalLoadsRate += parseFloat(invoice.amountReceived);
                this.totalsCounts.totalNetIncome += parseFloat(invoice.amountReceived) > 0 ? parseFloat(parseFloat(invoice.amountReceived) - parseFloat(factoringFees)) : 0;

                this.invoices.push(invoice);
              }
            }
            this.onItemSelected();
            this.$forceUpdate();
            this.loading = false;
          });
        } else { */
        this.getLoadsReportData(this.from, this.to, this.truckId).then((response) => {
          let _totalsCounts = this.getEmptyTotals();
          _totalsCounts.expensesByName = {};
          let i = 0;
          if (response.status === 'ok') {
            this.loads = [];
            this.currentDateTime = response.currentDateTime;

            let selectedArray = [];
            if (this.selected) {
              selectedArray = this.selected.split(',').map(v => {
                return parseInt(v);
              });
            }
            this.selectedItems = [];

            for (i = 0; i < response.loads.length; i++) {
              let _load = response.loads[i];
              if (selectedArray.length > 0 && selectedArray.indexOf(parseInt(_load.id)) < 0) continue;


              // Check filters
              if (this.hasOwnProperty('filterDispatcher') && parseInt(this.filterDispatcher) > 0) {
                let isExists = false;
                if (this.filterDispatcher.split(',').indexOf(_load.dispatcher.toString()) >= 0) {
                  isExists = true;
                }
                if (!isExists) continue;
              }

              if (this.hasOwnProperty('filterDriver') && parseInt(this.filterDriver) > 0) {
                if (_load.trucks.length === 0) continue;
                let isExists = false;
                for (let fi = 0; fi < _load.trucks.length; fi++) {
                  for (let fj = 0; fj < _load.trucks[fi].drivers.length; fj++) {
                    if (this.filterDriver.split(',').indexOf(_load.trucks[fi].drivers[fj].id.toString()) >= 0) {
                      isExists = true;
                      break;
                    }
                  }
                  if (isExists) break;
                }
                if (!isExists) continue;
              }
              if (this.hasOwnProperty('filterBroker') && parseInt(this.filterBroker) > 0) {
                let isExists = false;
                if (this.filterBroker.split(',').indexOf(_load.broker.toString()) >= 0) {
                  isExists = true;
                }
                if (!isExists) continue;
              }

              // const locations = JSON.parse(response.loads[i].locationsJSON.replace(/\n/g, '\\n').replace(/\r/g, '\\r').replace(/\t/g, '\\t'));
              const locations = response.loads[i].locations;
              _load.status = this.$root.getLoadStatus(locations, response.loads[i].finished);
              let pickupLocationName = 'N/A';
              let deliveryLocationName = 'N/A';
              for (let j = 1; j < _load.locations.length; j++) {
                if (_load.locations[j].pickup === 1) {
                  pickupLocationName = _load.locations[j].name;
                  break;
                }
              }
              for (let j = _load.locations.length - 1; j > 0; j--) {
                if (_load.locations[j].delivery === 1) {
                  deliveryLocationName = _load.locations[j].name;
                  break;
                }
              }
              pickupLocationName = pickupLocationName.replace(/, USA/gi, '');
              if (pickupLocationName.split(',').length > 2) {
                pickupLocationName = pickupLocationName.substring(pickupLocationName.indexOf(',') + 1);
              }
              deliveryLocationName = deliveryLocationName.replace(/, USA/gi, '');
              if (deliveryLocationName.split(',').length > 2) {
                deliveryLocationName = deliveryLocationName.substring(deliveryLocationName.indexOf(',') + 1);
              }
              // Process load item
              _load.uid_load_number = _load.loadNumber + ' ' + _load.uid;

              /* let totalMilesByMaps = 0;
                let totalMilesByOdometer = 0;

                _load.locations = [];
                let truckCnaged = true;
                for (let l = 0; l < locations.length; l++) {
                  let location = locations[l];
                  location.name = location.name.replace(/, USA/gi, '');

                  // Fill last locations if needed
                  if (l === locations.length - 1) {
                    if (!truckCnaged) {
                      _load.locations.push(location);
                    }
                    break;
                  }

                  const nextLocation = locations[l + 1];
                  const distance = this.$root.metersToMiles(location.distance);

                  if (parseInt(location.truck) === parseInt(this.truckId)) {
                    _load.locations.push(location);
                    truckCnaged = false;
                  } else if (!truckCnaged) {
                    _load.locations.push(location);
                    truckCnaged = true;
                  }

                  /!* if (pickupLocationName === '' &&  parseInt(location.truck) === parseInt(this.truckId) ) {
                                        pickupLocationName = location.name;
                                    }

                                    if (parseInt(location.truck) === this.truckId) {
                                        deliveryLocationName = nextLocation.name;
                                    } *!/

                  let odometerDistance = 0;
                  if (nextLocation.odometerStart && nextLocation.odometer) {
                    odometerDistance = parseInt(nextLocation.odometer) - parseInt(nextLocation.odometerStart);
                  }

                  totalMilesByMaps += distance;
                  totalMilesByOdometer += odometerDistance;

                  if ((location.truck && parseInt(location.truck) === parseInt(this.truckId)) || parseInt(this.truckId) === 0) {
                    _load.milesByMaps += distance;
                    _load.milesByOdometer += odometerDistance;
                  }
                }

                if (!this.truckId) {
                  _load.locations = locations;
                }

                _load.name = pickupLocationName + ' -- ' + deliveryLocationName; */
              _load.factoring = _load.factoringPercent;
              _load.rate = _load.Rate;
              /*
                            // _load.truckDistancePercentByMaps = _load.totalLoadMilesByMaps > 0 ? Math.min(1, _load.milesByMaps / _load.totalLoadMilesByMaps) : 0;
                            _load.truckDistancePercentByOdometer = _load.totalLoadMilesByMaps > 0 ? Math.min(1, _load.milesByOdometer / _load.totalLoadMilesByMaps) : 0;

                            // _load.truckRateByMaps = parseFloat(_load.Rate * _load.truckDistancePercentByMaps).round(2);
                            _load.truckRateByOdometer = parseFloat(_load.Rate * _load.truckDistancePercentByOdometer).round(2);

                            _load.truckNetByMiles = parseFloat(_load.net * _load.truckDistancePercentByOdometer).round(2);
                            _load.truckNetByOdometer = parseFloat(_load.net * _load.truckDistancePercentByOdometer).round(2);

                            _load.truckRatePerMileByMaps = _load.milesByMaps > 0 ? parseFloat(_load.Rate / _load.milesByMaps).round(2) : 0;
                            _load.truckRatePerMileByOdometer = _load.milesByOdometer > 0 ? parseFloat(_load.Rate / _load.milesByOdometer).round(2) : 0;

                            let onlyTruckExpenses = 0;
                            const exKeys = Object.keys(_load.expensesByName);
                            for (let ei = 0; ei < exKeys.length; ei++) {
                              if (!_totalsCounts.expensesByName.hasOwnProperty(exKeys[ei])) {
                                _totalsCounts.expensesByName[exKeys[ei]] = 0;
                              }
                              _totalsCounts.expensesByName[exKeys[ei]] += parseFloat(_load.expensesByName[exKeys[ei]]);
                              onlyTruckExpenses += parseFloat(_load.expensesByName[exKeys[ei]]);
                            }

                            // Calc bases on percent
                            if (_load.truckDistancePercentByOdometer < 1.0) {
                              _load.gross = _load.gross * _load.truckDistancePercentByOdometer;
                              const onlyLoadExpenses = parseFloat(_load.expenses) - onlyTruckExpenses;
                              _load.expenses = onlyLoadExpenses * _load.truckDistancePercentByOdometer;
                              _load.net = parseFloat(_load.gross) - parseFloat(_load.expenses);
                            }*/

              // Re-check is expenses pending per driver

              if (_load.isPendingExpenses && _load.expensesJSON) {
                const expensesArray = JSON.parse(_load.expensesJSON);
                _load.isPendingExpenses = false;
                for (let ei = 0; ei < expensesArray.length; ei++) {
                  if (parseInt(expensesArray[ei].truckId) === parseInt(this.truckId) && expensesArray[ei].hasOwnProperty('pending') && expensesArray[ei].pending) {
                    _load.isPendingExpenses = true;
                    break;
                  }
                }
              }

              const exKeys = _load.expensesByName ? Object.keys(_load.expensesByName) : [];
              for (let ei = 0; ei < exKeys.length; ei++) {
                if (!_totalsCounts.expensesByName.hasOwnProperty(exKeys[ei])) {
                  _totalsCounts.expensesByName[exKeys[ei]] = 0;
                }
                _totalsCounts.expensesByName[exKeys[ei]] += parseFloat(_load.expensesByName[exKeys[ei]]);
              }

              _totalsCounts.loadsCount += 1;
              _totalsCounts.totalTruckMilesByMaps += parseInt(_load.totalLoadMilesByMaps);
              _totalsCounts.totalTruckMilesByOdometer += parseInt(_load.milesByOdometer);
              _totalsCounts.totalLoadsRate += parseFloat(_load.truckRateByOdometer);
              _totalsCounts.totalAdditionalPayments += parseFloat(_load.additionalPayments);
              _totalsCounts.avgTruckRatePerMileByMaps = (_totalsCounts.totalLoadsRate + _totalsCounts.totalAdditionalPayments) / _totalsCounts.totalTruckMilesByMaps;
              _totalsCounts.avgTruckRatePerMileByOdometer = (_totalsCounts.totalLoadsRate + _totalsCounts.totalAdditionalPayments) / _totalsCounts.totalTruckMilesByOdometer;
              _totalsCounts.totalGrossIncome += parseFloat(_load.gross);
              _totalsCounts.totalExpenses += parseFloat(_load.expenses);
              _totalsCounts.totalNetIncome += parseFloat(_load.truckNetByOdometer);
              _totalsCounts.dispatchersSalary += parseFloat(_load.dispatchersSalary);
              _totalsCounts.driversSalary += parseFloat(_load.driversSalary);

              /*this.totalsCounts.miles += parseInt(response.loads[i].distanceInMiles);
              this.totalsCounts.totalLoadsRate += parseFloat(response.loads[i].Rate);
              this.totalsCounts.totalGrossIncome += parseFloat(response.loads[i].gross);
              this.totalsCounts.totalExpenses += parseFloat(response.loads[i].expenses);
              this.totalsCounts.totalNetIncome += parseFloat(response.loads[i].net);

              this.totalsCounts.totalDrivers = Object.assign(this.totalsCounts.totalDrivers, response.loads[i].drivers);
              this.totalsCounts.totalTrucks = Object.assign(this.totalsCounts.totalTrucks, response.loads[i].trucks);*/

              this.loads.push(_load);
            }
            // this.totalsCounts.avgTruckRatePerMileByMaps = parseFloat(this.totalsCounts.avgTruckRatePerMileByMaps / this.loads.length).toFixed(2);
            // this.totalsCounts.avgTruckRatePerMileByOdometer = parseFloat(this.totalsCounts.avgTruckRatePerMileByOdometer / this.loads.length).toFixed(2);
          }

          this.$store.dispatch('reports/totalsCounts', _totalsCounts);
          this.reportsGroupsListKey = 'reportsGroupsListKey' + new Date().getTime();
          this.$store.commit('reports/updateReportsGroupsListKey');
          this.onItemSelected();
          this.$forceUpdate();
          this.initReportsFilters();
          this.loading = false;
          resolve({
            loadsCount: _totalsCounts.loadsCount,
            totalTruckMilesByMaps: _totalsCounts.totalTruckMilesByMaps,
            totalTruckMilesByOdometer: _totalsCounts.totalTruckMilesByOdometer,
            totalLoadsRate: _totalsCounts.totalLoadsRate,
            totalAdditionalPayments: _totalsCounts.totalAdditionalPayments,
            avgTruckRatePerMileByMaps: _totalsCounts.avgTruckRatePerMileByMaps,
            avgTruckRatePerMileByOdometer: _totalsCounts.avgTruckRatePerMileByOdometer,
            totalGrossIncome: _totalsCounts.totalGrossIncome,
            dispatchersSalary: _totalsCounts.dispatchersSalary,
            driversSalary: _totalsCounts.driversSalary,
            expensesByName: _totalsCounts.expensesByName,
            totalExpenses: _totalsCounts.totalExpenses,
            totalNetIncome: _totalsCounts.totalNetIncome,
            loads: this.loads
          });
        }).finally(() => {
          this.loading = false;
        });
      });
    }
  }
};
</script>
