<template>
  <div style="text-align: center;">
    <div v-if="avatar">
      <v-avatar size="100" v-if="!hideResult && url && isImage">
        <v-img :src="url" v-if="url"/>
      </v-avatar>
      <v-avatar v-else-if="!hideResult && !url && avatarInitials" color="primary" size="100">
        <span class="white--text text-h5">{{avatarInitials}}</span>
      </v-avatar>
      <v-avatar size="100" v-else-if="!hideResult && !url && isImage">
        <v-icon large>mdi-camera</v-icon>
      </v-avatar>
    </div>
    <div v-if="odometer">
      <img v-img:images :style="{height:height ? parseInt(height)+'px' : '30px', width:width ? width : 'auto'}" :src="url" v-if="url">
    </div>
    <template v-if="!odometer">
      <!--<v-overlay :value="loading" :absolute="true">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>-->
      <v-btn v-if="!disabled && !readonly && !noUI && !avatar"
             :icon="$root.isMobile"
             :disabled="loading"
             class="ma-1"
             @click="addNew"
             color="success">
        <template v-if="loading">
          <v-progress-circular
            indeterminate
          ></v-progress-circular>
          {{$t('Uploading')}}
        </template>
        <template v-else>
        <v-icon :small="!$root.isMobile" :left="!$root.isMobile">
          {{$root.isMobile ? 'mdi-plus-box' : 'mdi-plus'}}
        </v-icon>
          {{!$root.isMobile ? addNewBtnLabel : ''}}
        </template>
      </v-btn>

      <v-btn
        class="ma-1"
        :data-cy="dataCy"
        v-if="!disabled && !readonly && !noUI && avatar"
        @click='pickFile'
        :loading="loading"
        :disabled="loading"
        :icon="$root.isMobile"
      >
        <v-icon :small="!$root.isMobile" :left="!$root.isMobile">
          {{image ? 'fa-image' : 'fa-file-upload'}}
        </v-icon>
        {{!$root.isMobile ? label: ''}}
      </v-btn>

      <v-btn v-if="isImage && isImageEditing && !readonly"
             class="ma-1"
             :disabled="loading"
             :icon="$root.isMobile"
             @click="onEdit"
      >
        <v-icon :small="!$root.isMobile" :left="!$root.isMobile">mdi-image-edit</v-icon>
        {{!$root.isMobile ? $t('Edit') : ''}}
      </v-btn>

      <v-btn v-if="!avatar && url && !hideResult && downloadAllowed && !noUI"
             :href="downloadUrl"
             :download="filename"
             class="ma-1"
             :disabled="loading"
             :icon="$root.isMobile"
      >
        <v-icon :small="!$root.isMobile" :left="!$root.isMobile">fa-file-download</v-icon>
        {{!$root.isMobile ? $t('Download') : ''}}
      </v-btn>

      <v-btn
        class="ma-1"
        v-if="!field && !disabled && miltifileupload && !noUI"
        @click="removeFile"
        :disabled="loading"
        :icon="$root.isMobile"
      >
        <v-icon :small="!$root.isMobile" :left="!$root.isMobile">fa-times</v-icon>
        {{!$root.isMobile ? $t('Close') : ''}}
      </v-btn>

      <v-btn
        class="ma-1"
        v-if="!avatar && url && !hideResult && downloadAllowed && sendViaEmailAllowed && !noUI"
        :disabled="loading"
        :icon="$root.isMobile"
        @click="sendViaEmail"
      >
        <v-icon :small="!$root.isMobile" :left="!$root.isMobile">mdi-email-send</v-icon>
        {{!$root.isMobile ? $t('SendViaEmail') : ''}}
      </v-btn>

      <v-btn v-if="field && !disabled && deleteAllowed && !noUI"
             xs12 md6 color="red"
             class="ma-1 white--text"
             @click="remove()"
             :disabled="loading"
             :icon="$root.isMobile"
      >
        <v-icon small :left="!$root.isMobile" light>fa-trash</v-icon>
        {{!$root.isMobile ? $t('Remove') : ''}}
      </v-btn>
    </template>
    <div v-if="!avatar && !odometer && !noUI" style="margin-bottom: 200px;">
      <h4 v-if="!hideResult && url && (isPdf || isImage) && title">{{title}}<br/></h4>
      <template v-if="!hideResult && url && isPdf">
        <iframe v-if="downloadUrl" :src="'/pdfjs/web/viewer.html?file=' + encodeURIComponent(downloadUrl)" width="100%" :height="height ? (parseInt(height) - 110) + 'px' : '390px'"></iframe>
        <iframe v-else :src="url" width="100%" :height="height ? (parseInt(height) - 110) + 'px' : '390px'"></iframe>
        <!--<template v-if="isSafari || isMobile">
          <iframe :src="'/pdfjs/web/viewer.html?file=' + encodeURIComponent(downloadUrl)" width="100%" :height="height ? height : '500px'" ></iframe>
        </template>
        <template v-else>
          <embed :src="url" width="100%" :height="height ? height : '500px'" />
        </template>-->
      </template>
      <v-progress-circular v-if="!hideResult && url && isPdf" :size="50" style="margin-top: 50px" color="primary" indeterminate></v-progress-circular><br/>
      <template v-if="!hideResult && url && isImage">
      <img contain v-img:images
           :style="{ width: 'auto', maxHeight: (parseInt(height) - 110)+'px' }"
           :alt="filename ? filename : label" :src="url"/>
      </template>
    </div>

    <input
      v-if="!disabled"
      type="file"
      style="display: none"
      ref="file"
      :multiple="miltifileupload"
      :accept="image ? 'image/*' : accept"
      @change="fileUploaded"
    >

    <v-dialog v-model="sendViaEmailModal"  scrollable fullscreen persistent hide-overlay transition="dialog-bottom-transition">
      <EmailSendForm :attFilesArray="[attData]"
                     :recipientsList="recipientsList"
                     @closed="sendViaEmailModal=false;"></EmailSendForm>
    </v-dialog>

    <v-dialog v-model="imageEditorModal" v-if="isImage && isImageEditing" hide-overlay scrollable fullscreen persistent transition="dialog-bottom-transition">
      <v-card style="height:750px">
        <v-overlay :value="imageEditorLoading" :absolute="true">
          <v-progress-circular indeterminate></v-progress-circular>
        </v-overlay>
        <v-card-text style="padding: 0px;">
          <component :is="ImageEditor" ref="imageEditor" :include-ui="true" :options="imageEditorOptions"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="loadingPDFConv" @click="onSaveEditedImageToPDF"><v-icon left>mdi-file-pdf-box</v-icon>{{$t('SaveToPDF')}}</v-btn>
          <v-btn color="primary" @click="onSaveEditedImage"><v-icon left>mdi-content-save</v-icon>{{$t('Save')}}</v-btn>
          <v-btn class="mr-4" @click="imageEditorModal=false">
            <v-icon left>mdi-close</v-icon>{{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import 'tui-color-picker/dist/tui-color-picker.css';
import 'tui-image-editor/dist/tui-image-editor.css';
// import { ImageEditor } from '@toast-ui/vue-image-editor';
import EmailSendForm from './EmailSendForm';

export default {
  props: ['label', 'url', 'downloadUrl', 'field', 'recipientsList', 'loading', 'image', 'hideResult', 'filename', 'disabled',
    'dataCy', 'title', 'avatar', 'odometer', 'height', 'width', 'downloadAllowed', 'sendViaEmailAllowed', 'miltifileupload', 'pickOnMount', 'pickOnClick', 'accept',
    'deleteAllowed', 'addNewBtnLabel', 'readonly', 'noUI', 'avatarInitials', 'isImageEditing', 'uploadFolder'],
  components: { EmailSendForm },
  data: () => ({
    pdfPagesCount: 1,
    pdfCurrentPage: 1,
    pdfLoaded: false,
    aratio: 0,
    sendViaEmailModal: false,
    folder: null,
    file: null,
    imgUrl: '',
    attData: {},
    imageEditorModal: false,
    imageEditorLoading: false,
    loadingPDFConv: false,
    imageEditorOptions: {
      cssMaxWidth: 1000,
      cssMaxHeight: 600,
      usageStatistics: false,
      uiSize: {
        width: '1000px',
        height: '600px'
      },
      includeUI: {
        theme: {
          'header.display': 'none'
        },
        initMenu: '',
        menuBarPosition: 'bottom',
        menu: ['crop', 'flip', 'rotate', 'draw', 'shape', 'icon', 'text', 'filter'] //, 'mask'
      },
      menuBarPosition: 'bottom'
    }
  }),
  mounted () {
    /* if (this.url && this.isImage) {
      let img = new Image();
      img.onload = () => {
        this.aratio = parseInt(img.height) / parseInt(img.width);
      }
      img.src = this.url;
    }
*/
    this.attData.attUrl = this.url;
    this.attData.isPdf = this.isPdf;
    this.attData.downloadAttUrl = this.downloadUrl;
    this.attData.folder = this.folder;
    this.attData.file = this.file;
    this.attData.filename = this.filename;
    this.imgUrl = this.url;
    if (this.pickOnMount) {
      this.pickFile();
    }
  },
  computed: {
    ImageEditor () {
      if (this.isImageEditing) {
        return () => import('@toast-ui/vue-image-editor/src/ImageEditor.vue');
      }
      return null;
    },
    isSafari () {
      return window.isSafari;
    },
    isMobile () {
      return window.isMobile;
    },
    isImage () {
      if (this.field && this.field.split('.').pop().match(/jpg|png|jpeg|jfif|webp/gi)) {
        return true;
      }
      return false;
    },
    isPdf () {
      if (this.field && this.field.split('.').pop().match(/pdf/gi)) {
        return true;
      }
      return false;
    }
  },
  methods: {
    onEdit () {
      this.imageEditorModal = true;
      this.imageEditorLoading = true;
      this.$nextTick(() => {
        if (this.$refs.imageEditor) {
          this.$refs.imageEditor.invoke('loadImageFromURL', this.url, this.filename ? this.filename : this.label).then((sizeValue) => {
            this.$refs.imageEditor.invoke('ui.activeMenuEvent');
            this.$refs.imageEditor.invoke('ui.resizeEditor', { imageSize: sizeValue });
            this.imageEditorLoading = false;
          }).catch(e => {
            this.imageEditorModal = false;
            this.imageEditorLoading = false;
            console.error('Something went wrong:', e);
            this.$root.toast('Something went wrong', { color: 'error' });
          });
        }
      });
    },
    onSaveEditedImageToPDF () {
      this.$dialog.confirm(this.$root.t('SaveToPDFConfirmationMsg')).then(dialog => {
        this.loadingPDFConv = true;
        this.$nextTick(() => {
          if (this.$refs.imageEditor) {
            const imgData = this.$refs.imageEditor.invoke('toDataURL');
            this.$root.loadPDFFromImage(imgData).then((response) => {
              if (response.status === 'ok') {
                let dst = Buffer.from(response.data);
                let fn = this.filename ? this.filename : this.label;
                let _filename = fn.split('.').slice(0, -1).join('.');
                const file = new File([dst], _filename + '.pdf', {
                  type: 'application/pdf',
                  lastModified: new Date().getTime()
                });
                let list = new DataTransfer();
                list.items.add(file);
                this.$emit('add-and-save', list.files);
                this.imageEditorModal = false;
              }
            }).finally(() => {
              this.loadingPDFConv = false;
            });
          }
        });
      });
    },
    onSaveEditedImage () {
      let i = 0;
      this.$nextTick(() => {
        if (this.$refs.imageEditor) {
          const imgData = this.$refs.imageEditor.invoke('toDataURL');
          const byteString = window.atob(imgData.split(',')[1]);
          const mimeString = imgData.split(',')[0].split(':')[1].split(';')[0];
          const ab = new ArrayBuffer(byteString.length);
          let ia = new Uint8Array(ab);
          for (i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const file = new File([ab],
            this.filename ? this.filename : this.label, {
              type: mimeString,
              lastModified: new Date().getTime()
            });
          let list = new DataTransfer();
          list.items.add(file);
          this.$emit('changeAndSave', list.files);
          this.imageEditorModal = false;
        }
      });
    },
    pickFile () {
      this.$refs.file.click();
    },
    fileUploaded (e) {
      let i = 0;
      for (i = 0; i < e.target.files.length; i++) {
        if (e.target.files[i].size > 6 * 1024 * 1025) {
          this.$root.toast('File sould be less then 6 mb', { color: 'error' });
          return;
        }
      }
      if (this.miltifileupload) {
        this.$emit('change', e.target.files);
      } else {
        this.$emit('change', e.target.files[0]);
      }
    },
    sendViaEmail () {
      let i = 0;
      let token = '';
      let urlArray = this.downloadUrl.split('/');
      for (i = 0; i < urlArray.length; i++) {
        token = urlArray[i];
        if (token && token === 'files') {
          this.attData.folder = urlArray[i + 2];
          this.attData.file = urlArray[i + 3];
          this.attData.filename = urlArray[i + 4];
          this.sendViaEmailModal = true;
          break;
        }
      }
    },
    addNew () {
      this.$emit('add-new');
    },
    removeFile () {
      this.$emit('removeFile');
    },
    remove () {
      this.$dialog.confirm(this.$i18n.t('CreateUser.RemoveFileConfirmMsg')).then(dialog => {
        this.$emit('remove');
      }).catch(() => {

      });
    }
  }
};
</script>
