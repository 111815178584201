<template>
  <v-layout v-if="($vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl) && !dropdown" justify-center>
    <!--<template v-for="roleItem in $store.getters['rolesList']">
      <v-flex grow v-if="roleItem !== 'DriverOwnerOperator'" :key="roleItem">
        <v-hover v-slot:default="{ hover }">
          <v-sheet :data-cy="'btn'+roleItem"
                   style="padding:5px;min-height:150px;margin:5px;cursor: pointer;"
                   :color="role === roleItem ? 'success' : null"
                   :elevation="hover || role === roleItem ? 16 : 2" class="mx-auto text-center"
                   @click="$emit('changed', roleItem)">
            <v-icon size="80" v-if="roleItem === 'Administrator'">fa-crown</v-icon>
            <v-icon size="80" v-else-if="roleItem === 'Dispatcher'" style="margin-top:-5px; margin-bottom:5px;">mdi-face-agent</v-icon>
            <v-icon size="95" v-else-if="roleItem === 'Broker'" style="margin-top:0px;margin-bottom:-15px;">mdi-account-cash</v-icon>
            <v-icon size="80" v-else-if="roleItem === 'Driver'">$vuetify.icons.drivingWheelLargeIcon</v-icon>
            <v-icon size="80" v-else-if="roleItem === 'Engineer'">fa-user-cog</v-icon>
            <v-icon size="80" v-else-if="roleItem === 'AccountingManager'">fa-user-tie</v-icon>
            <v-icon size="110" style="margin-top: -10px; margin-bottom: -15px;" v-else>mdi-account</v-icon>
            <h5>{{$t('Roles.' + roleItem)}}</h5>
          </v-sheet>
        </v-hover>
      </v-flex>
      </template>-->
      <v-btn-toggle v-model="selectedBtn">
        <template v-for="roleItem in $store.getters['rolesList']">
          <v-badge overlap left :offset-x="36" color="green" :key="roleItem" v-if="$store.getters.usersByRoles.online[roleItem] > 0 && !hideOnlineBadges">
            <template v-slot:badge>{{$store.getters.usersByRoles.online[roleItem]}}</template>
            <v-btn v-if="(roleItem !== 'DriverOwnerOperator' || showDriverOwnerOperator) && $root.isUsersListAllowed(roleItem)"
                   :disabled="disabled" :color="roleItem === role ? 'success' : null" :style="roleItem === role ? {color: '#fff'} : null"
                   @click="$emit('changed', roleItem)">
              {{$t('Roles.' + roleItem)}}
            </v-btn>
          </v-badge>
          <v-btn v-else-if="(roleItem !== 'DriverOwnerOperator' || showDriverOwnerOperator) && $root.isUsersListAllowed(roleItem)"
                 :disabled="disabled" :color="roleItem === role ? 'primary' : null" :style="roleItem === role ? {color: '#fff'} : null"
                 @click="$emit('changed', roleItem)"
                 :key="roleItem">
            {{$t('Roles.' + roleItem)}}
          </v-btn>
        </template>
      </v-btn-toggle>
  </v-layout>
  <v-layout v-else>
    <v-flex xs12>
      <v-select
        data-cy="roleSelect"
        id="role_select_id"
        v-model="selectedRole"
        @change="selectChange"
        :disabled="disabled"
        :items="roleItems"
        :label="$t('CreateUser.SelectRole')"
        :rules="$root.selectionRequiredRules"
        outlined dense
      />
      {{selectedRole}}
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ['role', 'showDriverOwnerOperator', 'dropdown', 'disabled', 'hideOnlineBadges'],
  data: () => ({
    roleItems: [],
    selectedRole: null,
    selectedBtn: -1
  }),
  mounted () {
    let i = 0;
    let _r = this.$store.getters['rolesList'];
    this.roleItems = [];
    for (i = 0; i < _r.length; i++) {
      // Hide owner operators, this role will be switched from driver role
      if (_r[i] === 'DriverOwnerOperator' && !this.showDriverOwnerOperator) continue;
      if (!this.$root.isUsersListAllowed(_r[i])) continue;
      this.roleItems.push({ text: this.$root.t('Roles.' + _r[i]), value: _r[i] });
    }
    this.$nextTick(() => {
      if (this.role) {
        this.$set(this, 'selectedRole', this.role);
        for (i = 0; i < this.roleItems.length; i++) {
          if (this.roleItems[i].value === this.selectedRole) {
            this.selectedBtn = i;
            break;
          }
        }
      }
    });
  },
  methods: {
    selectChange (value) {
      this.$emit('changed', value);
    }
  }
};
</script>
