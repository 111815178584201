<template>
  <div class="text-center">
    <UsersFormSaveCancelButtons v-if="isSaveAllowed && updatingEnabled"
                              :loading="isFieldLoading('saveBtn')"
                              @save="saveFields"
                              @cancel="$root.route('/user/page/' + userId)"/>

    <v-layout wrap>
      <v-flex xs12 md6 offset-md-3>
        <v-btn data-cy="backBtn" v-if="!updatingEnabled" style='width:100%;margin-bottom: 10px;' @click="$emit('back')">
          <v-icon>mdi-chevron-up</v-icon>{{$t('Back')}}
        </v-btn>
      </v-flex>
    </v-layout>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-layout>
        <v-flex xs12 md6 offset-md-3>
          <v-switch
            data-cy="driverOwnerOperatorSWITCH"
            :disabled="isFieldReadOnly('driverOwnerOperatorSWITCH')"
            v-if="!isFieldHidden('driverOwnerOperatorSWITCH') && !updatingEnabled"
            class="ml-5 mb-2"
            v-model="isDriverOwnerOperator"
            @change="onOwnerOperatorSwitch"
            inset
            :label="$t('User.Fields.driverOwnerOperatorSWITCH')"></v-switch>

          <v-text-field
            data-cy="driverOwnerOperatorPercent"
            class="mb-2"
            :disabled="isFieldReadOnly('driverOwnerOperatorPercent')"
            v-if="fields.driverOwnerOperatorSWITCH && !isFieldHidden('driverOwnerOperatorPercent')"
            v-model="fields.driverOwnerOperatorPercent"
            :label="$t('User.Fields.driverOwnerOperatorPercent')"
            suffix="%"
            prepend-icon="fa-percent"
            type="number" step="0.1" @mousewheel.prevent="" outlined hide-details dense>
          </v-text-field>

          <v-text-field
            data-cy="driverOwnerOperatorDetention"
            class="mb-2"
            :disabled="isFieldReadOnly('driverOwnerOperatorDetention')"
            v-if="fields.driverOwnerOperatorSWITCH && !isFieldHidden('driverOwnerOperatorDetention')"
            v-model="fields.driverOwnerOperatorDetention"
            :label="$t('User.Fields.driverOwnerOperatorDetention')"
            suffix="$"
            prepend-icon="fa-dollar-sign"
            type="number" step="0.1" @mousewheel.prevent="" outlined hide-details dense>
          </v-text-field>

          <v-text-field
            data-cy="driverOwnerOperatorNameOfBank"
            class="mb-2"
            :disabled="isFieldReadOnly('driverOwnerOperatorNameOfBank')"
            v-if="fields.driverOwnerOperatorSWITCH && !isFieldHidden('driverOwnerOperatorNameOfBank')"
            v-model="fields.driverOwnerOperatorNameOfBank"
            :label="$t('User.Fields.driverOwnerOperatorNameOfBank')"
            prepend-icon="fa-university"
            type="text" outlined hide-details dense></v-text-field>

          <v-text-field
            data-cy="driverOwnerOperatorBusinessName"
            class="mb-2"
            :disabled="isFieldReadOnly('driverOwnerOperatorBusinessName')"
            v-if="fields.driverOwnerOperatorSWITCH && !isFieldHidden('driverOwnerOperatorBusinessName')"
            v-model="fields.driverOwnerOperatorBusinessName"
            :label="$t('User.Fields.driverOwnerOperatorBusinessName')"
            prepend-icon="fa-id-badge"
            type="text" outlined hide-details dense></v-text-field>

          <v-text-field
            data-cy="driverOwnerOperatorAddress"
            class="mb-2"
            :disabled="isFieldReadOnly('driverOwnerOperatorAddress')"
            v-if="fields.driverOwnerOperatorSWITCH && !isFieldHidden('driverOwnerOperatorAddress')"
            v-model="fields.driverOwnerOperatorAddress"
            :label="$t('User.Fields.driverOwnerOperatorAddress')"
            prepend-icon="fa-map"
            type="text" outlined hide-details dense></v-text-field>

          <v-text-field
            data-cy="driverOwnerOperatorAccount"
            class="mb-2"
            :disabled="isFieldReadOnly('driverOwnerOperatorAccount')"
            v-if="fields.driverOwnerOperatorSWITCH && !isFieldHidden('driverOwnerOperatorAccount')"
            v-model="fields.driverOwnerOperatorAccount"
            :label="$t('User.Fields.driverOwnerOperatorAccount')"
            prepend-icon="mdi-bank-outline"
            type="text" outlined hide-details dense></v-text-field>

          <v-text-field
            data-cy="driverOwnerOperatorRouting"
            class="mb-2"
            :disabled="isFieldReadOnly('driverOwnerOperatorRouting')"
            v-if="fields.driverOwnerOperatorSWITCH && !isFieldHidden('driverOwnerOperatorRouting')"
            v-model="fields.driverOwnerOperatorRouting"
            :label="$t('User.Fields.driverOwnerOperatorRouting')"
            prepend-icon="mdi-bank-outline"
            type="text" outlined hide-details dense></v-text-field>

          <!-- UNIVERSAL USER FIELDS -->

          <v-text-field
            data-cy="phoneNumber"
            class="mb-2"
            :disabled="isFieldReadOnly('phoneNumber')"
            v-if="!isFieldHidden('phoneNumber')"
            v-model="fields.phoneNumber"
            :label="$t('User.Fields.phoneNumber')"
            prepend-icon="fa-phone"
            type="text" outlined hide-details dense></v-text-field>

          <v-text-field
            data-cy="SSN"
            class="mb-2"
            :disabled="isFieldReadOnly('SSN')"
            v-if="!isFieldHidden('SSN')"
            v-model="fields.SSN"
            :label="$t('User.Fields.SSN')"
            prepend-icon="fa-id-card"
            :append-icon="updatingEnabled ? (isFieldEncrypted('SSN') ? 'mdi-eye-off' : 'mdi-eye') : null"
            @click:append="decryptField('SSN',$route.params.id)"
            :type="isFieldEncrypted('SSN') ? 'password' : 'text'"
            outlined hide-details dense>
          </v-text-field>

          <v-textarea
            data-cy="paymentDetails"
            class="mb-2"
            :disabled="isFieldReadOnly('paymentDetails')"
            v-if="!isFieldHidden('paymentDetails')"
            v-model="fields.paymentDetails"
            :label="$t('User.Fields.paymentDetails')"
            prepend-icon="fa-file-invoice-dollar"
            outlined hide-details></v-textarea>

          <SelectUser
            :label="$t('User.Fields.linkedToDispatcher')"
            role="Dispatcher"
            :disabled="isFieldReadOnly('driverOwnerOperatorlinkedToDispatcher')"
            v-if="!isFieldHidden('driverOwnerOperatorlinkedToDispatcher')"
            :value="fields.linkedToDispatcher"
            icon-size="24px"
            @change="linkedToDispatcherChanged">
          </SelectUser>

          <SelectUser
            :label="$t('User.Fields.driverOwnerOperatorlinkedUsers')"
            excludeRole="Broker"
            :disabled="isFieldReadOnly('driverOwnerOperatorlinkedUsers')"
            v-if="!isFieldHidden('driverOwnerOperatorlinkedUsers')"
            :value="fields.driverOwnerOperatorlinkedUsers"
            icon-size="24px"
            :multiple="true"
            @change="linkedUsersChanged">
          </SelectUser>
        </v-flex>
      </v-layout>
      <UsersFormSaveCancelButtons v-if="isSaveAllowed && updatingEnabled"
                                 :loading="isFieldLoading('saveBtn')"
                                 @save="saveFields"
                                 @cancel="$root.route('/user/page/' + userId)"/>
    </v-form>
  </div>
</template>

<script>
import SelectUser from '../../components/SelectUser.vue';
import UsersFormSaveCancelButtons from '../../components/UsersFormSaveCancelButtons';
import fieldLoadingMixin from '../../mixins/fieldLoadingMixin';
import fieldAccessRightMixin from '../../mixins/fieldAccessRightsMixin';
import userFormsCommonMixin from '../../mixins/userFormsCommonMixin';

export default {
  components: { UsersFormSaveCancelButtons, SelectUser },
  props: ['updatingEnabled', 'userId', 'ownerOperator', 'isOwnSettings', 'isNew'],
  mixins: [fieldLoadingMixin, fieldAccessRightMixin, userFormsCommonMixin],
  data: () => ({
    valid: true,
    requiredEnabled: true,
    fields: {
      paymentDetails: '',
      paymentDetailsUPLOAD: '',
      agreementUPLOAD: '',
      passportUPLOAD: '',
      phoneNumber: '',
      SSN: '',
      resumeUPLOAD: '',
      W2FormUPLOAD: '',
      W9FormUPLOAD: '',
      driverOwnerOperatorSWITCH: 1,
      driverOwnerOperatorPercent: 0,
      driverOwnerOperatorDetention: 0,
      driverOwnerOperatorIENUPLOAD: '',
      driverOwnerOperatorInsuranceUPLOAD: '',
      driverOwnerOperatorAuthorityFormUSDOTUPLOAD: '',
      driverOwnerOperatorAuthorityFormMCUPLOAD: '',
      driverOwnerOperatorAuthorityFormCAUPLOAD: '',
      driverOwnerOperatorStatementOfInformationCorporationUPLOAD: '',
      driverOwnerOperatorNameOfBank: '',
      driverOwnerOperatorBusinessName: '',
      driverOwnerOperatorAddress: '',
      driverOwnerOperatorAccount: '',
      driverOwnerOperatorRouting: '',
      linkedToDispatcher: 0,
      driverOwnerOperatorlinkedUsers: []
    },
    documentsTab: 0
  }),
  computed: {
    isDriverOwnerOperator: {
      set (val) {
        this.fields.driverOwnerOperatorSWITCH = val ? 1 : 0;
      },
      get () {
        return this.fields.driverOwnerOperatorSWITCH === 1;
      }
    }
  },
  mounted () {
    if (!this.isNew) {
      this.fetchDataForSettingsModal();
    }
  },
  methods: {
    linkedToDispatcherChanged (value) {
      this.$set(this.fields, 'linkedToDispatcher', value);
    },
    linkedUsersChanged (value) {
      this.$set(this.fields, 'driverOwnerOperatorlinkedUsers', value);
    },
    onOwnerOperatorSwitch () {
      this.$emit('ownerOperatorDisable');
    },
    isValid () {
      return this.$refs.form.validate();
    },
    initData () {

    },
    setFields (fields, userRole, rights = null) {
      for (let key in this.fields) {
        this.fields[key] = fields[key] !== null ? fields[key] : '';
      }
      this.initData();
      this.loadImages();
      this.applyRights(this.isOwnSettings ? '' : userRole, this.isOwnSettings ? 'settings' : 'users', { deleteUsersDocuments: 0 });
    }
  }
};
</script>
