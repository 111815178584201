<template>
  <div>
    <template v-if="$root.isMobile">
      <v-skeleton-loader type="list-item" class="ma-2" v-if="loading"/>
      <v-list class="list-more-dense" v-else>
        <template v-for="key in Object.keys(totalsCountsNames)">
          <v-list-item v-if="totalsCounts.hasOwnProperty(key)" :key="key">
            <v-list-item-title>{{totalsCountsNames[key]}}</v-list-item-title>
            <v-list-item-action>
              <template v-if="totalsCountsTypes[key] === 'Miles'">
                {{totalsCounts[key]}}{{$t('milesShort')}}
              </template>
              <template v-else-if="totalsCountsTypes[key] === 'Money'">
                {{$root.moneyValue(totalsCounts[key], 0)}}
              </template>
              <template v-else>
                {{totalsCounts[key]}}
              </template>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </template>
    <template v-else>
      <v-skeleton-loader type="table-row" v-if="loading"/>
      <v-simple-table dense class="mb-5" v-else>
        <template v-slot:default>
          <thead>
            <tr>
              <template v-for="key in Object.keys(totalsCountsNames)">
                <th v-if="totalsCounts.hasOwnProperty(key)" :key="'header' + key" class="text-center">{{totalsCountsNames[key]}}</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr>
              <template v-for="key in Object.keys(totalsCountsNames)">
                <td v-if="totalsCounts.hasOwnProperty(key)" :key="'row' + key" class="text-center">
                  <h2>
                    <template v-if="totalsCountsTypes[key] === 'Miles'">
                      {{totalsCounts[key]}}{{$t('milesShort')}}
                    </template>
                    <template v-else-if="totalsCountsTypes[key] === 'Money'">
                      {{$root.moneyValue(totalsCounts[key], 2)}}
                    </template>
                    <template v-else>
                      {{totalsCounts[key]}}
                    </template>
                  </h2>
                </td>
              </template>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </div>
</template>

<script>
import reportsMixin from '../../mixins/reportsMixin.js';
export default {
  name: 'DispatchersTotals',
  props: ['to', 'from', 'dispatcherId'],
  mixins: [ reportsMixin ],
  components: {
  },
  data: function () {
    return {
      loading: false,
      totalsCounts: {}
    };
  },
  mounted () {
    this.setDispatchersTotalsHeaders();
    this.fetchData();
  },
  computed: {
    totalsCountsNames () {
      return this.$store.getters['reports/totalsCountsNames'];
    },
    totalsCountsTypes () {
      return this.$store.getters['reports/totalsCountsTypes'];
    }
  },
  methods: {
    fetchData () {
      if (!this.from || !this.to) return;
      this.loading = true;
      // Get total miles
      this.getDispatchersReportTotalData(this.from, this.to, this.dispatcherId || 0).then((response) => {
        if (response.status === 'ok') {
          this.totalsCounts = response.results;
        }
      }).finally(() => {
        this.loading = false;
      });
    }

  }
};
</script>
