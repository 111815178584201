<template>
  <v-card>
    <v-card-text class="mx-auto" elevation="0" xs-12>
      <v-card-title v-if="isInModal">
        {{$t('Trucks.MaintenanceOriginalParts')}} - {{truckData.name}}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-title v-else>
        {{$t('Trucks.MaintenanceOriginalParts')}}
      </v-card-title>
      <v-card-text style="max-height: 500px; overflow-y: auto;">
        <v-list class="ma-0" dense>
          <v-simple-table class="ml-3" v-if="truckData">
          <tr>
            <td class="font-weight-bold">{{$t('Trucks.Fields.vin')}}</td>
            <td>{{truckData.vin ? truckData.vin : ' - '}}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">{{$t('Trucks.Fields.year')}}</td>
            <td>{{truckData.year ? truckData.year : ' - '}}</td>
          </tr>
          </v-simple-table>
          <template v-for="part in originalParts">
            <v-list-item :key="part.id" dense>
              <v-list-item-content>
                <h4>{{part.maintenanceType}}</h4>
                <v-simple-table dense>
                  <thead>
                  <tr>
                    <th>{{$t('Trucks.maintenancePartName')}}</th>
                    <th>{{$t('Trucks.maintenancePartCode')}}</th>
                    <th>{{$t('Trucks.maintenancePartAmount')}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="p in part.parts">
                    <td class="pl-5">{{p.partName}}</td>
                    <td class="pl-5">{{p.partCode ? '[' + p.partCode + ']' : ''}}</td>
                    <td class="pl-5">{{p.partAmount}}</td>
                  </tr>
                  </tbody>
                </v-simple-table>
              </v-list-item-content>
            </v-list-item>
            <v-divider/>
          </template>
        </v-list>
      </v-card-text>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'TruckOriginalParts',
  props: ['originalParts', 'truckData', 'isInModal'],
  computed: {
  },
  data: function () {
    return {
    };
  },
  watch: {
  },
  computed: {
  },
  mounted () {
  },
  methods: {
  }

};
</script>
