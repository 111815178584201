<template>
    <v-card class="pl-5 pa-2 mr-5"><!-- :style="getStatusLightStyle">-->
      <!--{{index}}<br/>{{locations[index]}}-->
      <!--<v-overlay
        :absolute="true"
        :value="$store.getters['loads/getDistanceCalculationLoading']"
      >
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>-->
      <v-layout wrap>
        <v-flex xs12 md6>
          <!-- <template v-if="!edit">
            <span class="ml-3">{{$t('Loads.ByMap')}}</span>:&nbsp;
            <v-icon class="ml-2" small>fa-route</v-icon> {{$root.metersToMiles(locations[index].distance).toLocaleString() }}&nbsp;{{$t('milesShort')}}&nbsp;
            <v-icon class="ml-2">mdi-clock-fast</v-icon> {{ $root.showDuration(locations[index].duration) }}
          </template> -->
          <template>
              <span class="ml-3">{{$t('Loads.ToLocationTarget')}}</span>:&nbsp;
              <v-icon class="ml-3" small>fa-route</v-icon> {{$root.metersToMiles(locations[index].distance).toLocaleString() }}&nbsp;{{$t('milesShort')}}&nbsp;
              <v-icon class="ml-3">mdi-clock-fast</v-icon> {{ $root.showDuration(locations[index].duration) }}
            </template>
            <template v-if="locations[index+1].startedByDriverTS > 0 && !locations[index+1].onSiteByDriverTS">
              <br/>
              <span class="ml-3">{{$t('Loads.ToLocationLeft')}}</span>:&nbsp;
              <v-icon class="ml-2" small>fa-route</v-icon> {{$root.metersToMiles(locations[index+1].currentDistance).toLocaleString()}}&nbsp;{{$t('milesShort')}}
              <v-icon class="ml-2">mdi-clock-fast</v-icon> {{$root.showDuration(locations[index+1].currentDuration)}}
              &nbsp;
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" small icon class="mr-2" @click="$emit('refreshDistanceAndDuration', index + 1)">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('Refresh')}}</span>
              </v-tooltip>
            </template>
        </v-flex>
        <v-flex xs12 md6 class="text-right">
          <template v-if="!edit">
              <v-btn small
                      class="mb-2"
                      v-if="!locationStatusHidden &&
                            prevLocation &&
                            (prevLocation.dispatcherOnTheWayCheck > 0 || prevLocation.canceled === 1) &&
                            prevLocation.truck > 0 &&
                            prevLocation.driver > 0"
                      :disabled="locations[index+1].driverOnTheWayCheck > 0 ||
                                locationStatusReadOnly ||
                                readonly ||
                                locations[index + 1].canceled === 1"
                      @click="onStartClick(index + 1)">
                <v-icon left v-if="locations[index+1].driverOnTheWayCheck" color="success">mdi-check</v-icon>
                <span v-if="locations[index+1].driverOnTheWayCheck">{{$t('Loads.LocationStarted')}}</span>
                <span v-else>{{getStartBtnToString(index + 1)}}</span>
                <span v-if="locations[index+1].driverOnTheWayCheck && !$vuetify.breakpoint.xs">&nbsp;{{$t('At')}}&nbsp;{{$root.getDateTimeStringFromTimestamp(locations[index+1].driverOnTheWayCheck)}}</span>
              </v-btn>
              <v-btn icon class="mb-2"
                      v-if="locations[index + 1].driverOnTheWayCheck > 0 && !locations[index + 1].driverOnSiteCheck && !locationStatusHidden"
                      :disabled="locationStatusReadOnly || readonly || locations[index + 1].canceled === 1"
                      @click="onStartCancelClick(index + 1)">
                <v-icon color="error">mdi-cancel</v-icon>
              </v-btn>
              <div class="caption" v-if="locations[index+1].startedByDriverTS && locations[index+1].startedByDriver && usersInfo[locations[index+1].startedByDriver]">
                {{$t('by')}}&nbsp;<a href="#" @click.stop="$root.route('/user/page/' + usersInfo[locations[index+1].startedByDriver].id)">{{usersInfo[locations[index+1].startedByDriver].name}}</a>
              </div>
          </template>
        </v-flex>
        <!--<v-flex xs12  md6>
          <v-flex class="pt-1" style="max-width:300px;" v-if="!isDriverOwnerOperator && (locations[index+1].driverOnTheWayCheck || edit)">
            <OdometerInput
              :label="$root.t('Loads.odometerStartData')"
              :locationOdometerHidden="locationOdometerHidden"
              :locationOdometerReadOnly="locationOdometerReadOnly || !edit"
              :odometerUPLOADHidden="odometerUPLOADHidden"
              :odometerUPLOADReadOnly="odometerUPLOADReadOnly || !edit"
              folderName="odometerStart"
              :disabled="readonly"
              :hideUploadBtn="true"
              :field="locations[index+1].odometerStart"
              :fieldUPLOAD="locations[index+1].odometerStartUPLOAD"
              @odometerValueChanged="(val) => { onOdometerChanged(index + 1, val, true) }"
              @odometerUploaded="(val) => { onOdometerUploadChanged(index + 1, val, true) }"
              @odometerRemove="() => { onOdometerUploadChanged(index + 1, '', true) }"
            />
          </v-flex>
        </v-flex>-->
      </v-layout>
      <!--{{index}}-->
      <!--{{locations[index]}}-->
    </v-card>
</template>

<script>
import loadsMixin from '../mixins/loadsMixin';

export default {
  name: 'LoadProgressDrivingCard',
  props: ['index', 'edit', 'currentLat', 'currentLng', 'locationStatusHidden', 'locationStatusReadOnly', 'loadId',
    'locationOdometerHidden', 'locationOdometerReadOnly',
    'odometerUPLOADHidden', 'odometerUPLOADReadOnly', 'isDriverOwnerOperator', 'driversInfo', 'usersInfo', 'readonly'],
  components: {},
  mixins: [ loadsMixin ],
  data () {
    return {
      updating: null
    };
  },
  computed: {
    locations () {
      return this.$store.getters['loads/getLocations'];
    },
    location () {
      return this.$store.getters['loads/getLocation'](this.index);
    },
    prevLocation () {
      let i = 0;
      for (i = this.index; i >= 0; i--) {
        if (parseInt(this.locations[i].canceled) === 0) {
          return this.locations[i];
        }
      }
      return null;
    },
    getStatusLightStyle () {
      if (this.edit) {
        return {};
      }
      if (this.locations[this.index + 1].driverOnSiteCheck) {
        return { boxShadow: '0px 0px 5px 5px #66BB6A' };
      } else if (this.locations[this.index + 1].driverOnTheWayCheck) {
        return { boxShadow: '0px 0px 5px 5px #FF8F00' };
      }
      return { };
    }
  },
  mounted () {
  },
  beforeDestroy () {
    clearInterval(this.updating);
  },
  methods: {
    getStartBtnToString (index) {
      let type = 'n/a';
      const location = this.locations[index];

      if (location.pickup) { type = this.$root.t('Loads.Pickup'); } else if (location.delivery) { type = this.$root.t('Loads.Delivery'); } else if (location.parking) { type = this.$root.t('Loads.Parking'); } else if (location.exchange) { type = this.$root.t('Loads.Exchange'); } else if (location.returnToHQ) { type = this.$root.t('Loads.ReturnToHQ'); }

      return this.$root.t('Loads.startToString', { type: type, name: location.name });
    },
    /*statusChange (index, value) {
      this.$store.dispatch('loads/statusChange', { index: index, value: value, userId: this.$store.getters.currentUser.id }).then(() => {
        this.$emit('refreshDistanceAndDuration', index);
        let fields = {
          locationsJSON: JSON.stringify(this.locations)
        };
        this.$root.saveLoad(fields, this.loadId).then(() => {
          this.$root.getLoadUpdated(this.loadId).then((response) => {
            if (response.status === 'ok') {
              this.$store.commit('loads/updateLoadUpdated', response.results);
            }
          });
        });
      });
    },*/
    onStartClick (index) {
      /*const truckId = this.locations[index - 1].truck;
      this.$root.getTruckMiles(truckId).then((response) => {
        if (response.status === 'ok') {
          this.$store.dispatch('loads/setLocationField', { index: index, field: 'odometerStart', value: parseInt(response.results) });
          this.statusChange(index, 'driverOnTheWayCheck');
        }
      });*/
      this.setLoadLocationsStart(this.location.loadId, this.location.number + 1).then((response) => {
        if (response.status === 'ok') {
          this.$store.commit('loads/updateLoadUpdated', response.updated);
          this.$store.dispatch('loads/statusChange', { index: index, value: 'driverOnTheWayCheck', userId: this.$store.getters.currentUser.id });
          this.$store.dispatch('loads/statusChange', { index: index, value: 'startedByDriverTS', userId: this.$store.getters.currentUser.id });
          this.$emit('refreshDistanceAndDuration', index);
        } else if (response.status === 'error') {
          this.$root.toast(response.msg, { color: 'error' });
        }
      });
    },
    onStartCancelClick (index) {
      this.cancelLoadLocationsStart(this.location.loadId, this.location.number + 1).then((response) => {
        if (response.status === 'ok') {
          this.$store.commit('loads/updateLoadUpdated', response.updated);
          this.$store.dispatch('loads/statusChange', { index: index, value: 'driverOnTheWayCheck', userId: this.$store.getters.currentUser.id });
          this.$store.dispatch('loads/statusChange', { index: index, value: 'startedByDriverTS', userId: this.$store.getters.currentUser.id });
          this.$store.dispatch('loads/setLocationField', { index: index, field: 'odometer', value: response.odometerValue });

          this.$emit('refreshDistanceAndDuration', index);
        } else if (response.status === 'error') {
          this.$root.toast(response.msg, { color: 'error' });
        }
      });
    }
  }
};
</script>
