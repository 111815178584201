<template>
  <div class="text-center">
    <UsersFormSaveCancelButtons v-if="isSaveAllowed && updatingEnabled"
                               :loading="isFieldLoading('saveBtn')"
                               @save="saveFields"
                               @cancel="$root.route('/user/page/' + userId)"/>
    <v-layout wrap>
      <v-flex xs12 md6 offset-md-3>
        <v-btn data-cy="backBtn" v-if="!updatingEnabled" style='width:100%;margin-bottom: 10px;' @click="$emit('back')">
          <v-icon>mdi-chevron-up</v-icon>{{$t('Back')}}
        </v-btn>
      </v-flex>
    </v-layout>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-layout>
        <v-flex xs12 md6 offset-md-3>
          <h4 v-if="!isFieldHidden('brokerManagersJSON') && !isFieldReadOnly('brokerManagersJSON')">{{$t('User.Fields.brokerManagersJSON')}}</h4>
          <v-layout wrap v-if="!isFieldHidden('brokerManagersJSON') && !isFieldReadOnly('brokerManagersJSON')">
            <v-flex xs12 sm4>
              <v-text-field data-cy="managerName"
                            prepend-icon="fa-user-alt"
                            :error="managerNameValid?null:true"
                            v-model="managerName"
                            class="mr-2 mb-2"
                            :label="$t('CreateUser.FullName')"
                            outlined hide-details dense></v-text-field>
            </v-flex>

            <v-flex xs10 sm3>
              <v-text-field data-cy="managerPhone"
                            prepend-icon="fa-phone"
                            :append-icon="morePhones ? 'mdi-minus' : 'mdi-plus'"
                            @click:append="toggleMorePhones"
                            :error="managerPhoneValid?null:true"
                            v-model="managerPhone"
                            class="mr-2 mb-2"
                            :label="$t('CreateUser.PhoneWork')"
                            outlined hide-details dense></v-text-field>
            </v-flex>
            <v-flex xs2 sm1>
              <v-text-field data-cy="managerPhoneExt"
                            v-model="managerPhoneExt"
                            class="mr-2 mb-2"
                            label="Ext."
                            outlined hide-details dense></v-text-field>
            </v-flex>

            <v-flex xs12 sm4>
              <v-text-field data-cy="managerEmail"
                            prepend-icon="fa-envelope"
                            :error="managerEmailValid ? null : true"
                            v-model="managerEmail"
                            class="mr-2 mb-2"
                            :label="$t('CreateUser.EMail')"
                            outlined hide-details dense></v-text-field>
            </v-flex>

            <!--Additiobal phones-->
            <v-layout wrap v-show="morePhones">
              <v-flex xs10 sm2>
                <v-text-field data-cy="managerPhoneCell"
                              prepend-icon="fa-mobile-alt"
                              :error="managerPhoneValid ? null : true"
                              v-model="managerPhoneCell"
                              class="mr-2 mb-2"
                              :label="$t('CreateUser.PhoneCell')"
                              outlined hide-details dense></v-text-field>
              </v-flex>
              <v-flex xs2 sm1>
                <v-text-field data-cy="managerPhoneCellExt" v-model="managerPhoneCellExt" class="mr-2 mb-2" label="Ext." outlined hide-details dense></v-text-field>
              </v-flex>
              <v-flex xs10 sm2>
                <v-text-field data-cy="managerPhoneHome" prepend-icon="fa-home" :error="managerPhoneValid?null:true" v-model="managerPhoneHome" class="mr-2 mb-2" :label="$t('CreateUser.PhoneHome')" outlined hide-details dense></v-text-field>
              </v-flex>
              <v-flex xs2 sm1>
                <v-text-field data-cy="managerPhoneHomeExt" v-model="managerPhoneHomeExt" class="mr-2 mb-2" label="Ext." outlined hide-details dense></v-text-field>
              </v-flex>
              <v-flex xs10 sm2>
                <v-text-field data-cy="managerPhoneFax" prepend-icon="fa-fax" :error="managerPhoneValid?null:true" v-model="managerPhoneFax" class="mr-2 mb-2" :label="$t('CreateUser.PhoneFax')" outlined hide-details dense></v-text-field>
              </v-flex>
              <v-flex xs2 sm1>
                <v-text-field data-cy="managerPhoneFaxExt" v-model="managerPhoneFaxExt" class="mr-2 mb-1" label="Ext." outlined hide-details dense></v-text-field>
              </v-flex>
              <v-flex xs10 sm2>
                <v-text-field data-cy="managerPhoneAdditional" prepend-icon="fa-phone" :error="managerPhoneValid?null:true" v-model="managerPhoneAdditional" class="mr-2 mb-2" :label="$t('CreateUser.PhoneAdditional')" outlined hide-details dense></v-text-field>
              </v-flex>
              <v-flex xs2 sm1>
                <v-text-field data-cy="managerPhoneAdditionalExt" v-model="managerPhoneAdditionalExt" class="mr-2 mb-2" label="Ext." outlined hide-details dense></v-text-field>
              </v-flex>

            </v-layout>

            <!--home, work, mobile, fax, etc-->
            <v-btn data-cy="managerAddBtn" width="100%" color="primary" class="mb-1" :loading="isFieldLoading('brokerManagersJSON')" v-if="!editManagerMode" @click="addManager">{{$t('CreateUser.AddManager')}}</v-btn>
            <v-btn data-cy="managerUpdateBtn" v-if="editManagerMode" class="mb-1" color="primary" :loading="isFieldLoading('brokerManagersJSON')" @click="updateManager">{{$t('CreateUser.UpdateManager')}}</v-btn>
            <v-btn data-cy="managerCancelBtn" v-if="editManagerMode" text class="mb-1" @click="cancelEditManager">{{$t('Cancel')}}</v-btn>
          </v-layout>

          <v-data-table v-if="!isFieldHidden('brokerManagersJSON')"
                        style="width:100%;text-align: left;"
                        dense
                        :headers="brokerManagersHeaders"
                        :items="brokerManagers"
                        item-key="uid"
                        :disable-sort="true"
                        class="elevation-1"
                        @click:row="editManager($event.uid)">
            <template v-slot:item.incopy="{ item }">
              <v-switch inset :data-cy="item.name+'_InCopySwitch'" :disabled="isFieldReadOnly('brokerManagersJSON')" v-model="item.incopy" @change="changeManagerInCopy"></v-switch>
            </template>
            <template v-slot:item.phone="{ item }">
              <v-simple-table dense class="mt-2 mb-2">
                <template v-slot:default>
                  <tbody>
                    <tr v-if="item.phone">
                      <td><v-btn :href="'tel:'+item.phone" icon x-small><v-icon x-small>fa-phone</v-icon></v-btn></td>
                      <td>{{item.phone}}</td>
                      <td><span v-if="item.phoneExt">Ext. {{item.phoneExt}}</span></td>
                    </tr>

                    <tr v-if="item.phoneCell">
                      <td><v-btn :href="'tel:'+item.phoneCell" icon x-small><v-icon x-small>fa-mobile-alt</v-icon></v-btn></td>
                      <td>{{item.phoneCell}}</td>
                      <td><span v-if="item.phoneCellExt">Ext. {{item.phoneCellExt}}</span></td>
                    </tr>

                    <tr v-if="item.phoneHome">
                      <td><v-btn :href="'tel:'+item.phoneHome" icon x-small><v-icon x-small>fa-home</v-icon></v-btn></td>
                      <td>{{item.phoneHome}}</td>
                      <td><span v-if="item.phoneHomeExt">Ext. {{item.phoneHomeExt}}</span></td>
                    </tr>

                    <tr v-if="item.phoneFax">
                      <td><v-btn :href="'tel:'+item.phoneFax" icon x-small><v-icon x-small>fa-fax</v-icon></v-btn></td>
                      <td>{{item.phoneFax}}</td>
                      <td><span v-if="item.phoneFaxExt">Ext. {{item.phoneFaxExt}}</span></td>
                    </tr>

                    <tr v-if="item.phoneAdditional">
                      <td><v-btn :href="'tel:'+item.phoneAdditional" icon x-small><v-icon x-small>fa-phone</v-icon></v-btn></td>
                      <td>{{item.phoneAdditional}}</td>
                      <td><span v-if="item.phoneAdditionalExt">Ext. {{item.phoneAdditionalExt}}</span></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn small icon :data-cy="item.name+'_managerEditBtn'" :disabled="isFieldReadOnly('brokerManagersJSON')" @click="editManager(item.uid)"><v-icon small color="grey lighten-1">mdi-pen</v-icon></v-btn>
              <v-btn small icon :data-cy="item.name+'_managerRemoveBtn'" :disabled="isFieldReadOnly('brokerManagersJSON')"
                     v-confirm="{ok: dialog => removeManager(item.uid), cancel: () => {}, message: $t('CreateUser.RemoveManagerConfirmMsg')}">
                <v-icon small color="red lighten-1">fa-trash</v-icon></v-btn>
            </template>
          </v-data-table>
          <br/>

          <v-textarea
            data-cy="brokerBillTo"
            class="mb-2"
            v-model="fields.brokerBillTo"
            :label="$t('User.Fields.brokerBillTo')"
            :disabled="isFieldReadOnly('brokerBillTo')"
            v-if="!isFieldHidden('brokerBillTo')"
            prepend-icon="fa-file-invoice"
            outlined hide-details>
          </v-textarea>

          <v-text-field
            data-cy="brokerDaysToPay"
            class="mb-2"
            v-model="fields.brokerDaysToPay"
            :label="$t('User.Fields.brokerDaysToPay')"
            :disabled="isFieldReadOnly('brokerDaysToPay')"
            v-if="!isFieldHidden('brokerDaysToPay')"
            prepend-icon="fa-calendar-day"
            type="number" outlined hide-details dense></v-text-field>

          <v-layout wrap>
            <v-flex xs12 md4>
              <v-switch
                style="margin-top:-5px;"
                data-cy="brokerWithFactoringSWITCH"
                :disabled="isFieldReadOnly('brokerWithFactoringSWITCH')"
                v-if="!isFieldHidden('brokerWithFactoringSWITCH')"
                class="ml-10 mb-3 mt-1"
                v-model="isBrokerWithFactoring"
                inset hide-details
                :label="$t('User.Fields.brokerWithFactoringSWITCH')"></v-switch>
            </v-flex>
            <v-flex xs12 md8>
              <v-text-field
                xs12 md6
                class="mb-2"
                data-cy="brokerFactoringPercent"
                :disabled="isFieldReadOnly('brokerFactoringPercent')"
                v-if="!isFieldHidden('brokerFactoringPercent') && isBrokerWithFactoring"
                v-model="fields.brokerFactoringPercent"
                :label="$t('User.Fields.brokerFactoringPercent')"
                prefix="%"
                type="number" step="0.1" @mousewheel.prevent="" outlined hide-details dense>
              </v-text-field>
            </v-flex>
          </v-layout>

          <v-textarea
            data-cy="brokerFactoringPaymentDetails"
            class="mb-2"
            v-model="fields.brokerFactoringPaymentDetails"
            :label="$t('User.Fields.brokerFactoringPaymentDetails')"
            :disabled="isFieldReadOnly('brokerFactoringPaymentDetails')"
            v-if="isBrokerWithFactoring"
            prepend-icon="fa-file-invoice"
            outlined hide-details>
          </v-textarea>

          <v-text-field
            data-cy="brokerCompanyName"
            class="mb-2"
            :disabled="isFieldReadOnly('brokerCompanyName')"
            v-if="!isFieldHidden('brokerCompanyName')"
            v-model="fields.brokerCompanyName"
            :label="$t('User.Fields.brokerCompanyName')"
            prepend-icon="fa-building"
            type="text" outlined hide-details dense></v-text-field>

          <v-text-field
            data-cy="brokerContactPerson"
            class="mb-2"
            :disabled="isFieldReadOnly('brokerContactPerson')"
            v-if="!isFieldHidden('brokerContactPerson')"
            v-model="fields.brokerContactPerson"
            :label="$t('User.Fields.brokerContactPerson')"
            prepend-icon="fa-address-book"
            type="text" outlined hide-details dense></v-text-field>

          <!-- UNIVERSAL USER FIELDS -->

          <v-text-field
            autocomplete="off"
            data-cy="phoneNumber"
            class="mb-2"
            :disabled="isFieldReadOnly('phoneNumber')"
            v-if="!isFieldHidden('phoneNumber')"
            v-model="fields.phoneNumber"
            :label="$t('User.Fields.phoneNumber')"
            prepend-icon="fa-phone"
            type="text" outlined hide-details dense></v-text-field>

          <v-text-field
            class="mb-2"
            autocomplete="off"
            data-cy="SSN"
            :disabled="isFieldReadOnly('SSN')"
            v-if="!isFieldHidden('SSN')"
            v-model="fields.SSN"
            :label="$t('User.Fields.brokerIEN')"
            prepend-icon="fa-id-card"
            :append-icon="updatingEnabled ? (isFieldEncrypted('SSN') ? 'mdi-eye-off' : 'mdi-eye') : null"
            @click:append="decryptField('SSN',$route.params.id)"
            :type="isFieldEncrypted('SSN') ? 'password' : 'text'"
            outlined hide-details dense>
          </v-text-field>

          <v-textarea
            data-cy="paymentDetails"
            class="mb-2"
            :disabled="isFieldReadOnly('paymentDetails')"
            v-if="!isFieldHidden('paymentDetails')"
            v-model="fields.paymentDetails"
            :label="$t('User.Fields.brokerPaymentNotes')"
            prepend-icon="fa-file-invoice-dollar" outlined hide-details></v-textarea>
        </v-flex>
      </v-layout>
    </v-form>
    <UsersFormSaveCancelButtons v-if="isSaveAllowed && updatingEnabled"
                               :loading="isFieldLoading('saveBtn')"
                               @save="saveFields"
                               @cancel="$root.route('/user/page/' + userId)"/>
  </div>
</template>

<script>
import UsersFormSaveCancelButtons from '../../components/UsersFormSaveCancelButtons';
import fieldLoadingMixin from '../../mixins/fieldLoadingMixin';
import fieldAccessRightMixin from '../../mixins/fieldAccessRightsMixin';
import userFormsCommonMixin from '../../mixins/userFormsCommonMixin';

export default {
  components: { UsersFormSaveCancelButtons },
  mixins: [fieldLoadingMixin, fieldAccessRightMixin, userFormsCommonMixin],
  props: ['updatingEnabled', 'userId', 'isOwnSettings', 'isNew'],
  data: () => ({
    valid: true,
    requiredEnabled: true,
    fields: {
      paymentDetails: '',
      paymentDetailsUPLOAD: '',
      agreementUPLOAD: '',
      passportUPLOAD: '',
      phoneNumber: '',
      SSN: '',
      resumeUPLOAD: '',
      W2FormUPLOAD: '',
      W9FormUPLOAD: '',
      brokerManagersJSON: '[]',
      brokerBillTo: '',
      brokerSetupPackageUPLOAD: '',
      brokerDaysToPay: 0,
      brokerWithFactoringSWITCH: 0,
      brokerFactoringPercent: 0,
      brokerFactoringPaymentDetails: '',
      brokerCompanyName: '',
      brokerContactPerson: ''
    },
    editManagerMode: false,
    editManagerUID: 0,
    brokerManagers: [],
    brokerManagersHeaders: [
      { text: 'Name', value: 'name', divider: false },
      { text: 'Phone', value: 'phone', divider: false },
      { text: 'Email', value: 'email', divider: false },
      { text: 'Include In Copy', value: 'incopy', divider: false },
      { text: 'Actions', value: 'actions', divider: false }
    ],

    morePhones: false,

    managerName: '',
    managerPhone: '',
    managerPhoneExt: '',
    managerEmail: '',

    managerPhoneHome: '',
    managerPhoneHomeExt: '',
    managerPhoneCell: '',
    managerPhoneCellExt: '',
    managerPhoneFax: '',
    managerPhoneFaxExt: '',
    managerPhoneAdditional: '',
    managerPhoneAdditionalExt: '',

    managerNameValid: true,
    managerPhoneValid: true,
    managerEmailValid: true,
    documentsTab: 0
  }),
  computed: {
    isBrokerWithFactoring: {
      set (val) {
        this.fields.brokerWithFactoringSWITCH = val ? 1 : 0;
      },
      get () {
        return this.fields.brokerWithFactoringSWITCH === 1;
      }
    }
  },
  mounted () {
    if (!this.isNew) {
      this.fetchDataForSettingsModal();
    }
  },
  methods: {
    isValid () {
      return this.$refs.form.validate();
    },
    next () {
      if (!this.$refs.form.validate()) {
        this.$root.toast(this.$i18n.t('FormValidationFailed'), { color: 'error' });
        return;
      }
      this.$emit('next');
    },
    toggleMorePhones () {
      this.morePhones = !this.morePhones;
    },
    clearManagerFields () {
      this.managerName = '';
      this.managerPhone = '';
      this.managerPhoneExt = '';
      this.managerEmail = '';
      this.managerPhoneHome = '';
      this.managerPhoneHomeExt = '';
      this.managerPhoneCell = '';
      this.managerPhoneCellExt = '';
      this.managerPhoneFax = '';
      this.managerPhoneFaxExt = '';
      this.managerPhoneAdditional = '';
      this.managerPhoneAdditionalExt = '';
    },
    cancelEditManager () {
      this.clearManagerFields();
      this.editManagerMode = false;
    },
    updateManager () {
      if (!this.validateManagerData()) return;
      for (let i = 0; i < this.brokerManagers.length; i++) {
        if (this.brokerManagers[i].uid === this.editManagerUID) {
          this.brokerManagers[i].name = this.managerName;
          this.brokerManagers[i].phone = this.managerPhone;
          this.brokerManagers[i].phoneExt = this.managerPhoneExt;
          this.brokerManagers[i].email = this.managerEmail;

          this.brokerManagers[i].phoneHome = this.managerPhoneHome;
          this.brokerManagers[i].phoneHomeExt = this.managerPhoneHomeExt;
          this.brokerManagers[i].phoneCell = this.managerPhoneCell;
          this.brokerManagers[i].phoneCellExt = this.managerPhoneCellExt;
          this.brokerManagers[i].phoneFax = this.managerPhoneFax;
          this.brokerManagers[i].phoneFaxExt = this.managerPhoneFaxExt;
          this.brokerManagers[i].phoneAdditional = this.managerPhoneAdditional;
          this.brokerManagers[i].phoneAdditionalExt = this.managerPhoneAdditionalExt;

          break;
        }
      }
      this.fields.brokerManagersJSON = JSON.stringify(this.brokerManagers);
      this.clearManagerFields();
      this.editManagerMode = false;
    },
    changeManagerInCopy () {
      this.fields.brokerManagersJSON = JSON.stringify(this.brokerManagers);
    },
    removeManager (uid) {
      for (let i = 0; i < this.brokerManagers.length; i++) {
        if (this.brokerManagers[i].uid === uid) {
          this.brokerManagers.splice(i, 1);
          break;
        }
      }
      this.fields.brokerManagersJSON = JSON.stringify(this.brokerManagers);
    },
    editManager (uid) {
      this.brokerManagers.forEach((element) => {
        if (element.uid === uid) {
          this.managerName = element.name;
          this.managerPhone = element.phone;
          this.managerPhoneExt = element.phoneExt;
          this.managerEmail = element.email;

          this.managerPhoneHome = element.phoneHome;
          this.managerPhoneHomeExt = element.phoneHomeExt;
          this.managerPhoneCell = element.phoneCell;
          this.managerPhoneCellExt = element.phoneCellExt;
          this.managerPhoneFax = element.phoneFax;
          this.managerPhoneFaxExt = element.phoneFaxExt;
          this.managerPhoneAdditional = element.phoneAdditional;
          this.managerPhoneAdditionalExt = element.phoneAdditionalExt;

          this.editManagerUID = uid;
          this.editManagerMode = true;
        }
      });
    },
    addManager () {
      if (!this.validateManagerData()) return;
      this.brokerManagers.push({
        uid: new Date().getTime(),
        name: this.managerName,
        phone: this.managerPhone,
        phoneExt: this.managerPhoneExt,
        email: this.managerEmail,
        incopy: false,
        phoneHome: this.managerPhoneHome,
        phoneHomeExt: this.managerPhoneHomeExt,
        phoneCell: this.managerPhoneCell,
        phoneCellExt: this.managerPhoneCellExt,
        phoneFax: this.managerPhoneFax,
        phoneFaxExt: this.managerPhoneFaxExt,
        phoneAdditional: this.managerPhoneAdditional,
        phoneAdditionalExt: this.managerPhoneAdditionalExt
      });
      this.fields.brokerManagersJSON = JSON.stringify(this.brokerManagers);
      this.clearManagerFields();
    },
    validateManagerData () {
      this.managerEmailValid = /.+@.+\..+/.test(this.managerEmail);
      this.managerNameValid = this.managerName.length > 0;
      this.managerPhoneValid = true;
      return this.managerEmailValid && this.managerNameValid && this.managerPhoneValid;
    },
    loadManagers () {
      this.brokerManagers = this.fields.brokerManagersJSON ? JSON.parse(this.fields.brokerManagersJSON) : [];
    },
    setFields (fields, userRole, rights = null) {
      for (let key in this.fields) {
        this.fields[key] = fields[key] !== null ? fields[key] : '';
      }
      this.loadImages();
      this.loadManagers();
      this.applyRights(this.isOwnSettings ? '' : userRole, this.isOwnSettings ? 'settings' : 'users', { deleteUsersDocuments: 0 });
    }
  }
};
</script>
