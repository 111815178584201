<template>
  <div>
    <div style="display:block; width: calc(100% - 20px); margin-left:10px;">
      <template v-for="(item, index) in items">
        <v-tooltip bottom :key="index" v-if="item.percent > 0">
          <template v-slot:activator="{ on }">
            <div v-on="on" class="text-center pl-1" :style="{display:'inline-block', color: '#fff', borderRadius: '2px', borderRight: '0px #f00 solid', backgroundColor: item.color, width: item.percent + '%', height:'30px'}">
              <b>{{parseFloat(item.percent).toFixed(1)}}%</b>
            </div>
          </template>
          <span>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td class="pa-2" colspan="2">{{item.name}}</td>
                  </tr>
                  <tr v-if="Object.keys(item.ratesNames).length > 0">
                    <td class="pa-2" colspan="2">{{Object.keys(item.ratesNames).join(', ')}}</td>
                  </tr>
                  <tr>
                    <td class="pa-2">Distance</td>
                    <td class="pa-2">{{item.distance}}{{$t('milesShort')}}</td>
                  </tr>
                  <tr v-if="item.rateMiles > 0">
                    <td class="pa-2">By Miles</td>
                    <td class="pa-2">${{item.rateMiles}}</td>
                  </tr>
                  <tr v-if="item.rateFixed > 0">
                    <td class="pa-2">Fixed</td>
                    <td class="pa-2">${{item.rateFixed}}</td>
                  </tr>
                  <tr v-if="item.rate > 0">
                    <td class="pa-2">Total</td>
                    <td class="pa-2">${{item.rate}}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </span>
        </v-tooltip>
      </template>
    </div>
  </div>
</template>

<script>
import userFormsCommonMixin from '../mixins/userFormsCommonMixin';

export default {
  name: 'DriverRateBreakdown',
  props: ['data'],
  components: { },
  mixins: [userFormsCommonMixin],
  data: function () {
    return {
      loading: false,
      error: null,
      polling: null,
      updating: null,
      items: [],
      distinctColors: ['#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080'],
      typeNames: {
        '10': this.$root.t('User.RateTypes.TYPE_BY_MILES'),
        '20': this.$root.t('User.RateTypes.TYPE_FIXED'),
        '30': this.$root.t('User.RateTypes.TYPE_FIXED_PLUS_MILES'),
        '40': this.$root.t('User.RateTypes.TYPE_FIXED_PLUS_MILES_MORE_THAN'),
        '50': this.$root.t('User.RateTypes.TYPE_FIXED_OR_BY_MILES_MORE_THAN')
      },
    };
  },
  watch: {},
  computed: {
  },
  mounted () {
    this.$nextTick(() => {
      this.processData();
    });
  },
  beforeDestroy () {
  },
  methods: {
    processData () {
      let i = 0;
      const keys = Object.keys(this.data);
      let totalDistance = 0;
      for (i = 0; i < keys.length; i++) {
        let key = keys[i];
        totalDistance += this.data[key + ''].distance;
      }
      if (totalDistance === 0) return;

      this.items.push({
        name: 'Current Rate',
        distance: this.data['0'].distance,
        rate: this.data['0'].rate,
        percent: this.data['0'].distance / totalDistance * 100.0,
        color: this.distinctColors[0],
        ratesNames: {}
      });

      for (i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (parseInt(key) === 0) continue;
        this.items.push({
          name: this.typeNames[key],
          ratesNames: this.data[key].ratesNames,
          distance: this.data[key].distance,
          rate: this.data[key].rate,
          rateMiles: this.data[key].rateMiles,
          rateFixed: this.data[key].rateFixed,
          percent: this.data[key].distance / totalDistance * 100.0,
          color: this.distinctColors[i + 1]
        });
      }
    }
  }
};
</script>
